import { Moment } from 'moment';
import { IFormikOption } from '../../types';
import { getMoment } from '../../utils';

export const isWithinRange = (date: Moment, minDate?: Moment, maxDate?: Moment): boolean =>
  (!maxDate || date.isSameOrBefore(maxDate, 'days')) && (!minDate || date.isSameOrAfter(minDate, 'days'));

export const FROM_TIMES = [
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
];

export const UNTIL_TIMES = [
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
  '23:59',
];

export const toTimeOptions = (times: string[]): IFormikOption[] => times.map((time) => ({ label: time, value: time }));

export const sortByDateAndTime = (date1: string, time1: string, date2: string, time2: string) => {
  const moment1 = getMoment(date1);
  const moment2 = getMoment(date2);
  if (moment1?.isSame(moment2)) {
    return FROM_TIMES.indexOf(time1) - FROM_TIMES.indexOf(time2);
  }
  return moment1?.diff(moment2) || 0;
};
