import { Grid, makeStyles } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useToggle } from 'react-use';
import { Panel } from '../../../../../common';
import { Icon } from '../../../../../common/components/layout/icon.component';
import { Visible } from '../../../../../common/components/layout/Visible.component';
import { translate, translateIgnoreTS } from '../../../../../common/translations/translate';
import { IRootState } from '../../../../../root.state';
import { getSgwRequestWithAddedFields } from '../../../../../store/selectors/sgwRequest.selectors';
import { IRequestMessage, IRequestMessagesList } from '../../../../../types';
import { SgwMessages } from '../../../../molecules/SgwMessages/SgwMessages.component';

const NUMBER_OF_MESSAGES = 5;

const useStyles = makeStyles({
  showMore: {
    fontFamily: 'Roboto',
    fontSize: 16,
    color: '#4796EC',
    marginTop: 16,
    marginRight: 16,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  showMoreIcon: {
    marginRight: 5,
  },
  messagesContainer: {
    marginTop: 20,
    marginBottom: 0,
    padding: 8,
  },
});

interface IProps {
  showAllOnly?: boolean;
  messagesSelector(store: IRootState): IRequestMessagesList;
  fetchMessages(requestId: number): void;
  saveMessage?(message: IRequestMessage): void;
}

export const SgwMessagesPanel: FC<IProps> = ({ showAllOnly = false, messagesSelector, fetchMessages, saveMessage }) => {
  const C = useStyles();
  const messages = useSelector(messagesSelector);
  const request = useSelector(getSgwRequestWithAddedFields);
  const [showAll, toggleShowAll] = useToggle(false);

  const [displayMessages, setDisplayMessages] = useState<IRequestMessagesList>(messages);
  const showMessage = showAll ? 'showLessMessages' : 'showAllMessages';

  useEffect(() => {
    setDisplayMessages({
      ...messages,
      data: messages.data.slice(!showAll && !showAllOnly ? -NUMBER_OF_MESSAGES : 0),
    });
  }, [showAll, messages, setDisplayMessages, showAllOnly]);

  useEffect(() => {
    request && fetchMessages(request.id);
  }, [fetchMessages, request]);

  return (
    <Panel data-testid="messages_panel" title={translate('Requests.Detail.Messages.Title')}>
      <Visible visible={messages.data.length >= NUMBER_OF_MESSAGES && !showAllOnly}>
        <div className={C.showMore} data-testid={'PanelAction'} color="primary" onClick={toggleShowAll}>
          <Icon.History alt={translateIgnoreTS(`Messages.Form.${showMessage}`)} className={C.showMoreIcon} />
          {translateIgnoreTS(`Messages.Form.${showMessage}`)}
        </div>
      </Visible>
      <Grid container spacing={2} className={C.messagesContainer}>
        {request && <SgwMessages messagesList={displayMessages} requestId={request.id} saveMessage={saveMessage} />}
      </Grid>
    </Panel>
  );
};
