import { IRequestStatus } from './requestStatus.types';

export enum RequestReason {
  association = 'association',
  ceremonial_cars = 'ceremonial_cars',
  city_event = 'city_event',
  city_filming = 'city_filming',
  construction_zone = 'construction_zone',
  container = 'container',
  event = 'event',
  loading_unloading_zone = 'loading_unloading_zone',
  move = 'move',
  moving_lift = 'moving_lift',
  scaffold = 'scaffold',
  sweeping_service = 'sweeping_service',
  utility_request = 'utility_request',
}

export interface IRequestReason {
  displayName: string;
  displayRemarks: string;
  name: string;
  needsPayment: boolean;
  needsPlacement: boolean;
  reason: RequestReason;
}

export interface IRequestUser {
  id: number;
  firstName?: string;
  lastName?: string;
  fullName: string;
}

export interface IRequestPriority {
  name: string;
  priority: number;
}

export enum MessageState {
  awaiting_feedback = 'awaiting_feedback',
  feedback_received = 'feedback_received',
  not_in_feedback_loop = 'not_in_feedback_loop',
  header = 'header',
}

export interface IRequestReference {
  id: number;
  dateFrom: string;
  dateUntil: string;
  timeFrom?: string;
  timeUntil?: string;
  emergencyProcedure?: boolean;
  priority: IRequestPriority;
  referenceId: string;
  state: IRequestStatus;
  userResponsible?: IRequestUser;
}
