import {
  generateBaseActionCreator,
  generatePayloadActionCreator,
  TBaseActionCreator,
  TPayloadActionCreator,
} from '../../common';
import { IParkingBanFeature } from '../types/parking-ban-feature';

export const initCpa: TBaseActionCreator = generateBaseActionCreator('cpa.init');

export const setParkingBans: TPayloadActionCreator<IParkingBanFeature[]> =
  generatePayloadActionCreator('cpa.setParkingBans');

export const setParkingBansLoading: TPayloadActionCreator<boolean> =
  generatePayloadActionCreator('cpa.setParkingBansLoading');
