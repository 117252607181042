import { SagaIterator } from 'redux-saga';
import { put, call, takeLatest } from 'redux-saga/effects';
import { genericErrorHandler } from '../../common';
import { translate } from '../../common/translations/translate';
import { HttpStatusCodes, ICall } from '../../types';
import { SgwRequestActions, SnackBarActions } from '../actions';
import { SgwPhasesActions } from '../actions/phases.actions';
import { SgwRequestReschedulingApi } from '../api/sgwRequestRescheduling.api';

function* onApply({ payload }: ReturnType<typeof SgwRequestActions.reschedulings.apply>): SagaIterator {
  try {
    yield call(SgwRequestReschedulingApi.apply, payload.requestId, payload.reschedulingId);
    yield put(SgwRequestActions.reschedulings.fetch(payload.requestId));
    yield put(SgwPhasesActions.fetchList(parseInt(payload.requestId)));
    yield put(SgwRequestActions.syncDates({ requestId: parseInt(payload.requestId) }));
    yield put(SnackBarActions.setSuccess(translate('sgw.requests.detail.requestRescheduling.successApplySnackbar')));
  } catch (error) {
    yield put(SnackBarActions.setFailure(translate('sgw.requests.detail.requestRescheduling.failApplySnackbar')));
  }
}
function* onConsider({ payload }: ReturnType<typeof SgwRequestActions.reschedulings.consider>): SagaIterator {
  const response: ICall<typeof SgwRequestReschedulingApi.consider> = yield call(
    SgwRequestReschedulingApi.consider,
    payload.requestId,
    payload.reschedulingId,
  );
  if (response?.status === HttpStatusCodes.OK) {
    yield put(SgwRequestActions.reschedulings.fetch(payload.requestId));
    yield put(SnackBarActions.setSuccess(translate('sgw.requests.detail.requestRescheduling.successConsideration')));
  }
}

function* onFetch({ payload }: ReturnType<typeof SgwRequestActions.reschedulings.fetch>): SagaIterator {
  const response: ICall<typeof SgwRequestReschedulingApi.fetch> = yield call(SgwRequestReschedulingApi.fetch, payload);
  if (response?.status === HttpStatusCodes.OK) yield put(SgwRequestActions.reschedulings.set(response!.data.data));
}

function* onReject({ payload }: ReturnType<typeof SgwRequestActions.reschedulings.reject>): SagaIterator {
  const response: ICall<typeof SgwRequestReschedulingApi.reject> = yield call(
    SgwRequestReschedulingApi.reject,
    payload.requestId,
    payload.reschedulingId,
  );
  if (response?.status === HttpStatusCodes.OK) yield put(SgwRequestActions.reschedulings.fetch(payload.requestId));
}

function* onUpdate({ payload }: ReturnType<typeof SgwRequestActions.reschedulings.update>): SagaIterator {
  const response: ICall<typeof SgwRequestReschedulingApi.update> = yield call(
    SgwRequestReschedulingApi.update,
    payload.requestId,
    payload.reschedulingId,
    payload.rescheduling,
  );
  if (response?.status === HttpStatusCodes.OK) yield put(SgwRequestActions.reschedulings.fetch(payload.requestId));
}

export function* sgwRequestRescheduling(): SagaIterator {
  yield takeLatest(SgwRequestActions.reschedulings.apply.type, genericErrorHandler(onApply));
  yield takeLatest(SgwRequestActions.reschedulings.consider.type, genericErrorHandler(onConsider));
  yield takeLatest(SgwRequestActions.reschedulings.fetch.type, genericErrorHandler(onFetch));
  yield takeLatest(SgwRequestActions.reschedulings.reject.type, genericErrorHandler(onReject));
  yield takeLatest(SgwRequestActions.reschedulings.update.type, genericErrorHandler(onUpdate));
}
