import { Paper, TextField } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import classNames from 'classnames';
import React, { FC } from 'react';
import { CONFIG } from '../../../common';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { translate } from '../../../common/translations/translate';
import { useLoginForm } from '../../../hooks/forms/useLoginForm.hook';
import { useLoginStyles } from './Login.styles';

export const LoginPage: FC = () => {
  const C = useLoginStyles();
  const { onSubmit, formMethods } = useLoginForm();
  const { register } = formMethods;

  return (
    <>
      <Container>
        <Paper square data-testid="unauthorizedPage" className={C.loginWrapper}>
          <div className={C.centered}>
            <img alt={translate('login.image')} className={C.image} src={CONFIG.logoUrl} />
            <h1>{translate('login.title')}</h1>
            <form onSubmit={onSubmit} className={classNames(C.form, C.centered)}>
              <TextField className={C.inputFields} {...register('username')} label={translate('login.username')} />
              <TextField
                className={C.inputFields}
                {...register('password')}
                label={translate('login.password')}
                type="password"
              />
              <Button.Blue type="submit" className={C.button}>
                {translate('login.button')}
              </Button.Blue>
            </form>
          </div>
        </Paper>
      </Container>
    </>
  );
};
