import { Grid, TextField } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { ChangeEvent, FC, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Panel, PanelAction } from '../../../common';
import { Autocomplete as AutocompleteUtility } from '../../../common/components/form-fields/autocomplete.component';
import { SingleSelectV2Component } from '../../../common/components/form-fields/single-select-v2.component';
import { Visible } from '../../../common/components/layout/Visible.component';
import { appUrls } from '../../../common/config/url.constants';
import { UserActions } from '../../../common/store/user/user.actions';
import { selectAllRoles } from '../../../common/store/user/user.selectors';
import { translate } from '../../../common/translations/translate';
import { countryToFlag } from '../../../common/utils/countryToFlag.util';
import { useUserForm } from '../../../hooks/forms/useUserForm.hook';
import { UtilityCompanyActions } from '../../../store/actions';
import { selectCountries } from '../../../store/selectors';
import { useGeneralStyles } from '../../../style/generalStyles';
import { ICountry } from '../../../types';
import { useFormStyles } from '../../../style/form.styles';
import { SubmitButton } from '../../atoms';
import { getUtilityCompaniesList } from '../../../store/selectors/utilityCompanies.selectors';

interface IProps {
  title?: string;
}

export const UserForm: FC<IProps> = ({ title }) => {
  const C = useFormStyles();
  const G = useGeneralStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { formMethods, submitUser, id } = useUserForm();
  const {
    getValues,
    register,
    setValue,
    watch,
    formState: { errors },
  } = formMethods;
  const countries = useSelector(selectCountries);
  const allUserRoles = useSelector(selectAllRoles);
  const allUtilityCompanies = useSelector(getUtilityCompaniesList);
  const roles = watch('roles');

  useEffect(() => {
    dispatch(UserActions.fetchAllRoles());
    dispatch(UtilityCompanyActions.list.fetch({}));
  }, [dispatch]);

  useEffect(() => {
    if (roles?.length === 1) {
      setValue('role', roles[0].id);
    }
  }, [setValue, roles]);

  const goToDetail = () => history.push(appUrls.users.detail(id));

  const onRoleChange = (e: ChangeEvent<{ value: unknown }>) => setValue('role', `${e.target.value}`);

  const onUtilityCompanyChange = (utilityCompanyId?: number) =>
    setValue(
      'utilityCompany',
      allUtilityCompanies.find((uc) => uc.id === utilityCompanyId),
      { shouldTouch: true },
    );

  const onCountryChange = (_: ChangeEvent<any>, value: ICountry | null) => setValue('country', value);

  return (
    <FormProvider {...formMethods}>
      <form id={'createUser'} onSubmit={submitUser} data-testid="userForm" noValidate>
        <Panel title={title}>
          <Visible visible={!!id}>
            <PanelAction icon={<Edit />} onClick={goToDetail} data-testid="editButton" />
          </Visible>
          <Grid container spacing={3} className={C.formGrid}>
            <Grid item xs={12}>
              <TextField
                {...register('firstName', { required: true })}
                label={translate('users.details.firstName')}
                className={G.fullWidth}
                required
                data-testid="firstNameField"
                error={!!errors.firstName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('lastName')}
                label={translate('users.details.lastName')}
                className={G.fullWidth}
                data-testid="lastNameField"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('email', { required: true })}
                label={translate('users.details.email')}
                className={G.fullWidth}
                required
                data-testid="emailField"
                error={!!errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('phoneNumber')}
                label={translate('users.details.phoneNumber')}
                className={G.fullWidth}
                data-testid="phoneNumberField"
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                {...register('street')}
                label={translate('users.details.street')}
                className={G.fullWidth}
                data-testid="streetField"
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                {...register('streetNumber')}
                label={translate('users.details.streetNumber')}
                className={G.fullWidth}
                data-testid="streetNumberField"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                {...register('zipCode')}
                label={translate('users.details.zipCode')}
                className={G.fullWidth}
                data-testid="zipCodeField"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                {...register('city')}
                label={translate('users.details.city')}
                className={G.fullWidth}
                data-testid="cityField"
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                data-testid="countryField"
                options={countries}
                value={watch('country')}
                onChange={onCountryChange}
                autoHighlight
                getOptionLabel={(option) => option.name}
                renderOption={(option) => (
                  <>
                    <span>{countryToFlag(option.country)}</span>
                    {option.name} ({option.country})
                  </>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translate('users.details.country')}
                    variant="standard"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: '', // disable autocomplete and autofill
                    }}
                    InputLabelProps={{ shrink: id ? true : undefined }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <SingleSelectV2Component
                disabled={getValues('source') === 'a-profiel'}
                menuItems={allUserRoles.map((r) => ({ value: r.id, label: r.label }))}
                data-testid="rolesField"
                placeholder={translate('users.edit.rolePlaceholder')}
                title={watch('role') ? translate('users.edit.role') : ''}
                style={{ maxWidth: 'none' }}
                value={watch('role')}
                onChange={onRoleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <AutocompleteUtility<number>
                title={watch('utilityCompany')?.id ? translate('users.details.utilityCompany') : ''}
                data-testid="utilityCompanyField"
                placeholder={translate('users.edit.utilityCompanyPlaceholder')}
                menuItems={allUtilityCompanies.map((uc) => ({ value: uc.id, label: uc.name as any }))}
                value={watch('utilityCompany')?.id}
                onChange={onUtilityCompanyChange}
                elipsisChars={25}
              />
            </Grid>
            <SubmitButton formId="createUser" data-testid="createButton" />
          </Grid>
        </Panel>
      </form>
    </FormProvider>
  );
};
