import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as I18n from 'i18n-js';
import * as React from 'react';
import { WORK_ORDER_ITEM_TYPES } from '../../planning.constants';
import { ITeam, IWorkOrder, IWorkOrderItem } from '../../../types';
import { TeamAssignItem } from './team-assign-item.component';
import { translate } from '../../../common/translations/translate';

interface IProps {
  workOrderItems: IWorkOrderItem[];
  teams: ITeam[];
  handleDialogClose: () => void;
  onConfirm: (team: ITeam, items: IWorkOrderItem[]) => void;
  onUnassign: (items: IWorkOrderItem[]) => void;
}

interface INumberOfSigns {
  pickUp: number;
  dropOff: number;
}

export class TeamAssignDialog extends React.Component<IProps> {
  public render(): React.ReactNode {
    const assignedTeam: ITeam | undefined = this.isAllWoiAssignedToTeam(this.props.workOrderItems);
    const numberOfSigns: INumberOfSigns = this.getNumOfSigns();
    return (
      <div style={{ position: 'absolute', backgroundColor: 'white' }}>
        <Dialog open={true} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {I18n.t('AssignToTeam')}
            <br />
            {this.props.workOrderItems.length} items
            {numberOfSigns.pickUp > 0 && (
              <div className="planning-signs-count">{I18n.t('SignsToBePickedUp', { count: numberOfSigns.pickUp })}</div>
            )}
            {numberOfSigns.dropOff > 0 && (
              <div className="planning-signs-count">{I18n.t('SignsToBeDropOff', { count: numberOfSigns.dropOff })}</div>
            )}
          </DialogTitle>
          <DialogContent>
            <div className={'team-assign-dialog'}>
              {this.props.teams.map((team: ITeam) => {
                return (
                  <TeamAssignItem
                    key={team.id}
                    team={team}
                    handleClick={this.chooseTeam}
                    disabled={assignedTeam ? team.id === assignedTeam.id : false}
                  />
                );
              })}
              <Button style={{ margin: 10 }} variant={'contained'} onClick={this.onUnassign}>
                {I18n.t('UnassignFromTeam')}
              </Button>
            </div>
          </DialogContent>
          <DialogActions>
            <Button style={{ color: 'black' }} onClick={this.props.handleDialogClose}>
              {translate('refuse')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  private onUnassign = (): void => {
    this.props.onUnassign(this.props.workOrderItems);
  };

  private chooseTeam = (team: ITeam): void => {
    this.props.onConfirm(team, this.props.workOrderItems);
  };

  private isAllWoiAssignedToTeam(items: IWorkOrderItem[]): ITeam | undefined {
    const workOrderItem: IWorkOrderItem | undefined = items.find((item) => !!item.workOrder);
    const workOrder: IWorkOrder | undefined = workOrderItem ? workOrderItem.workOrder : undefined;
    if (workOrder) {
      const isInOneTeam: boolean = items.every((item: IWorkOrderItem) => {
        if (!item.workOrder) {
          return false;
        }
        return item.workOrder.id === workOrder.id;
      });
      if (isInOneTeam) {
        return workOrder.team;
      }
    }
    return;
  }

  private getNumOfSigns(): INumberOfSigns {
    const res: INumberOfSigns = { pickUp: 0, dropOff: 0 };

    if (this.props.workOrderItems.length) {
      this.props.workOrderItems.forEach((x) => {
        if (x.civilianNotification && x.numberOfSigns) {
          res.pickUp += x.numberOfSigns;
        }

        if (x.parkingBanIntake && x.type === WORK_ORDER_ITEM_TYPES.PICK_UP && x.numberOfSigns) {
          res.pickUp += x.numberOfSigns;
        }

        if (x.parkingBanIntake && x.type === WORK_ORDER_ITEM_TYPES.DROP_OFF && x.numberOfSigns) {
          res.dropOff += x.numberOfSigns;
        }
      });
    }
    return res;
  }
}
