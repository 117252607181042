import { makeStyles, Paper, TextField, Typography } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import classNames from 'classnames';
import * as React from 'react';
import { ChangeEvent, FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useToggle } from 'react-use';
import { COLORS, Panel } from '../../../common';
import AsignButton from '../../../common/components/buttons/asign-button';
import { Icon } from '../../../common/components/layout/icon.component';
import { Visible } from '../../../common/components/layout/Visible.component';
import { translate } from '../../../common/translations/translate';
import { useOnClickOutside } from '../../../hooks';
import { Unavailable } from '../../../common/components/formatters/unavailable';

interface IProps {
  isReadOnly?: boolean;
  notes?: string;
  onPatchInternalNotes: (backofficeNotes: string, shouldAppend: boolean) => void;
  visible?: boolean;
}

const NUMBER_OF_NOTES = 5;

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  paper: {
    padding: '10px',
    marginBottom: '20px',
    backgroundColor: COLORS.TEAM_CARD_BG,
    cursor: 'pointer',
  },
  notes: {
    whiteSpace: 'pre-line',
    fontSize: 'inherit',
  },
  textField: {
    width: '100%',
    marginBottom: '10px',
  },
  showMore: {
    fontFamily: 'Roboto',
    fontSize: 16,
    color: '#4796EC',
    marginTop: 16,
    marginRight: 16,
    cursor: 'pointer',
  },
  showMoreIcon: {
    marginRight: 5,
  },
});

export const SgwRequestInternalNotes: FunctionComponent<IProps> = ({
  isReadOnly = false,
  notes,
  onPatchInternalNotes,
  visible = true,
}) => {
  const C = useStyles();
  const [newMessage, setNewMessage] = useState<string>('');
  const [editMode, setEditMode] = useState(false);
  const [editedNotes, setEditedNotes] = useState<string>(notes || '');
  const ref = useOnClickOutside(() => setEditMode(false));
  const [showAll, toggleShowAll] = useToggle(false);

  const onSaveMessage = useCallback(() => {
    if (editMode) {
      onPatchInternalNotes(editedNotes, false);
    } else if (newMessage.length) {
      onPatchInternalNotes(newMessage, true);
    }
    setNewMessage('');
    setEditMode(false);
  }, [editMode, editedNotes, newMessage, onPatchInternalNotes]);

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setNewMessage(event.target.value);
  }, []);

  useEffect(() => {
    setEditedNotes(notes || '');
  }, [notes]);

  let notesToShow = notes;
  const noteDelimiter = '\n\n';
  const splitNotes = (notes || '').split(noteDelimiter);
  if (!showAll && splitNotes.length > NUMBER_OF_NOTES) {
    notesToShow = splitNotes.slice(-NUMBER_OF_NOTES).join(noteDelimiter);
  }

  return visible ? (
    <Panel title={translate('Requests.Detail.internalNotes.title')} ref={ref}>
      <Visible visible={splitNotes.length > NUMBER_OF_NOTES} key={showAll ? 1 : 0}>
        <div
          className={C.showMore}
          data-testid={'PanelAction'}
          style={{ position: 'absolute', right: 0, top: 0 }}
          color="primary"
          onClick={toggleShowAll}
        >
          {showAll ? (
            <>
              <Icon.History
                alt={translate('Requests.Detail.internalNotes.showLessMessages')}
                className={C.showMoreIcon}
              />
              {translate('Requests.Detail.internalNotes.showLessMessages')}
            </>
          ) : (
            <>
              <Icon.History
                alt={translate('Requests.Detail.internalNotes.showAllMessages')}
                className={C.showMoreIcon}
              />
              {translate('Requests.Detail.internalNotes.showAllMessages')}
            </>
          )}
        </div>
      </Visible>
      {notes ? (
        editMode ? (
          <TextField
            multiline
            value={editedNotes}
            onChange={(event) => setEditedNotes(event.target.value)}
            className={classNames(C.textField, C.notes)}
            variant="outlined"
          />
        ) : (
          <Paper onClick={() => !isReadOnly && setEditMode(true)} data-testid="internalNotesPaper" className={C.paper}>
            <Typography className={C.notes}>{notesToShow}</Typography>
          </Paper>
        )
      ) : (
        isReadOnly && <Unavailable text={translate('Requests.Detail.internalNotes.noNotes')} />
      )}
      <Visible visible={!isReadOnly}>
        <Visible visible={!editMode}>
          <TextField
            data-testid="inputField"
            multiline
            value={newMessage}
            className={C.textField}
            label={translate('Requests.Detail.internalNotes.description')}
            onChange={onChange}
          />
        </Visible>
        <div className={C.buttonContainer}>
          <AsignButton
            data-testid="saveMessageButton"
            color="secondary"
            onClick={onSaveMessage}
            startIcon={<Save fontSize="small" />}
            disabled={!newMessage && !editMode}
          >
            {editMode
              ? translate('Requests.Detail.internalNotes.saveNotes')
              : translate('Requests.Detail.internalNotes.saveNote')}
          </AsignButton>
        </div>
      </Visible>
    </Panel>
  ) : null;
};
