import I18n from 'i18n-js';
import { IntakeTypes, IRequestLocationForm } from '../../../types';
import { antwerpZipCodes } from '../../../common/config/app.constants';
import { getCity } from '../../../common/utils/zipCodes.util';

export const locationFormManagement = {
  getInitialValues: (location: Partial<IRequestLocationForm>) =>
    ({
      ...location,
      description: location.description || undefined,
      geometryType: location.geometryType || 'line',
      gisId: location.gisId || undefined,
      street: location.street || undefined,
      streetNumberFrom: location.streetNumberFrom === '0' ? '' : location.streetNumberFrom || undefined,
      streetNumberTo: location.streetNumberTo === '0' ? '' : location.streetNumberTo || undefined,
      streetNumberUnknown:
        location.streetNumberUnknown || (location.streetNumberFrom === '0' && location.streetNumberTo === '0') || false,
      type: location.type || IntakeTypes.parkingbanintake,
      zipCode: location.zipCode || antwerpZipCodes[0],
    } as IRequestLocationForm),
  requestValidateValues: async (
    values: Partial<IRequestLocationForm>,
    onSave: (values: Partial<IRequestLocationForm>) => void,
  ) => {
    let hasError = false;
    const errors: Partial<IRequestLocationForm> = {};
    const requiredFields: (keyof IRequestLocationForm)[] = ['street', 'zipCode'];

    requiredFields.forEach((field) => {
      if (!values[field]) {
        hasError = true;
        // @ts-ignore
        errors[field] = I18n.translate('Requests.Create.Form.required');
      }
    });

    if (!hasError) {
      onSave({
        ...values,
        city: values.zipCode ? getCity(values.zipCode) : undefined,
      });
    }

    return errors;
  },
};
