import { LineString, MultiPolygon, Polygon, Point, Position } from 'geojson';
import { LatLng, latLngBounds, LatLngBounds } from 'leaflet';
import { IFetchGeocode, IGeometry, IGeometryType } from '../../types';
import area from '@turf/area';
import length from '@turf/length';
import convex from '@turf/convex';
import center from '@turf/center';

export const convertLatLngToPosition = ({ lat, lng }: LatLng): Position => [lng, lat];

export const coordinatesToLatLng0Level = ([longitude, latitude]: Position): LatLng => new LatLng(latitude, longitude);
export const coordinatesToLatLng1Level = (positions: Position[]): LatLng[] => positions.map(coordinatesToLatLng0Level);
export const coordinatesToLatLng2Level = (positions: Position[][]): LatLng[] =>
  positions.flatMap(coordinatesToLatLng1Level);
export const coordinatesToLatLng3Level = (positions: Position[][][]): LatLng[] =>
  positions.flatMap(coordinatesToLatLng2Level);

export const geometryToPositions = ({ type, coordinates }: IGeometry): LatLng[] => {
  switch (type) {
    case 'Point':
      return [coordinatesToLatLng0Level(coordinates as Position)];
    case 'MultiPoint':
      return coordinatesToLatLng1Level(coordinates as Position[]);
    case 'LineString':
      return coordinatesToLatLng1Level(coordinates as Position[]);
    case 'MultiLineString':
      return coordinatesToLatLng2Level(coordinates as Position[][]);
    case 'Polygon':
      return coordinatesToLatLng2Level(coordinates as Position[][]);
    case 'MultiPolygon':
      return coordinatesToLatLng3Level(coordinates as Position[][][]);
  }
};

export const geometryCalculateCenter = (geometry: IGeometry) => {
  const positions = geometryToPositions(geometry);
  return {
    lat: positions.reduce((a, b) => a + b.lat, 0) / positions.length,
    lng: positions.reduce((a, b) => a + b.lng, 0) / positions.length,
  } as LatLng;
};

export const getCenterOfGeometries = (geometries: IGeometry[]) =>
  geometries.length
    ? coordinatesToLatLng0Level(center({ geometries, type: 'GeometryCollection' }).geometry.coordinates)
    : undefined;

export const getBoundsOfGeometries = (geometries: IGeometry[]): LatLngBounds =>
  getBoundsOfBounds(geometries.map(({ coordinates }) => getBounds(coordinates)));

export const getBounds = (positions: Position | Position[] | Position[][] | Position[][][]): LatLngBounds => {
  if (Array.isArray(positions[0]) && Array.isArray(positions[0][0])) {
    return getBounds(positions.flat() as Position[][] | Position[][][]);
  } else if (!Array.isArray(positions[0])) {
    return latLngBounds([coordinatesToLatLng0Level(positions as Position)]);
  } else {
    return latLngBounds(coordinatesToLatLng1Level(positions as Position[]));
  }
};

export const getBoundsOfBounds = (bounds: LatLngBounds[]): LatLngBounds => {
  return latLngBounds(bounds.map((bound) => [bound.getSouthEast(), bound.getNorthWest()]).flat());
};

export const equalBounds = (bounds1?: LatLngBounds, bounds2?: LatLngBounds) =>
  bounds1 &&
  bounds2 &&
  bounds1.getNorth() === bounds2.getNorth() &&
  bounds1.getEast() === bounds2.getEast() &&
  bounds1.getSouth() === bounds2.getSouth() &&
  bounds1.getWest() === bounds2.getWest();

export const isLatLngArray = (obj: any): obj is LatLng[] => obj.length !== undefined && obj.length > 0;

export const getGeoCodeAddress = ({ street, streetNumber, city, zipCode }: IFetchGeocode) => {
  if (street) {
    // AS-6232 Remove postal code from street name
    street = street.replace(/\(\d+\)/g, '').trim();
  }
  return `${street}${streetNumber ? `+${streetNumber}` : ''},+${zipCode}+${city}`;
};

export const createLinestring = (points: Point[]): LineString => ({
  coordinates: points.map(({ coordinates }) => coordinates),
  type: IGeometryType.LineString,
});

export const createPolygon = ({ coordinates: [lng1, lat1] }: Point, { coordinates: [lng2, lat2] }: Point): Polygon => ({
  coordinates: [
    [
      [lng1, lat1],
      [lng1, lat2],
      [lng2, lat2],
      [lng2, lat1],
      [lng1, lat1],
    ],
  ],
  type: IGeometryType.Polygon,
});

export const createLinestringFromLatLng = (latLngs: LatLng[]): LineString => ({
  coordinates: latLngs.map(convertLatLngToPosition),
  type: IGeometryType.LineString,
});

export const createPolygonFromLatLng = (latLngs: LatLng[]): Polygon => ({
  coordinates: [latLngs.map(convertLatLngToPosition)],
  type: IGeometryType.Polygon,
});

export const geometryToRequestGeometryType = (geometry: IGeometry): 'line' | 'polygon' | undefined => {
  switch (geometry.type) {
    case IGeometryType.LineString:
      return 'line';
    case IGeometryType.Polygon:
      return 'polygon';
    default:
      return undefined;
  }
};

export const multiPolygonToPolygon = (multiPolygon: MultiPolygon): Polygon =>
  convex(multiPolygon, { concavity: 2 })!.geometry;

export const getDistance = (geometry?: IGeometry) =>
  geometry
    ? Math.round(
        length(
          {
            geometry,
            type: 'Feature',
            properties: { key: 0, id: 0, type: '', current: true, description: '', title: '' },
          },
          { units: 'meters' },
        ),
      )
    : 0;

export const getArea = (geometry?: IGeometry) =>
  geometry
    ? Math.round(
        area({
          geometry,
          type: 'Feature',
          properties: { key: 0, id: 1, type: '', current: true, description: '', title: '' },
        }),
      )
    : 0;
