import { createReducer } from '@reduxjs/toolkit';
import { payloadReducer } from '../../..';
import { IUser } from '../../../types/user';
import { UserActions } from '../user.actions';

type TState = IUser[];

export default createReducer<TState>([], {
  [UserActions.setAdminList.type]: payloadReducer<TState>(),
});
