import { Actions } from '../..';
import { FetchAccessToken } from '../../../types';

class AppActionsClass extends Actions {
  fetchToken = this.createPayloadAction<FetchAccessToken>('fetchToken');
  fetchOAuthToken = this.createPayloadAction<string>('fetchOAuthToken');
  init = this.createAction('init');
  initData = this.createPayloadAction<boolean>('initData');
  logout = this.createPayloadAction<boolean | undefined>('logout');
  postMessageToParent = this.createPayloadAction('postMessageToParent');
  setAccessToken = this.createPayloadAction<string | null>('setAccessToken');
  setLoading = this.createPayloadAction<boolean>('setLoading');
  setRedirectUrl = this.createPayloadAction<string>('setRedirectUrl');
}

export const AppActions = new AppActionsClass('app');
