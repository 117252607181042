import * as React from 'react';
import { Route } from 'react-router';
import { appUrls } from '../common/config/url.constants';
import NotificationDetailPage from './pages/notification-detail.page';
import { NotificationsPage } from './pages/notifications.page';

export const NotificationsRoutes = () => (
  <>
    <Route exact={true} path={appUrls.notifications.detail()} component={NotificationDetailPage} />
    <Route exact={true} path={appUrls.notifications.base} component={NotificationsPage} />
  </>
);
