import { Grid, TextField } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React, { ChangeEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, PanelAction } from '../../../../../common';
import { Visible } from '../../../../../common/components/layout/Visible.component';
import { GenericTablePanel } from '../../../../../common/components/table/GenericTablePanel.component';
import { translate, translateIgnoreTS } from '../../../../../common/translations/translate';
import { snakeToCamel } from '../../../../../common/utils/string.util';
import { useToggle } from '../../../../../hooks/useToggle.hook';
import { SgwSettingsActions } from '../../../../../store/actions';
import { getSgwSettingsList, selectTable } from '../../../../../store/selectors/sgwSettings.selectors';
import { useGeneralStyles } from '../../../../../style/generalStyles';
import { ISgwSetting, ISgwSettings } from '../../../../../types';
import { Auth } from '../../../../atoms';
import { Button } from '../../../../../common/components/buttons/asign-button-extensions';

export const SettingsPage = () => {
  const G = useGeneralStyles();
  const dispatch = useDispatch();
  const settings = useSelector(getSgwSettingsList);
  const { paging } = useSelector(selectTable);
  const [isEditMode, toggleEditMode] = useToggle();

  useEffect(() => {
    dispatch(SgwSettingsActions.list.fetch({}));
  }, [dispatch]);

  const onValueChange = (setting?: ISgwSetting) => (e: ChangeEvent<HTMLInputElement>) => {
    setting && dispatch(SgwSettingsActions.set({ ...setting, value: e.currentTarget.value }));
  };

  const onToggleEditMode = () => {
    isEditMode && dispatch(SgwSettingsActions.list.fetch({}));
    toggleEditMode();
  };

  const onSave = () => {
    dispatch(SgwSettingsActions.saveList());
    toggleEditMode();
  };

  return (
    <Auth acl={[ISgwSettings.viewSgwSettings]} showUnauthorizedPage>
      <Container className={G.marginTop20}>
        <GenericTablePanel<ISgwSetting>
          columnKey="name"
          title={translate('sgw.settings.title')}
          showPagination={false}
          columns={[
            {
              label: translate('sgw.settings.description'),
              name: 'name',
              sortable: false,
              renderer: (name) => translateIgnoreTS(`sgw.settings.${snakeToCamel(name)}`),
            },
            {
              label: translate('sgw.settings.value'),
              name: 'value',
              sortable: false,
              renderer: (value, setting) => (
                <Visible visible={isEditMode} defaultComponent={value}>
                  <TextField fullWidth value={value} type="number" required onChange={onValueChange(setting)} />
                </Visible>
              ),
            },
          ]}
          labelUnavailable={translate('sgw.settings.unavailable')}
          records={settings}
          paging={paging}
          customHeader={
            <Auth acl={ISgwSettings.editSgwSettings}>
              <PanelAction icon={<Edit />} onClick={onToggleEditMode} />
            </Auth>
          }
        />
        <Visible visible={isEditMode}>
          <Grid container justify="flex-end">
            <Grid item>
              <Button.Green onClick={onSave}>{translate('sgw.settings.save')}</Button.Green>
            </Grid>
          </Grid>
        </Visible>
      </Container>
    </Auth>
  );
};
