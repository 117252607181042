import { createSelector } from '@reduxjs/toolkit';
import { createPagedApiParamsSelector } from '../../common/store/utils/generic.selectors';
import { createAllEntitiesSelector } from '../../common/utils/normalized.util';
import { IRootState } from '../../root.state';
import { ISgwAdvisingPartiesFilter, ISgwAdvisingParty } from '../../types/sgwAdvisingParties.types';

export const selectSgwNatureOfWorks = (store: IRootState) => store.sgw.advisingParties;
export const selectAllIds = (store: IRootState) => store.sgw.advisingParties.list.allIds;
export const selectById = (store: IRootState) => store.sgw.advisingParties.list.byId;
export const selectTable = (store: IRootState) => store.sgw.advisingParties.list.table;
export const selectListLoading = (store: IRootState) => store.sgw.advisingParties.list.loading;
export const selectLoading = (store: IRootState) => store.sgw.advisingParties.loading;

export const getAdvisingPartiesList = createAllEntitiesSelector(selectAllIds, selectById);
export const getPagedApiParams = createPagedApiParamsSelector<ISgwAdvisingPartiesFilter>(selectTable);

const initialAdvisingParty: Partial<ISgwAdvisingParty> = { active: true };

export const getAdvisingParty = (id?: string) =>
  createSelector([selectById], (selectById) => (id ? selectById?.[id] : initialAdvisingParty));
