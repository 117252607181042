import AddIcon from '@material-ui/icons/Add';
import React, { FC, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IPaging, ISorting } from '../../../../../../common';
import { Button } from '../../../../../../common/components/buttons/asign-button-extensions';
import { CheckboxWithLabel } from '../../../../../../common/components/form-fields/checkboxWithLabel.component';
import { Container } from '../../../../../../common/components/layout/container.component';
import { GenericTablePanel } from '../../../../../../common/components/table/GenericTablePanel.component';
import { appUrls } from '../../../../../../common/config/url.constants';
import { translate } from '../../../../../../common/translations/translate';
import { SgwNatureOfWorksActions } from '../../../../../../store/actions';
import {
  getNatureOfWorksList,
  selectLoading,
  selectTable,
} from '../../../../../../store/selectors/sgwNatureOfWorks.selectors';
import { useGeneralStyles } from '../../../../../../style/generalStyles';
import { ISgwNatureOfWork, ISgwNatureOfWorksAclActions, ISgwNatureOfWorksFilter } from '../../../../../../types';
import { Auth } from '../../../../../atoms';
import { useFormStyles } from '../../../../../../style/form.styles';

export const NatureOfWorksPage: FC = () => {
  const C = useFormStyles();
  const G = useGeneralStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { filters, paging, sorting } = useSelector(selectTable);
  const loading = useSelector(selectLoading);
  const natureOfWorks = useSelector(getNatureOfWorksList);

  useEffect(() => {
    dispatch(SgwNatureOfWorksActions.list.fetch({}));
  }, [dispatch]);

  const onChangeSorting = useCallback(
    (sorting: ISorting) => {
      dispatch(SgwNatureOfWorksActions.list.fetch({ sorting }));
    },
    [dispatch],
  );

  const onChangePaging = useCallback(
    (paging: IPaging) => {
      dispatch(SgwNatureOfWorksActions.list.fetch({ paging }));
    },
    [dispatch],
  );

  const onChangeFiltering = useCallback(
    (filters: ISgwNatureOfWorksFilter): void => {
      dispatch(SgwNatureOfWorksActions.list.fetch({ filters }));
    },
    [dispatch],
  );
  const onChange = () => {
    onChangeFiltering({ ...filters, showDeleted: !filters.showDeleted });
  };

  const onClick = useCallback(
    (naturOfWork: ISgwNatureOfWork) => {
      history.push(`${appUrls.sgw.manage.natureOfWorks.base}/${naturOfWork.id}`);
    },
    [history],
  );

  const onCreateNatureOfWork = useCallback(() => {
    history.push(appUrls.sgw.manage.natureOfWorks.create);
  }, [history]);

  return (
    <Auth acl={ISgwNatureOfWorksAclActions.viewSgwNatureOfWorksOverview} showUnauthorizedPage>
      <Container>
        <Auth acl={ISgwNatureOfWorksAclActions.createSgwNatureOfWorks}>
          <Button.Green
            className={C.createButton}
            dataTestId="newNatureOfWork"
            onClick={onCreateNatureOfWork}
            startIcon={<AddIcon fontSize={'small'} />}
          >
            {translate('sgw.natureOfWorks.newNatureOfWork')}
          </Button.Green>

          <CheckboxWithLabel
            checked={!!filters.showDeleted}
            label={translate('sgw.natureOfWorks.list.showDeleted')}
            onChange={onChange}
          />
        </Auth>
        <GenericTablePanel<ISgwNatureOfWork>
          dataTestId="SgwNatureOfWorksListTable"
          labelUnavailable={translate('sgw.natureOfWorks.list.unavailable')}
          onChangeSorting={onChangeSorting}
          onChangePaging={onChangePaging}
          onClick={onClick}
          loading={loading}
          paging={paging}
          sorting={sorting}
          records={natureOfWorks}
          title={<div className={G.flexRowSpaceBetween}>{translate('sgw.natureOfWorks.list.title')}</div>}
          columnKey="id"
          columns={[
            {
              label: translate('sgw.natureOfWorks.columns.id'),
              name: 'id',
              sortable: true,
            },
            {
              label: translate('sgw.natureOfWorks.columns.name'),
              name: 'name',
              sortable: true,
            },
            {
              label: translate('sgw.natureOfWorks.columns.description'),
              name: 'description',
              sortable: true,
            },
            {
              label: translate('sgw.natureOfWorks.columns.gipodGuid'),
              name: 'gipodGuid',
              sortable: true,
            },
          ]}
        />
      </Container>
    </Auth>
  );
};
