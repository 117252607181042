import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import { atom, useAtom } from 'jotai';
import React, { FC } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from '../../../common/translations/translate';
import { formatDateTimeToDisplayDate } from '../../../common/utils/date.util';
import { SgwPhasesActions } from '../../../store/actions/phases.actions';
import { getPhasesWithTotalAndMaxSurfaceArea, getSgwRequest } from '../../../store/selectors/sgwRequest.selectors';
import { useGeneralStyles } from '../../../style/generalStyles';
import { useSpacingStyles } from '../../../style/spacing.styles';
import { ISgwPhase } from '../../../types';
import { ErrorLabel } from '../../atoms/ErrorLabel/ErrorLabel.component';
import { SgwRequestActions } from '../../../store/actions';

export const intakeModalAtom = atom(false);

export const IntakeForm: FC<{ sgwRequestId: string }> = ({ sgwRequestId }) => {
  const sgwRequest = useSelector(getSgwRequest(sgwRequestId));
  const G = { ...useGeneralStyles(), ...useSpacingStyles() };
  const phases = useSelector(getPhasesWithTotalAndMaxSurfaceArea());
  const [, setIntakeModal] = useAtom(intakeModalAtom);
  const form = 'intakeForm';
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<{ phases: ISgwPhase[]; offsetDays: number }>({
    defaultValues: {
      phases: phases,
      offsetDays: sgwRequest?.offsetDays,
    },
  });
  const { fields } = useFieldArray({
    control,
    name: 'phases',
  });

  const onSubmit = (data: { phases: ISgwPhase[]; offsetDays: number }) => {
    dispatch(SgwRequestActions.quarterCost.setLoading(true));
    data.phases.forEach((phase) => {
      dispatch(SgwPhasesActions.save({ phase }));
    });
    dispatch(
      SgwRequestActions.patch({
        id: parseInt(sgwRequestId),
        offsetDays: data.offsetDays,
      }),
    );
    setIntakeModal(false);
  };

  return (
    <form id={form} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid xs={12} container item justify="flex-end">
          <Grid xs={4} item>
            {translate('sgw.requests.detail.retributionTable.surfaceArea')}
          </Grid>
          <Grid xs={4} item>
            {translate('sgw.requests.detail.retributionTable.exemptSurface')}
          </Grid>
        </Grid>
        {fields.map((phase, index) => (
          <Grid container key={phase.id} className={G.marginTop.XS}>
            <Grid xs={4} item container>
              <div className={classNames(G.flexColumn, phase.parentPhaseId ? G.marginLeft.XS : '')}>
                <div>
                  {phase.phaseName || `${translate('sgw.requests.detail.retributionTable.phase')} ${index + 1}`}
                </div>
                <small>{`${formatDateTimeToDisplayDate(phase.dateFrom)} - ${formatDateTimeToDisplayDate(
                  phase.dateUntil,
                )}`}</small>
              </div>
            </Grid>
            <Grid xs={4} item className={G.flexRowCenterVertical}>
              <div>{`${phases[index].totalSurfaceArea} m2`}</div>
            </Grid>
            <Grid xs={4} item className={G.flexRowCenterVertical}>
              <div className={G.flexColumn}>
                <div className={G.flexRow}>
                  <input
                    type="number"
                    {...register(`phases.${index}.exemptedSurfaceArea` as const, {
                      valueAsNumber: true,
                      min: { value: 0, message: translate('services.form.largerThanZero') },
                      max: {
                        value: phases[index].maxSurfaceArea,
                        message: translate('services.form.maxValue'),
                      },
                      required: { value: true, message: translate('services.form.required') },
                    })}
                    defaultValue={0}
                  />
                  <span className={G.marginLeft.XXS}>m2</span>
                </div>
                <ErrorLabel
                  showError={!!errors?.phases?.[index]?.exemptedSurfaceArea}
                  message={errors?.phases?.[index]?.exemptedSurfaceArea?.message}
                />
              </div>
            </Grid>
          </Grid>
        ))}

        <Grid container className={G.marginTop.XL}>
          <Grid xs={8} item container>
            <div className={G.flexColumn}>{translate('sgw.requests.detail.info.offsetDays')}</div>
          </Grid>
          <Grid xs={4} item className={G.flexRowCenterVertical}>
            <div className={G.flexColumn}>
              <div className={G.flexRow}>
                <input
                  type="number"
                  {...register('offsetDays', {
                    valueAsNumber: true,
                    min: { value: 0, message: translate('services.form.largerThanZero') },
                    max: {
                      value: 9999,
                      message: translate('services.form.maxValue'),
                    },
                    required: { value: true, message: translate('services.form.required') },
                  })}
                  defaultValue={0}
                />
                <span className={G.marginLeft.XXS}>{translate('sgw.requests.detail.info.days')}</span>
              </div>
              <ErrorLabel showError={!!errors?.offsetDays} message={errors?.offsetDays?.message} />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
