import { useLocation, useParams } from 'react-router';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { ManualPlacementApi } from '../store/api/manualPlacement.api';
import { extendPublicDomainIntake } from '../common/utils/location.util';
import { EDITABLE_STATES } from '../types';

export const useManualPlacementQuery = () => {
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const key = useMemo(() => new URLSearchParams(search).get('key')!, [search]);

  const { isLoading, data: request } = useQuery('manualPlacement', () => ManualPlacementApi.fetch(id, key), {
    select: (response) => {
      const { metadata, permitRequest } = response.data.data;
      return {
        ...permitRequest,
        locations: permitRequest.publicDomainIntakes.map((intake) => extendPublicDomainIntake(intake)),
        metadata,
        editable: EDITABLE_STATES.includes(permitRequest?.state.state),
      };
    },
  });

  return { isLoading, request };
};
