import { Actions, TableActions } from '../../common';
import { ISgwSetting } from '../../types';

class SgwSettingsActionsClass extends Actions {
  list = new TableActions<ISgwSetting>(`${this.prefix}.list`);
  reset = this.createPayloadAction<ISgwSetting>('reset');
  set = this.createPayloadAction<ISgwSetting>('set');
  saveList = this.createAction('saveList');
}

export const SgwSettingsActions = new SgwSettingsActionsClass('sgwSettings');
