import * as React from 'react';
import { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedDate, FormattedDateTime } from '../../common';
import { Loader } from '../../common/components/layout/loader.component';
import TourIcon from '../../common/components/tour-icon/tour-icon';
import { translate } from '../../common/translations/translate';
import { SortableList } from '../../components/molecules/SortableList/SortableList.component';
import { IRootState } from '../../root.state';
import { changeOrderReorderingWorkOrder } from '../store/reordering.actions';
import { IReorderingWorkOrder } from '../types/reordering-work-order';
import { IReorderingWorkOrderItem } from '../types/reordering-work-order-item';
import { ReorderingListItem } from './reordering-list-item';

export const ReorderingList: FunctionComponent = () => {
  const dispatch = useDispatch();
  const workOrder: IReorderingWorkOrder | null = useSelector((store: IRootState) => store.reordering.workOrder);
  const loading: boolean | null = useSelector((store: IRootState) => store.reordering.loading);

  const allChecked =
    workOrder &&
    workOrder.workOrderItems &&
    workOrder.workOrderItems.every((w: IReorderingWorkOrderItem) => !!w.checked);
  const updatedBy =
    workOrder && workOrder.updatedBy && workOrder.updatedBy.fullName ? workOrder.updatedBy.fullName : '/';

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    if (workOrder) {
      dispatch(changeOrderReorderingWorkOrder({ order: newIndex, workOrderItem: workOrder.workOrderItems[oldIndex] }));
    }
  };

  const renderWorkorderItems = (workOrderItems: IReorderingWorkOrderItem[]) => {
    return (
      workOrder && (
        <SortableList<IReorderingWorkOrderItem, {}>
          items={workOrderItems}
          // @ts-ignore
          renderItem={ReorderingListItem}
          onSort={onSortEnd}
        />
      )
    );
  };

  return (
    <div className="reordering-list-container">
      <Loader loading={!workOrder}>
        {workOrder && (
          <>
            <h2>
              {workOrder.teamName}
              <TourIcon
                checked={allChecked}
                color={workOrder.teamColor}
                tooltip={allChecked ? translate('allChecked') : translate('notAllChecked')}
                style={{ width: 20, paddingTop: 6 }}
              />
            </h2>
            <div className="reordering-list-date">
              <FormattedDate date={workOrder.date} />
            </div>
            <div className="reordering-list-updated-by">{translate('lastUpdatedBy') + ': ' + updatedBy}</div>
            <div className="reordering-list-updated-at">
              <FormattedDateTime dateTime={workOrder.updatedAt} />
            </div>
            <div className="reordering-list-item-container">
              <Loader loading={loading}>{renderWorkorderItems(workOrder.workOrderItems)}</Loader>
            </div>
          </>
        )}
      </Loader>
    </div>
  );
};
