import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { COLORS, FormattedDate, IPaging, ISorting } from '../../../common';
import { LabelChip } from '../../../common/components/layout/LabelChip.component';
import { GenericTablePanel } from '../../../common/components/table/GenericTablePanel.component';
import { appUrls } from '../../../common/config/url.constants';
import { UserActions } from '../../../common/store/user/user.actions';
import { getList, selectListLoading, selectUserTable } from '../../../common/store/user/user.selectors';
import { translate, translateIgnoreTS } from '../../../common/translations/translate';
import { IRole, IUser, IUserFilter } from '../../../common/types/user';
import { UtilityCompanyActions } from '../../../store/actions';
import { userInitialFilter } from '../../User.constants';
import { UsersFilterComponent } from './UsersFilterComponent';

const useStyles = makeStyles({
  nameFieldStyle: {
    overflow: 'auto',
    maxWidth: 120,
  },
  emailFieldStyle: {
    overflow: 'auto',
    maxWidth: 250,
  },
});

export const UsersList: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { paging, sorting, filters } = useSelector(selectUserTable);
  const loading = useSelector(selectListLoading);
  const usersList = useSelector(getList);
  const C = useStyles();

  useEffect(() => {
    dispatch(UserActions.list.fetch({ filters: {} }));
    dispatch(UserActions.fetchAllRoles());
    dispatch(UtilityCompanyActions.list.fetch({}));
  }, [dispatch]);

  const onChangeSorting = useCallback(
    (sorting: ISorting) => {
      dispatch(UserActions.list.fetch({ sorting }));
    },
    [dispatch],
  );

  const onChangePaging = useCallback(
    (paging: IPaging) => {
      dispatch(UserActions.list.fetch({ paging }));
    },
    [dispatch],
  );

  const onFilterChange = useCallback(
    (filters: IUserFilter): void => {
      dispatch(UserActions.list.fetch({ filters }));
    },
    [dispatch],
  );

  const resetFilters = useCallback((): void => {
    dispatch(UserActions.list.fetch({ filters: userInitialFilter }));
  }, [dispatch]);

  const onClick = useCallback(
    (user: IUser) => {
      history.push(appUrls.users.detail(`${user.id}`));
    },
    [history],
  );

  return (
    <>
      <GenericTablePanel<IUser>
        customHeader={
          <UsersFilterComponent filters={filters} changeFilters={onFilterChange} resetFilters={resetFilters} />
        }
        loading={loading}
        labelUnavailable={translate('users.list.unavailable')}
        onChangeSorting={onChangeSorting}
        onChangePaging={onChangePaging}
        onClick={onClick}
        paging={paging}
        sorting={sorting}
        records={usersList}
        title={translate('users.list.title')}
        columnKey="id"
        columns={[
          {
            label: translate('users.list.id'),
            name: 'id',
            sortable: true,
          },
          {
            label: translate('users.list.firstName'),
            name: 'firstName',
            sortable: true,
            className: C.nameFieldStyle,
          },
          {
            label: translate('users.list.lastName'),
            name: 'lastName',
            sortable: true,
            className: C.nameFieldStyle,
          },
          {
            label: translate('users.list.email'),
            name: 'email',
            sortable: true,
            className: C.emailFieldStyle,
          },
          {
            label: translate('users.list.source'),
            name: 'source',
            sortable: true,
            renderer: (source) => translateIgnoreTS(`users.filters.options.source.${source}`),
          },
          {
            label: translate('users.list.roles'),
            name: 'roles',
            renderer: (roles: IRole[]) =>
              roles
                .filter((r) => !r.derived)
                .map((r) => r.label)
                .join(', '),
          },
          {
            label: translate('users.list.lastLogin'),
            name: 'lastLogin',
            renderer: (date: string) => <FormattedDate date={date} />,
            sortable: true,
          },
          {
            label: translate('users.list.utilityCompany'),
            name: 'utilityCompany',
            renderer: (utilityCompany) => utilityCompany?.name,
          },
          {
            label: translate('users.list.deleted'),
            name: 'deleted',
            renderer: (deleted, user) =>
              deleted ? (
                <LabelChip color={COLORS.DARK_TERRA_COTTA} label={translate('users.list.deleted')} />
              ) : (
                <LabelChip color={COLORS.GREEN} label={translate('users.list.active')} />
              ),
            sortable: true,
          },
        ]}
      />
    </>
  );
};
