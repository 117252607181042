import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { ChangeEvent, FunctionComponent, useCallback, useEffect, useState } from 'react';
import {
  CarFreeZonesFilterType,
  DateFormat,
  ExemptionFilterState,
  ExemptionsFilterOnType,
  ExemptionsType,
  IExemptionsFilter,
} from '../../types';
import {
  exemptionsInitialFilter,
  getCarFreeZonesFilterTypeOptions,
  getExemptionFilterStateOptions,
  getExemptionTypeOptions,
} from '../CarFreeZone.constants';
import { MultiSelect } from '../../common';
import { ClearFiltersFab } from '../../common/components/buttons/ClearFiltersFab';
import { translate } from '../../common/translations/translate';
import { useDispatch, useSelector } from 'react-redux';
import { selectExemptionsTable } from '../../common/store/car-free-zones/carFreeZones.selectors';
import { CarFreeZonesActions } from '../../common/store/car-free-zones/carFreeZones.actions';
import { SearchTextComponent } from '../../request/components/overview/search-text-component';
import { AsignDatepicker } from '../../common/components/form-fields/datepicker/asign-datepicker.component';
import { Moment } from 'moment/moment';
import { getMoment } from '../../utils';

const useStyles = makeStyles({
  root: {
    marginBottom: 20,
  },
  resetButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 22,
  },
});

export const ExemptionsFilter: FunctionComponent = () => {
  const C = useStyles();
  const dispatch = useDispatch();
  const { filters } = useSelector(selectExemptionsTable);
  const [searchField, setSearchField] = useState<ExemptionsFilterOnType>(ExemptionsFilterOnType.licensePlate);
  const [searchValue, setSearchValue] = useState<string>('');
  const [stateFilter, setStateFilter] = useState<ExemptionFilterState[]>([]);

  const onFetchExemptions = (newFilterValues: IExemptionsFilter) =>
    dispatch(
      CarFreeZonesActions.exemptions.list.fetch({
        filters: {
          ...filters,
          ...newFilterValues,
        },
      }),
    );

  const resetFilters = useCallback((): void => {
    setSearchField(ExemptionsFilterOnType.licensePlate);
    setSearchValue('');
    setStateFilter([]);
    dispatch(CarFreeZonesActions.exemptions.list.fetch({ filters: exemptionsInitialFilter }));
  }, [dispatch]);

  const changeFilterOn = (e: ChangeEvent<any>) => {
    setSearchField(e.target.value);
    setSearchValue('');
  };

  const changeFilterOnValue = (e: ChangeEvent<any>) => {
    setSearchValue(e.target.value);
  };

  const isValidSearchField = () => searchValue.length === 0 || searchValue.length > 3;

  useEffect(() => {
    const active = stateFilter.length === 1 ? stateFilter.includes(ExemptionFilterState.active) : undefined;
    onFetchExemptions({ active });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateFilter]);

  const onSearch = () =>
    onFetchExemptions({
      [ExemptionsFilterOnType.licensePlate]:
        (ExemptionsFilterOnType.licensePlate === searchField && searchValue) || undefined,
      [ExemptionsFilterOnType.pincode]: (ExemptionsFilterOnType.pincode === searchField && searchValue) || undefined,
      [ExemptionsFilterOnType.zapper]: (ExemptionsFilterOnType.zapper === searchField && searchValue) || undefined,
      [searchField]: searchValue,
    });

  return (
    <Grid container spacing={1} justify="space-between" className={C.root}>
      <Grid item xs={3}>
        <SearchTextComponent<ExemptionsFilterOnType>
          allSearchFields={[
            {
              value: ExemptionsFilterOnType.licensePlate,
              label: translate('carFreeZones.exemptions.table.licensePlate'),
            },
            { value: ExemptionsFilterOnType.pincode, label: translate('carFreeZones.exemptions.table.pincode') },
            { value: ExemptionsFilterOnType.zapper, label: translate('carFreeZones.exemptions.table.zapper') },
          ]}
          title={translate('filterOn')}
          searchField={searchField}
          searchValue={searchValue}
          updateSearchField={changeFilterOn}
          updateSearchValue={changeFilterOnValue}
          error={!isValidSearchField()}
          saveSearchValue={onSearch}
        />
      </Grid>
      <Grid item xs={1}>
        <MultiSelect
          data={getExemptionTypeOptions()}
          displayProp={'label'}
          id="typeFilter"
          label={translate('carFreeZones.exemptions.table.type')}
          value={filters.type || []}
          valueChange={(type: ExemptionsType[]) => onFetchExemptions({ type })}
          valueProp={'value'}
        />
      </Grid>
      <Grid item xs={1}>
        <MultiSelect
          data={getCarFreeZonesFilterTypeOptions()}
          displayProp={'label'}
          id="zoneTypeFilter"
          label={translate('carFreeZones.exemptions.table.zoneType')}
          value={filters.zoneType || []}
          valueChange={(zoneType: CarFreeZonesFilterType[]) => onFetchExemptions({ zoneType })}
          valueProp={'value'}
        />
      </Grid>
      <Grid item xs={1}>
        <AsignDatepicker
          label={translate('carFreeZones.exemptions.filters.dateFrom')}
          value={getMoment(filters.dateFrom)}
          maxDate={getMoment(filters.dateUntil)}
          onChange={(selectedDate: Moment) =>
            onFetchExemptions({ dateFrom: selectedDate.format(DateFormat.reduxStoreDateString) })
          }
          options={{ popperPlacement: 'bottom-start', 'data-testid': 'dateFrom' }}
        />
      </Grid>
      <Grid item xs={1}>
        <AsignDatepicker
          label={translate('carFreeZones.exemptions.filters.dateUntil')}
          value={getMoment(filters.dateUntil)}
          minDate={getMoment(filters.dateFrom)}
          onChange={(selectedDate: Moment) =>
            onFetchExemptions({ dateUntil: selectedDate.format(DateFormat.reduxStoreDateString) })
          }
          options={{ popperPlacement: 'bottom-start', 'data-testid': 'dateUntil' }}
        />
      </Grid>
      <Grid item xs={1}>
        <MultiSelect
          data={getExemptionFilterStateOptions()}
          displayProp={'label'}
          id="stateFilter"
          label={translate('carFreeZones.krautli.list.filters.state.label')}
          value={stateFilter || []}
          valueChange={setStateFilter}
          valueProp={'value'}
        />
      </Grid>
      <Grid item xs={2} className={C.resetButton}>
        <ClearFiltersFab onClick={resetFilters} />
      </Grid>
    </Grid>
  );
};
