import React, { FunctionComponent } from 'react';
import { IStoredFile } from '../../../types';

// @ts-ignore
import Lightbox from 'react-awesome-lightbox';

interface IProps {
  images: IStoredFile[];
  index: number;
  onClose: () => void;
  visible?: boolean;
}

export const ImageCarousel: FunctionComponent<IProps> = ({ images, index, onClose, visible = false }) => {
  if (!visible) return null;

  const views = images.map((img: IStoredFile) => ({ url: img.url, title: img.url }));

  return <Lightbox images={views} startIndex={index} onClose={onClose} doubleClickZoom={0} zoomStep={1} />;
};
