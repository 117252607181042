import React, { FC } from 'react';
import { translate } from '../../../common/translations/translate';
import { useSelector } from 'react-redux';
import { PanelContent } from '../../../common/components/panel/panel-content.component';
import { Paper } from '@material-ui/core';
import { useGeneralStyles } from '../../../style/generalStyles';
import { Visible } from '../../../common/components/layout/Visible.component';
import { Unavailable } from '../../../common/components/formatters/unavailable';
import { getSgwRequestReschedulingsSortedById } from '../../../store/selectors/sgwRequestRescheduling.selectors';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import classNames from 'classnames';
import { atom, useAtom } from 'jotai';
import { PanelTitle } from '../../atoms';
import { ATOM_HASHES } from '../../../common/config/atom.constants';
import { atomWithHash } from 'jotai/utils';
import { RequestReschedulingModals, SgwReschedulingAccordion } from '../../molecules';

export const considerModalAtom = atom(false);
export const rejectModalAtom = atom(false);
export const applyModalAtom = atom(false);
export const reschedulingEditModeAtom = atomWithHash(ATOM_HASHES.reschedulings.editMode, false, { replaceState: true });

export const RequestReschedulings: FC = () => {
  const G = useGeneralStyles();
  const reschedulings = useSelector(getSgwRequestReschedulingsSortedById);
  const activeRescheduling = reschedulings[reschedulings.length - 1];
  const [, setRejectModal] = useAtom(rejectModalAtom);
  const [, setConsiderModal] = useAtom(considerModalAtom);
  const [, setApplyModal] = useAtom(applyModalAtom);
  const [editMode] = useAtom(reschedulingEditModeAtom);

  return (
    <>
      <Paper square className={G.marginTop20}>
        <PanelContent title={<PanelTitle>{translate('sgw.requests.detail.requestRescheduling.title')}</PanelTitle>}>
          <Visible
            visible={!!reschedulings.length}
            defaultComponent={
              <Unavailable text={translate('sgw.requests.detail.requestRescheduling.noReschedulings')} />
            }
          >
            {reschedulings?.map((rescheduling, index) => (
              <SgwReschedulingAccordion rescheduling={rescheduling} index={index} key={rescheduling.id} />
            ))}
            <div className={classNames(G.flexRowEnd, G.marginTop20)}>
              <Visible visible={!!activeRescheduling?.acl?.rejectReschedulingRequest}>
                <Button.Gray onClick={() => setRejectModal(true)} className={G.marginRight}>
                  {translate('sgw.requests.detail.requestRescheduling.rejectButton')}
                </Button.Gray>
              </Visible>
              <Visible visible={!!activeRescheduling?.acl?.considerReschedulingRequest}>
                <Button.Blue onClick={() => setConsiderModal(true)}>
                  {translate('sgw.requests.detail.requestRescheduling.considerButton')}
                </Button.Blue>
              </Visible>
              <Visible visible={!!activeRescheduling?.acl?.applyReschedulingRequest}>
                <Button.Blue onClick={(e) => setApplyModal(true)} disabled={editMode}>
                  {translate('sgw.requests.detail.requestRescheduling.applyButton')}
                </Button.Blue>
              </Visible>
            </div>
            <Visible visible={!!activeRescheduling?.acl?.applyReschedulingRequest}>
              <p className={classNames(G.infoText, G.textEnd)}>
                {translate('sgw.requests.detail.requestRescheduling.applyInfo')}
              </p>
            </Visible>
          </Visible>
          <RequestReschedulingModals />
        </PanelContent>
      </Paper>
    </>
  );
};
