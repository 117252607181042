import React, { FC } from 'react';
import { Route, Switch } from 'react-router';
import { appUrls } from '../../common/config/url.constants';
import { UtilityCompaniesPage, DetailUtilityCompanyPage, CreateUtilityCompanyPage } from '../pages';

export const UtilityCompaniesRoutes: FC = () => (
  <Switch>
    <Route exact path={appUrls.utilityCompanies.base}>
      <UtilityCompaniesPage />
    </Route>
    <Route exact path={appUrls.utilityCompanies.create}>
      <CreateUtilityCompanyPage />
    </Route>
    <Route exact path={appUrls.utilityCompanies.detail()}>
      <DetailUtilityCompanyPage />
    </Route>
    <Route exact path={appUrls.utilityCompanies.edit()}>
      <DetailUtilityCompanyPage isEditMode />
    </Route>
  </Switch>
);
