export const HOUR_IN_MS = 3600000;
export const THROTTLE = {
  keyboard: 150,
};

export const DEBOUNCE = {
  sendEmailAP: 5000,
};

export const NOTIFICATION_REFETCH_MS = 600000;

export const QUARTER_COST_REFETCH_MS = 1000;
export const QUARTER_COST_RETRY_REFETCH_MS = 5000;
export const SYNC_CONFLICTS_TIMEOUT_MS = 15000;
