import axios from 'axios';
import { CONFIG, IApiPromise, IPagedApiParams } from '../../common';
import { IUtilityCompany, IUtilityCompanyBE } from '../../types/utilityCompany.types';

const baseUtilityCompanyUrl = `${CONFIG.backOfficeApi}/v1/utility_companies`;

export const UtilityCompaniesApi = {
  fetchList: (params?: IPagedApiParams): IApiPromise<IUtilityCompany[]> => axios.get(baseUtilityCompanyUrl, { params }),
  save: (company: Partial<IUtilityCompanyBE>): IApiPromise<IUtilityCompany> => {
    const { id, ...companyData } = company;
    return id ? axios.patch(`${baseUtilityCompanyUrl}/${id}`, companyData) : axios.post(baseUtilityCompanyUrl, company);
  },
  fetch: (id: string): IApiPromise<IUtilityCompany> => axios.get(`${baseUtilityCompanyUrl}/${id}`),
};
