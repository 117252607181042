import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { genericErrorHandler } from '../..';
import { MoovActions } from './moov.actions';
import { MoovApi } from './moov.api';
import { getMoovTemplatesPagedApiParams } from './moov.selectors';
import { getPagingFromHeaders } from '../../utils/api.util';
import { ICall, ISelect } from '../../../types';

function* onFetchAll(): SagaIterator {
  const response: ICall<typeof MoovApi.fetchAll> = yield call(MoovApi.fetchAll);
  yield put(MoovActions.setAll(response!.data.data));
}

function* onFetchList(): SagaIterator {
  // TODO AS-5641: add loading behaviour
  const params: ISelect<typeof getMoovTemplatesPagedApiParams> = yield select(getMoovTemplatesPagedApiParams);

  const response: ICall<typeof MoovApi.fetchList> = yield call(MoovApi.fetchList, params!);

  yield all([
    put(MoovActions.list.set(response!.data.data)),
    put(MoovActions.list.setParams({ paging: getPagingFromHeaders(response as any) })),
  ]);
}

function* onSyncTemplates(): SagaIterator {
  yield put(MoovActions.setSyncTemplatesLoading(true));

  yield call(MoovApi.importTemplates);
  yield put(MoovActions.list.fetch({}));

  yield put(MoovActions.setSyncTemplatesLoading(false));
}

export function* moovSaga(): SagaIterator {
  yield takeLatest(MoovActions.syncTemplates.type, genericErrorHandler(onSyncTemplates));
  yield takeLatest(MoovActions.list.fetch.type, genericErrorHandler(onFetchList));
  yield takeLatest(MoovActions.fetchAll.type, genericErrorHandler(onFetchAll));
}
