import moment from 'moment';
import * as React from 'react';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Visible } from '../../common/components/layout/Visible.component';
import { appUrls } from '../../common/config/url.constants';
import { Auth } from '../../components';
import { useAuthorization } from '../../hooks';
import { DateFormat, IPlanningAclActions, IRequest, IWorkOrderItem } from '../../types';
import { PopupItem } from './PopupItem.component';
import { translate } from '../../common/translations/translate';

interface IProps {
  item: IWorkOrderItem;
  request?: IRequest;
}

export const WorkOrderItemType: FC<IProps> = ({ item, request }) => {
  const { isAuthorized: canEditPlanning } = useAuthorization(IPlanningAclActions.editPlanning);

  if (item.type === 'retrieve') {
    return (
      <p>
        <PopupItem label={'Sign:'}>
          <strong>
            <Link to={appUrls.signs.detail(item.sign!.hash)}>{item.sign!.hash}</Link>
          </strong>
        </PopupItem>
      </p>
    );
  } else if (request) {
    const {
      dateFrom,
      dateUntil,
      originalDateUntil,
      secondToLastDateUntil,
      id,
      referenceId,
      firstName,
      lastName,
      phoneNumber,
    } = request;
    /*
      As-5660:
        - current period start date = request dateFrom
        - current period end date = dateUntil of the *second to last* extension
        - extension end date = dateUntil of the *last* extension
      */
    return (
      <p>
        <PopupItem label={translate('planning.workOrderItem.currentPeriod')}>
          {moment(dateFrom).format(DateFormat.date) +
            ' - ' +
            moment(dateUntil === originalDateUntil ? dateUntil : secondToLastDateUntil || originalDateUntil).format(
              DateFormat.date,
            )}
        </PopupItem>
        <PopupItem visible={dateUntil !== originalDateUntil} label={translate('planning.workOrderItem.extensionUntil')}>
          {moment(dateUntil).format(DateFormat.date)}
        </PopupItem>
        <PopupItem label={translate('RequestId')}>{id}</PopupItem>
        <PopupItem label={translate('ReferenceId')}>{referenceId}</PopupItem>
        <Auth acl={IPlanningAclActions.editPlanning}>
          <Visible visible={canEditPlanning}>
            <PopupItem label={translate('Name')}>{`${firstName} ${lastName}`}</PopupItem>
            <PopupItem label={translate('PhoneNumber')}>{phoneNumber}</PopupItem>
          </Visible>
        </Auth>
      </p>
    );
  } else return null;
};
