import React, { ChangeEvent, FC, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { translate } from '../../../common/translations/translate';
import { Panel } from '../../../common';
import { SingleSelectV2Component } from '../../../common/components/form-fields/single-select-v2.component';
import { LabelValue } from '../../../common/components/layout/LabelValue.component';
import { useGeneralStyles } from '../../../style/generalStyles';
import { useDispatch, useSelector } from 'react-redux';
import { UserActions } from '../../../common/store/user/user.actions';
import { getSgwRequest, getSgwRequestAssignStatus } from '../../../store/selectors/sgwRequest.selectors';
import { SgwRequestActions } from '../../../store/actions';
import {
  ISgwRequestBE,
  ISgwRequestDetailAclActions,
  SgwRequestGisState,
  SgwRequestState,
  SgwRequestUserResponsibleType,
} from '../../../types';
import { CancelButton, ReviewButton } from '../../atoms';
import classNames from 'classnames';
import { Visible } from '../../../common/components/layout/Visible.component';
import { SgwRequestMotivationPanel } from '../../molecules';
import { useStyles } from './SgwRequestResponsiblePanel.styles';
import { AssignResponsibleControl } from './AssignResponsibleControl.component';
import { selectUser } from '../../../store/selectors';
import { SgwPermitDocumentsPanel } from '../../molecules/SgwPermitDocumentsPanel/SgwPermitDocumentsPanel.component';
import { useAuthorization } from '../../../hooks';

interface IProps {
  id: string;
}

export const SgwRequestResponsiblePanel: FC<IProps> = ({ id }) => {
  const C = useStyles();
  const G = useGeneralStyles();
  const dispatch = useDispatch();
  const request = useSelector(getSgwRequest(id));
  const currentUser = useSelector(selectUser);
  const { allowEditRequest, assignedUser, assignedToMe, assignedGisToMe, assignedMobilityAdvisorToMe } =
    useSelector(getSgwRequestAssignStatus);

  const gisStates = Object.entries(SgwRequestGisState).map(([value, label]) => ({ label, value }));
  const isInReviewState = request?.state === SgwRequestState.reviewing;
  const showMotivationalPanel = !!request?.motivationFieldPermit || !!allowEditRequest;
  const showAssignGis = isInReviewState && (assignedGisToMe || assignedToMe);
  const { isAuthorized: canEdit } = useAuthorization(ISgwRequestDetailAclActions.editSgwResponsiblePanel);

  useEffect(() => {
    dispatch(UserActions.fetchAdminList());
  }, [dispatch]);

  const onChange = (field: SgwRequestUserResponsibleType) => (id?: number) => id && patchRequest({ [field]: `${id}` });

  const patchRequest = (payload: Partial<ISgwRequestBE>) =>
    request && dispatch(SgwRequestActions.patch({ ...payload, id: request.id }));

  const onAssignRequest = (field: SgwRequestUserResponsibleType) => (toMe?: boolean) =>
    currentUser && patchRequest({ [field]: toMe ? `${currentUser.id}` : null });

  const onChangeGisStatus = (e: ChangeEvent<{ value: unknown }>) => patchRequest({ gisStatus: `${e.target.value}` });

  const defaultPlaceholder = `${translate('Requests.Header.assign.to')} ${translate('Requests.Header.assign.nobody')}`;

  return (
    <>
      <Panel
        data-testid="assignResponsiblePanel"
        title={
          <div className={C.titleContainer}>
            <div className={C.title}>{translate('sgw.requests.detail.responsiblePanel.title')}</div>
            <CancelButton />
            <ReviewButton />
          </div>
        }
        className={classNames(showMotivationalPanel && C.resetMargin)}
      >
        <Grid container spacing={2} className={C.paddingBottom}>
          <LabelValue
            labelCentered
            label={translate('sgw.requests.detail.responsiblePanel.generalDossierResponsible')}
            className={C.paddingTop}
          >
            <Visible visible={canEdit} defaultComponent={request?.userResponsible?.fullName || defaultPlaceholder}>
              <AssignResponsibleControl
                canAssign={!assignedToMe && !!request?.acl?.canAssign}
                canUnassign={!!request?.userResponsible && !!request?.acl?.canAssign}
                onChange={onChange(SgwRequestUserResponsibleType.userResponsible)}
                onAssignRequest={onAssignRequest(SgwRequestUserResponsibleType.userResponsible)}
                enabled={request?.acl?.canAssign}
                value={request?.userResponsible?.id}
                assignedUser={assignedUser}
              />
            </Visible>
          </LabelValue>
          <LabelValue
            labelCentered
            label={translate('sgw.requests.detail.responsiblePanel.gisResponsible')}
            className={C.paddingTop}
          >
            <Visible visible={canEdit} defaultComponent={request?.userGisResponsible?.fullName || defaultPlaceholder}>
              <AssignResponsibleControl
                canAssign={
                  !assignedGisToMe &&
                  !!request?.acl?.canAssignGisResponsible &&
                  !!currentUser?.acl?.sgwRequestAssignOneselfAsGisResponsible
                }
                canUnassign={!!request?.userGisResponsible && !!request?.acl?.canAssignGisResponsible}
                assignedUser={request?.userGisResponsible}
                onAssignRequest={onAssignRequest(SgwRequestUserResponsibleType.userGisResponsible)}
                onChange={onChange(SgwRequestUserResponsibleType.userGisResponsible)}
                enabled={
                  !!request?.acl?.canAssignGisResponsible &&
                  !!currentUser?.acl?.sgwRequestAssignAnotherPersonAsGisResponsible
                }
                value={request?.userGisResponsible?.id}
              />
            </Visible>
          </LabelValue>
          <Visible visible={!!request?.userGisResponsible}>
            <LabelValue labelCentered label={translate('sgw.requests.detail.responsiblePanel.gisState')}>
              <div className={G.flexRowCenter}>
                <Visible
                  visible={canEdit}
                  defaultComponent={gisStates.find(({ value }) => value === request?.gisStatus)?.label}
                >
                  <SingleSelectV2Component
                    className={C.autoComplete}
                    onChange={onChangeGisStatus}
                    menuItems={gisStates}
                    disabled={!showAssignGis}
                    value={request?.gisStatus}
                  />
                </Visible>
              </div>
            </LabelValue>
          </Visible>
          <LabelValue
            labelCentered
            label={translate('sgw.requests.detail.responsiblePanel.mobilityAdvisor')}
            className={C.paddingTop}
          >
            <Visible visible={canEdit} defaultComponent={request?.userMobilityAdvisor?.fullName || defaultPlaceholder}>
              <AssignResponsibleControl
                onChange={onChange(SgwRequestUserResponsibleType.userMobilityAdvisor)}
                value={request?.userMobilityAdvisor?.id}
                assignedUser={request?.userMobilityAdvisor}
                onAssignRequest={onAssignRequest(SgwRequestUserResponsibleType.userMobilityAdvisor)}
                canAssign={!assignedMobilityAdvisorToMe && !!request?.acl?.canAssignMobilityAdvisor}
                canUnassign={!!request?.userMobilityAdvisor && !!request?.acl?.canAssignMobilityAdvisor}
                enabled={!!request?.acl?.canAssignMobilityAdvisor}
              />
            </Visible>
          </LabelValue>
        </Grid>
      </Panel>
      <Visible visible={showMotivationalPanel}>
        <SgwRequestMotivationPanel id={id} className={classNames(C.resetMargin)} />
      </Visible>
      <SgwPermitDocumentsPanel id={id} />
    </>
  );
};
