import { combineReducers } from 'redux';
import { generateObjectReducer, generatePrimitiveReducer } from '../../common';
import { IReorderingWorkOrder } from '../types/reordering-work-order';
import { IReorderingWorkOrderItem } from '../types/reordering-work-order-item';
import {
  setReorderingHoveredWorkOrderItem,
  setReorderingLoading,
  setReorderingMarkerPopup,
  setReorderingWorkOrder,
} from './reordering.actions';
import { initialReorderingState } from './reordering.state';

export const reorderingReducer = combineReducers({
  hoveredWorkOrderItem: generateObjectReducer<IReorderingWorkOrder | null>(
    setReorderingHoveredWorkOrderItem.type,
    initialReorderingState.workOrder,
  ),
  loading: generatePrimitiveReducer<boolean>(setReorderingLoading.type, initialReorderingState.loading),
  markerPopup: generatePrimitiveReducer<IReorderingWorkOrderItem | null>(
    setReorderingMarkerPopup.type,
    initialReorderingState.markerPopup,
  ),
  workOrder: generateObjectReducer<IReorderingWorkOrder | null>(
    setReorderingWorkOrder.type,
    initialReorderingState.workOrder,
  ),
});
