import * as React from 'react';
import { initialSignsState } from '..';
import { Container, IPaging, ISorting, PanelAction } from '../../common';
import { Auth } from '../../components';
import { IParkingBanIntake, ISignsAclActions } from '../../types';
import { SignsOverview } from '../components/signs-overview.component';
import { SIGN_CONDITIONS, SIGN_FILTERS, SIGN_STATES } from '../signs.constants';
import { ISign } from '../types/sign';
import { ISignsFilter, TSignPredefinedFilter } from '../types/signs-filter';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  getSignsList,
  selectLoading,
  selectSignConditions,
  selectSignsCounters,
  selectSignStates,
  selectSignTypes,
  selectTable,
} from '../store/signs.selectors';
import { bulkDeleteSign, fetchSignCounters, fetchSignsFilterData, SignsActions } from '../store/signs.actions';
import { GenericTablePanel } from '../../common/components/table/GenericTablePanel.component';
import { IModule } from '../types/module';
import { translate } from '../../common/translations/translate';
import { SignsListFilters } from '../components/signs-list-filters.component';
import { ConfirmDialog } from '../../common/components/modal-dialog/confirm-dialog.component';
import { Edit } from '@material-ui/icons';
import { useToggle } from '../../hooks/useToggle.hook';
import { makeStyles } from '@material-ui/core';
import { appUrls } from '../../common/config/url.constants';

export const useStyles = makeStyles({
  editButton: {
    marginTop: '20px',
  },
});

export const SignsPage: FC = () => {
  const dispatch = useDispatch();
  const C = useStyles();

  const { push } = useHistory();
  const conditions = useSelector(selectSignConditions);
  const counters = useSelector(selectSignsCounters);
  const states = useSelector(selectSignStates);
  const types = useSelector(selectSignTypes);
  const signs = useSelector(getSignsList);
  const { filters, paging, sorting } = useSelector(selectTable);
  const loading = useSelector(selectLoading);

  const [isEditMode, toggleEditMode] = useToggle();
  const [signsToDelete, setSignsToDelete] = useState<ISign[]>([]);
  const [isBulkDeleteSignPopup, setBulkDeleteSignPopup] = useState<boolean>(false);
  const closeBulkDeletePopup = useCallback(() => setBulkDeleteSignPopup(false), []);

  const [selectedPredefinedFilter, setSelectedPredefinedFilter] = useState<TSignPredefinedFilter>();

  useEffect(() => {
    dispatch(fetchSignsFilterData());
    dispatch(SignsActions.list.fetch({}));
    dispatch(fetchSignCounters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPredefinedFilterChange = (newFilter: TSignPredefinedFilter) => {
    const filter: Partial<ISignsFilter> = {};
    if (selectedPredefinedFilter === newFilter) {
      setSelectedPredefinedFilter(undefined);
    } else {
      setSelectedPredefinedFilter(newFilter);

      switch (newFilter) {
        case SIGN_STATES.PLACED:
          filter.states = [newFilter, SIGN_STATES.MOVED];
          break;
        case SIGN_STATES.STORED:
        case SIGN_STATES.MISSING:
        case SIGN_STATES.MOVED:
          filter.states = [newFilter];
          break;
        case SIGN_CONDITIONS.DAMAGED:
        case SIGN_CONDITIONS.LOW_BATTERY:
          filter.conditions = [newFilter];
          break;
        case SIGN_FILTERS.NO_CONNECTION:
          filter.noConnection = true;
          break;
        case SIGN_FILTERS.NO_GPS:
          filter.noGPS = true;
          break;
        case SIGN_FILTERS.NOT_EXTENDED:
          filter.notExtended = true;
          break;
        case SIGN_FILTERS.REQUIRE_MAINTENANCE:
          filter.requireMaintenance = true;
          break;
      }
    }

    dispatch(
      SignsActions.list.fetch({
        filters: {
          ...initialSignsState.list.table.filters,
          ...filter,
        },
        paging: initialSignsState.list.table.paging,
      }),
    );
  };

  const onFilterChange = (filters: ISignsFilter) => {
    dispatch(
      SignsActions.list.fetch({
        filters,
        paging: initialSignsState.list.table.paging,
      }),
    );
  };

  const onSortingChange = (sorting: ISorting) => {
    dispatch(
      SignsActions.list.fetch({
        sorting,
      }),
    );
  };

  const onPagingChange = (paging: IPaging) => {
    dispatch(
      SignsActions.list.fetch({
        paging,
      }),
    );
  };

  const deleteSigns = (signs: ISign[]) => {
    setSignsToDelete(signs);
    setBulkDeleteSignPopup(true);
  };

  const onSignClick = (sign: ISign) => {
    push(appUrls.signs.detail(sign.hash));
  };

  const renderParkingBanAddress = (pbi: IParkingBanIntake | undefined): string => {
    return pbi ? `${pbi.street} ${pbi.streetNumberFrom} - ${pbi.streetNumberTo}` : '-';
  };

  return (
    <Auth acl={ISignsAclActions.viewSign} showUnauthorizedPage>
      <Container>
        <SignsOverview
          signCounters={counters}
          filter={selectedPredefinedFilter}
          filterChange={onPredefinedFilterChange}
        />
        <GenericTablePanel<ISign>
          title=" "
          customHeader={<PanelAction className={C.editButton} icon={<Edit />} onClick={toggleEditMode} />}
          filterNode={
            <SignsListFilters
              signConditions={conditions}
              inEditMode={isEditMode}
              signClick={onSignClick}
              signStates={states}
              signsFilter={filters}
              signsFilterChange={onFilterChange}
              signTypes={types}
            />
          }
          columnKey={'hash'}
          bulkActions={
            isEditMode ? [{ label: translate('bulkActions.removeSelectedSigns'), action: deleteSigns }] : undefined
          }
          onClick={onSignClick}
          columns={[
            { label: translate('SignID'), name: 'hash', sortable: true },
            {
              label: translate('ModuleID'),
              name: 'sigfoxDevice',
              renderer: (device?: IModule) => device?.id || '-',
              sortable: true,
            },
            { label: translate('Type'), name: 'type', renderer: (type) => type.formatted, sortable: true },
            { label: translate('Status'), name: 'state', renderer: (state) => state.name, sortable: true },
            {
              label: translate('RequestID'),
              name: 'parkingBanIntake',
              renderer: (intake) => intake?.permitRequestId || '-',
              sortable: true,
              sortKey: 'permitRequestId',
            },
            {
              label: translate('Location'),
              name: 'parkingBanIntake',
              renderer: (intake?: IParkingBanIntake) => renderParkingBanAddress(intake),
              sortable: true,
              sortKey: 'location',
            },
            {
              label: translate('ApplicationVersion'),
              name: 'sigfoxDevice',
              renderer: (sigfoxDevice?: IModule) => sigfoxDevice?.applicationVersion || '-',
              sortable: true,
              sortKey: 'applicationVersion',
            },
          ]}
          labelUnavailable={translate('signs.table.notFound')}
          records={signs}
          onChangeSorting={onSortingChange}
          onChangePaging={onPagingChange}
          sorting={sorting}
          paging={paging}
          loading={loading}
        />
      </Container>
      <ConfirmDialog
        visible={isBulkDeleteSignPopup}
        onClose={closeBulkDeletePopup}
        onConfirm={() => {
          setBulkDeleteSignPopup(false);
          dispatch(bulkDeleteSign(signsToDelete.map(({ hash }) => hash)));
        }}
        okButtonText={translate('signs.dialogs.bulkDeleteConfirm')}
        title={translate('signs.dialogs.bulkDeleteTitle')}
      >
        {translate('signs.dialogs.bulkDeleteMessage', { count: signsToDelete.length })}
      </ConfirmDialog>
    </Auth>
  );
};
