import { Grid, TextField } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import classNames from 'classnames';
import React, { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { Panel, PanelAction } from '../../../common';
import { CheckboxWithLabel } from '../../../common/components/form-fields/checkboxWithLabel.component';
import { Visible } from '../../../common/components/layout/Visible.component';
import { translate } from '../../../common/translations/translate';
import { useGeneralStyles } from '../../../style/generalStyles';
import { useSgwTrafficMeasureForm } from '../../../hooks';
import { appUrls } from '../../../common/config/url.constants';
import { useHistory } from 'react-router';
import { useFormStyles } from '../../../style/form.styles';
import { SubmitButton } from '../../atoms';
import { Feedback } from '../../../common/components/layout/Feedback.component';

interface IProps {
  title: string;
}

export const TrafficMeasureForm: FC<IProps> = ({ title }) => {
  const C = useFormStyles();
  const G = useGeneralStyles();
  const history = useHistory();
  const { formMethods, submitTrafficMeasure, id } = useSgwTrafficMeasureForm();
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = formMethods;
  const isEditMode = !!id;
  const deleted = watch('deleted');
  const existsInGipod = watch('existsInGipod');

  const goToDetail = () => history.push(appUrls.sgw.manage.trafficMeasures.detail(id));

  return (
    <FormProvider {...formMethods} data-testid="trafficMeasuresForm">
      <form id="createTrafficMeasure" onSubmit={submitTrafficMeasure} noValidate>
        <Panel title={title}>
          <Visible visible={isEditMode}>
            <PanelAction icon={<Edit />} data-testid="editButton" onClick={goToDetail} />
          </Visible>
          <Feedback.Info className={G.whiteSpaceBreak}>
            {translate('sgw.trafficMeasures.trafficMeasureInfo')}
          </Feedback.Info>
          <Grid container spacing={3} className={classNames(C.formGrid)}>
            <Grid item xs={12}>
              <TextField
                {...register('name', { required: true })}
                label={translate('sgw.trafficMeasures.columns.name')}
                className={G.fullWidth}
                required
                data-testid="nameField"
                error={!!errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('description')}
                label={translate('sgw.trafficMeasures.columns.description')}
                className={G.fullWidth}
                data-testid="descriptionField"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('gipodGuid', { required: true })}
                label={translate('sgw.trafficMeasures.columns.gipodGuid')}
                className={G.fullWidth}
                required
                error={!!errors.gipodGuid}
              />
            </Grid>
            <Grid item xs={12}>
              <CheckboxWithLabel
                {...register('existsInGipod')}
                checked={existsInGipod}
                label={translate('sgw.trafficMeasures.columns.existInGipod')}
                onChange={(checked: boolean) => setValue('existsInGipod', checked)}
                className={C.checkbox}
              />
            </Grid>
            <Grid item xs={12}>
              <CheckboxWithLabel
                {...register('deleted')}
                checked={deleted}
                label={translate('sgw.trafficMeasures.deactivate')}
                onChange={(checked: boolean) => setValue('deleted', checked)}
                className={C.checkbox}
              />
            </Grid>
          </Grid>
        </Panel>
        <SubmitButton formId="createTrafficMeasure" data-testid="createButton">
          {translate('sgw.trafficMeasures.saveTrafficMeasure')}
        </SubmitButton>
      </form>
    </FormProvider>
  );
};
