import {
  Actions,
  generateBaseActionCreator,
  generatePayloadActionCreator,
  TableActions,
  TBaseActionCreator,
  TPayloadActionCreator,
} from '../../common';
import {
  IRequest,
  IRequestExtension,
  IRequestExtensionPatch,
  IRequestMessage,
  IRequestMessageAttachments,
  IRequestMessagesList,
  IRequestMetadata,
  IRequestOverview,
  IRequestPatchApprove,
  IRequestPatchCluster,
  IRequestPatchInternalNotes,
  IRequestPatchPriority,
  IRequestPatchReject,
  IRequestReason,
  IRequestReasonFilter,
  IRequestsFilter,
  IUploadedFile,
  IFile,
  IRequestFormData,
  IRequestsHistoryApi,
  IRequestsPermitHistory,
  IRequestHistoryState,
  IFeature,
  IRequestPatchPublicDomainIntakes,
} from '../../types';

class RequestsActionsClass extends Actions {
  approve = this.createPayloadAction<IRequestPatchApprove>('approve');
  assign = this.createPayloadAction<string>('assign');
  attemptAssign = this.createPayloadAction<string>('attemptAssign');
  cancel = this.createPayloadAction<string>('cancel');
  export = this.createAction('exportList');
  fetch = this.createPayloadAction<number>('fetch');
  fetchDrafts = this.createPayloadAction<number>('fetchDrafts');
  remove = this.createPayloadAction<string>('remove');
  save = this.createPayloadAction<{ id?: string; formData: IRequestFormData; attachmentIds: string[] }>('save');
  fetchConflicts = this.createPayloadAction<string>('fetchConflicts');
  fetchEditableFields = this.createPayloadAction<string>('fetchEditableFields');
  fetchExtensions = this.createPayloadAction<string>('fetchExtensions');
  fetchHistory = this.createPayloadAction<IRequestsHistoryApi>('fetchHistory');
  fetchPermitHistory = this.createPayloadAction<IRequestsHistoryApi>('fetchPermitHistory');
  fetchMetadata = this.createAction('fetchMetadata');
  list = new TableActions<IRequestOverview, IRequestsFilter>(`${this.prefix}.list`);
  patchCluster = this.createPayloadAction<IRequestPatchCluster>('patchCluster');
  patchExtensions = this.createPayloadAction<IRequestExtensionPatch>('patchExtensions');
  patchInternalNotes = this.createPayloadAction<IRequestPatchInternalNotes>('patchInternalNotes');
  patchPriority = this.createPayloadAction<IRequestPatchPriority>('patchPriority');
  patchPublicDomainIntakes = this.createPayloadAction<IRequestPatchPublicDomainIntakes>('patchPublicDomainIntakes');
  regeneratePermit = this.createPayloadAction<number>('regeneratePermit');
  reject = this.createPayloadAction<IRequestPatchReject>('reject');
  removeFile = this.createPayloadAction<{ id: string; fileName: string }>('removeFile');
  resetPincode = this.createPayloadAction<string>('resetPincode');
  review = this.createPayloadAction<string>('review');
  sendApprovedMail = this.createPayloadAction<number>('sendApprovedMail');
  sendRetributionMail = this.createPayloadAction<number>('sendRetributionMail');
  set = this.createPayloadAction<IRequest | null>('set');
  setConflicts = this.createPayloadAction<{ id: string; conflicts: IFeature[] }>('setConflicts');
  setCopyRequest = this.createPayloadAction<IRequest | null>('setCopyRequest');
  setEditableFields = this.createPayloadAction<string[]>('setEditableFields');
  setExporting = this.createPayloadAction<boolean>('setExporting');
  setAlreadyAssigned = this.createPayloadAction<boolean>('setAlreadyAssigned');
  setExtensions = this.createPayloadAction<IRequestExtension[]>('setExtensions');
  setExtension = this.createPayloadAction<IRequestExtension>('setExtension');
  setHistory = this.createPayloadAction<IRequestHistoryState>('setHistory');
  setPermitHistory = this.createPayloadAction<IRequestsPermitHistory[]>('setPermitHistory');
  setCurrentConflictKey = this.createPayloadAction<number | null>('setCurrentConflictKey');
  setDrafts = this.createPayloadAction<IRequestOverview[]>('setDrafts');
  setLoading = this.createPayloadAction<boolean>('setLoading');
  setSaving = this.createPayloadAction<boolean>('setSaving');
  setMetadata = this.createPayloadAction<IRequestMetadata | null>('setMetadata');
  submit = this.createPayloadAction<string>('submit');
  unassign = this.createPayloadAction<string>('unassign');
  uploadFile = this.createPayloadAction<{ id: string; file: IFile }>('uploadFile');
  setGeoCodeCorrect = this.createPayloadAction<boolean>('setGeoCodeCorrect');
}

export const RequestActions = new RequestsActionsClass('requests');

export const fetchRequestReasons: TBaseActionCreator = generateBaseActionCreator('requests.fetchRequestReasons');

export const setRequestReasons: TPayloadActionCreator<IRequestReason[]> =
  generatePayloadActionCreator('requests.setRequestReasons');

export const fetchRequestReasonFilters: TBaseActionCreator = generateBaseActionCreator(
  'requests.fetchRequestReasonFilters',
);

export const setRequestReasonFilters: TPayloadActionCreator<IRequestReasonFilter[]> = generatePayloadActionCreator(
  'requests.setRequestReasonFilters',
);

export const fetchRequestMessages: TPayloadActionCreator<string> = generatePayloadActionCreator(
  'requests.fetchRequestMessages',
);

export const setRequestMessageAttachments: TPayloadActionCreator<IRequestMessageAttachments> =
  generatePayloadActionCreator('requests.setRequestMessage');

export const saveRequestMessage: TPayloadActionCreator<IRequestMessage> =
  generatePayloadActionCreator('requests.saveRequestMessages');

export const setRequestMessagesList: TPayloadActionCreator<IRequestMessagesList> = generatePayloadActionCreator(
  'requests.setRequestMessagesList',
);

export const resolveRequestMessages: TPayloadActionCreator<string> = generatePayloadActionCreator(
  'requests.resolveRequestMessages',
);

export const uploadMessageAttachment: TPayloadActionCreator<File> = generatePayloadActionCreator(
  'requests.uploadMessageAttachment',
);

export const updateMessageAttachment: TPayloadActionCreator<IUploadedFile> = generatePayloadActionCreator(
  'requests.updateMessageAttachment',
);

export const deleteMessageAttachment: TPayloadActionCreator<IUploadedFile> = generatePayloadActionCreator(
  'requests.deleteMessageAttachment',
);
