import moment from 'moment';
import { CONFIG, enumToArray, IMapLocation } from '../../common';
import { WORK_ORDER_ITEM_TYPES } from '../planning.constants';
import { ITeam, IMarkerPopup, IWorkOrder, IWorkOrderItem, IWorkOrderItemsFilter } from '../../types';
import { WorkOrderItemsFilterStorage } from '../../common/utils/filter-storage';

export interface IPlanningState {
  mapLocation: IMapLocation;
  teams: ITeam[];
  workOrders: IWorkOrder[];
  markerPopup: IMarkerPopup | null;
  workOrderItems: IWorkOrderItem[];
  workOrderItemsFilter: IWorkOrderItemsFilter;
  workOrderItemsLoading: boolean;
  workOrdersLoading: boolean;
}

export const initialPlanningState: IPlanningState = {
  mapLocation: {
    ...CONFIG.defaultMapLocation,
  },
  markerPopup: null,
  teams: [],
  workOrderItems: [],
  workOrderItemsFilter: WorkOrderItemsFilterStorage.get() || {
    date: moment().format('YYYY-MM-DD'),
    due_date: moment().add(1, 'days').format('YYYY-MM-DD'),
    due_date_from: moment().format('YYYY-MM-DD'),
    planned: null,
    priority: null,
    reasons: [],
    reference_number: '',
    sign_types: [],
    team_id: null,
    types: enumToArray(WORK_ORDER_ITEM_TYPES).filter((type) => type !== WORK_ORDER_ITEM_TYPES.PICKUP_OVERDUE),
  },
  workOrderItemsLoading: true,
  workOrders: [],
  workOrdersLoading: true,
};

export const initialPlanningFiltersForInstaller: IWorkOrderItemsFilter = WorkOrderItemsFilterStorage.get() || {
  date: moment().format('YYYY-MM-DD'),
  due_date_from: moment().format('YYYY-MM-DD'),
  due_date: moment().add(1, 'year').format('YYYY-MM-DD'),
  planned: true,
  priority: null,
  reasons: [],
  reference_number: '',
  sign_types: [],
  team_id: null,
  types: enumToArray(WORK_ORDER_ITEM_TYPES),
};
