import { useForm } from 'react-hook-form';
import { IPhaseAddress } from '../../types';

export const usePhaseLocationForm = (onAddAddress: (values: IPhaseAddress) => void) => {
  const formMethods = useForm<IPhaseAddress>();
  const { handleSubmit, reset } = formMethods;

  const _onSubmit = (values: IPhaseAddress) => {
    onAddAddress(values);
    reset();
  };

  return { onSubmit: handleSubmit(_onSubmit), formMethods };
};
