import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { COLORS } from '../../../common';
import { appUrls } from '../../../common/config/url.constants';
import { StorageActions } from '../../../store/actions';
import { IStoredFile, BulkDownloadTypes, IRouterWithId } from '../../../types';
import { isMimeTypeSupported } from '../../../utils';
import { openNewWindow } from '../../../utils/window.util';

interface IProps {
  editMode: boolean;
  fileStorage: IStoredFile;
}

const useStyles = createUseStyles({
  file: {
    color: COLORS.BLUE,
    '&:hover': {
      cursor: 'pointer',
    },
  },
});
export const FilePreviewer: FC<IProps> = ({ editMode, fileStorage }) => {
  const C = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams<IRouterWithId>();
  const openAttachmentPreviewer = () => {
    if (!editMode && isMimeTypeSupported(fileStorage.mimeType)) {
      openNewWindow(appUrls.attachments.base(true, fileStorage.url));
    } else {
      dispatch(
        StorageActions.bulkDownload({
          type: BulkDownloadTypes.sgwRequest,
          id: id,
          fileIds: [fileStorage.fileName],
          fileName: fileStorage.name,
        }),
      );
    }
  };

  return fileStorage?.name ? (
    <span onClick={openAttachmentPreviewer} className={C.file}>
      {fileStorage.name}
    </span>
  ) : null;
};
