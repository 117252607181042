import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { genericErrorHandler, TPayloadAction } from '../../common';

import {
  bulkProcessEvent,
  EventsActions,
  fetchEventsFilterData,
  initEventOverview,
  setEventErrors,
  setEventsFilterDataLoaded,
  setEventsLoading,
  setEventTypes,
} from './events.actions';
import { EventsApi } from './events.api';
import { ICall } from '../../types';
import { getEventsFilterDataLoaded, getPagedApiParams } from './events.selectors';
import { getPagingFromHeaders } from '../../common/utils/api.util';

function* onFetchEvents(): SagaIterator {
  const params = yield select(getPagedApiParams);
  const response: ICall<typeof EventsApi.fetchList> = yield call(EventsApi.fetchList, params);
  yield all([
    put(
      EventsActions.list.setParams({
        paging: getPagingFromHeaders(response as any),
      }),
    ),
    put(EventsActions.list.set(response!.data.data)),
  ]);
}

function* onFetchEventsFilterData(): SagaIterator {
  const dataLoaded = yield select(getEventsFilterDataLoaded);
  if (!dataLoaded) {
    const typesResponse: ICall<typeof EventsApi.getEventTypes> = yield call(EventsApi.getEventTypes);
    yield all([put(setEventTypes(typesResponse!.data.data)), put(setEventsFilterDataLoaded(true))]);
  }
}

function* onBulkProcessEvent(action: TPayloadAction<number[]>): SagaIterator {
  yield put(setEventsLoading(true));
  const updateResponse: ICall<typeof EventsApi.bulkProcessEvents> = yield call(
    EventsApi.bulkProcessEvents,
    action.payload,
  );

  if (updateResponse?.data.data?.errors?.length) {
    yield put(setEventErrors(updateResponse?.data.data?.errors));
  } else {
    yield call(refreshEvents);
  }

  yield put(setEventsLoading(false));
}

function* refreshEvents() {
  yield put(EventsActions.list.fetch({}));
}

export function* eventsSaga(): SagaIterator {
  yield takeLatest(EventsActions.list.fetch.type, genericErrorHandler(onFetchEvents));
  yield takeLatest(initEventOverview.type, genericErrorHandler(refreshEvents));
  yield takeLatest(bulkProcessEvent.type, genericErrorHandler(onBulkProcessEvent));
  yield takeLatest(fetchEventsFilterData.type, genericErrorHandler(onFetchEventsFilterData));
}
