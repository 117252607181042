import LocalStorage from '../../common/utils/local-storage';
import { IRequestsFilter, IWorkOrderItemsFilter } from '../../types';
import moment from 'moment';

class FilterStorage<T> {
  key: string;
  refreshDaily: boolean;

  constructor(key: string, refreshDaily: boolean = false) {
    this.key = key;
    this.refreshDaily = refreshDaily;
  }

  set = (value: T): void => {
    const userId = LocalStorage.get('userId');
    LocalStorage.set(`${this.key}-${userId}`, JSON.stringify(value));
    const storedDate = LocalStorage.get(`${this.key}-${userId}-date`);
    if (this.refreshDaily && !storedDate) {
      LocalStorage.set(`${this.key}-${userId}-date`, moment().format('YYYY-MM-DD'));
    }
  };

  get = (): T | null => {
    const userId = LocalStorage.get('userId');

    if (this.refreshDaily && LocalStorage.get(`${this.key}-${userId}-date`) !== moment().format('YYYY-MM-DD')) {
      this.remove();
      LocalStorage.set(`${this.key}-${userId}-date`, moment().format('YYYY-MM-DD'));
    }

    const storedString = LocalStorage.get(`${this.key}-${userId}`);
    return storedString ? JSON.parse(storedString) : null;
  };

  remove = (): void => {
    const userId = LocalStorage.get('userId');
    LocalStorage.remove(`${this.key}-${userId}`);
    if (this.refreshDaily) {
      LocalStorage.remove(`${this.key}-${userId}-date`);
    }
  };
}

export const WorkOrderItemsFilterStorage = new FilterStorage<IWorkOrderItemsFilter>('workOrderItemsFilter', true);
export const RequestFilterStorage = new FilterStorage<IRequestsFilter>('requestFilter');
