import * as React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
import { appUrls } from '../common/config/url.constants';
import { currentUserHasRoleOrAclAction } from '../common/store/user/user.selectors';
import { translate } from '../common/translations/translate';
import { Auth } from '../components';
import { RouterMenu } from '../components';
import { IServiceAclActions } from '../types';
import { CreateService } from './components/detail/edit/CreateService.component';
import { ServicesDetailPage } from './components/detail/ServicesDetail.page';
import { ServicesOverview } from './components/overview/ServicesOverview.container';
import { ServicesWhitelistPage } from './components/whitelist/ServicesWhitelist.page';

export const ServiceRoutes = () => {
  const viewServiceList = useSelector(currentUserHasRoleOrAclAction([], [IServiceAclActions.viewServiceList]));
  const viewWhiteList = useSelector(currentUserHasRoleOrAclAction([], [IServiceAclActions.viewWhitelistList]));

  return (
    <Auth acl={[IServiceAclActions.viewServiceList]}>
      <RouterMenu
        tabs={[
          { label: translate('services.menu.services'), route: appUrls.services.base, visible: viewServiceList },
          {
            label: translate('services.menu.whitelists'),
            route: appUrls.services.whitelist.base,
            visible: viewWhiteList,
          },
        ]}
      />
      <Switch>
        <Route exact path={[appUrls.services.whitelist.base]} component={ServicesWhitelistPage} />
        <Route exact path={[appUrls.services.create]} component={CreateService} />
        <Route exact path={[appUrls.services.base]} component={ServicesOverview} />
        <Route exact path={[appUrls.services.detail()]} component={ServicesDetailPage} />
      </Switch>
    </Auth>
  );
};
