import React, { FunctionComponent, useRef, useState } from 'react';
import * as L from 'leaflet';
import { Marker as LeafletMarker } from 'react-leaflet';
import { Markers } from './marker-icon';
import { IMarker } from '../../../types';

export const Marker: FunctionComponent<IMarker> = ({ color, ondrag, ondragstart, ondragend, ...props }) => {
  const markerRef = useRef<L.Marker>();
  const [icon] = useState(color ? Markers.WithColor(color) : Markers.Blue);

  markerRef?.current?.on('drag', (e) => ondrag?.(e));
  markerRef?.current?.on('dragstart', (e) => ondragstart?.(e));
  markerRef?.current?.on('dragend', (e) => ondragend?.(e));

  return <LeafletMarker ref={markerRef} icon={icon} {...props} />;
};
