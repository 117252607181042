import { Grid, makeStyles, Paper } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { FormattedDate, PanelAction } from '../../../common';
import { LabelValue } from '../../../common/components/layout/LabelValue.component';
import { PanelContent } from '../../../common/components/panel/panel-content.component';
import { translate } from '../../../common/translations/translate';
import { IRouterWithId, IUtilityCompanyAclActions } from '../../../types';
import { Auth } from '../../atoms';
import { useHistory, useParams } from 'react-router';
import { appUrls } from '../../../common/config/url.constants';
import { getUtilityCompany } from '../../../store/selectors/utilityCompanies.selectors';

const useStyles = makeStyles({
  gridContainer: {
    width: '100%',
  },
  selectedPrio: {
    color: 'white',
    backgroundColor: 'orange',
    '&:hover': {
      background: '#efefef',
    },
  },
  basePrio: {
    marginRight: '10px',
    textTransform: 'none',
    fontWeight: 500,
  },
  fitContent: {
    height: 'fit-content',
  },
  resetSpacing: {
    width: '100%',
    margin: '0 0 8px -8px',
  },
  bottomSpacing: {
    marginBottom: '1.1rem',
  },
  paperSpacing: {
    marginBottom: '20px',
  },
});

export const UtilityCompanyDetailInfo: FC = () => {
  const history = useHistory();
  const C = useStyles();
  const { id } = useParams<IRouterWithId>();
  const company = useSelector(getUtilityCompany(id));

  const goToEdit = () => history.push(appUrls.utilityCompanies.edit(id));

  return (
    <Paper square data-testid="UtilityCompanyDetailInfo" className={C.paperSpacing}>
      <PanelContent title={translate('utilityCompanies.detail.info.title')}>
        <Auth acl={IUtilityCompanyAclActions.editUtilityCompany}>
          <PanelAction icon={<Edit />} onClick={goToEdit} data-testid="ToggleButton" />
        </Auth>
        <Grid container spacing={2} className={C.resetSpacing}>
          <LabelValue label={translate('utilityCompanies.detail.info.id')}>{company?.id}</LabelValue>
          <LabelValue label={translate('utilityCompanies.table.name')}>{company?.name}</LabelValue>
          <LabelValue label={translate('utilityCompanies.detail.info.users')} visible={!!company?.users.length}>
            {company?.users?.map((user) => (user?.fullName ? <div key={user.id}>{user.fullName}</div> : null))}
          </LabelValue>
          <LabelValue label={translate('utilityCompanies.detail.info.created')}>
            <FormattedDate date={company?.createdAt} />
          </LabelValue>
          <LabelValue label={translate('utilityCompanies.detail.info.updated')}>
            <FormattedDate date={company?.updatedAt} />
          </LabelValue>
        </Grid>
      </PanelContent>
    </Paper>
  );
};
