import { createSelector } from '@reduxjs/toolkit';
import { createPagedApiParamsSelector } from '../../common/store/utils/generic.selectors';
import { createAllEntitiesSelector } from '../../common/utils/normalized.util';
import { IRootState } from '../../root.state';

export const selectAllIds = (store: IRootState) => store.sgw.partners.list.allIds;
export const selectById = (store: IRootState) => store.sgw.partners.list.byId;
export const selectSgwProjectsTable = (store: IRootState) => store.sgw.partners.list.table;
export const selectListLoading = (store: IRootState) => store.sgw.partners.list.loading;

export const getSgwPartnerList = createAllEntitiesSelector(selectAllIds, selectById);
export const getPagedApiParams = createPagedApiParamsSelector(selectSgwProjectsTable);
export const getSgwPartner = (id?: number) =>
  createSelector([selectById], (selectById) => (id ? selectById?.[id] : undefined));
