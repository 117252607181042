import { combineReducers } from 'redux';
import { ISgwTrafficMeasure, ISgwTrafficMeasuresFilter } from '../../../types';
import { createPagedTableReducer } from '../pagedTable.reducer';
import { SgwTrafficMeasuresActions } from '../../actions/sgwTrafficMeasures.actions';
import { initialSgwTrafficMeasuresState } from '../../state/trafficMeasures.state';
import { generateBooleanReducer } from '../../../common';

export const trafficMeasuresReducer = combineReducers({
  list: createPagedTableReducer<ISgwTrafficMeasure, ISgwTrafficMeasuresFilter>({
    fetchAction: SgwTrafficMeasuresActions.list.fetch.type,
    addEntitiesActions: [SgwTrafficMeasuresActions.list.set.type],
    addEntityActions: [SgwTrafficMeasuresActions.set.type],
    setAllIdsAction: SgwTrafficMeasuresActions.list.set.type,
    setParamsActions: [SgwTrafficMeasuresActions.list.fetch.type, SgwTrafficMeasuresActions.list.setParams.type],
    initialParamsState: initialSgwTrafficMeasuresState.list.table,
  }),
  loading: generateBooleanReducer([SgwTrafficMeasuresActions.setLoading.type], true),
});
