import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { CancelOutlined, EditOutlined, SaveOutlined } from '@material-ui/icons';
import React, { FC } from 'react';
import { ModalButton } from '../../../common/components/buttons/modal-button';
import { translate } from '../../../common/translations/translate';
import { useGeoDrawingListStyles } from '../../../style/GeoDrawingList.styles';

interface IProps {
  editMode: boolean;
  onCancel?(): void;
  onDelete?(): void;
  onEdit?(): void;
  onSave?(): void;
}

export const GeoDrawingListItemButtons: FC<IProps> = ({ editMode, onCancel, onEdit, onDelete, onSave }) => {
  const C = useGeoDrawingListStyles();

  return editMode ? (
    <Grid item xs={4} className={C.buttons}>
      <Tooltip title={translate('sgw.requests.detail.map.buttons.cancel')}>
        <IconButton data-testid={'GeoDrawingCancel'} onClick={onCancel}>
          <CancelOutlined color="error" />
        </IconButton>
      </Tooltip>
      <Tooltip title={translate('sgw.requests.detail.map.buttons.done')}>
        <IconButton type="submit" form="geoDrawingListItem" data-testid={'GeoDrawingSave'} onClick={onSave}>
          <SaveOutlined color="secondary" />
        </IconButton>
      </Tooltip>
    </Grid>
  ) : (
    <Grid item xs={4} className={C.buttons}>
      <Tooltip title={translate('sgw.requests.detail.map.buttons.edit')}>
        <IconButton data-testid={'GeoDrawingEdit'} onClick={onEdit}>
          <EditOutlined />
        </IconButton>
      </Tooltip>

      <ModalButton.DeleteGeoDrawing
        onConfirm={() => onDelete?.()}
        title={translate('sgw.requests.detail.map.deleteTitle')}
        buttonTitle={translate('sgw.requests.detail.map.buttons.delete')}
        needsReason={false}
        visible
        modalBody={translate('sgw.requests.detail.map.deleteConfirmation')}
      />
    </Grid>
  );
};
