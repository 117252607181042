import { Actions } from '../..';
import { ICostPatchState, IPayment, IPaymentPatchState } from '../../../types';

class PaymentActionsClass extends Actions {
  patchCostState = this.createPayloadAction<ICostPatchState>('patchCostState');
  patchState = this.createPayloadAction<IPaymentPatchState>('patchState');
  set = this.createPayloadAction<IPayment>('set');
}

export const PaymentActions = new PaymentActionsClass('payments');
