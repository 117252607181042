import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { genericErrorHandler } from '../../common';
import { getPagingFromHeaders } from '../../common/utils/api.util';
import { ICall, ISgwSetting } from '../../types';
import { SgwSettingsActions } from '../actions';
import { SgwSettingsApi } from '../api';
import { getSgwSettingsList } from '../selectors/sgwSettings.selectors';

function* onFetchSettings() {
  const response: ICall<typeof SgwSettingsApi.fetchList> = yield call(SgwSettingsApi.fetchList);
  yield put(SgwSettingsActions.list.set(response!.data.data));
  yield put(SgwSettingsActions.list.setParams({ paging: getPagingFromHeaders(response as any) }));
}

function* onSaveList(): SagaIterator {
  const settings: ISgwSetting[] = yield select(getSgwSettingsList);
  const response: ICall<typeof SgwSettingsApi.saveList> = yield call(SgwSettingsApi.saveList, settings);
  yield put(SgwSettingsActions.list.set(response!.data.data));
}

export function* sgwSettingsSaga(): SagaIterator {
  yield takeLatest(SgwSettingsActions.list.fetch.type, genericErrorHandler(onFetchSettings));
  yield takeLatest(SgwSettingsActions.saveList.type, genericErrorHandler(onSaveList));
}
