import assign_logo from '../assets/images/a-logo.svg';
import assign_user from '../assets/images/assign-user.svg';
import assign_user_inverted from '../assets/images/assign-user-inverted.svg';
import copy_icon from '../assets/images/icon-copy.svg';
import google_maps from '../assets/images/google-maps-icon.svg';
import request_export_icon_url from '../assets/images/request-export.svg';
import handle_internally from '../assets/images/handle-internally.svg';
import history from '../assets/images/history.svg';
import logout from '../assets/images/logout.svg';
import map_marker from '../assets/images/map-marker.svg';
import message_black_icon_url from '../assets/images/message-black.svg';
import message_green_icon_url from '../assets/images/message-green.svg';
import message_orange_icon_url from '../assets/images/message-orange.svg';
import pdf_icon_url from '../assets/images/pdf-icon.svg';
import reset_pincode from '../assets/images/reset-pincode.svg';
import unassign_user from '../assets/images/unassign-user.svg';
import urgent_black_icon_url from '../assets/images/icon-urgent-black.svg';
import urgent_icon_url from '../assets/images/icon-urgent.svg';
import conflicts from '../assets/images/conflicts.svg';
import info from '../assets/images/info.svg';
import partner_requestor from '../assets/images/partner-requestor.svg';
import partner_client from '../assets/images/partner-client.svg';
import partner_fee from '../assets/images/partner-fee.svg';
import partner_contractor from '../assets/images/partner-contractor.svg';
import partner_signalisation from '../assets/images/partner-signalisation.svg';
import conflict_generic from '../assets/images/conflict.generic.svg';
import conflict_bench from '../assets/images/conflict.bench.svg';
import conflict_bus from '../assets/images/conflict.bus.svg';
import conflict_calender from '../assets/images/conflict.calendar.svg';
import conflict_change from '../assets/images/conflict.change.svg';
import conflict_crane from '../assets/images/conflict.crane.svg';
import conflict_parking from '../assets/images/conflict.parking.svg';
import conflict_port from '../assets/images/conflict.port.svg';
import conflict_road_closure from '../assets/images/conflict.road-closure.svg';
import conflict_train from '../assets/images/conflict.train.svg';
import conflict_tree from '../assets/images/conflict.tree.svg';
import conflict_prohibited from '../assets/images/conflict.unavailable.svg';
import conflict_waste from '../assets/images/conflict.waste.svg';
import conflict_email from '../assets/images/conflict.email.svg';
import conflict_flag from '../assets/images/conflict.flag.svg';
import conflict_detour from '../assets/images/conflict.detour.svg';
import feedback_given from '../assets/images/lamp.svg';
import email from '../assets/images/email.svg';
import download from '../assets/images/download.svg';

export const ASSIGN_LOGO = assign_logo;
export const ASSIGN_USER = assign_user;
export const ASSIGN_USER_INVERTED = assign_user_inverted;
export const COPY_ICON = copy_icon;
export const GOOGLE_MAPS = google_maps;
export const HANDLE_INTERNALLY = handle_internally;
export const HISTORY = history;
export const LOGOUT = logout;
export const MAP_MARKER = map_marker;
export const MESSAGE_BLACK_ICON_URL = message_black_icon_url;
export const MESSAGE_GREEN_ICON_URL = message_green_icon_url;
export const MESSAGE_ORANGE_ICON_URL = message_orange_icon_url;
export const PDF_ICON_URL = pdf_icon_url;
export const REQUEST_EXPORT_ICON_URL = request_export_icon_url;
export const RESET_PINCODE = reset_pincode;
export const UNASSIGN_USER = unassign_user;
export const URGENT_BLACK_ICON_URL = urgent_black_icon_url;
export const URGENT_ICON_URL = urgent_icon_url;
export const CONFLICTS = conflicts;
export const INFO = info;
export const PARTNER_REQUESTOR = partner_requestor;
export const PARTNER_CLIENT = partner_client;
export const PARTNER_FEE = partner_fee;
export const PARTNER_CONTRACTOR = partner_contractor;
export const PARTNER_SIGNALISATION = partner_signalisation;
export const CONFLICT_GENERIC = conflict_generic;
export const CONFLICT_BENCH = conflict_bench;
export const CONFLICT_DETOUR = conflict_detour;
export const CONFLICT_BUS = conflict_bus;
export const CONFLICT_CALENDER = conflict_calender;
export const CONFLICT_CHANGE = conflict_change;
export const CONFLICT_CRANE = conflict_crane;
export const CONFLICT_PARKING = conflict_parking;
export const CONFLICT_PORT = conflict_port;
export const CONFLICT_ROAD_CLOSURE = conflict_road_closure;
export const CONFLICT_TRAIN = conflict_train;
export const CONFLICT_TREE = conflict_tree;
export const CONFLICT_PROHIBITED = conflict_prohibited;
export const CONFLICT_WASTE = conflict_waste;
export const CONFLICT_EMAIL = conflict_email;
export const CONFLICT_FLAG = conflict_flag;
export const FEEDBACK_GIVEN = feedback_given;
export const EMAIL = email;
export const DOWNLOAD = download;
