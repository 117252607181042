import { createUseStyles } from 'react-jss';
import { COLORS } from '../common';

export const useGeoDrawingListStyles = createUseStyles({
  addContainer: {
    border: `1px solid ${COLORS.GREY_LIGHTER}`,
    padding: '10px',
  },
  container: {
    height: '100px',
    border: `1px solid ${COLORS.GREY_LIGHTER}`,
    padding: '10px',
  },
  copyButton: {
    display: 'flex',
    justifyContent: 'center',
  },
  bold: {
    fontWeight: '500',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  info: {
    height: '100%',
  },
  type: {
    display: 'flex',
    gap: 5,
  },
});
