import { PriorityPicker } from '../../atoms/PriorityPicker/PriorityPicker.component';
import React, { FC, useCallback } from 'react';
import { Priority } from '../../../types/priority.types';
import { useDispatch } from 'react-redux';
import { SgwRequestActions } from '../../../store/actions';
import { useParams } from 'react-router';
import { IRouterWithId } from '../../../types';

interface IProps {
  prio: boolean;
  readOnly?: boolean;
}

export const SgwPriorityPicker: FC<IProps> = ({ prio, readOnly = false }) => {
  const priority = prio ? Priority.high : Priority.normal;
  const dispatch = useDispatch();
  const { id } = useParams<IRouterWithId>();

  const onSelectPriority = useCallback(
    (priority: Priority) => {
      id &&
        dispatch(
          SgwRequestActions.patch({
            id: Number(id),
            prio: priority === Priority.high,
          }),
        );
    },
    [dispatch, id],
  );

  return <PriorityPicker priority={priority} onSelectPriority={readOnly ? undefined : onSelectPriority} />;
};
