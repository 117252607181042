import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectSgwRequest } from '../../store/selectors/sgwRequest.selectors';
import { ISgwPhase } from '../../types';

export const useSgwPhaseForm = (phase?: ISgwPhase) => {
  const request = useSelector(selectSgwRequest);
  const formMethods = useForm<ISgwPhase>({
    defaultValues: {
      ...(phase || {
        dateFrom: request?.dateFrom,
        dateUntil: request?.dateFrom,
        timeFrom: '07:00',
        timeUntil: '17:00',
        workingHoursFrom: '07:00',
        workingHoursUntil: '17:00',
        exemptedSurfaceArea: 0,
      }),
    },
  });

  return { formMethods };
};
