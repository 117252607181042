import { IAclMatrix, ICountry } from '../../types';
import type { IUtilityCompany } from '../../types';
import type { IFilter } from './filter';

export const USER_ROLE_ACL = 'ROLE_ACL';
export const USER_ROLE_ACL_READ_ONLY = 'ROLE_ACL_READ_ONLY';
export const USER_ROLE_ACL_UTILITY_COMPANY = 'ROLE_ACL_UTILITY_COMPANY';
export const USER_ROLE_ACL_SUPER_ADMIN = 'ROLE_ACL_SUPER_ADMIN';

const SOURCE_M_PROFILE = 'astad-adfs';

export interface IUserData {
  birthday: string;
  firstName: string;
  lastName: string;
  email: string;
  country: ICountry | null;
  phoneNumber: string;
  street: string;
  streetNumber: string;
  bus: string;
  zipCode: string;
  city: string;
  companyId: string;
  companyName: string;
  ssn: string;
  ibanNumber: string;
  lastLogin: string;
  deleted?: boolean;
  createdAt?: string;
  updatedAt?: string;
  sourceId: string;
}

export interface IUserBE extends Omit<IUser, 'utilityCompany' | 'role' | 'roles'> {
  utilityCompany?: number;
  roles?: string[];
}

export interface IUser extends Partial<IUserData> {
  id: number;
  fullName: string;
  email: string;
  roles: IRole[];
  source: string;
  acl: IAclMatrix;
  applicationUser?: boolean;
  utilityCompany?: Omit<IUtilityCompany, 'users'>;
  role?: string;
}

export interface IRole {
  id: string;
  derived?: boolean;
  label: string;
}

export const userHasRole = (user: IUser | null | undefined, role: string): boolean =>
  !!user?.roles?.some((item: IRole) => item.id === role);

export const userHasAtLeastOneOfRoles = (user: IUser | null | undefined, roles: string[]): boolean =>
  !!user && roles.some((role) => userHasRole(user, role));

export const userHasAtLeastOneOfAcls = (user: IUser | null | undefined, acls: string[]): boolean =>
  !!user && acls.some((action) => user?.acl?.hasOwnProperty(action));

export const isOnlyRoleUtilities = (user: IUser | null | undefined): boolean => {
  return !!user?.utilityCompany;
};

export const isUtilitiesUser = (user: IUser | null | undefined) =>
  userHasRole(user, USER_ROLE_ACL_UTILITY_COMPANY) && !!user?.utilityCompany;

export const isReadOnlyUser = (user: IUser | null | undefined) => !user || userHasRole(user, USER_ROLE_ACL_READ_ONLY);

export const isBackofficeUser = (user: IUser | null | undefined) =>
  userHasAtLeastOneOfRoles(user, [USER_ROLE_ACL, USER_ROLE_ACL_READ_ONLY, USER_ROLE_ACL_SUPER_ADMIN]);

export const isMProfile = (user?: IUser | null): boolean => {
  return user?.source === SOURCE_M_PROFILE;
};

export interface IUserFilter extends IFilter {
  query?: string;
  sources?: string[];
  roles?: string[];
  utilityCompanyIds?: IUtilityCompany[];
  showDeleted?: boolean;
  showAvailableForSgwAdvisingParty?: boolean;
}

export interface IUserApi extends IUserFilter {
  page?: number;
  page_size?: number;
  sort?: string;
  order?: 'desc' | 'asc';
}

export enum UserSourceType {
  mprofile = 'astad-adfs',
  aprofiel = 'a-profiel',
  application = 'application',
}

export const userGetRoleNames = (user: IUser | null | undefined): string[] =>
  user?.roles?.filter((r) => !r.derived).map(({ label }) => label) || [];

export interface IUserRequestFilter {
  streetName?: string;
  fromDate?: string;
  untilDate?: string;
  userRequested?: number;
  userResponsible?: number;
  requestTypes?: string[];
  utilityCompany?: number;
}

export interface IUpdatePassword {
  code: string;
  newPassword: string;
  newPasswordConfirmation?: string;
}
