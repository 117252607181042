import { push } from 'connected-react-router';
import * as React from 'react';
import { IBatchState } from '..';
import { Connect, Container } from '../../common';
import MoveModulesDialog from '../components/dialog/move-modules-dialog.component';
import { ModulesList } from '../components/ModulesList.component';
import { ModulesOverview } from '../components/modules-overview.component';
import { fetchBatchForReorder, initModuleOverview, moveModules } from '../store/modules.actions';
import { IMovementBatch } from '../types/module-batch';
import { ModulePredefinedFilter } from '../types/modules-filter';
import { Auth } from '../../components';
import { IModulesAclActions } from '../../types';

interface IProps {
  batches: IBatchState;
  fetchBatchForReorder: typeof fetchBatchForReorder;
  moveModules?: typeof moveModules;
  initModuleOverview?: typeof initModuleOverview;
  modulesLoading?: boolean;
  push?: typeof push;
}

interface IState {
  predefinedFilter?: ModulePredefinedFilter | number;
  batchMovementPopupOpen: boolean;
}

@Connect(
  ({ modules }) => ({
    batches: modules.batches,
    modulesLoading: modules.modulesLoading,
  }),
  {
    fetchBatchForReorder,
    initModuleOverview,
    moveModules,
    push,
  },
)
export class ModulesPage extends React.Component<IProps, IState> {
  public state: IState = {
    batchMovementPopupOpen: false,
  };

  public componentDidMount(): void {
    this.props.initModuleOverview!();
  }

  public render(): React.ReactNode {
    return (
      <Auth acl={IModulesAclActions.viewModule} showUnauthorizedPage>
        <Container>
          <ModulesOverview onMoveModulesClick={this.onMoveModulesClick} />
          <ModulesList />
          {this.state.batchMovementPopupOpen && (
            <MoveModulesDialog
              onClose={this.onMoveModulesPopupClose}
              onConfirm={this.onMoveModules}
              batches={this.props.batches.batchesForReorder}
              fetchBatchForReorder={this.props.fetchBatchForReorder}
            />
          )}
        </Container>
      </Auth>
    );
  }

  private onMoveModulesClick = () => {
    this.setState({ batchMovementPopupOpen: true });
  };

  private onMoveModulesPopupClose = () => {
    this.setState({ batchMovementPopupOpen: false });
  };

  private onMoveModules = (count: number, batchFrom: IMovementBatch, batchTo: IMovementBatch) => {
    this.props.moveModules!({ count, batchFrom: batchFrom.id, batchTo: batchTo.id });
    this.setState({ batchMovementPopupOpen: false });
  };
}
