import { createReducer } from '@reduxjs/toolkit';
import { payloadReducer } from '../../..';
import { WorkOrderItemsActions } from '../work-order-items.actions';
import { IWorkOrderItemType } from '../../../../types';

type TState = IWorkOrderItemType[];

export default createReducer<TState>([], {
  [WorkOrderItemsActions.setCreatableTypes.type]: payloadReducer<TState>(),
});
