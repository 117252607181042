import { useForm } from 'react-hook-form';
import { IRouterWithId, ISgwPermitCondition } from '../../types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SgwPermitConditionsActions } from '../../store/actions';
import { useParams } from 'react-router';
import { getSgwPermitCondition } from '../../store/selectors/sgwPermitConditions.selectors';

export const useSgwPermitConditionForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams<IRouterWithId>();
  const initialValues = useSelector(getSgwPermitCondition(id));

  const formMethods = useForm<ISgwPermitCondition>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: { ...initialValues },
  });

  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    reset(initialValues);
  }, [initialValues, reset]);

  const _onSubmit = (values: ISgwPermitCondition) => dispatch(SgwPermitConditionsActions.save(values));

  const submitPermitCondition = handleSubmit(_onSubmit);

  return { formMethods, submitPermitCondition, id, initialValues };
};
