import * as React from 'react';
import { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedDate, IPaging, ISorting } from '../../common';
import { GenericTablePanel } from '../../common/components/table/GenericTablePanel.component';
import { multiLineCell } from '../../common/components/table/MultiLineCell.component';
import { appUrls } from '../../common/config/url.constants';
import { CarFreeZonesActions } from '../../common/store/car-free-zones/carFreeZones.actions';
import {
  getRequestListWithAddedFields,
  selectRequestsLoading,
  selectRequestTable,
} from '../../common/store/car-free-zones/carFreeZones.selectors';
import { getCanOpenRequestDetails } from '../../common/store/user/user.selectors';
import { translate } from '../../common/translations/translate';
import { IUser } from '../../common/types/user';
import { useRequestMetadata } from '../../hooks';
import { FeedbackIcon } from '../../request/components/icons/feedback-icon';
import { StatusIcon } from '../../request/components/icons/status-icon.component';
import { UrgentIcon } from '../../request/components/icons/urgent-icon';
import { IRequestData, IRequestOverview } from '../../types';
import { carFreeZoneRequestsAllowedStates } from '../CarFreeZone.constants';

interface IProps {
  id: string;
}

export const CarFreeZoneRequestListComponent: FC<IProps> = ({ id }) => {
  const dispatch = useDispatch();
  const requestListWithAddedFields = useSelector(getRequestListWithAddedFields);
  const canOpenRequestDetails = useSelector(getCanOpenRequestDetails);
  const metadata = useRequestMetadata();
  const { sorting, paging } = useSelector(selectRequestTable);
  const loading = useSelector(selectRequestsLoading);

  useEffect(() => {
    dispatch(
      CarFreeZonesActions.requestList.fetch({
        filters: { car_free_zone: [id], states: carFreeZoneRequestsAllowedStates },
      }),
    );
  }, [dispatch, id]);

  const onChangeSorting = useCallback(
    (sorting: ISorting) => {
      dispatch(CarFreeZonesActions.requestList.fetch({ sorting }));
    },
    [dispatch],
  );

  const onChangePaging = useCallback(
    (paging: IPaging) => {
      dispatch(CarFreeZonesActions.requestList.fetch({ paging }));
    },
    [dispatch],
  );

  const recordToLink = useCallback((record: IRequestOverview) => appUrls.requests.detail(record.id, true), []);

  return (
    metadata && (
      <GenericTablePanel<IRequestData>
        columnKey="id"
        title={translate('carFreeZones.krautli.details.list.title')}
        columns={[
          {
            label: <UrgentIcon.Header />,
            name: 'emergencyProcedure',
            renderer: (emergencyProcedure) => (emergencyProcedure ? <UrgentIcon /> : null),
          },
          {
            label: <FeedbackIcon.Header />,
            name: 'messageState',
            renderer: (messageState) => <FeedbackIcon messageState={messageState} />,
          },
          { label: translate('Requests.table.reference'), name: 'referenceId', sortable: true },
          {
            label: translate('Requests.table.createdAt'),
            name: 'createdAt',
            sortable: true,
            renderer: (createdAt: string) => <FormattedDate date={createdAt} />,
          },
          {
            label: translate('Requests.table.dateFrom'),
            name: 'dateFrom',
            sortable: true,
            renderer: (dateFrom: string) => <FormattedDate date={dateFrom} />,
          },
          {
            label: translate('Requests.table.dateUntil'),
            name: 'dateUntil',
            sortable: true,
            renderer: (dateUntil: string) => <FormattedDate date={dateUntil} />,
          },
          {
            label: translate('Requests.table.type'),
            name: 'types',
            sortable: false,
            renderer: (values: string[]) => multiLineCell(values),
          },
          {
            label: translate('Requests.table.locations'),
            name: 'fullAddresses',
            sortable: false,
            renderer: (values: string[]) => multiLineCell(values),
          },
          { label: translate('Requests.table.eventName'), name: 'eventName', sortable: true },
          {
            label: translate('Requests.table.userResponsible'),
            name: 'userResponsible',
            sortable: true,
            renderer: (userResponsible: IUser | undefined) => userResponsible?.fullName,
          },
          {
            label: translate('Requests.table.state'),
            name: 'state',
            sortable: true,
            renderer: (state) => <StatusIcon statusState={state.state} metadataStates={metadata?.states} />,
          },
        ]}
        labelUnavailable={translate('carFreeZones.krautli.details.list.unavailable')}
        onChangeSorting={onChangeSorting}
        onChangePaging={onChangePaging}
        paging={paging}
        sorting={sorting}
        records={requestListWithAddedFields}
        recordToLink={canOpenRequestDetails ? recordToLink : undefined}
        loading={loading}
      />
    )
  );
};
