import { Grid, Paper } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { CONFIG } from '../../../common';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { Loader } from '../../../common/components/layout/loader.component';
import { Visible } from '../../../common/components/layout/Visible.component';
import { MPROFILE_CLIENT_ID, MPROFILE_URL } from '../../../common/config/environment';
import { appUrls } from '../../../common/config/url.constants';
import { translate } from '../../../common/translations/translate';
import { useOauthAccessToken } from '../../../hooks';
import { isAppFeatureEnabled } from '../../../store/selectors';
import { useLoginStyles } from './Login.styles';

export const LoginSelectionPage: FC = () => {
  const C = useLoginStyles();
  const history = useHistory();
  const isDigipolisLoginEnabled = useSelector(isAppFeatureEnabled.integrateDigipolisLogin);
  const accessToken = useOauthAccessToken();

  const asignLoginClick = () => history.push(appUrls.login.base);
  const adLoginClick = () => {
    const redirect_uri = encodeURI(window.location.origin + appUrls.login.oauthMprofile);
    const url = `${MPROFILE_URL}/v1/authorize?state=mprofile_login&response_type=token&save_consent=true&client_id=${MPROFILE_CLIENT_ID}&service=astad.mprofiel.v1&scope=all&redirect_uri=${redirect_uri}`;
    window.location.href = url;
  };

  return (
    <Container>
      <Paper square data-testid="unauthorizedPage" className={C.loginWrapper}>
        <div className={C.centered}>
          <img alt={translate('login.image')} className={C.image} src={CONFIG.logoUrl} />
          <h1>{translate('login.title')}</h1>
          <Grid container justify="center" className={C.buttonsWrapper}>
            <Visible visible={!accessToken}>
              <Grid item xs={6}>
                <Button.Blue type="submit" className={C.button} onClick={asignLoginClick}>
                  {translate('login.asignLogin')}
                </Button.Blue>
              </Grid>
              <Visible visible={isDigipolisLoginEnabled}>
                <Grid item xs={6} className={C.digipolisLoginButtonWrapper}>
                  <Button.Blue type="submit" className={C.button} onClick={adLoginClick}>
                    {translate('login.digipolisLogin')}
                  </Button.Blue>
                </Grid>
              </Visible>
            </Visible>
            <Visible visible={!!accessToken}>
              <Grid item xs={12}>
                <Loader />
              </Grid>
            </Visible>
          </Grid>
        </div>
      </Paper>
    </Container>
  );
};
