import React, { FunctionComponent, useCallback } from 'react';
import { IFileLink } from '../../../types';
import { Close } from '@material-ui/icons';
import { Loader } from '../layout/loader.component';
import { Checkbox } from '../form-fields/checkbox.component';
import { Tooltip } from '@material-ui/core';
import I18n from 'i18n-js';

interface IProps extends IFileLink {
  removeByField: 'fileName' | 'id';
}

export const FileLink: FunctionComponent<IProps> = ({
  fileName,
  id,
  loading = false,
  name,
  onRemove,
  onSelect,
  selected,
  url,
  removeByField = 'fileName',
}) => {
  const _onRemove = useCallback(() => {
    if (onRemove) {
      switch (removeByField) {
        case 'fileName':
          fileName && onRemove(fileName);
          break;
        case 'id':
          id && onRemove(`${id}`);
          break;
      }
    }
  }, [fileName, onRemove, id, removeByField]);

  const _onSelect = useCallback(() => {
    id && onSelect && onSelect(`${id}`);
  }, [id, onSelect]);

  return (
    <div style={styles.root} data-testid="FileLink">
      {onSelect && <Checkbox checked={selected} onClick={_onSelect} style={styles.checkbox} />}
      <a href={url} target="_blank" rel="noreferrer">
        {name}
      </a>
      <Loader loading={loading} small style={styles.icon}>
        {onRemove ? (
          <Tooltip title={I18n.translate('FileBox.delete')}>
            <Close
              fontSize={'small'}
              color={'error'}
              data-testid="FileLinkRemoveButton"
              onClick={_onRemove}
              style={styles.icon}
            />
          </Tooltip>
        ) : (
          <div />
        )}
      </Loader>
    </div>
  );
};

const styles = {
  root: {
    backgroundColor: '#e4e4e4',
    borderRadius: '16px',
    display: 'inline-block',
    lineHeight: '32px',
    margin: '5px 5px 5px 0',
    padding: '0 12px',
  },
  checkbox: {
    backgroundColor: '#e0e0e0',
    verticalAlign: 'middle',
    marginRight: '10px',
  },
  icon: {
    marginLeft: '10px',
    verticalAlign: 'middle',
  },
};
