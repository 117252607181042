import { createUseStyles } from 'react-jss';
import { COLORS } from '../../../common';

export const useSgwConflictAccordionStyles = createUseStyles({
  actionButtons: {
    display: 'flex',
    width: '86px',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  accordion: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  image: {
    width: '54px',
    height: '54px',
    margin: '20px 40px 0px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '6px',
    boxShadow: `0px 1px 3px 1px ${COLORS.GREY_LIGHT}`,
  },
  mapMarker: {
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    borderRadius: '10px',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
    minWidth: 'unset',
    marginRight: '10px',
  },
  mapMarkerIcon: {
    width: '30px',
  },
  panel: {
    width: '100%',
    marginTop: '16px',
    boxShadow: `0px 1px 3px 1px ${COLORS.GREY_LIGHT}`,
  },
  pressedButton: {
    background: `${COLORS.BLUE_LIGHTER} !important`,
    boxShadow: 'none',
    border: `1px solid ${COLORS.GREY}`,
  },
  icon: {
    width: '100%',
    maxWidth: '40px',
  },
  flagIcon: {
    width: '20px',
    marginRight: '10px',
  },
  line: {
    height: '2px',
    background: COLORS.GREY_LIGHTER,
    color: COLORS.GREY_LIGHTER,
    borderRadius: '2px',
    margin: '0 15px',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    maxWidth: '75vw',
  },
  sendEmail: {
    display: 'flex',
    alignItems: 'center',
    background: `${COLORS.BLUE_LIGHTER} !important`,
    padding: '10px',
    borderRadius: '10px',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
    minWidth: 'unset',
  },
  sendEmailIcon: {
    width: '20px',
    height: '20px',
  },
  summary: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },

  summaryWrapper: {
    maxHeight: '65px',
    background: 'none !important',
  },
});
