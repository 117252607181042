import { IApiResponseBody } from '../types/api';
import { IPaging } from '../types/paging';
import { initialPaging } from '../config/paging.constants';

export const getPagingFromHeaders = (response: IApiResponseBody<any>): IPaging => ({
  page: parseInt(response.headers?.['x-rombit-pagination-page'], 10) || initialPaging.page,
  pageSize: parseInt(response.headers?.['x-rombit-pagination-pagesize'], 10) || initialPaging.pageSize,
  totalPages: parseInt(response.headers?.['x-rombit-pagination-totalpages'], 10) || initialPaging.totalPages,
  totalRecords: parseInt(response.headers?.['x-rombit-pagination-totalrecords'], 10) || initialPaging.totalRecords,
});
