import { IRootState } from '../../../root.state';
import { createAllEntitiesSelector } from '../../utils/normalized.util';
import { createPagedApiParamsSelector } from '../utils/generic.selectors';
import { createSelector } from '@reduxjs/toolkit';

export const selectSyncTemplatesLoading = (store: IRootState) => store.moov.syncTemplatesLoading;
export const selectAllIds = (store: IRootState) => store.moov.list.allIds;
export const selectById = (store: IRootState) => store.moov.list.byId;
export const selectMoovTable = (store: IRootState) => store.moov.list.table;

export const getMoovTemplatesList = createAllEntitiesSelector(selectAllIds, selectById);
export const getMoovTemplatesAll = createSelector([selectById], (templates) =>
  Object.keys(templates).map((id) => templates[id]),
);
export const getMoovTemplatesPagedApiParams = createPagedApiParamsSelector(selectMoovTable);
