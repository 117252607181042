import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FormattedDateTime, IPaging, ISorting } from '../../../common';
import { SyncButton } from '../../../common/components/buttons/SyncButton.component';
import { GenericTablePanel } from '../../../common/components/table/GenericTablePanel.component';
import { appUrls } from '../../../common/config/url.constants';
import { ServicesActions } from '../../../common/store/services/Services.actions';
import {
  getList,
  selectLatestAPTRSync,
  selectSyncingAPTR,
  selectTable,
} from '../../../common/store/services/Services.selectors';
import { translate, translateIgnoreTS } from '../../../common/translations/translate';
import { Auth } from '../../../components';
import { useGeneralStyles } from '../../../style/generalStyles';
import { IServiceAclActions } from '../../../types';
import { IPincode, IService, IServiceFilter, VehicleSource } from '../../../types';
import { serviceInitialFilter } from '../../Service.constants';
import { ServicesFilterComponent } from './ServicesFilterComponent';

const useStyles = makeStyles({
  syncAPTR: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
});

export const ServicesListComponent: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { paging, sorting, filters } = useSelector(selectTable);
  const services = useSelector(getList);
  const isSyncing = useSelector(selectSyncingAPTR);
  const latestAPTRSync = useSelector(selectLatestAPTRSync);
  const G = useGeneralStyles();
  const C = useStyles();

  useEffect(() => {
    dispatch(ServicesActions.list.fetch({}));
  }, [dispatch]);

  const onChangeSorting = useCallback(
    (sorting: ISorting) => {
      dispatch(ServicesActions.list.fetch({ sorting }));
    },
    [dispatch],
  );

  const onChangePaging = useCallback(
    (paging: IPaging) => {
      dispatch(ServicesActions.list.fetch({ paging }));
    },
    [dispatch],
  );

  const onFilterChange = useCallback(
    (filters: IServiceFilter): void => {
      dispatch(ServicesActions.list.fetch({ filters }));
    },
    [dispatch],
  );

  const resetFilters = useCallback((): void => {
    dispatch(ServicesActions.list.fetch({ filters: serviceInitialFilter }));
  }, [dispatch]);

  const onClick = useCallback(
    (service: IService) => {
      history.push(appUrls.services.detail(`${service.id}`));
    },
    [history],
  );

  const syncAPTR = useCallback((): void => {
    dispatch(ServicesActions.aptr.sync());
  }, [dispatch]);

  return (
    <>
      <GenericTablePanel<IService>
        customHeader={
          <ServicesFilterComponent filters={filters} changeFilters={onFilterChange} resetFilters={resetFilters} />
        }
        labelUnavailable={translate('services.list.unavailable')}
        onChangeSorting={onChangeSorting}
        onChangePaging={onChangePaging}
        onClick={onClick}
        paging={paging}
        sorting={sorting}
        records={services}
        title={
          <div className={G.flexRowSpaceBetween}>
            <span>{translate('services.list.title')}</span>
            <div className={C.syncAPTR}>
              <Auth acl={[IServiceAclActions.syncAPTR]}>
                <SyncButton isSyncing={isSyncing} onClick={syncAPTR}>
                  {translate('services.list.syncAPTR')}
                </SyncButton>
              </Auth>
              <div className={G.infoText}>
                {translate('services.list.latestSyncAPTR', { timestamp: latestAPTRSync })}
              </div>
            </div>
          </div>
        }
        columnKey="id"
        columns={[
          {
            label: translate('services.list.id'),
            name: 'id',
            sortable: true,
          },
          {
            label: translate('services.list.name'),
            name: 'name',
            sortable: true,
          },
          {
            label: translate('services.list.numberOfLicensePlates'),
            name: 'numberOfLicensePlates',
            sortable: true,
            renderer: (numberOfLicensePlates) => numberOfLicensePlates || '',
          },
          {
            label: translate('services.list.numberOfZappers'),
            name: 'numberOfZappers',
            sortable: true,
            renderer: (numberOfZappers) => numberOfZappers || '',
          },
          {
            label: translate('services.list.pincodeValidDate'),
            name: 'currentPincode',
            renderer: (pincode?: IPincode, service?: IService) => {
              if (!pincode && !service?.prevPincode) {
                return translate('services.list.noPincode');
              }
              if (pincode) {
                return pincode.dateUntil ? (
                  <FormattedDateTime dateTime={pincode.dateUntil} />
                ) : (
                  translate('services.list.noExpiration')
                );
              }
              if (!pincode && service?.prevPincode) {
                return translate('services.list.expiredPincode');
              }
            },
            sortable: true,
          },
          {
            label: translate('services.list.syncs'),
            name: 'id',
            renderer: (id: number, record?: IService) => {
              let services = [];
              if (record?.syncWithMoov) {
                services.push(translate('services.sync.moov'));
              }
              if (record?.syncWithZoneguard) {
                services.push(translate('services.sync.zoneguard'));
              }
              return services.join('; ');
            },
            sortable: false,
          },
          {
            label: translate('services.list.vehicleSource'),
            name: 'vehicleSource',
            renderer: (vehicleSource) =>
              translateIgnoreTS(`services.form.vehicleSource.options.${vehicleSource || VehicleSource.whitelist}`),
            sortable: false,
          },
        ]}
      />
    </>
  );
};
