import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getWhitelistEntryList,
  selectWhitelistEntryListTable,
} from '../../../common/store/services/Services.selectors';
import { ServicesActions } from '../../../common/store/services/Services.actions';
import { IPaging, ISorting } from '../../../common';
import { IAptrUsageCode, IWhitelistEntry, IWhitelistEntryListFilter, VehicleSource } from '../../../types';
import { GenericTablePanel } from '../../../common/components/table/GenericTablePanel.component';
import { WhitelistEntryListFilter } from './WhitelistEntryListFilter.component';
import { translate, translateIgnoreTS } from '../../../common/translations/translate';

interface IProps {
  vehicleSource?: VehicleSource;
}

export const WhitelistEntryList: FC<IProps> = ({ vehicleSource }) => {
  const dispatch = useDispatch();
  const { paging, sorting } = useSelector(selectWhitelistEntryListTable);
  const whitelistEntries = useSelector(getWhitelistEntryList);

  const onChangeSorting = useCallback(
    (sorting: ISorting) => {
      dispatch(ServicesActions.whitelistEntriesList.fetch({ sorting }));
    },
    [dispatch],
  );

  const onChangePaging = useCallback(
    (paging: IPaging) => {
      dispatch(ServicesActions.whitelistEntriesList.fetch({ paging }));
    },
    [dispatch],
  );

  const onChangeFiltering = useCallback(
    (filters: IWhitelistEntryListFilter) => {
      dispatch(ServicesActions.whitelistEntriesList.fetch({ filters }));
    },
    [dispatch],
  );

  const getColumns = useCallback(() => {
    const columns = [
      {
        label: translate('services.whitelists.entries.table.value'),
        name: 'value',
        sortable: true,
      },
      {
        label: translate('services.whitelists.entries.table.type'),
        name: 'type',
        renderer: (type: string) => translateIgnoreTS(`services.whitelists.entries.types.${type}`),
        sortable: true,
      },
    ];

    if (vehicleSource === VehicleSource.aptr) {
      columns.push({
        label: translate('services.whitelists.entries.table.aptrUsageCodes'),
        name: 'aptrUsageCodes',
        // @ts-ignore
        renderer: (aptrUsageCodes: IAptrUsageCode[]) => aptrUsageCodes.map(({ usageCode }) => usageCode).join(', '),
        sortable: false,
      });
    } else {
      columns.push({
        label: translate('services.whitelists.entries.table.remark'),
        name: 'description',
        sortable: true,
      });
    }
    return columns;
  }, [vehicleSource]);

  return (
    <GenericTablePanel<IWhitelistEntry>
      filterNode={<WhitelistEntryListFilter onChange={onChangeFiltering} />}
      labelUnavailable={translate('services.list.unavailable')}
      onChangeSorting={onChangeSorting}
      onChangePaging={onChangePaging}
      paging={paging}
      sorting={sorting}
      records={whitelistEntries}
      title={translate('services.whitelists.entries.title')}
      columnKey="id"
      // @ts-ignore
      columns={getColumns()}
    />
  );
};
