import React, { FC, useCallback, useEffect, useState } from 'react';
import { IWhitelistEntryListFilter, VehicleSource } from '../../../types/services.types';
import { useParams } from 'react-router';
import { Grid, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { MultiSelect } from '../../../common';
import { getWhitelistEntryListTypeFilter } from '../../Service.constants';
import { useSelector } from 'react-redux';
import { getService, getServiceAptrUsageCodes } from '../../../common/store/services/Services.selectors';
import { Visible } from '../../../common/components/layout/Visible.component';
import { translate } from '../../../common/translations/translate';

export interface IProps {
  onChange(filter: IWhitelistEntryListFilter): void;
}

interface IParams {
  id: string;
}

const useStyles = makeStyles({
  root: { marginBottom: 20 },
});

export const WhitelistEntryListFilter: FC<IProps> = ({ onChange }) => {
  const C = useStyles();
  const { id }: IParams = useParams();
  const { vehicleSource } = useSelector(getService(id));
  const serviceAptrUsageCodes = useSelector(getServiceAptrUsageCodes(id));
  const [filter, setFilter] = useState<IWhitelistEntryListFilter>();

  useEffect(() => {
    updateFilter({ serviceId: id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateFilter = useCallback(
    (newFilter: Partial<IWhitelistEntryListFilter>) => {
      const _filter = {
        ...filter,
        ...newFilter,
      };
      setFilter(_filter);
      onChange(_filter);
    },
    [filter, onChange],
  );

  const updateQuery = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      updateFilter({ query: event.target.value });
    },
    [updateFilter],
  );

  const updateType = useCallback(
    (type: string[]) => {
      updateFilter({ type });
    },
    [updateFilter],
  );

  const updateAptrUsageCode = useCallback(
    (aptrUsageCode: string[]) => {
      updateFilter({ aptrUsageCode });
    },
    [updateFilter],
  );

  return (
    <Grid container spacing={4} justify="space-between" className={C.root}>
      <Grid item xs={4}>
        <TextField
          value={filter?.query}
          onChange={updateQuery}
          fullWidth
          id="whitelistEntryListFilterQuery"
          label={translate('services.whitelists.entries.filters.query')}
          InputProps={{
            endAdornment: <Search />,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <MultiSelect
          data={getWhitelistEntryListTypeFilter()}
          value={filter?.type || []}
          valueChange={updateType}
          valueProp="id"
          keyProp="id"
          displayProp="name"
          id="whitelistEntryListFilterType"
          label={translate('services.whitelists.entries.filters.type')}
        />
      </Grid>
      <Visible visible={vehicleSource === VehicleSource.aptr}>
        <Grid item xs={4}>
          <MultiSelect
            data={serviceAptrUsageCodes}
            displayProp="name"
            id="whitelistEntryListAptrUsageCode"
            keyProp="id"
            label={translate('services.whitelists.entries.filters.aptrUsageCode')}
            value={filter?.aptrUsageCode || []}
            valueChange={updateAptrUsageCode}
            valueProp="id"
          />
        </Grid>
      </Visible>
    </Grid>
  );
};
