import { SingleSelectV2Component } from '../../../common/components/form-fields/single-select-v2.component';
import { sortById } from '../../../common';
import { GeoDrawingListItem } from '../../atoms';
import * as React from 'react';
import { useGeoDrawingContext } from '../../../hooks/useGeoDrawingContext.hook';
import { useRequestDetailMapStyles } from '../RequestDetailMap/RequestDetailMap.component';
import { useSelector } from 'react-redux';
import { getPhaseMenuItems } from '../../../store/selectors/sgwRequest.selectors';

export const GeoDrawingList = () => {
  const C = useRequestDetailMapStyles();
  const {
    formMethods: { watch },
    phaseGeoDrawings: { addMode, changeSelectedPhase, selectedPhase, idToEdit, deleteGeoDrawing, setIdToEdit },
  } = useGeoDrawingContext();

  return (
    <>
      <SingleSelectV2Component
        inputClassname={C.selectTitle}
        onChange={changeSelectedPhase}
        value={selectedPhase?.id}
        menuItems={useSelector(getPhaseMenuItems)}
      />
      {[...(selectedPhase?.sgwGeoDrawings || [])].sort(sortById).map((geoDrawing) => (
        <GeoDrawingListItem
          key={geoDrawing.id}
          disabled={(!!idToEdit && idToEdit !== geoDrawing.id) || addMode}
          editMode={idToEdit === geoDrawing.id}
          onDelete={deleteGeoDrawing}
          onEdit={setIdToEdit}
          {...geoDrawing}
          size={watch('surfaceArea')}
        />
      ))}
    </>
  );
};
