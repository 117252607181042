import { useEffect } from 'react';
import { JsonObject } from '../types';
import nl from '../common/translations/nl.json';
import { TranslateOptions } from 'i18n-js';
import { translate } from '../common/translations/translate';

export const useTitle = (jsonKey: JsonObject<typeof nl>, options?: TranslateOptions) => {
  useEffect(() => {
    document.title = translate(jsonKey, options);
  }, [jsonKey, options]);
};
