import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { genericErrorHandler } from '../..';
import { ICall } from '../../../types';
import { WorkOrderItemsActions } from './work-order-items.actions';
import { WorkOrderItemsApi } from './work-order-items.api';

function* onFetchWorkOrderItemTypes(): SagaIterator {
  const response: ICall<typeof WorkOrderItemsApi.fetchTypes> = yield call(WorkOrderItemsApi.fetchTypes);
  yield put(WorkOrderItemsActions.setTypes(response!.data.data));
}

function* onFetchWorkOrderItemCreatableTypes(): SagaIterator {
  const response: ICall<typeof WorkOrderItemsApi.fetchCreatableTypes> = yield call(
    WorkOrderItemsApi.fetchCreatableTypes,
  );
  yield put(WorkOrderItemsActions.setCreatableTypes(response!.data.data));
}

function* onPostWorkOrderItem({ payload }: ReturnType<typeof WorkOrderItemsActions.post>): SagaIterator {
  const response: ICall<typeof WorkOrderItemsApi.post> = yield call(WorkOrderItemsApi.post, payload);
  yield put(WorkOrderItemsActions.append(response!.data.data));
}

function* onDeleteWorkOrderItem({ payload }: ReturnType<typeof WorkOrderItemsActions.delete>): SagaIterator {
  yield call(WorkOrderItemsApi.delete, payload);
}

export function* workOrderItemsSaga(): SagaIterator {
  yield takeLatest(WorkOrderItemsActions.delete.type, genericErrorHandler(onDeleteWorkOrderItem));
  yield takeLatest(WorkOrderItemsActions.fetchTypes.type, genericErrorHandler(onFetchWorkOrderItemTypes));
  yield takeLatest(
    WorkOrderItemsActions.fetchCreatableTypes.type,
    genericErrorHandler(onFetchWorkOrderItemCreatableTypes),
  );
  yield takeLatest(WorkOrderItemsActions.post.type, genericErrorHandler(onPostWorkOrderItem));
}
