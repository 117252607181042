import { CircularProgress, Grid, makeStyles, TextField } from '@material-ui/core';
import { AttachFileSharp, Close, SendOutlined } from '@material-ui/icons';
import * as React from 'react';
import { FunctionComponent, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { Visible } from '../../../common/components/layout/Visible.component';
import { translate } from '../../../common/translations/translate';
import { deleteMessageAttachment, SgwRequestActions, uploadMessageAttachment } from '../../../store/actions';
import { selectRequestMessageAttachments } from '../../../store/selectors';
import { IRequestMessage, IRequestMessagesList, IUploadedFile } from '../../../types';

export interface IMessagesProps {
  requestId: string;
  messagesList: IRequestMessagesList;
  saveMessage?(message: IRequestMessage): void;
}

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  button: { marginLeft: 20 },
  buttonIcon: { marginRight: 5, width: 20 },
});

export const Form: FunctionComponent<IMessagesProps> = ({ requestId, messagesList, saveMessage }) => {
  const styles = useStyles();
  const acceptFileTypes =
    'image/*, .heic, ' +
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document, ' +
    'application/msword,  application/pdf';

  const [newMessageText, setNewMessageText] = useState<string>('');

  const messageAttachments = useSelector(selectRequestMessageAttachments);
  const uploadFileRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();

  const attachmentsValid = () => Object.values(messageAttachments).filter((attachment) => !attachment.id).length === 0;

  const canSend = () => newMessageText && attachmentsValid();

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => setNewMessageText(event.target.value);

  const sendMessageHandler = () => {
    if (canSend()) {
      const requestMessage = {
        attachments: messageAttachments || [],
        content: newMessageText,
        requestId,
      };
      saveMessage ? saveMessage(requestMessage) : dispatch(SgwRequestActions.saveSgwRequestMessage(requestMessage));
      setNewMessageText('');
    }
  };

  const resolveMessagesHandler = () => dispatch(SgwRequestActions.resolveSgwRequestMessages(requestId));

  const handleUploadClick = () =>
    // @ts-ignore
    uploadFileRef.current.click();

  const fileUploadHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length) {
      const len = event.target.files.length;
      for (let i = 0; i < len; i++) {
        const file = event.target.files[i] as File;
        dispatch(uploadMessageAttachment(file));
      }
    }
  };

  const deleteAttachmentHandler = (file: IUploadedFile) => () => dispatch(deleteMessageAttachment(file));

  Object.values(messageAttachments)
    .filter((f) => f.uploadError)
    .forEach((f) => {
      // Use simple browser alert because this component embedded into old angular app
      alert(f.uploadError);
      dispatch(deleteMessageAttachment(f));
    });

  return (
    <div className="messages-form">
      <Grid container alignItems={'flex-end'}>
        <Grid item xs={11}>
          <TextField
            id="message_input"
            name="message"
            label={translate('Messages.Form.Label')}
            value={newMessageText}
            placeholder={translate('Messages.Form.Placeholder')}
            fullWidth
            margin="none"
            multiline
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleMessageChange}
          />
        </Grid>
        <Grid item xs={1}>
          <AttachFileSharp className="messages-form-attachment" onClick={handleUploadClick} />
          <input
            // AS-5495 Problem when adding an attachment twice
            key={Object.values(messageAttachments).length}
            type="file"
            name="file"
            onChange={fileUploadHandler}
            multiple
            ref={uploadFileRef}
            style={{ display: 'none' }}
            accept={acceptFileTypes}
          />
        </Grid>
        <Grid item xs={12}>
          {Object.values(messageAttachments).map((file) => (
            // TODO: Use correct href in link
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a className="messages-attachments-file messages-attachments-file-new" key={file.key}>
              {file.name}
              {file.loading ? (
                <CircularProgress className="messages-attachments-progress" />
              ) : (
                <Close
                  fontSize={'small'}
                  color={'error'}
                  className="messages-attachments-delete"
                  onClick={deleteAttachmentHandler(file)}
                />
              )}
            </a>
          ))}
        </Grid>
        <div className={styles.buttonContainer}>
          <Visible visible={messagesList.acl.messages_can_resolve}>
            <div className={`messages-form-resolve-button  ${styles.button}`}>
              <Button.Green onClick={resolveMessagesHandler}>{translate('Messages.Form.ResolveButton')}</Button.Green>
            </div>
          </Visible>
          <div className={`messages-form-button ${styles.button}`}>
            <Button.Green disabled={!canSend()} onClick={sendMessageHandler}>
              <SendOutlined className={styles.buttonIcon} />
              {translate('Messages.Form.SendButton')}
            </Button.Green>
          </div>
        </div>
      </Grid>
    </div>
  );
};
