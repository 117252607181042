import { SgwPermitConditionCategory } from '../../../types';
import React, { FC } from 'react';
import { Visible } from '../../../common/components/layout/Visible.component';
import { ColonSeparatedTitle, SelectedPermitConditionRow } from '../../atoms';
import { translate, translateIgnoreTS } from '../../../common/translations/translate';
import { useSelector } from 'react-redux';
import { getSelectedPermitConditionsByCategory } from '../../../store/selectors/sgwRequest.selectors';

interface IProps {
  category: SgwPermitConditionCategory;
}

export const SelectedPermitConditionCategory: FC<IProps> = ({ category }) => {
  const permitConditions = useSelector(getSelectedPermitConditionsByCategory(category));

  return (
    <Visible visible={!!permitConditions.length}>
      <div>
        <ColonSeparatedTitle title={translate('sgw.permitConditions.columns.category')}>
          {translateIgnoreTS(`sgw.permitConditions.categories.${category}`)}
        </ColonSeparatedTitle>
        <div>
          {permitConditions.map((permitCondition) => (
            <SelectedPermitConditionRow key={permitCondition.id} {...permitCondition} />
          ))}
        </div>
      </div>
    </Visible>
  );
};
