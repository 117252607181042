import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { IPaging, IPartialPagedTable, ISorting, payloadReducer } from '../../common';
import { IFilter } from '../../common/types/filter';
import { extractIdsFromArray, IById, IWithId, normalizeArray } from '../../common/utils/normalized.util';
import { ICreatePagedTableReducerProps } from '../../types';
import { initialPaging } from '../../common/config/paging.constants';

const createPagingReducer = (pagingTypes: string[], resetTypes: string[] = []) =>
  createReducer<IPaging>(initialPaging, {
    ...pagingTypes.reduce(
      (accumulator, type) => ({
        ...accumulator,
        [type]: payloadReducer<IPaging>(initialPaging),
      }),
      {},
    ),
    ...resetTypes.reduce(
      (accumulator, type) => ({
        ...accumulator,
        [type]: () => initialPaging,
      }),
      {},
    ),
  });

const createSortingReducer = (type: string, initialSorting: ISorting) =>
  createReducer<ISorting>(initialSorting, {
    [type]: payloadReducer<ISorting>(initialSorting),
  });

export const createTableReducers = (
  pagingType: string[],
  sortingType: string,
  initialSorting: ISorting,
  filteringType: string = 'filter',
  initialFiltering: IFilter = {},
) =>
  combineReducers({
    paging: createPagingReducer(pagingType, [sortingType, filteringType]),
    sorting: createSortingReducer(sortingType, initialSorting),
    filters: createFilteringReducer(filteringType, initialFiltering),
  });

const createFilteringReducer = (type: string, initialSorting: IFilter) =>
  createReducer<IFilter>(initialSorting, {
    [type]: payloadReducer<IFilter>(initialSorting),
  });

export const createTableParamsReducer = <F = {}>(initialState: IPartialPagedTable<F>, actions: string[]) =>
  createReducer<IPartialPagedTable<F>>(initialState, {
    ...actions.reduce(
      (accumulator, type) => ({
        ...accumulator,
        [type]: (
          state: IPartialPagedTable<F>,
          { payload }: PayloadAction<IPartialPagedTable<F>>,
        ): IPartialPagedTable<F> => ({
          ...state,
          ...payload,
          paging: payload.filters || payload.sorting ? initialState.paging : payload.paging || state.paging,
        }),
      }),
      {},
    ),
  });

const createAllIdsReducer = <Entity>(setEntitiesAction: string, removeEntityActions: string[] = []) => {
  const actionsMap = {
    [setEntitiesAction]: (state: string[], { payload }: PayloadAction<Entity[]>): string[] =>
      extractIdsFromArray(payload),
  };

  removeEntityActions.forEach((removeAction) => {
    // @ts-ignore
    actionsMap[removeAction] = (state: string[], { payload }: PayloadAction<string>): string[] =>
      state.filter((id) => id !== payload);
  });

  return createReducer<string[]>([], actionsMap);
};

export const createByIdReducer = <Entity>(
  addEntitiesActions: string[],
  addEntityActions?: string[],
  removeEntityActions?: string[],
) => {
  const actionsMap = {};

  addEntitiesActions.forEach(
    (action) =>
      // @ts-ignore
      (actionsMap[action] = (state: IById<Entity>, { payload }: PayloadAction<Entity[]>): IById<Entity> => ({
        ...state,
        ...normalizeArray<Entity>(payload as IWithId<Entity>[]),
      })),
  );

  addEntityActions?.forEach(
    (action) =>
      // @ts-ignore
      (actionsMap[action] = (state: IById<Entity>, { payload }: PayloadAction<IWithId<Entity>>): IById<Entity> => ({
        ...state,
        [payload.id]: payload,
      })),
  );

  removeEntityActions?.forEach((removeAction) => {
    // @ts-ignore
    actionsMap[removeAction] = (state: IById<Entity>, { payload }: PayloadAction<string>): IById<Entity> => {
      const { [payload]: removedId, ...newState } = state;
      return newState;
    };
  });

  // @ts-ignore
  return createReducer<IById<Entity>>({}, actionsMap);
};

const createSetBooleanReducer = (setTrueTypes: string[], setFalseTypes: string[] = [], payloadType?: string) =>
  createReducer<boolean>(false, {
    ...setTrueTypes.reduce(
      (accumulator, type) => ({
        ...accumulator,
        [type]: () => true,
      }),
      {},
    ),
    ...setFalseTypes.reduce(
      (accumulator, type) => ({
        ...accumulator,
        [type]: () => false,
      }),
      {},
    ),
    ...(!!payloadType && {
      payloadType: (state: boolean, { payload }: PayloadAction<boolean>) => payload,
    }),
  });
export const createPagedTableReducer = <Entity, Filter = {}>(
  props: ICreatePagedTableReducerProps<Filter>,
  storeEntities = true,
) =>
  combineReducers({
    allIds: createAllIdsReducer<Entity>(props.setAllIdsAction, props.removeEntityActions),
    ...(storeEntities && {
      byId: createByIdReducer<Entity>(
        props.addEntitiesActions || [],
        props.addEntityActions,
        props.removeEntityActions,
      ),
    }),
    table: createTableParamsReducer<Filter>(props.initialParamsState, props.setParamsActions),
    loading: createSetBooleanReducer(
      [props.fetchAction],
      [props.setAllIdsAction, ...(props.addEntitiesActions || [])],
      props.setLoadingAction,
    ),
  });
