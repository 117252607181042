import { combineReducers } from 'redux';
import { ISgwPermitCondition } from '../../../types';
import { createPagedTableReducer } from '../pagedTable.reducer';
import { SgwPermitConditionsActions } from '../../actions';
import { initialSgwPermitConditionsState } from '../../state';
import { generatePayloadReducer } from '../../../common';

export const permitConditionsReducer = combineReducers({
  list: createPagedTableReducer<ISgwPermitCondition>({
    addEntitiesActions: [SgwPermitConditionsActions.list.set.type],
    addEntityActions: [SgwPermitConditionsActions.set.type],
    fetchAction: SgwPermitConditionsActions.list.fetch.type,
    setAllIdsAction: SgwPermitConditionsActions.list.set.type,
    setParamsActions: [SgwPermitConditionsActions.list.fetch.type, SgwPermitConditionsActions.list.setParams.type],
    initialParamsState: initialSgwPermitConditionsState.list.table,
  }),
  existingReferenceCodes: generatePayloadReducer<string[]>(
    [SgwPermitConditionsActions.setExistingReferenceCodes.type],
    [],
  ),
  existingTitles: generatePayloadReducer<string[]>([SgwPermitConditionsActions.setExistingTitles.type], []),
});
