import axios from 'axios';
import { CONFIG, IApiPromise } from '../../common';
import { IRequestRescheduling } from '../../types/SgwReschedulings.types';

const BASE_SGW_REQUEST_URL = '/v1/sgw/requests';

export const SgwRequestReschedulingApi = {
  apply: (requestId: string, reschedulingId: string): IApiPromise<IRequestRescheduling> =>
    axios.post(
      `${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${requestId}/rescheduling_requests/${reschedulingId}/apply`,
    ),
  consider: (requestId: string, reschedulingId: string): IApiPromise<IRequestRescheduling> =>
    axios.post(
      `${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${requestId}/rescheduling_requests/${reschedulingId}/consider`,
    ),
  fetch: (requestId: string): IApiPromise<IRequestRescheduling[]> =>
    axios.get(`${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${requestId}/rescheduling_requests`),
  reject: (requestId: string, reschedulingId: string): IApiPromise<IRequestRescheduling> =>
    axios.post(
      `${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${requestId}/rescheduling_requests/${reschedulingId}/reject`,
    ),

  update: (
    requestId: string,
    reschedulingId: string,
    rescheduling: Partial<IRequestRescheduling>,
  ): IApiPromise<IRequestRescheduling> =>
    axios.patch(
      `${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${requestId}/rescheduling_requests/${reschedulingId}`,
      rescheduling,
    ),
};
