import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import I18n from 'i18n-js';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { FormikCheckboxField } from '../../../common/components/formikFormFields';
import { PanelTitle } from '../../../common/components/panel/panel-title.component';
import { getEditableFieldsIncludes } from '../../../store/selectors';
import { RequestCreateField, IRequestFormData } from '../../../types';

interface IProps {
  values: IRequestFormData;
}

const useStyles = makeStyles({
  root: { marginBottom: '2em' },
  container: { width: 550 },
  countryField: { width: 300 },
});

export const RequestFormEmergencyComponent: FunctionComponent<IProps> = ({ values }) => {
  const C = useStyles();
  return (
    <Box className={C.root}>
      <PanelTitle>{I18n.t('Requests.Create.EmergencyTitle')}</PanelTitle>
      <Grid container className={C.container} spacing={4} justify={'flex-start'}>
        <Grid item xs={12}>
          <FormikCheckboxField
            disabled={!useSelector(getEditableFieldsIncludes(RequestCreateField.emergencyProcedure))}
            name={RequestCreateField.emergencyProcedure}
            label={I18n.t('Requests.Create.Form.emergencyProcedure')}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
