import { createReducer } from '@reduxjs/toolkit';
import { IFileMetadata } from '../../../types';
import { IById } from '../../../common/utils/normalized.util';
import { StorageActions } from '../../actions';

type TEntity = IFileMetadata;
type TState = IById<TEntity>;

export default createReducer<TState>(
  {},
  {
    [StorageActions.upload.type]: (state: TState, { payload }: ReturnType<typeof StorageActions.upload>): TState =>
      payload.metadata ? { ...state, [payload.metadata.id]: payload.metadata } : state,
    [StorageActions.uploadError.type]: (
      state: TState,
      { payload }: ReturnType<typeof StorageActions.uploadError>,
    ): TState => (payload ? { ...state, [payload.id]: payload } : state),
    [StorageActions.uploadSuccess.type]: (
      state: TState,
      { payload }: ReturnType<typeof StorageActions.uploadSuccess>,
    ): TState => (payload ? { ...state, [payload.id]: payload } : state),
  },
);
