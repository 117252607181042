import { IRootState } from '../../root.state';
import { createSelector } from '@reduxjs/toolkit';
import { initialAppState } from '../../common';
import { getVisibleTabs, getFirstVisibleTab } from '../../common/store/user/user.selectors';

export const selectRedirectUrl = (state: IRootState) => state.app.redirectUrl;
export const selectLoading = (state: IRootState) => state.app.loading;
export const selectAccessToken = (state: IRootState) => state?.app?.accessToken;
export const selectError = ({ errors }: IRootState) =>
  !!errors.error && Object.keys(errors.error).length ? errors.error : null;

export const getIsLoggedIn = createSelector([selectAccessToken], (token) => token !== null);

export const getRedirectUrlAfterLogin = createSelector(
  [getFirstVisibleTab, getVisibleTabs, selectRedirectUrl],
  (firstVisibleTab, visibleTabs, redirectUrl) => {
    return visibleTabs.some((tab) => tab.route === redirectUrl)
      ? redirectUrl
      : firstVisibleTab?.route || initialAppState.redirectUrl;
  },
);
