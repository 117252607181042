import { Chip, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FunctionComponent, default as React } from 'react';

interface IPlateWithDate {
  label: string;
  date?: string;
}

interface IProps {
  deleteLicensePlateHandler: (toDel: string, date?: string) => () => void;
  licensePlates?: string[];
  licensePlatesWithDates?: IPlateWithDate[];
  showOnDelete: boolean;
}

const useStyles = makeStyles({
  chip: {
    marginRight: '15px',
    marginBottom: '15px',
  },
});

export const LicensePlatesList: FunctionComponent<IProps> = ({
  deleteLicensePlateHandler,
  licensePlates,
  licensePlatesWithDates,
  showOnDelete,
}) => {
  const C = useStyles();

  return (
    <Grid container justify="flex-start">
      <Grid item xs>
        {licensePlates?.map((licensePlate) => (
          <Chip
            key={licensePlate}
            label={licensePlate.toUpperCase()}
            onDelete={!showOnDelete ? undefined : deleteLicensePlateHandler(licensePlate)}
            className={C.chip}
          />
        ))}
        {licensePlatesWithDates?.map((licensePlate) => (
          <Chip
            key={licensePlate.label}
            label={licensePlate.label.toUpperCase()}
            onDelete={!showOnDelete ? undefined : deleteLicensePlateHandler(licensePlate.label, licensePlate.date)}
            className={C.chip}
          />
        ))}
      </Grid>
    </Grid>
  );
};
