import { Link } from '@material-ui/icons';
import * as React from 'react';

interface IProps {
  latitude: number;
  longitude: number;
}

export const Viod: React.FunctionComponent<IProps> = (props: IProps) => {
  const link =
    `https://geocortex.antwerpen.be/Html5Viewer/?viewer=viod&runWorkflow=OpstartenVIODPunt&type=WGS84&` +
    `argX=${props.latitude}&argY=${props.longitude}`;

  return (
    <a href={link} target="_blank" className="link viod-link" rel="noopener noreferrer">
      <Link fontSize="default" />
      <div>VIOD</div>
    </a>
  );
};
