import { createSelector } from '@reduxjs/toolkit';
import { IRootState } from '../../root.state';
import { createAllEntitiesSelector } from '../../common/utils/normalized.util';
import { createPagedApiParamsSelector } from '../../common/store/utils/generic.selectors';
import { ISignsFilter } from '../types/signs-filter';

export const selectSignsState = (store: IRootState) => store.signs;
export const selectSignLoading = (store: IRootState) => store.signs.signLoading;
export const selectAllIds = (store: IRootState) => store.signs.list.allIds;
export const selectById = (store: IRootState) => store.signs.list.byId;
export const selectTable = (store: IRootState) => store.signs.list.table;
export const selectLoading = (store: IRootState) => store.signs.list.loading;
export const getSignsList = createAllEntitiesSelector(selectAllIds, selectById);
export const getPagedApiParams = createPagedApiParamsSelector<ISignsFilter>(selectTable);

export const selectSignsOptions = createSelector([getSignsList], (signs) =>
  signs ? signs.map(({ hash }) => ({ value: hash, label: hash })) : [],
);
export const selectSign = (store: IRootState) => store.signs.sign;

export const selectSignConditions = (store: IRootState) => store.signs.signConditions;
export const selectSignsCounters = (store: IRootState) => store.signs.signCounters;
export const selectSignStates = (store: IRootState) => store.signs.signStates;
export const selectSignTypes = (store: IRootState) => store.signs.signTypes;
