import React, { FC, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { Button } from '@material-ui/core';
import { useGeneralStyles } from '../../../style/generalStyles';
import { translate } from '../../../common/translations/translate';
import { useDispatch, useSelector } from 'react-redux';
import { SgwAdvisingPartiesActions, SgwRequestActions } from '../../../store/actions';
import {
  getConflictGroupOptions,
  getSgwRequestAssignStatus,
  getSortedConflictGroupsWithPhaseIds,
  selectConflictsLoading,
  selectSgwRequest,
} from '../../../store/selectors/sgwRequest.selectors';
import { SgwConflictAccordion } from '../../molecules/SgwConflictAccordion/SgwConflictAccordion.component';
import { Visible } from '../../../common/components/layout/Visible.component';
import { editAtom, ManualRequestAdvice } from '../../molecules/SgwManualRequestAdvice/SgwManualRequestAdvice.component';
import { useAtom } from 'jotai';
import { useAuthorization, useRequestAuthorization } from '../../../hooks';
import { ISgwAdvisingPartiesAclActions, ISgwRequestDetailAclActions } from '../../../types';
import { ModalButton } from '../../../common/components/buttons/modal-button';
import { Panel } from '../../../common';
import { allPaging } from '../../../common/config/paging.constants';

const useStyles = createUseStyles({
  legend: {
    color: 'black',
    fontSize: 'larger',
    padding: '0.25rem 0.5rem',
  },
  marginTop: {
    marginTop: '40px',
  },
  button: {
    textTransform: 'none',
    marginLeft: '24px',
  },
  noConflicts: {
    marginTop: '10px',
    marginBottom: '10px',
    paddingLeft: '8px',
  },
});

interface IProps {
  requestId: string;
}
export const RequestDetailConflictPanel: FC<IProps> = ({ requestId }) => {
  const C = useStyles();
  const G = useGeneralStyles();
  const dispatch = useDispatch();
  const [, setEditMode] = useAtom(editAtom);
  const conflictGroups = useSelector(getSortedConflictGroupsWithPhaseIds);
  const { isAuthorized: viewSgwAdvisingPartyRequestOverview } = useAuthorization(
    ISgwAdvisingPartiesAclActions.viewSgwAdvisingPartyRequestOverview,
  );
  const { isAuthorized: requestAllowsSyncingConflicts } = useRequestAuthorization(
    ISgwRequestDetailAclActions.syncSgwConflicts,
  );
  const { isAuthorized: requestAllowsCreatingManualAdviceRequest } = useRequestAuthorization(
    ISgwRequestDetailAclActions.createManualAdviceRequest,
  );
  const loading = useSelector(selectConflictsLoading);
  const { allowEditRequest } = useSelector(getSgwRequestAssignStatus);
  const request = useSelector(selectSgwRequest);
  const conflictGroupOptions = useSelector(getConflictGroupOptions());

  useEffect(() => {
    requestId && dispatch(SgwRequestActions.conflicts.fetchList(requestId));
    dispatch(SgwAdvisingPartiesActions.list.fetch({ paging: allPaging }));
  }, [dispatch, requestId, viewSgwAdvisingPartyRequestOverview]);
  const onConfirm = () => request && dispatch(SgwRequestActions.conflicts.sync(request.id));
  return (
    <Panel
      title={
        <div className={G.flexRowSpaceBetween}>
          <div className={C.legend}>{translate('sgw.requests.detail.conflicts.legend')}</div>
          <div className={G.flexRow}>
            <Visible visible={requestAllowsSyncingConflicts}>
              <ModalButton.SyncConflicts
                buttonTitle={translate('sgw.requests.detail.syncConflicts')}
                title={translate('sgw.requests.detail.syncConflicts')}
                onConfirm={onConfirm}
                loading={loading}
                visible
                needsReason={false}
                disabled={!allowEditRequest}
                modalBody={translate('sgw.requests.detail.syncConflictsConfirmation')}
              />
            </Visible>
            <Visible visible={requestAllowsCreatingManualAdviceRequest}>
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={C.button}
                  onClick={() => setEditMode((prevState) => !prevState.valueOf())}
                  disabled={!conflictGroupOptions.length}
                >
                  {translate('sgw.requests.detail.conflicts.buttonManualAdvice')}
                </Button>
              </span>
              <ManualRequestAdvice requestId={requestId} />
            </Visible>
          </div>
        </div>
      }
    >
      <Visible
        visible={!!conflictGroups.length}
        defaultComponent={
          <div className={C.noConflicts}>{translate('sgw.requests.detail.conflicts.noConflictsFound')}</div>
        }
      >
        {conflictGroups.map((conflictGroup) => (
          <SgwConflictAccordion {...conflictGroup} key={conflictGroup.id} />
        ))}
      </Visible>
    </Panel>
  );
};
