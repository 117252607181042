import { createReducer } from '@reduxjs/toolkit';
import { IServiceContactWithId } from '../../../../../types';
import { IById, normalizeArray } from '../../../../utils/normalized.util';
import { ServicesActions } from '../../Services.actions';

type TEntity = IServiceContactWithId;
type TState = IById<TEntity>;

export default createReducer<TState>(
  {},
  {
    [ServicesActions.setContactList.type]: (
      state: TState,
      { payload }: ReturnType<typeof ServicesActions.setContactList>,
    ): TState => normalizeArray<TEntity>(payload),
  },
);
