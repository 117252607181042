import MomentUtils from '@date-io/moment';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import * as React from 'react';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router';
import { theme } from './common';
import { GenericSnackBar } from './common/components/feedback/GenericSnackBar.component';
import { appUrls } from './common/config/url.constants';
import { MainMenu } from './components/organisms/MainMenu/MainMenu.component';
import { LoginSelectionPage } from './components/pages/LoginPage/LoginSelection.page';
import { MainMenuRoutes } from './components/routes/MainMenu.routes';
import { selectLoading } from './store/selectors';
import { AttachmentViewerPage, ManualPlacementPage, ResetPasswordPage, LoginPage } from './components';
import { useLogin } from './hooks';
import { Error } from './components/pages/ErrorPage/Error.page';
import { AppFeatureAction } from './store/actions';

export const RootComponent: FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const { showMainMenu } = useLogin();

  useEffect(() => {
    dispatch(AppFeatureAction.fetch());
  }, [dispatch]);

  return !loading ? (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <CssBaseline />
        <GenericSnackBar />
        <Route exact path={appUrls.login.selection} component={LoginSelectionPage} />
        <Route exact path={appUrls.login.oauthMprofile} component={LoginSelectionPage} />
        <Route exact path={appUrls.login.base} component={LoginPage} />
        <Route exact path={appUrls.reset.password()}>
          <ResetPasswordPage />
        </Route>
        <Route exact path={appUrls.manualPlacement.base()}>
          <ManualPlacementPage />
        </Route>
        <Route path={appUrls.attachments.base()}>
          <AttachmentViewerPage />
        </Route>
        <Route path={appUrls.error}>
          <Error />
        </Route>
        {showMainMenu ? (
          <MainMenu>
            <MainMenuRoutes />
          </MainMenu>
        ) : (
          <MainMenuRoutes />
        )}
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  ) : null;
};
