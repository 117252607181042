import * as React from 'react';
import { Route, Switch } from 'react-router';
import { RequestPage } from './components/details/request.page';
import { RequestEditWrapperComponent } from './components/edit/RequestEditWrapper.component';
import { Overview } from './components/overview/overview.container';
import { RequestEditLocationsPage } from './components/editLocations/RequestEditLocations.page';
import { appUrls } from '../common/config/url.constants';

export const RequestRoutes = () => (
  <Switch>
    <Route exact path={[appUrls.requests.base, appUrls.requests.list]}>
      <Overview />
    </Route>
    <Route exact path={appUrls.requests.create}>
      <RequestEditWrapperComponent />
    </Route>
    <Route exact path={appUrls.requests.edit()}>
      <RequestEditWrapperComponent />
    </Route>
    <Route exact path={appUrls.requests.editLocations()}>
      <RequestEditLocationsPage />
    </Route>
    <Route exact path={[appUrls.requests.detail(), appUrls.requests.detailLocation()]}>
      <RequestPage />
    </Route>
  </Switch>
);
