import { createRef, useCallback, useEffect, useRef } from 'react';
import * as L from 'leaflet';
import { polylineDecorator, PolylineDecorator, Symbol } from 'leaflet';
import { useBaseMap } from '../common/components/map/BaseMap.context';
import { COLORS } from '../common';

export const useLeafletArrow = (showArrow?: boolean) => {
  const polyRef = createRef<L.Polyline>();
  const arrowRef = useRef<PolylineDecorator>();
  const { mapRef } = useBaseMap();

  const addArrow = useCallback(() => {
    const arrowShape = [
      {
        offset: '55%',
        repeat: '0',
        symbol: Symbol?.arrowHead({
          pixelSize: 25,
          polygon: true,
          pathOptions: { fillOpacity: 1, weight: 5, color: COLORS.BEER },
        }),
      },
    ];
    if (polyRef.current) {
      if (mapRef?.current) {
        arrowRef.current = polylineDecorator(polyRef.current, {
          patterns: arrowShape,
        });
      }
      if (arrowRef.current && mapRef?.current) {
        arrowRef.current?.addTo(mapRef?.current);
      }
    }
  }, [mapRef, polyRef]);

  useEffect(() => {
    showArrow && addArrow();
    const map = mapRef?.current;

    return () => {
      arrowRef.current && map && arrowRef.current?.removeFrom(map);
    };
  }, [showArrow, addArrow, mapRef]);

  return polyRef;
};
