import axios from 'axios';
import { backendUrl, CONFIG, IApiPromise } from '../..';
import { IWorkOrderItem, IWorkOrderItemPost, IWorkOrderItemType } from '../../../types';

export const WorkOrderItemsApi = {
  fetchTypes: (): IApiPromise<IWorkOrderItemType[]> => {
    return axios.get(`${backendUrl}/general/v1/work_order_items/types`);
  },
  fetchCreatableTypes: (): IApiPromise<IWorkOrderItemType[]> => {
    return axios.get(`${backendUrl}/general/v1/work_order_items/creatable_types`);
  },
  post: (payload: IWorkOrderItemPost): IApiPromise<IWorkOrderItem> => {
    return axios.post(`${CONFIG.backOfficeApi}/v1/work_order_items`, payload);
  },
  delete: (id: number): IApiPromise<IWorkOrderItem> => {
    return axios.delete(`${CONFIG.backOfficeApi}/v1/work_order_items/${id}`);
  },
};
