import React, { FC } from 'react';
import { translate } from '../../../common/translations/translate';
import { Chip } from '@material-ui/core';
import { LabelValue } from '../../../common/components/layout/LabelValue.component';
import { createUseStyles } from 'react-jss';
import { Priority } from '../../../types/priority.types';
import classNames from 'classnames';
import { COLORS } from '../../../common';

interface IProps {
  priority: Priority;
  onSelectPriority?(priority: Priority): void;
  disabled?: boolean;
}

const useStyles = createUseStyles<any, IProps>({
  base: {
    marginRight: '10px',
    textTransform: 'none',
    fontWeight: 500,
  },
  normal: {
    color: ({ priority }) => (priority === Priority.normal ? 'white' : 'black'),
    backgroundColor: ({ priority }) => (priority === Priority.normal ? COLORS.PRIMARY : COLORS.GREY_LIGHTER),
  },
  high: {
    color: ({ priority }) => (priority === Priority.high ? 'white' : 'black'),
    backgroundColor: ({ priority }) => (priority === Priority.high ? COLORS.ORANGE : COLORS.GREY_LIGHTER),
    '&:hover': {
      backgroundColor: ({ priority }) => (priority === Priority.high ? COLORS.ORANGE_DARK : COLORS.GREY_LIGHTER),
    },
    '&:focus': {
      backgroundColor: ({ priority }) => (priority === Priority.high ? COLORS.ORANGE : COLORS.GREY_LIGHTER),
    },
  },
});

export const PriorityPicker: FC<IProps> = (props) => {
  const C = useStyles(props);
  const { onSelectPriority } = props;

  return (
    <LabelValue label={translate('Requests.Detail.Info.Priority')} labelCentered>
      <Chip
        onClick={onSelectPriority && (() => onSelectPriority(Priority.normal))}
        className={classNames(C.base, C.normal)}
        label={translate('Normal')}
        color="primary"
        data-testid="normal"
        disabled={props.disabled}
      />
      <Chip
        onClick={onSelectPriority && (() => onSelectPriority(Priority.high))}
        className={classNames(C.base, C.high)}
        label={translate('High')}
        color={undefined}
        data-testid="high"
        disabled={props.disabled}
      />
    </LabelValue>
  );
};
