import { ISortingDirection } from '../..';
import { IPagedTableState, IRequestOverview } from '../../../types';
import { IRole, IUser, IUserFilter, IUserRequestFilter } from '../../types/user';

export interface IUserState {
  user: IUser | null;
  admins: IUser[];
  allRoles: IRole[];
  list: IPagedTableState<IUser, IUserFilter>;
  requestList: IPagedTableState<IRequestOverview, IUserRequestFilter>;
  selectedId: string | null;
}

export const initialUserState: IUserState = {
  user: null,
  admins: [],
  allRoles: [],
  selectedId: null,
  list: {
    byId: {},
    allIds: [],
    table: {
      filters: {},
      sorting: {
        direction: ISortingDirection.desc,
        key: 'detected',
      },
      paging: {
        page: 1,
        pageSize: 20,
        totalPages: 0,
        totalRecords: 0,
      },
    },
    loading: true,
  },
  requestList: {
    byId: {},
    allIds: [],
    table: {
      filters: {},
      sorting: {
        direction: ISortingDirection.desc,
        key: 'createdAt',
      },
      paging: {
        page: 1,
        pageSize: 20,
        totalPages: 0,
        totalRecords: 0,
      },
    },
    loading: true,
  },
};
