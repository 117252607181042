import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { UserActions } from '../../common/store/user/user.actions';
import { getUser } from '../../common/store/user/user.selectors';
import { IUser } from '../../common/types/user';
import { IRouterWithId } from '../../types';

export const useUserForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams<IRouterWithId>();
  const user = useSelector(getUser(id));

  const formMethods = useForm<IUser>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      ...user,
    },
  });

  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    reset(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reset]);

  const _onSubmit = useCallback(
    (values: IUser) => {
      dispatch(UserActions.save(values));
    },
    [dispatch],
  );

  const submitUser = handleSubmit(_onSubmit);

  return { formMethods, submitUser, id };
};
