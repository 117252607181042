import React, { FC } from 'react';
import { Button } from '../../../../common/components/buttons/asign-button-extensions';
import { Icon } from '../../../../common/components/layout/icon.component';
import { translate } from '../../../../common/translations/translate';
import { Auth } from '../../../../components';
import { IRequestAclActions, IRequestCopyButtonComponent } from '../../../../types';

export const CopyButton: FC<IRequestCopyButtonComponent> = ({ onCopyRequest }) => (
  <Auth acl={IRequestAclActions.editRequestDraft}>
    <div>
      <Button.White
        data-testid="copyButton"
        iconButton
        onClick={onCopyRequest}
        style={{ marginLeft: '10px' }}
        tooltip={translate('icons.alt.copyRequest')}
      >
        <Icon.CopyRequest />
      </Button.White>
    </div>
  </Auth>
);
