import { FC } from 'react';
import * as React from 'react';
import { IWorkOrderItem } from '../../types';

interface IProps {
  item: IWorkOrderItem;
}

export const WorkOrderItemPopupHeader: FC<IProps> = ({ item }) => {
  const { parkingBanIntake, civilianNotification, type } = item;

  let address, cityLine;
  if (civilianNotification) {
    address = `${civilianNotification.streetName} ${civilianNotification.streetNumber}`;
    cityLine = `${civilianNotification.postalCode} ${civilianNotification.city}`;
  } else if (type === 'retrieve') {
    address = `${item.streetName} ${item.streetNumber}`;
    cityLine = `${item.sign?.parkingBanIntake?.zipCode || ''} ${item.city}`;
  } else if (parkingBanIntake) {
    const { city, street, streetNumberFrom, streetNumberTo, zipCode } = parkingBanIntake;
    address = `${street} ${streetNumberFrom} - ${streetNumberTo}`;
    cityLine = `${zipCode} ${city}`;
  } else {
    address = `${item.streetName} ${item.streetNumber}`;
    cityLine = `${item.postalCode} ${item.city}`;
  }

  return (
    <h3 data-testid={'WorkOrderItemPopupHeader'}>
      {address}
      <br />
      {cityLine}
    </h3>
  );
};
