import React, { ChangeEvent, FC } from 'react';
import { ISgwPermitConditionsFilter, SgwPermitConditionCategory } from '../../../types';
import { useSelector } from 'react-redux';
import { getActivePermitConditionsByCategory } from '../../../store/selectors/sgwPermitConditions.selectors';
import { translate, translateIgnoreTS } from '../../../common/translations/translate';
import { createUseStyles } from 'react-jss';
import { ColonSeparatedTitle, PermitConditionRow } from '../../atoms';
import { Visible } from '../../../common/components/layout/Visible.component';

interface IProps {
  addPermitCondition(id: number): void;
  category: SgwPermitConditionCategory;
  filters: ISgwPermitConditionsFilter;
  removePermitCondition(id: number): void;
  selectedIds: number[];
}

const useStyles = createUseStyles({
  title: {
    fontSize: 'large',
    marginBottom: '10px',
  },
});

export const PermitConditionCategory: FC<IProps> = ({
  addPermitCondition,
  category,
  filters,
  removePermitCondition,
  selectedIds,
}) => {
  const C = useStyles();

  const permitConditions = useSelector(getActivePermitConditionsByCategory(category, filters));

  const onChangeCheckbox = (id: number) => (event: ChangeEvent<HTMLInputElement>) => {
    event.target.checked ? addPermitCondition(id) : removePermitCondition(id);
  };

  return (
    <Visible visible={!!permitConditions.length}>
      <ColonSeparatedTitle className={C.title} title={translate('sgw.permitConditions.columns.category')}>
        {translateIgnoreTS(`sgw.permitConditions.categories.${category}`)}
      </ColonSeparatedTitle>
      <div>
        {permitConditions.map((permitCondition) => (
          <PermitConditionRow
            key={permitCondition.id}
            checked={selectedIds.includes(permitCondition.id)}
            onSelect={onChangeCheckbox(permitCondition.id)}
            {...permitCondition}
          />
        ))}
      </div>
    </Visible>
  );
};
