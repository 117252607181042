import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { IUpdatePassword } from '../../common/types/user';
import { UserActions } from '../../common/store/user/user.actions';
import { translate } from '../../common/translations/translate';
import { useHistory, useParams } from 'react-router';
import { useEffect } from 'react';
import { appUrls } from '../../common/config/url.constants';

export const useUpdatePasswordForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { code } = useParams<{ code: string }>();

  const formMethods = useForm<IUpdatePassword>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });
  const { handleSubmit, setError, setValue } = formMethods;

  useEffect(() => {
    if (code) {
      setValue('code', code);
    } else {
      history.push(appUrls.login.selection);
    }
  }, [code, history, setValue]);

  const _onSubmit = ({ newPasswordConfirmation, ...payload }: IUpdatePassword) => {
    if (payload.newPassword === newPasswordConfirmation) {
      dispatch(UserActions.updatePassword(payload));
    } else {
      setError('newPassword', { type: 'custom', message: translate('resetPassword.wrongPassword') });
    }
  };

  return { onSubmit: handleSubmit(_onSubmit), formMethods };
};
