import { makeStyles } from '@material-ui/core/styles';
import * as L from 'leaflet';
import React, { FunctionComponent, useCallback, useRef } from 'react';
import { Marker as LeafletMarker, Marker, Polygon, Polyline, Popup } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { COLORS } from '../..';
import { RequestActions } from '../../../store/actions';
import { IFeature } from '../../../types';
import { geometryCalculateCenter, geometryToPositions } from '../../utils/geojson.util';
import { PanelTitle } from '../panel/panel-title.component';
import { Markers } from './marker-icon';
import { selectCurrentConflictKey } from '../../../store/selectors';

interface IProps {
  conflict: IFeature;
  pathOptions?: L.PathOptions;
}

const defaultPathOptions: L.PathOptions = {
  weight: 5,
};

const pathOptions = {
  conflict: {
    color: COLORS.DANGER,
    outlineColor: COLORS.WHITE,
    outlineOffset: 5,
    width: 10,
  },
};

const useStyles = makeStyles({
  popupContent: { fontSize: '0.9em' },
});

const PopupMessage: FunctionComponent<{ messageHtml?: string }> = ({ messageHtml }) => {
  const C = useStyles();
  return messageHtml ? (
    <Popup>
      <PanelTitle>Conflict</PanelTitle>
      <div className={C.popupContent} dangerouslySetInnerHTML={{ __html: `${messageHtml}` }}></div>
    </Popup>
  ) : null;
};

export const GeometryConflict: FunctionComponent<IProps> = ({ conflict, ...restProps }) => {
  const { geometry } = conflict;
  const ref = useRef<L.Marker>(null);
  const popupMessage = conflict.properties.description;
  const positions = geometryToPositions(conflict.geometry);

  const dispatch = useDispatch();
  const selected = useSelector(selectCurrentConflictKey) === conflict.properties.key;

  const mouseover = useCallback(() => {
    dispatch(RequestActions.setCurrentConflictKey(conflict.properties.key));
  }, [dispatch, conflict.properties.key]);

  const mouseout = useCallback(() => {
    dispatch(RequestActions.setCurrentConflictKey(null));
  }, [dispatch]);

  const openPopup = useCallback(() => {
    ref?.current?.openPopup();
  }, [ref]);

  if (!positions || !geometry) return null;
  const props = {
    ...defaultPathOptions,
    ...pathOptions.conflict,
    ...restProps,
  };

  switch (geometry.type) {
    case 'Point':
      return <Marker position={positions[0]} {...props} />;

    case 'LineString':
      // I add key={Math.random()} to Polyline because leaflet by default put on top the last rendered element
      // and when 'selected' became true the order on the map became invalid
      return (
        <>
          {selected && (
            <Polyline positions={positions as L.LatLng[]} {...props} color={'#FFF'} stroke={true} weight={10} />
          )}
          <Polyline
            key={Math.random()}
            positions={positions as L.LatLng[]}
            eventHandlers={{
              click: openPopup,
              mouseover,
              mouseout,
            }}
            {...props}
          />
          <LeafletMarker
            ref={ref}
            position={geometryCalculateCenter(geometry)}
            icon={selected ? Markers.WarningActive : Markers.Warning}
            eventHandlers={{
              mouseover,
              mouseout,
            }}
          >
            <PopupMessage messageHtml={popupMessage} />
          </LeafletMarker>
        </>
      );

    case 'Polygon':
    case 'MultiPolygon':
      // I add key={Math.random()} to Polyline because leaflet by default put on top the last rendered element
      // and when 'selected' became true the order on the map became invalid
      return (
        <>
          {selected && <Polygon positions={positions} {...props} color={'#FFF'} stroke={true} weight={10} />}
          <Polygon
            key={Math.random()}
            eventHandlers={{
              click: openPopup,
              mouseover,
              mouseout,
            }}
            positions={positions}
            {...props}
          />
          <LeafletMarker
            ref={ref}
            position={geometryCalculateCenter(geometry)}
            icon={selected ? Markers.WarningActive : Markers.Warning}
            eventHandlers={{
              mouseover,
              mouseout,
            }}
          >
            <PopupMessage messageHtml={popupMessage} />
          </LeafletMarker>
        </>
      );
    default:
      return null;
  }
};
