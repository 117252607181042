import { IFormikOption } from '../../types';
import I18n from 'i18n-js';
import { antwerpZipCodes } from '../config/app.constants';

export const getZipcodesAsFormikOptions = (): IFormikOption[] =>
  antwerpZipCodes.map((value) => ({
    label: `${value} - ${getCity(value)}`,
    value,
  }));

export const getCity = (zipCode: number): string => I18n.translate(`Cities.${zipCode}`);
