import React, { FC, useEffect, useState } from 'react';
import { translate } from '../../../common/translations/translate';
import { Panel } from '../../../common';
import { useGeneralStyles } from '../../../style/generalStyles';
import { useDispatch, useSelector } from 'react-redux';
import { getSgwRequest, getSgwRequestAssignStatus } from '../../../store/selectors/sgwRequest.selectors';
import { SgwRequestActions } from '../../../store/actions';
import { createUseStyles } from 'react-jss';
import { EditButton } from '../../../request/components/details/edit-button.component';
import classNames from 'classnames';
import { Visible } from '../../../common/components/layout/Visible.component';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { Save } from '@material-ui/icons';
import { IWithClassName } from '../../../types';
import { RichEditor } from '../../atoms/RichEditor/RichEditor.component';

const useStyles = createUseStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  text: {
    padding: '0 6px',
    minHeight: '3rem',
    maxHeight: '200px',
    overflowY: 'auto',
  },
  editor: {
    padding: '15px',
  },
});

interface IProps extends IWithClassName {
  id: string;
}

interface ITitleProps {
  changeMode(): void;
  showEditButton: boolean;
}

const Title: FC<ITitleProps> = ({ changeMode, showEditButton }) => (
  <div className={classNames(useGeneralStyles().flexRowSpaceBetween)}>
    {translate('sgw.requests.detail.motivationalPanel.title')}
    <Visible visible={showEditButton}>
      <EditButton title={translate('sgw.requests.detail.phases.generalInfo.edit')} onClick={changeMode} />
    </Visible>
  </div>
);

export const SgwRequestMotivationPanel: FC<IProps> = ({ id, className }) => {
  const C = useStyles();
  const G = useGeneralStyles();
  const dispatch = useDispatch();
  const request = useSelector(getSgwRequest(id));
  const { allowEditMotivation } = useSelector(getSgwRequestAssignStatus);
  const [motivation, setMotivation] = useState<string>('');
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    if (editMode && !!request?.motivationFieldPermit) {
      setMotivation(request.motivationFieldPermit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  const patchRequest = () => {
    request && dispatch(SgwRequestActions.patch({ motivationFieldPermit: motivation, id: request.id }));
    setEditMode(false);
  };

  const changeMode = () => setEditMode(!editMode);

  return (
    <Panel
      data-testid="requestMotivationalPanel"
      title={<Title changeMode={changeMode} showEditButton={allowEditMotivation} />}
      className={className}
    >
      <Visible
        visible={editMode}
        defaultComponent={
          <div className={C.text} dangerouslySetInnerHTML={{ __html: request?.motivationFieldPermit || '' }} />
        }
      >
        <div className={C.form}>
          <RichEditor
            placeholder={translate('sgw.requests.detail.motivationalPanel.title')}
            onChange={setMotivation}
            value={request?.motivationFieldPermit}
            editorClassName={C.editor}
          />
          <Button.Green
            type="button"
            startIcon={<Save fontSize="small" />}
            className={G.marginTop20}
            onClick={patchRequest}
          >
            {translate('Save')}
          </Button.Green>
        </div>
      </Visible>
    </Panel>
  );
};
