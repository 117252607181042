import { createSelector } from '@reduxjs/toolkit';
import { createPagedApiParamsSelector } from '../../common/store/utils/generic.selectors';
import { createAllEntitiesSelector } from '../../common/utils/normalized.util';
import { IRootState } from '../../root.state';
import { ISgwPermitCondition, ISgwPermitConditionsFilter, SgwPermitConditionCategory } from '../../types';

export const selectAllIds = (store: IRootState) => store.sgw.permitConditions.list.allIds;
export const selectById = (store: IRootState) => store.sgw.permitConditions.list.byId;
export const selectSgwPermitConditionsTable = (store: IRootState) => store.sgw.permitConditions.list.table;
export const selectLoading = (store: IRootState) => store.sgw.permitConditions.list.loading;
export const selectExistingReferenceCodes = (store: IRootState) => store.sgw.permitConditions.existingReferenceCodes;
export const selectExistingTitles = (store: IRootState) => store.sgw.permitConditions.existingTitles;

export const getSgwPermitConditionsList = createAllEntitiesSelector(selectAllIds, selectById);

export const getPagedApiParams = createPagedApiParamsSelector(selectSgwPermitConditionsTable);

const initialPermitCondition: Partial<ISgwPermitCondition> = { active: true };

export const getSgwPermitCondition = (id?: string) =>
  createSelector([selectById], (selectById) => (id ? selectById?.[id] : initialPermitCondition));

export const getActivePermitConditionsByCategory = (
  _category: SgwPermitConditionCategory,
  filters?: ISgwPermitConditionsFilter,
) =>
  createSelector([getSgwPermitConditionsList], (permitConditions) =>
    permitConditions.filter(
      ({ active, category, referenceCode, description, title }) =>
        active &&
        category === _category &&
        (!filters?.referenceCode || referenceCode.includes(filters.referenceCode)) &&
        (!filters?.query ||
          description.toLowerCase().includes(filters.query.toLowerCase()) ||
          title.toLowerCase().includes(filters.query.toLowerCase())),
    ),
  );
