import { combineReducers } from 'redux';
import { generateArrayReducer, generateObjectReducer, generatePrimitiveReducer, IMapLocation } from '../../common';
import { ITeam, IMarkerPopup, IWorkOrder, IWorkOrderItem, IWorkOrderItemsFilter } from '../../types';
import {
  setMapLocation,
  setMarkerPopup,
  setTeams,
  setWorkOrderItems,
  setWorkOrderItemsFilter,
  setWorkOrderItemsLoading,
  setWorkOrders,
  setWorkOrdersLoading,
} from './planning.actions';
import { initialPlanningState } from './planning.state';

export const planningReducer = combineReducers({
  mapLocation: generateObjectReducer<IMapLocation>(setMapLocation.type, initialPlanningState.mapLocation),
  markerPopup: generateObjectReducer<IMarkerPopup | null>(setMarkerPopup.type, initialPlanningState.markerPopup),
  teams: generateArrayReducer<ITeam>(setTeams.type, initialPlanningState.teams),
  workOrderItems: generateArrayReducer<IWorkOrderItem>(setWorkOrderItems.type, initialPlanningState.workOrderItems),
  workOrderItemsFilter: generateObjectReducer<IWorkOrderItemsFilter>(
    setWorkOrderItemsFilter.type,
    initialPlanningState.workOrderItemsFilter,
  ),
  workOrderItemsLoading: generatePrimitiveReducer<boolean>(
    setWorkOrderItemsLoading.type,
    initialPlanningState.workOrderItemsLoading,
  ),
  workOrders: generateArrayReducer<IWorkOrder>(setWorkOrders.type, initialPlanningState.workOrders),
  workOrdersLoading: generatePrimitiveReducer<boolean>(
    setWorkOrdersLoading.type,
    initialPlanningState.workOrdersLoading,
  ),
});
