import { CheckBoxOutlineBlankRounded, CheckBoxRounded } from '@material-ui/icons';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { IWithClassName, IWithStyle } from '../../../types';

interface IProps extends IWithStyle, IWithClassName {
  checked?: boolean;
  onClick?: () => void;
}

export const Checkbox: FunctionComponent<IProps> = ({ checked = false, style, ...props }) =>
  checked ? (
    <CheckBoxRounded
      color="secondary"
      style={{ cursor: 'pointer', ...style }}
      data-testid="CheckBoxChecked"
      {...props}
    />
  ) : (
    <CheckBoxOutlineBlankRounded style={{ cursor: 'pointer', ...style }} data-testid="CheckBoxUnchecked" {...props} />
  );
