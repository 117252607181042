import { latLngBounds, LatLngExpression } from 'leaflet';
import React, { FunctionComponent } from 'react';
import { Marker } from 'react-leaflet';
import { BaseMap } from './BaseMap.component';
import { Markers } from './marker-icon';

interface IProps {
  coordinates: LatLngExpression;
}

export const BaseMapWithMarker: FunctionComponent<IProps> = ({ coordinates }) => (
  <BaseMap bounds={latLngBounds([coordinates])} height={400} maxZoom={19}>
    <Marker position={coordinates} icon={Markers.Blue} />
  </BaseMap>
);
