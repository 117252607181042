import { useAtom } from 'jotai';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import ModalDialog from '../../../common/components/modal-dialog/modal-dialog.component';
import { translate } from '../../../common/translations/translate';
import { SgwRequestActions } from '../../../store/actions';
import { getSgwRequestReschedulingsSortedById } from '../../../store/selectors/sgwRequestRescheduling.selectors';
import { IRouterWithId } from '../../../types';
import { rejectModalAtom, applyModalAtom, considerModalAtom } from '../../organisms';

export const RequestReschedulingModals: FC = () => {
  const dispatch = useDispatch();
  const { id: requestId } = useParams<IRouterWithId>();
  const reschedulings = useSelector(getSgwRequestReschedulingsSortedById);
  const activeRescheduling = reschedulings[reschedulings.length - 1];
  const [rejectModal, setRejectModal] = useAtom(rejectModalAtom);
  const [considerModal, setConsiderModal] = useAtom(considerModalAtom);
  const [applyModal, setApplyModal] = useAtom(applyModalAtom);

  const rejectRescheduling = () => {
    dispatch(
      SgwRequestActions.reschedulings.reject({
        requestId: requestId,
        reschedulingId: `${activeRescheduling.id}`,
      }),
    );
    setRejectModal(false);
  };

  const considerRescheduling = () => {
    dispatch(
      SgwRequestActions.reschedulings.consider({ requestId: requestId, reschedulingId: `${activeRescheduling.id}` }),
    );
    setConsiderModal(false);
  };

  const applyRescheduling = () => {
    dispatch(
      SgwRequestActions.reschedulings.apply({ requestId: requestId, reschedulingId: `${activeRescheduling.id}` }),
    );
    setApplyModal(false);
  };

  return (
    <>
      <ModalDialog
        maxWidth="sm"
        onClose={() => setRejectModal(false)}
        title={translate('sgw.requests.detail.requestRescheduling.rejectModal.title')}
        visible={rejectModal}
        okButtonText={translate('sgw.requests.detail.requestRescheduling.rejectButton')}
        onConfirm={rejectRescheduling}
      >
        {translate('sgw.requests.detail.requestRescheduling.rejectModal.description')}
      </ModalDialog>
      <ModalDialog
        maxWidth="sm"
        onClose={() => setConsiderModal(false)}
        title={translate('sgw.requests.detail.requestRescheduling.considerModal.title')}
        visible={considerModal}
        okButtonText={translate('sgw.requests.detail.requestRescheduling.considerButton')}
        onConfirm={considerRescheduling}
      >
        {translate('sgw.requests.detail.requestRescheduling.considerModal.description')}
      </ModalDialog>
      <ModalDialog
        maxWidth="sm"
        onClose={() => setApplyModal(false)}
        title={translate('sgw.requests.detail.requestRescheduling.applyModal.title')}
        visible={applyModal}
        okButtonText={translate('sgw.requests.detail.requestRescheduling.applyButton')}
        onConfirm={applyRescheduling}
      >
        {translate('sgw.requests.detail.requestRescheduling.applyModal.description')}
      </ModalDialog>
    </>
  );
};
