import * as I18n from 'i18n-js';
import * as React from 'react';
import { Panel } from '../../common';
import { FileBox } from '../../common/components/attachment';
import { BulkDownloadTypes, IStoredFile } from '../../types';
import { FunctionComponent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { StorageActions } from '../../store/actions';

interface IProps {
  attachments: IStoredFile[];
  notificationId: number;
}

export const NotificationAttachments: FunctionComponent<IProps> = ({ attachments, notificationId }) => {
  const dispatch = useDispatch();
  const onDownloadZip = useCallback(
    (fileIds: string[]) => {
      dispatch(
        StorageActions.bulkDownload({
          type: BulkDownloadTypes.notification,
          id: notificationId,
          fileIds,
        }),
      );
    },
    [dispatch, notificationId],
  );

  return (
    <Panel title={I18n.t('Gallery.Attachments')}>
      <FileBox
        onDownloadZip={onDownloadZip}
        placeholder={I18n.translate('Gallery.NoAttachments')}
        storedFiles={attachments}
      />
    </Panel>
  );
};
