import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { PaymentActions } from './payments.actions';
import { PaymentsApi } from './payments.api';
import { RequestActions } from '../../../store/actions';

function* onPatchPaymentState({ payload }: ReturnType<typeof PaymentActions.patchState>): SagaIterator {
  yield call(PaymentsApi.patchState, payload);

  yield put(RequestActions.fetch(payload.requestId));
  yield put(RequestActions.fetchExtensions(`${payload.requestId}`));
}

function* onPatchCostState({ payload }: ReturnType<typeof PaymentActions.patchCostState>): SagaIterator {
  yield call(PaymentsApi.patchCostState, payload);

  yield put(RequestActions.fetch(payload.requestId));
  yield put(RequestActions.fetchExtensions(`${payload.requestId}`));
}

export function* paymentsSaga(): SagaIterator {
  yield takeLatest(PaymentActions.patchCostState, onPatchCostState);
  yield takeLatest(PaymentActions.patchState, onPatchPaymentState);
}
