import * as React from 'react';
import { Route } from 'react-router';
import { PlanningPage } from './pages/planning.page';
import { appUrls } from '../common/config/url.constants';

export const PlanningRoutes = () => (
  <>
    <Route exact={true} path={appUrls.planning.base} component={PlanningPage} />
  </>
);
