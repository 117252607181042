import { CONFIG, IApiPromise, IPagedApiParams } from '../common';
import axios from 'axios';
import { IWithId } from '../common/utils/normalized.util';

export const generateBasicRestApi = <TEntity>(baseUrl: string) => {
  const url = `${CONFIG.backOfficeApi}${baseUrl}`;
  return {
    fetch: (id: string): IApiPromise<TEntity> => axios.get(`${url}/${id}`),
    fetchList: (params: IPagedApiParams): IApiPromise<TEntity[]> => axios.get(url, { params }),
    save: (entity: Partial<IWithId<TEntity>>): IApiPromise<TEntity> => {
      const { id, ...data } = entity;
      return id ? axios.patch(`${url}/${id}`, data) : axios.post(url, data);
    },
  };
};
