import { Grid, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Header } from '../../../common/components/layout/Header.component';
import { Visible } from '../../../common/components/layout/Visible.component';
import { appUrls } from '../../../common/config/url.constants';
import { translate } from '../../../common/translations/translate';
import { StatusIcon } from '../../../request/components/icons/status-icon.component';
import { selectSgwRequest } from '../../../store/selectors/sgwRequest.selectors';
import { SgwRequestState } from '../../../types';
import { UserAssignedLabel } from '../../atoms/UserAssignedLabel/UserAssignedLabel.component';
import { useRequestMetadata } from '../../../hooks';

export const useHeaderStyles = makeStyles({
  header: {
    marginLeft: '0px',
    marginRight: '20px',
  },
  rightHeader: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    marginLeft: '0px',
    marginRight: '20px',
  },
  container: {
    maxWidth: 'unset',
  },
});

export const RequestDetailHeader: FC = () => {
  const C = useHeaderStyles();
  const request = useSelector(selectSgwRequest);
  const metadata = useRequestMetadata();

  return (
    <Header
      data-testid="RequestDetailHeader"
      backButtonUrl={appUrls.sgw.requests.base}
      containerClassName={C.container}
      extraHeader={
        <Visible visible={request?.state !== SgwRequestState.draft}>
          <UserAssignedLabel />
        </Visible>
      }
    >
      <Grid container className={C.header}>
        <Grid item xs={12}>
          <span className={C.title}>
            {translate('sgw.requests.detail.title', { referenceId: request?.referenceId })}
          </span>
          <StatusIcon statusState={request?.state} metadataStates={metadata?.sgwStates} />
        </Grid>
      </Grid>
    </Header>
  );
};
