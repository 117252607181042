import { createSelector } from '@reduxjs/toolkit';
import { IRootState } from '../../root.state';
import { AppFeatureFlag } from '../../types';

export const selectAppFeatures = (state: IRootState) => state.appFeatures;

export const getAppFeature = (featureToBeChecked: AppFeatureFlag) =>
  createSelector([selectAppFeatures], (features) => features[featureToBeChecked]);

export const isAppFeatureEnabled = {
  [AppFeatureFlag.integrateDigipolisArcGis]: getAppFeature(AppFeatureFlag.integrateDigipolisArcGis),
  [AppFeatureFlag.integrateDigipolisLogin]: getAppFeature(AppFeatureFlag.integrateDigipolisLogin),
  [AppFeatureFlag.integrateDigipolisOrder]: getAppFeature(AppFeatureFlag.integrateDigipolisOrder),
  [AppFeatureFlag.sentryLoggingBackoffice]: getAppFeature(AppFeatureFlag.sentryLoggingBackoffice),
};
