import * as React from 'react';
import { FunctionComponent } from 'react';
import { BasemapLayer, FeatureLayer, DynamicMapLayer, TiledMapLayer } from 'react-esri-leaflet';
import { IMapLayer, ISgwPhase, ISgwRequest, MapLayerTypes } from '../../../types';
import { OpenStreetMapLayer } from './OpenStreetMapLayer.component';
import { PHASE_TIME_OVERLAYS, REQUEST_TIME_OVERLAYS } from '../../config/map.config';
import { BaseLayer } from './base-layer.component';
import { SatelliteLayer } from './satellite-layer.component';

interface IProps {
  layer: IMapLayer;
  phase?: ISgwPhase;
  request?: ISgwRequest | null;
}

export const MapLayer: FunctionComponent<IProps> = ({ layer, phase, request }) => {
  const timeConstraints = (): { from: Date; to: Date } | undefined => {
    if (PHASE_TIME_OVERLAYS.includes(layer.layer!) && phase)
      return { from: new Date(phase?.dateFrom!), to: new Date(phase?.dateUntil!) };
    if (REQUEST_TIME_OVERLAYS.includes(layer.layer!) && request)
      return { from: new Date(request.dateFrom), to: new Date(request.dateUntil) };
  };

  switch (layer.type) {
    case MapLayerTypes.FEATURE:
      return <FeatureLayer url={layer.url!} onEachFeature={layer.onEachFeature} style={layer.styles} />;
    case MapLayerTypes.SATELLITE:
      return <SatelliteLayer />;
    case MapLayerTypes.DYNAMIC:
      return <DynamicMapLayer layers={[layer.layer]} url={layer.url!} {...timeConstraints()} />;
    case MapLayerTypes.TILED:
      return <TiledMapLayer url={layer.url!} />;
    case MapLayerTypes.BASE_MAP:
      return <BasemapLayer name={layer.baseMap!} />;
    case MapLayerTypes.BASE:
      return <BaseLayer />;
    case MapLayerTypes.OPEN_STREET_MAP:
      return <OpenStreetMapLayer id={layer.id} url={layer.url!} />;
  }
};
