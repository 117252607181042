import * as React from 'react';
import { FC, PropsWithChildren } from 'react';
import { Grid } from '@material-ui/core';
import { IWithClassName } from '../../../types';
import { createUseStyles } from 'react-jss';
import { useScreenSize } from '../../../hooks';
import classNames from 'classnames';

interface IProps extends IWithClassName {
  label: string;
  visible?: boolean;
  labelCentered?: boolean;
}

const useStyles = createUseStyles({
  label: { fontWeight: 600 },
  labelCentered: { display: 'flex', alignItems: 'center' },
  valueMobile: { marginTop: -15 },
});

export const LabelValue: FC<PropsWithChildren<IProps>> = ({
  className,
  children,
  label,
  labelCentered = false,
  visible = true,
}) => {
  const { isMobile } = useScreenSize();
  const C = useStyles();

  return visible ? (
    <>
      <Grid item xs={12} md={4} className={classNames(className, C.label, labelCentered && C.labelCentered)}>
        {label}
      </Grid>
      <Grid item xs={12} md={8} className={classNames(className, isMobile && C.valueMobile)}>
        {children}
      </Grid>
    </>
  ) : null;
};
