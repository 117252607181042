import * as L from 'leaflet';
import { ControlPosition, LatLng, LatLngBounds } from 'leaflet';
import { IGeometry } from './geojson.types';
import { Polygon } from 'geojson';
import { IComponentProps, IWithId } from './generic.types';
import { RefObject } from 'react';
import { MarkerProps } from 'react-leaflet';
import { Map } from 'leaflet';
import { Basemaps } from 'esri-leaflet';

export enum MapLayerTypes {
  BASE,
  BASE_MAP,
  DYNAMIC,
  FEATURE,
  SATELLITE,
  TILED,
  OPEN_STREET_MAP,
}

export interface IMapLayer {
  id: number;
  baseMap?: Basemaps;
  checked?: boolean;
  layer?: number;
  name: string;
  onEachFeature?: (...args: any[]) => void;
  url?: string;
  styles?: any;
  type: MapLayerTypes;
}

export interface MapDrawTypes {
  circle?: boolean;
  circlemarker?: boolean;
  marker?: boolean;
  polygon?: boolean;
  polyline?: boolean;
  rectangle?: boolean;
}

export interface IMapDrawProps {
  beginDrawingWithType?: 'circle' | 'circlemarker' | 'marker' | 'polygon' | 'polyline' | 'rectangle';
  deletable: boolean;
  drawTypes: MapDrawTypes;
  editable: boolean;
  onCreated: (geometry: IGeometry) => void;
  position: ControlPosition;
  visible: boolean;
  allowMultiLines: boolean;
}

export interface IBaseMapControl extends IComponentProps {
  onClick?: () => void;
  tooltip?: string;
}

export interface IBaseMapOverviewLayer {
  bounds?: LatLngBounds;
  geometries?: IGeometry[];
  onClick?: () => void;
  showLayer?: boolean;
}

export interface IBaseMapContext {
  addOverlay: (event: L.LayersControlEvent) => void;
  baseLayers: IMapLayer[];
  flyToBounds: (bounds?: LatLngBounds, maxZoom?: number) => void;
  mapRef: RefObject<Map> | null;
  overlayLayers: IMapLayer[];
  removeOverlay: (event: L.LayersControlEvent) => void;
  selectBaseLayer: (event: L.LayersControlEvent) => void;
  selectedBaseLayer: number;
  selectedOverlayLayers: number[];
  setBaseLayers: (layers: IMapLayer[]) => void;
  setOverlayLayers: (layers: IMapLayer[]) => void;
}

export interface ICarFreeZoneFeatureProperties {
  FIRST_NAAM_GEBIED: string;
  GISID_GEBIED: string;
  HANDHAVING: string;
  OBJECTID: number;
  SHAPE_Area: number;
  SHAPE_Length: number;
  STATUS: string;
  UITZONDERING: string;
  VENSTERTIJD: string;
  ZONE: string;
}

export interface ICarFreeZoneFeature extends IWithId {
  geometry: Polygon;
  properties: ICarFreeZoneFeatureProperties;
  type: 'Feature';
}

export interface IMarker extends MarkerProps {
  ondrag?(e: L.LeafletEvent): void;
  ondragstart?(e: L.LeafletEvent): void;
  ondragend?(e: L.LeafletEvent): void;
  color?: string;
  position: LatLng;
  ref?: RefObject<any>;
}

export interface IDraggableMarker extends IMarker {
  draggable?: boolean;
  onDragMarker?: (newPosition: LatLng) => void;
  onDragMarkerEnd?: () => void;
  onDragMarkerStart?: () => void;
}
