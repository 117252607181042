import { Paper, Tab, Tabs } from '@material-ui/core';
import React, { FC, useEffect, useMemo } from 'react';
import { Container } from '../../../common';
import { useHistory, useLocation } from 'react-router';
import { ITab, ITabGroup, IWithClassName } from '../../../types';
import { Visible } from '../../../common/components/layout/Visible.component';
import { useGeneralStyles } from '../../../style/generalStyles';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useRouterMenuStyles } from '../../../style/routerMenu.styles';

interface IProps extends IWithClassName {
  tabGroups: ITabGroup[];
}

export const GroupedRouterMenu: FC<IProps> = ({ tabGroups = [], className }) => {
  const C = useRouterMenuStyles();
  const history = useHistory();
  const location = useLocation();
  const { flexRow } = useGeneralStyles();

  const getVisibleTabs = (tabs: ITab[]) => tabs.filter(({ visible }) => visible === undefined || visible);
  const getVisibleGroups = (groups: ITabGroup[]) =>
    groups.filter(({ tabs, visible }) => (visible === undefined || visible) && getVisibleTabs(tabs).length);

  const allTabs = tabGroups.flatMap((group) => group.tabs);
  const allTabsSorted = useMemo(
    () => [...allTabs].sort(({ route: r1 }, { route: r2 }) => r2.length - r1.length),
    [allTabs],
  );
  const allVisibleTabs = getVisibleTabs(allTabs);

  const selectedRoute = useMemo(
    () => allTabsSorted.find(({ route }) => location.pathname?.includes(route))?.route,
    [location.pathname, allTabsSorted],
  );

  const selectedRouteInGroup = (groups: ITab[]) =>
    groups.some(({ route }) => route === selectedRoute) ? selectedRoute : false;

  useEffect(() => {
    !selectedRoute && allVisibleTabs.length && history.push(allVisibleTabs[0].route);
  }, [history, selectedRoute, allVisibleTabs]);

  return (
    <Visible visible={!!allVisibleTabs.length}>
      <Paper square className={className}>
        <Container className={classNames(flexRow, C.groups)}>
          {getVisibleGroups(tabGroups).map((group) => (
            <div className={C.group} key={group.name}>
              <div className={C.groupName}>{group.name}</div>
              <Tabs className={C.tabs} value={selectedRouteInGroup(group.tabs)} indicatorColor="primary">
                {getVisibleTabs(group.tabs).map(({ label, route }) => (
                  <Tab
                    data-testid={`tab-${route}${route === selectedRoute ? '-selected' : ''}`}
                    key={label?.toString()}
                    label={
                      <Link className={C.link} to={route}>
                        {label}
                      </Link>
                    }
                    value={route}
                  />
                ))}
              </Tabs>
            </div>
          ))}
        </Container>
      </Paper>
    </Visible>
  );
};
