import { Grid } from '@material-ui/core';
import * as I18n from 'i18n-js';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Connect, Container, WithRouter } from '../../common';
import { Header } from '../../common/components/layout/Header.component';
import { Loader } from '../../common/components/layout/loader.component';
import { appUrls } from '../../common/config/url.constants';
import { IUser } from '../../common/types/user';
import { ModuleGeneral } from '../components/module-general.component';
import { ModuleMaintenance } from '../components/module-maintenance.component';
import {
  deleteModule,
  fetchBatches,
  fetchModule,
  fetchPlaces,
  fetchStates,
  updateModule,
} from '../store/modules.actions';
import { IModule } from '../types/module';
import { IModuleBatch } from '../types/module-batch';
import { IModulePlace } from '../types/module-place';
import { IModuleState } from '../types/module-state';

interface IProps
  extends Partial<
    RouteComponentProps<{
      id: string;
    }>
  > {
  batches?: IModuleBatch[];
  fetchBatches?: typeof fetchBatches;
  fetchModule?: typeof fetchModule;
  deleteModule?: typeof deleteModule;
  fetchPlaces?: typeof fetchPlaces;
  fetchStates?: typeof fetchStates;
  updateModule?: typeof updateModule;
  module?: IModule;
  moduleLoading?: boolean;
  places?: IModulePlace[];
  states?: IModuleState[];
  user: IUser | null;
}

@WithRouter
@Connect(
  ({ modules, user }) => ({
    batches: modules.batches.batches,
    module: modules.module,
    moduleLoading: modules.moduleLoading,
    places: modules.places,
    states: modules.states,
    user: user.user,
  }),
  {
    deleteModule,
    fetchBatches,
    fetchModule,
    fetchPlaces,
    fetchStates,
    updateModule,
  },
)
export class ModuleDetailPage extends React.Component<IProps> {
  public componentDidMount(): void {
    const { id } = this.props.match!.params;
    this.props.fetchModule!(id);
    this.props.fetchBatches!();
    this.props.fetchStates!();
    this.props.fetchPlaces!();
  }

  public render(): React.ReactNode {
    return this.props.moduleLoading ? (
      <Loader />
    ) : (
      <>
        <div style={{ marginTop: -20 }}>
          <Header backButtonUrl={appUrls.signs.modules.base}>
            {I18n.t('DetailModule')} {this.props.module!.id}
          </Header>
        </div>
        <Container>
          <Grid container={true} spacing={4}>
            <Grid item={true} xs={6}>
              <ModuleGeneral
                module={this.props.module!}
                states={this.props.states!}
                updateModule={this.props.updateModule!}
                deleteModule={this.deleteModule}
                user={this.props.user}
              />
            </Grid>
            <Grid item={true} xs={6}>
              <ModuleMaintenance
                batches={this.props.batches!}
                module={this.props.module!}
                places={this.props.places!}
                updateModule={this.props.updateModule!}
              />
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }

  private deleteModule = () => {
    if (this.props.module) {
      this.props.deleteModule!(this.props.module.id);
    }
  };
}
