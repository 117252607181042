import * as React from 'react';
import AsignButton from './asign-button';
import { IAsignButtonProps } from '../../../types';

export const Button = {
  Black: (props: IAsignButtonProps) => <AsignButton {...props} className={`black-button ${props.className}`} />,
  Blue: (props: IAsignButtonProps) => (
    <AsignButton {...props} className={`blue-button ${props.className}`} color="primary" />
  ),
  Green: (props: IAsignButtonProps) => (
    <AsignButton {...props} className={`green-button ${props.className}`} color="secondary" />
  ),
  Link: (props: IAsignButtonProps) => <AsignButton {...props} className={`link-button ${props.className}`} />,
  Red: (props: IAsignButtonProps) => (
    <AsignButton
      {...props}
      className={props.disabled ? `gray-button ${props.className}` : `red-button ${props.className}`}
    />
  ),
  White: (props: IAsignButtonProps) => <AsignButton {...props} className={`white-button ${props.className}`} />,
  Gray: (props: IAsignButtonProps) => <AsignButton {...props} className={`gray-button ${props.className}`} />,
};
