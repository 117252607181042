import { Checkbox, FormControlLabel, Tooltip } from '@material-ui/core';
import * as React from 'react';
import { COLORS, enumToArray } from '../../common';
import { WORK_ORDER_ITEM_TYPES } from '../planning.constants';
import { getWorkOrderItemTypeIcon } from '../utils/work-order-item-type-icon';
import { translateIgnoreTS } from '../../common/translations/translate';

interface IProps {
  types: WORK_ORDER_ITEM_TYPES[];
  typesChange: (types: WORK_ORDER_ITEM_TYPES[]) => void;
}

export class WorkOrderItemTypeFilter extends React.Component<IProps> {
  public render(): React.ReactNode {
    return enumToArray(WORK_ORDER_ITEM_TYPES).map((type: WORK_ORDER_ITEM_TYPES) => (
      <div key={type} data-testid={`WorkOrderItemTypeFilter-${type}`}>
        <Tooltip title={translateIgnoreTS(type)}>
          <FormControlLabel
            style={{ margin: 0 }}
            control={
              <Checkbox
                aria-label={`WorkOrderItemTypeCheckbox-${type}`}
                value={type}
                checked={this.isChecked(type)}
                onChange={this.handleChange}
              />
            }
            label={
              <i
                className="material-icons"
                style={{
                  color: type === WORK_ORDER_ITEM_TYPES.PICKUP_OVERDUE ? COLORS.RED : COLORS.GREY,
                  fontSize: 16,
                  marginTop: 3,
                }}
              >
                {getWorkOrderItemTypeIcon(type)}
              </i>
            }
          />
        </Tooltip>
      </div>
    ));
  }

  private isChecked(type: WORK_ORDER_ITEM_TYPES): boolean {
    return this.props.types.indexOf(type) >= 0;
  }

  private handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const type: WORK_ORDER_ITEM_TYPES = e.target.value as WORK_ORDER_ITEM_TYPES;
    if (this.isChecked(type)) {
      this.props.typesChange(this.props.types.filter((other) => other !== type));
    } else {
      this.props.typesChange([...this.props.types, type]);
    }
  };
}
