import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as React from 'react';
import { translate } from '../../../common/translations/translate';

interface IProps {
  onClose: () => void;
  onConfirm: () => void;
}

export class DeleteModuleDialog extends React.Component<IProps> {
  public render(): React.ReactNode {
    return (
      <div style={{ position: 'absolute', backgroundColor: 'white' }}>
        <Dialog open={true} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{translate('DeleteModule')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{translate('DeleteModuleConfirmation')}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={this.props.onClose}>
              {translate('refuse')}
            </Button>
            <Button variant="contained" color="secondary" onClick={this.onConfirm}>
              {translate('accept')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  private onConfirm = () => this.props.onConfirm();
}
