import { combineReducers } from 'redux';
import {
  generateArrayReducer,
  generateObjectReducer,
  generatePrimitiveReducer,
  IPagedTable,
  ISortingDirection,
} from '../../common';
import { IModule } from '../types/module';
import { IModuleBatch } from '../types/module-batch';
import { IModuleCounters } from '../types/module-counters';
import { IModulePlace } from '../types/module-place';
import { IModuleState } from '../types/module-state';
import { IModuleAppVersion, IModulesFilter } from '../types/modules-filter';
import {
  ModulesActions,
  setBatches,
  setBatchesForReorder,
  setBatchesUsed,
  setModule,
  setModuleCounters,
  setModuleLoading,
  setModulesLoading,
  setPlaces,
  setStates,
} from './modules.actions';
import { IBatchesForReorder, initialModulesState } from './modules.state';
import { createPagedTableReducer } from '../../store/reducers/pagedTable.reducer';
import { baseInitialFilter } from '../../common/config/paging.constants';

export const moduleListInitialFilter: IPagedTable<IModulesFilter> = {
  ...baseInitialFilter,
  filters: {
    appVersions: [],
    batches: [],
    coupled: false,
    critical: null,
    id: '',
    inMaintenance: null,
    places: [],
    signId: '',
    states: [],
  },
  sorting: { key: 'id', direction: ISortingDirection.asc },
};

export const modulesReducer = combineReducers({
  batches: combineReducers({
    batches: generateArrayReducer<IModuleBatch>(setBatches.type, initialModulesState.batches.batches),
    batchesUsed: generateArrayReducer<IModuleBatch>(setBatchesUsed.type, initialModulesState.batches.batchesUsed),
    batchesForReorder: generateObjectReducer<IBatchesForReorder>(
      setBatchesForReorder.type,
      initialModulesState.batches.batchesForReorder,
    ),
  }),
  list: createPagedTableReducer<IModule, IModulesFilter>({
    fetchAction: ModulesActions.list.fetch.type,
    addEntitiesActions: [ModulesActions.list.set.type],
    setAllIdsAction: ModulesActions.list.set.type,
    setParamsActions: [ModulesActions.list.fetch.type, ModulesActions.list.setParams.type],
    initialParamsState: moduleListInitialFilter,
  }),
  module: generateObjectReducer<IModule | null>(setModule.type, initialModulesState.module),
  moduleCounters: generateObjectReducer<IModuleCounters>(setModuleCounters.type, initialModulesState.moduleCounters),
  moduleLoading: generatePrimitiveReducer<boolean>(setModuleLoading.type, initialModulesState.moduleLoading),
  modulesLoading: generatePrimitiveReducer<boolean>(setModulesLoading.type, initialModulesState.modulesLoading),
  places: generateArrayReducer<IModulePlace>(setPlaces.type, initialModulesState.places),
  appVersions: generateArrayReducer<IModuleAppVersion>(ModulesActions.setAppVersions.type, initialModulesState.places),
  states: generateArrayReducer<IModuleState>(setStates.type, initialModulesState.states),
});
