import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, FormattedDateTime, IPaging, ISorting } from '../../common';
import { SyncButton } from '../../common/components/buttons/SyncButton.component';
import { GenericTablePanel } from '../../common/components/table/GenericTablePanel.component';
import { MoovActions } from '../../common/store/moov/moov.actions';
import {
  getMoovTemplatesList,
  selectMoovTable,
  selectSyncTemplatesLoading,
} from '../../common/store/moov/moov.selectors';
import { Auth } from '../../components';
import { ICarFreeZoneAclActions, IMoovTemplate } from '../../types';
import { translate } from '../../common/translations/translate';

const useStyles = makeStyles({
  container: { paddingTop: 20 },
  header: { display: 'flex', justifyContent: 'space-between' },
});

export const MoovTemplates: FunctionComponent = () => {
  const dispatch = useDispatch();
  const C = useStyles();
  const isSyncing = useSelector(selectSyncTemplatesLoading);
  const templates = useSelector(getMoovTemplatesList);
  const { paging, sorting } = useSelector(selectMoovTable);

  useEffect(() => {
    dispatch(MoovActions.list.fetch({}));
  }, [dispatch]);

  const onChangeSorting = useCallback(
    (sorting: ISorting) => {
      dispatch(MoovActions.list.fetch({ sorting }));
    },
    [dispatch],
  );

  const onChangePaging = useCallback(
    (paging: IPaging) => {
      dispatch(MoovActions.list.fetch({ paging }));
    },
    [dispatch],
  );

  const syncTemplates = useCallback(() => {
    dispatch(MoovActions.syncTemplates());
  }, [dispatch]);

  const TableHeader: FunctionComponent = () => (
    <div className={C.header}>
      <span>{translate('carFreeZones.moovTemplates.overview')}</span>
      <Auth acl={ICarFreeZoneAclActions.syncMoveTemplates}>
        <SyncButton isSyncing={isSyncing} onClick={syncTemplates} dataTestId="CarFreeZonesSyncTemplates">
          {translate('carFreeZones.moovTemplates.syncTemplates')}
        </SyncButton>
      </Auth>
    </div>
  );

  return (
    <Container className={C.container}>
      <Auth acl={ICarFreeZoneAclActions.viewMoovTemplates}>
        <GenericTablePanel<IMoovTemplate>
          labelUnavailable={translate('carFreeZones.moovTemplates.unavailable')}
          dataTestId="MoovTemplatesListTable"
          onChangeSorting={onChangeSorting}
          onChangePaging={onChangePaging}
          paging={paging}
          sorting={sorting}
          records={templates}
          title={<TableHeader />}
          columnKey="id"
          columns={[
            {
              label: translate('carFreeZones.moovTemplates.reference'),
              name: 'id',
              sortable: true,
            },
            {
              label: translate('carFreeZones.moovTemplates.name'),
              name: 'name',
              sortable: true,
            },
            {
              label: translate('carFreeZones.moovTemplates.createdAt'),
              name: 'createdAt',
              renderer: (createdAt: string) => <FormattedDateTime dateTime={createdAt} />,
              sortable: true,
            },
            {
              label: translate('carFreeZones.moovTemplates.updatedAt'),
              name: 'updatedAt',
              renderer: (updatedAt: string) => <FormattedDateTime dateTime={updatedAt} />,
              sortable: true,
            },
          ]}
        />
      </Auth>
    </Container>
  );
};
