import React, { FC } from 'react';
import { IRequestMetadataState } from '../../../types';
import { LabelChip } from '../../../common/components/layout/LabelChip.component';
import { Visible } from '../../../common/components/layout/Visible.component';

interface IProps {
  statusState?: string;
  metadataStates?: IRequestMetadataState[];
}
export const StatusIcon: FC<IProps> = ({ statusState, metadataStates }) => {
  const metadataState = metadataStates?.find(({ state }) => state === statusState);

  return (
    <Visible visible={!!metadataState}>
      <LabelChip color={metadataState?.color} label={metadataState?.displayName} />
    </Visible>
  );
};
