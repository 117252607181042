import { IGeneralMetadata } from '../../types';

export const initialMetadataState: IGeneralMetadata = {
  loaded: false,
  countries: [],
  sgw: {
    natureofworks: [],
    trafficmeasures: [],
    districts: [],
  },
};
