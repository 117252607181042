import { combineReducers } from 'redux';
import { generateBooleanReducer } from '../../utils/generic.reducers';
import { MoovActions } from '../moov.actions';
import { createPagedTableReducer } from '../../../../store/reducers/pagedTable.reducer';
import { IMoovTemplate } from '../../../../types';
import { IPagedTable } from '../../../types/paging';
import { ISortingDirection } from '../../../types/sorting';
import { baseInitialFilter } from '../../../config/paging.constants';

const moovTemplateListInitialFilter: IPagedTable = {
  ...baseInitialFilter,
  sorting: { key: 'id', direction: ISortingDirection.asc },
};

export default combineReducers({
  list: createPagedTableReducer<IMoovTemplate>({
    fetchAction: MoovActions.list.fetch.type,
    setAllIdsAction: MoovActions.list.set.type,
    addEntitiesActions: [MoovActions.setAll.type, MoovActions.list.set.type],
    setParamsActions: [MoovActions.list.fetch.type, MoovActions.list.setParams.type],
    initialParamsState: moovTemplateListInitialFilter,
  }),
  syncTemplatesLoading: generateBooleanReducer([MoovActions.setSyncTemplatesLoading.type]),
});
