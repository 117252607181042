import moment from 'moment';
import * as React from 'react';
import { DateFormat } from '../../../types';

interface IProps {
  dateTime: string | undefined;
}

export const FormattedDateTime = ({ dateTime }: IProps) => (
  <>{dateTime && moment(dateTime).format(DateFormat.dateTime)}</>
);
