import React, { FC } from 'react';
import { Route, Switch } from 'react-router';
import { appUrls } from '../../common/config/url.constants';
import {
  EditNatureOfWorksPage,
  CreateNatureOfWorksPage,
  DetailNatureOfWorksPage,
  NatureOfWorksPage,
} from '../pages/sgw/manage/NatureOfWorks';

export const NatureOfWorksPageRoutes: FC = () => (
  <Switch>
    <Route exact path={appUrls.sgw.manage.natureOfWorks.base}>
      <NatureOfWorksPage />
    </Route>
    <Route exact path={appUrls.sgw.manage.natureOfWorks.create}>
      <CreateNatureOfWorksPage />
    </Route>
    <Route exact path={appUrls.sgw.manage.natureOfWorks.detail()}>
      <DetailNatureOfWorksPage />
    </Route>
    <Route exact path={appUrls.sgw.manage.natureOfWorks.edit()}>
      <EditNatureOfWorksPage />
    </Route>
  </Switch>
);
