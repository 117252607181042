import * as I18n from 'i18n-js';
import * as React from 'react';
import { FormattedAddress, FormattedDate, Panel } from '../../common';
import { ISign } from '../types/sign';
import { IRequest } from '../../types';
import { appUrls } from '../../common/config/url.constants';

interface IProps {
  sign: ISign;
  request?: IRequest;
}

export class SignRequest extends React.Component<IProps> {
  public render(): React.ReactNode {
    const { sign, request } = this.props;
    return sign.parkingBanIntake && request ? (
      <Panel title={I18n.t('LinkedRequest')}>
        <dl>
          <dt>{I18n.t('RequestID')}</dt>
          <dd>
            <a href={appUrls.requests.detail(request.id, true)} target="_parent">
              {request.id}
            </a>
          </dd>
          <dt>{I18n.t('Location')}</dt>
          <dd>
            <FormattedAddress address={sign.parkingBanIntake} />
          </dd>
          <dt>{I18n.t('Period')}</dt>
          <dd>
            <FormattedDate date={request.dateFrom} /> - <FormattedDate date={request.dateUntil} />
            {request.onlyOnWeekdays && <span>{I18n.t('OnlyOnWeekdays')}</span>}
          </dd>
          {!request.entireDay && (
            <>
              <dt>{I18n.t('Between')}</dt>
              <dd>
                {request.timeFrom} - {request.timeUntil}
              </dd>
            </>
          )}
        </dl>
      </Panel>
    ) : null;
  }
}
