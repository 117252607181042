import React, { FunctionComponent, PropsWithChildren } from 'react';
import { PanelTitle } from './panel-title.component';

interface IProps {
  title?: string | React.ReactNode;
}

export const PanelContent: FunctionComponent<PropsWithChildren<IProps>> = ({ title, children }) => (
  <div style={{ padding: '15px', position: 'relative' }}>
    <PanelTitle style={{ marginTop: 0 }}>{title}</PanelTitle>
    {children}
  </div>
);
