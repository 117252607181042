import * as React from 'react';
import { FunctionComponent } from 'react';
import { Auth } from '../../atoms';
import { IUserManagementAclActions } from '../../../types';
import { FormattedDateTime, PanelAction } from '../../../common';
import { Grid, Paper } from '@material-ui/core';
import { LabelValue } from '../../../common/components/layout/LabelValue.component';
import { PanelContent } from '../../../common/components/panel/panel-content.component';
import { useHistory } from 'react-router';
import { IUser, userGetRoleNames } from '../../../common/types/user';
import { Edit } from '@material-ui/icons';
import { appUrls } from '../../../common/config/url.constants';
import { translate } from '../../../common/translations/translate';
import { ModalButton } from '../../../common/components/buttons/modal-button';
import { useGeneralStyles } from '../../../style/generalStyles';
import { useDispatch, useSelector } from 'react-redux';
import { UserActions } from '../../../common/store/user/user.actions';
import { Visible } from '../../../common/components/layout/Visible.component';
import { selectUser } from '../../../store/selectors';

interface IProps {
  user: IUser;
}

export const UserInfo: FunctionComponent<IProps> = ({ user }) => {
  const G = useGeneralStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectUser);

  const canDeactivateUser = !!currentUser?.acl.editUser && currentUser?.id !== user.id && !user.deleted;
  const canActivateUser = !!currentUser?.acl.editUser && !!user.deleted;
  const canResetPassword = !!currentUser?.acl.editUser && !!user.applicationUser;

  const onEditClick = () => history.push(appUrls.users.edit(`${user.id}`));
  const onActivateUser = () => user && dispatch(UserActions.deactivateUser({ deactivate: false, id: user.id }));
  const onDeactivateUser = () => user && dispatch(UserActions.deactivateUser({ deactivate: true, id: user.id }));
  const onResetPassword = () => user && dispatch(UserActions.resetPassword(user.id));

  return (
    <Paper square>
      <PanelContent title={translate('users.details.title')}>
        <Visible visible={!user.deleted}>
          <Auth acl={[IUserManagementAclActions.editUser]}>
            <PanelAction icon={<Edit />} onClick={onEditClick} />
          </Auth>
        </Visible>
        <Grid container spacing={2} className={G.marginTop20}>
          <LabelValue label={translate('users.details.id')}>{user.id}</LabelValue>
          <LabelValue label={translate('users.details.firstName')}>{user.firstName}</LabelValue>
          <LabelValue label={translate('users.details.lastName')}>{user.lastName}</LabelValue>
          <LabelValue label={translate('users.details.email')}>{user.email}</LabelValue>
          <LabelValue label={translate('users.details.phoneNumber')}>{user.phoneNumber}</LabelValue>
          <LabelValue label={translate('users.details.source')}>{user.source}</LabelValue>
          <LabelValue label={translate('users.details.sourceId')}>{user.sourceId}</LabelValue>
          <LabelValue label={translate('users.details.roles')}>{userGetRoleNames(user).join(', ')}</LabelValue>
          <LabelValue label={translate('users.details.utilityCompany')}>{user.utilityCompany?.name}</LabelValue>
          <LabelValue label={translate('users.details.createdAt')}>
            <FormattedDateTime dateTime={user.createdAt} />
          </LabelValue>
          <LabelValue label={translate('users.details.updatedAt')}>
            <FormattedDateTime dateTime={user.updatedAt} />
          </LabelValue>
        </Grid>
        <Grid container justify="flex-end" className={G.marginTop20} spacing={2}>
          <Visible visible={!user.deleted}>
            <Grid item>
              <ModalButton.ResetPassword
                onConfirm={onResetPassword}
                visible={canResetPassword}
                title={translate('users.details.resetPassword')}
                buttonTitle={translate('users.details.resetPassword')}
                needsReason={false}
                modalBody={translate('users.details.resetPasswordConfirmation')}
              />
            </Grid>
          </Visible>
          <Grid item>
            <ModalButton.Deactivate
              onConfirm={onDeactivateUser}
              visible={canDeactivateUser}
              title={translate('users.details.deactivateUser')}
              buttonTitle={translate('users.details.deactivateUser')}
              needsReason={false}
              modalBody={translate('users.details.deactivateUserConfirmation')}
            />
            <ModalButton.Activate
              onConfirm={onActivateUser}
              visible={canActivateUser}
              title={translate('users.details.activateUser')}
              buttonTitle={translate('users.details.activateUser')}
              needsReason={false}
              modalBody={translate('users.details.activateUserConfirmation')}
            />
          </Grid>
        </Grid>
      </PanelContent>
    </Paper>
  );
};
