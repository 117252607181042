import axios from 'axios';
import { EXCLUDED_ERROR_STATUSES } from './common/error-constants';
import LocalStorage from './common/utils/local-storage';
import { AppActions } from './common';
import { History } from 'history';
import { Store } from 'redux';
import { appUrls } from './common/config/url.constants';
import { HttpStatusCodes } from './types';

export const setupInterceptors = (history: History, store: Store) => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === HttpStatusCodes.BAD_REQUEST && error.response?.data.error === 'invalid_grant') {
        store.dispatch(AppActions.logout());
        return Promise.resolve();
      }

      if (error.response?.status === HttpStatusCodes.UNAUTHORIZED) {
        const locallyStoredRefreshToken = LocalStorage.get('refreshToken');
        if (locallyStoredRefreshToken) {
          store.dispatch(AppActions.fetchToken({ type: 'refreshToken', refreshToken: locallyStoredRefreshToken }));
        } else {
          history.push(appUrls.login.selection);
        }
        return Promise.resolve();
      }

      if (error.response?.status && EXCLUDED_ERROR_STATUSES.includes(error.response.status)) {
        history.push(appUrls.base);
        return Promise.resolve();
      }
      return Promise.reject(error);
    },
  );
};
