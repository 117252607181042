import * as React from 'react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { createUseStyles } from 'react-jss';
import { useLocation } from 'react-router';
import { Visible } from '../../../common/components/layout/Visible.component';

const useStyles = createUseStyles({
  viewer: {
    '& iframe': {
      height: '100vh',
      width: '100vw',
    },
  },
});

export const AttachmentViewerPage = () => {
  const C = useStyles();
  const { search } = useLocation();
  const url = new URLSearchParams(search).get('url');

  return (
    <Visible visible={!!url}>
      <DocViewer pluginRenderers={DocViewerRenderers} documents={url ? [{ uri: url }] : []} className={C.viewer} />
    </Visible>
  );
};
