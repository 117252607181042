import React, { FunctionComponent } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useField } from 'formik';
import { IFormikTextFieldAutoComplete, IWithClassName, IWithStyle } from '../../../types';

interface IProps extends IFormikTextFieldAutoComplete, IWithClassName, IWithStyle {}

export const FormikAutocompleteField: FunctionComponent<IProps> = ({
  disabled,
  name,
  label,
  onChange,
  options,
  value,
  required = true,
  className,
  renderOption,
}) => {
  const [field, meta, helpers] = useField({ name, type: 'text', value });
  const showError = meta.touched && !!meta.error;
  return (
    <Autocomplete
      disabled={disabled}
      {...field}
      className={className}
      options={options}
      value={options.find((opt) => opt.value === value)}
      onChange={(_, newValue) => {
        if (newValue && !Array.isArray(newValue)) {
          helpers.setValue(newValue.value);
          onChange && onChange(newValue);
        }
      }}
      autoHighlight
      getOptionLabel={(option) => option.label}
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField
          key={params.id}
          label={label}
          variant="standard"
          {...params}
          disabled={disabled}
          inputProps={{
            ...params.inputProps,
            autoComplete: '', // disable autocomplete and autofill
            disabled: disabled,
          }}
          required={required}
          error={showError}
          helperText={showError && meta.error}
        />
      )}
    />
  );
};
