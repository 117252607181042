import React, { FC } from 'react';
import { Container } from '../../../../common';
import { Header } from '../../../../common/components/layout/Header.component';
import { appUrls } from '../../../../common/config/url.constants';
import { translate } from '../../../../common/translations/translate';
import { IUserManagementAclActions } from '../../../../types';
import { Auth } from '../../../atoms';
import { UserForm } from '../../../forms';
import { useFormStyles } from '../../../../style/form.styles';

export const CreateUserPage: FC = () => (
  <Auth acl={IUserManagementAclActions.createUser} showUnauthorizedPage>
    <Header backButtonUrl={appUrls.users.base}>{translate('users.create.title')}</Header>
    <Container className={useFormStyles().container}>
      <UserForm title={translate('users.create.details')} />
    </Container>
  </Auth>
);
