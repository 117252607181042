import { translate } from '../../../common/translations/translate';
import { EditButton } from '../../../request/components/details/edit-button.component';
import React, { FC, PropsWithChildren } from 'react';
import { useGeneralStyles } from '../../../style/generalStyles';

interface IProps {
  onEdit?(): void;
}

export const PanelTitle: FC<PropsWithChildren<IProps>> = ({ onEdit, children }) => {
  const { flexRowSpaceBetween } = useGeneralStyles();
  if (!onEdit) return <>{children}</>;

  return (
    <div className={flexRowSpaceBetween} data-testid="PanelTitle">
      <div>{children}</div>
      <EditButton
        title={translate('sgw.requests.detail.phases.generalInfo.edit')}
        onClick={(e) => {
          e.stopPropagation();
          onEdit();
        }}
      />
    </div>
  );
};
