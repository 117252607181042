import { translate } from '../../../common/translations/translate';
import React, { FC } from 'react';
import { FormattedDateTime, ISortingDirection } from '../../../common';
import { GenericTable } from '../../../common/components/table/GenericTable.component';
import { getAttachmentsByPhaseId } from '../../../store/selectors/sgwRequest.selectors';
import { useSelector } from 'react-redux';
import { FilePreviewer } from '../FilePreviewer/FilePreviewer.component';
import { ISgwRequestAttachment } from '../../../types';

interface IProps {
  id: number;
  editMode: boolean;
}

export const PhaseAttachments: FC<IProps> = ({ id, editMode }) => {
  const phaseAttachments = useSelector(getAttachmentsByPhaseId(id));

  return (
    <GenericTable<ISgwRequestAttachment>
      columnKey="id"
      columns={[
        {
          label: translate('sgw.requests.detail.phases.attachments.table.category'),
          name: 'category',
        },
        {
          label: translate('sgw.requests.detail.phases.attachments.table.fileName'),
          name: 'fileStorage',
          renderer: (fileStorage) => <FilePreviewer editMode={editMode} fileStorage={fileStorage} />,
        },
        {
          label: translate('sgw.requests.detail.phases.attachments.table.createdAt'),
          name: 'createdAt',
          renderer: (createdAt) => <FormattedDateTime dateTime={createdAt} />,
        },
      ]}
      labelUnavailable={translate('sgw.requests.detail.phases.attachments.table.unavailable')}
      records={phaseAttachments}
      sorting={{ key: 'createdAt', direction: ISortingDirection.asc }}
    />
  );
};
