import * as React from 'react';
import { FunctionComponent } from 'react';
import { IFormikDateField, IFormikTextField, IWithClassName, IWithStyle } from '../../../types';
import { FormikTextField } from './FormikTextField.component';
import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

interface IProps extends IFormikTextField, IWithStyle, IWithClassName {
  min?: number;
  max?: number;
}
const useStyles = makeStyles<Theme, IFormikDateField>({
  root: { color: ({ disabled, readOnly }) => (disabled && !readOnly ? 'gray' : 'black') },
});

export const FormikTextFieldNumber: FunctionComponent<IProps> = ({ min, max, ...props }) => {
  const C = useStyles(props);

  return <FormikTextField {...props} type="number" inputProps={{ min, max, className: C.root }} fullWidth={false} />;
};
