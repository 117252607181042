import MomentUtils from '@date-io/moment';
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { nl } from 'date-fns/locale';
import { Field, FieldProps } from 'formik';
import { MaterialUiPickersDate } from 'material-ui-pickers';
import moment from 'moment';
import * as React from 'react';
import { FunctionComponent, useCallback } from 'react';
import { COLORS } from '../..';
import { IFormikField } from '../../../types';

const useStyles = makeStyles({
  error: {
    color: COLORS.DANGER,
  },
});

export const FormikTimeField: FunctionComponent<IFormikField> = ({
  disabled,
  error = false,
  name,
  label,
  required,
}) => {
  const C = useStyles();

  const changeHandler = useCallback(
    (fieldProps: MaterialUiPickersDate) => {
      return (value: MaterialUiPickersDate) => {
        if (value) {
          fieldProps.form.setFieldValue(name, value.format('HH:mm'));
        }
      };
    },
    [name],
  );

  return (
    <Field
      name={name}
      type="text"
      component={(fieldProps: FieldProps) => (
        <MuiPickersUtilsProvider utils={MomentUtils} locale={nl}>
          <TimePicker
            disabled={disabled}
            autoOk
            disableToolbar
            minutesStep={60}
            views={['hours']}
            ampm={false}
            variant="dialog"
            value={moment(fieldProps.field.value, 'HH:mm')}
            label={label}
            onChange={changeHandler(fieldProps)}
            required={required}
            error={error}
            InputProps={error ? { className: C.error } : undefined}
          />
        </MuiPickersUtilsProvider>
      )}
      Label={{ label }}
    />
  );
};
