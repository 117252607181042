import { LineString, Point, Polygon } from 'geojson';
import { ILocationAddress } from '../common';
import { ISign } from '../signs/types/sign';
import { ICarFreeZone, IGate } from './carFreeZones.types';
import { LatLng, LatLngBounds } from 'leaflet';
import { IGeometry } from './geojson.types';
import { RequestParkingBanState } from './parkingBan.types';
import { IPermittedPlate } from './permittedPlate.types';

export enum IntakeTypes {
  carfreezoneintake = 'carfreezoneintake',
  minorconstructionsiteintake = 'minorconstructionsiteintake',
  parkingbanintake = 'parkingbanintake',
}

export interface IIntakeType {
  type: IntakeTypes;
  name: string;
  displayName: string;
}

interface IIntake extends ILocationAddress {
  attachments?: any[];
  geometry?: LineString | Point | Polygon;
  id: number;
  permitRequestId: number;
  reviewingState?: {
    type: string;
    name: string;
  };
}

export interface IRequestLocationForm extends ILocationAddress {
  attachments?: any[];
  bounds?: LatLngBounds;
  description?: string;
  geometry?: IGeometry;
  geometryType?: 'line' | 'polygon';
  gisId?: string;
  id: number;
  isNew?: boolean;
  type: IntakeTypes;
  numberOfSigns?: number;
  streetNumberUnknown?: boolean;
  carFreeZone?: number;
  carFreeZoneGateEntrance?: number | null;
  carFreeZoneGateExit?: number | null;
}

export interface IParkingBanIntake extends IIntake {
  checkedAt?: string;
  noParkedVehicles?: boolean;
  numberOfSigns?: number;
  numberOfZoneSigns?: number;
  numberOfSignsNeedMaintenance?: number;
  parkedPlates?: any[];
  state?: {
    state: string;
    name: string;
    transitions: string[];
  };
  type?: IIntakeType;
}

export interface IPublicDomainIntake extends IIntake {
  anprRegularisation?: boolean;
  bufferedGeometry?: Point | LineString | Polygon;
  carFreeZone?: ICarFreeZone;
  carFreeZoneGateEntrance?: IGate;
  carFreeZoneGateExit?: IGate;
  checkedAt?: string;
  city: string;
  comment?: string;
  description?: string;
  gisId?: string;
  id: number;
  noParkedVehicles?: boolean;
  numberOfSigns?: number;
  numberOfZoneSigns?: number;
  parkedPlates?: string[];
  permittedPlates?: IPermittedPlate[];
  signs?: IPublicDomainIntakeSign[];
  signsPlacedAt?: string;
  signsPlacedAtDate?: string;
  signsPlacedAtTime?: string;
  state?: { state: RequestParkingBanState; name: string; transitions: string[] };
  street: string;
  streetNumberFrom: string;
  streetNumberTo: string;
  type: IIntakeType;
  zipCode: number;
}

export interface ISortedPermittedPlate {
  startDate?: string;
  plates: IPermittedPlate[];
}

export interface IPublicDomainIntakeSign {
  id: number;
  active: boolean;
  damaged: boolean;
  placedAt: string;
  pickedUpAt?: string;
  updatedAt: string;
  sign: ISign;
}

export interface IPublicDomainIntakeExtended extends IPublicDomainIntake {
  canMarkSignsAsMissing: boolean;
  canHaveZoneSigns: boolean;
  cityAndZipCode: string;
  streetAndNumbers: string;
  googleMapsAddress: string;
  viodAddress: string;
  numberOfSignsPlaced: number;
  positions: LatLng | LatLng[] | LatLng[][] | LatLng[][][] | null;
  bounds?: LatLngBounds;
}
