import { Tooltip } from '@material-ui/core';
import * as React from 'react';
import { URGENT_BLACK_ICON_URL, URGENT_ICON_URL } from '../../../common/icons';
import { translate } from '../../../common/translations/translate';
import { IWithClassName } from '../../../types';

interface IProps extends IWithClassName {
  isHeader?: boolean;
}

export const UrgentIcon = ({ isHeader, ...props }: IProps) => (
  <Tooltip title={translate('emergencyProcedure')} aria-label="urgent">
    <img
      style={{ height: '15px' }}
      src={isHeader ? URGENT_BLACK_ICON_URL : URGENT_ICON_URL}
      alt={translate('emergencyProcedure')}
      {...props}
    />
  </Tooltip>
);

UrgentIcon.Header = () => <UrgentIcon isHeader />;
