import * as React from 'react';
import { Route, Switch } from 'react-router';
import { appUrls } from '../common/config/url.constants';
import { CreateUserPage } from '../components/pages/User/CreateUserPage/CreateUserPage';
import { UsersOverview } from './components/overview/UsersOverview.container';
import { DetailUserPage } from '../components';

export const UserRoutes = () => (
  <Switch>
    <Route exact path={[appUrls.users.base]}>
      <UsersOverview />
    </Route>
    <Route exact path={[appUrls.users.create]}>
      <CreateUserPage />
    </Route>
    <Route exact path={[appUrls.users.detail()]}>
      <DetailUserPage />
    </Route>
    <Route exact path={[appUrls.users.edit()]}>
      <DetailUserPage isEditMode />
    </Route>
  </Switch>
);
