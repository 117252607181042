import * as I18n from 'i18n-js';
import { FunctionComponent } from 'react';
import * as React from 'react';
import { Unavailable } from '../../../common/components/formatters/unavailable';
import { PanelTitle } from '../../../common/components/panel/panel-title.component';
import { IPayment } from '../../../types';
import moment from 'moment';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { DateFormat } from '../../../types';

interface IProps {
  payment: IPayment;
}

const styles = {
  tableCell: {
    borderBottom: 'none',
  },
};

export const PaymentHistory: FunctionComponent<IProps> = ({ payment }) => {
  return (
    <>
      <PanelTitle>{I18n.translate('Requests.Detail.Payments.History.Title')}</PanelTitle>
      {payment.paymentHistory.length ? (
        <Table size={'small'}>
          <TableHead>
            <TableRow>
              <TableCell>{I18n.translate('Status')}</TableCell>
              <TableCell>{I18n.translate('Timestamp')}</TableCell>
              <TableCell>{I18n.translate('UserResponsible')} </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payment.paymentHistory.map((record) => (
              <TableRow key={Math.random()}>
                <TableCell style={styles.tableCell}>{I18n.t(`Requests.PaymentStates.${record.state}`)}</TableCell>
                <TableCell style={styles.tableCell}>{moment(record.timestamp).format(DateFormat.dateTime)}</TableCell>
                <TableCell style={styles.tableCell}>{record.userName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Unavailable text={I18n.translate('Requests.Detail.Payments.History.None')} />
      )}
    </>
  );
};
