import { push } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { genericErrorHandler, TPayloadAction } from '../../common';
import { appUrls } from '../../common/config/url.constants';
import { translate } from '../../common/translations/translate';
import { getPagingFromHeaders } from '../../common/utils/api.util';
import { ICall, ISelect, ISgwTrafficMeasure } from '../../types';
import { SnackBarActions, SgwTrafficMeasuresActions } from '../actions';
import { SgwTrafficMeasuresApi } from '../api';
import { getPagedApiParams, getSgwTrafficMeasure } from '../selectors/sgwTrafficMeasures.selectors';

function* onFetchTrafficMeasureList() {
  const params: ISelect<typeof getPagedApiParams> = yield select(getPagedApiParams);
  const response: ICall<typeof SgwTrafficMeasuresApi.fetchList> = yield call(SgwTrafficMeasuresApi.fetchList, params!);
  yield all([
    put(
      SgwTrafficMeasuresActions.list.setParams({
        paging: getPagingFromHeaders(response as any),
      }),
    ),
    put(SgwTrafficMeasuresActions.list.set(response!.data.data)),
  ]);
}

function* onSaveTrafficMeasure({ payload }: TPayloadAction<ISgwTrafficMeasure>): SagaIterator {
  const currentTrafficMeasure: ISgwTrafficMeasure = yield select(getSgwTrafficMeasure(`${payload.id}`));

  const response: ICall<typeof SgwTrafficMeasuresApi.save> = yield call(SgwTrafficMeasuresApi.save, {
    ...currentTrafficMeasure,
    ...payload,
  });

  yield put(SgwTrafficMeasuresActions.set(response!.data.data));
  yield put(SgwTrafficMeasuresActions.setLoading(false));
  yield put(SnackBarActions.setSuccess(translate('sgw.trafficMeasures.saved')));
  yield put(push(appUrls.sgw.manage.trafficMeasures.detail(`${response!.data.data.id}`)));
}

function* onFetch({ payload }: TPayloadAction<string>) {
  const response: ICall<typeof SgwTrafficMeasuresApi.fetch> = yield call(SgwTrafficMeasuresApi.fetch, payload);
  yield put(SgwTrafficMeasuresActions.set(response!.data.data));
  yield put(SgwTrafficMeasuresActions.setLoading(false));
}

export function* sgwTrafficMeasuresSaga(): SagaIterator {
  yield takeLatest(SgwTrafficMeasuresActions.list.fetch.type, genericErrorHandler(onFetchTrafficMeasureList));
  yield takeLatest(SgwTrafficMeasuresActions.save.type, genericErrorHandler(onSaveTrafficMeasure));
  yield takeLatest(SgwTrafficMeasuresActions.fetch.type, genericErrorHandler(onFetch));
}
