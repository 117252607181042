import * as I18n from 'i18n-js';
import * as L from 'leaflet';

export function LeafletDrawTranslation() {
  // @ts-ignore
  const drawLocal: any = L.drawLocal;
  drawLocal.draw.handlers.circle.tooltip.start = I18n.t('ClickAndDragToDrawCircle');
  drawLocal.draw.handlers.rectangle.tooltip.start = I18n.t('ClickAndDragToDrawRectangle');
  drawLocal.draw.handlers.simpleshape.tooltip.end = I18n.t('FinishDrawing');
  drawLocal.draw.toolbar.actions.text = I18n.t('Cancel');
}
