import moment from 'moment';
import { DateFormat, ISgwQuarter, Quarter } from '../../types';

export const formatDateToBackendDateFormat = (dateInString: string) =>
  convertDateFormat(dateInString, DateFormat.date, DateFormat.reduxStoreDateString);

export const convertDateFormat = (
  dateInString: string | undefined,
  fromFormat: string = DateFormat.date,
  toFormat: string,
): string => {
  if (!dateInString) {
    return '';
  }
  const parsedDate = moment(dateInString, fromFormat, true);
  return parsedDate.isValid() ? parsedDate.format(toFormat) : dateInString;
};

export function formatDateTimeToDisplayDate(date: string, time?: string): string {
  if (date && time) {
    return moment(`${date} ${time}`, DateFormat.reduxStoreDateTimeString).format(DateFormat.dateTime);
  } else if (date) {
    return moment(date, DateFormat.reduxStoreDateString).format(DateFormat.date);
  }
  return '';
}

export function formatDateTimeToDisplayDateTime(dateTime: string): string {
  return moment(dateTime).format(DateFormat.dateTime);
}

const getNextQuarter = ({ year, index }: ISgwQuarter) =>
  index === Quarter.FOUR ? { year: ++year, index: 1 } : { year, index: ++index };

export const allQuartersBetweenDates = (startDate?: string, endDate?: string) => {
  if (!startDate || !endDate) return [];

  const start = moment(startDate);
  const end = moment(endDate);
  let quarter = {
    year: start.year(),
    index: start.quarter(),
  };

  let endQuarter = {
    year: end.year(),
    index: end.quarter(),
  };
  const allQuarters = [{ ...quarter }];
  while (quarter.year < endQuarter.year || (quarter.year === endQuarter.year && quarter.index < endQuarter.index)) {
    quarter = getNextQuarter(quarter);
    allQuarters.push({ ...quarter });
  }
  return allQuarters;
};

export const lowestDate = <T, K extends keyof T>(objects: T[], key: K): string => {
  return moment.min(objects.map((o) => moment(o[key] as moment.MomentInput))).format(DateFormat.reduxStoreDateString);
};

export const highestDate = <T, K extends keyof T>(objects: T[], key: K): string => {
  return moment.max(objects.map((o) => moment(o[key] as moment.MomentInput))).format(DateFormat.reduxStoreDateString);
};
