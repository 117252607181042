import { ExpansionPanelDetails, ExpansionPanelSummary, Grid, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMore } from '@material-ui/icons';
import MailIcon from '@material-ui/icons/Mail';
import * as I18n from 'i18n-js';
import moment from 'moment';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { COLORS, Panel } from '../../../common';
import { Unavailable } from '../../../common/components/formatters/unavailable';
import { InfoText } from '../../../common/components/layout/InfoText.component';
import { LabelValue } from '../../../common/components/layout/LabelValue.component';
import { Visible } from '../../../common/components/layout/Visible.component';
import { getIsAuthorized } from '../../../common/store/user/user.selectors';
import { formatCurrency } from '../../../common/utils/string.util';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import { DateFormat, IPayment, IPaymentPatchState, IRequestAclActions } from '../../../types';
import { PaymentDetails } from './payment-details.component';
import { PaymentHistory } from './payment-history.component';
import { Button } from '../../../common/components/buttons/asign-button-extensions';

const useStyles = makeStyles({
  emailRetribution: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '15px',
    alignItems: 'center',
  },
  expansionPanel: {
    marginBottom: '15px',
  },
  expansionPanelSummary: {
    backgroundColor: COLORS.PRIMARY,
    color: COLORS.WHITE,
  },
  expansionPanelDetails: {
    display: 'inherit',
  },
  expansionPanelSummaryItems: {
    width: '100%',
    justifyContent: 'space-between',
    display: 'flex',
    fontWeight: 500,
  },
  lastSentOn: {
    marginRight: '15px',
  },
  paymentInformation: {
    marginBottom: '20px',
  },
  title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  fullWidth: {
    width: '100%',
  },
});

interface IProps {
  billingMailedAt?: string;
  totalCost?: number;
  totalPaid: number;
  title: React.ReactNode;
  payments: IPayment[];
  onPatchPaymentState: (payload: IPaymentPatchState) => void;
  reasonForApproval?: string;
  reasonForRejection?: string;
  requestId?: number;
  showEmailRetribution?: boolean;
  onsendRetributionMail?: () => void;
}

export const PaymentsComponent: FunctionComponent<IProps> = ({
  billingMailedAt,
  onsendRetributionMail,
  totalCost,
  totalPaid,
  title,
  payments,
  onPatchPaymentState,
  reasonForApproval,
  reasonForRejection,
  requestId,
  showEmailRetribution = false,
}) => {
  const C = useStyles();
  const canEditAssignedRequest = useSelector(getIsAuthorized([IRequestAclActions.editAssignedRequest]));
  const canViewRequest = useSelector(getIsAuthorized([IRequestAclActions.viewRequest]));
  const isReadOnly = canViewRequest && !canEditAssignedRequest;

  return (
    <Panel title={title}>
      <Grid container spacing={2} className={C.paymentInformation}>
        <LabelValue
          visible={totalCost !== undefined && totalCost < totalPaid}
          label={I18n.translate('Requests.Detail.Payments.TotalDue')}
        >
          {totalCost && formatCurrency(totalCost)}
        </LabelValue>
        <LabelValue
          visible={totalCost !== undefined && totalCost < totalPaid}
          label={I18n.translate('Requests.Detail.Payments.Overpaid')}
        >
          {totalCost && formatCurrency(totalPaid - totalCost)}
        </LabelValue>
        <LabelValue visible={totalPaid !== 0} label={I18n.translate('Requests.Detail.Payments.TotalAmountPaid')}>
          {formatCurrency(totalPaid)}
        </LabelValue>
        <LabelValue visible={!!reasonForApproval} label={I18n.translate('Requests.Detail.Payments.ReasonForApproval')}>
          {reasonForApproval}
        </LabelValue>
        <LabelValue
          visible={!!reasonForRejection}
          label={I18n.translate('Requests.Detail.Payments.ReasonForRejection')}
        >
          {reasonForRejection}
        </LabelValue>
      </Grid>
      {payments.length ? (
        payments.map((payment, index) => (
          <ExpansionPanel key={index} square className={index === payments.length - 1 ? undefined : C.expansionPanel}>
            <ExpansionPanelSummary
              className={C.expansionPanelSummary}
              expandIcon={<ExpandMore />}
              IconButtonProps={{ color: 'inherit' }}
            >
              <div className={C.expansionPanelSummaryItems}>
                <div>
                  Betaling {index + 1}: {I18n.t(`Requests.PaymentStates.${payment.state.state}`)}
                </div>
                <div>{formatCurrency(payment.amountToBePaid)}</div>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={C.expansionPanelDetails}>
              <PaymentDetails onPatchPaymentState={onPatchPaymentState} payment={payment} requestId={requestId} />
              <PaymentHistory payment={payment} />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))
      ) : (
        <Unavailable className={C.fullWidth} text={I18n.translate('Requests.Detail.Payments.None')} />
      )}
      <Visible visible={showEmailRetribution}>
        <div className={C.emailRetribution}>
          <div className={C.lastSentOn}>
            {billingMailedAt ? (
              <InfoText>
                {I18n.t('Requests.Detail.Payments.LastSentOn', {
                  billingMailedAt: moment(billingMailedAt).format(DateFormat.dateTime),
                })}
              </InfoText>
            ) : (
              <Unavailable text={I18n.t('Requests.Detail.Payments.NoBillingMailInformation')} />
            )}
          </div>
          <Button.Green startIcon={<MailIcon />} onClick={onsendRetributionMail} visible={!isReadOnly}>
            <Tooltip title={I18n.t('Requests.Detail.Payments.SendMailToFacturisation')}>
              <span style={{ marginLeft: '5px' }}>{I18n.t('Requests.Detail.Payments.EmailRetribution')}</span>
            </Tooltip>
          </Button.Green>
        </div>
      </Visible>
    </Panel>
  );
};
