import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Edit, Save } from '@material-ui/icons';
import { Formik } from 'formik';
import * as React from 'react';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedDateTime, ISorting, PanelAction } from '../../../common';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { CheckboxWithLabel } from '../../../common/components/form-fields/checkboxWithLabel.component';
import { LabelValue } from '../../../common/components/layout/LabelValue.component';
import { LoaderBlur } from '../../../common/components/layout/LoaderBlur.component';
import { Visible } from '../../../common/components/layout/Visible.component';
import ModalDialog from '../../../common/components/modal-dialog/modal-dialog.component';
import { PanelContent } from '../../../common/components/panel/panel-content.component';
import { PanelFooter } from '../../../common/components/panel/panel-footer.component';
import { ServicesActions } from '../../../common/store/services/Services.actions';
import {
  getContactPersonsList,
  getExistingNames,
  getServicesSaving,
} from '../../../common/store/services/Services.selectors';
import { translate } from '../../../common/translations/translate';
import { Auth } from '../../../components';
import { IServiceAclActions, IWithClassName } from '../../../types';
import {
  IService,
  IServiceContact,
  IServiceContactWithId,
  newContactInitialValues,
  VehicleSource,
} from '../../../types/services.types';
import { ServiceEditForm } from './edit/ServiceEditForm.component';
import { serviceFormManagement } from './edit/ServiceEditForm.management';
import { ServiceContacts } from './ServiceContacts.component';

const useStyles = makeStyles({
  divider: { marginTop: 20 },
  form: { padding: 15 },
  grid: { marginTop: 20, marginBottom: 20 },
  saveButton: {
    marginRight: 10,
  },
  syncWrapper: {
    marginTop: 20,
  },
  aptrLastSync: {
    fontStyle: 'italic',
    fontSize: 13,
    paddingLeft: 5,
  },
});

interface IProps extends IWithClassName {
  service: IService;
  onSave: (service: IService) => void;
}

export const ServiceDetails: FunctionComponent<IProps> = ({ service, onSave, className }) => {
  const C = useStyles();
  const saving = useSelector(getServicesSaving);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState(false);
  const contacts = useSelector(getContactPersonsList);
  const existingNames = useSelector(getExistingNames);
  const [newContact, setNewContact] = useState<IServiceContact>(newContactInitialValues);

  const toggleEditMode = useCallback(() => setEditMode(!editMode), [editMode]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!saving) {
      setEditMode(false);
    }
  }, [saving]);

  const onSubmit = useCallback(() => {
    setShowPopup(true);
  }, [setShowPopup]);

  const onAdd = useCallback(() => {
    dispatch(ServicesActions.addContact({ service, contact: newContact }));
  }, [dispatch, newContact, service]);

  const onDelete = useCallback(
    (contact: IServiceContactWithId) => {
      dispatch(ServicesActions.deleteContact({ service, contact }));
    },
    [dispatch, service],
  );

  const onFetchContactList = useCallback(
    (sorting: ISorting) => {
      dispatch(ServicesActions.contactsList.fetch({ filters: { id: `${service.id}` }, sorting }));
    },
    [dispatch, service],
  );

  useEffect(() => {
    dispatch(ServicesActions.contactsList.fetch({ filters: { id: `${service.id}` }, sorting: undefined }));
  }, [dispatch, service]);

  return (
    <Paper square data-testid="ServiceDetails" className={className}>
      <PanelContent title={translate('services.detail.infoTitle')}>
        <Auth acl={[IServiceAclActions.editService]}>
          <PanelAction icon={<Edit />} onClick={toggleEditMode} data-testid="EditModeButton" />
        </Auth>
        <Grid container spacing={2} className={C.grid}>
          <Visible visible={!editMode}>
            <LabelValue label={translate('ID')}>{service.id}</LabelValue>
            <LabelValue label={translate('Name')}>{service.name}</LabelValue>
            <LabelValue label={translate('street')}>
              {service.streetName} {service.streetNumber}
              {service.bus && (
                <>
                  &nbsp;{translate('services.form.bus')} {service.bus}
                </>
              )}
            </LabelValue>
            <LabelValue label={translate('services.form.city')}>
              {service.postalCode} {service.city}
            </LabelValue>
            <LabelValue label={translate('services.form.description')}>{service.description}</LabelValue>
            <LabelValue label={translate('services.form.vehicleSource.label')}>
              {service.vehicleSource === VehicleSource.aptr ? 'APTR' : service.vehicleSource}
              <Visible visible={service.vehicleSource === VehicleSource.aptr && !!service.aptrSyncedAt}>
                <span className={C.aptrLastSync}>
                  ({translate('services.form.lastSync')}
                  <FormattedDateTime dateTime={service.aptrSyncedAt} />)
                </span>
              </Visible>
            </LabelValue>
            <Visible visible={service.vehicleSource === VehicleSource.aptr}>
              <LabelValue label={translate('services.form.aptrAreaCodeClean')}>{service.aptrAreaCode}</LabelValue>
              <LabelValue label={translate('services.form.aptrVpUsageCode')}>{service.aptrVpUsageCode}</LabelValue>
              <LabelValue label={translate('services.form.aptrC3UsageCode')}>{service.aptrC3UsageCode}</LabelValue>
              <LabelValue label={translate('services.form.aptrF3UsageCode')}>{service.aptrF3UsageCode}</LabelValue>
            </Visible>
            <LabelValue label={translate('services.form.createdAt')}>
              <FormattedDateTime dateTime={service.createdAt} />
            </LabelValue>
            <LabelValue label={translate('services.form.updatedAt')}>
              <FormattedDateTime dateTime={service.updatedAt} />
            </LabelValue>

            <div className={C.syncWrapper}>
              <CheckboxWithLabel
                checked={!!service.syncWithMoov}
                label={translate('services.form.syncWithMoov')}
                readOnly
              />
              <CheckboxWithLabel
                checked={!!service.syncWithZoneguard}
                label={translate('services.form.syncWithZoneguard')}
                readOnly
              />
            </div>
          </Visible>

          <Visible visible={editMode}>
            <LoaderBlur loading={saving}>
              <Formik
                initialValues={serviceFormManagement.getInitialValues(service)}
                onSubmit={onSubmit}
                validate={(values) => serviceFormManagement.requestValidateValues(values, existingNames, service.name)}
              >
                {(formikProps) => (
                  <>
                    <ServiceEditForm service={service} formikProps={formikProps} />
                    <PanelFooter>
                      <Button.Green
                        className={C.saveButton}
                        disabled={formikProps?.isSubmitting}
                        onClick={formikProps?.submitForm}
                        startIcon={<Save fontSize="small" />}
                        data-testid="SaveButton"
                      >
                        {translate('Save')}
                      </Button.Green>
                    </PanelFooter>

                    <Visible visible={showPopup}>
                      <ModalDialog
                        title={translate('services.dialogs.confirmSavePopup.title')}
                        data-testid="ServicesSavePopup"
                        onClose={() => {
                          formikProps.setSubmitting(false);
                          setEditMode(false);
                          setShowPopup(false);
                        }}
                        onConfirm={() => {
                          formikProps.setSubmitting(true);
                          setEditMode(false);
                          setShowPopup(false);
                          onSave(formikProps.values);
                        }}
                        okButtonText={translate('yes')}
                        refuseButtonText={translate('no')}
                      >
                        <Typography>{translate('services.dialogs.confirmSavePopup.message')}</Typography>
                      </ModalDialog>
                    </Visible>
                  </>
                )}
              </Formik>
            </LoaderBlur>
          </Visible>
        </Grid>
        <ServiceContacts
          contacts={contacts}
          setNewContact={setNewContact}
          onAdd={onAdd}
          onDelete={onDelete}
          newContact={newContact}
          onFetchContactList={onFetchContactList}
        />
      </PanelContent>
    </Paper>
  );
};
