import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ISorting, ISortingDirection } from '../../../../common';
import { IRequestDetailComponent } from '../../../../types';
import { RequestHistoryComponent } from './request-history.component';
import { RequestActions } from '../../../../store/actions';

export const RequestHistory: FunctionComponent<IRequestDetailComponent> = ({ requestId }) => {
  const [sorting, setSorting] = useState<ISorting>({ key: 'timestamp', direction: ISortingDirection.asc });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(RequestActions.fetchHistory({ all: false, id: requestId, sorting }));
  }, [dispatch, requestId, sorting]);

  const changeSortingOrder = useCallback(
    (key: string) => () => {
      const direction =
        sorting.key === key && sorting.direction === 'asc' ? ISortingDirection.desc : ISortingDirection.asc;
      setSorting({ key, direction });
    },
    [sorting],
  );

  const onClickShowAll = useCallback(() => {
    dispatch(RequestActions.fetchHistory({ all: true, id: requestId, sorting }));
  }, [dispatch, requestId, sorting]);

  return (
    <RequestHistoryComponent
      changeSortingOrder={changeSortingOrder}
      sorting={sorting}
      onClickShowAll={onClickShowAll}
    />
  );
};
