import { LatLngBounds } from 'leaflet';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BaseMap, COLORS, CONFIG } from '../../../common';
import { Visible } from '../../../common/components/layout/Visible.component';
import { BaseMapOverviewLayer } from '../../../common/components/map/BaseMapOverviewLayer.component';
import { GeometryComponent } from '../../../common/components/map/Geometry.component';
import { GeometryConflict } from '../../../common/components/map/GeometryConflict.component';
import { SelectableCarFreeZoneLayer } from '../../../common/components/map/SelectableCarFreeZoneLayer.component';
import { getAllCarFreeZonesWithGisId } from '../../../common/store/car-free-zones/carFreeZones.selectors';
import { equalBounds, getBoundsOfGeometries } from '../../../common/utils/geojson.util';
import {
  ICarFreeZone,
  ICarFreeZoneFeature,
  IFeature,
  IGeometry,
  IntakeTypes,
  IRequestLocationForm,
} from '../../../types';

interface IProps {
  canEditLocations: boolean;
  conflicts: IFeature[];
  location?: IRequestLocationForm;
  allLocations: IRequestLocationForm[];
  onSaveLocation: (location: IRequestLocationForm) => void;
  onSelectOverview: () => void;
  onSetGeometry: (geometry: IGeometry) => void;
}

export const MapPane: FunctionComponent<IProps> = ({
  allLocations = [],
  canEditLocations,
  conflicts,
  location,
  onSaveLocation,
  onSelectOverview,
  onSetGeometry,
}) => {
  const [bounds, setBounds] = useState<LatLngBounds | undefined>(CONFIG.defaultMapBounds);
  const carFreeZones: ICarFreeZone[] = useSelector(getAllCarFreeZonesWithGisId);

  useEffect(() => {
    if (location) {
      const newBounds = location?.geometry ? getBoundsOfGeometries([location.geometry]) : location?.bounds;

      if (newBounds && !equalBounds(bounds, newBounds)) {
        setBounds(newBounds);
      }
    } else {
      setBounds(undefined);
    }
  }, [bounds, location]);

  const onGeometryChange = useCallback(
    (geometry: IGeometry) => {
      if (location) onSaveLocation({ ...location, geometry });
    },
    [location, onSaveLocation],
  );

  const onGeometryFinish = useCallback(
    (geometry: IGeometry) => {
      onGeometryChange(geometry);
      onSetGeometry(geometry);
    },
    [onGeometryChange, onSetGeometry],
  );

  const showDrawProps = useCallback(
    () => location?.type === IntakeTypes.parkingbanintake || location?.type === IntakeTypes.minorconstructionsiteintake,
    [location?.type],
  );

  const mapLocationToGeometry = ({ geometry }: IRequestLocationForm): IGeometry => geometry!;

  const getAllExceptCurrentLocationGeometries = useCallback(
    () =>
      allLocations
        .filter(({ geometry, type }) => !!geometry && type !== IntakeTypes.carfreezoneintake)
        .filter((loc) => loc !== location)
        .map(mapLocationToGeometry),
    [allLocations, location],
  );

  const getAllLocationsGisIds = useCallback(
    () =>
      allLocations
        .filter(({ type, gisId }) => type === IntakeTypes.carfreezoneintake && !!gisId)
        .map(({ gisId }) => gisId) as string[],
    [allLocations],
  );

  const getAllLocationsBounds = useCallback(
    () => getBoundsOfGeometries(allLocations.filter(({ geometry }) => !!geometry).map(mapLocationToGeometry)),
    [allLocations],
  );

  const _onSelectCFZ = useCallback(
    (feature: ICarFreeZoneFeature) => {
      if (location?.type === IntakeTypes.carfreezoneintake && location?.gisId !== feature.properties.GISID_GEBIED) {
        const newLoc = {
          ...location,
          geometry: feature.geometry,
          gisId: feature.properties.GISID_GEBIED,
        };

        const cfz = carFreeZones.find(({ gisId }) => gisId === feature.properties.GISID_GEBIED);
        if (cfz) {
          newLoc.carFreeZone = cfz.id;
          newLoc.gisId = cfz.gisId;
          newLoc.carFreeZoneGateEntrance = null;
          newLoc.carFreeZoneGateExit = null;
        }

        onSaveLocation(newLoc);
      }
    },
    [carFreeZones, location, onSaveLocation],
  );

  return (
    <BaseMap
      bounds={bounds}
      maxZoom={location?.type === IntakeTypes.carfreezoneintake ? 17 : 19}
      drawProps={{
        drawTypes: {
          polyline: canEditLocations && location?.type === IntakeTypes.parkingbanintake,
          polygon: canEditLocations && showDrawProps(),
        },
        onCreated: onGeometryFinish,
      }}
      height={'100%'}
      showDrawProps={showDrawProps()}
      showLayersControl
    >
      <Visible visible={!!location?.geometry && location?.type !== IntakeTypes.carfreezoneintake}>
        <GeometryComponent
          editable={canEditLocations}
          key={location?.id}
          saveGeometry={onGeometryChange}
          geometry={location?.geometry!}
          pathOptions={{ color: COLORS.PRIMARY }}
        />
      </Visible>
      <SelectableCarFreeZoneLayer
        selectedGisIds={location ? location.gisId : getAllLocationsGisIds()}
        onSelectGisId={_onSelectCFZ}
        bounds={bounds}
      />
      <BaseMapOverviewLayer
        bounds={getAllLocationsBounds()}
        geometries={getAllExceptCurrentLocationGeometries()}
        onClick={onSelectOverview}
        showLayer={!location}
      />
      {conflicts?.map((conf) => (
        <GeometryConflict key={conf.properties.key} conflict={conf} />
      ))}
    </BaseMap>
  );
};
