import { Grid } from '@material-ui/core';
import moment from 'moment';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { CheckboxWithLabel } from '../../../common/components/form-fields/checkboxWithLabel.component';
import { AsignDatepicker } from '../../../common/components/form-fields/datepicker/asign-datepicker.component';
import { SingleSelectV2Component } from '../../../common/components/form-fields/single-select-v2.component';
import { LabelValue } from '../../../common/components/layout/LabelValue.component';
import ModalDialog from '../../../common/components/modal-dialog/modal-dialog.component';
import { translate } from '../../../common/translations/translate';
import { convertDateFormat } from '../../../common/utils/date.util';
import { toTimeOptions, FROM_TIMES } from '../../../common/utils/moment.util';
import { SgwPhasesActions } from '../../../store/actions/phases.actions';
import { getStandardPhaseOptions, selectSgwRequest } from '../../../store/selectors/sgwRequest.selectors';
import { DateFormat, IRouterWithId, ISgwPhase } from '../../../types';

interface IProps {
  id: string;
  onClose: () => void;
  visible: boolean;
}

export const RecurringPhasesForm: FC<IProps> = ({ id, visible, onClose }) => {
  const form = `copyRecurringPhase-${id}`;
  const request = useSelector(selectSgwRequest);
  const { id: requestId } = useParams<IRouterWithId>();
  const dispatch = useDispatch();
  const phaseOptions = useSelector(getStandardPhaseOptions());
  const { register, watch, setValue, handleSubmit } = useForm<Partial<ISgwPhase>>({
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      dateFrom: request?.dateFrom,
      dateUntil: request?.dateFrom,
      timeFrom: '07:00',
      timeUntil: '17:00',
      workingHoursFrom: '07:00',
      workingHoursUntil: '17:00',
      workingHoursNotStandard: false,
      parentPhaseId: phaseOptions[0]?.value,
    },
  });
  const onSubmit = (phase: Partial<ISgwPhase>) => {
    dispatch(
      SgwPhasesActions.clone({
        requestId: requestId,
        phaseId: parseInt(id),
        phase: phase,
      }),
    );
    onClose();
  };

  return (
    <ModalDialog
      title={translate('sgw.requests.detail.phases.recurringPhaseModal.title')}
      visible={visible}
      onClose={onClose}
      form={form}
      okButtonText={translate('sgw.requests.detail.phases.recurringPhaseModal.confirmButton')}
    >
      <form onSubmit={handleSubmit(onSubmit)} id={form}>
        <Grid container spacing={2}>
          <LabelValue label={translate('sgw.requests.detail.phases.recurringPhaseModal.startOfPhase')} labelCentered>
            <AsignDatepicker
              {...register('dateFrom', { required: true })}
              value={moment(watch('dateFrom'), DateFormat.reduxStoreDateString)}
              onChange={(value) =>
                setValue(
                  'dateFrom',
                  convertDateFormat(value, DateFormat.reduxStoreDateTimeString, DateFormat.reduxStoreDateString),
                )
              }
            />
            <SingleSelectV2Component
              {...register('timeFrom', { required: true })}
              fullWidth={false}
              onChange={(e) => setValue('timeFrom', `${e?.target?.value}`)}
              menuItems={toTimeOptions(FROM_TIMES)}
              value={watch('timeFrom')}
            />
          </LabelValue>
          <LabelValue label={translate('sgw.requests.detail.phases.recurringPhaseModal.endOfPhase')} labelCentered>
            <AsignDatepicker
              {...register('dateUntil', { required: true })}
              value={moment(watch('dateUntil'), DateFormat.reduxStoreDateString)}
              onChange={(value) =>
                setValue(
                  'dateUntil',
                  convertDateFormat(value, DateFormat.reduxStoreDateTimeString, DateFormat.reduxStoreDateString),
                )
              }
            />
            <SingleSelectV2Component
              {...register('timeUntil', { required: true })}
              fullWidth={false}
              onChange={(e) => setValue('timeUntil', `${e?.target?.value}`)}
              menuItems={toTimeOptions(FROM_TIMES)}
              value={watch('timeUntil')}
            />
          </LabelValue>
          <LabelValue label={translate('sgw.requests.detail.phases.recurringPhaseModal.startHour')} labelCentered>
            <SingleSelectV2Component
              {...register('workingHoursFrom', { required: true })}
              fullWidth={false}
              onChange={(e) => setValue('workingHoursFrom', `${e?.target?.value}`)}
              menuItems={toTimeOptions(FROM_TIMES)}
              value={watch('workingHoursFrom')}
            />
          </LabelValue>
          <LabelValue label={translate('sgw.requests.detail.phases.recurringPhaseModal.endHour')} labelCentered>
            <SingleSelectV2Component
              {...register('workingHoursUntil', { required: true })}
              fullWidth={false}
              onChange={(e) => {
                setValue('workingHoursUntil', `${e?.target?.value}`);
              }}
              menuItems={toTimeOptions(FROM_TIMES)}
              value={watch('workingHoursUntil')}
            />
          </LabelValue>
          <CheckboxWithLabel
            label={translate('sgw.requests.detail.phases.recurringPhaseModal.notStandardWorkinghours')}
            {...register('workingHoursNotStandard')}
            checked={!!watch('workingHoursNotStandard')}
            onChange={(value) => setValue('workingHoursNotStandard', value)}
          />
          <LabelValue label={translate('sgw.requests.detail.phases.recurringPhaseModal.parentPhase')} labelCentered>
            <SingleSelectV2Component
              {...register('parentPhaseId', { required: true })}
              onChange={(e) => setValue('parentPhaseId', e?.target?.value as number)}
              menuItems={phaseOptions}
              value={watch('parentPhaseId')}
            />
          </LabelValue>
        </Grid>
      </form>
    </ModalDialog>
  );
};
