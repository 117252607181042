import React, { FunctionComponent, useRef } from 'react';
// @ts-ignore - react-leaflet-draw doesn't have typescript support
import { EditControl } from 'react-leaflet-draw';
import { IMapDrawProps, MapDrawTypes } from '../../../types';
import { FeatureGroup } from 'react-leaflet';
import { Visible } from '../layout/Visible.component';
import { setLeafletDrawTranslations } from '../../utils/location.util';
import * as L from 'leaflet';

const defaultDrawTypes: MapDrawTypes = {
  circle: false,
  circlemarker: false,
  marker: false,
  polygon: false,
  polyline: false,
  rectangle: false,
};

export const Draw: FunctionComponent<Partial<IMapDrawProps>> = ({
  beginDrawingWithType,
  deletable = false,
  drawTypes = {},
  editable = false,
  onCreated,
  position = 'topright',
  visible = true,
  allowMultiLines = false,
}) => {
  const mapDrawRef = useRef<L.FeatureGroup>(null);
  setLeafletDrawTranslations();

  const _onCreated = (event: any) => {
    const drawing = mapDrawRef?.current?.toGeoJSON();
    // @ts-ignore - Typings are not known
    onCreated?.(drawing.features.pop().geometry);
    // Remove the current drawing
    mapDrawRef?.current?.removeLayer(event.layer);
  };

  const _onDrawVertex = (event: any) => {
    const layerIds = Object.keys(event.layers._layers);

    if (!allowMultiLines && layerIds.length > 1) {
      const secondVertex = event.layers._layers[layerIds[1]]._icon;
      requestAnimationFrame(() => secondVertex.click());
    }
  };

  const _onMounted = (drawControl: any) => {
    if (beginDrawingWithType) {
      drawControl._toolbars.draw._modes?.[beginDrawingWithType]?.handler.enable();
    }
  };

  return (
    <Visible visible={visible}>
      <FeatureGroup ref={mapDrawRef}>
        <EditControl
          key={Math.random()} // random key to force EditControl to update on rerender, fixes AS-8615
          position={position}
          onCreated={_onCreated}
          onDrawVertex={_onDrawVertex}
          onMounted={_onMounted}
          draw={{
            ...defaultDrawTypes,
            ...drawTypes,
          }}
          edit={{
            edit: editable,
            remove: deletable,
          }}
        />
      </FeatureGroup>
    </Visible>
  );
};
