import axios from 'axios';
import { CONFIG, IApiPromise } from '../../common';
import { IBearerToken, ILoginParams, IRefreshTokenParams } from '../../types';

export const LoginApi = {
  fetchToken: (params: ILoginParams): IApiPromise<IBearerToken> =>
    axios.get(CONFIG.tokenUrl, {
      params: {
        grant_type: 'password',
        ...params,
      },
    }),
  refreshToken: (params: IRefreshTokenParams): IApiPromise<IBearerToken> =>
    axios.get(CONFIG.tokenUrl, {
      params: {
        grant_type: 'refresh_token',
        ...params,
      },
    }),
  oauthToken: (oauthToken: string): IApiPromise<IBearerToken> =>
    axios.get(CONFIG.oauthTokenUrl, {
      params: {
        access_token: oauthToken,
        redirect: false,
      },
    }),
  logoutUrl: (redirectUri: string): IApiPromise<object> =>
    axios.post(CONFIG.logoutUrl, {
      redirectUri: redirectUri,
    }),
};
