import { Actions } from '../../common';
import { IBulkDownload, IFile, IFileMetadata } from '../../types';

class StorageActionsClass extends Actions {
  bulkDownload = this.createPayloadAction<IBulkDownload>('bulkDownload');
  clear = this.createAction('clear');
  upload = this.createPayloadAction<IFile>('upload');
  uploadError = this.createPayloadAction<IFileMetadata>('uploadError');
  uploadSuccess = this.createPayloadAction<IFileMetadata>('uploadSuccess');
  remove = this.createPayloadAction<{ id: string }>('remove');
}

export const StorageActions = new StorageActionsClass('storage');
