import React, { FC } from 'react';
import { Route, Switch } from 'react-router';
import { appUrls } from '../../common/config/url.constants';
import { AdvisingPartiesPage } from '../pages';
import { CreateAdvisingPartyPage } from '../pages/sgw/manage/AdvisingParties/CreateAdvisingPartyPage/CreateAdvisingParty.page';
import { DetailAdvisingPartyPage } from '../pages/sgw/manage/AdvisingParties/DetailAdvisingPartyPage/DetailAdvisingParty.page';
import { EditAdvisingPartyPage } from '../pages/sgw/manage/AdvisingParties/EditAdvisingPartyPage/EditAdvisingParty.page';

export const AdvisingPartiesRoutes: FC = () => (
  <Switch>
    <Route exact path={appUrls.sgw.manage.advisingParties.base}>
      <AdvisingPartiesPage />
    </Route>
    <Route exact path={appUrls.sgw.manage.advisingParties.create}>
      <CreateAdvisingPartyPage />
    </Route>
    <Route exact path={appUrls.sgw.manage.advisingParties.detail()}>
      <DetailAdvisingPartyPage />
    </Route>
    <Route exact path={appUrls.sgw.manage.advisingParties.edit()}>
      <EditAdvisingPartyPage />
    </Route>
  </Switch>
);
