import React, { FC } from 'react';
import { CheckboxWithLabel } from '../../../common/components/form-fields/checkboxWithLabel.component';
import { translate } from '../../../common/translations/translate';
import { LabelValue } from '../../../common/components/layout/LabelValue.component';
import { TextField } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { ISgwRequestInfo } from '../../../types';
import { useRequestDetailStyles } from '../../forms/RequestDetailInfoForm/requestDetailInfo.styles';
import { Visible } from '../../../common/components/layout/Visible.component';

export const RequestDetailInfoCraneFields: FC = () => {
  const { register, watch, setValue, getValues } = useFormContext<ISgwRequestInfo>();
  const C = useRequestDetailStyles();

  return (
    <>
      <CheckboxWithLabel
        label={translate('sgw.requests.detail.info.useOfCrane')}
        {...register('crane.isUsed')}
        checked={!!watch('crane.isUsed')}
        onChange={(value) => setValue('crane.isUsed', value)}
      />
      <Visible visible={!!watch('crane.isUsed')}>
        <CheckboxWithLabel
          label={translate('sgw.requests.detail.info.moreThan25Tons')}
          {...register('crane.moreThan25Tons')}
          checked={!!watch('crane.moreThan25Tons')}
          onChange={(value) => setValue('crane.moreThan25Tons', value)}
        />
        <LabelValue label={translate('sgw.requests.detail.info.craneMaxHeight')} className={C.bottomSpacing}>
          <TextField {...register('crane.maxHeight')} type="number" InputProps={{ inputProps: { min: 0 } }} />
        </LabelValue>
        <LabelValue label={translate('sgw.requests.detail.info.craneType')} className={C.bottomSpacing}>
          <TextField {...register('crane.type')} />
        </LabelValue>
        <LabelValue label={translate('sgw.requests.detail.info.requestorDescription')} className={C.bottomSpacing}>
          {getValues('workDescription')}
        </LabelValue>
      </Visible>
    </>
  );
};
