import * as React from 'react';
import { PropsWithChildren, ReactNode, useState } from 'react';
import { IPaging, ISorting, Paging, Panel } from '../../index';
import { Unavailable } from '../formatters/unavailable';
import { LoaderBlur } from '../layout/LoaderBlur.component';
import { Visible } from '../layout/Visible.component';
import { GenericTable } from './GenericTable.component';
import { IWithClassName } from '../../../types';
import { IColumn } from '../../../types/table.types';
import { BulkAction, GenericTableBulkActions } from './GenericTableBulkActions.component';
import { Loader } from '../layout/loader.component';
import { Disableable } from '../layout/Disableable.component';

interface IProps<DataType> extends IWithClassName {
  blurPaginationWhenLoading?: boolean;
  bulkActions?: BulkAction<DataType>[];
  columnKey: keyof DataType;
  columns: IColumn<DataType>[];
  customHeader?: ReactNode;
  dataTestId?: string;
  filterNode?: ReactNode;
  labelUnavailable: string;
  loading?: boolean;
  onChangeSorting?(sorting: ISorting): void;
  onChangePaging?(paging: IPaging): void;
  onClick?(record: DataType): void;
  paging?: IPaging;
  records?: DataType[];
  sorting?: ISorting;
  title?: ReactNode;
  onDelete?(record: DataType): void;
  recordToLink?(record: DataType): string;
  showPagination?: boolean;
}

export const GenericTablePanel = <DataType,>({
  blurPaginationWhenLoading,
  filterNode,
  bulkActions = [],
  showPagination = true,
  ...props
}: PropsWithChildren<IProps<DataType>>) => {
  const [selectedRecords, setSelectedRecords] = useState<DataType[]>([]);

  const onSelectRecord = (record: DataType) => {
    setSelectedRecords((previousRecords) =>
      previousRecords.includes(record) ? previousRecords.filter((r) => record !== r) : previousRecords.concat([record]),
    );
  };

  return (
    <div className={props.className} data-testid={props.dataTestId}>
      <Panel title={props.title}>
        <Visible visible={!!props.customHeader}>{props.customHeader}</Visible>
        <Visible visible={!!filterNode}>{filterNode}</Visible>
        {props.records?.length ? (
          <LoaderBlur loading={!!props.loading}>
            <GenericTable
              {...props}
              multiSelectable={!!bulkActions?.length}
              selectedRecords={selectedRecords}
              onSelectRecord={onSelectRecord}
            />
          </LoaderBlur>
        ) : (
          <Loader loading={!!props.loading}>
            <Unavailable text={props.labelUnavailable} />
          </Loader>
        )}
      </Panel>
      <Visible visible={!!bulkActions?.length}>
        <GenericTableBulkActions
          selectedRecords={selectedRecords}
          total={props.records?.length || 0}
          bulkActions={bulkActions}
        />
      </Visible>
      <Visible visible={showPagination && !!props.paging}>
        <Disableable disabled={!!(blurPaginationWhenLoading && props.loading)}>
          <Paging {...props.paging!} pageChange={props.onChangePaging} />
        </Disableable>
      </Visible>
    </div>
  );
};
