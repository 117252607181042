import React, { FunctionComponent } from 'react';
import Fab from '@material-ui/core/Fab';
import ClearIcon from '@material-ui/icons/Clear';
import { IWithStyle } from '../../../types';
import { translate } from '../../translations/translate';

interface IProps extends IWithStyle {
  onClick: () => void;
  className?: string;
}

export const ClearFiltersFab: FunctionComponent<IProps> = ({ onClick, style }) => (
  <Fab
    onClick={onClick}
    style={{ textTransform: 'none', whiteSpace: 'nowrap', ...style }}
    size={'medium'}
    color="primary"
    variant="extended"
  >
    <ClearIcon style={{ marginRight: 10 }} />
    {translate('clearFilters')}
  </Fab>
);
