import { FormControl, Grid, InputLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { SingleSelect } from '../../common';
import Clock from '../../common/components/clock/clock.component';
import { AsignDatepicker } from '../../common/components/form-fields/datepicker/asign-datepicker.component';
import { translate } from '../../common/translations/translate';
import { SIGN_STATES, SIGN_TYPES } from '../signs.constants';
import { ISignsMapFilter } from '../types/signs-map';
import { makeStyles } from '@material-ui/styles';

interface IProps {
  signsMapFilter: ISignsMapFilter;
  signsFilterChange: (filters: ISignsMapFilter) => void;
}

const useStyles = makeStyles({
  checkbox: { paddingTop: 19 },
  clock: { textAlign: 'end' },
  datePicker: { zIndex: 999 },
  dateFields: { marginTop: 8, flexWrap: 'nowrap' },
  delimiter: { paddingTop: 14 },
  filters: { paddingTop: 10, paddingRight: 20, paddingBottom: 15, paddingLeft: 20 },
});

export const SignsMapFilters: FunctionComponent<IProps> = (props: IProps) => {
  const C = useStyles();

  const typeFilterChange = (type: string) => {
    props.signsFilterChange({
      ...props.signsMapFilter,
      type,
    });
  };

  const stateFilterChange = (state: string) => {
    props.signsFilterChange({
      ...props.signsMapFilter,
      state,
    });
  };

  const dateFromFilterChange = (selectedDate: Moment) => {
    props.signsFilterChange({
      ...props.signsMapFilter,
      lastUpdatedFrom: selectedDate.format('YYYY-MM-DD'),
    });
  };

  const dateToFilterChange = (selectedDate: Moment) => {
    props.signsFilterChange({
      ...props.signsMapFilter,
      lastUpdatedTo: selectedDate.format('YYYY-MM-DD'),
    });
  };

  const hideOnMapFilterChange = () => {
    props.signsFilterChange({
      ...props.signsMapFilter,
      showHiddenSigns: !props.signsMapFilter.showHiddenSigns,
    });
  };

  return (
    <>
      <Grid container spacing={2} justify="space-between" className={C.filters}>
        <Grid item md={1} lg={2}>
          <SingleSelect
            data={[
              { value: ' ', display: translate('All') },
              { value: SIGN_TYPES.DIGITAL, display: translate('DigitalSign') },
              { value: SIGN_TYPES.MANUAL, display: translate('ManualSign') },
            ]}
            value={props.signsMapFilter.type}
            valueChange={typeFilterChange}
            valueProp="value"
            keyProp="value"
            displayProp="display"
            id="signTypeFilter"
            label={translate('planning.filter.signTypes')}
          />
        </Grid>
        <Grid item md={1} lg={2}>
          <SingleSelect
            data={[
              { value: ' ', display: translate('All') },
              { value: SIGN_STATES.PLACED, display: translate('Placed') },
              { value: SIGN_STATES.MISSING, display: translate('Missing') },
              { value: SIGN_STATES.MOVED, display: translate('Moved') },
            ]}
            value={props.signsMapFilter.state}
            valueChange={stateFilterChange}
            valueProp="value"
            keyProp="value"
            displayProp="display"
            id="signStateFilter"
            label={translate('FilterOnSignState')}
          />
        </Grid>
        <Grid item md={5} lg={3}>
          <FormControl fullWidth>
            <InputLabel shrink>{translate('FilterOnLastInfo')}</InputLabel>
            <Grid container spacing={2} className={C.dateFields}>
              <Grid item className={C.datePicker}>
                <AsignDatepicker
                  value={moment(props.signsMapFilter.lastUpdatedFrom)}
                  onChange={dateFromFilterChange}
                  id="lastUpdatedFrom"
                />
              </Grid>
              <Grid item className={C.delimiter}>
                -
              </Grid>
              <Grid item className={C.datePicker}>
                <AsignDatepicker
                  value={moment(props.signsMapFilter.lastUpdatedTo)}
                  onChange={dateToFilterChange}
                  id="lastUpdatedTo"
                />
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
        <Grid item md={3} lg={4} className={C.checkbox}>
          <Checkbox
            value={props.signsMapFilter.showHiddenSigns}
            onChange={hideOnMapFilterChange}
            id="showHiddenSignsFilter"
          />
          {translate('SignMap.showHiddenSigns')}
        </Grid>
        <Grid item md={2} lg={1} className={C.clock}>
          <Clock />
        </Grid>
      </Grid>
    </>
  );
};
