import React, { FC } from 'react';
import { Route, Switch } from 'react-router';
import { appUrls } from '../../common/config/url.constants';
import { DetailProjectPage, ProjectsPage, EditProjectPage, CreateProjectPage } from '../pages/sgw/manage';

export const ProjectRoutes: FC = () => (
  <Switch>
    <Route exact path={appUrls.sgw.manage.projects.base}>
      <ProjectsPage />
    </Route>
    <Route exact path={appUrls.sgw.manage.projects.create}>
      <CreateProjectPage />
    </Route>
    <Route exact path={appUrls.sgw.manage.projects.detail()}>
      <DetailProjectPage />
    </Route>
    <Route exact path={appUrls.sgw.manage.projects.edit()}>
      <EditProjectPage />
    </Route>
  </Switch>
);
