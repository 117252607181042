import { createContext, useContext } from 'react';
import { IBaseMapContext } from '../../../types';
import { BASE_MAP_BASE_LAYERS, BASE_MAP_OVERLAY_LAYERS, BaseMapDefaultLayers } from '../../config/map.config';

export const BaseMapContext = createContext<IBaseMapContext>({
  addOverlay: () => {},
  baseLayers: BASE_MAP_BASE_LAYERS,
  flyToBounds: () => {},
  mapRef: null,
  overlayLayers: BASE_MAP_OVERLAY_LAYERS,
  removeOverlay: () => {},
  selectBaseLayer: () => {},
  selectedOverlayLayers: BaseMapDefaultLayers.overlayLayers,
  selectedBaseLayer: BaseMapDefaultLayers.baseLayer,
  setBaseLayers: () => {},
  setOverlayLayers: () => {},
});

export const useBaseMap = () => useContext(BaseMapContext);
