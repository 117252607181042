import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { Visible } from '../../../common/components/layout/Visible.component';
import { translate } from '../../../common/translations/translate';

interface IProps {
  showError: boolean;
  message?: string;
}

const useStyle = createUseStyles({
  error: {
    color: 'red',
    marginTop: '0.5rem',
  },
});

export const ErrorLabel: FC<IProps> = ({ showError, message }) => (
  <Visible visible={showError}>
    <small className={useStyle().error} role="alert" aria-live="polite">
      {message || translate('required')}
    </small>
  </Visible>
);
