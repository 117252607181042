import { FC, PropsWithChildren } from 'react';
import { Visible } from '../../common/components/layout/Visible.component';
import * as React from 'react';

interface IProps {
  label: string;
  visible?: boolean;
}

export const PopupItem: FC<PropsWithChildren<IProps>> = ({ label, visible = true, children }) => {
  return (
    <Visible visible={visible}>
      <strong>{label}:</strong>&nbsp;{children}
      <br />
    </Visible>
  );
};
