import { Paper } from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../../common/components/layout/loader.component';
import { PanelContent } from '../../../common/components/panel/panel-content.component';
import { translate } from '../../../common/translations/translate';
import { SgwPartnerActions } from '../../../store/actions';
import { getSgwRequestWithAddedFields } from '../../../store/selectors/sgwRequest.selectors';
import { useAuthorization, useRequestAuthorization, useRequestMetadata } from '../../../hooks';
import { PanelTitle } from '../../atoms';
import { RequestDetailInfoContent } from './RequestDetailInfoContent.component';
import { atomWithHash } from 'jotai/utils';
import { useAtom } from 'jotai';
import { RequestDetailInfoForm } from './RequestDetailInfoForm.component';
import { useRequestDetailStyles } from './requestDetailInfo.styles';
import { ISgwRequestDetailAclActions } from '../../../types';

export const requestEditAtom = atomWithHash('rqst-edit', false);

export const RequestDetailInfo: FC = () => {
  const C = useRequestDetailStyles();
  const dispatch = useDispatch();
  const requestMetadata = useRequestMetadata();
  const request = useSelector(getSgwRequestWithAddedFields);
  const [editMode, setEditMode] = useAtom(requestEditAtom);
  const { isAuthorized: canViewPartnerInfo } = useAuthorization(ISgwRequestDetailAclActions.viewSgwPartnerInformation);
  const { isAuthorized: canEdit } = useRequestAuthorization(ISgwRequestDetailAclActions.editSgwGeneralInformation);

  const cluster = requestMetadata?.clusters?.find(({ cluster }) => cluster === request?.cluster);

  useEffect(() => {
    canViewPartnerInfo && request?.requestorId && dispatch(SgwPartnerActions.fetch(`${request.requestorId}`));
  }, [dispatch, request, canViewPartnerInfo]);

  if (!request) {
    return <Loader loading />;
  }

  return (
    <Paper square data-testid="sgwRequestDetailInfo" className={C.paperSpacing}>
      <PanelContent
        title={
          <PanelTitle onEdit={canEdit ? () => setEditMode(!editMode) : undefined}>
            {translate('sgw.requests.detail.info.title')}
          </PanelTitle>
        }
      >
        {editMode && canEdit ? (
          <RequestDetailInfoForm {...request} cluster={cluster} />
        ) : (
          <RequestDetailInfoContent {...request} cluster={cluster} />
        )}
      </PanelContent>
    </Paper>
  );
};
