import React, { FC, useState } from 'react';
import { Grid, Link } from '@material-ui/core';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { Visible } from '../../../common/components/layout/Visible.component';
import { translate, translateIgnoreTS } from '../../../common/translations/translate';
import { SgwPartnerType } from '../../../types';
import { getRequestPartnersId } from '../../../store/selectors/sgwRequest.selectors';
import { useModalHandler } from '../../../hooks';
import ModalDialog from '../../../common/components/modal-dialog/modal-dialog.component';
import { SgwRequestActions } from '../../../store/actions';
import { usePartnerContext } from '../../../hooks/usePartnerContext.hook';
import { getRequestFieldByPartnerType } from '../../../utils';

const useStyles = createUseStyles({
  action: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 2rem!important',
    fontWeight: 500,
  },
});

enum ActionButtonType {
  partnerSameAsRequester = 'partnerSameAsRequester',
  partnerSameAsContractor = 'partnerSameAsContractor',
  partnerNotSameAsRequester = 'partnerNotSameAsRequester',
}

interface IProps {
  openForm(): void;
}

export const PartnerActionButtons: FC<IProps> = ({ openForm }) => {
  const C = useStyles();
  const dispatch = useDispatch();
  const { partnerId, partnerType, request, isSameAsRequester, setCreateMode } = usePartnerContext();
  const requestPartnerIds = useSelector(getRequestPartnersId);
  const { open, closeModal, openModal } = useModalHandler();
  const [currentActionType, setCurrentActionType] = useState<ActionButtonType>();

  const onClick = (actionType: ActionButtonType) => () => {
    setCurrentActionType(actionType);
    openModal();
  };

  const onConfirm = () => {
    let value = requestPartnerIds[SgwPartnerType.requester];
    if (currentActionType === ActionButtonType.partnerSameAsContractor) {
      value = requestPartnerIds[SgwPartnerType.contractor];
    }

    request &&
      dispatch(
        SgwRequestActions.patch({
          id: request.id,
          [getRequestFieldByPartnerType(partnerType)]: value,
        }),
      );

    closeModal();
  };

  const _openForm = () => {
    setCreateMode(true);
    openForm();
  };

  return (
    <Grid item xs={4}>
      <Grid container spacing={2}>
        <Visible visible={!isSameAsRequester && partnerType !== SgwPartnerType.requester}>
          <Grid item xs={12} className={C.action}>
            <Link onClick={onClick(ActionButtonType.partnerSameAsRequester)}>
              {translate('sgw.requests.detail.partners.partnerSameAsRequester')}
            </Link>
          </Grid>
        </Visible>
        <Visible
          visible={partnerType === SgwPartnerType.fee && partnerId !== requestPartnerIds[SgwPartnerType.contractor]}
        >
          <Grid item xs={12} className={C.action}>
            <Link onClick={onClick(ActionButtonType.partnerSameAsContractor)}>
              {translate('sgw.requests.detail.partners.partnerSameAsContractor')}
            </Link>
          </Grid>
        </Visible>
        <Visible visible={isSameAsRequester}>
          <Grid item xs={12} className={C.action}>
            <Link onClick={_openForm}>{translate('sgw.requests.detail.partners.partnerNotSameAsRequester')}</Link>
          </Grid>
        </Visible>
        <ModalDialog
          okButtonText={translate('yes')}
          onClose={closeModal}
          onConfirm={onConfirm}
          title={translateIgnoreTS(`sgw.requests.detail.partners.${currentActionType}`)}
          visible={open}
        >
          {translateIgnoreTS(`sgw.requests.detail.partners.${currentActionType}Info`)}
        </ModalDialog>
      </Grid>
    </Grid>
  );
};
