import { makeStyles } from '@material-ui/core/styles';
import React, { FunctionComponent, PropsWithChildren, useCallback } from 'react';
import { IBaseMapControl } from '../../../types';
import classNames from 'classnames';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    overflow: 'hidden',
    position: 'absolute',
    right: 70,
    top: 10,
    zIndex: 800,
    border: '2px solid rgba(0,0,0,0.2)',
    borderRadius: 5,
    width: 48,
    height: 48,
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFF',
    padding: 5,
    cursor: 'pointer',
    height: '100%',
    width: '100%',
  },
});

export const BaseMapControl: FunctionComponent<PropsWithChildren<IBaseMapControl>> = ({
  children,
  className,
  onClick,
  tooltip = '',
  ...props
}) => {
  const C = useStyles();

  const _onClick = useCallback(() => {
    onClick?.();
  }, [onClick]);

  return (
    <Tooltip title={tooltip}>
      <div className={classNames(C.container, className)} {...props}>
        <div className={C.button} onClick={_onClick}>
          {children}
        </div>
      </div>
    </Tooltip>
  );
};
