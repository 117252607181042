import { SagaIterator } from 'redux-saga';
import { all, call, put, select, take, takeEvery, takeLatest, throttle } from 'redux-saga/effects';
import { genericErrorHandler, IReverseGeocodeAddress } from '../../common';
import { translate } from '../../common/translations/translate';
import { geometryToPositions, getGeoCodeAddress } from '../../common/utils/geojson.util';
import { SignsApi } from '../../signs/store/signs.api';
import { HttpStatusCodes, ICall, IRequestLocationForm, ISelect, IStoredFile, SnackBarVariant } from '../../types';
import { RequestActions, RequestLocationsActions, SnackBarActions, StorageActions } from '../actions';
import { GeolocationApi, RequestsApi } from '../api';
import { getIsGeoCodeFetched, selectRequestLocationDetail } from '../selectors';
import { takeUploadResult } from './storage.saga';

function* onPatchRequestLocation({ payload }: ReturnType<typeof RequestLocationsActions.patch>): SagaIterator {
  const response: ICall<typeof RequestsApi.patchRequestLocation> = yield call(
    RequestsApi.patchRequestLocation,
    payload,
  );
  const intake = response!.data.data;
  // @ts-ignore permitRequestId would not exist - investigation needed
  yield put(RequestActions.fetch(intake.permitRequestId));
}

function* onAddSignToLocation({ payload }: ReturnType<typeof RequestLocationsActions.addSign>): SagaIterator {
  const response: ICall<typeof SignsApi.updateSign> = yield call(SignsApi.updateSign, {
    hash: payload.signId,
    parkingBanIntake: payload.location.id,
  });
  if (response!.status === HttpStatusCodes.OK) {
    yield put(RequestActions.fetch(payload.location.permitRequestId));
  }
}

function* onRemoveSignFromLocation({ payload }: ReturnType<typeof RequestLocationsActions.removeSign>): SagaIterator {
  const response: ICall<typeof SignsApi.updateSign> = yield call(SignsApi.updateSign, {
    hash: payload.signId,
    parkingBanIntake: null,
  });
  if (response!.status === HttpStatusCodes.OK) {
    yield put(RequestActions.fetch(payload.location.permitRequestId));
  }
}

function* onFetchLocationDetails({ payload }: ReturnType<typeof RequestLocationsActions.fetch>): SagaIterator {
  const response: ICall<typeof RequestsApi.fetchLocationDetails> = yield call(
    RequestsApi.fetchLocationDetails,
    payload,
  );
  yield put(RequestLocationsActions.set(response!.data.data));
}

function* onUploadFile({ payload }: ReturnType<typeof RequestLocationsActions.uploadFile>): SagaIterator {
  yield put(StorageActions.upload(payload));
  const resultAction:
    | ReturnType<typeof StorageActions.uploadError>
    | ReturnType<typeof StorageActions.uploadSuccess>
    | undefined = yield take(takeUploadResult(payload.metadata.id));
  if (resultAction!.type === StorageActions.uploadSuccess.type) {
    const detail: ISelect<typeof selectRequestLocationDetail> = yield select(selectRequestLocationDetail);
    const { attachments: atts, id: locationId, permitRequestId: id } = detail!;
    const attachments = [...atts];
    const { name, uploadId } = resultAction!.payload;
    attachments.push({ file: uploadId!, name } as IStoredFile);
    const response: ICall<typeof RequestsApi.patchRequestLocation> = yield call(RequestsApi.patchRequestLocation, {
      id,
      locationId,
      attachments,
    });
    // @ts-ignore - Investigation needed as typings don't resolve
    yield put(RequestLocationsActions.set(response!.data.data));
  }
}

function* onRemoveFile({ payload }: ReturnType<typeof RequestLocationsActions.removeFile>): SagaIterator {
  const detail: ISelect<typeof selectRequestLocationDetail> = yield select(selectRequestLocationDetail);
  const { attachments, id: locationId, permitRequestId: id } = detail!;
  const _attachments = attachments.filter(({ file }: IStoredFile) => file !== payload);
  const response: ICall<typeof RequestsApi.patchRequestLocation> = yield call(RequestsApi.patchRequestLocation, {
    id,
    locationId,
    attachments: _attachments,
  });
  // @ts-ignore - Investigation needed as typings don't resolve
  yield put(RequestLocationsActions.set(response!.data.data));
}

function* onMarkSignsAsMissing({
  payload,
}: ReturnType<typeof RequestLocationsActions.markSignsAsMissing>): SagaIterator {
  const signs = payload.signs || [];
  yield all(signs.filter((sign) => sign.active).map(({ sign }) => call(SignsApi.markSignAsMissing, sign.hash)));
  yield put(RequestActions.fetch(payload.permitRequestId));
}

function* onFetchSuggestions({ payload }: ReturnType<typeof RequestLocationsActions.fetchSuggestions>): SagaIterator {
  const response: ICall<typeof GeolocationApi.getSuggestions> = yield call(
    GeolocationApi.getSuggestions,
    `${payload.street}` + (payload.zipCode ? ` ${payload.zipCode}` : '') + (payload.city ? ` ${payload.city}` : ''),
  );
  yield put(RequestLocationsActions.setSuggestions(response!.data.data));
}

function* onFetchGeoCode({ payload }: ReturnType<typeof RequestLocationsActions.fetchGeocode>): SagaIterator {
  const address = getGeoCodeAddress(payload);
  const fetched = yield select(getIsGeoCodeFetched(address));
  if (!fetched) {
    const response: ICall<typeof GeolocationApi.getGeocode> = yield call(GeolocationApi.getGeocode, address);

    if (response?.status === HttpStatusCodes.NO_CONTENT) {
      yield put(RequestActions.setGeoCodeCorrect(false));
      yield put(
        SnackBarActions.setFeedback({
          feedback: translate('Requests.Create.AddressNotFound'),
          variant: SnackBarVariant.error,
        }),
      );
    } else {
      yield all([
        put(RequestActions.setGeoCodeCorrect(true)),
        put(RequestLocationsActions.setGeocode({ ...response!.data.data.point, id: address })),
        put(SnackBarActions.setFeedback(null)),
      ]);
    }
  } else {
    yield put(RequestActions.setGeoCodeCorrect(true));
  }
}

function* onReverseGeoCode({ payload }: ReturnType<typeof RequestLocationsActions.reverseGeocode>): SagaIterator {
  const positions = geometryToPositions(payload.geometry);
  const responses = yield all(positions.map((position) => call(GeolocationApi.reverseGeocode, position)));
  // @ts-ignore - Need to check how to type this all
  const addresses: IReverseGeocodeAddress[] = responses.map(
    (response: { data: { data: Partial<IRequestLocationForm> } }) => response.data.data,
  );

  if (!addresses.length) return;

  const location: Partial<IRequestLocationForm> = {
    city: addresses[0].city,
    id: payload.locationId,
    zipCode: addresses[0].zipCode,
  };

  const allStreetsAreTheSame = addresses.every(({ streetName }) => streetName === addresses[0].streetName);

  if (allStreetsAreTheSame) {
    const streetNumbers = addresses.map(({ streetNumber }) => streetNumber).sort((a, b) => a.localeCompare(b, 'nl'));
    location.street = addresses[0].streetName;
    location.streetNumberFrom = streetNumbers[0];
    location.streetNumberTo = streetNumbers[streetNumbers.length - 1];
  }

  yield put(RequestLocationsActions.setReverseGeocode(location));
}

export function* requestLocationsSaga(): SagaIterator {
  yield takeEvery(RequestLocationsActions.patch.type, genericErrorHandler(onPatchRequestLocation));
  yield takeLatest(RequestLocationsActions.addSign.type, genericErrorHandler(onAddSignToLocation));
  yield takeLatest(RequestLocationsActions.markSignsAsMissing.type, genericErrorHandler(onMarkSignsAsMissing));
  yield takeLatest(RequestLocationsActions.removeSign.type, genericErrorHandler(onRemoveSignFromLocation));
  yield takeLatest(RequestLocationsActions.fetch.type, genericErrorHandler(onFetchLocationDetails));
  yield takeEvery(RequestLocationsActions.removeFile.type, genericErrorHandler(onRemoveFile));
  yield takeEvery(RequestLocationsActions.uploadFile.type, genericErrorHandler(onUploadFile));
  yield takeEvery(RequestLocationsActions.fetchGeocode.type, genericErrorHandler(onFetchGeoCode));
  yield takeEvery(RequestLocationsActions.reverseGeocode.type, genericErrorHandler(onReverseGeoCode));
  yield throttle(1000, RequestLocationsActions.fetchSuggestions.type, genericErrorHandler(onFetchSuggestions));
}
