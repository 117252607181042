import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { Visible } from '../../../common/components/layout/Visible.component';
import { translate } from '../../../common/translations/translate';
import { formatDateTimeToDisplayDate } from '../../../common/utils/date.util';
import { selectPhases } from '../../../store/selectors/sgwRequest.selectors';
import { useGeneralStyles } from '../../../style/generalStyles';
import { IRequestRescheduling } from '../../../types/SgwReschedulings.types';

export interface IProps {
  rescheduling: IRequestRescheduling;
}

const useStyles = createUseStyles({
  gridMargin: {
    marginTop: '-8px',
  },
});

export const SgwReschedulingContent: FC<IProps> = ({ rescheduling }) => {
  const phases = useSelector(selectPhases);
  const G = useGeneralStyles();
  const C = useStyles();

  return (
    <Grid container>
      {rescheduling.phaseReschedulings.map((phaseRescheduling, index) => (
        <Grid
          key={phaseRescheduling.sgwPhaseId + index}
          container
          item
          alignItems="center"
          spacing={4}
          className={C.gridMargin}
        >
          <Grid item xs={4}>
            <div>
              <div>{phases.find((phase) => phase.id === phaseRescheduling.sgwPhaseId)?.phaseName}</div>
              <small>{`${formatDateTimeToDisplayDate(
                phaseRescheduling.originalDateFrom,
              )} - ${formatDateTimeToDisplayDate(phaseRescheduling.originalDateUntil)}`}</small>
            </div>
          </Grid>
          <Grid item xs={3}>
            {formatDateTimeToDisplayDate(phaseRescheduling.newDateFrom)}
          </Grid>
          <Grid item xs={3}>
            {formatDateTimeToDisplayDate(phaseRescheduling.newDateUntil)}
          </Grid>
        </Grid>
      ))}

      <div className={classNames(G.flexColumn, G.marginTop20)}>
        <b>{translate('sgw.requests.detail.requestRescheduling.accordion.additionalClarification')}</b>
        <div className={G.gridContainerPadding}>
          <Visible
            visible={!!rescheduling.description}
            defaultComponent={
              <span>{translate('sgw.requests.detail.requestRescheduling.accordion.noClarification')}</span>
            }
          >
            {rescheduling.description}
          </Visible>
        </div>
      </div>
    </Grid>
  );
};
