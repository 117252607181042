import { createUseStyles } from 'react-jss';
import { COLORS } from '../../../common';
export const useStyles = createUseStyles({
  header: {
    border: `1px solid ${COLORS.GREY_LIGHT}`,
    backgroundColor: COLORS.GREY_LIGHTER,
    padding: '5px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  title: {
    fontSize: 'larger',
  },
  quarter: {
    padding: '5px 10px',
    backgroundColor: COLORS.BLUE_LIGHTER,
  },
  invoicedQuarter: {
    padding: '5px 10px',
    backgroundColor: COLORS.GREEN_LIGHTER,
  },
  quarterTotal: {
    padding: '5px 10px',
    border: `1px solid ${COLORS.GREY_LIGHT}`,
    backgroundColor: COLORS.BLUE_LIGHTER,
    textAlign: 'right',
  },
  invoicedQuarterTotal: {
    padding: '5px 10px',
    border: `1px solid ${COLORS.GREY_LIGHT}`,
    backgroundColor: COLORS.GREEN_LIGHTER,
    textAlign: 'right',
  },
  phaseName: {
    padding: '5px 10px',
    borderBottom: `1px solid ${COLORS.GREY_LIGHT}`,
  },
  recurringPhaseName: {
    padding: '5px 30px',
    borderBottom: `1px solid ${COLORS.GREY_LIGHT}`,
  },
  phase: {
    padding: '5px 10px',
    borderBottom: `1px solid ${COLORS.GREY_LIGHT}`,
    display: 'flex',
    justifyContent: 'center',
  },
  phaseTotal: {
    padding: '5px 10px',
    textAlign: 'right',
  },
});
