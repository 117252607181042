import React, { FC } from 'react';
import { Header } from '../../../../../../common/components/layout/Header.component';
import { appUrls } from '../../../../../../common/config/url.constants';
import { Auth } from '../../../../../atoms';
import { ISgwTrafficMeasureAclActions } from '../../../../../../types';
import { Container } from '../../../../../../common';
import { translate } from '../../../../../../common/translations/translate';
import { TrafficMeasureForm } from '../../../../../forms';
import { useFormStyles } from '../../../../../../style/form.styles';

export const CreateTrafficMeasuresPage: FC = () => (
  <Auth acl={ISgwTrafficMeasureAclActions.createSgwTrafficMeasure} showUnauthorizedPage>
    <Header backButtonUrl={appUrls.sgw.manage.trafficMeasures.base}>
      {translate('sgw.trafficMeasures.newTrafficMeasure')}
    </Header>
    <Container className={useFormStyles().container}>
      <TrafficMeasureForm title={translate('sgw.trafficMeasures.newTrafficMeasureDetails')} />
    </Container>
  </Auth>
);
