import { Grid, TextField } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import * as I18n from 'i18n-js';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { FunctionComponent, useEffect } from 'react';
import { MultiSelect } from '../../common';
import { AsignDatepicker } from '../../common/components/form-fields/datepicker/asign-datepicker.component';
import { ISign } from '../types/sign';
import { ISignCondition } from '../types/sign-condition';
import { ISignState } from '../types/sign-state';
import { ISignType } from '../types/sign-type';
import { ISignsFilter } from '../types/signs-filter';

interface IProps {
  signConditions: ISignCondition[];
  signStates: ISignState[];
  signTypes: ISignType[];
  signsFilter: ISignsFilter;
  signsFilterChange: (filters: ISignsFilter) => void;
  signClick: (sign: ISign) => void;
  inEditMode: boolean;
}

export const SignsListFilters: FunctionComponent<IProps> = (props: IProps) => {
  useEffect(() => {
    // Initialize filters when edit-mode turns on and clean up when off
    if (!props.inEditMode && props.signsFilter.lastUpdatedFrom) {
      props.signsFilterChange({
        ...props.signsFilter,
        lastUpdatedFrom: undefined,
        lastUpdatedTo: undefined,
      });
    } else if (props.inEditMode && !props.signsFilter.lastUpdatedFrom) {
      props.signsFilterChange({
        ...props.signsFilter,
        lastUpdatedFrom: '2017-01-01',
        lastUpdatedTo: moment().format('YYYY-MM-DD'),
      });
    }
  }, [props]);

  const typeFilterChange = (types: string[]) => {
    props.signsFilterChange({
      ...props.signsFilter,
      types,
    });
  };

  const stateFilterChange = (states: string[]) => {
    props.signsFilterChange({
      ...props.signsFilter,
      states,
    });
  };

  const conditionFilterChange = (conditions: string[]) => {
    props.signsFilterChange({
      ...props.signsFilter,
      conditions,
    });
  };

  const idFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.signsFilterChange({
      ...props.signsFilter,
      query: event.target.value,
    });
  };

  const moduleIdFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.signsFilterChange({
      ...props.signsFilter,
      sigfoxDeviceId: event.target.value,
    });
  };

  const dateFromFilterChange = (selectedDate: Moment) => {
    props.signsFilterChange({
      ...props.signsFilter,
      lastUpdatedFrom: selectedDate.format('YYYY-MM-DD'),
    });
  };

  const dateToFilterChange = (selectedDate: Moment) => {
    props.signsFilterChange({
      ...props.signsFilter,
      lastUpdatedTo: selectedDate.format('YYYY-MM-DD'),
    });
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        justify="space-between"
        style={{ marginBottom: 20, paddingLeft: 20, paddingRight: 20 }}
      >
        <Grid item xs={2}>
          <TextField
            value={props.signsFilter.query}
            onChange={idFilterChange}
            fullWidth
            id="signIdFilter"
            label={I18n.t('FilterOnSignID')}
            InputProps={{
              endAdornment: <SearchIcon />,
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            value={props.signsFilter.sigfoxDeviceId}
            onChange={moduleIdFilterChange}
            fullWidth
            id="moduleIdFilter"
            label={I18n.t('FilterOnModuleID')}
            InputProps={{
              endAdornment: <SearchIcon />,
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <MultiSelect
            data={props.signTypes}
            value={props.signsFilter.types || []}
            valueChange={typeFilterChange}
            valueProp="type"
            keyProp="type"
            displayProp="name"
            id="signTypeFilter"
            label={I18n.t('FilterOnSignType')}
          />
        </Grid>
        <Grid item xs={2}>
          <MultiSelect
            data={props.signStates}
            value={props.signsFilter.states || []}
            valueChange={stateFilterChange}
            valueProp="state"
            keyProp="state"
            displayProp="name"
            id="signStateFilter"
            label={I18n.t('FilterOnSignState')}
          />
        </Grid>
        <Grid item xs={2}>
          <MultiSelect
            data={props.signConditions}
            value={props.signsFilter.conditions || []}
            valueChange={conditionFilterChange}
            valueProp="condition"
            keyProp="condition"
            displayProp="name"
            id="signConditionFilter"
            label={I18n.t('FilterOnSignCondition')}
          />
        </Grid>
      </Grid>
      {props.inEditMode && (
        <Grid container spacing={2} style={{ marginBottom: 20, paddingLeft: 20, paddingRight: 20 }} justify="flex-end">
          <Grid item style={{ paddingTop: 14 }}>
            {I18n.translate('FilterOnSignsUpdated')}
          </Grid>
          <Grid item>
            <AsignDatepicker value={moment(props.signsFilter.lastUpdatedFrom)} onChange={dateFromFilterChange} />
          </Grid>
          <Grid item style={{ paddingTop: 14 }}>
            -
          </Grid>
          <Grid item>
            <AsignDatepicker value={moment(props.signsFilter.lastUpdatedTo)} onChange={dateToFilterChange} />
          </Grid>
        </Grid>
      )}
    </>
  );
};
