import { Grid } from '@material-ui/core';
import * as React from 'react';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsAuthorized } from '../../../../common/store/user/user.selectors';
import { RequestLocationsActions } from '../../../../store/actions';
import { IPublicDomainIntakeExtended, IRequestAclActions } from '../../../../types';
import { LicensePlatesEditor } from './license-plates-editor.component';

interface IProps {
  location: IPublicDomainIntakeExtended;
}

/** For requests placed by Werkhaven */
export const LocationLicensePlatesWerkhavenComponent: FunctionComponent<IProps> = ({ location }) => {
  const dispatch = useDispatch();
  const canAddLicensePlatesToRequest = useSelector(getIsAuthorized([IRequestAclActions.addLicensePlatesToRequest]));

  const [parkedPlates, setParkedPlates] = useState<string[]>([]);

  useEffect(() => setParkedPlates(location.parkedPlates || []), [location]);

  const onParkedPlatesChange = useCallback(
    (newParkedPlates: string[]) => {
      dispatch(
        RequestLocationsActions.patch({
          id: `${location.permitRequestId}`,
          locationId: `${location.id}`,
          parkedPlates: newParkedPlates,
        }),
      );
      setParkedPlates(newParkedPlates);
    },
    [dispatch, location.id, location.permitRequestId],
  );

  return (
    <Grid container alignItems={'flex-end'} data-testid="LocationLicensePlatesWerkhaven">
      <Grid item sm={12}>
        <LicensePlatesEditor
          parkedPlates={parkedPlates}
          onParkedPlatesChange={onParkedPlatesChange}
          showAddMode={canAddLicensePlatesToRequest}
        />
      </Grid>
    </Grid>
  );
};
