import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  root: { width: '100%' },
});

export interface IOption {
  value: string;
  label: string;
}

interface IProps {
  label: string;
  optionsSelector: any;
  onChange: (value: string | null) => void;
  onQueryChange: (value?: any) => void;
  value: string | null;
}

export const AutocompleteQueryComponent: FunctionComponent<IProps> = ({
  label,
  optionsSelector,
  onChange,
  onQueryChange,
  value,
}) => {
  const [inputValue, setInputValue] = useState<string | null>('');
  const C = useStyles();

  const options: IOption[] = useSelector(optionsSelector);

  useEffect(() => onQueryChange(inputValue), [inputValue, onQueryChange]);
  useEffect(() => onQueryChange(value), [value, onQueryChange]);

  const val = options.find((i) => i.value === inputValue) || null;

  return (
    <Autocomplete
      className={C.root}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={val}
      onChange={(event: any, newValue: IOption | null) => {
        if (newValue) {
          setInputValue(newValue.value);
          onChange(newValue.value);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => <TextField {...params} label={label} fullWidth margin="none" />}
      renderOption={(option) => {
        return (
          <Grid container alignItems="center">
            <Grid item xs>
              <span>{option.label}</span>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};
