import { FunctionComponent, useCallback, useEffect, useRef } from 'react';
import { ICarFreeZoneFeature, ICarFreeZoneFeatureProperties, IMapLayer, MapLayerTypes } from '../../../types';
import { CONFIG } from '../../config/config';
import { useBaseMap } from './BaseMap.context';
import { LatLngBounds } from 'leaflet';

interface IProps {
  bounds?: LatLngBounds;
  onSelectGisId?: (carFreeZoneFeature: ICarFreeZoneFeature) => void;
  selectedGisIds?: string | string[];
}

export const SelectableCarFreeZoneLayer: FunctionComponent<IProps> = ({
  bounds,
  onSelectGisId,
  selectedGisIds = [],
}) => {
  const { overlayLayers, setOverlayLayers } = useBaseMap();
  const refFeatureLayers = useRef<{ feature: any; layer: any }[]>([]);

  const click = useCallback(
    ({ target }: any) => {
      onSelectGisId?.(target.feature as ICarFreeZoneFeature);
    },
    [onSelectGisId],
  );

  const refreshFeature = useCallback(
    ({ layer, feature }: any) => {
      const { GISID_GEBIED, FIRST_NAAM_GEBIED, VENSTERTIJD, HANDHAVING }: ICarFreeZoneFeatureProperties =
        feature.properties;
      // Removing the click handler with a ref to the function didn't work. Therefore the layer.off is removing
      // all the click handlers, including the ones for bind popup. If you don't first cleanup the popup it will
      // not be able to bind it later on.
      layer.unbindPopup(); // Clean popup
      layer.off('click'); // Clean all click handlers
      layer.on({ click }); // Register onClick handler
      layer.bindPopup(`
        <div>${HANDHAVING} ${FIRST_NAAM_GEBIED}</div>
        <div>${VENSTERTIJD}</div>
      `); // Bind the popup
      const gisIds = Array.isArray(selectedGisIds) ? selectedGisIds : [selectedGisIds];
      const color = gisIds.includes(GISID_GEBIED) ? 'green' : 'blue';
      layer.setStyle({ color, fillColor: color });
    },
    [selectedGisIds, click],
  );

  useEffect(() => {
    const carFreeZoneLayer = overlayLayers.find(({ name }) => name === 'carFreeZones.layer')!;
    const updatedCarFreeZoneLayer: IMapLayer = {
      ...carFreeZoneLayer,
      id: 10,
      name: 'carFreeZones.layer',
      type: MapLayerTypes.FEATURE,
      url: `${CONFIG.arcgisUrl}/17`,
      styles: {
        opacity: 0.8,
        weight: '2',
      },
      onEachFeature: (feature: ICarFreeZoneFeature, layer: any) => {
        refreshFeature({ feature, layer });
        // Only create the reference here, all logic will be done in the other useEffect
        refFeatureLayers.current.push({ feature, layer });
      },
    };

    setOverlayLayers(
      overlayLayers.map((layer) => (layer.name === 'carFreeZones.layer' ? updatedCarFreeZoneLayer : layer)),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bounds]);

  useEffect(() => {
    // Rerender could have been accomplished by executing the useEffect above with every change of selectedGisIds
    // But that results in slow rerender with blinks. Using a ref makes sure everything is really smooth
    refFeatureLayers.current.forEach(refreshFeature);
  }, [selectedGisIds, refFeatureLayers, onSelectGisId, click, refreshFeature]);

  return null;
};
