import { createReducer } from '@reduxjs/toolkit';
import { payloadReducer } from '../../../../common';
import { IRequestsHistory } from '../../../../types';
import { RequestActions } from '../../../actions';

type TState = IRequestsHistory[];

const initialState: TState = [];

export default createReducer<TState>(initialState, {
  [RequestActions.setHistory.type]: payloadReducer<TState>(initialState),
});
