import { makeStyles, Paper, TextField, Typography } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import classNames from 'classnames';
import * as React from 'react';
import { ChangeEvent, FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { COLORS, Panel } from '../../../common';
import AsignButton from '../../../common/components/buttons/asign-button';
import { Visible } from '../../../common/components/layout/Visible.component';
import { getIsAuthorized } from '../../../common/store/user/user.selectors';
import { translate } from '../../../common/translations/translate';
import { Auth } from '../../../components';
import { useOnClickOutside } from '../../../hooks';
import { IAclActions, IRequestAclActions } from '../../../types';

interface IProps {
  checkAclAction: IAclActions;
  notes?: string;
  onPatchInternalNotes: (backofficeNotes: string, shouldAppend: boolean) => void;
  visible?: boolean;
}

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  paper: {
    padding: '10px',
    marginBottom: '20px',
    backgroundColor: COLORS.TEAM_CARD_BG,
    cursor: 'pointer',
  },
  notes: {
    whiteSpace: 'pre-line',
    fontSize: 'inherit',
  },
  textField: {
    width: '100%',
    marginBottom: '10px',
  },
});

export const RequestInternalNotes: FunctionComponent<IProps> = ({
  checkAclAction,
  notes,
  onPatchInternalNotes,
  visible = true,
}) => {
  const C = useStyles();
  const [newMessage, setNewMessage] = useState<string>('');
  const [editMode, setEditMode] = useState(false);
  const [editedNotes, setEditedNotes] = useState<string>(notes || '');
  const editAssignedRequest = useSelector(getIsAuthorized([IRequestAclActions.editAssignedRequest]));
  const ref = useOnClickOutside(() => setEditMode(false));

  const onSaveMessage = useCallback(() => {
    if (editMode) {
      onPatchInternalNotes(editedNotes, false);
    } else if (newMessage.length) {
      onPatchInternalNotes(newMessage, true);
    }
    setNewMessage('');
    setEditMode(false);
  }, [editMode, editedNotes, newMessage, onPatchInternalNotes]);

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setNewMessage(event.target.value);
  }, []);

  useEffect(() => {
    setEditedNotes(notes || '');
  }, [notes]);

  return visible ? (
    <Panel title={translate('Requests.Detail.internalNotes.title')} ref={ref}>
      {notes &&
        (editMode ? (
          <TextField
            multiline
            value={editedNotes}
            onChange={(event) => setEditedNotes(event.target.value)}
            className={classNames(C.textField, C.notes)}
            variant="outlined"
          />
        ) : (
          <Paper
            onClick={() => editAssignedRequest && setEditMode(true)}
            data-testid="internalNotesPaper"
            className={C.paper}
          >
            <Typography className={C.notes}>{notes}</Typography>
          </Paper>
        ))}
      <Auth acl={[checkAclAction]}>
        <Visible visible={!editMode}>
          <TextField
            data-testid="inputField"
            multiline
            value={newMessage}
            className={C.textField}
            label={translate('Requests.Detail.internalNotes.description')}
            onChange={onChange}
          />
        </Visible>
        <div className={C.buttonContainer}>
          <AsignButton
            data-testid="saveMessageButton"
            color="secondary"
            onClick={onSaveMessage}
            startIcon={<Save fontSize="small" />}
            disabled={!newMessage && !editMode}
          >
            {editMode
              ? translate('Requests.Detail.internalNotes.saveNotes')
              : translate('Requests.Detail.internalNotes.saveNote')}
          </AsignButton>
        </div>
      </Auth>
    </Panel>
  ) : null;
};
