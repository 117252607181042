import React, { createContext, FC, FormEventHandler, PropsWithChildren } from 'react';
import { ISgwGeoDrawing } from '../types';
import { IPhaseGeoDrawings, usePhaseGeoDrawings } from '../hooks';
import { UseFormReturn } from 'react-hook-form';
import { FormProvider } from 'react-hook-form';
import { useGeoDrawingForm } from '../hooks/useGeoDrawingForm.hook';

interface IAddGeoDrawingFormReturn extends UseFormReturn<ISgwGeoDrawing> {
  submitGeometry: FormEventHandler<HTMLFormElement>;
}

interface IGeoDrawingContext {
  phaseGeoDrawings: IPhaseGeoDrawings;
  formMethods: IAddGeoDrawingFormReturn;
}

export const initialGeoDrawingContextValues: IGeoDrawingContext = {
  // @ts-ignore
  phaseGeoDrawings: {},
};

export const GeoDrawingContext = createContext<IGeoDrawingContext>(initialGeoDrawingContextValues);

export const GeoDrawingContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const phaseGeoDrawings = usePhaseGeoDrawings();
  const { saveGeoDrawing, geoDrawingToEdit, idToEdit } = phaseGeoDrawings;
  const formMethods = useGeoDrawingForm(saveGeoDrawing, idToEdit, geoDrawingToEdit || {});

  const value = {
    phaseGeoDrawings,
    formMethods,
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.submitGeometry} id="geoDrawingListItem">
        <GeoDrawingContext.Provider value={value}>{children}</GeoDrawingContext.Provider>
      </form>
    </FormProvider>
  );
};
