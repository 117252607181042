import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppActions } from '../common';

export const useOauthAccessToken = () => {
  const dispatch = useDispatch();
  const AccessTokenHashRegex = /access_token=([a-zA-Z0-9]+)&/;
  const hash = window.location.hash;
  const matches = hash.match(AccessTokenHashRegex);
  var accessToken = '';

  if (matches && matches.length > 0) {
    accessToken = matches[1];
  }

  useEffect(() => {
    if (accessToken) {
      dispatch(AppActions.fetchOAuthToken(accessToken));
    }
  }, [dispatch, accessToken]);

  return accessToken;
};
