import { Tooltip } from '@material-ui/core';
import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { MESSAGE_BLACK_ICON_URL, MESSAGE_GREEN_ICON_URL, MESSAGE_ORANGE_ICON_URL } from '../../../common/icons';
import { translate } from '../../../common/translations/translate';
import { MessageState } from '../../../types';

const useStyles = createUseStyles({
  img: {
    height: '20px',
  },
});

interface IProps {
  messageState?: MessageState;
}

export const FeedbackIcon = ({ messageState }: IProps) => {
  const C = useStyles();

  if (!messageState || !MessageState[messageState] || messageState === MessageState.not_in_feedback_loop) {
    return null;
  }

  let messageUrl;
  let title;
  switch (messageState) {
    case MessageState.awaiting_feedback:
      messageUrl = MESSAGE_GREEN_ICON_URL;
      title = translate('awaitingFeedback');
      break;
    case MessageState.feedback_received:
      messageUrl = MESSAGE_ORANGE_ICON_URL;
      title = translate('feedbackReceived');
      break;
    default:
      messageUrl = MESSAGE_BLACK_ICON_URL;
      title = translate('feedbackStatus');
      break;
  }

  return (
    <Tooltip title={title} aria-label={messageState}>
      <img className={C.img} src={messageUrl} alt={title} />
    </Tooltip>
  );
};

FeedbackIcon.Header = () => <FeedbackIcon messageState={MessageState.header} />;
