import { Paper } from '@material-ui/core';
import * as React from 'react';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initialSignsMapFilters } from '..';
import { BaseMap } from '../../common';
import { IRootState } from '../../root.state';
import { SignsMapClusterComponent } from '../components/signs-map-cluster.component';
import { SignsMapFilters } from '../components/signs-map-filters.component';
import { fetchSignsFilterData, fetchSignsMap } from '../store/signs.actions';
import { ISignMap, ISignsMapFilter } from '../types/signs-map';

export const SignsMapPage: FunctionComponent = () => {
  const [signsMapFilters, setSignsMapFilters] = useState<ISignsMapFilter>(initialSignsMapFilters);

  const dispatch = useDispatch();

  const signsMap: ISignMap | null = useSelector((store: IRootState) => store.signs.signsMap);
  const loading: boolean = useSelector((store: IRootState) => store.signs.signLoading);

  useEffect(() => {
    dispatch(fetchSignsFilterData());
    dispatch(fetchSignsMap(signsMapFilters));
  }, [dispatch, signsMapFilters]);

  return (
    <div className="container sign-map-container">
      <Paper style={{ padding: '15px' }}>
        <SignsMapFilters signsMapFilter={signsMapFilters} signsFilterChange={setSignsMapFilters} />
        <BaseMap loading={loading}>
          {signsMap && <SignsMapClusterComponent signsMap={signsMap.signs} signsMapLines={signsMap.lines} />}
        </BaseMap>
      </Paper>
    </div>
  );
};
