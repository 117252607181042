import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { FunctionComponent } from 'react';

const useStyles = makeStyles({
  root: {
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: '1.5em',
    padding: '0 15px',
    '& LI': {
      padding: 8,
    },
  },
});

export const RequesterTooltipComponent: FunctionComponent = () => {
  const C = useStyles();

  return (
    <div className={C.root}>
      <p>In het kader van dit dossier, moet je als aanvrager enkele zaken ondernemen:</p>
      <ol>
        <li>Plaatsing van de parkeerborden</li>
        <li>
          Na het plaatsen van de borden, kan je hier alle nummerplaten toevoegen van de voertuigen die op de door jouw
          gereserveerde zone staan geparkeerd.
        </li>
        <li>Ook wanneer er geen geparkeerde voertuigen aanwezig zijn, moet je dit aanduiden.</li>
        <li>Geef de datum en het tijdstip op wanneer de borden werden geplaatst op de locatie.</li>
      </ol>
    </div>
  );
};
