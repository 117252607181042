import { Actions } from '../..';
import { IWorkOrderItem, IWorkOrderItemPost, IWorkOrderItemType } from '../../../types';

class WorkOrderItemsActionsClass extends Actions {
  append = this.createPayloadAction<IWorkOrderItem>('append');
  delete = this.createPayloadAction<number>('delete');
  fetchTypes = this.createAction('fetchTypes');
  fetchCreatableTypes = this.createAction('fetchCreatableTypes');
  post = this.createPayloadAction<IWorkOrderItemPost>('post');
  setTypes = this.createPayloadAction<IWorkOrderItemType[]>('setTypes');
  setCreatableTypes = this.createPayloadAction<IWorkOrderItemType[]>('setCreatableTypes');
}

export const WorkOrderItemsActions = new WorkOrderItemsActionsClass('workOrderItem');
