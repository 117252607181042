import { createSelector } from '@reduxjs/toolkit';
import { createAllEntitiesSelector } from '../../common/utils/normalized.util';
import { IRootState } from '../../root.state';

export const selectSgwSettings = (store: IRootState) => store.sgw.settings;
export const selectAllIds = (store: IRootState) => store.sgw.settings.list.allIds;
export const selectById = (store: IRootState) => store.sgw.settings.list.byId;
export const selectTable = (store: IRootState) => store.sgw.settings.list.table;
export const selectListLoading = (store: IRootState) => store.sgw.settings.list.loading;

export const getSgwSettingsList = createAllEntitiesSelector(selectAllIds, selectById);

export const getSgwSetting = (id?: string) =>
  createSelector([selectById], (selectById) => (id ? selectById?.[id] : undefined));
