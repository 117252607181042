import { Box, Divider, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field, FieldProps } from 'formik';
import I18n from 'i18n-js';
import * as React from 'react';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormikDateField, FormikTextField } from '../../../common/components/formikFormFields';
import { PanelTitle } from '../../../common/components/panel/panel-title.component';
import { RequestsApi } from '../../../store/api';
import { getEditableFieldsIncludes } from '../../../store/selectors';
import { IRequestFormData, RequestCreateField, UserType } from '../../../types';
import { REQUEST_FORM_DEFAULT_VALUES } from './request-form.management';

const useStyles = makeStyles({
  root: { marginBottom: '2em' },
  userTypeContainer: { width: 400 },
  container: { width: 500 },
});

interface IProps {
  values: IRequestFormData;
  setFieldValue: (field: string, value: any) => void;
}

const TextFieldWrapper: FunctionComponent<{
  name: string;
  type?: string;
  validate?: any;
  required?: boolean;
  disabled?: boolean;
}> = ({ name, type, validate, required = true, disabled = false }) => {
  return (
    <FormikTextField
      name={name}
      label={I18n.t(`Requests.Create.Form.${name}`)}
      type={type}
      validate={validate}
      required={required}
      disabled={disabled}
    />
  );
};

export const RequestFormUserInfoComponent: FunctionComponent<IProps> = ({ values, setFieldValue }) => {
  const C = useStyles();
  const [checkedIban, setCheckedIban] = useState<{ iban: string; response: string | null }>({
    iban: '',
    response: null,
  });

  useEffect(() => {
    if (values.userType === UserType.company) {
      setFieldValue(RequestCreateField.ssn, REQUEST_FORM_DEFAULT_VALUES.ssn);
    } else if (values.userType === UserType.civilian) {
      setFieldValue(RequestCreateField.companyId, REQUEST_FORM_DEFAULT_VALUES.companyId);
      setFieldValue(RequestCreateField.companyName, REQUEST_FORM_DEFAULT_VALUES.companyName);
    }
  }, [setFieldValue, values.userType]);

  const ssnDisabled = !useSelector(getEditableFieldsIncludes(RequestCreateField.ssn));
  const companyIdDisabled = !useSelector(getEditableFieldsIncludes(RequestCreateField.companyId));
  const companyNameDisabled = !useSelector(getEditableFieldsIncludes(RequestCreateField.companyName));
  const userTypeDisabled = !useSelector(getEditableFieldsIncludes(RequestCreateField.userType));

  const validateIban = useCallback(
    async (value: string) => {
      if (!value) {
        // No need to validate empty string
        return '';
      }
      if (value.toUpperCase() !== value) {
        setFieldValue(RequestCreateField.ibanNumber, value.toUpperCase());
      }
      // This logic needs to prevent server calls on each form change
      if (checkedIban.iban !== value) {
        const checkIban = await RequestsApi.checkIban(value);
        const response = !checkIban.data.data.valid ? ' ' : null;
        setCheckedIban({ iban: value, response });
        return response;
      } else {
        return checkedIban.response;
      }
    },
    [checkedIban, setFieldValue],
  );

  return (
    <Box className={C.root}>
      <PanelTitle>{I18n.t('Requests.Create.UserType')}</PanelTitle>
      <Field name="userType" disabled={userTypeDisabled}>
        {({ field }: FieldProps) => {
          return (
            <RadioGroup {...field} name="userType">
              <Grid container className={C.userTypeContainer}>
                <Grid item xs={6}>
                  <FormControlLabel
                    value={UserType.civilian}
                    control={<Radio />}
                    label={I18n.t('Requests.Create.Civilian')}
                    disabled={userTypeDisabled}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    value={UserType.company}
                    control={<Radio />}
                    label={I18n.t('Requests.Create.Company')}
                    disabled={userTypeDisabled}
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          );
        }}
      </Field>

      <Divider />

      <PanelTitle>{I18n.t('Requests.Create.PersonalInfo')}</PanelTitle>
      <Grid container className={C.container} spacing={4} justify={'center'}>
        <Grid item xs={6}>
          <TextFieldWrapper
            name={RequestCreateField.firstName}
            disabled={!useSelector(getEditableFieldsIncludes(RequestCreateField.firstName))}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldWrapper
            name={RequestCreateField.lastName}
            disabled={!useSelector(getEditableFieldsIncludes(RequestCreateField.lastName))}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldWrapper
            name={RequestCreateField.emailAddress}
            disabled={!useSelector(getEditableFieldsIncludes(RequestCreateField.emailAddress))}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldWrapper
            name={RequestCreateField.phoneNumber}
            disabled={!useSelector(getEditableFieldsIncludes(RequestCreateField.phoneNumber))}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldWrapper
            name={RequestCreateField.ibanNumber}
            validate={validateIban}
            required={false}
            disabled={!useSelector(getEditableFieldsIncludes(RequestCreateField.ibanNumber))}
          />
        </Grid>
        <Grid item xs={6}>
          {values['userType'] === UserType.civilian && (
            <>
              {values['country'] === 'BE' ? (
                <TextFieldWrapper name={RequestCreateField.ssn} required={false} disabled={ssnDisabled} />
              ) : (
                <FormikDateField
                  name={RequestCreateField.birthday}
                  label={I18n.t(`Requests.Create.Form.birthday`)}
                  required
                  disabled={ssnDisabled}
                />
              )}
            </>
          )}
        </Grid>
        {values['userType'] === UserType.company && (
          <>
            <Grid item xs={6}>
              <TextFieldWrapper name={RequestCreateField.companyId} disabled={companyIdDisabled} />
            </Grid>
            <Grid item xs={6}>
              <TextFieldWrapper name={RequestCreateField.companyName} disabled={companyNameDisabled} />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};
