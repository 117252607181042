import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ISortingDirection } from '../../../../common';
import { IRequestDetailComponent } from '../../../../types';
import { PermitHistoryComponent } from './permit-history.component';
import { RequestActions } from '../../../../store/actions';

export const RequestPermitHistory: FunctionComponent<IRequestDetailComponent> = ({ requestId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      RequestActions.fetchPermitHistory({
        all: true,
        id: requestId,
        sorting: { key: 'timestamp', direction: ISortingDirection.asc },
      }),
    );
  }, [dispatch, requestId]);

  return <PermitHistoryComponent />;
};
