import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { genericErrorHandler, TPayloadAction } from '../../common';
import { getPagingFromHeaders } from '../../common/utils/api.util';
import { ICall, ISelect, ISgwAdvisingPartiesAclActions, ISgwAdvisingParty } from '../../types';
import { SgwAdvisingPartiesActions, SnackBarActions } from '../actions';
import { SgwAdvisingPartiesApi } from '../api/sgwAdvisingParties.api';
import { getAdvisingParty, getPagedApiParams } from '../selectors/sgwAdvisingParties.selectors';
import { translate } from '../../common/translations/translate';
import { push } from 'connected-react-router';
import { appUrls } from '../../common/config/url.constants';
import { mapToAdvisingPartyBE } from '../../utils/advisingParties.util';
import { getIsAuthorized } from '../../common/store/user/user.selectors';

function* onFetchList() {
  const isAuthorized: ISelect<typeof getIsAuthorized> = yield select(
    getIsAuthorized([
      ISgwAdvisingPartiesAclActions.viewAdvisingPartiesOverview,
      ISgwAdvisingPartiesAclActions.viewSgwAdvisingPartyRequestOverview,
    ]),
  );
  if (!isAuthorized) {
    return;
  }
  const params: ISelect<typeof getPagedApiParams> = yield select(getPagedApiParams);
  const response: ICall<typeof SgwAdvisingPartiesApi.fetchList> = yield call(SgwAdvisingPartiesApi.fetchList, params!);
  yield all([
    put(
      SgwAdvisingPartiesActions.list.setParams({
        paging: getPagingFromHeaders(response as any),
      }),
    ),
    put(SgwAdvisingPartiesActions.list.set(response!.data.data)),
  ]);
}

function* onSave({ payload }: TPayloadAction<ISgwAdvisingParty>): SagaIterator {
  const currentAdvisingParty: ISgwAdvisingParty = yield select(getAdvisingParty(`${payload.id}`));

  const response: ICall<typeof SgwAdvisingPartiesApi.save> = yield call(
    SgwAdvisingPartiesApi.save,
    mapToAdvisingPartyBE({
      ...currentAdvisingParty,
      ...payload,
    }),
  );

  yield put(SgwAdvisingPartiesActions.set(response!.data.data));
  yield put(SgwAdvisingPartiesActions.setLoading(false));
  yield put(SnackBarActions.setSuccess(translate('sgw.advisingParties.saved')));
  yield put(push(appUrls.sgw.manage.advisingParties.detail(`${response!.data.data.id}`)));
}

function* onFetch({ payload }: TPayloadAction<string>) {
  const response: ICall<typeof SgwAdvisingPartiesApi.fetch> = yield call(SgwAdvisingPartiesApi.fetch, payload);
  yield put(SgwAdvisingPartiesActions.set(response!.data.data));
  yield put(SgwAdvisingPartiesActions.setLoading(false));
}

export function* sgwAdvisingPartiesSaga(): SagaIterator {
  yield takeLatest(SgwAdvisingPartiesActions.list.fetch.type, genericErrorHandler(onFetchList));
  yield takeLatest(SgwAdvisingPartiesActions.save.type, genericErrorHandler(onSave));
  yield takeLatest(SgwAdvisingPartiesActions.fetch.type, genericErrorHandler(onFetch));
}
