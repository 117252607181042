import { push } from 'connected-react-router';
import moment from 'moment';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { genericErrorHandler, initialRequestsState, ISortingDirection, TPayloadAction } from '../../common';
import { appUrls } from '../../common/config/url.constants';
import { getUsername } from '../../common/store/user/user.selectors';
import { geometryToRequestGeometryType, multiPolygonToPolygon } from '../../common/utils/geojson.util';
import {
  DateFormat,
  HttpStatusCodes,
  ICall,
  IFileMetadata,
  IRequestFormData,
  IRequestLocationForm,
  IRequestMessage,
  IRequestPatchUserResponsible,
  IReselect,
  ISelect,
  IUploadedFile,
  RequestStates,
  UserType,
} from '../../types';
import {
  deleteMessageAttachment,
  fetchRequestMessages,
  fetchRequestReasonFilters,
  fetchRequestReasons,
  RequestActions,
  resolveRequestMessages,
  saveRequestMessage,
  setRequestMessageAttachments,
  setRequestMessagesList,
  setRequestReasonFilters,
  setRequestReasons,
  SnackBarActions,
  StorageActions,
  updateMessageAttachment,
  uploadMessageAttachment,
} from '../actions';
import { RequestsApi, StorageApi } from '../api';
import {
  getEditedRequest,
  getIsOnlyRoleUtilities,
  getLocalUploadFiles,
  getRequestApiParams,
  getRequestAttachmentsForPatch,
  getRequestNotes,
  getRequestsDetails,
  selectRequestMessageAttachments,
  selectUser,
} from '../selectors';
import I18n from 'i18n-js';
import { getPagingFromHeaders } from '../../common/utils/api.util';
import { mapToRequestMessageBE } from '../../utils';
import { takeUploadResult } from './storage.saga';
import { translate } from '../../common/translations/translate';

function* onFetchRequest(action: TPayloadAction<number>): SagaIterator {
  yield put(RequestActions.setLoading(true));
  const response: ICall<typeof RequestsApi.getRequest> = yield call(RequestsApi.getRequest, action.payload);
  yield all([put(RequestActions.set(response!.data.data)), put(RequestActions.setLoading(false))]);
}

function* onSaveRequest({ payload }: ReturnType<typeof RequestActions.save>): SagaIterator {
  try {
    yield put(RequestActions.setLoading(true));

    const allFormData: IRequestFormData | undefined = payload.id
      ? yield select(getEditedRequest(payload.id, payload.formData))
      : payload.formData;
    const attachments: IReselect<typeof getLocalUploadFiles> = yield select(
      getLocalUploadFiles(payload.attachmentIds),
    ) || [];

    const data = {
      ...allFormData,
      // @ts-ignore
      attachments: attachments!.concat(allFormData!.attachments || []),
      ssn: allFormData!.userType === UserType.company ? undefined : allFormData!.ssn,
    };

    const response: ICall<typeof RequestsApi.putRequest> | ICall<typeof RequestsApi.postRequest> = payload.id
      ? // @ts-ignore
        yield call(RequestsApi.putRequest, payload.id, data)
      : // @ts-ignore
        yield call(RequestsApi.postRequest, data);

    yield put(RequestActions.set(response!.data.data));
    yield put(push(appUrls.requests.editLocations(`${response!.data.data.id}`)));
  } catch (e: any) {
    const { msgs = [] } = e?.response?.data || {};
    if (
      msgs.some(
        (msg: any) =>
          msg.type === 'E' && msg.message.includes('Access for a license plate cannot be after the end of the request'),
      )
    ) {
      yield put(SnackBarActions.setFailure(translate('Requests.Detail.LicensePlates.AccessToLicensePlateRestricted')));
    } else if (msgs.some((msg: any) => msg.type === 'E' && msg.message.includes('companyId: Ongeldig BTW nummer'))) {
      yield put(SnackBarActions.setFailure(translate('noValidVat.general')));
    } else {
      throw e;
    }
  } finally {
    yield put(RequestActions.setLoading(false));
  }
}

function* onPatchPriority({ payload }: ReturnType<typeof RequestActions.patchPriority>): SagaIterator {
  const response: ICall<typeof RequestsApi.patchRequestPriority> = yield call(
    RequestsApi.patchRequestPriority,
    payload,
  );
  yield put(RequestActions.set(response!.data.data));
}

function* onPatchCluster({ payload }: ReturnType<typeof RequestActions.patchCluster>): SagaIterator {
  const response: ICall<typeof RequestsApi.patchRequestCluster> = yield call(RequestsApi.patchRequestCluster, payload);
  yield put(RequestActions.set(response!.data.data));
}

function* onPatchPublicDomainIntakes({
  payload: { id, publicDomainIntakes },
}: ReturnType<typeof RequestActions.patchPublicDomainIntakes>): SagaIterator {
  const transformed = publicDomainIntakes.map(
    ({
      carFreeZone,
      carFreeZoneGateEntrance,
      carFreeZoneGateExit,
      city,
      description,
      geometry,
      gisId,
      id,
      isNew,
      street,
      streetNumberFrom,
      streetNumberTo,
      streetNumberUnknown,
      type,
      zipCode,
    }) => {
      const pdi: Partial<IRequestLocationForm> = {
        carFreeZone,
        carFreeZoneGateEntrance,
        carFreeZoneGateExit,
        city,
        description,
        gisId,
        street,
        type,
        zipCode,
      };
      if (!isNew) pdi.id = id;
      if (streetNumberUnknown) {
        pdi.streetNumberUnknown = true;
        pdi.streetNumberFrom = '0';
        pdi.streetNumberTo = '0';
      } else {
        pdi.streetNumberFrom = streetNumberFrom;
        pdi.streetNumberTo = streetNumberTo;
      }
      if (geometry) {
        pdi.geometry = geometry.type === 'MultiPolygon' ? multiPolygonToPolygon(geometry) : geometry;
        pdi.geometryType = geometryToRequestGeometryType(geometry);
      }
      return pdi as IRequestLocationForm;
    },
  );

  yield put(RequestActions.setSaving(true));
  const response: ICall<typeof RequestsApi.patchRequestPublicDomainIntakes> = yield call(
    RequestsApi.patchRequestPublicDomainIntakes,
    { id, publicDomainIntakes: transformed },
  );
  yield put(RequestActions.set(response!.data.data));
  yield put(RequestActions.fetchConflicts(id));
  yield put(RequestActions.setSaving(false));
}

function* onRequestAttemptAssign({ payload: id }: ReturnType<typeof RequestActions.assign>): SagaIterator {
  const response: ICall<typeof RequestsApi.getRequest> = yield call(RequestsApi.getRequest, parseInt(id, 10));
  yield put(RequestActions.set(response!.data.data));
  const assignedUser = response!.data.data.userResponsible;

  if (assignedUser) {
    yield put(RequestActions.setAlreadyAssigned(true));
  } else {
    yield put(RequestActions.assign(id));
  }
}

function* onRequestAssign({ payload: id }: ReturnType<typeof RequestActions.assign>): SagaIterator {
  const user: ISelect<typeof selectUser> = yield select(selectUser);

  const details: IReselect<typeof getRequestsDetails> = yield select(getRequestsDetails(id));

  const params: IRequestPatchUserResponsible = { id, userResponsible: `${user!.id}` };
  if (
    details!.state.state === RequestStates.submitted &&
    details!.state.transitions.includes(RequestStates.reviewing)
  ) {
    params.state = RequestStates.reviewing;
  }
  const response: ICall<typeof RequestsApi.patchRequestUserResponsible> = yield call(
    RequestsApi.patchRequestUserResponsible,
    params,
  );
  yield put(RequestActions.set(response!.data.data));
}

function* onRequestUnassign({ payload: id }: ReturnType<typeof RequestActions.unassign>): SagaIterator {
  const details: IReselect<typeof getRequestsDetails> = yield select(getRequestsDetails(id));
  const params: IRequestPatchUserResponsible = { id, userResponsible: null };
  if (
    details!.state.state === RequestStates.reviewing &&
    details!.state.transitions.includes(RequestStates.submitted)
  ) {
    params.state = RequestStates.submitted;
  }
  const response: ICall<typeof RequestsApi.patchRequestUserResponsible> = yield call(
    RequestsApi.patchRequestUserResponsible,
    params,
  );
  yield put(RequestActions.set(response!.data.data));
}

function* onRequestApprove({ payload }: ReturnType<typeof RequestActions.approve>): SagaIterator {
  const response: ICall<typeof RequestsApi.patchRequestApprove> = yield call(RequestsApi.patchRequestApprove, payload);
  yield put(RequestActions.set(response!.data.data));
}

function* onRequestReject({ payload }: ReturnType<typeof RequestActions.reject>): SagaIterator {
  const response: ICall<typeof RequestsApi.patchRequestReject> = yield call(RequestsApi.patchRequestReject, payload);
  yield put(RequestActions.set(response!.data.data));
}

function* onRequestReview({ payload }: ReturnType<typeof RequestActions.review>): SagaIterator {
  const response: ICall<typeof RequestsApi.patchRequestReview> = yield call(RequestsApi.patchRequestReview, payload);
  yield put(RequestActions.set(response!.data.data));
}

function* onRequestCancel({ payload }: ReturnType<typeof RequestActions.cancel>): SagaIterator {
  const response: ICall<typeof RequestsApi.patchRequestCancellation> = yield call(
    RequestsApi.patchRequestCancellation,
    payload,
  );
  yield put(RequestActions.set(response!.data.data));
}

function* onRequestDelete({ payload }: ReturnType<typeof RequestActions.remove>): SagaIterator {
  yield call(RequestsApi.deleteRequest, payload);
}

function* onFetchRequestList() {
  const params: ISelect<typeof getRequestApiParams> = yield select(getRequestApiParams);
  const response: ICall<typeof RequestsApi.getRequestList> = yield call(RequestsApi.getRequestList, params!);
  yield all([
    put(
      RequestActions.list.setParams({
        paging: getPagingFromHeaders(response as any),
      }),
    ),
    put(RequestActions.list.set(response!.data.data)),
  ]);
}

function* onExportRequest() {
  const params: ISelect<typeof getRequestApiParams> = yield select(getRequestApiParams);
  yield call(RequestsApi.exportRequests, params!);
  yield put(RequestActions.setExporting(true));
}

function* onFetchRequestMetadataList() {
  const response: ICall<typeof RequestsApi.getMetadataList> = yield call(RequestsApi.getMetadataList);
  yield put(RequestActions.setMetadata(response!.data.data));
}

function* onFetchRequestReasons() {
  const response: ICall<typeof RequestsApi.getRequestReasons> = yield call(RequestsApi.getRequestReasons);
  yield put(setRequestReasons(response!.data.data));
}

function* onFetchRequestReasonFilters() {
  const response: ICall<typeof RequestsApi.getRequestReasonFilters> = yield call(RequestsApi.getRequestReasonFilters);
  yield put(setRequestReasonFilters(response!.data.data));
}

function* onFetchRequestMessages(action: TPayloadAction<string>): SagaIterator {
  const isOnlyRoleUtilities = yield select(getIsOnlyRoleUtilities);
  if (isOnlyRoleUtilities) return;

  try {
    yield put(RequestActions.setLoading(true));
    const response: ICall<typeof RequestsApi.getRequestMessages> = yield call(
      RequestsApi.getRequestMessages,
      action.payload,
    );
    yield put(setRequestMessagesList(response!.data.data));
  } finally {
    yield put(RequestActions.setLoading(false));
  }
}

function* onSaveRequestMessage({ payload }: TPayloadAction<IRequestMessage>): SagaIterator {
  try {
    yield put(RequestActions.setLoading(true));
    const response: ICall<typeof RequestsApi.saveRequestMessage> = yield call(
      RequestsApi.saveRequestMessage,
      payload.requestId!,
      mapToRequestMessageBE(payload),
    );
    yield put(setRequestMessagesList(response!.data.data));
  } finally {
    yield put(setRequestMessageAttachments(initialRequestsState.requestMessageAttachments));
    yield put(RequestActions.setLoading(false));
  }
}

function* onResolveRequestMessage(action: TPayloadAction<string>): SagaIterator {
  try {
    yield put(RequestActions.setLoading(true));
    const response: ICall<typeof RequestsApi.resolveRequestMessages> = yield call(
      RequestsApi.resolveRequestMessages,
      action.payload,
    );
    yield put(setRequestMessagesList(response!.data.data));
  } finally {
    yield put(RequestActions.setLoading(false));
  }
}

function* onUploadMessageAttachment(action: TPayloadAction<File>): SagaIterator {
  const key = Date.now() * 10000 + Math.floor(Math.random() * 10000);
  const newAttachment: IUploadedFile = { name: action.payload.name, key, loading: true };
  yield put(updateMessageAttachment(newAttachment));
  const formData = new FormData();
  formData.append('file', action.payload, action.payload.name);
  try {
    const response: ICall<typeof StorageApi.uploadFile> = yield call(StorageApi.uploadFile, formData);
    newAttachment.id = response!.data.data[0].id;
    newAttachment.loading = false;
  } catch (e: any) {
    if (e.response!.data!.msgs!.length >= 0) {
      newAttachment.uploadError = e.response.data.msgs[0].message;
    }
  } finally {
    yield put(updateMessageAttachment(newAttachment));
  }
}

function* onDeleteMessageAttachment(action: TPayloadAction<IUploadedFile>): SagaIterator {
  const attachments: ISelect<typeof selectRequestMessageAttachments> = yield select(selectRequestMessageAttachments);
  if (attachments?.[action.payload.key]) {
    const newAttachments = { ...attachments! };
    delete newAttachments[action.payload.key];
    yield put(setRequestMessageAttachments(newAttachments));
  }
}

function* onUpdateMessageAttachment(action: TPayloadAction<IUploadedFile>): SagaIterator {
  const attachments: ISelect<typeof selectRequestMessageAttachments> = yield select(selectRequestMessageAttachments);
  yield put(setRequestMessageAttachments({ ...attachments!, [action.payload.key]: action.payload }));
}

function* onFetchConflicts({ payload }: ReturnType<typeof RequestActions.fetchConflicts>): SagaIterator {
  const response: ICall<typeof RequestsApi.getConflicts> = yield call(RequestsApi.getConflicts, payload);
  yield put(
    RequestActions.setConflicts({
      id: payload,
      conflicts: response!.data.data.map((conf: any) => ({
        ...conf.feature,
        properties: { ...conf.feature.properties, key: Math.floor(Math.random() * 100000) + 1 },
      })),
    }),
  );
}

function* onFetchDrafts({ payload }: ReturnType<typeof RequestActions.fetchDrafts>): SagaIterator {
  const response: ICall<typeof RequestsApi.getDrafts> = yield call(RequestsApi.getDrafts, payload);
  // @ts-ignore - Typings are incorrect
  yield put(RequestActions.setDrafts(response!.data.data));
}

function* onFetchExtensions({ payload }: ReturnType<typeof RequestActions.fetchExtensions>): SagaIterator {
  const response: ICall<typeof RequestsApi.getExtensions> = yield call(RequestsApi.getExtensions, payload);
  // @ts-ignore - Typings are incorrect
  yield put(RequestActions.setExtensions(response!.data.data));
}

function* onFetchEditableFields({ payload }: ReturnType<typeof RequestActions.fetchEditableFields>): SagaIterator {
  const response: ICall<typeof RequestsApi.getEditableFields> = yield call(RequestsApi.getEditableFields, payload);
  // @ts-ignore - Typings are incorrect
  yield put(RequestActions.setEditableFields(response!.data.data));
}

function* onFetchHistory({ payload }: ReturnType<typeof RequestActions.fetchHistory>): SagaIterator {
  const response: ICall<typeof RequestsApi.getRequestHistory> = yield call(RequestsApi.getRequestHistory, payload);
  // @ts-ignore - Typings are incorrect
  yield put(RequestActions.setHistory(response!.data.data));
}

function* onFetchPermitHistory({ payload }: ReturnType<typeof RequestActions.fetchPermitHistory>): SagaIterator {
  const response: ICall<typeof RequestsApi.getRequestPermitHistory> = yield call(
    RequestsApi.getRequestPermitHistory,
    payload,
  );
  yield put(RequestActions.setPermitHistory(response!.data.data));
}

function* onRequestSendApprovedMail({ payload }: ReturnType<typeof RequestActions.sendApprovedMail>): SagaIterator {
  yield call(RequestsApi.postSendApprovedMail, `${payload}`);
}

function* onRequestSendRetributionMail({
  payload,
}: ReturnType<typeof RequestActions.sendRetributionMail>): SagaIterator {
  const response: ICall<typeof RequestsApi.postSendRetributionMail> = yield call(
    RequestsApi.postSendRetributionMail,
    `${payload}`,
  );
  yield put(RequestActions.set(response!.data.data));
}

function* onRegeneratePermit({ payload }: ReturnType<typeof RequestActions.regeneratePermit>): SagaIterator {
  const response: ICall<typeof RequestsApi.postPermitRegenerate> = yield call(
    RequestsApi.postPermitRegenerate,
    `${payload}`,
  );
  if (response!.status === HttpStatusCodes.NO_CONTENT) {
    yield put(RequestActions.fetch(payload));
  }
}

function* onPatchInternalNotes({ payload }: ReturnType<typeof RequestActions.patchInternalNotes>): SagaIterator {
  const userName: ISelect<typeof getUsername> = yield select(getUsername());
  const notes: IReselect<typeof getRequestNotes> = yield select(getRequestNotes(payload.id));
  const delimiter = notes!.length ? '\n\n' : '';

  const backofficeNotes = payload.shouldAppend
    ? `${notes}${delimiter}${userName} (${moment().format(DateFormat.dateTime)}):\n${payload.backofficeNotes}`
    : payload.backofficeNotes;

  const response: ICall<typeof RequestsApi.patchRequestInternalNotes> = yield call(
    RequestsApi.patchRequestInternalNotes,
    { backofficeNotes, id: payload.id },
  );
  yield put(RequestActions.set(response!.data.data));
}

function* onSetRequest({ payload }: ReturnType<typeof RequestActions.set>): SagaIterator {
  if (payload && payload.id) {
    yield put(fetchRequestMessages(`${payload.id}`));
    yield put(
      RequestActions.fetchHistory({
        all: false,
        id: `${payload.id}`,
        sorting: { key: 'timestamp', direction: ISortingDirection.asc },
      }),
    );
    yield put(
      RequestActions.fetchPermitHistory({
        all: true,
        id: `${payload.id}`,
        sorting: { key: 'timestamp', direction: ISortingDirection.asc },
      }),
    );
  }
}

function* onPatchExtension({ payload }: ReturnType<typeof RequestActions.patchExtensions>): SagaIterator {
  const response: ICall<typeof RequestsApi.patchRequestExtension> = yield call(
    RequestsApi.patchRequestExtension,
    payload,
  );
  // @ts-ignore - Typings are incorrect
  yield put(RequestActions.setExtension(response!.data.data));
  if (response!.status === HttpStatusCodes.OK) {
    yield put(RequestActions.fetch(payload.id));
  }
}

function* onUploadFile({ payload }: ReturnType<typeof RequestActions.uploadFile>): SagaIterator {
  yield put(StorageActions.upload(payload.file));
  const resultAction = yield take(takeUploadResult(payload.file.metadata.id));
  // @ts-ignore - See how this can be fixed
  if (resultAction.type === StorageActions.uploadSuccess.type) {
    const attachments: IReselect<typeof getRequestAttachmentsForPatch> = yield select(
      getRequestAttachmentsForPatch(payload.id),
    );
    // @ts-ignore - See how this can be fixed
    const metadata: IFileMetadata = resultAction.payload;
    attachments!.push({ file: metadata.uploadId!, name: metadata.name });
    // @ts-ignore - See how this can be fixed
    const response: ICall<typeof RequestsApi.patchRequestAttachment> = yield call(RequestsApi.patchRequestAttachment, {
      id: payload.id,
      attachments,
    });
    yield put(RequestActions.set(response!.data.data));
  }
}

function* onRemoveFile({ payload }: ReturnType<typeof RequestActions.removeFile>): SagaIterator {
  const _attachments: IReselect<typeof getRequestAttachmentsForPatch> = yield select(
    getRequestAttachmentsForPatch(payload.id),
  );
  const attachments = _attachments!.filter(({ file }) => file !== payload.fileName);
  const response: ICall<typeof RequestsApi.patchRequestAttachment> = yield call(RequestsApi.patchRequestAttachment, {
    id: payload.id,
    attachments,
  });
  yield put(RequestActions.set(response!.data.data));
}

function* onSubmit({ payload }: ReturnType<typeof RequestActions.submit>): SagaIterator {
  const response: ICall<typeof RequestsApi.patchRequestWaitingForPayment> = yield call(
    RequestsApi.patchRequestWaitingForPayment,
    payload,
  );
  if (response!.status === HttpStatusCodes.OK) {
    yield put(RequestActions.set(response!.data.data));
    yield put(push(appUrls.requests.detail(payload)));
  }
}

function* onResetPincode({ payload }: ReturnType<typeof RequestActions.resetPincode>): SagaIterator {
  try {
    const response: ICall<typeof RequestsApi.resetPincode> = yield call(RequestsApi.resetPincode, payload);
    if (response?.data?.data) {
      yield put(RequestActions.set(response.data.data));
    }
    yield put(SnackBarActions.setSuccess(I18n.t('Requests.Header.ResetPincode.FeedbackSuccess')));
  } catch (e) {
    yield put(SnackBarActions.setFailure(I18n.t('Requests.Header.ResetPincode.FeedbackFailure')));
  }
}

export function* requestsSaga(): SagaIterator {
  yield takeLatest(RequestActions.approve.type, genericErrorHandler(onRequestApprove));
  yield takeLatest(RequestActions.assign.type, genericErrorHandler(onRequestAssign));
  yield takeLatest(RequestActions.attemptAssign.type, genericErrorHandler(onRequestAttemptAssign));
  yield takeLatest(RequestActions.cancel.type, genericErrorHandler(onRequestCancel));
  yield takeLatest(RequestActions.remove.type, genericErrorHandler(onRequestDelete));
  yield takeLatest(RequestActions.export.type, genericErrorHandler(onExportRequest));
  yield takeLatest(RequestActions.fetch.type, genericErrorHandler(onFetchRequest));
  yield takeLatest(RequestActions.save.type, genericErrorHandler(onSaveRequest));
  yield takeLatest(RequestActions.fetchDrafts.type, genericErrorHandler(onFetchDrafts));
  yield takeLatest(RequestActions.fetchConflicts.type, genericErrorHandler(onFetchConflicts));
  yield takeLatest(RequestActions.fetchEditableFields.type, genericErrorHandler(onFetchEditableFields));
  yield takeLatest(RequestActions.fetchExtensions.type, genericErrorHandler(onFetchExtensions));
  yield takeLatest(RequestActions.fetchHistory.type, genericErrorHandler(onFetchHistory));
  yield takeLatest(RequestActions.fetchPermitHistory.type, genericErrorHandler(onFetchPermitHistory));
  yield takeLatest(RequestActions.list.fetch.type, genericErrorHandler(onFetchRequestList));
  yield takeLatest(RequestActions.fetchMetadata.type, genericErrorHandler(onFetchRequestMetadataList));
  yield takeLatest(RequestActions.sendApprovedMail.type, genericErrorHandler(onRequestSendApprovedMail));
  yield takeLatest(RequestActions.sendRetributionMail.type, genericErrorHandler(onRequestSendRetributionMail));
  yield takeLatest(RequestActions.reject.type, genericErrorHandler(onRequestReject));
  yield takeLatest(RequestActions.unassign.type, genericErrorHandler(onRequestUnassign));
  yield takeLatest(fetchRequestReasons.type, genericErrorHandler(onFetchRequestReasons));
  yield takeLatest(fetchRequestReasonFilters.type, genericErrorHandler(onFetchRequestReasonFilters));
  yield takeLatest(fetchRequestMessages.type, genericErrorHandler(onFetchRequestMessages));
  yield takeLatest(saveRequestMessage.type, genericErrorHandler(onSaveRequestMessage));
  yield takeLatest(resolveRequestMessages.type, genericErrorHandler(onResolveRequestMessage));
  yield takeEvery(deleteMessageAttachment.type, genericErrorHandler(onDeleteMessageAttachment));
  yield takeEvery(updateMessageAttachment.type, genericErrorHandler(onUpdateMessageAttachment));
  yield takeEvery(uploadMessageAttachment.type, onUploadMessageAttachment);
  yield takeEvery(RequestActions.patchPriority.type, genericErrorHandler(onPatchPriority));
  yield takeEvery(RequestActions.patchCluster.type, genericErrorHandler(onPatchCluster));
  yield takeEvery(RequestActions.patchPublicDomainIntakes.type, genericErrorHandler(onPatchPublicDomainIntakes));
  yield takeLatest(RequestActions.regeneratePermit.type, genericErrorHandler(onRegeneratePermit));
  yield takeLatest(RequestActions.review.type, genericErrorHandler(onRequestReview));
  yield takeEvery(RequestActions.patchInternalNotes.type, genericErrorHandler(onPatchInternalNotes));
  yield takeLatest(RequestActions.set.type, genericErrorHandler(onSetRequest));
  yield takeLatest(RequestActions.patchExtensions.type, genericErrorHandler(onPatchExtension));
  yield takeEvery(RequestActions.removeFile.type, genericErrorHandler(onRemoveFile));
  yield takeEvery(RequestActions.uploadFile.type, genericErrorHandler(onUploadFile));
  yield takeLatest(RequestActions.submit.type, genericErrorHandler(onSubmit));
  yield takeLatest(RequestActions.resetPincode.type, genericErrorHandler(onResetPincode));
}
