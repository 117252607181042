import axios from 'axios';
import { CONFIG, IApiPromise, IPagedApiParams } from '../../common';
import { IEvent } from '../types/event';
import { IEventType } from '../types/event-type';

export class EventsApi {
  public static getEvent(id: string): IApiPromise<IEvent> {
    return axios.get(`${CONFIG.backOfficeApi}/v1/events/${id}`);
  }

  public static bulkProcessEvents(processedSigns: number[]): IApiPromise<{ errors?: string[] }> {
    return axios.post(`${CONFIG.backOfficeApi}/v1/events/bulkProcess`, {
      events: processedSigns,
    });
  }

  public static fetchList(params: IPagedApiParams): IApiPromise<IEvent[]> {
    return axios.get(`${CONFIG.backOfficeApi}/v1/events`, { params });
  }

  public static getEventTypes(): IApiPromise<IEventType[]> {
    return axios.get(`${CONFIG.backOfficeApi}/v1/events/types`);
  }
}
