export const docViewerSupportedMimeTypes = [
  'image/bmp',
  'image/jpg',
  'image/jpeg',
  'application/vnd.ms-outlook',
  'application/pdf',
  'image/png',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/plain',
];

export const isMimeTypeSupported = (mimeType: string) => docViewerSupportedMimeTypes.includes(mimeType);
