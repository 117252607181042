import * as I18n from 'i18n-js';
import * as React from 'react';
import { Panel, TileSelect } from '../../common';
import { SIGN_CONDITIONS, SIGN_FILTERS, SIGN_STATES } from '../signs.constants';
import { ISignCounters } from '../types/sign-counters';
import { TSignPredefinedFilter } from '../types/signs-filter';

interface IProps {
  signCounters: ISignCounters;
  filter?: TSignPredefinedFilter;
  filterChange: (value: TSignPredefinedFilter) => void;
}

export class SignsOverview extends React.Component<IProps> {
  public render(): React.ReactNode {
    const { signCounters } = this.props;
    return (
      <Panel title={I18n.t('OverviewSigns')}>
        <TileSelect
          id="overviewFilters"
          value={this.props.filter}
          valueChange={this.props.filterChange}
          values={[
            {
              subtitle: I18n.t('Placed'),
              title: `${signCounters.placed}`,
              value: SIGN_STATES.PLACED,
            },
            {
              subtitle: I18n.t('Moved'),
              title: `${signCounters.moved}`,
              value: SIGN_STATES.MOVED,
            },
            {
              subtitle: I18n.t('Damaged'),
              title: `${signCounters.damaged}`,
              value: SIGN_CONDITIONS.DAMAGED,
            },
            {
              subtitle: I18n.t('NotExtended'),
              title: `${signCounters.notExtended}`,
              value: SIGN_FILTERS.NOT_EXTENDED,
            },
            {
              subtitle: I18n.t('NoGPS'),
              title: `${signCounters.noGPS}`,
              value: SIGN_FILTERS.NO_GPS,
            },
            {
              subtitle: I18n.t('Stored'),
              title: `${signCounters.stored}`,
              value: SIGN_STATES.STORED,
            },
            {
              subtitle: I18n.t('NoConnection'),
              title: `${signCounters.noConnection}`,
              value: SIGN_FILTERS.NO_CONNECTION,
            },
            {
              subtitle: I18n.t('Missing'),
              title: `${signCounters.missing}`,
              value: SIGN_STATES.MISSING,
            },
            {
              subtitle: I18n.t('maintenance'),
              title: `${signCounters.requireMaintenance}`,
              value: SIGN_FILTERS.REQUIRE_MAINTENANCE,
            },
            {
              subtitle: I18n.t('LowBattery'),
              title: `${signCounters.lowBattery}`,
              value: SIGN_CONDITIONS.LOW_BATTERY,
            },
          ]}
        />
      </Panel>
    );
  }
}
