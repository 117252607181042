import React, { FC } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Search } from '@material-ui/icons';
import { SgwPermitConditionCategory, ISgwPermitConditionsFilter } from '../../../types';
import { translate } from '../../../common/translations/translate';
import { ClearFiltersFab } from '../../../common/components/buttons/ClearFiltersFab';
import { CheckboxWithLabel } from '../../../common/components/form-fields/checkboxWithLabel.component';
import { MultiSelect } from '../../../common';
import { getFilterOptions } from '../../../utils';
import { Visible } from '../../../common/components/layout/Visible.component';

interface IProps {
  changeFilters(filter: ISgwPermitConditionsFilter): void;
  filters: ISgwPermitConditionsFilter;
  resetFilters(): void;
  showDeletedCheckbox: boolean;
}

const useStyles = makeStyles({
  root: {
    marginBottom: '20px',
  },
  resetButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '22px',
  },
  showDeleted: {
    maxWidth: '120px',
  },
});

export const PermitConditionsFilter: FC<IProps> = ({ filters, changeFilters, resetFilters, showDeletedCheckbox }) => {
  const C = useStyles();

  const _changeFilters = (newFilter: ISgwPermitConditionsFilter) => changeFilters({ ...filters, ...newFilter });

  const queryFilterChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    _changeFilters({ query: event.target.value });

  const referenceCodeFilterChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    _changeFilters({ referenceCode: event.target.value });

  const showDeletedFilterChange = (checked: boolean) => _changeFilters({ active: checked ? undefined : true });

  const categoryFilterChange = (category: SgwPermitConditionCategory[]) => _changeFilters({ category });

  return (
    <Grid container spacing={4} justify="space-between" className={C.root}>
      <Grid item xs={3}>
        <TextField
          value={filters.query || ''}
          onChange={queryFilterChange}
          fullWidth
          id="permitConditionQueryFilter"
          label={translate('sgw.permitConditions.filter.query')}
          InputProps={{
            endAdornment: <Search />,
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          value={filters.referenceCode || ''}
          onChange={referenceCodeFilterChange}
          fullWidth
          id="permitConditionReferenceCodeFilter"
          label={translate('sgw.permitConditions.filter.referenceCode')}
          InputProps={{
            endAdornment: <Search />,
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <MultiSelect
          data={getFilterOptions(SgwPermitConditionCategory, 'sgw.permitConditions.categories')}
          displayProp={'label'}
          id="categoryFilter"
          label={translate('sgw.permitConditions.filter.category')}
          value={filters.category || []}
          valueChange={categoryFilterChange}
          valueProp={'value'}
        />
      </Grid>
      <Grid item xs={3} className={C.resetButton}>
        <ClearFiltersFab onClick={resetFilters} />
      </Grid>
      <Visible visible={showDeletedCheckbox}>
        <Grid item xs={3}>
          <CheckboxWithLabel
            checked={!filters.active}
            label={translate('sgw.permitConditions.filter.showDeleted')}
            onChange={showDeletedFilterChange}
          />
        </Grid>
      </Visible>
    </Grid>
  );
};
