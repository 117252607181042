// In docker containers we generate a js file from the config that gets inserted into the index.html
// @ts-ignore
const ENV = window.ENV ? window.ENV : process.env;

export const ENVIRONMENT = ENV.REACT_APP_HOST_ENV;
export const IS_DEVELOPMENT = ENVIRONMENT === 'development';
export const IS_PRODUCTION = ENVIRONMENT === 'production';
export const VERSION = ENV.REACT_APP_VERSION;
export const BACKEND_URL = ENV.REACT_APP_BACKEND_URL;
export const FRONT_URL = ENV.REACT_APP_FRONT_URL;

export const CLIENT_ID = ENV.REACT_APP_AS_CLIENT_ID;
export const CLIENT_SECRET = ENV.REACT_APP_AS_CLIENT_SECRET;

export const MPROFILE_CLIENT_ID = ENV.REACT_APP_ANTWERPEN_OAUTH_MPROFILE_CLIENT_ID;
export const MPROFILE_URL = ENV.REACT_APP_ANTWERPEN_OAUTH_MPROFILE_URL;
export const SENTRY_DSN = ENV.REACT_APP_SENTRY_DSN;
