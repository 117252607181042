import React, { FC, useEffect, useState } from 'react';
import { DateFormat, IFile, IntakeTypes, IPublicDomainIntakeExtended } from '../../../types';
import { LatLngBounds } from 'leaflet';
import { Paper, TextField, Grid } from '@material-ui/core';
import { PanelContent } from '../../../common/components/panel/panel-content.component';
import { useGeneralStyles } from '../../../style/generalStyles';
import { InfoOutlined } from '@material-ui/icons';
import { RequesterTooltipComponent } from '../../../request/components/details/license-plates/requester-tooltip.component';
import { HtmlTooltip } from '../../../common/components/html-tooltip/html-tooltip.component';
import { translate } from '../../../common/translations/translate';
import { Feedback } from '../../../common/components/layout/Feedback.component';
import { PanelTitle } from '../../../common/components/panel/panel-title.component';
import { Visible } from '../../../common/components/layout/Visible.component';
import { LicensePlatesEditor } from '../../../request/components/details/license-plates/license-plates-editor.component';
import { useManualPlacementQuery, useScreenSize } from '../../../hooks';
import { FileBox } from '../../../common/components/attachment';
import { useDispatch, useSelector } from 'react-redux';
import { getStorageFiles } from '../../../store/selectors';
import { useManualPlacementForm } from '../../../hooks/forms/useManualPlacementForm.hook';
import { StorageActions } from '../../../store/actions';
import { CheckboxWithLabel } from '../../../common/components/form-fields/checkboxWithLabel.component';
import moment from 'moment';
import { convertDateFormat } from '../../../common/utils/date.util';
import { AsignDatepicker } from '../../../common/components/form-fields/datepicker/asign-datepicker.component';
import { createUseStyles } from 'react-jss';
import { SubmitButton } from '../../atoms';
import { MapLocationControles } from '../../../request/components/details/location/MapLocationControles.component';
import { GeometryComponent } from '../../../common/components/map/Geometry.component';
import { BaseMap } from '../../../common';
import classNames from 'classnames';

interface IProps {
  intake: IPublicDomainIntakeExtended;
}

const useStyles = createUseStyles({
  dateTime: {
    margin: '20px 0px 20px 0px',
    width: '150px',
  },
  time: {
    width: '100%',
    marginTop: '10px',
  },
  smallScreenButton: { marginBottom: 10 },
  bigScreenButton: { marginTop: -15 },
  info: { marginBottom: -4, marginLeft: 7 },
  smallScreenMap: { height: '40vh' },
  bigScreenMap: { height: '100%' },
});

export const ManualPlacementForm: FC<IProps> = ({ intake }) => {
  const G = useGeneralStyles();
  const C = useStyles();
  const { isMobile } = useScreenSize();
  const dispatch = useDispatch();
  const { description, street, streetNumberFrom, streetNumberTo, zipCode, city, type } = intake;
  const [bounds, setBounds] = useState<LatLngBounds>();
  const { request } = useManualPlacementQuery();
  const editable = !!request?.editable;
  const {
    formMethods: {
      register,
      setValue,
      watch,
      formState: { errors },
    },
    parkedPlates,
    onSubmit,
  } = useManualPlacementForm(intake);

  const uploadFile = (file: IFile) => {
    dispatch(StorageActions.upload(file));
  };

  useEffect(() => {
    resetPosition();
    // eslint-disable-next-line
  }, [intake]);

  const resetPosition = () => {
    setBounds(
      !!intake.bounds ? new LatLngBounds(intake.bounds.getSouthWest(), intake.bounds.getNorthEast()) : undefined,
    );
  };

  return (
    <form id={`manualPlacement-${intake.id}`} onSubmit={onSubmit} noValidate>
      <Grid container spacing={isMobile ? 1 : 3} className={G.marginBottom20}>
        <Grid item md={6} xs={12}>
          <div className={classNames(isMobile ? C.smallScreenMap : C.bigScreenMap)}>
            <BaseMap
              height="100%"
              boundsOptions={{ padding: [30, 30], maxZoom: 19 }}
              showLayersControl
              bounds={bounds}
              maxZoom={type.type === IntakeTypes.carfreezoneintake ? 17 : 19}
            >
              <MapLocationControles resetPosition={resetPosition} />
              <GeometryComponent
                key={intake.id}
                geometry={intake.geometry!}
                positions={intake.positions}
                showMarkers={intake.type.type !== IntakeTypes.carfreezoneintake}
              />
            </BaseMap>
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
          <Paper square>
            <PanelContent
              title={
                <div className={G.flexRowSpaceBetween}>
                  <div>
                    {street ? <p>{`${street} ${streetNumberFrom} - ${streetNumberTo}, ${zipCode} ${city}`}</p> : null}
                    <p>{type.name}</p>
                    <Visible visible={!!description}>{description}</Visible>
                  </div>
                </div>
              }
            >
              <TextField
                {...register('comment')}
                fullWidth
                label={translate('manualPlacement.comment')}
                multiline
                disabled={!request?.editable}
              />
              <PanelTitle>
                {translate('Requests.Detail.LicensePlates.Title')}
                <HtmlTooltip title={<RequesterTooltipComponent />}>
                  <InfoOutlined fontSize="small" className={C.info} />
                </HtmlTooltip>
              </PanelTitle>
              <Visible
                visible={editable || !!parkedPlates.length}
                defaultComponent={translate('Requests.Detail.LicensePlates.NoInfoWasSubmitted')}
              >
                <Feedback.Warning>
                  {translate('Requests.Detail.LicensePlates.WarningNoAnyLicensePlates')}{' '}
                </Feedback.Warning>
                <Feedback.Warning>
                  {translate('Requests.Detail.LicensePlates.CanBeEditedUntilPermitGoesInEffect')}
                </Feedback.Warning>
                <CheckboxWithLabel
                  {...register('noParkedVehicles')}
                  checked={watch('noParkedVehicles')}
                  label={translate('manualPlacement.noParkedVehicles')}
                  onChange={(value) => setValue('noParkedVehicles', value)}
                />
                <Visible visible={!watch('noParkedVehicles')}>
                  <LicensePlatesEditor
                    parkedPlates={parkedPlates}
                    onParkedPlatesChange={(parkedPlates) => setValue('parkedPlates', parkedPlates)}
                    showAddMode={editable}
                  />
                </Visible>
                <div className={C.dateTime}>
                  <AsignDatepicker
                    {...register('signsPlacedAtDate')}
                    value={moment(watch('signsPlacedAtDate'), DateFormat.reduxStoreDateString)}
                    label={translate('manualPlacement.date')}
                    onChange={(value) =>
                      setValue(
                        'signsPlacedAtDate',
                        convertDateFormat(value, DateFormat.reduxStoreDateTimeString, DateFormat.reduxStoreDateString),
                      )
                    }
                    maxDate={moment()}
                  />
                  <TextField
                    required
                    placeholder={'HH:mm'}
                    label={translate('manualPlacement.time')}
                    {...register('signsPlacedAtTime', { pattern: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, required: true })}
                    error={!!errors.signsPlacedAtTime}
                  />
                </div>
              </Visible>
              <PanelTitle>{translate('manualPlacement.attachments')}</PanelTitle>
              <FileBox
                {...register('attachments')}
                onFileSelect={editable ? uploadFile : undefined}
                placeholder={translate('Requests.Detail.Attachments.Placeholder')}
                storedFiles={intake.attachments?.filter(({ fileName }) => watch('attachments').includes(fileName))}
                uploadedFiles={useSelector(getStorageFiles)}
              />
            </PanelContent>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <SubmitButton
            visible={editable}
            formId={`manualPlacement-${intake.id}`}
            className={classNames(isMobile ? C.smallScreenButton : C.bigScreenButton)}
          >
            {translate('manualPlacement.save')}
          </SubmitButton>
        </Grid>
      </Grid>
    </form>
  );
};
