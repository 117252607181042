import { Grid, MenuItem, Select } from '@material-ui/core';
import AsignButton from '../buttons/asign-button';
import * as React from 'react';
import { PropsWithChildren, useState } from 'react';
import { translate } from '../../translations/translate';
import { Visible } from '../layout/Visible.component';

interface IProps<DataType> {
  selectedRecords: DataType[];
  total: number;
  bulkActions?: BulkAction<DataType>[];
}

export interface BulkAction<DataType> {
  action(records: DataType[]): void;
  label: string;
}

export const GenericTableBulkActions = <T,>({ bulkActions, selectedRecords, total }: PropsWithChildren<IProps<T>>) => {
  const [selectedAction, setSelectedAction] = useState<BulkAction<T>>();
  const EMPTY_SELECTION = '-';

  const changeSelectedAction = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newAction = bulkActions?.find(({ label }) => event.target.value === label);
    setSelectedAction(newAction);
  };

  return (
    <Grid container spacing={2} alignContent="flex-start" alignItems="center" className="list-actions-container">
      <Grid item>
        <b>{translate('bulkActions.action')}</b>
      </Grid>
      <Grid item>
        <Select value={selectedAction?.label || EMPTY_SELECTION} onChange={changeSelectedAction}>
          <MenuItem data-testid="SelectBulkAction" value={EMPTY_SELECTION} key={EMPTY_SELECTION}>
            {translate('bulkActions.selectAnAction')}
          </MenuItem>
          {bulkActions?.map((action) => (
            <MenuItem value={action.label} key={action.label} data-testid={`BulkActionItem-${action.label}`}>
              {action.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item>
        <AsignButton
          data-testid={`BulkActionConfirm-${selectedAction?.label}`}
          color="primary"
          onClick={() => {
            selectedAction?.action(selectedRecords);
            setSelectedAction(undefined);
          }}
          disabled={!selectedAction || selectedRecords.length <= 0}
        >
          {translate('bulkActions.confirm')}
        </AsignButton>
      </Grid>
      <Visible visible={!!selectedRecords}>
        <Grid item>
          <span>{translate('bulkActions.selectedCount', { selected: selectedRecords?.length, total })}</span>
        </Grid>
      </Visible>
    </Grid>
  );
};
