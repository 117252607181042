import { generateBooleanReducer } from '../../..';
import { IExemption, IExemptionsFilter } from '../../../../types';
import { CarFreeZonesActions } from '../carFreeZones.actions';
import { combineReducers } from 'redux';
import { createPagedTableReducer } from '../../../../store/reducers/pagedTable.reducer';
import { carFreeZonesExemptionsInitialParams } from '../../../../carFreeZone/CarFreeZone.constants';

export default combineReducers({
  isSyncing: generateBooleanReducer([CarFreeZonesActions.exemptions.isSyncing.type]),
  list: createPagedTableReducer<IExemption, IExemptionsFilter>({
    fetchAction: CarFreeZonesActions.exemptions.list.fetch.type,
    addEntitiesActions: [CarFreeZonesActions.exemptions.list.set.type],
    setAllIdsAction: CarFreeZonesActions.exemptions.list.set.type,
    setParamsActions: [
      CarFreeZonesActions.exemptions.list.fetch.type,
      CarFreeZonesActions.exemptions.list.setParams.type,
    ],
    initialParamsState: carFreeZonesExemptionsInitialParams,
  }),
});
