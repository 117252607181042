import React, { FC, useCallback, useEffect, useState } from 'react';
import { translate } from '../../../common/translations/translate';
import { useRequestAuthorization } from '../../../hooks';
import { CollapsablePanel } from '../../molecules';
import { useDispatch, useSelector } from 'react-redux';
import { SgwRequestActions, StorageActions } from '../../../store/actions';
import { selectAttachments, selectAttachmentsLoading } from '../../../store/selectors/sgwRequest.selectors';
import { COLORS, FormattedDateTime, ISortingDirection } from '../../../common';
import { GenericTable } from '../../../common/components/table/GenericTable.component';
import {
  BulkDownloadTypes,
  Category,
  IFile,
  IFileMetadata,
  ISgwPhase,
  ISgwRequestAttachment,
  ISgwRequestDetailAclActions,
  IStoredFile,
} from '../../../types';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { CloudDownload, CloudUpload } from '@material-ui/icons';
import { createUseStyles } from 'react-jss';
import { useGeneralStyles } from '../../../style/generalStyles';
import classNames from 'classnames';
import { LoaderBlur } from '../../../common/components/layout/LoaderBlur.component';
import { RequestAttachmentCategorySelector } from '../../molecules/RequestAttachmentCategorySelector/RequestAttachmentCategorySelector.component';
import { atomWithHash } from 'jotai/utils';
import { useAtom } from 'jotai';
import { FileSelect } from '../../../common/components/attachment';
import { Visible } from '../../../common/components/layout/Visible.component';
import { getStorageFiles } from '../../../store/selectors';
import { ATOM_HASHES } from '../../../common/config/atom.constants';
import { FilePreviewer } from '../../molecules/FilePreviewer/FilePreviewer.component';
import { RequestAttachmentPhaseSelector } from '../../molecules';
import { RequestAttachmentPhaseQuarterSelector } from '../../molecules';
import { retributionCategories } from '../../../common/config/sgwAttachments.constants';
import { Star, StarBorder } from '@material-ui/icons';

interface IProps {
  id: string;
}

const useStyles = createUseStyles({
  button: { margin: '10px 0 0 20px' },
  flexWithSpace: { display: 'flex', justifyContent: 'flex-end' },
  star: { color: COLORS.ORANGE, cursor: 'pointer' },
  table: { width: '100%' },
  marginRight: { marginRight: 10 },
});

const editModeAtom = atomWithHash(ATOM_HASHES.attachments.edit, false);
const starredAttachmentsAtom = atomWithHash(ATOM_HASHES.attachments.star, false);

export const RequestAttachments: FC<IProps> = ({ id }) => {
  const C = useStyles();
  const G = useGeneralStyles();
  const dispatch = useDispatch();
  const attachments = useSelector(selectAttachments);
  const attachmentsLoading = useSelector(selectAttachmentsLoading);
  const storageFiles = useSelector(getStorageFiles);
  const [selectedAttachments, setSelectedRecords] = useState<ISgwRequestAttachment[]>([]);
  const [uploadFiles, setUploadFiles] = useState<string[]>([]);
  const [editMode, setEditMode] = useAtom(editModeAtom);
  const [showOnlyStarredAttachments, setShowOnlyStarredAttachments] = useAtom(starredAttachmentsAtom);
  const { isAuthorized: editSgwAttachmentPanel } = useRequestAuthorization(
    ISgwRequestDetailAclActions.editSgwAttachmentPanel,
  );

  useEffect(() => {
    dispatch(SgwRequestActions.attachments.fetch(id));
  }, [dispatch, id]);

  const onFileUploaded = (file: IFileMetadata) => {
    const attachment = {} as ISgwRequestAttachment;
    attachment.fileStorage = { fileName: file.uploadId } as IStoredFile;
    attachment.sgwPhases = [];
    attachment.category = Category.other;
    dispatch(
      SgwRequestActions.attachments.save({
        requestId: id,
        attachment: attachment,
      }),
    );
  };

  if (attachmentsLoading) {
    storageFiles.forEach((file) => {
      if (!file.loading && uploadFiles.includes(file.id)) {
        setUploadFiles([]);
        onFileUploaded(file);
      }
    });
  }

  const onDownloadZip = useCallback(() => {
    dispatch(
      StorageActions.bulkDownload({
        type: BulkDownloadTypes.sgwRequest,
        id: id,
        fileIds: selectedAttachments.map(({ fileStorage }) => fileStorage.fileName),
      }),
    );
  }, [dispatch, id, selectedAttachments]);

  const onSelect = (record: ISgwRequestAttachment) => {
    setSelectedRecords((previousRecords) =>
      previousRecords.includes(record) ? previousRecords.filter((r) => record !== r) : previousRecords.concat([record]),
    );
  };

  const onFileSelect = (file: IFile) => {
    setUploadFiles(uploadFiles.concat(file.metadata.id));
    dispatch(StorageActions.upload(file));
    dispatch(SgwRequestActions.attachments.setLoading(true));
  };

  const onDelete = useCallback(
    (record: ISgwRequestAttachment) => {
      dispatch(SgwRequestActions.attachments.delete({ requestId: id, attachmentId: `${record.id}` }));
    },
    [dispatch, id],
  );

  const toggleStar = (isCurrentlyStarred: boolean, record: ISgwRequestAttachment) => () => {
    if (editSgwAttachmentPanel) {
      dispatch(
        SgwRequestActions.attachments.save({
          requestId: id,
          attachment: { id: record.id, starred: !isCurrentlyStarred },
        }),
      );
    }
  };

  return (
    <CollapsablePanel
      urlId={ATOM_HASHES.attachments.open}
      onEdit={editSgwAttachmentPanel ? () => setEditMode(!editMode) : undefined}
      title={translate('sgw.requests.detail.attachments.title')}
    >
      <div className={classNames(G.flexColumn, G.fullWidth)}>
        <LoaderBlur loading={attachmentsLoading}>
          <GenericTable
            columnKey="id"
            columns={[
              {
                label: translate('sgw.requests.detail.attachments.table.category'),
                name: 'category',
                renderer: (category, record?) => (
                  <RequestAttachmentCategorySelector
                    editMode={editMode}
                    category={category}
                    attachmentId={record.id}
                    requestId={id}
                  />
                ),
              },
              {
                label: translate('sgw.requests.detail.attachments.table.fileName'),
                name: 'fileStorage',
                renderer: (fileStorage) => <FilePreviewer fileStorage={fileStorage} editMode={editMode} />,
              },
              {
                label: translate('sgw.requests.detail.attachments.table.phase'),
                name: 'sgwPhases',
                renderer: (phases: ISgwPhase[], record) => (
                  <Visible
                    visible={retributionCategories.includes(record?.category!)}
                    defaultComponent={
                      <RequestAttachmentPhaseSelector
                        attachment={record}
                        editMode={editMode}
                        phases={phases}
                        requestId={id}
                      />
                    }
                  >
                    <RequestAttachmentPhaseQuarterSelector attachment={record} editMode={editMode} requestId={id} />
                  </Visible>
                ),
              },
              {
                label: translate('sgw.requests.detail.attachments.table.createdAt'),
                name: 'createdAt',
                renderer: (createdAt) => <FormattedDateTime dateTime={createdAt} />,
              },
              {
                label: showOnlyStarredAttachments ? (
                  <Star className={C.star} onClick={() => setShowOnlyStarredAttachments(false)} />
                ) : (
                  <StarBorder className={C.star} onClick={() => setShowOnlyStarredAttachments(true)} />
                ),
                name: 'starred',
                renderer: (starred, record) =>
                  starred ? (
                    <Star className={C.star} onClick={toggleStar(starred, record)} />
                  ) : (
                    <StarBorder className={C.star} onClick={toggleStar(starred, record)} />
                  ),
              },
            ]}
            labelUnavailable={translate('sgw.requests.detail.attachments.table.unavailable')}
            records={showOnlyStarredAttachments ? attachments.filter(({ starred }) => starred) : attachments}
            multiSelectable={!editMode}
            selectedRecords={selectedAttachments}
            onSelectRecord={onSelect}
            sorting={{ key: 'createdAt', direction: ISortingDirection.asc }}
            onDelete={editMode ? onDelete : undefined}
          />
          <div className={C.flexWithSpace}>
            <Visible
              visible={!editMode}
              defaultComponent={
                <FileSelect.BlueButton onFileSelect={onFileSelect} disabled={attachmentsLoading}>
                  <CloudUpload className={C.marginRight} />
                  <span>{translate('FileBox.uploadButton')}</span>
                </FileSelect.BlueButton>
              }
            >
              <Visible visible={editSgwAttachmentPanel}>
                <Button.Blue className={C.button} disabled={selectedAttachments.length === 0} onClick={onDownloadZip}>
                  <CloudDownload className={C.marginRight} />
                  <span>{translate('FileBox.downloadButton')}</span>
                </Button.Blue>
              </Visible>
            </Visible>
          </div>
        </LoaderBlur>
      </div>
    </CollapsablePanel>
  );
};
