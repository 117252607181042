import { useForm } from 'react-hook-form';
import { ISgwNatureOfWork, IRouterWithId } from '../../types';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SgwNatureOfWorksActions } from '../../store/actions';
import { getNatureOfWorks } from '../../store/selectors/sgwNatureOfWorks.selectors';
import { useParams } from 'react-router';

export const useSgwNatureOfWorkForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams<IRouterWithId>();
  const initialValues = useSelector(getNatureOfWorks(id));

  const formMethods = useForm<ISgwNatureOfWork>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: { ...initialValues },
  });

  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    reset(initialValues);
  }, [initialValues, reset]);

  const _onSubmit = useCallback(
    (values: ISgwNatureOfWork) => {
      dispatch(SgwNatureOfWorksActions.save(values));
    },
    [dispatch],
  );

  const submitNatureOfWork = handleSubmit(_onSubmit);

  return { formMethods, submitNatureOfWork, id };
};
