import { createSelector } from '@reduxjs/toolkit';
import { createPagedApiParamsSelector } from '../../common/store/utils/generic.selectors';
import { createAllEntitiesSelector } from '../../common/utils/normalized.util';
import { IRootState } from '../../root.state';
import { ISgwNatureOfWorksFilter } from '../../types';

export const selectSgwNatureOfWorks = (store: IRootState) => store.sgw.natureOfWorks;
export const selectAllIds = (store: IRootState) => store.sgw.natureOfWorks.list.allIds;
export const selectById = (store: IRootState) => store.sgw.natureOfWorks.list.byId;
export const selectTable = (store: IRootState) => store.sgw.natureOfWorks.list.table;
export const selectLoading = (store: IRootState) => store.sgw.natureOfWorks.list.loading;

export const getNatureOfWorksList = createAllEntitiesSelector(selectAllIds, selectById);
export const getPagedApiParams = createPagedApiParamsSelector<ISgwNatureOfWorksFilter>(selectTable);
export const getNatureOfWorks = (id?: string) =>
  createSelector([selectById], (selectById) => (id ? selectById?.[id] : undefined));
