import { baseInitialFilter } from '../../common/config/paging.constants';
import { ISgwPartnersState } from '../../types';

export const initialSgwPartnersState: ISgwPartnersState = {
  list: {
    allIds: [],
    byId: {},
    table: baseInitialFilter,
    loading: true,
  },
  loading: true,
};
