import * as React from 'react';
import { FC, PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppActions, CONFIG } from '../../../common';
import { Icon } from '../../../common/components/layout/icon.component';
import { Visible } from '../../../common/components/layout/Visible.component';
import {
  getAsignTabs,
  getFirstVisibleTab,
  getMasterTabs,
  getSgwTabs,
  getTabsAreVisible,
} from '../../../common/store/user/user.selectors';
import { translate } from '../../../common/translations/translate';
import { useToggle } from '../../../hooks/useToggle.hook';
import { NotificationActions } from '../../../store/actions/notifications.actions';
import { AppInfo } from '../../molecules/AppInfo/AppInfo.component';
import { GroupedRouterMenu } from '../../molecules/GroupedRouterMenu/GroupedRouterMenu.component';
import { useMainMenuStyles } from './MainMenu.styles';
import { selectUser } from '../../../store/selectors';
import { NOTIFICATION_REFETCH_MS } from '../../../common/time-constants';
import { usePageActivation } from '../../../hooks';

export const MainMenu: FC<PropsWithChildren> = ({ children }) => {
  const C = useMainMenuStyles();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [showInfo, toggleShowInfo] = useToggle(false);

  useEffect(() => {
    if (user) {
      dispatch(NotificationActions.fetchCount());

      const interval = setInterval(() => {
        dispatch(NotificationActions.fetchCount());
      }, NOTIFICATION_REFETCH_MS);
      return () => clearInterval(interval);
    }
  }, [dispatch, user]);

  usePageActivation(() => dispatch(NotificationActions.fetchCount()));

  const asignTabs = useSelector(getAsignTabs);
  const sgwTabs = useSelector(getSgwTabs);
  const masterDataTabs = useSelector(getMasterTabs);
  const tabsAreVisible = useSelector(getTabsAreVisible);
  const firstVisibleTab = useSelector(getFirstVisibleTab);

  const onLogout = () => dispatch(AppActions.logout(true));

  return (
    <>
      <div className={C.header} data-testid="MainMenuHeader">
        <a href={firstVisibleTab?.route ?? '/'} className={C.logo}>
          <img alt={translate('login.image')} className={C.image} src={CONFIG.logoUrl} />
        </a>
        <Visible visible={tabsAreVisible}>
          <GroupedRouterMenu
            className={C.mainMenu}
            tabGroups={[
              {
                name: 'Beperkte signalisatie',
                tabs: asignTabs,
              },
              {
                name: 'Signalisatie Grote Werven',
                tabs: sgwTabs,
              },
              {
                name: 'Master Data',
                tabs: masterDataTabs,
              },
            ]}
          />
        </Visible>
        <div className={C.topRightButtons}>
          <div className={C.logout} onClick={onLogout}>
            {user?.fullName || user?.email} <Icon.Logout variant="large" />
          </div>
          <div className={C.infoButton} onClick={toggleShowInfo}>
            <Icon.Info variant="large" />
          </div>
          <Visible visible={showInfo}>
            <AppInfo />
          </Visible>
        </div>
      </div>
      <div className={C.content}>{children}</div>
    </>
  );
};
