import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Fab, Tooltip } from '@material-ui/core';

interface IProps {
  className?: string;
  content?: string | React.ReactNode;
  color?: 'default' | 'primary' | 'secondary' | 'white' | 'grey';
  dataTestId?: string;
  onClick?: () => void;
  style?: object;
  tooltip?: string;
}

export const AsignFab: FunctionComponent<PropsWithChildren<IProps>> = (props) => {
  let color = 'default';
  let className = props.className || '';
  if (props.color) {
    if (['primary', 'secondary', 'default'].includes(props.color)) {
      color = props.color;
    }
    if (props.color === 'white') {
      className = className + ' white-fab';
    }
    if (props.color === 'grey') {
      className = className + ' grey-fab';
    }
  }

  return (
    <Tooltip title={`${props.tooltip || ''}`}>
      <Fab
        style={props.style}
        color={color as any}
        className={'fab-marging ' + className}
        data-testid={props.dataTestId}
        size="small"
        onClick={props.onClick}
      >
        {props.children}
      </Fab>
    </Tooltip>
  );
};
