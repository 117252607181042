import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { translate } from '../../../common/translations/translate';
import { formatDateTimeToDisplayDate } from '../../../common/utils/date.util';
import { getPhaseById } from '../../../store/selectors/sgwRequest.selectors';
import { useGeneralStyles } from '../../../style/generalStyles';

interface IProps {
  phaseId: number;
  index: number;
}

const useStyles = createUseStyles({
  phase: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '30ch',
  },
});

export const PhaseNameDatesComponent: FC<IProps> = ({ phaseId, index }) => {
  const phase = useSelector(getPhaseById(phaseId));
  const G = useGeneralStyles();
  const C = useStyles();
  return phase ? (
    <div className={G.flexRow} key={phase.id}>
      <b className={C.phase}>{phase.phaseName || `${translate('sgw.requests.detail.conflicts.phase')} ${index}`}</b>
      {`: ${formatDateTimeToDisplayDate(phase.dateFrom)} - ${formatDateTimeToDisplayDate(phase.dateUntil)}`}
    </div>
  ) : null;
};
