import { Actions, TableActions } from '../../common';
import { ISgwProject, ISgwProjectsFilter } from '../../types';

class SgwProjectsActionsClass extends Actions {
  list = new TableActions<ISgwProject, ISgwProjectsFilter>(`${this.prefix}.list`);
  setLoading = this.createPayloadAction<boolean>('setLoading');
  set = this.createPayloadAction<ISgwProject>('set');
  fetch = this.createPayloadAction<string>('fetch');
  save = this.createPayloadAction<ISgwProject>('save');
}

export const SgwProjectsActions = new SgwProjectsActionsClass('sgwProjects');
