import * as React from 'react';
import { FC, useCallback, useEffect, useState } from 'react';
import { Polygon, Polyline, PolylineProps } from 'react-leaflet';
import 'leaflet-polylinedecorator';
import { LatLng } from 'leaflet';
import { DraggableMarker } from './DraggableMarker.component';
import { GeoDrawingType, IGeometry, IGeometryType } from '../../../types';
import { createLinestringFromLatLng, createPolygonFromLatLng } from '../../utils/geojson.util';
import { Visible } from '../layout/Visible.component';
import { useLeafletArrow } from '../../../hooks/useLeafletArrow.hook';

interface IProps extends PolylineProps {
  editable?: boolean;
  geometryType?: IGeometryType.LineString | IGeometryType.Polygon;
  positions: LatLng[];
  saveGeometry?: (geometry: IGeometry) => void;
  geoDrawingType?: GeoDrawingType;
  hideMarkers?: boolean;
  showArrow?: boolean;
}

export const GeometryWithMarkers: FC<IProps> = ({
  editable = false,
  geometryType = IGeometryType.LineString,
  positions,
  saveGeometry,
  geoDrawingType,
  hideMarkers = false,
  showArrow,
  ...props
}) => {
  const polyRef = useLeafletArrow(showArrow);

  const [showPositions, setShowPositions] = useState<LatLng[]>(positions);

  useEffect(() => {
    setShowPositions(positions);
  }, [positions]);

  const onDragStart = useCallback(
    (index: number) => (latlng: LatLng) => {
      const newPositions = [...showPositions];
      newPositions[index] = latlng;

      if (geometryType === IGeometryType.Polygon && index === 0) {
        newPositions[positions.length - 1] = latlng;
      }

      setShowPositions(newPositions);
    },
    [geometryType, positions.length, showPositions],
  );

  const onDragMarkerEnd = useCallback(() => {
    switch (geometryType) {
      case IGeometryType.LineString:
        saveGeometry?.(createLinestringFromLatLng(showPositions));
        break;

      case IGeometryType.Polygon:
        saveGeometry?.(createPolygonFromLatLng(showPositions));
        break;
    }
  }, [geometryType, saveGeometry, showPositions]);

  const getMarkerPositions = useCallback(() => {
    if (hideMarkers) return [];
    switch (geometryType) {
      case IGeometryType.Polygon:
        return positions.slice(0, positions.length - 1);
      default:
        return positions;
    }
  }, [geometryType, hideMarkers, positions]);

  return (
    <>
      {getMarkerPositions().map((position, index) => (
        <DraggableMarker
          key={`marker-${index}`}
          draggable={editable}
          position={position}
          onDragMarker={onDragStart(index)}
          onDragMarkerEnd={onDragMarkerEnd}
        />
      ))}
      <Visible visible={geometryType === IGeometryType.LineString}>
        <Polyline ref={polyRef} {...props} positions={showPositions} />
      </Visible>
      <Visible visible={geometryType === IGeometryType.Polygon}>
        <Polygon {...props} positions={[showPositions]} />
      </Visible>
    </>
  );
};
