import { Grid, makeStyles } from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Auth, UserRequestOverview } from '../../..';
import { Loader } from '../../../../common/components/layout/loader.component';
import { UtilityCompanyActions } from '../../../../store/actions';
import { IUtilityCompanyAclActions } from '../../../../types';
import { UtilityCompanyDetailInfo } from '../../../molecules';
import { Header } from '../../../../common/components/layout/Header.component';
import { appUrls } from '../../../../common/config/url.constants';
import { translate } from '../../../../common/translations/translate';
import { Visible } from '../../../../common/components/layout/Visible.component';
import { UtilityCompanyForm } from '../../../forms';
import { getUtilityCompany, selectLoading } from '../../../../store/selectors/utilityCompanies.selectors';

const useStyles = makeStyles({
  header: {
    maxWidth: 'unset',
  },
  gridContainer: {
    paddingLeft: '20px',
    paddingRight: '20px',
  },
});

interface IProps {
  isEditMode?: boolean;
}

export const DetailUtilityCompanyPage: FC<IProps> = ({ isEditMode = false }) => {
  const C = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const loading = useSelector(selectLoading);
  const company = useSelector(getUtilityCompany(id));
  const requiredAcl = isEditMode
    ? IUtilityCompanyAclActions.editUtilityCompany
    : IUtilityCompanyAclActions.viewUtilityCompany;

  useEffect(() => {
    id && dispatch(UtilityCompanyActions.fetch(id));
  }, [dispatch, id]);

  return (
    <Auth acl={requiredAcl} showUnauthorizedPage>
      <Loader loading={loading}>
        <Header
          data-testid="UtilityCompanyDetailHeader"
          backButtonUrl={appUrls.utilityCompanies.base}
          containerClassName={C.header}
        >
          {translate('utilityCompanies.detail.title', { company: company?.name })}
        </Header>
        <Grid container justify="center" spacing={2} className={C.gridContainer}>
          <Grid item xs={6} data-testid="leftColumn">
            <Visible visible={isEditMode} defaultComponent={<UtilityCompanyDetailInfo />}>
              <UtilityCompanyForm title={translate('utilityCompanies.updateUtilityCompany')} />
            </Visible>
          </Grid>
          <Grid item xs={6} data-testid="rightColumn">
            &nbsp;
          </Grid>
        </Grid>
        <Grid container justify="center" spacing={2} className={C.gridContainer}>
          <Grid item xs={12} data-testid="leftColumn">
            <UserRequestOverview utilityCompany={parseInt(id)} />
          </Grid>
        </Grid>
      </Loader>
    </Auth>
  );
};
