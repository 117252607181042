import { useForm } from 'react-hook-form';
import { ISgwProject, IRouterWithId } from '../../types';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SgwProjectsActions } from '../../store/actions';
import { getSgwProject } from '../../store/selectors/sgwProjects.selectors';
import { useParams } from 'react-router';

export const useSgwProjectForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams<IRouterWithId>();
  const initialValues = useSelector(getSgwProject(id));

  const formMethods = useForm<ISgwProject>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: { ...initialValues },
  });

  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    reset(initialValues);
  }, [initialValues, reset]);

  const _onSubmit = useCallback(
    (values: ISgwProject) => {
      dispatch(SgwProjectsActions.save(values));
    },
    [dispatch],
  );

  const submitProject = handleSubmit(_onSubmit);

  return { formMethods, submitProject, id };
};
