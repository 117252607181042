import { combineReducers } from 'redux';
import { IUtilityCompany, IUtilityCompanyFilter } from '../../types';
import { UtilityCompanyActions } from '../actions';
import { createPagedTableReducer } from './pagedTable.reducer';
import { initialUtilityCompaniesState } from '../state';
import { generatePayloadReducer } from '../../common';

export const utilityCompaniesReducer = combineReducers({
  list: createPagedTableReducer<IUtilityCompany, IUtilityCompanyFilter>({
    fetchAction: UtilityCompanyActions.list.fetch.type,
    addEntitiesActions: [UtilityCompanyActions.list.set.type],
    addEntityActions: [UtilityCompanyActions.set.type],
    setAllIdsAction: UtilityCompanyActions.list.set.type,
    setParamsActions: [UtilityCompanyActions.list.fetch.type, UtilityCompanyActions.list.setParams.type],
    initialParamsState: initialUtilityCompaniesState.list.table,
  }),
  loading: generatePayloadReducer([UtilityCompanyActions.setLoading.type], false),
});
