import { InputLabel } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import NoSsr from '@material-ui/core/NoSsr';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import classNames from 'classnames';
import * as React from 'react';
import { createUseStyles } from 'react-jss';
import Select, {
  components,
  ControlProps,
  MenuProps,
  OptionProps,
  PlaceholderProps,
  SingleValueProps,
  ValueContainerProps,
} from 'react-select';
import { IWithClassName } from '../../../types';
import { COLORS } from '../../config/theme';
import { translate } from '../../translations/translate';

const useStyles = createUseStyles({
  root: {
    flexGrow: 1,
    position: 'relative',
  },
  input: {
    display: 'flex',
    minHeight: '21px',
    height: 'auto',
    padding: '10px 0 3px 0',
  },
  label: {
    position: 'absolute',
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  singleValue: {
    fontSize: 14,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 14,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
  },
  disabled: {
    color: COLORS.DISABLED_GREY,
  },
});

const selectStyles = {
  input: (base: any) => ({
    ...base,
    '& input': {
      font: 'inherit',
    },
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    cursor: 'pointer',
    padding: 4,
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: 'none',
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    cursor: 'pointer',
  }),
};

const NoOptionsMessage = () => <MenuItem disabled>{translate('noOptionsAvailable')}</MenuItem>;

const inputComponent = ({ inputRef, ...props }: any) => <div ref={inputRef} {...props} />;

const Control = (props: ControlProps<any>) => {
  const C = useStyles();
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classNames(C.input, props.isDisabled && C.disabled),
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
};

const Option = (props: OptionProps<any>) => (
  <MenuItem
    buttonRef={props.innerRef}
    selected={props.isFocused}
    component="div"
    style={{
      fontWeight: props.isSelected ? 500 : 400,
    }}
    {...props.innerProps}
  >
    {props.children}
  </MenuItem>
);

const Placeholder = (props: PlaceholderProps<any>) => (
  <Typography color="textSecondary" className={useStyles().placeholder}>
    {props.children}
  </Typography>
);

const SingleValue = (props: SingleValueProps<any>) => (
  <Typography className={useStyles().singleValue} {...props.innerProps}>
    {props.children}
  </Typography>
);

const ValueContainer = (props: ValueContainerProps<any>) => (
  <div className={useStyles().valueContainer}>{props.children}</div>
);

const CustomDropDownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    {props.selectProps.menuIsOpen ? <ArrowDropUpIcon color={'action'} /> : <ArrowDropDownIcon color={'action'} />}
  </components.DropdownIndicator>
);

const Menu = (props: MenuProps<any>) => (
  <Paper square className={useStyles().paper} {...props.innerProps}>
    {props.children}
  </Paper>
);

// The actual component:

interface IProps<T> extends IWithClassName {
  elipsisChars?: number;
  title?: string;
  placeholder: string;
  menuItems: { value: T; label: string }[];
  onChange: (value?: T) => void;
  value?: T;
  isClearable?: boolean;
  disabled?: boolean;
  labelClassName?: string;
  required?: boolean;
}

export const Autocomplete = <T,>({
  title = '',
  placeholder,
  menuItems,
  value,
  onChange,
  elipsisChars,
  isClearable = true,
  className,
  disabled = false,
  labelClassName,
  required = false,
}: IProps<T>) => {
  const C = useStyles();
  const itemsSorted = menuItems.sort();
  const handleChange = (newValue: any) => {
    onChange(newValue ? newValue.value : undefined);
  };

  let currentMenuItem = itemsSorted.find((item) => item.value === value);
  if (elipsisChars && currentMenuItem && currentMenuItem.label.length > elipsisChars) {
    currentMenuItem = { ...currentMenuItem, label: currentMenuItem.label.substr(0, elipsisChars - 3) + '...' };
  }
  return (
    <div className={classNames(C.root, className)}>
      <NoSsr>
        <InputLabel shrink focused={!!value} className={classNames(C.label, labelClassName)} required={required}>
          {title}
        </InputLabel>
        <Select
          styles={selectStyles}
          options={itemsSorted}
          components={{
            Control,
            Menu,
            NoOptionsMessage,
            Option,
            Placeholder,
            SingleValue,
            ValueContainer,
            DropdownIndicator: CustomDropDownIndicator,
          }}
          value={currentMenuItem || null}
          onChange={handleChange}
          placeholder={placeholder}
          isClearable={isClearable}
          isDisabled={disabled}
        />
      </NoSsr>
    </div>
  );
};
