export enum ModulePredefinedFilter {
  CRITICAL = 'critical',
  IN_MAINTENANCE = 'in_maintenance',
}

export interface IModulesFilter {
  appVersions: string[];
  id: string;
  signId: string;
  states: string[];
  batches: number[];
  inMaintenance: boolean | null;
  critical: boolean | null;
  coupled: boolean;
  places: string[];
}

export interface IModuleAppVersion {
  id: string;
  name: string;
}
