import { Button, Grid } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import { ClearFiltersFab } from '../../../common/components/buttons/ClearFiltersFab';
import { Autocomplete } from '../../../common/components/form-fields/autocomplete.component';
import { AsignDatepicker } from '../../../common/components/form-fields/datepicker/asign-datepicker.component';
import { MultiSelectV2Component } from '../../../common/components/form-fields/multi-select-v2.component';
import { Disableable } from '../../../common/components/layout/Disableable.component';
import { PanelTitle } from '../../../common/components/panel/panel-title.component';
import { getAdmins } from '../../../common/store/user/user.selectors';
import { RequestFilterStorage } from '../../../common/utils/filter-storage';
import {
  getAllRequestReasons,
  getAllRequestStates,
  getAllRequestTypes,
  getIsOnlyRoleUtilities,
  isAppFeatureEnabled,
} from '../../../store/selectors';
import {
  AppFeatureFlag,
  CarFreeZonesFilterState,
  DateFormat,
  IRequestsFilter,
  MessageState,
  OPEN_EXTENSION_STATES,
  OPEN_EXTENSION_STATES_WITHOUT_ORDER_API,
  PaymentState,
  RequestExtensionState,
  RequestParkingBanState,
  RequestPriority,
  RequestReason,
  RequestSearchField,
  RequestStates,
} from '../../../types';
import { QuickFilter } from './quick-filter.component';
import { SearchTextComponent } from './search-text-component';
import { Visible } from '../../../common/components/layout/Visible.component';
import { getCarFreeZonesMenuItemsBase } from '../../../common/store/car-free-zones/carFreeZones.selectors';
import { getCarFreeZonesFilterStateOptions } from '../../../carFreeZone/CarFreeZone.constants';
import { translate } from '../../../common/translations/translate';

interface IProps {
  save: (filter: IRequestsFilter) => void;
  style?: React.CSSProperties;
}

const filterMargins: React.CSSProperties = { margin: '0px 10px 30px 0px', alignItems: 'left' };
const quickFilterMargins: React.CSSProperties = { margin: '0px 0px 30px -7px', alignItems: 'left' };

const moreFiltersStyle: React.CSSProperties = { marginBottom: 35, maxWidth: 254 };

export const Filters: FunctionComponent<IProps> = ({ save, style }: IProps) => {
  const initialFilter = RequestFilterStorage.get() || {};
  const carFreeZoneMenu = useSelector(getCarFreeZonesMenuItemsBase) || [];

  const [from, setFrom] = useState<Moment>(moment(initialFilter.fromDate));
  const [to, setTo] = useState<Moment>(
    initialFilter.untilDate ? moment(initialFilter.untilDate) : moment().add(14, 'days'),
  );

  // search filter
  const [searchField, setSearchField] = useState<RequestSearchField>(
    initialFilter.search?.field || RequestSearchField.user_requested_name,
  );
  const [searchValue, setSearchValue] = useState<string>(initialFilter.search?.value || '');
  const [savedSearchValue, setSavedSearchValue] = useState<string>(searchValue);
  const [bypassOtherFilters, setBypassOtherFilters] = useState<boolean>(
    initialFilter.search?.bypassOtherFilters || false,
  );

  // quick filters
  const [mine, setMine] = useState<boolean>(initialFilter.mine || false);
  const [toBePickedUp, setToBePickedUp] = useState<boolean>(initialFilter.toBePickedUp || false);
  const [urgent, setUrgent] = useState<boolean>(initialFilter.urgent || false);
  const [extensionStates, setExtensionStates] = useState<RequestExtensionState[]>(initialFilter.extensionStates || []);

  // more filters
  const [showMoreFilters, setShowMoreFilters] = useState<boolean>(false);
  const [messageStates, setMessageStates] = useState<MessageState[]>(initialFilter.messageStates || []);
  const [requestStates, setRequestStates] = useState<string[]>(initialFilter.requestStates || []);
  const [requestTypes, setRequestTypes] = useState<string[]>(initialFilter.requestTypes || []);
  const [requestReasons, setRequestReasons] = useState<RequestReason[]>(initialFilter.requestReasons || []);
  const [priorities, setPriorities] = useState<RequestPriority[]>(initialFilter.priorities || []);
  const [paymentStates, setPaymentStates] = useState<PaymentState[]>(initialFilter.paymentStates || []);
  const [parkingBanStates, setParkingBanStates] = useState<RequestParkingBanState[]>(
    initialFilter.parkingBanStates || [],
  );
  const [userResponsible, setUserResponsible] = useState<number | undefined>(initialFilter.userResponsible);
  const [carFreeZone, setCarFreeZone] = useState<string[] | undefined>(initialFilter.carFreeZone);
  const [carFreeZoneState, setCarFreeZoneState] = useState<CarFreeZonesFilterState[]>(
    initialFilter.carFreeZoneState || [],
  );

  const [requestedFrom, setRequestedFrom] = useState<Moment>();
  const [requestedTo, setRequestedTo] = useState<Moment>();
  const [openExtensionStates, setOpenExtensionStates] = useState<RequestExtensionState[]>(OPEN_EXTENSION_STATES);

  const isDigipolisOrderEnabled = useSelector(isAppFeatureEnabled[AppFeatureFlag.integrateDigipolisOrder]);
  const allRequestStates = useSelector(getAllRequestStates);
  const allRequestTypes = useSelector(getAllRequestTypes);
  const allRequestReasons = useSelector(getAllRequestReasons);

  const isOnlyRoleUtilities = useSelector(getIsOnlyRoleUtilities);

  useEffect(() => {
    setOpenExtensionStates(isDigipolisOrderEnabled ? OPEN_EXTENSION_STATES : OPEN_EXTENSION_STATES_WITHOUT_ORDER_API);
  }, [isDigipolisOrderEnabled]);

  useUpdateEffect(() => {
    save({
      carFreeZone,
      carFreeZoneState,
      extensionStates,
      fromDate: from.format(DateFormat.reduxStoreDateString),
      mine,
      messageStates,
      paymentStates,
      priorities,
      parkingBanStates,
      requestedFrom: requestedFrom ? requestedFrom.format('YYYY-MM-DD') : undefined,
      requestedTo: requestedTo ? requestedTo.format('YYYY-MM-DD') : undefined,
      requestReasons,
      requestStates,
      requestTypes,
      search: {
        bypassOtherFilters,
        field: searchField,
        value: savedSearchValue,
      },
      untilDate: to.format(DateFormat.reduxStoreDateString),
      toBePickedUp,
      urgent,
      userResponsible,
    });
  }, [
    bypassOtherFilters,
    carFreeZone,
    carFreeZoneState,
    extensionStates,
    from,
    messageStates,
    mine,
    parkingBanStates,
    paymentStates,
    priorities,
    requestedFrom,
    requestedTo,
    requestReasons,
    requestStates,
    requestTypes,
    save,
    savedSearchValue,
    searchField,
    to,
    toBePickedUp,
    urgent,
    userResponsible,
  ]);

  const clearQuickFilters = useCallback(() => {
    setMine(false);
    setToBePickedUp(false);
    setUrgent(false);
    setMessageStates([]);
    setExtensionStates([]);
  }, []);

  //Change the default From-date in the Aanvragen tab to one month in the past and the default To date to one year in the future.
  const clearGeneralFilters = useCallback(() => {
    setFrom(moment().subtract(1, 'month'));
    setTo(moment().add(1, 'year'));
    clearQuickFilters();
    setUserResponsible(undefined);
    setMessageStates([]);
    setRequestStates([]);
    setRequestTypes([]);
    setRequestReasons([]);
    setPriorities([]);
    setPaymentStates([]);
    setParkingBanStates([]);
    setRequestedFrom(undefined);
    setRequestedTo(undefined);
    setCarFreeZone([]);
    setCarFreeZoneState([]);
  }, [clearQuickFilters]);

  const clearFilters = useCallback(() => {
    clearGeneralFilters();
    setSearchField(RequestSearchField.user_requested_name);
    setSearchValue('');
    setSavedSearchValue('');
    setBypassOtherFilters(false);
  }, [clearGeneralFilters]);

  const myRequestsCallback = useCallback(() => {
    setUserResponsible(undefined);
    if (!mine) setToBePickedUp(false);
    setMine(!mine);
  }, [mine]);

  const submittedCallback = useCallback(() => {
    if (requestStates.includes(RequestStates.submitted)) {
      setRequestStates(requestStates.filter((state) => state !== RequestStates.submitted));
    } else {
      setRequestStates([...requestStates, RequestStates.submitted]);
    }
  }, [requestStates, setRequestStates]);

  const newReactionsCallback = useCallback(() => {
    messageStates.length === 1 && messageStates.includes(MessageState.feedback_received)
      ? setMessageStates([])
      : setMessageStates([MessageState.feedback_received]);
  }, [messageStates]);

  const userResponsibleCallback = useCallback((value?: number) => {
    setUserResponsible(value);
    setToBePickedUp(false);
    setMine(false);
  }, []);

  const changeCarFreeZone = useCallback((value?: number) => {
    setCarFreeZone(value !== undefined ? [`${value}`] : undefined);
  }, []);

  const openExtensionsCallback = useCallback(() => {
    extensionStates.length === openExtensionStates.length &&
    extensionStates.every((state) => openExtensionStates.includes(state))
      ? setExtensionStates([])
      : setExtensionStates([...openExtensionStates]);
  }, [extensionStates, openExtensionStates]);

  const urgentCallback = useCallback(() => {
    setUrgent(!urgent);
  }, [urgent]);

  const changeMessageStates = useCallback(
    (newMessageStates: MessageState[]) => {
      clearQuickFilters();
      setMessageStates(newMessageStates);
    },
    [clearQuickFilters],
  );

  const changeRequestStates = useCallback(
    (newRequestStates: string[]) => {
      clearQuickFilters();
      setRequestStates(newRequestStates);
    },
    [clearQuickFilters],
  );

  const changeRequestTypes = useCallback(
    (newRequestTypes: string[]) => {
      clearQuickFilters();
      setRequestTypes(newRequestTypes);
    },
    [clearQuickFilters],
  );

  const changeRequestReasons = useCallback(
    (newRequestReasons: RequestReason[]) => {
      clearQuickFilters();
      setRequestReasons(newRequestReasons);
    },
    [clearQuickFilters],
  );

  const changePriorities = useCallback(
    (newPriorities: RequestPriority[]) => {
      clearQuickFilters();
      setPriorities(newPriorities);
    },
    [clearQuickFilters],
  );

  const changePaymentStates = useCallback(
    (newPaymentStates: PaymentState[]) => {
      clearQuickFilters();
      setPaymentStates(newPaymentStates);
    },
    [clearQuickFilters],
  );

  const changeParkingBanStates = useCallback(
    (newParkingBanStates: RequestParkingBanState[]) => {
      clearQuickFilters();
      setParkingBanStates(newParkingBanStates);
    },
    [clearQuickFilters],
  );

  const changeSearchField = useCallback((e: React.ChangeEvent<any>) => {
    setSearchField(e.target.value);
    setSearchValue('');
    setSavedSearchValue('');
  }, []);

  const moreFiltersCallback = useCallback(() => setShowMoreFilters(!showMoreFilters), [showMoreFilters]);
  const changeSearchValue = useCallback((e: React.ChangeEvent<any>) => setSearchValue(e.target.value), []);
  const saveSearchValue = useCallback(() => setSavedSearchValue(searchValue), [searchValue]);
  const bypassOtherFiltersCallback = useCallback(() => {
    saveSearchValue();
    setBypassOtherFilters(!bypassOtherFilters);
  }, [saveSearchValue, bypassOtherFilters]);

  const isValidSearchField = useCallback(() => {
    switch (searchField) {
      case RequestSearchField.event_name:
        return searchValue.length === 0 || searchValue.length > 2;
      case RequestSearchField.reference_id:
        return searchValue.length === 0 || searchValue.length === 11;
      case RequestSearchField.street_name:
        return searchValue.length === 0 || searchValue.length > 2;
      case RequestSearchField.user_requested_name:
        return searchValue.length === 0 || searchValue.length > 2;
      default:
        return true;
    }
  }, [searchValue, searchField]);

  const showToggleBypass = useCallback(
    () => (isValidSearchField() && searchValue.length !== 0) || bypassOtherFilters,
    [isValidSearchField, searchValue, bypassOtherFilters],
  );

  return (
    <Grid container direction="column" spacing={2} style={{ ...style, padding: '10px 15px' }}>
      <Grid item style={{ marginBottom: 20 }}>
        <PanelTitle>{translate('Requests.Filter.Title')}</PanelTitle>
        <Grid item style={{ minWidth: 150, flex: 1, alignItems: 'left', marginBottom: 10 }}>
          <SearchTextComponent<RequestSearchField>
            allSearchFields={[
              { value: RequestSearchField.user_requested_name, label: translate('Requests.SearchFields.Name') },
              { value: RequestSearchField.street_name, label: translate('Requests.SearchFields.Address') },
              { value: RequestSearchField.reference_id, label: translate('Requests.SearchFields.Reference') },
              { value: RequestSearchField.event_name, label: translate('Requests.SearchFields.EventName') },
              {
                value: RequestSearchField.external_reference,
                label: translate('Requests.SearchFields.ExternalReference'),
              },
            ]}
            searchField={searchField}
            updateSearchField={changeSearchField}
            updateSearchValue={changeSearchValue}
            searchValue={searchValue}
            saveSearchValue={saveSearchValue}
            title={bypassOtherFilters ? translate('searching') : translate('filterOn')}
            error={!isValidSearchField()}
          />
        </Grid>
        <Grid item style={quickFilterMargins}>
          <Visible visible={showToggleBypass()}>
            <QuickFilter
              checked={bypassOtherFilters}
              onChange={bypassOtherFiltersCallback}
              label={translate('Requests.Filter.SearchAllRequest')}
            />
          </Visible>
        </Grid>
        <Disableable disabled={bypassOtherFilters}>
          <Grid item style={filterMargins}>
            <AsignDatepicker
              label={translate('Requests.Filter.From')}
              value={from}
              onChange={setFrom}
              options={{ popperPlacement: 'bottom-start' }}
              maxDate={to}
            />
          </Grid>
          <Grid item style={filterMargins}>
            <AsignDatepicker
              label={translate('Requests.Filter.To')}
              value={to}
              onChange={setTo}
              options={{ popperPlacement: 'bottom-start' }}
              minDate={from}
            />
          </Grid>
          <Grid item style={quickFilterMargins}>
            <QuickFilter
              checked={mine}
              disabled={!!userResponsible}
              onChange={myRequestsCallback}
              label={translate('Requests.Filter.MyRequests')}
            />
            <QuickFilter
              checked={requestStates.includes(RequestStates.submitted)}
              disabled={!!userResponsible}
              onChange={submittedCallback}
              label={translate('Requests.Filter.Submitted')}
            />
            <QuickFilter
              checked={messageStates.length === 1 && messageStates.includes(MessageState.feedback_received)}
              onChange={newReactionsCallback}
              label={translate('Requests.Filter.NewReactions')}
            />
            <QuickFilter
              checked={
                extensionStates.length === openExtensionStates.length &&
                extensionStates.every((state) => openExtensionStates.includes(state))
              }
              onChange={openExtensionsCallback}
              label={translate('Requests.Filter.openExtensions')}
            />
            <QuickFilter checked={urgent} onChange={urgentCallback} label={translate('Requests.Filter.Urgent')} />
          </Grid>
          <Grid item style={{ minWidth: 150, flex: 1, alignItems: 'left', marginBottom: 30 }}>
            <Autocomplete<number>
              title={translate('UserResponsible')}
              placeholder={translate('Requests.Filter.SelectUserResponsible')}
              menuItems={useSelector(getAdmins)}
              value={userResponsible}
              onChange={userResponsibleCallback}
              elipsisChars={25}
            />
          </Grid>
          <Grid item style={{ minWidth: 150, flex: 1, alignItems: 'left' }}>
            <Button
              style={{ textTransform: 'none', textAlign: 'left', marginLeft: '-8px', fontWeight: 'normal' }}
              color="primary"
              onClick={moreFiltersCallback}
            >
              {translate('Requests.Filter.Buttons.MoreFilters')}
              {showMoreFilters ? <ExpandLess /> : <ExpandMore />}
            </Button>
            {showMoreFilters && (
              <Grid>
                <MultiSelectV2Component
                  style={{ marginTop: 8, ...moreFiltersStyle }}
                  title={translate('Requests.Filter.MessageState')}
                  values={messageStates}
                  onChange={changeMessageStates}
                  menuItems={[
                    { value: MessageState.feedback_received, label: translate('feedbackReceived') },
                    { value: MessageState.awaiting_feedback, label: translate('awaitingFeedback') },
                  ]}
                  placeholder={translate('Requests.Filter.SelectMessageState')}
                />
                <MultiSelectV2Component
                  style={moreFiltersStyle}
                  title={translate('Requests.Filter.RequestState')}
                  values={requestStates}
                  onChange={changeRequestStates}
                  menuItems={allRequestStates}
                  placeholder={translate('Requests.Filter.SelectRequestState')}
                />
                <MultiSelectV2Component
                  style={moreFiltersStyle}
                  title={translate('Requests.Filter.RequestType')}
                  values={requestTypes}
                  onChange={changeRequestTypes}
                  menuItems={allRequestTypes}
                  placeholder={translate('Requests.Filter.SelectRequestType')}
                />
                {!isOnlyRoleUtilities && (
                  <MultiSelectV2Component
                    style={moreFiltersStyle}
                    title={translate('Requests.Filter.RequestReason')}
                    values={requestReasons}
                    onChange={changeRequestReasons}
                    menuItems={allRequestReasons}
                    placeholder={translate('Requests.Filter.SelectRequestReason')}
                  />
                )}
                <MultiSelectV2Component
                  style={moreFiltersStyle}
                  title={translate('Requests.Filter.Priority')}
                  values={priorities}
                  onChange={changePriorities}
                  menuItems={[
                    { value: RequestPriority.PRIORITY_NORMAL, label: translate('Normal') },
                    { value: RequestPriority.PRIORITY_HIGH, label: translate('High') },
                  ]}
                  placeholder={translate('Requests.Filter.SelectPriority')}
                />
                <MultiSelectV2Component
                  style={moreFiltersStyle}
                  title={translate('Requests.Filter.PaymentStates')}
                  values={paymentStates}
                  onChange={changePaymentStates}
                  menuItems={[
                    { value: PaymentState.new, label: translate('Requests.PaymentStates.new') },
                    { value: PaymentState.processing, label: translate('Requests.PaymentStates.processing') },
                    { value: PaymentState.processed, label: translate('Requests.PaymentStates.processed') },
                    { value: PaymentState.overpaid, label: translate('Requests.PaymentStates.overpaid') },
                    { value: PaymentState.partially_paid, label: translate('Requests.PaymentStates.partially_paid') },
                    { value: PaymentState.closed, label: translate('Requests.PaymentStates.closed') },
                  ]}
                  placeholder={translate('Requests.Filter.SelectPaymentStates')}
                />
                <MultiSelectV2Component
                  style={moreFiltersStyle}
                  title={translate('Requests.Filter.ParkingBanStates')}
                  values={parkingBanStates}
                  onChange={changeParkingBanStates}
                  menuItems={[
                    { value: RequestParkingBanState.new, label: translate('Requests.ParkingBanStates.New') },
                    { value: RequestParkingBanState.planned, label: translate('Requests.ParkingBanStates.Planned') },
                    {
                      value: RequestParkingBanState.deploying,
                      label: translate('Requests.ParkingBanStates.Deploying'),
                    },
                    { value: RequestParkingBanState.deployed, label: translate('Requests.ParkingBanStates.Deployed') },
                    {
                      value: RequestParkingBanState.not_deployed,
                      label: translate('Requests.ParkingBanStates.NotDeployed'),
                    },
                    {
                      value: RequestParkingBanState.collecting,
                      label: translate('Requests.ParkingBanStates.Collecting'),
                    },
                    {
                      value: RequestParkingBanState.collected,
                      label: translate('Requests.ParkingBanStates.Collected'),
                    },
                  ]}
                  placeholder={translate('Requests.Filter.SelectParkingBanStates')}
                />
                <Grid item style={{ minWidth: 150, flex: 1, alignItems: 'left', marginBottom: 30 }}>
                  <Autocomplete<number>
                    title={translate('Requests.Filter.Labels.CarFreeZones')}
                    placeholder={translate('Requests.Filter.Placeholders.CarFreeZones')}
                    menuItems={carFreeZoneMenu}
                    value={carFreeZone?.length ? parseInt(carFreeZone[0], 10) : undefined}
                    onChange={changeCarFreeZone}
                    elipsisChars={25}
                  />
                </Grid>
                <MultiSelectV2Component
                  style={moreFiltersStyle}
                  title={translate('Requests.Filter.Labels.CarFreeZoneState')}
                  values={carFreeZoneState}
                  onChange={setCarFreeZoneState}
                  menuItems={getCarFreeZonesFilterStateOptions()}
                  placeholder={translate('Requests.Filter.Placeholders.CarFreeZoneState')}
                />
                <Grid item style={filterMargins}>
                  <AsignDatepicker
                    label={translate('Requests.Filter.RequestedFrom')}
                    value={requestedFrom}
                    onChange={setRequestedFrom}
                    options={{ popperPlacement: 'bottom-start' }}
                    maxDate={requestedTo ? moment.min(moment(), requestedTo) : moment()}
                  />
                </Grid>
                <Grid item style={filterMargins}>
                  <AsignDatepicker
                    label={translate('Requests.Filter.RequestedTo')}
                    value={requestedTo}
                    onChange={setRequestedTo}
                    options={{ popperPlacement: 'bottom-start' }}
                    minDate={requestedFrom}
                    maxDate={moment()}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Disableable>
      </Grid>
      <ClearFiltersFab style={{ position: 'fixed', bottom: 13, left: 125 }} onClick={clearFilters} />
    </Grid>
  );
};
