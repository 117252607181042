import { IPagedTable, ISortingDirection } from '../common';
import { initialPaging } from '../common/config/paging.constants';
import {
  CarFreeZonesFilterState,
  CarFreeZonesFilterType,
  ExemptionFilterState,
  ExemptionsType,
  ICarFreeZoneRequestsFilter,
  ICarFreeZonesFilter,
  IExemptionsFilter,
  IGatesFilter,
  RequestStates,
} from '../types';
import I18n from 'i18n-js';

export const carFreeZoneRequestsAllowedStates: RequestStates[] = [
  RequestStates.draft,
  RequestStates.approved,
  RequestStates.in_effect,
  RequestStates.reviewing,
  RequestStates.submitted,
  RequestStates.waiting_for_payment,
];

export const carFreeZonesInitialFilter: ICarFreeZonesFilter = {
  query: '',
  state: [],
  type: [CarFreeZonesFilterType.krautli],
};

export const carFreeZonesInitialParams: IPagedTable<ICarFreeZonesFilter> = {
  filters: carFreeZonesInitialFilter,
  sorting: {
    key: 'name',
    direction: ISortingDirection.asc,
  },
  paging: initialPaging,
};

export const carFreeZonesGatesInitialParams: IPagedTable<IGatesFilter> = {
  filters: { carFreeZoneId: '' },
  sorting: {
    key: 'id',
    direction: ISortingDirection.asc,
  },
  paging: initialPaging,
};

export const exemptionsInitialFilter: IExemptionsFilter = {
  licensePlate: undefined,
  pincode: undefined,
  zapper: undefined,
  type: [],
  zoneType: [],
  dateFrom: undefined,
  dateUntil: undefined,
  active: undefined,
};

export const carFreeZonesExemptionsInitialParams: IPagedTable<IExemptionsFilter> = {
  filters: exemptionsInitialFilter,
  sorting: {
    key: 'date_until',
    direction: ISortingDirection.asc,
  },
  paging: initialPaging,
};

export const carFreeZoneRequestsInitialFilter: IPagedTable<ICarFreeZoneRequestsFilter> = {
  filters: { states: carFreeZoneRequestsAllowedStates },
  sorting: {
    key: 'id',
    direction: ISortingDirection.desc,
  },
  paging: initialPaging,
};

export const getCarFreeZonesFilterStateOptions = () =>
  Object.values(CarFreeZonesFilterState).map((value) => ({
    label: I18n.t(`carFreeZones.krautli.list.filters.state.options.${value}`),
    value,
  }));

export const getCarFreeZonesFilterTypeOptions = () =>
  Object.values(CarFreeZonesFilterType).map((value) => ({
    label: I18n.t(`carFreeZones.krautli.list.filters.type.options.${value}`),
    value,
  }));

export const getExemptionTypeOptions = () =>
  Object.values(ExemptionsType).map((value) => ({
    label: I18n.t(`carFreeZones.exemptions.filters.type.options.${value}`),
    value,
  }));

export const getExemptionFilterStateOptions = () =>
  Object.values(ExemptionFilterState).map((value) => ({
    label: I18n.t(`carFreeZones.exemptions.filters.state.options.${value}`),
    value,
  }));
