import TextField from '@material-ui/core/TextField';
import moment, { Moment } from 'moment';
import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { IComponentProps } from '../../../../types';
import { isWithinRange } from '../../../utils/moment.util';
import { onlyKeepDateFormat } from '../../../utils/string.util';

interface IProps extends IComponentProps {
  disabled?: boolean;
  label?: string;
  value?: string;
  onClick?: () => void;
  setDate?: (selectedDate: Date) => void; // Date instead of Moment because react-datepicker expects it
  minDate?: Moment;
  maxDate?: Moment;
  fullWidth: boolean;
  required?: boolean;
}

export const AsignDatepickerInput: FunctionComponent<IProps> = ({
  disabled = false,
  label,
  value = '',
  onClick,
  setDate,
  style,
  minDate,
  maxDate,
  className,
  fullWidth,
  required,
}) => {
  const [currentTextValue, setCurrentTextValue] = useState<string>(value);

  const allowedDateFormats = ['DD/MM/YYYY', 'D/M/YYYY', 'D/MM/YYYY', 'DD/M/YYYY'];
  const stringToMoment = (value: string): Moment => moment(onlyKeepDateFormat(value), allowedDateFormats, true);

  useEffect(() => {
    setCurrentTextValue(value);
  }, [value]);

  const isValid = (input: string = currentTextValue): boolean => {
    const currentMoment = stringToMoment(input);
    return input === '' || (currentMoment.isValid() && isWithinRange(currentMoment, minDate, maxDate));
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentTextValue(event.target.value);
    if (setDate && isValid(event.target.value) && event.target.value !== '') {
      setDate(stringToMoment(event.target.value).toDate());
    }
  };

  return (
    <TextField
      disabled={disabled}
      inputProps={{ style, className }}
      fullWidth={fullWidth}
      className={className}
      error={!isValid()}
      value={currentTextValue}
      onChange={onChange}
      onClick={onClick}
      label={label}
      placeholder={'DD/MM/YYYY'}
      required={required}
    />
  );
};
