import { RequestFilterStorage } from '../../common/utils/filter-storage';
import { IRequestsState } from '../../types';
import { ISortingDirection } from '../../common';
import moment from 'moment';

export const initialRequestsState: IRequestsState = {
  alreadyAssigned: false,
  conflicts: {
    byId: {},
  },
  copyRequest: null,
  draftIds: [],
  currentConflictKey: null,
  detail: {
    byId: {},
    editableFields: [],
  },
  exporting: false,
  list: {
    byId: {},
    allIds: [],
    table: {
      filters: RequestFilterStorage.get() || {
        fromDate: moment().format('YYYY-MM-DD'),
        untilDate: moment().add(14, 'days').format('YYYY-MM-DD'),
      },
      paging: {
        page: 1,
        pageSize: 20,
        totalPages: 1,
        totalRecords: 20,
      },
      sorting: {
        direction: ISortingDirection.desc,
        key: 'createdAt',
      },
    },
    loading: true,
  },
  geocodesById: {},
  history: {
    requestHistory: {
      history: [],
      total: 0,
    },
    permitHistory: [],
  },
  loading: true,
  saving: true,
  locationDetail: null,
  request: null,
  reverseGeocodesById: {},
  requestExtensions: null,
  requestMessageAttachments: [],
  requestMessagesList: {
    id: 0,
    acl: {
      messages_can_edit: false,
      messages_can_resolve: false,
      messages_can_view: false,
    },
    data: [],
  },
  requestReasonFilters: [],
  requestReasons: [],
  metadata: null,
  suggestions: [],
};
