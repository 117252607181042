import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { Container } from '../../../common';
import { LoaderBlur } from '../../../common/components/layout/LoaderBlur.component';
import { getServicesSaving } from '../../../common/store/services/Services.selectors';
import { Auth } from '../../../components';
import { IServiceAclActions } from '../../../types';
import { ServicesWhitelistListComponent } from './ServicesWhitelistList.component';
import { ServicesWhitelistUpload } from './ServicesWhitelistUpload.component';

export const ServicesWhitelistPage: FunctionComponent = () => {
  const busy = useSelector(getServicesSaving);

  return (
    <Auth acl={[IServiceAclActions.viewWhitelistList]}>
      <Container>
        <LoaderBlur loading={busy}>
          <ServicesWhitelistUpload />
          <ServicesWhitelistListComponent />
        </LoaderBlur>
      </Container>
    </Auth>
  );
};
