import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Container, WorkOrderItemsActions } from '../../../common';
import { Loader } from '../../../common/components/layout/loader.component';
import { appUrls } from '../../../common/config/url.constants';
import { CarFreeZonesActions } from '../../../common/store/car-free-zones/carFreeZones.actions';
import { getIsAuthorized } from '../../../common/store/user/user.selectors';
import { Auth } from '../../../components';
import { RequestActions } from '../../../store/actions';
import { getIsOnlyRoleUtilities, getRequestNotes, selectRequestDetailById } from '../../../store/selectors';
import { IRequestAclActions, RequestReason } from '../../../types';
import { AttachmentsPanel } from './AttachmentsPanel.component';
import { ContactInfo } from './contact-info.component';
import { Header } from './header/header.component';
import { HistoryContainer } from './history/history.container';
import { Info } from './info.component';
import { RequestInternalNotes } from './internal-notes.component';
import { Locations } from './Locations.container';
import { MessagesPanel } from './MessagesPanel.component';
import { Payments } from './payments.container';

const useStyles = makeStyles({
  container: {
    marginTop: 20,
  },
});

export const RequestPage: FunctionComponent = () => {
  const dispatch = useDispatch();
  const C = useStyles();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const request = useSelector(selectRequestDetailById)[id];
  const isOnlyRoleUtilities = useSelector(getIsOnlyRoleUtilities);
  const notes = useSelector(getRequestNotes(id));
  const canEditAssignedRequest = useSelector(getIsAuthorized([IRequestAclActions.editAssignedRequest]));
  const canViewRequest = useSelector(getIsAuthorized([IRequestAclActions.viewRequest]));
  const isReadOnly = canViewRequest && !canEditAssignedRequest;

  useEffect(() => {
    if (canViewRequest) {
      dispatch(RequestActions.fetch(parseInt(id, 10)));
      dispatch(RequestActions.fetchConflicts(id));
      dispatch(WorkOrderItemsActions.fetchTypes());
      dispatch(CarFreeZonesActions.fetchAll());
    }
  }, [dispatch, id, canViewRequest]);

  useEffect(() => {
    if (!isReadOnly && canViewRequest) {
      dispatch(WorkOrderItemsActions.fetchCreatableTypes());
    }
  }, [dispatch, isReadOnly, canViewRequest]);

  const patchPriority = useCallback(
    (priority: number) => {
      dispatch(RequestActions.patchPriority({ id: parseInt(id, 10), priority }));
    },
    [dispatch, id],
  );

  const patchCluster = useCallback(
    (cluster: string) => {
      dispatch(RequestActions.patchCluster({ id: parseInt(id, 10), cluster }));
    },
    [dispatch, id],
  );

  const onApproveRequest = useCallback(
    (reasonForApproval: string | null) => {
      dispatch(RequestActions.approve({ id, reasonForApproval }));
    },
    [dispatch, id],
  );

  const onRejectRequest = useCallback(
    (reasonForRejection: string | null) => {
      dispatch(RequestActions.reject({ id, reasonForRejection }));
    },
    [dispatch, id],
  );

  const onReviewRequest = useCallback(() => {
    dispatch(RequestActions.review(id));
  }, [dispatch, id]);

  const onCancelRequest = useCallback(() => {
    dispatch(RequestActions.cancel(id));
  }, [dispatch, id]);

  const onCopyRequest = useCallback(() => {
    dispatch(RequestActions.setCopyRequest(request));
    history.push(appUrls.requests.create);
  }, [dispatch, history, request]);

  const onPatchInternalNotes = useCallback(
    (backofficeNotes: string, shouldAppend: boolean) => {
      dispatch(RequestActions.patchInternalNotes({ id, backofficeNotes, shouldAppend }));
    },
    [dispatch, id],
  );

  if (isOnlyRoleUtilities && request && !(request.reason.reason === RequestReason.utility_request)) {
    history.push(appUrls.base);
  }

  return (
    <Auth acl={IRequestAclActions.viewRequest} showUnauthorizedPage>
      <Loader loading={!request}>
        <Header
          onApproveRequest={onApproveRequest}
          onCancelRequest={onCancelRequest}
          onCopyRequest={onCopyRequest}
          onRejectRequest={onRejectRequest}
          onReviewRequest={onReviewRequest}
          requestId={id}
        />
        <Container className={C.container}>
          <Grid container justify="center" spacing={4}>
            <Grid item xs={6} data-testid="leftColumn">
              <Info requestId={id} patchPriority={patchPriority} patchCluster={patchCluster} />
              <Payments requestId={id} />
              <RequestInternalNotes
                visible={!request?.sgwRequestId}
                notes={notes}
                onPatchInternalNotes={onPatchInternalNotes}
                checkAclAction={IRequestAclActions.requestAddInternalNote}
              />
              <ContactInfo requestId={id} />
              <MessagesPanel visible={!isOnlyRoleUtilities} requestId={id} />
              <AttachmentsPanel requestId={id} />
              <HistoryContainer requestId={id} />
            </Grid>
            {request?.publicDomainIntakes?.length ? (
              <Grid item xs={6} data-testid="rightColumn">
                <Locations requestId={id} />
              </Grid>
            ) : null}
          </Grid>
        </Container>
      </Loader>
    </Auth>
  );
};
