import { ISgwQuarter, ISgwRequestAttachment } from '../../types';
import moment from 'moment';

export const stringQuarter = ({ year, index }: ISgwQuarter) => `${year}${index}`;

export const stringBeQuarter = ({ quarterIndex, quarterYear }: ISgwRequestAttachment) =>
  quarterYear && quarterIndex && `${quarterYear}${quarterIndex}`;

export const parseQuarter = (q: string) =>
  (q.substring(4) &&
    q.substring(0, 4) && {
      quarterYear: parseInt(q.substring(0, 4)),
      quarterIndex: parseInt(q.substring(4, 5)),
    }) ||
  {};

export const getDatesOfQuarter = (year: number, index: number) =>
  `${moment([year]).quarter(index).format('DD/MM/YYYY')} - ${moment([year])
    .quarter(index)
    .endOf('quarter')
    .format('DD/MM/YYYY')}`;
