import { ICarFreeZoneFeature, IMapLayer, MapLayerTypes } from '../../types';
import { CONFIG } from './config';

export const BASE_MAP_BASE_LAYERS: IMapLayer[] = [
  { id: 1, name: 'base', type: MapLayerTypes.BASE },
  { id: 2, name: 'baseCity', url: CONFIG.baseMapUrl, type: MapLayerTypes.TILED },
  { id: 3, name: 'arcGisTopographic', type: MapLayerTypes.BASE_MAP, baseMap: 'Topographic' },
  { id: 4, name: 'arcGisGray', type: MapLayerTypes.BASE_MAP, baseMap: 'Gray' },
  { id: 5, name: 'satellite', type: MapLayerTypes.SATELLITE },
  {
    id: 6,
    name: 'openStreetMap',
    type: MapLayerTypes.OPEN_STREET_MAP,
    url: CONFIG.openStreetMapUrl,
  },
];

export const BASE_MAP_OVERLAY_LAYERS: IMapLayer[] = [
  { id: 6, name: 'busLine', url: CONFIG.arcgisUrl, layer: 5, type: MapLayerTypes.DYNAMIC },
  { id: 7, name: 'busStop', url: CONFIG.arcgisUrl, layer: 3, type: MapLayerTypes.DYNAMIC },
  { id: 8, name: 'tramLine', url: CONFIG.arcgisUrl, layer: 6, type: MapLayerTypes.DYNAMIC },
  { id: 9, name: 'tramStop', url: CONFIG.arcgisUrl, layer: 4, type: MapLayerTypes.DYNAMIC },
  {
    id: 10,
    name: 'carFreeZones.layer',
    onEachFeature: (feature: ICarFreeZoneFeature, layer: any) => {
      const { HANDHAVING, VENSTERTIJD, ZONE } = feature.properties;
      layer.bindPopup(`
        <div>${HANDHAVING} ${ZONE}</div>
        <div>${VENSTERTIJD}</div>
      `);
    },
    styles: {
      color: 'blue',
      fillColor: 'blue',
      opacity: 0.8,
      weight: '2',
    },
    type: MapLayerTypes.FEATURE,
    url: `${CONFIG.arcgisUrl}/17`,
  },
  { id: 11, name: 'trafficDirection', url: CONFIG.arcgisUrl, layer: 2, type: MapLayerTypes.DYNAMIC },
  { id: 12, name: 'cateringCore', url: CONFIG.arcgisUrl, layer: 7, type: MapLayerTypes.DYNAMIC },
  { id: 13, name: 'shoppingArea', url: CONFIG.arcgisUrl, layer: 8, type: MapLayerTypes.DYNAMIC },
  { id: 14, name: 'neighborhoodParking', url: CONFIG.arcgisUrl, layer: 9, type: MapLayerTypes.DYNAMIC },
  { id: 15, name: 'education', url: CONFIG.arcgisUrl, layer: 11, type: MapLayerTypes.DYNAMIC },
  { id: 16, name: 'educationCampus', url: CONFIG.arcgisUrl, layer: 10, type: MapLayerTypes.DYNAMIC },
  { id: 17, name: 'disabledParking', url: CONFIG.arcgisUrl, layer: 14, type: MapLayerTypes.DYNAMIC },
  { id: 18, name: 'chargingSpot', url: CONFIG.arcgisUrl, layer: 12, type: MapLayerTypes.DYNAMIC },
  { id: 19, name: 'taxiSpot', url: CONFIG.arcgisUrl, layer: 13, type: MapLayerTypes.DYNAMIC },
];

export const getSelectedCarFreeZoneLayer = (gisId: string): IMapLayer => ({
  id: 10,
  name: 'carFreeZones.layer',
  onEachFeature: (feature: ICarFreeZoneFeature, layer: any) => {
    const { GISID_GEBIED, HANDHAVING, VENSTERTIJD, ZONE } = feature.properties;

    layer.bindPopup(`
        <div>${HANDHAVING} ${ZONE}</div>
        <div>${VENSTERTIJD}</div>
      `);
    const color = gisId === GISID_GEBIED ? 'green' : 'blue';
    layer.setStyle({ color, fillColor: color });
  },
  styles: {
    opacity: 0.8,
    weight: '2',
  },
  type: MapLayerTypes.FEATURE,
  url: `${CONFIG.arcgisUrl}/17`,
});

export const SGW_BASE_MAP_OVERLAY_LAYERS: IMapLayer[] = [
  { id: 6, name: 'constructionZone', url: CONFIG.arcgisUrl, layer: 48, type: MapLayerTypes.DYNAMIC },
  { id: 7, name: 'parkingBanLine', url: CONFIG.arcgisUrl, layer: 20, type: MapLayerTypes.DYNAMIC },
  { id: 8, name: 'parkingBanZone', url: CONFIG.arcgisUrl, layer: 21, type: MapLayerTypes.DYNAMIC },
  { id: 9, name: 'eventsLine', url: CONFIG.arcgisUrl, layer: 23, type: MapLayerTypes.DYNAMIC },
  { id: 10, name: 'eventsZone', url: CONFIG.arcgisUrl, layer: 22, type: MapLayerTypes.DYNAMIC },
  { id: 11, name: 'eventsAgev', url: CONFIG.arcgisUrl, layer: 18, type: MapLayerTypes.DYNAMIC },
  { id: 12, name: 'busLine', url: CONFIG.arcgisUrl, layer: 5, type: MapLayerTypes.DYNAMIC },
  { id: 13, name: 'busStop', url: CONFIG.arcgisUrl, layer: 3, type: MapLayerTypes.DYNAMIC },
  { id: 14, name: 'tramLine', url: CONFIG.arcgisUrl, layer: 6, type: MapLayerTypes.DYNAMIC },
  { id: 15, name: 'tramStop', url: CONFIG.arcgisUrl, layer: 4, type: MapLayerTypes.DYNAMIC },
  { id: 16, name: 'portArea', url: CONFIG.arcgisUrl, layer: 24, type: MapLayerTypes.DYNAMIC },
  { id: 17, name: 'tree', url: CONFIG.arcgisUrl, layer: 25, type: MapLayerTypes.DYNAMIC },
  { id: 18, name: 'park', url: CONFIG.arcgisUrl, layer: 26, type: MapLayerTypes.DYNAMIC },
  { id: 19, name: 'wasteDisposalAboveGround', url: CONFIG.arcgisUrl, layer: 27, type: MapLayerTypes.DYNAMIC },
  { id: 20, name: 'wasteDisposalUnderGround', url: CONFIG.arcgisUrl, layer: 28, type: MapLayerTypes.DYNAMIC },
  { id: 21, name: 'glassContainer', url: CONFIG.arcgisUrl, layer: 29, type: MapLayerTypes.DYNAMIC },
  { id: 22, name: 'antiParkingPost', url: CONFIG.arcgisUrl, layer: 30, type: MapLayerTypes.DYNAMIC },
  { id: 23, name: 'bench', url: CONFIG.arcgisUrl, layer: 31, type: MapLayerTypes.DYNAMIC },
  { id: 24, name: 'veloStation', url: CONFIG.arcgisUrl, layer: 32, type: MapLayerTypes.DYNAMIC },
  { id: 25, name: 'bicycleParkingArea', url: CONFIG.arcgisUrl, layer: 33, type: MapLayerTypes.DYNAMIC },
  { id: 26, name: 'bicycleShed', url: CONFIG.arcgisUrl, layer: 34, type: MapLayerTypes.DYNAMIC },
  { id: 27, name: 'neighbourhoodBicycleShed', url: CONFIG.arcgisUrl, layer: 36, type: MapLayerTypes.DYNAMIC },
  { id: 28, name: 'disabledParking', url: CONFIG.arcgisUrl, layer: 14, type: MapLayerTypes.DYNAMIC },
  { id: 29, name: 'carSharing', url: CONFIG.arcgisUrl, layer: 37, type: MapLayerTypes.DYNAMIC },
  { id: 30, name: 'cambio', url: CONFIG.arcgisUrl, layer: 38, type: MapLayerTypes.DYNAMIC },
  { id: 31, name: 'chargingSpot', url: CONFIG.arcgisUrl, layer: 12, type: MapLayerTypes.DYNAMIC },
  { id: 32, name: 'fixedLoadingZone', url: CONFIG.arcgisUrl, layer: 39, type: MapLayerTypes.DYNAMIC },
  { id: 33, name: 'timedLoadingZone', url: CONFIG.arcgisUrl, layer: 40, type: MapLayerTypes.DYNAMIC },
  { id: 34, name: 'taxiSpot', url: CONFIG.arcgisUrl, layer: 13, type: MapLayerTypes.DYNAMIC },
  { id: 35, name: 'trafficLightCity', url: CONFIG.arcgisUrl, layer: 41, type: MapLayerTypes.DYNAMIC },
  { id: 36, name: 'railway', url: CONFIG.arcgisUrl, layer: 43, type: MapLayerTypes.DYNAMIC },
  { id: 37, name: 'levelCrossing', url: CONFIG.arcgisUrl, layer: 44, type: MapLayerTypes.DYNAMIC },
  { id: 38, name: 'monument', url: CONFIG.arcgisUrl, layer: 45, type: MapLayerTypes.DYNAMIC },
  { id: 39, name: 'lowEmissionZone', url: CONFIG.arcgisUrl, layer: 42, type: MapLayerTypes.DYNAMIC },
  { id: 40, name: 'trafficSign', url: CONFIG.arcgisUrl, layer: 46, type: MapLayerTypes.DYNAMIC },
  { id: 41, name: 'detour', url: CONFIG.arcgisUrl, layer: 47, type: MapLayerTypes.DYNAMIC },
];

export const BaseMapDefaultLayers = {
  baseLayer: 1,
  overlayLayers: [8, 10],
};

export const REQUEST_TIME_OVERLAYS = [20, 21];
export const PHASE_TIME_OVERLAYS = [18, 19, 22, 23, 47, 48];
