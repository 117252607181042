import { Grid } from '@material-ui/core';
import * as React from 'react';
import { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Container } from '../../../../common';
import { Loader } from '../../../../common/components/layout/loader.component';
import { Visible } from '../../../../common/components/layout/Visible.component';
import { appUrls } from '../../../../common/config/url.constants';
import { UserActions } from '../../../../common/store/user/user.actions';
import { selectUserById } from '../../../../common/store/user/user.selectors';
import { translate } from '../../../../common/translations/translate';
import { useGeneralStyles } from '../../../../style/generalStyles';
import { IUserManagementAclActions } from '../../../../types';
import { Auth } from '../../../atoms';
import { UserForm } from '../../../forms/UserForm/UserForm.component';
import { SgwRequestList, UserDetailHeader, UserInfo } from '../../../molecules';
import { UserRequestOverview } from '../../../organisms';

interface IProps {
  isEditMode?: boolean;
}

export const DetailUserPage: FunctionComponent<IProps> = ({ isEditMode = false }) => {
  const G = useGeneralStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const user = useSelector(selectUserById(id));
  const requiredAcl = isEditMode ? IUserManagementAclActions.editUser : IUserManagementAclActions.viewUser;

  useEffect(() => {
    id && dispatch(UserActions.fetchUserById(`${id}`));
  }, [dispatch, id]);

  useEffect(() => {
    isEditMode && user?.deleted && history.push(appUrls.users.detail(id));
  }, [isEditMode, history, user?.deleted, id]);

  if (!user) {
    return <Loader loading />;
  }

  return (
    <Auth acl={requiredAcl} showUnauthorizedPage>
      <UserDetailHeader userFullName={user.fullName} />
      <Container className={G.marginTop20}>
        <Grid item xs={6}>
          <Visible visible={isEditMode} defaultComponent={<UserInfo user={user} />}>
            <UserForm title={translate('users.edit.title')} />
          </Visible>
        </Grid>
        <div className={G.marginTop20}>
          <UserRequestOverview userRequested={user.id} />
        </div>

        <div className={G.marginTop20}>
          <SgwRequestList userRequested={user.id} />
        </div>
      </Container>
    </Auth>
  );
};
