import { IRootState } from '../../root.state';
import { createAllEntitiesSelector } from '../../common/utils/normalized.util';
import { createPagedApiParamsSelector } from '../../common/store/utils/generic.selectors';
import { createSelector } from '@reduxjs/toolkit';
import * as I18n from 'i18n-js';
import { ModulePredefinedFilter } from '../types/modules-filter';

export const selectModulesState = (store: IRootState) => store.modules;
export const selectModulesStates = (store: IRootState) => store.modules.states;
export const selectModulesPlaces = (store: IRootState) => store.modules.places;
export const selectModulesAppVersions = (store: IRootState) => store.modules.appVersions;
export const selectModulesBatches = (store: IRootState) => store.modules.batches;
export const selectModulesBatchesUsed = (store: IRootState) => store.modules.batches.batchesUsed;
export const selectModulesCounter = (store: IRootState) => store.modules.moduleCounters;

const selectAllIds = (store: IRootState) => store.modules.list.allIds;
const selectById = (store: IRootState) => store.modules.list.byId;
export const selectModulesTable = (store: IRootState) => store.modules.list.table;
export const getModulesList = createAllEntitiesSelector(selectAllIds, selectById);
export const getModulesPagedApiParams = createPagedApiParamsSelector(selectModulesTable);
export const getCurrentBatch = createSelector([selectModulesBatches], ({ batches }) =>
  batches.find((batch) => !!batch.current),
);

export const getModuleFilterTiles = createSelector(
  [selectModulesBatches, selectModulesCounter, getCurrentBatch],
  ({ batches }, counters, currentBatch) => {
    const tiles = batches
      .filter((batch) => counters.batches?.[batch.id] !== undefined)
      .map((batch) => ({
        disable: currentBatch && currentBatch?.id > batch.id,
        subtitle: batch.name,
        title: `${(counters.batches && counters.batches[batch.id]) || 0}`,
        value: batch.id as any,
      }));
    tiles.splice(3, 0, {
      disable: false,
      subtitle: I18n.t('Critical'),
      title: `${counters.critical}`,
      value: ModulePredefinedFilter.CRITICAL,
    });

    tiles.splice(7, 0, {
      disable: false,
      subtitle: I18n.t('InMaintenance'),
      title: `${counters.inMaintenance}`,
      value: ModulePredefinedFilter.IN_MAINTENANCE,
    });
    return tiles;
  },
);
