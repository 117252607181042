import { useForm } from 'react-hook-form';
import { IRouterWithId, ISgwTrafficMeasure } from '../../types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getSgwTrafficMeasure } from '../../store/selectors/sgwTrafficMeasures.selectors';
import { SgwTrafficMeasuresActions } from '../../store/actions';

export const useSgwTrafficMeasureForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams<IRouterWithId>();
  const initialValues = useSelector(getSgwTrafficMeasure(id));

  const formMethods = useForm<ISgwTrafficMeasure>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: initialValues ? { ...initialValues } : { existsInGipod: true },
  });

  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    dispatch(SgwTrafficMeasuresActions.list.fetch({}));
  }, [dispatch]);

  useEffect(() => {
    reset(initialValues);
  }, [reset, initialValues]);

  const _onSubmit = (values: ISgwTrafficMeasure) => dispatch(SgwTrafficMeasuresActions.save(values));

  return { formMethods, submitTrafficMeasure: handleSubmit(_onSubmit), id };
};
