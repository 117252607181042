import { Card, CardActions, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import I18n from 'i18n-js';
import * as React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { DateFormat, IRequestAclActions, IRequestOverview } from '../../../types';
import moment from 'moment';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { REQUEST_FORM_DEFAULT_VALUES } from './request-form.management';
import { ModalButton } from '../../../common/components/buttons/modal-button';
import { Auth } from '../../../components';

interface IProps {
  onEdit: () => void;
  onDelete: () => void;
  draft: IRequestOverview;
}

const useStyles = makeStyles({
  root: {
    margin: '15px',
    background: '#fafafa',
    minWidth: '30%',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export const DraftComponent: React.FunctionComponent<IProps> = ({ draft, onEdit, onDelete }) => {
  const C = useStyles();

  return (
    <Auth acl={IRequestAclActions.editRequestDraft}>
      <Card className={C.root}>
        <CardContent>
          <div className={C.header}>
            <Typography style={{ fontWeight: 400, fontSize: '1.2em' }}>
              {`${moment(draft.dateFrom).format(DateFormat.date)} - ${moment(draft.dateUntil).format(DateFormat.date)}`}
            </Typography>
            <ModalButton.Close
              onConfirm={onDelete}
              visible
              title={I18n.t('Requests.Create.Drafts.RemoveRequest')}
              buttonTitle={I18n.t('Requests.Create.Drafts.RemoveRequest')}
              tooltip={I18n.t('Requests.Create.Drafts.Remove')}
              needsReason={false}
              modalBody={I18n.t('Requests.Create.Drafts.AreYouSure')}
            />
          </div>
          {`${draft.timeFrom || REQUEST_FORM_DEFAULT_VALUES.timeFrom} - ${
            draft.timeUntil || REQUEST_FORM_DEFAULT_VALUES.timeUntil
          }`}
        </CardContent>
        <CardActions className={C.actions}>
          <Button.Green onClick={onEdit} startIcon={<EditIcon fontSize="small" />}>
            {I18n.t('Requests.Create.Drafts.Finish')}
          </Button.Green>
        </CardActions>
      </Card>
    </Auth>
  );
};
