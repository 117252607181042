import { generateBooleanReducer } from '../../..';
import { CarFreeZonesActions } from '../carFreeZones.actions';
import { combineReducers } from 'redux';
import { createPagedTableReducer } from '../../../../store/reducers/pagedTable.reducer';
import { IGate, IGatesFilter } from '../../../../types';
import { carFreeZonesGatesInitialParams } from '../../../../carFreeZone/CarFreeZone.constants';

export default combineReducers({
  isSyncing: generateBooleanReducer([CarFreeZonesActions.gates.isSyncing.type]),
  list: createPagedTableReducer<IGate, IGatesFilter>({
    fetchAction: CarFreeZonesActions.gates.list.fetch.type,
    addEntitiesActions: [CarFreeZonesActions.gates.list.set.type],
    setAllIdsAction: CarFreeZonesActions.gates.list.set.type,
    setParamsActions: [CarFreeZonesActions.gates.list.fetch.type, CarFreeZonesActions.gates.list.setParams.type],
    initialParamsState: carFreeZonesGatesInitialParams,
  }),
});
