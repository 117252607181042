import { createReducer } from '@reduxjs/toolkit';
import { IById } from '../../../../common/utils/normalized.util';
import { IRequest } from '../../../../types';
import { RequestActions } from '../../../actions';

type TEntity = IRequest;
type TState = IById<TEntity>;

export default createReducer<TState>(
  {},
  {
    [RequestActions.set.type]: (state: TState, { payload }: ReturnType<typeof RequestActions.set>): TState =>
      payload ? { ...state, [payload.id]: payload } : state,
    [RequestActions.patchPriority.type]: (
      state: TState,
      { payload }: ReturnType<typeof RequestActions.patchPriority>,
    ): TState => ({
      ...state,
      [payload.id]: {
        ...state[payload.id],
        priority: {
          ...state[payload.id].priority,
          priority: payload.priority,
        },
      },
    }),
  },
);
