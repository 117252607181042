export enum WORK_ORDER_ITEM_TYPES {
  PICK_UP = 'pick_up',
  PICKUP_OVERDUE = 'pickup_overdue',
  DROP_OFF = 'drop_off',
  UPDATE = 'update',
  REPLACE = 'replace',
  NOTIFICATION = 'notification',
  RETRIEVE = 'retrieve',
}

export enum WORK_ORDER_ITEM_PRIORITIES {
  NORMAL = 1,
  HIGH = 2,
}

export enum WORK_ORDER_ITEM_STATES {
  open = 'open',
  done = 'done',
}
