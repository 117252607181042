import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import * as React from 'react';

export const ReorderIcon: React.FunctionComponent = () => {
  return (
    <>
      <ArrowDropUp style={{ marginBottom: -9 }} />
      <ArrowDropDown style={{ marginTop: -9 }} />
    </>
  );
};
