import { IParkingBanFeature } from '../types/parking-ban-feature';

export interface ICpaState {
  parkingBans: IParkingBanFeature[];
  parkingBansLoading: boolean;
}

export const initialCpaState: ICpaState = {
  parkingBans: [],
  parkingBansLoading: true,
};
