import { createPagedApiParamsSelector } from '../../common/store/utils/generic.selectors';
import { createAllEntitiesSelector } from '../../common/utils/normalized.util';
import { IRootState } from '../../root.state';
import { createSelector } from '@reduxjs/toolkit';

export const selectUtilityCompanies = (store: IRootState) => store.utilityCompanies;
export const selectAllIds = (store: IRootState) => store.utilityCompanies.list.allIds;
export const selectById = (store: IRootState) => store.utilityCompanies.list.byId;
export const selectTable = (store: IRootState) => store.utilityCompanies.list.table;
export const selectListLoading = (store: IRootState) => store.utilityCompanies.list.loading;
export const getUtilityCompany = (id?: string) =>
  createSelector([selectById], (selectById) => (id ? selectById?.[id] : undefined));
export const selectLoading = (store: IRootState) => store.utilityCompanies.loading;

export const getUtilityCompaniesList = createAllEntitiesSelector(selectAllIds, selectById);
export const getPagedApiParams = createPagedApiParamsSelector(selectTable);
