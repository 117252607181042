import { LatLngExpression } from 'leaflet';
import * as L from 'leaflet';
import { MapContainer } from 'react-leaflet';
import { IMapLocation } from '../../common';
import { MapSearchControl } from '../../common/components/map/map-search-control.component';
import { IMapLayer, IWorkOrderItem } from '../../types';
import { LeafletDrawTranslation } from './leafletDrawTranslation';
import { WorkOrderItemMarkers } from './work-order-item-markers.component';
import React, { FC, useEffect } from 'react';
import { Loader } from '../../common/components/layout/loader.component';
import { BaseMapProvider } from '../../common/components/map/BaseMapProvider.component';
import { useBaseMap } from '../../common/components/map/BaseMap.context';
import { MapLayersControl } from '../../common/components/map/MapLayersControl.component';

interface IProps {
  deleteSign(woi: IWorkOrderItem): void;
  mapLocation: IMapLocation;
  onWorkOrderItemsAssign(workOrderItems: IWorkOrderItem[]): void;
  searchStreet(street: string): void;
  workOrderItems: IWorkOrderItem[];
  workOrderItemsLoading: boolean;
  overlayLayers?: IMapLayer[];
  selectedOverlayLayers?: number[];
}

export const PlanningMap: FC<IProps> = ({ overlayLayers, selectedOverlayLayers, ...props }) => (
  <BaseMapProvider defaultOverlayLayers={overlayLayers} defaultSelectedOverlayLayers={selectedOverlayLayers}>
    <PlanningMapWithContext {...props} />
  </BaseMapProvider>
);

const PlanningMapWithContext: FC<IProps> = ({
  deleteSign,
  mapLocation,
  onWorkOrderItemsAssign,
  searchStreet,
  workOrderItems,
  workOrderItemsLoading,
}) => {
  const { mapRef, selectBaseLayer, addOverlay, removeOverlay } = useBaseMap();

  mapRef?.current?.on('baselayerchange', (e) => selectBaseLayer(e as L.LayersControlEvent));
  mapRef?.current?.on('overlayadd', (e) => addOverlay(e as L.LayersControlEvent));
  mapRef?.current?.on('overlayremove', (e) => removeOverlay(e as L.LayersControlEvent));

  useEffect(() => {
    LeafletDrawTranslation();
  }, []);

  useEffect(() => {
    if (mapRef?.current) {
      mapRef.current.invalidateSize(false);
      // AS-3921 Force update of coordinates and zoom after search applied
      mapRef.current.setView(mapLocation.center as LatLngExpression, mapLocation.zoom);
    }
  }, [mapLocation, mapRef]);

  return (
    <MapContainer
      ref={mapRef}
      zoomAnimation
      center={mapLocation.center}
      style={{ height: '100%' }}
      zoom={mapLocation.zoom}
      data-testid="PlanningMap"
    >
      <MapSearchControl searchStreet={searchStreet} />
      <MapLayersControl />

      <Loader
        loading={workOrderItemsLoading}
        style={{
          backgroundColor: 'rgba(255, 255, 255, .5)',
          bottom: 0,
          left: 0,
          position: 'absolute',
          right: 0,
          top: 0,
          paddingTop: '50%',
          zIndex: 1000,
        }}
      >
        <WorkOrderItemMarkers
          deleteSign={deleteSign}
          workOrderItems={workOrderItems}
          onWorkOrderItemsAssign={onWorkOrderItemsAssign}
        />
      </Loader>
    </MapContainer>
  );
};
