import axios from 'axios';
import { CONFIG, IApiPromise } from '../../common';
import { IReorderingWorkOrder } from '../types/reordering-work-order';

export class ReorderingApi {
  public static getReorderingWorkOrder(workOrderId: number): IApiPromise<IReorderingWorkOrder> {
    const source = axios.CancelToken.source();
    return axios.get(`${CONFIG.backOfficeApi}/v1/work_orders/${workOrderId}`, {
      cancelToken: source.token,
    });
  }

  public static saveReorderingWorkOrder(workOrder: IReorderingWorkOrder): IApiPromise<IReorderingWorkOrder> {
    const source = axios.CancelToken.source();

    return axios.post(`${CONFIG.backOfficeApi}/v1/work_orders/${workOrder.id}`, {
      cancelToken: source.token,
      workOrderItemsOrder: workOrder.workOrderItems.map((woi) => ({
        checked: woi.checked,
        id: woi.id,
        sequence: woi.sequence,
      })),
    });
  }
}
