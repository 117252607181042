import { useDispatch, useSelector } from 'react-redux';
import { getSgwProjectsList, selectLoading, selectSgwProjectsTable } from '../store/selectors/sgwProjects.selectors';
import { useEffect } from 'react';
import { SgwProjectsActions } from '../store/actions';
import { allPaging, initialPaging } from '../common/config/paging.constants';

export const useProjects = (fetchAll = false) => {
  const projects = useSelector(getSgwProjectsList);
  const table = useSelector(selectSgwProjectsTable);
  const loading = useSelector(selectLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      SgwProjectsActions.list.fetch({
        paging: fetchAll ? allPaging : initialPaging,
      }),
    );
  }, [dispatch, fetchAll]);

  return { projects, table, loading };
};
