import { createUseStyles } from 'react-jss';

export const useFormStyles = createUseStyles({
  container: {
    width: '45%',
    margin: '3rem auto',
  },
  grid: { marginTop: 20, marginBottom: 20 },
  createButton: {
    marginBottom: 18,
    marginTop: 20,
  },
  formGrid: {
    padding: '20px',
  },
  checkbox: {
    marginLeft: '-12px',
  },
  bottomButtons: {
    textAlign: 'end',
  },
});
