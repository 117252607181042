import { combineReducers } from 'redux';
import { generateObjectReducer } from '../../../common';
import { IAppVersions, IGeneralMetadata } from '../../../types';
import { MetadataActions } from '../../actions/metadata.actions';
import { initialMetadataState } from '../../state/metadata.state';

export const metadataReducer = combineReducers({
  general: generateObjectReducer<IGeneralMetadata>(MetadataActions.set.type, initialMetadataState),
  appVersions: generateObjectReducer<IAppVersions>(MetadataActions.setAppVersions.type, {}),
});
