import moment from 'moment';
import { ISortingDirection } from '../../common';
import { NOTIFICATION_SOURCE_CIVILIAN } from '../notification.constants';
import { INotification, INotificationHistoryItem, INotificationsFilter } from '../types/notification';
import { IPagedTableState } from '../../types';

export interface INotificationState {
  count: number;
  list: IPagedTableState<INotification, INotificationsFilter>;
  notification: INotification | null;
  notificationHistory: INotificationHistoryItem[];
  notificationsError: string | null;
  loading: boolean;
}

export const initialNotificationsState: INotificationState = {
  notification: null,
  count: 0,
  notificationHistory: [],
  notificationsError: null,
  list: {
    allIds: [],
    byId: {},
    table: {
      filters: {
        from_date: moment().subtract(6, 'months').format('YYYY-MM-DD'),
        q: '',
        source: [NOTIFICATION_SOURCE_CIVILIAN],
        states: [],
        until_date: moment().add(1, 'day').format('YYYY-MM-DD'),
      },
      sorting: {
        direction: ISortingDirection.desc,
        key: 'id',
      },
      paging: {
        page: 1,
        pageSize: 20,
        totalPages: 0,
        totalRecords: 0,
      },
    },
    loading: true,
  },
  loading: true,
};
