import { makeStyles } from '@material-ui/core/styles';
import { FunctionComponent, default as React } from 'react';
import { COLORS } from '../..';

interface IProps {
  className?: string;
  text: string;
}

const useStyles = makeStyles({
  root: { color: COLORS.GREY_LIGHT, fontStyle: 'italic', fontSize: '0.875rem' },
});

export const Unavailable: FunctionComponent<IProps> = ({ className = '', text }) => {
  const C = useStyles();
  return <span className={`${className} ${C.root}`}>{text}</span>;
};
