import { IPincodeManagement, IService } from '../../../../types/services.types';

export const pincodeFormManagement = {
  getInitialValues: (service: IService): IPincodeManagement => ({
    pincodeAutoRenewPeriod: service.pincodeAutoRenewPeriod,
    pincodeAutoRenewValue: service.pincodeAutoRenewValue,
    pincodeFromDate: service.pincodeFromDate,
    pincodeUntilDate: service.pincodeUntilDate,
  }),
};
