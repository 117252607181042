import { Grid } from '@material-ui/core';
import { ArrowBack, WarningRounded } from '@material-ui/icons';
import * as React from 'react';
import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { appUrls } from '../../common/config/url.constants';
import { Auth } from '../../components';
import { IWorkOrderItemAclActions } from '../../types';
import { ReorderingList } from '../components/reordering-list';
import { ReorderingMap } from '../components/reordering-map';
import { fetchReorderingWorkOrder } from '../store/reordering.actions';

export const ReorderingPage: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{ id?: string }>();
  const editConflict = false; // Mock for future releases when we introduce sockets.

  useEffect(() => {
    if (params.id) {
      dispatch(fetchReorderingWorkOrder(parseInt(params.id, 0)));
    }
  });

  const gotToPlanningPage = () => history.push(appUrls.planning.base);

  return (
    <Auth acl={IWorkOrderItemAclActions.reorderWorkOrderItems} showUnauthorizedPage>
      <Grid
        alignContent="stretch"
        container={true}
        direction="column"
        spacing={0}
        wrap="nowrap"
        style={{ height: '100vh', marginBottom: -20 }}
      >
        <Grid container={true} className="reordering-header">
          <Grid>
            {/*// TODO: Use correct href in link*/}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
            <a className="reordering-header-back" onClick={gotToPlanningPage}>
              <ArrowBack />
            </a>
            <div className="reordering-header-title">Ordening modus</div>
            {editConflict && (
              <div className="reordering-header-warning">
                <span style={{ color: '#d10a20' }}>
                  <WarningRounded style={{ marginBottom: -4 }} /> <strong>Opgelet:</strong>
                </span>
                &nbsp;&nbsp; Deze ordening wordt reeds uitgevoerd door andere gebruiker.
              </div>
            )}
          </Grid>
        </Grid>

        <Grid item={true} className={'planning-grid'}>
          <Grid container={true} alignContent="stretch" style={{ height: '100%' }}>
            <Grid item={true} sm={8} lg={9}>
              <ReorderingMap />
            </Grid>
            <Grid item={true} sm={4} lg={3} className={'planning-tours-grid'}>
              <ReorderingList />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Auth>
  );
};
