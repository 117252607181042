import { Theme, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 700,
    border: '1px solid #dadde9',
    opacity: '1 !important',
  },
}))(Tooltip);
