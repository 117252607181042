import { Actions, TableActions } from '../..';
import { IRole, IUpdatePassword, IUser, IUserFilter, IUserRequestFilter } from '../../types/user';
import { IRequestOverview } from '../../../types';

class UserActionsClass extends Actions {
  list = new TableActions<IUser, IUserFilter>(`${this.prefix}.list`);
  requestList = new TableActions<IRequestOverview, IUserRequestFilter>(`${this.prefix}.requestList`);
  fetchAdminList = this.createAction('fetchAdminList');
  fetchListByName = this.createPayloadAction<string>('fetchListByName');
  fetchAllRoles = this.createAction('fetchAllRoles');
  setAllRoles = this.createPayloadAction<IRole[]>('setAllRoles');
  setAdminList = this.createPayloadAction<IUser[]>('setAdminList');
  fetch = this.createPayloadAction<boolean>('fetch');
  save = this.createPayloadAction<IUser>('save');
  set = this.createPayloadAction<IUser | undefined>('set');
  setSelected = this.createPayloadAction<string | null>('setSelected');
  fetchUserById = this.createPayloadAction<string>('fetchUserById');
  setUser = this.createPayloadAction<IUser | undefined>('setUser');
  deactivateUser = this.createPayloadAction<{ deactivate: boolean; id: number }>('deactivateUser');
  resetPassword = this.createPayloadAction<number>('resetPassword');
  updatePassword = this.createPayloadAction<IUpdatePassword>('updatePassword');
}

export const UserActions = new UserActionsClass('user');
