import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';
import * as React from 'react';
import { FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import { AppBar, Grid, IconButton, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Visible } from './Visible.component';
import { IWithClassName } from '../../../types';
import classNames from 'classnames';
import { Container } from './container.component';
import { COLORS } from '../../config/theme';

const useStyles = makeStyles({
  appBar: {
    alignItems: 'center',
    backgroundColor: COLORS.WHITE,
    display: 'flex',
    flexDirection: 'row',
    height: 100,
    justifyContent: 'space-around',
    marginBottom: 20,
  },
  backButton: { marginRight: 20 },
  container: { width: '100%' },
  header: { lineHeight: '2em' },
  headerContainer: { display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' },
  headerSpaceBetween: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' },
});

export interface IHeaderProps extends IWithClassName {
  backButtonUrl?: string;
  extraHeader?: ReactNode;
  subText?: ReactNode;
  containerClassName?: string;
}

export const Header: FunctionComponent<PropsWithChildren<IHeaderProps>> = ({
  backButtonUrl,
  children,
  className,
  extraHeader,
  subText,
  containerClassName,
}) => {
  const C = useStyles();

  return (
    <AppBar position="static" color="default" className={classNames(C.appBar, className)} data-testid="Header">
      <Container className={classNames(C.container, containerClassName)}>
        <Grid container alignItems="center">
          <Grid item xs={1}>
            <Visible visible={!!backButtonUrl}>
              <Link to={backButtonUrl || ''} className={C.backButton} data-testid="LinkBackButton">
                <IconButton>
                  <ArrowBack />
                </IconButton>
              </Link>
            </Visible>
          </Grid>
          <Grid item xs={extraHeader ? 11 : 10}>
            <div className={extraHeader ? C.headerSpaceBetween : C.headerContainer}>
              <Typography variant="h5" className={C.header}>
                {children}
              </Typography>
              {extraHeader}
            </div>
            {subText}
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  );
};
