import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import * as React from 'react';
import { FC, useCallback } from 'react';
import { Popup } from 'react-leaflet';
import { Link } from 'react-router-dom';
import { FormattedDate } from '../../common';
import { Loader } from '../../common/components/layout/loader.component';
import { Visible } from '../../common/components/layout/Visible.component';
import { appUrls } from '../../common/config/url.constants';
import { translate } from '../../common/translations/translate';
import { Auth } from '../../components';
import { useAuthorization } from '../../hooks';
import { IMarkerPopup, IRequestAclActions, IWorkOrderItem, IWorkOrderItemAclActions } from '../../types';
import { PopupItem } from './PopupItem.component';
import { WorkOrderItemPopupHeader } from './WorkOrderItemPopupHeader.component';
import { WorkOrderItemType } from './WorkOrderItemType.component';

interface IProps {
  deleteSign: (workOrderItem: IWorkOrderItem) => void;
  markerPopup?: IMarkerPopup;
}

const useStyles = makeStyles({
  deleteOrder: { backgroundColor: 'red !important', color: 'white !important' },
  linkToRequest: {
    color: 'black !important',
  },
});

export const WorkOrderItemPopup: FC<IProps> = ({ deleteSign, markerPopup }) => {
  const C = useStyles();
  const { workOrderItem, request } = markerPopup || { workOrderItem: undefined, request: undefined };
  const { isAclUser, isAuthorized: canViewRequest } = useAuthorization(IRequestAclActions.viewRequest);
  const { isAuthorized: canEditWorkOrderItem } = useAuthorization(IWorkOrderItemAclActions.editWorkOrderItem);

  const showRequestButton = isAclUser ? canViewRequest : false;
  const showDeleteWorkOrderItemButton = isAclUser ? canEditWorkOrderItem : false;

  const onDeleteSignClick = useCallback(() => {
    workOrderItem && deleteSign(workOrderItem);
  }, [deleteSign, workOrderItem]);

  return (
    <Popup>
      {workOrderItem ? (
        <>
          <WorkOrderItemPopupHeader item={workOrderItem} />
          <PopupItem visible={!!workOrderItem.numberOfSigns} label={translate('NumberOfSigns')}>
            {workOrderItem.numberOfSigns}
          </PopupItem>
          <PopupItem visible={!!workOrderItem.parkingBanIntake} label={translate('DigitalSign')}>
            {workOrderItem.hasSmartSigns ? translate('yes') : translate('no')}
          </PopupItem>
          <WorkOrderItemType item={workOrderItem} request={request} />
          <Visible visible={!!workOrderItem.workOrder}>
            <PopupItem label={translate('AssignedTo')}>{workOrderItem.workOrder?.team.name}</PopupItem>
            <PopupItem label={translate('ToBeExecutedOn')}>
              {moment(workOrderItem.workOrder?.date).format('DD.MM.YYYY')}
            </PopupItem>
          </Visible>
          <Auth acl={IRequestAclActions.viewRequest}>
            <Visible visible={!!workOrderItem.parkingBanIntake && !!request?.id && showRequestButton}>
              <Button
                className={C.linkToRequest}
                href={appUrls.requests.detail(request?.id, true)}
                target="_parent"
                variant="contained"
              >
                {translate('ViewRequest')}
              </Button>
            </Visible>
          </Auth>
          <Auth acl={IWorkOrderItemAclActions.editWorkOrderItem}>
            <Visible
              visible={
                !!workOrderItem.sign &&
                !workOrderItem.parkingBanIntake &&
                workOrderItem.state !== 'done' &&
                showDeleteWorkOrderItemButton
              }
            >
              <Button variant="contained" onClick={onDeleteSignClick} className={C.deleteOrder}>
                {translate('DeleteOrder')}
              </Button>
            </Visible>
          </Auth>
          <PopupItem
            visible={!!workOrderItem?.civilianNotification?.createdAt}
            label={translate('Notifications.info.createdAt')}
          >
            <FormattedDate date={workOrderItem?.civilianNotification?.createdAt} />
          </PopupItem>
          <PopupItem
            visible={!!workOrderItem?.civilianNotification?.userComment}
            label={translate('Notifications.info.userCommentLabel')}
          >
            {workOrderItem?.civilianNotification?.userComment}
          </PopupItem>
          <Visible visible={!!workOrderItem.civilianNotification}>
            <br />
            <Link to={appUrls.notifications.detail(`${workOrderItem?.civilianNotification?.id}`)}>
              <Button variant="contained">{translate('Notifications.Buttons.ViewNotification')} </Button>
            </Link>
          </Visible>
        </>
      ) : (
        <Loader />
      )}
    </Popup>
  );
};
