import { ISortingDirection } from '../../common';
import type { ISgwAdvisingPartiesState } from '../../types';
import { initialPaging } from '../../common/config/paging.constants';

export const initialSgwAdvisingPartiesState: ISgwAdvisingPartiesState = {
  list: {
    allIds: [],
    byId: {},
    table: {
      filters: { active: true },
      paging: initialPaging,
      sorting: {
        direction: ISortingDirection.asc,
        key: 'name',
      },
    },
    loading: true,
  },
  loading: true,
};
