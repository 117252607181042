import { IPagedTable, IPaging } from '../types/paging';
import { ISortingDirection } from '../types/sorting';

export const initialPaging: IPaging = {
  page: 1,
  pageSize: 15,
  totalRecords: 0,
  totalPages: 0,
};

export const allPaging: IPaging = {
  ...initialPaging,
  page: 1,
  pageSize: 1000,
};

export const baseInitialFilter: IPagedTable = {
  filters: {},
  sorting: { key: 'id', direction: ISortingDirection.desc },
  paging: initialPaging,
};
