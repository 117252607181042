import { createReducer } from '@reduxjs/toolkit';
import { payloadReducer } from '../../../common';
import { IRequestLocationDetail } from '../../../types';
import { WorkOrderItemsActions } from '../../../common';
import { RequestLocationsActions } from '../../actions';

type TState = IRequestLocationDetail | null;

export default createReducer<TState>(null, {
  [RequestLocationsActions.set.type]: payloadReducer<TState>(),
  [WorkOrderItemsActions.append.type]: (state: TState, { payload }: ReturnType<typeof WorkOrderItemsActions.append>) =>
    state
      ? {
          ...state,
          workOrderItems: [...state.workOrderItems, payload],
        }
      : state,
  [WorkOrderItemsActions.delete.type]: (state: TState, { payload }: ReturnType<typeof WorkOrderItemsActions.delete>) =>
    state
      ? {
          ...state,
          workOrderItems: state.workOrderItems.filter(({ id }) => id !== payload),
        }
      : state,
});
