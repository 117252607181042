import { ISgwPhase, ISgwRequestAttachment } from '../../../types';
import React, { FC } from 'react';
import { MultiSelectV2Component } from '../../../common/components/form-fields/multi-select-v2.component';
import { SgwRequestActions } from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPhases, getPhaseNames } from '../../../store/selectors/sgwRequest.selectors';
import { translate } from '../../../common/translations/translate';
import { useGeneralStyles } from '../../../style/generalStyles';

interface IProps {
  attachment: ISgwRequestAttachment;
  editMode: boolean;
  phases: ISgwPhase[];
  requestId: string;
}

export const RequestAttachmentPhaseSelector: FC<IProps> = ({ attachment, editMode, phases, requestId }) => {
  const allPhases = useSelector(getAllPhases);
  const phaseNames = useSelector(getPhaseNames(phases));
  const dispatch = useDispatch();
  const { textLength20 } = useGeneralStyles();

  if (editMode) {
    return (
      <MultiSelectV2Component
        className={textLength20}
        values={phases.map(({ id }) => id)}
        onChange={(newValues) =>
          dispatch(
            SgwRequestActions.attachments.save({
              requestId,
              attachment: { sgwPhases: newValues, id: attachment.id },
            }),
          )
        }
        menuItems={allPhases.map(({ phaseName, id }) => ({ label: phaseName, value: id }))}
      />
    );
  } else {
    if (!phases.length) {
      return <div>{translate('sgw.requests.detail.attachments.table.noPhase')}</div>;
    }
    if (phases.length > 1 && phases.length === allPhases.length) {
      return <div>{translate('sgw.requests.detail.attachments.table.allPhases')}</div>;
    }
    return <div className={textLength20}>{phaseNames.join(', ')}</div>;
  }
};
