import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { LocationPanel } from './LocationPanel.component';
import { Grid } from '@material-ui/core';
import { SidePaneHeader } from './SidePaneHeader.component';
import { makeStyles } from '@material-ui/core/styles';
import { ICostOverview, IFetchGeocode, IFetchSuggestions, IRequestLocationForm } from '../../../types';
import { RequestLocationsActions } from '../../../store/actions';
import { CostOverview } from './CostOverview.component';
import { Visible } from '../../../common/components/layout/Visible.component';

const useStyles = makeStyles({
  container: { padding: 16 },
  costOverviewContainer: { margin: '8px 0 16px' },
});

interface IProps {
  canEditLocations: boolean;
  costOverview?: ICostOverview;
  expandedLocationId: boolean | number | undefined;
  locations: IRequestLocationForm[];
  onAddLocation: () => void;
  onExpandLocation: (id: number) => (event: any, isExpanded: boolean) => void;
  onRemoveLocation: (id: number) => void;
  onSaveLocation: (location: Partial<IRequestLocationForm>) => void;
  onSaveLocations: () => void;
  onSubmit: () => void;
  saveAllowed: boolean;
  submitAllowed: boolean;
  submitVisible: boolean;
  onValidationResult: (location: Partial<IRequestLocationForm>, flag: boolean) => void;
  onLocationTypeChanged: (location: Partial<IRequestLocationForm>) => void;
}

export const SidePane: FunctionComponent<IProps> = ({
  canEditLocations,
  costOverview,
  expandedLocationId,
  locations,
  onAddLocation,
  onExpandLocation,
  onRemoveLocation,
  onSaveLocation,
  onSaveLocations,
  onSubmit,
  saveAllowed,
  submitAllowed,
  submitVisible,
  onValidationResult,
  onLocationTypeChanged,
}) => {
  const dispatch = useDispatch();
  const C = useStyles();

  const onRefreshSuggestions = useCallback(
    (address: IFetchSuggestions) => {
      dispatch(RequestLocationsActions.fetchSuggestions(address));
    },
    [dispatch],
  );

  const onRefreshGeocodes = useCallback(
    (address: IFetchGeocode) => {
      dispatch(RequestLocationsActions.fetchGeocode(address));
    },
    [dispatch],
  );

  return (
    <Grid container>
      <div className={C.container}>
        <Grid item xs={12}>
          <SidePaneHeader
            addAllowed={canEditLocations && locations.length < 20}
            onAdd={onAddLocation}
            onSaveLocations={onSaveLocations}
            onSubmit={onSubmit}
            saveAllowed={saveAllowed}
            submitAllowed={submitAllowed}
            submitVisible={submitVisible}
          />
        </Grid>
        <Grid item xs={12} className={C.costOverviewContainer}>
          <Visible visible={!!costOverview}>
            <CostOverview costOverview={costOverview!} />
          </Visible>
        </Grid>
        {locations.map((location) => (
          <LocationPanel
            canEditLocations={canEditLocations}
            deleteAllowed={canEditLocations && locations.length > 1}
            expanded={location.id === expandedLocationId}
            key={location.id}
            location={location}
            onExpand={onExpandLocation(location.id)}
            onRemove={onRemoveLocation}
            onRefreshGeocodes={onRefreshGeocodes}
            onRefreshSuggestions={onRefreshSuggestions}
            onSave={onSaveLocation}
            onValidationResult={onValidationResult}
            onLocationTypeChanged={onLocationTypeChanged}
          />
        ))}
      </div>
    </Grid>
  );
};
