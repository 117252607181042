import { IRootState } from '../../root.state';
import { createAllEntitiesSelector } from '../../common/utils/normalized.util';
import { createPagedApiParamsSelector } from '../../common/store/utils/generic.selectors';

export const selectTable = (store: IRootState) => store.events.list.table;
export const selectLoading = (store: IRootState) => store.events.list.loading;
export const selectAllIds = (store: IRootState) => store.events.list.allIds;
export const selectById = (store: IRootState) => store.events.list.byId;
export const getEventsList = createAllEntitiesSelector(selectAllIds, selectById);
export const getPagedApiParams = createPagedApiParamsSelector(selectTable);
export const getEventsFilterDataLoaded = (store: IRootState) => store.events.eventsFilterDataLoaded;
export const getEventTypes = (store: IRootState) => store.events.eventTypes;
