import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRecurringPhaseTemplates,
  getStandardPhases,
  selectSgwRequest,
} from '../../../store/selectors/sgwRequest.selectors';
import { PhaseAccordion } from '../PhaseAccordion/PhaseAccordion.component';
import { SgwPhasesActions } from '../../../store/actions/phases.actions';
import { makeStyles } from '@material-ui/core/styles';
import { SgwTrafficMeasuresActions } from '../../../store/actions';
import { translate } from '../../../common/translations/translate';
import { useGeneralStyles } from '../../../style/generalStyles';
import { useAuthorization, useRequestAuthorization } from '../../../hooks';
import { IRouterWithId, ISgwRequestAclActions, ISgwRequestDetailAclActions } from '../../../types';
import { Panel } from '../../../common';
import { Visible } from '../../../common/components/layout/Visible.component';
import classNames from 'classnames';
import AsignButton from '../../../common/components/buttons/asign-button';
import Add from '@material-ui/icons/Add';
import { useParams } from 'react-router';
import { NEW_PHASE, NEW_RECURRING_PHASE_TEMPLATE } from '../../../common/config/sgwPhase.constants';

const useStyles = makeStyles({
  phases: {
    marginBottom: '20px',
  },
  title: {
    fontSize: 'larger',
  },
});

export const RequestPhases: FC = () => {
  const C = useStyles();
  const G = useGeneralStyles();
  const dispatch = useDispatch();
  const standardPhases = useSelector(getStandardPhases);
  const recurringPhaseTemplates = useSelector(getRecurringPhaseTemplates);
  const request = useSelector(selectSgwRequest);
  const { isAuthorized: userCanViewTrafficMeasures } = useAuthorization(
    ISgwRequestAclActions.getSgwTrafficMeasuresForPhaseDetails,
  );
  const { isAuthorized: requestAllowsEditPhases } = useRequestAuthorization(
    ISgwRequestDetailAclActions.editSgwPhaseDetails,
  );
  const [newPhase, setNewPhase] = useState(false);
  const [newRecurringPhase, setNewRecurringPhase] = useState(false);
  const { id: requestId } = useParams<IRouterWithId>();

  useEffect(() => {
    request?.id && dispatch(SgwPhasesActions.fetchList(request?.id));
    userCanViewTrafficMeasures && dispatch(SgwTrafficMeasuresActions.list.fetch({}));
  }, [userCanViewTrafficMeasures, dispatch, request?.id]);

  useEffect(() => {
    standardPhases && setNewPhase(false);
    recurringPhaseTemplates && setNewRecurringPhase(false);
  }, [standardPhases, recurringPhaseTemplates]);

  const onDelete = (newPhase: boolean, recurringPhase: boolean, id?: string) => {
    if (newPhase) setNewPhase(false);
    if (newPhase && recurringPhase) setNewRecurringPhase(false);
    if (id && !newPhase) dispatch(SgwPhasesActions.remove({ requestId: parseInt(requestId), phaseId: id }));
  };

  return (
    <Panel title={<span>{translate('sgw.requests.detail.phases.planning')}</span>}>
      <span className={C.title}>{translate('sgw.requests.detail.phases.phases')}</span>
      <div className={C.phases}>
        <Visible visible={!standardPhases.length && !newPhase}>
          <span className={classNames(G.infoText, G.marginTop20)}>
            {translate('sgw.requests.detail.phases.noPhases')}
          </span>
        </Visible>

        {standardPhases.map((phase, index) => (
          <>
            <PhaseAccordion key={phase.id} id={`${phase.id}`} phase={phase} index={index} onDelete={onDelete} />
            {phase.recurringPhases.map((phase) => (
              <PhaseAccordion key={phase.id} id={`${phase.id}`} phase={phase} index={index} onDelete={onDelete} />
            ))}
          </>
        ))}

        <Visible visible={newPhase}>
          <PhaseAccordion
            key={NEW_PHASE}
            id={NEW_PHASE}
            index={standardPhases.length}
            newPhase
            editMode
            onDelete={onDelete}
          />
        </Visible>
      </div>
      <div className={G.flexRowEnd}>
        <Visible visible={requestAllowsEditPhases}>
          <AsignButton color="secondary" onClick={() => setNewPhase(true)} startIcon={<Add />} disabled={newPhase}>
            {translate('sgw.requests.detail.phases.newPhase')}
          </AsignButton>
        </Visible>
      </div>

      <span className={C.title}>{translate('sgw.requests.detail.phases.recurringPhases')}</span>
      <div className={C.phases}>
        <Visible visible={!recurringPhaseTemplates.length && !newRecurringPhase}>
          <span className={classNames(G.infoText, G.marginTop20)}>
            {translate('sgw.requests.detail.phases.noRecurringPhases')}
          </span>
        </Visible>
        {recurringPhaseTemplates.map((phase, index) => (
          <PhaseAccordion
            key={phase.id}
            id={`${phase.id}`}
            phase={phase}
            index={index}
            recurringPhaseTemplate
            onDelete={onDelete}
          />
        ))}

        <Visible visible={newRecurringPhase}>
          <PhaseAccordion
            key={NEW_RECURRING_PHASE_TEMPLATE}
            id={NEW_RECURRING_PHASE_TEMPLATE}
            index={recurringPhaseTemplates.length}
            newPhase
            editMode
            recurringPhaseTemplate
            onDelete={onDelete}
          />
        </Visible>
      </div>
      <div className={G.flexRowEnd}>
        <Visible visible={requestAllowsEditPhases}>
          <AsignButton
            color="secondary"
            onClick={() => setNewRecurringPhase(true)}
            startIcon={<Add />}
            disabled={newRecurringPhase}
          >
            {translate('sgw.requests.detail.phases.newRecurringPhase')}
          </AsignButton>
        </Visible>
      </div>
    </Panel>
  );
};
