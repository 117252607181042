import { CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Disableable } from './Disableable.component';
import { Visible } from './Visible.component';

type IProps = {
  loading: boolean;
};

const useStyles = makeStyles({
  center: {
    position: 'absolute',
    top: '50%',
  },
});

export const LoaderBlur: FunctionComponent<PropsWithChildren<IProps>> = ({ children, loading }) => {
  const C = useStyles();
  return (
    <div>
      <Visible visible={loading}>
        <Grid container data-testid="loader" justify="center">
          <Grid item className={C.center}>
            <CircularProgress data-testid="loading" />
          </Grid>
        </Grid>
      </Visible>
      <Disableable disabled={loading}>{children}</Disableable>
    </div>
  );
};
