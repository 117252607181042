import { ISortingDirection } from '../../common';
import { ISgwSettingsSate } from '../../types/sgwSetting.types';

export const initialSgwSettingsState: ISgwSettingsSate = {
  list: {
    allIds: [],
    byId: {},
    table: {
      filters: {},
      paging: {
        page: 1,
        pageSize: 20,
        totalPages: 1,
        totalRecords: 20,
      },
      sorting: {
        direction: ISortingDirection.desc,
        key: 'createdAt',
      },
    },
    loading: true,
  },
};
