import { Grid } from '@material-ui/core';
import { atomWithHash } from 'jotai/utils';
import React, { FC, useCallback, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Loader } from '../../../../../common/components/layout/loader.component';
import { Visible } from '../../../../../common/components/layout/Visible.component';
import { ATOM_HASHES } from '../../../../../common/config/atom.constants';
import { GeoDrawingContextProvider } from '../../../../../context/GeoDrawingContext';
import { SgwRequestActions } from '../../../../../store/actions';
import { selectRequestMessages } from '../../../../../store/selectors';
import {
  getSgwRequestNotes,
  selectLoading,
  selectSgwRequest,
} from '../../../../../store/selectors/sgwRequest.selectors';
import { IRouterWithId, ISgwRequestAclActions, ISgwRequestDetailAclActions } from '../../../../../types';
import { Auth, PageGrid } from '../../../../atoms';
import { SgwRequestInternalNotes, RequestDetailHeader, RequestDetailInfo, RequestPhases } from '../../../../molecules';
import {
  PartnersContactInfoPanel,
  RequestAttachments,
  RequestDetailMapPanel,
  RequestPermitConditions,
  RequestReschedulings,
  SgwRequestHistory,
  SgwRequestResponsiblePanel,
} from '../../../../organisms';
import { RequestDetailConflictPanel } from '../../../../organisms/RequestDetailConflictPanel/RequestDetailConflictPanel.component';
import { RetributionTable } from '../../../../organisms/RetributionTable/RetributionTable.component';
import { SgwMessagesPanel } from './SgwMessagesPanel.component';
import { useRequestAuthorization } from '../../../../../hooks';

const useStyles = createUseStyles({
  bottomPanels: {
    margin: '20px 150px',
  },
});

export const visibleConflictsAtom = atomWithHash<number[]>(ATOM_HASHES.conflicts.visibleConflicts, []);

export const RequestDetailPage: FC = () => {
  const dispatch = useDispatch();
  const C = useStyles();
  const { id } = useParams<IRouterWithId>();
  const loading = useSelector(selectLoading);
  const notes = useSelector(getSgwRequestNotes());
  const request = useSelector(selectSgwRequest);
  const { isAuthorized: canAddInternalNote } = useRequestAuthorization(ISgwRequestAclActions.editSgwInternalNotes);

  const onPatchInternalNotes = useCallback(
    (backofficeNotes: string, shouldAppend: boolean) => {
      dispatch(SgwRequestActions.patchInternalNotes({ id, backofficeNotes, shouldAppend }));
    },
    [dispatch, id],
  );

  useEffect(() => {
    if (id) {
      dispatch(SgwRequestActions.fetch(id));
      dispatch(SgwRequestActions.reschedulings.fetch(id));
    }
  }, [dispatch, id]);

  const fetchMessages = useCallback(
    (requestId: number) => dispatch(SgwRequestActions.fetchSgwRequestMessages(`${requestId}`)),
    [dispatch],
  );

  return (
    <Auth acl={ISgwRequestAclActions.viewSgwRequestDetail} showUnauthorizedPage>
      <Loader loading={loading}>
        <RequestDetailHeader />
        <PageGrid fullWidth>
          <Grid item xs={6} data-testid="leftColumn">
            <Auth acl={ISgwRequestDetailAclActions.viewSgwResponsiblePanel}>
              <SgwRequestResponsiblePanel id={id} />
            </Auth>
            <RequestDetailInfo />
            <Auth acl={ISgwRequestDetailAclActions.viewSgwPartnerInformation}>
              <PartnersContactInfoPanel requestId={id} />
            </Auth>
            <Auth acl={ISgwRequestDetailAclActions.viewSgwInternalNotes}>
              <SgwRequestInternalNotes
                notes={notes}
                onPatchInternalNotes={onPatchInternalNotes}
                isReadOnly={!canAddInternalNote}
              />
            </Auth>
          </Grid>
          <Grid item xs={6} data-testid="rightColumn">
            <Auth acl={ISgwRequestDetailAclActions.viewSgwCommunicationModule}>
              <SgwMessagesPanel messagesSelector={selectRequestMessages} fetchMessages={fetchMessages} />
            </Auth>
            <Auth acl={ISgwRequestDetailAclActions.viewSgwAttachmentPanel}>
              <RequestAttachments id={id} />
            </Auth>
            <Auth acl={ISgwRequestDetailAclActions.viewSgwPermitConditions}>
              <RequestPermitConditions />
            </Auth>
            <Auth acl={ISgwRequestDetailAclActions.viewReschedulingRequest}>
              <RequestReschedulings />
            </Auth>
          </Grid>
        </PageGrid>
        <div className={C.bottomPanels}>
          <Auth acl={ISgwRequestDetailAclActions.viewSgwPhaseDetails}>
            <RequestPhases />
          </Auth>
          <GeoDrawingContextProvider>
            <Auth acl={ISgwRequestDetailAclActions.viewSgwMap}>
              <RequestDetailMapPanel />
            </Auth>
            <Auth
              acl={[
                ISgwRequestDetailAclActions.viewAllConflicts,
                ISgwRequestDetailAclActions.viewOnlyConflictsOfOwnAdvisingParty,
              ]}
            >
              <RequestDetailConflictPanel requestId={id} />
            </Auth>
          </GeoDrawingContextProvider>
          <Visible visible={!!request?.acl?.sgwRetributionRequired}>
            <Auth acl={ISgwRequestDetailAclActions.viewSgwCostCalculation}>
              <RetributionTable />
            </Auth>
          </Visible>
          <Visible visible={!!request?.acl?.viewSgwHistoryLog}>
            <SgwRequestHistory />
          </Visible>
        </div>
      </Loader>
    </Auth>
  );
};
