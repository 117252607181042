import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RequestActions } from '../store/actions';
import { selectRequestMetadata } from '../store/selectors';

export const useRequestMetadata = () => {
  const dispatch = useDispatch();
  const metadata = useSelector(selectRequestMetadata);

  useEffect(() => {
    !metadata && dispatch(RequestActions.fetchMetadata());
  }, [dispatch, metadata]);

  return metadata;
};
