import { Checkbox, MenuItem, TextField } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { Form, Formik, FormikProps } from 'formik';
import React, { FC, ReactNode, useState } from 'react';
import { Panel, PanelAction } from '../../common';
import { Visible } from '../../common/components/layout/Visible.component';
import { translate } from '../../common/translations/translate';
import { IUser } from '../../common/types/user';
import { Auth } from '../../components';
import { useAuthorization } from '../../hooks';
import { ICpaAppAclActions, ISignsAclActions } from '../../types';
import { SIGN_STATES, SIGN_TYPES } from '../signs.constants';
import { ISign } from '../types/sign';
import { ISignCondition } from '../types/sign-condition';
import { ISignState } from '../types/sign-state';
import { ISignType } from '../types/sign-type';
import { ISignUpdate } from '../types/sign-update';
import { DeleteButton } from './buttons/DeleteButton.component';
import { RetrieveButton } from './buttons/RetrieveButton.component';
import { SubmitButton } from './buttons/SubmitButton.component';
import { DeleteSignDialog } from './dialog/delete-sign-dialog.component';

interface IProps {
  sign: ISign;
  signConditions: ISignCondition[];
  signStates: ISignState[];
  signTypes: ISignType[];
  updateSign: (sign: ISignUpdate) => void;
  generateRetrieveWorkOrderItem: () => void;
  deleteSign: (withModule: boolean) => void;
  user: IUser | null;
}

interface IRenderFormProps {
  inEditMode: boolean;
  sign: ISign;
  signStates: ISignState[];
  signConditions: ISignCondition[];
  generateRetrieveWorkOrderItem: () => void;
  onDeleteSignClick: () => void;
  signTypes: ISignType[];
}

const renderForm =
  ({
    inEditMode,
    sign,
    signStates,
    signConditions,
    generateRetrieveWorkOrderItem,
    onDeleteSignClick,
    signTypes,
  }: IRenderFormProps) =>
  (props: FormikProps<ISignUpdate>): ReactNode => {
    const [isRetrieveWorkOrderItemGenerated, setIsRetrieveWorkOrderItemGenerated] = useState(false);

    const { isAuthorized: canSetSignToUpgradeMode } = useAuthorization(ICpaAppAclActions.setSignToUpgradeMode);

    const onGenerateRetrieveWorkOrderItem = (): void => {
      setIsRetrieveWorkOrderItemGenerated(true);
      generateRetrieveWorkOrderItem();
    };

    const doCoordinatesExists = (): boolean => {
      if (!sign.location) {
        return false;
      }
      return !sign.location.coordinates.some((item) => item === 0);
    };

    const getPossibleSignStates = (): ISignState[] => {
      return signStates.filter((state: ISignState) => {
        if (state.state === SIGN_STATES.UPGRADE) {
          return canSetSignToUpgradeMode;
        }

        return (
          state.state === sign.state.state ||
          (sign.possibleStatesToTransit && sign.possibleStatesToTransit.indexOf(state.state) !== -1)
        );
      });
    };

    const showRetrieveButton =
      !inEditMode &&
      !isRetrieveWorkOrderItemGenerated &&
      sign.type.name === SIGN_TYPES.DIGITAL &&
      [SIGN_STATES.STORED].indexOf(sign.state.state) < 0 &&
      doCoordinatesExists();
    const showDeleteButton = inEditMode && sign.acl.canBeDeleted;
    return (
      <Form noValidate>
        <dl>
          <dt>{translate('SignID')}</dt>
          <dd>{sign.hash}</dd>
          <dt>{translate('Status')}</dt>
          <dd>
            {inEditMode ? (
              <TextField
                value={props.values.state}
                fullWidth={true}
                select={true}
                onChange={props.handleChange}
                id="state"
                name="state"
              >
                {getPossibleSignStates().map((state: ISignState) => (
                  <MenuItem key={state.state} value={state.state}>
                    {state.name}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              sign.state.name
            )}
          </dd>
          <dt>{translate('Type')}</dt>
          <dd>
            {inEditMode ? (
              <TextField
                value={props.values.type}
                fullWidth={true}
                select={true}
                onChange={props.handleChange}
                id="type"
                name="type"
              >
                {signTypes.map((type: ISignType) => (
                  <MenuItem key={type.type} value={type.type}>
                    {type.displayName}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              sign.type.formatted
            )}
          </dd>
          <dt>{translate('Condition')}</dt>
          <dd>
            {inEditMode ? (
              <TextField
                value={props.values.condition}
                fullWidth={true}
                select={true}
                onChange={props.handleChange}
                id="condition"
                name="condition"
              >
                {signConditions.map((condition: ISignCondition) => (
                  <MenuItem key={condition.condition} value={condition.condition}>
                    {condition.displayName}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              sign.condition.name
            )}
          </dd>
          <dt>{translate('Owner')}</dt>
          <dd>{sign.owner}</dd>
          <Visible visible={!!sign.sigfoxDevice}>
            <dt>{translate('showOnMap')}</dt>
            <dd>
              {inEditMode ? (
                <>
                  <Checkbox
                    checked={props.values.hideOnMapView}
                    onChange={props.handleChange}
                    id="hideOnMapView"
                    name="hideOnMapView"
                  />
                  {translate('showOnMapDescription')}
                </>
              ) : sign.hideOnMapView ? (
                translate('no')
              ) : (
                translate('yes')
              )}
            </dd>
          </Visible>
          <Visible visible={!!sign.sigfoxDevice || inEditMode}>
            <dt>{translate('ModuleID')}</dt>
            <dd>
              {inEditMode ? (
                <TextField
                  value={props.values.sigfoxDeviceId}
                  fullWidth={true}
                  onChange={props.handleChange}
                  id="sigfoxDeviceId"
                  name="sigfoxDeviceId"
                />
              ) : (
                sign.sigfoxDevice && sign.sigfoxDevice.id
              )}
            </dd>
          </Visible>
        </dl>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
          <Visible visible={showRetrieveButton}>
            <RetrieveButton onClick={onGenerateRetrieveWorkOrderItem} />
          </Visible>

          <Auth acl={ISignsAclActions.deleteSign}>
            <Visible visible={showDeleteButton}>
              <DeleteButton onClick={onDeleteSignClick} />
            </Visible>
          </Auth>
          <Visible visible={inEditMode}>
            <SubmitButton />
          </Visible>
        </div>
      </Form>
    );
  };

export const SignGeneral: FC<IProps> = ({
  sign,
  user,
  updateSign,
  generateRetrieveWorkOrderItem,
  deleteSign,
  signConditions,
  signStates,
  signTypes,
}) => {
  const [inEditMode, setInEditMode] = useState(false);
  const [isDeleteSignPopup, setIsDeleteSignPopup] = useState(false);
  const { isAuthorized: canDeleteSign } = useAuthorization([ISignsAclActions.deleteSign]);

  const onClickEditButton = (): void => {
    setInEditMode(!inEditMode);
  };

  const onSubmit = (sign: ISignUpdate): void => {
    setInEditMode(false);
    updateSign(sign);
  };

  const onDeleteSignClick = () => {
    setIsDeleteSignPopup(true);
  };

  const onDeleteDialogConfirm = (withModule: boolean) => {
    deleteSign(withModule);
    setIsDeleteSignPopup(false);
  };

  const onDeleteDialogClose = () => {
    setIsDeleteSignPopup(false);
  };

  return (
    <Panel title={translate('DetailsSign')}>
      <PanelAction icon={<Edit />} onClick={onClickEditButton} />
      <Formik
        initialValues={{
          condition: sign.condition.condition,
          hash: sign.hash,
          sigfoxDeviceId: sign.sigfoxDevice ? sign.sigfoxDevice.id : '',
          state: sign.state.state,
          type: sign.type.name,
          hideOnMapView: sign.hideOnMapView,
        }}
        render={renderForm({
          generateRetrieveWorkOrderItem,
          inEditMode,
          sign,
          signConditions,
          signStates,
          onDeleteSignClick,
          signTypes,
        })}
        onSubmit={onSubmit}
      />
      <DeleteSignDialog
        visible={isDeleteSignPopup && canDeleteSign}
        onClose={onDeleteDialogClose}
        onConfirm={onDeleteDialogConfirm}
        sign={sign}
      />
    </Panel>
  );
};
