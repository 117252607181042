import { Actions } from '../../common';
import { ISgwPhase } from '../../types';

class SgwPhasesActionsClass extends Actions {
  copyFromPhase = this.createPayloadAction<{ phaseIdToSave: number; phaseIdToCopy: number }>('copyFromPhase');
  fetchList = this.createPayloadAction<number>('fetchList');
  save = this.createPayloadAction<{ phase: Partial<ISgwPhase>; syncRequest?: boolean }>('save');
  setList = this.createPayloadAction<ISgwPhase[]>('setList');
  setLoading = this.createPayloadAction<boolean>('setLoading');
  remove = this.createPayloadAction<{ requestId: number; phaseId: string }>('remove');
  clone = this.createPayloadAction<{ requestId: string; phaseId: number; phase: Partial<ISgwPhase> }>('clone');
}

export const SgwPhasesActions = new SgwPhasesActionsClass('sgwPhases');
