import * as React from 'react';
import { FunctionComponent } from 'react';
import { Route, Switch } from 'react-router';
import { appUrls } from '../../common/config/url.constants';
import { Auth } from '../../components';
import { ICarFreeZoneAclActions } from '../../types';
import { CarFreeZonePage } from './CarFreeZone.page';
import { CarFreeZonesListPage } from './CarFreeZonesList.page';

export const KrautliZones: FunctionComponent = () => {
  return (
    <Switch>
      <Route exact path={[appUrls.carFreeZones.krautliZones.detail()]}>
        <Auth acl={ICarFreeZoneAclActions.viewCarFreeZone} showUnauthorizedPage>
          <CarFreeZonePage />
        </Auth>
      </Route>
      <Route path={appUrls.carFreeZones.base}>
        <Auth acl={ICarFreeZoneAclActions.viewCarFreeZoneList} showUnauthorizedPage>
          <CarFreeZonesListPage />
        </Auth>
      </Route>
    </Switch>
  );
};
