import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GenericTable } from '../../../common/components/table/GenericTable.component';
import { BACKEND_URL } from '../../../common/config/environment';
import { translate } from '../../../common/translations/translate';
import { convertDateFormat } from '../../../common/utils/date.util';
import { SgwRequestActions } from '../../../store/actions';
import { selectSgwRequestPermitHistory } from '../../../store/selectors/sgwRequest.selectors';
import { DateFormat, ISgwRequestPermit, IStoredFile } from '../../../types';
import { FilePreviewer } from '../FilePreviewer/FilePreviewer.component';

interface IProps {
  id: string;
}

export const SgwRequestPermitHistory: FC<IProps> = ({ id }) => {
  const dispatch = useDispatch();
  const permits = useSelector(selectSgwRequestPermitHistory);

  useEffect(() => {
    id && dispatch(SgwRequestActions.fetchSgwRequestPermitHistory({ id }));
  }, [id, dispatch]);

  return (
    <GenericTable<ISgwRequestPermit>
      columnKey="filePath"
      columns={[
        {
          label: translate('sgw.requests.detail.permitHistory.table.filename'),
          name: 'filename',
          renderer: (_, permit) => (
            <FilePreviewer
              editMode={false}
              fileStorage={
                {
                  name: permit?.filename,
                  url: permit?.filePath ? `${BACKEND_URL}${permit.filePath}` : '',
                  mimeType: 'application/pdf',
                } as IStoredFile
              }
            />
          ),
        },
        {
          label: translate('sgw.requests.detail.permitHistory.table.createdBy'),
          name: 'createdBy',
        },
        {
          label: translate('sgw.requests.detail.permitHistory.table.timestamp'),
          name: 'timeChanged',
          renderer: (timeChanged) => convertDateFormat(timeChanged, DateFormat.beDateTime, DateFormat.dateTime),
        },
      ]}
      records={permits}
      labelUnavailable={translate('sgw.requests.detail.permitHistory.table.unavailable')}
    />
  );
};
