import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Panel, TileSelect } from '../../../common';
import { ATOMS } from '../../../common/config/atom.constants';
import { translate } from '../../../common/translations/translate';
import { SgwRequestActions } from '../../../store/actions';
import { selectCounters, selectTable } from '../../../store/selectors/sgwRequest.selectors';
import { ISgwRequestAclActions, SgwRequestListOverviewTile } from '../../../types';
import { RequestListOverviewTitle } from '../../molecules';
import { useGeneralStyles } from '../../../style/generalStyles';
import { useAtom } from 'jotai';
import { useAuthorization } from '../../../hooks';
import { Visible } from '../../../common/components/layout/Visible.component';
import { useScreenSize } from '../../../hooks';

export const RequestListOverview: FC = () => {
  const G = useGeneralStyles();
  const dispatch = useDispatch();
  const counters = useSelector(selectCounters);
  const { filters: _filters } = useSelector(selectTable);
  const [filters, setFilters] = useAtom(ATOMS.sgwRequest.overview.filtersAtom);
  const { clusters, myRequests, tile } = filters;
  const { isAuthorized } = useAuthorization(ISgwRequestAclActions.viewSgwDashboardTiles);
  const { isBigScreen } = useScreenSize();

  useEffect(() => {
    setFilters(_filters);
  }, [_filters, setFilters]);

  useEffect(() => {
    isAuthorized && dispatch(SgwRequestActions.fetchRequestCounters());
  }, [dispatch, clusters, myRequests, tile, isAuthorized]);

  const onTileChange = (newTile: string) =>
    dispatch(
      SgwRequestActions.list.fetch({
        filters: {
          ...filters,
          tile: newTile === tile ? undefined : newTile,
        },
      }),
    );

  return (
    <Visible visible={isAuthorized}>
      <Panel title={<RequestListOverviewTitle />} className={G.fullWidth}>
        <TileSelect
          id="overviewFilters"
          value={tile}
          valueChange={onTileChange}
          itemsPerRow={isBigScreen ? 10 : 5}
          values={[
            {
              subtitle: translate('sgw.requests.states.new'),
              title: `${counters.new}`,
              value: SgwRequestListOverviewTile.NEW,
            },
            {
              subtitle: translate('sgw.requests.states.urgent'),
              title: `${counters.urgent}`,
              value: SgwRequestListOverviewTile.URGENT,
            },
            {
              subtitle: translate('sgw.requests.states.sleeping'),
              title: `${counters.sleeping}`,
              value: SgwRequestListOverviewTile.SLEEPING,
            },
            {
              subtitle: translate('sgw.requests.states.reschedule'),
              title: `${counters.reschedule}`,
              value: SgwRequestListOverviewTile.RESCHEDULE,
            },
            {
              subtitle: translate('sgw.requests.states.checkGIS'),
              title: `${counters.checkGIS}`,
              value: SgwRequestListOverviewTile.CHECK_GIS,
            },
            {
              subtitle: translate('sgw.requests.states.inReview'),
              title: `${counters.inReview}`,
              value: SgwRequestListOverviewTile.IN_REVIEW,
            },
            {
              subtitle: translate('sgw.requests.states.inEffect'),
              title: `${counters.inEffect}`,
              value: SgwRequestListOverviewTile.IN_EFFECT,
            },
            {
              subtitle: translate('sgw.requests.states.finalInvoice'),
              title: `${counters.finalInvoice || 0}`,
              value: SgwRequestListOverviewTile.FINAL_INVOICE,
            },
            {
              subtitle: translate('sgw.requests.states.finalInvoiceSent'),
              title: `${counters.finalInvoiceSent || 0}`,
              value: SgwRequestListOverviewTile.FINAL_INVOICE_SENT,
            },
            {
              subtitle: translate('sgw.requests.states.todo'),
              title: `${counters.todo || 0}`,
              value: SgwRequestListOverviewTile.TODO,
            },
          ]}
        />
      </Panel>
    </Visible>
  );
};
