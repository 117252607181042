import React, { FC } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { createUseStyles } from 'react-jss';
import { useManualPlacementQuery, useScreenSize, useTitle } from '../../../hooks';
import { ManualPlacementHeader } from '../../molecules/ManualPlacementHeader/ManualPlacementHeader.component';
import { Loader } from '../../../common/components/layout/loader.component';
import { ManualPlacementRequestInfo } from '../../molecules/ManualPlacementRequestInfo/ManualPlacementRequestInfo.component';
import { ManualPlacementContactInfo } from '../../molecules/ManualPlacementContactInfo/ManualPlacementContactInfo.component';
import { useGeneralStyles } from '../../../style/generalStyles';
import { translate } from '../../../common/translations/translate';
import { Visible } from '../../../common/components/layout/Visible.component';
import { IntakeTypes } from '../../../types';
import { ManualPlacementForm } from '../../forms/ManualPlacementForm/ManualPlacementForm.component';
import classnames from 'classnames';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contentDesktop: { width: '70%' },
  contentMobile: { width: '85%' },
});

export const ManualPlacementPage: FC = () => {
  const { isMobile } = useScreenSize();
  const C = useStyles();
  const G = useGeneralStyles();
  useTitle('manualPlacement.title');
  const { request, isLoading } = useManualPlacementQuery();

  if (!request || isLoading) {
    return <Loader />;
  }

  return (
    <div className={C.root}>
      <ManualPlacementHeader {...request} />
      <Grid
        container
        justify="center"
        spacing={3}
        className={classnames(isMobile ? C.contentMobile : C.contentDesktop)}
      >
        <Grid item xs={12} md={6}>
          <ManualPlacementRequestInfo
            {...request}
            permittedPlates={request.publicDomainIntakes.flatMap(({ permittedPlates }) => permittedPlates || [])}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ManualPlacementContactInfo {...request} />
        </Grid>
        <Grid item xs={12}>
          <Visible visible={!request.editable}>
            <Paper square className={G.marginBottom20}>
              <div className={G.warningPrimary}>
                {translate('manualPlacement.notEditable', { state: request?.state.name.toLowerCase() })}
              </div>
            </Paper>
          </Visible>
          {request?.locations
            .filter(({ type }) => type.type === IntakeTypes.parkingbanintake)
            .map((location) => (
              <ManualPlacementForm intake={location} />
            ))}
        </Grid>
      </Grid>
    </div>
  );
};
