import { createUseStyles } from 'react-jss';
import { HEADER_HEIGHT } from '../components/organisms/MainMenu/MainMenu.styles';

export const useRouterMenuStyles = createUseStyles(() => ({
  group: {
    borderRight: '1px solid white',
    padding: '0px 10px',
    '&:last-child': {
      borderRight: 'none',
    },
  },
  groupName: {
    textAlign: 'center',
  },
  groups: {
    height: HEADER_HEIGHT,
    width: '100%',
  },
  tabs: {
    borderTop: '1px solid white',
    marginLeft: '5px',
    marginRight: '5px',
  },
  link: {
    textDecoration: 'inherit',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'inherit',
      color: 'inherit',
    },
  },
}));
