export const isInIframe = () => {
  /*
  Browsers can block access to window.top due to same origin policy: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Same_origin_policy_for_JavaScript.
   */
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};
