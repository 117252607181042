import { Button } from '@material-ui/core';
import { Lens } from '@material-ui/icons';
import * as React from 'react';
import { ITeam } from '../../../types';

interface IProps {
  team: ITeam;
  handleClick: (team: ITeam) => void;
  disabled: boolean;
}

export class TeamAssignItem extends React.Component<IProps> {
  public render(): React.ReactNode {
    return (
      <Button
        variant={'contained'}
        onClick={this.handleClick}
        disabled={this.props.disabled}
        style={{ margin: 10, opacity: this.props.disabled ? 0.4 : 1 }}
      >
        <Lens style={{ color: this.props.team.color }} />
        {this.props.team.name}
      </Button>
    );
  }

  private handleClick = () => {
    this.props.handleClick(this.props.team);
  };
}
