import React, { FC, useCallback } from 'react';
import {
  ConflictCategory,
  IConflictGroupWithPhaseIds,
  IRequestMessage,
  ISgwRequestDetailAclActions,
} from '../../../types';
import { translate, translateIgnoreTS } from '../../../common/translations/translate';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Paper } from '@material-ui/core';
import { Icon } from '../../../common/components/layout/icon.component';
import { useDispatch, useSelector } from 'react-redux';
import { SgwRequestActions } from '../../../store/actions';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { useSgwConflictAccordionStyles } from './SgwConflictAccordion.styles';
import { DEBOUNCE } from '../../../common/time-constants';
import { SgwConflictState } from '../SgwConflictState/SgwConflictState.component';
import { SelectAdvisingParty } from '../SgwSelectAdvisingParty/SgwSelectAdvisingParty.component';
import { useGeneralStyles } from '../../../style/generalStyles';
import { Visible } from '../../../common/components/layout/Visible.component';
import { useAtom } from 'jotai';
import { visibleConflictsAtom } from '../../pages';
import classNames from 'classnames';
import { SgwMessagesPanel } from '../../pages/sgw/request/RequestDetailPage/SgwMessagesPanel.component';
import { getConflictGroupMessagesById } from '../../../store/selectors/sgwRequest.selectors';
import { SgwConflictInfo } from '../SgwConflictInfo/SgwConflictInfo.component';
import { useRequestAuthorization } from '../../../hooks';

export const SgwConflictAccordion: FC<IConflictGroupWithPhaseIds> = (conflictGroup) => {
  const { id, sgwRequestId, conflictCategory, isFlagged, sgwAdvisingPartyId, state } = conflictGroup;
  const C = useSgwConflictAccordionStyles();
  const [visibleConflicts, setVisibleConflicts] = useAtom(visibleConflictsAtom);
  const { isAuthorized: canAssignSgwConflictToAdvisingParty } = useRequestAuthorization(
    ISgwRequestDetailAclActions.assignSgwConflictToAdvisingParty,
  );
  const { isAuthorized: canSendRequestForAdvice } = useRequestAuthorization(
    ISgwRequestDetailAclActions.sendRequestForAdvice,
  );
  const G = useGeneralStyles();
  const dispatch = useDispatch();
  const messages = useSelector(getConflictGroupMessagesById(id));

  const sendEmail = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();
    dispatch(
      SgwRequestActions.conflicts.sendEmailAP({
        requestId: `${sgwRequestId}`,
        conflictId: id,
      }),
    );
  };

  const onClickConflictGroup = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setVisibleConflicts((prev) =>
      prev.includes(conflictGroup.id)
        ? prev.filter((prevId) => prevId !== conflictGroup.id)
        : [...prev, conflictGroup.id],
    );
  };

  const fetchMessages = useCallback(
    (requestId: number) => dispatch(SgwRequestActions.conflicts.fetchMessages({ requestId, conflictGroupId: id })),
    [dispatch, id],
  );

  const saveMessage = (conflictGroupId: number) => (message: IRequestMessage) => {
    if (!messages.data.length && !state) sendEmail();
    dispatch(SgwRequestActions.conflicts.saveMessage({ conflictGroupId, message }));
  };

  const canSelectAdvisingParty =
    canAssignSgwConflictToAdvisingParty &&
    ![ConflictCategory.constructionZone, ConflictCategory.parkingBan].includes(conflictGroup.conflictCategory);

  const canSendEmail =
    canSendRequestForAdvice &&
    ![ConflictCategory.constructionZone, ConflictCategory.parkingBan].includes(conflictGroup.conflictCategory);

  return (
    <div className={C.accordion}>
      <Paper className={C.image}>
        <Icon.ConflictIcons
          variant={conflictCategory}
          alt={translateIgnoreTS(`assets.images.conflicts.${conflictCategory}`)}
          className={C.icon}
        />
      </Paper>
      <ExpansionPanel className={C.panel}>
        <ExpansionPanelSummary className={C.summaryWrapper}>
          <div className={C.summary}>
            <div className={G.flexRowCenterVertical}>
              <Visible visible={isFlagged}>
                <Icon.ConflictFlag className={C.flagIcon} />
              </Visible>

              <div className={C.title}>
                {translateIgnoreTS(`sgw.requests.detail.conflicts.conflictCategoryDisplayName.${conflictCategory}`)}
              </div>
            </div>
            <div className={G.flexRowCenterVertical}>
              <Visible visible={canSelectAdvisingParty}>
                <b className={G.marginRight}>{`${translate(`sgw.requests.detail.advisingParties.advisingParty`)}:`}</b>
                <SelectAdvisingParty
                  conflictGroupId={id}
                  sgwAdvisingPartyId={sgwAdvisingPartyId}
                  sgwRequestId={sgwRequestId}
                />
              </Visible>

              <SgwConflictState {...conflictGroup} />
              <div className={C.actionButtons}>
                <Visible visible={!!conflictGroup.conflicts.length}>
                  <Button.White
                    onClick={onClickConflictGroup}
                    className={classNames(C.mapMarker, visibleConflicts.includes(id) ? C.pressedButton : undefined)}
                    type="button"
                  >
                    <Icon.MapMarker className={C.mapMarkerIcon} />
                  </Button.White>
                </Visible>
                <Visible visible={canSendEmail}>
                  <Button.Gray
                    debounceTime={DEBOUNCE.sendEmailAP}
                    className={C.sendEmail}
                    onClick={sendEmail}
                    type="button"
                  >
                    <Icon.SendEmail className={C.sendEmailIcon} />
                  </Button.Gray>
                </Visible>
              </div>
            </div>
          </div>
        </ExpansionPanelSummary>
        <hr className={C.line} />
        <ExpansionPanelDetails className={C.details}>
          <Grid container spacing={2}>
            <Grid item xs={6} data-testid="leftColumn">
              <SgwConflictInfo conflictGroup={conflictGroup} />
            </Grid>
            <Grid item xs={6} data-testid="rightColumn">
              <SgwMessagesPanel
                showAllOnly
                messagesSelector={getConflictGroupMessagesById(id)}
                fetchMessages={fetchMessages}
                saveMessage={saveMessage(id)}
              />
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};
