import I18n from 'i18n-js';
import { IntakeTypes, IPublicDomainIntake, IPublicDomainIntakeExtended } from '../../types';
import { geometryToPositions, getBounds, getBoundsOfGeometries } from './geojson.util';
import { CONFIG } from '../config/config';
import { getViodUrl } from './string.util';
import { SIGN_STATES } from '../../signs/signs.constants';

export const setLeafletDrawTranslations = () => {
  // @ts-ignore
  const drawLocal: any = L.drawLocal;
  drawLocal.draw.handlers.polyline.tooltip.start = I18n.t('leaflet.drawLineStart');
  drawLocal.draw.handlers.polyline.tooltip.cont = I18n.t('leaflet.drawLineCont');
  drawLocal.draw.handlers.polyline.tooltip.end = I18n.t('leaflet.drawLineEnd');
  drawLocal.draw.handlers.polygon.tooltip.start = I18n.t('leaflet.drawPolygonStart');
  drawLocal.draw.handlers.polygon.tooltip.cont = I18n.t('leaflet.drawPolygonCont');
  drawLocal.draw.handlers.polygon.tooltip.end = I18n.t('leaflet.drawPolygonEnd');
  drawLocal.draw.toolbar.actions.text = I18n.t('leaflet.actionsText');
  drawLocal.draw.toolbar.actions.title = I18n.t('leaflet.actionsTitle');
  drawLocal.draw.toolbar.buttons.polygon = I18n.t('leaflet.drawPolygon');
  drawLocal.draw.toolbar.buttons.polyline = I18n.t('leaflet.drawLine');
  drawLocal.draw.toolbar.finish.text = I18n.t('leaflet.finishText');
  drawLocal.draw.toolbar.finish.title = I18n.t('leaflet.finishTitle');
  drawLocal.draw.toolbar.undo.text = I18n.t('leaflet.undoText');
  drawLocal.draw.toolbar.undo.title = I18n.t('leaflet.undoTitle');
};

export const extendPublicDomainIntake = (intake: IPublicDomainIntake): IPublicDomainIntakeExtended => {
  const { ...props } = intake;
  const { street, streetNumberFrom, streetNumberTo, city, zipCode, signs, geometry } = intake;
  const positions = geometry ? geometryToPositions(geometry) || null : null;
  let bounds = undefined;
  if (intake.carFreeZone?.polygon) {
    bounds = getBoundsOfGeometries([intake.carFreeZone.polygon]);
  } else if (geometry?.coordinates) {
    bounds = getBounds(geometry.coordinates);
  }

  return {
    ...props,
    cityAndZipCode: `${zipCode} ${city}`,
    streetAndNumbers: streetNumberFrom !== '0' ? `${street} ${streetNumberFrom}-${streetNumberTo}` : `${street}`,
    googleMapsAddress:
      CONFIG.baseGoogleMapsUrl + `${street} ${streetNumberFrom !== '0' ? streetNumberFrom : ''} ${zipCode} ${city}`,
    viodAddress: getViodUrl(intake),
    numberOfSignsPlaced: signs?.filter(({ sign }) => sign && sign.state.state === SIGN_STATES.PLACED).length || 0,
    canMarkSignsAsMissing: signs?.some(({ active }) => active) || false,
    canHaveZoneSigns: geometry?.type === 'Polygon' && intake.type.type === IntakeTypes.parkingbanintake,
    positions,
    bounds,
  };
};
