import React, { FC, useEffect } from 'react';
import { Container, FormattedDate, IPaging, ISorting } from '../../common';
import { Auth } from '../../components';
import { INotificationAclActions } from '../../types';
import { INotification } from '../types/notification';
import { useDispatch, useSelector } from 'react-redux';
import { getNotifications, selectListLoading, selectTable } from '../../store/selectors/notifications.selectors';
import { useHistory } from 'react-router';
import { GenericTablePanel } from '../../common/components/table/GenericTablePanel.component';
import { getNotificationStates, NotificationState } from '../notification.constants';
import { LabelChip } from '../../common/components/layout/LabelChip.component';
import { NotificationActions } from '../../store/actions/notifications.actions';
import { translate } from '../../common/translations/translate';
import { NotificationFilters } from '../components/NotificationFilters.component';
import { appUrls } from '../../common/config/url.constants';
export const NotificationsPage: FC = () => {
  const { paging, sorting } = useSelector(selectTable);
  const notifications = useSelector(getNotifications);
  const loading = useSelector(selectListLoading);
  const { push } = useHistory();

  const states = getNotificationStates();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(NotificationActions.list.fetch({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPagingChange = (paging: IPaging): void => {
    dispatch(NotificationActions.list.fetch({ paging }));
  };

  const onSortingChange = (sorting: ISorting): void => {
    dispatch(NotificationActions.list.fetch({ sorting }));
  };

  const onNotificationClick = (notification: INotification) => push(appUrls.notifications.detail(`${notification.id}`));

  return (
    <Auth acl={INotificationAclActions.viewNotification} showUnauthorizedPage>
      <Container>
        <GenericTablePanel<INotification>
          filterNode={<NotificationFilters />}
          columns={[
            { label: translate('Notifications.table.id'), name: 'id', sortable: true },
            {
              label: translate('Notifications.table.date'),
              name: 'createdAt',
              sortable: true,
              renderer: (createdAt: string) => <FormattedDate date={createdAt} />,
            },
            {
              label: translate('Notifications.table.address'),
              name: 'streetName',
              sortable: true,
              renderer: (streetName: string, record) => (
                <>
                  {streetName} {record?.streetNumber}
                  <br />
                  {record?.postalCode || ''} {record?.city}
                </>
              ),
            },
            { label: translate('Notifications.table.numberOfSigns'), name: 'numberOfSigns', sortable: true },
            {
              label: translate('Notifications.table.userResponsible'),
              name: 'userResponsible',
              renderer: (userResponsible) => userResponsible?.fullName,
              sortable: true,
            },
            {
              label: translate('Notifications.table.state'),
              name: 'state',
              renderer: (state: NotificationState) => {
                const stateValues = states.find(({ id }) => state === id);
                return stateValues && <LabelChip color={stateValues.bgcolor} label={stateValues.name} />;
              },
              sortable: true,
            },
          ]}
          records={notifications}
          columnKey="id"
          labelUnavailable={translate('Notifications.table.unavailable')}
          onChangeSorting={onSortingChange}
          onChangePaging={onPagingChange}
          paging={paging}
          sorting={sorting}
          loading={loading}
          onClick={onNotificationClick}
        />
      </Container>
    </Auth>
  );
};
