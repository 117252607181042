import axios from 'axios';
import { CONFIG, IApiPromise } from '../..';
import { ICost, ICostPatchState, IPayment, IPaymentPatchState } from '../../../types';

export const PaymentsApi = {
  patchState: ({ id, state, reasonForManualEdit }: IPaymentPatchState): IApiPromise<IPayment> => {
    return axios.patch(`${CONFIG.backOfficeApi}/v1/payments/${id}`, { state, reasonForManualEdit });
  },
  patchCostState: ({ id, state }: ICostPatchState): IApiPromise<ICost> => {
    return axios.patch(`${CONFIG.backOfficeApi}/v1/costs/${id}`, { state });
  },
};
