import { Actions } from '../../common';
import { IAppVersions, IGeneralMetadata } from '../../types';

class MetadataActionsClass extends Actions {
  fetch = this.createAction('fetch');
  set = this.createPayloadAction<IGeneralMetadata>('set');
  fetchAppVersions = this.createAction('fetchAppVersions');
  setAppVersions = this.createPayloadAction<IAppVersions>('setAppVersions');
}

export const MetadataActions = new MetadataActionsClass('metadata');
