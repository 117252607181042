import { Checkbox, Grid } from '@material-ui/core';
import { ColonSeparatedTitle } from '../ColonSeparatedTitle/ColonSeparatedTitle.component';
import React, { ChangeEvent, FC } from 'react';
import { ISgwPermitCondition } from '../../../types';
import { createUseStyles } from 'react-jss';

interface IProps extends ISgwPermitCondition {
  onSelect(event: ChangeEvent<HTMLInputElement>): void;
  checked: boolean;
}

const useStyles = createUseStyles({
  referenceCode: {
    fontSize: 'large',
    paddingRight: '5px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  row: {
    margin: '10px',
  },
});

export const PermitConditionRow: FC<IProps> = ({ id, checked, referenceCode, title, description, onSelect }) => {
  const C = useStyles();

  return (
    <Grid container alignItems="center" className={C.row}>
      <Grid item xs={1}>
        <Checkbox
          inputProps={{
            // @ts-ignore
            'data-testid': `PermitConditionRowCheckbox-${id}`,
          }}
          checked={checked}
          onChange={onSelect}
        />
      </Grid>
      <Grid item xs={2} className={C.referenceCode}>
        {referenceCode}
      </Grid>
      <Grid item xs={9}>
        <ColonSeparatedTitle title={title}>{description}</ColonSeparatedTitle>
      </Grid>
    </Grid>
  );
};
