import moment from 'moment';
import * as React from 'react';
import { FunctionComponent, useEffect, useState } from 'react';
import { MapContainerProps } from 'react-leaflet';

interface IProps extends MapContainerProps {
  format?: string;
}

const Clock: FunctionComponent<IProps> = (props: IProps) => {
  const getTime = () => moment().format(props.format || 'HH:mm:ss');
  const [time, setTime] = useState(getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(getTime());
    }, 1000);
    return () => clearInterval(interval);
  });

  return <>{time}</>;
};
export default Clock;
