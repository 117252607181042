import { ExpansionPanel, ExpansionPanelDetails } from '@material-ui/core';
import React, { FunctionComponent, useMemo } from 'react';
import { ICollapsablePanelProps } from '../../../types';
import classNames from 'classnames';
import { JotaiExpansionPanel, PanelTitle } from '../../atoms';
import { atomWithHash } from 'jotai/utils';
import { CollapsablePanelSummary } from './CollapsablePanelSummary.component';
import { atom, useAtom } from 'jotai';

const defaultAtom = atom(false);

export const CollapsablePanel: FunctionComponent<ICollapsablePanelProps> = ({
  children,
  className,
  classNameDetails,
  noTitleWrap = false,
  onEdit,
  title,
  urlId,
  ...props
}) => {
  const atomUrl = useMemo(() => urlId && atomWithHash(urlId, false), [urlId]);
  const [isOpen] = useAtom(atomUrl || defaultAtom);

  return atomUrl ? (
    <JotaiExpansionPanel
      {...props}
      isExpanded={atomUrl}
      square
      className={classNames('collapsable-expansion-panel', className)}
    >
      <CollapsablePanelSummary
        {...props}
        title={<PanelTitle onEdit={isOpen ? onEdit : undefined}>{title}</PanelTitle>}
        noTitleWrap={noTitleWrap}
      >
        {children}
      </CollapsablePanelSummary>
      <ExpansionPanelDetails className={classNameDetails}>{children}</ExpansionPanelDetails>
    </JotaiExpansionPanel>
  ) : (
    <ExpansionPanel {...props} square className={classNames('collapsable-expansion-panel', className)}>
      <CollapsablePanelSummary {...props} title={<PanelTitle>{title}</PanelTitle>} noTitleWrap={noTitleWrap}>
        {children}
      </CollapsablePanelSummary>
      <ExpansionPanelDetails className={classNameDetails}>{children}</ExpansionPanelDetails>
    </ExpansionPanel>
  );
};
