import React, { FC } from 'react';
import { Unavailable } from '../../../common/components/formatters/unavailable';
import { Visible } from '../../../common/components/layout/Visible.component';
import { translate } from '../../../common/translations/translate';
import { IMessage, IRequestMessage, IRequestMessagesList } from '../../../types';
import { Form } from './form.component';
import { SgwMessage } from './SgwMessage.component';

export interface IProps {
  requestId: number;
  messagesList: IRequestMessagesList;
  saveMessage?(message: IRequestMessage): void;
}

export const SgwMessages: FC<IProps> = ({ requestId, messagesList, saveMessage }) => {
  return (
    <Visible visible={!!messagesList?.acl?.messages_can_view}>
      <div className="messages">
        <Visible
          visible={!!messagesList?.data?.length}
          defaultComponent={<Unavailable text={translate('Messages.Form.NoMessages')} />}
        >
          {messagesList.data.map((item: IMessage) => (
            <SgwMessage requestId={`${requestId}`} key={item.id} message={item} />
          ))}
        </Visible>
        <Visible visible={messagesList.acl.messages_can_edit}>
          <Form requestId={`${requestId}`} messagesList={messagesList} saveMessage={saveMessage} />
        </Visible>
      </div>
    </Visible>
  );
};
