import { makeStyles } from '@material-ui/core/styles';
import { Table, TableContainer, TableCell, TableHead, TableBody, TableRow } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Alert } from '@material-ui/lab';
import * as React from 'react';
import { FunctionComponent, useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { Visible } from '../../../common/components/layout/Visible.component';
import { ServicesActions } from '../../../common/store/services/Services.actions';
import { selectUploadResult } from '../../../common/store/services/Services.selectors';
import { translate } from '../../../common/translations/translate';

const useStyles = makeStyles({
  header: {
    marginBottom: 18,
    marginTop: 18,
    width: '100%',
    justifyContent: 'flex-start',
    display: 'flex',
    flexFlow: 'column',
  },
  message: {
    marginTop: 20,
    width: '100%',
    '& .MuiAlert-message': {
      width: '100%',
    },
  },
  warningButton: {
    marginLeft: 15,
  },
  errorCancelButton: {
    float: 'right',
  },
  errorTableContainer: {
    maxHeight: 300,
    minWidth: 650,
    overflow: 'scroll',
    marginTop: '1em',
    marginBottom: '1em',
  },
});

export const ServicesWhitelistUpload: FunctionComponent = () => {
  const C = useStyles();

  const acceptFileTypes = '.csv, .xls, .xlsx';

  const uploadResult = useSelector(selectUploadResult);

  const uploadFileRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();

  const [uploadFile, setUploadFile] = useState<File>();

  const handleUploadClick = () => {
    // @ts-ignore
    uploadFileRef.current.click();
  };

  const hideAlert = useCallback(() => {
    dispatch(ServicesActions.setUploadResult(null));
  }, [dispatch]);

  const fileUploadHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length) {
        const len = event.target.files.length;
        for (let i = 0; i < len; i++) {
          const file = event.target.files[i] as File;
          setUploadFile(file);
          dispatch(ServicesActions.uploadWhitelistFile({ file }));
        }
      }
    },
    [dispatch, setUploadFile],
  );

  const forceFileUploadHandler = useCallback(() => {
    if (uploadFile) {
      dispatch(ServicesActions.uploadWhitelistFile({ file: uploadFile, ignoreFileLength: true }));
    }
  }, [dispatch, uploadFile]);

  return (
    <>
      <div className={C.header}>
        <div>
          <Button.Green
            dataTestId="serviceUploadButton"
            onClick={handleUploadClick}
            startIcon={<AddIcon fontSize={'small'} />}
          >
            {translate('services.whitelists.uploadButton')}
          </Button.Green>
        </div>
        <div>
          <Visible visible={!!uploadResult}>
            <Alert
              severity={uploadResult?.type === 'info' ? 'success' : 'error'}
              variant="outlined"
              className={C.message}
              action={
                <>
                  {uploadResult?.type === 'can_ignore_error' && (
                    <Button.Red onClick={forceFileUploadHandler} className={C.warningButton}>
                      {translate('services.ignoreTheWarning')}
                    </Button.Red>
                  )}
                </>
              }
            >
              {uploadResult?.mesage}

              {uploadResult?.data && (
                <TableContainer component={'div'} className={C.errorTableContainer}>
                  <Table size={'small'} component="div">
                    <TableHead>
                      <TableCell>{translate('services.whitelists.uploadErrorTable.error')}</TableCell>
                      <TableCell>{translate('services.whitelists.uploadErrorTable.value')}</TableCell>
                      <TableCell>{translate('services.whitelists.uploadErrorTable.line')}</TableCell>
                    </TableHead>
                    <TableBody style={{ maxHeight: 300, overflow: 'scroll' }}>
                      {uploadResult.data.map((item) => (
                        <TableRow>
                          <TableCell>{item.error}</TableCell>
                          <TableCell>{item.value}</TableCell>
                          <TableCell>{item.line}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {uploadResult?.type !== 'info' && (
                <Button.Gray className={C.errorCancelButton} onClick={hideAlert}>
                  {translate('Cancel')}
                </Button.Gray>
              )}
            </Alert>
          </Visible>
        </div>
        <input
          key={Math.random()}
          type="file"
          name="file"
          onChange={fileUploadHandler}
          multiple
          ref={uploadFileRef}
          style={{ display: 'none' }}
          accept={acceptFileTypes}
        />
      </div>
    </>
  );
};
