import React, { useEffect, useState } from 'react';

export const useStickyValue = <T>(value: T): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [stickyValue, setStickyValue] = useState(value);

  useEffect(() => {
    if (value !== undefined) setStickyValue(value);
  }, [value]);

  return [stickyValue, setStickyValue];
};
