import { BaseMap, COLORS, Markers } from '../../../common';
import { GeometryComponent } from '../../../common/components/map/Geometry.component';
import { GeoDrawingType, getPathOptions, IGeometryType } from '../../../types';
import * as React from 'react';
import { useGeoDrawingContext } from '../../../hooks/useGeoDrawingContext.hook';
import { createUseStyles } from 'react-jss';
import { SGW_BASE_MAP_OVERLAY_LAYERS } from '../../../common/config/map.config';
import { useAtom } from 'jotai';
import { visibleConflictsAtom } from '../../pages';
import { useSelector } from 'react-redux';
import { getConflictsOfConflictGroups } from '../../../store/selectors/sgwRequest.selectors';

export const useRequestDetailMapStyles = createUseStyles({
  googleMapsIcon: { width: '13px', marginRight: '5px' },
  locationLinks: { display: 'flex', gap: '10px' },
  panel: {
    margin: '20px 150px',
  },
  phaseDetails: {
    paddingRight: '15px',
  },
  map: {
    maxHeight: '600px',
  },
  selectTitle: {
    fontWeight: '600',
    fontSize: 'large',
    border: `1px solid ${COLORS.GREY_LIGHTER}`,
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    height: '30px',
  },
  viodIcon: { color: COLORS.PRIMARY, marginRight: '2px', marginLeft: '-5px', transform: 'rotate(-45deg)' },
});

export const RequestDetailMap = () => {
  const C = useRequestDetailMapStyles();
  const {
    phaseGeoDrawings: { addMode, bounds, selectedPhase, idToEdit },
    formMethods: { watch, setValue, register },
  } = useGeoDrawingContext();
  const [visibleConflictGroupIds] = useAtom(visibleConflictsAtom);
  const visibleConflicts = useSelector(getConflictsOfConflictGroups(visibleConflictGroupIds));
  const isConstructionZone = watch('geoDrawingType') === GeoDrawingType.constructionzone;

  return (
    <BaseMap
      className={C.map}
      bounds={bounds}
      showLayersControl
      showDrawProps={addMode && !!watch('geoDrawingType')}
      drawProps={{
        allowMultiLines: true,
        beginDrawingWithType: isConstructionZone ? 'polygon' : 'polyline',
        drawTypes: isConstructionZone ? { polyline: false, polygon: true } : { polyline: true, polygon: false },
        onCreated: (geometry) => setValue('geometry', geometry),
      }}
      overlayLayers={SGW_BASE_MAP_OVERLAY_LAYERS}
      selectedOverlayLayers={[]}
    >
      {visibleConflicts.map((conflict) => (
        <GeometryComponent
          geometry={conflict.geometry}
          showMarkers={conflict.geometry.type === IGeometryType.Point}
          pathOptions={{ color: COLORS.RED }}
          icon={conflict.geometry.type === IGeometryType.Point ? Markers.Warning : undefined}
        />
      ))}
      {addMode && watch('geoDrawingType') && (
        <GeometryComponent
          {...register('geometry', { required: true })}
          editable
          geometry={watch('geometry')}
          pathOptions={getPathOptions(watch('geoDrawingType'))}
          geoDrawingType={watch('geoDrawingType')}
          saveGeometry={(geometry) => setValue('geometry', geometry)}
        />
      )}
      {selectedPhase?.sgwGeoDrawings?.map(({ geometry, geoDrawingType, id }) => {
        const otherGeoDrawingIsBeingEdited = !!idToEdit && idToEdit !== id;
        const isBeingEdited = idToEdit === id;

        return (
          <GeometryComponent
            key={id}
            editable={isBeingEdited}
            geometry={isBeingEdited ? watch('geometry') : geometry}
            pathOptions={getPathOptions(geoDrawingType, addMode || otherGeoDrawingIsBeingEdited)}
            geoDrawingType={geoDrawingType}
            saveGeometry={(geometry) => setValue('geometry', geometry)}
            showMarkers={isBeingEdited}
          />
        );
      })}
    </BaseMap>
  );
};
