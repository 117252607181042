import React, { FC, useEffect } from 'react';
import { ExpansionPanelSummary, Grid } from '@material-ui/core';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { Visible } from '../../../common/components/layout/Visible.component';
import { SgwPartnerActions } from '../../../store/actions';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { translate } from '../../../common/translations/translate';
import { Icon } from '../../../common/components/layout/icon.component';
import { PartnerDetail } from './PartnerDetail.component';
import { JotaiExpansionPanel } from '../../atoms';
import { atomWithHash } from 'jotai/utils';
import { ATOM_HASHES } from '../../../common/config/atom.constants';
import { usePartnerContext } from '../../../hooks/usePartnerContext.hook';

const useStyles = createUseStyles({
  panel: {
    '&:before': {
      backgroundColor: 'unset!important',
    },
    borderTop: `1px solid #0000001f`,
    width: '100%',
    boxShadow: 'unset',
  },
  icon: {
    height: '30px!important',
  },
});

export const PartnerContactInfo: FC = () => {
  const C = useStyles();
  const dispatch = useDispatch();
  const { partnerId, partnerType, partner, isSameAsRequester } = usePartnerContext();
  const isExpanded = atomWithHash<boolean>(ATOM_HASHES.partners.open(partnerId), false, { replaceState: true });

  useEffect(() => {
    !isSameAsRequester && partnerId && dispatch(SgwPartnerActions.fetch(`${partnerId}`));
  }, [dispatch, partnerId, isSameAsRequester]);

  const openMailClient = () => (window.location.href = `mailto:${partner?.contactEmailAddress}`);

  return (
    <Visible visible={!!partner}>
      <JotaiExpansionPanel className={C.panel} isExpanded={isExpanded}>
        <ExpansionPanelSummary>
          <Grid container alignItems="center">
            <Grid item xs={2}>
              <Icon.Partner partnerType={partnerType} className={C.icon} />
            </Grid>
            <Grid item xs={4}>
              {`${partner?.contactFirstName} ${partner?.contactLastName}`}
            </Grid>
            <Grid item xs={4}>
              {partner?.contactPhoneNumber}
            </Grid>
            <Grid item xs={2}>
              <Button.Blue type="button" onClick={openMailClient}>
                {translate('sgw.requests.detail.partners.sendEmail')}
              </Button.Blue>
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        <PartnerDetail />
      </JotaiExpansionPanel>
    </Visible>
  );
};
