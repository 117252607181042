import { ISgwPermitConditionsState } from '../../types';
import { ISortingDirection } from '../../common';
import { initialPaging } from '../../common/config/paging.constants';

export const initialSgwPermitConditionsState: ISgwPermitConditionsState = {
  list: {
    allIds: [],
    byId: {},
    table: {
      filters: { active: true },
      paging: initialPaging,
      sorting: {
        direction: ISortingDirection.asc,
        key: 'referenceCode',
      },
    },
    loading: true,
  },
  loading: true,
  existingReferenceCodes: [],
  existingTitles: [],
};
