import { IAclActions } from '../types';
import { useSelector } from 'react-redux';
import { getIsAclUser, getIsRequestAuthorized } from '../common/store/user/user.selectors';
import { selectUser } from '../store/selectors';

export const useRequestAuthorization = (acl: IAclActions | IAclActions[], allAclNeeded: boolean = false) => ({
  isAclUser: useSelector(getIsAclUser),
  isAuthorized: useSelector(getIsRequestAuthorized(Array.isArray(acl) ? acl : [acl], allAclNeeded)),
  user: useSelector(selectUser),
});
