import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { FunctionComponent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { appUrls } from '../../../common/config/url.constants';
import { Drawer } from '@material-ui/core';
import { OverviewComponent } from './overview.component';
import { Filters } from './filters.component';
import { IRequestAclActions, IRequestsFilter } from '../../../types';
import { push } from 'connected-react-router';
import { ListHeader } from './list-header.component';
import { RequestActions } from '../../../store/actions';
import { Auth } from '../../../components';
import { RequestFilterStorage } from '../../../common/utils/filter-storage';

const drawerWidth = 300;

const useStyles = makeStyles({
  drawer: {
    width: drawerWidth - 15,
    '& .MuiDrawer-paperAnchorDockedLeft': {
      'overflow-x': 'hidden',
    },
    '& .MuiDrawer-paper': {
      top: 'inherit',
    },
  },
  filter: {
    width: drawerWidth,
  },
  overview: {
    width: '-webkit-fill-available',
    margin: 20,
  },
});

export const Overview: FunctionComponent = () => {
  const C = useStyles();

  const dispatch = useDispatch();

  const saveFilter = useCallback(
    (filters: IRequestsFilter) => {
      RequestFilterStorage.set(filters);
      dispatch(RequestActions.list.fetch({ filters }));
    },
    [dispatch],
  );

  const onExport = useCallback(() => {
    dispatch(RequestActions.export());
  }, [dispatch]);

  const onExportClose = useCallback(() => {
    dispatch(RequestActions.setExporting(false));
  }, [dispatch]);

  const onNewRequest = useCallback(() => {
    dispatch(push!(appUrls.requests.create));
  }, [dispatch]);

  return (
    <Auth acl={IRequestAclActions.viewRequest} showUnauthorizedPage>
      <div style={{ display: 'flex' }}>
        <Drawer className={C.drawer} variant="permanent">
          <Filters save={saveFilter} style={{ width: drawerWidth }} />
        </Drawer>
        <div className={C.overview}>
          <ListHeader onNewRequest={onNewRequest} onExport={onExport} onSnackbarClose={onExportClose} />
          <OverviewComponent />
        </div>
      </div>
    </Auth>
  );
};
