import axios from 'axios';
import { backendUrl, CONFIG } from '../../common';
import { bulkDownloadUrlPrefix, bulkDownloadUrlPrefix2, IBulkDownload } from '../../types';

export const StorageApi = {
  download: ({ fileIds, id, id2, type }: IBulkDownload) => {
    const query = fileIds.reduce((s, f) => `${s}files[]=${f}&`, '');
    return axios.get(
      `${CONFIG.backOfficeApi}${bulkDownloadUrlPrefix[type]}${id}${
        id2 ? `${bulkDownloadUrlPrefix2[type]}${id2}` : ''
      }/attachments?${query}`,
      { responseType: 'blob' },
    );
  },

  uploadFile: (file: FormData, onUploadProgress?: (progressEvent: any) => void) =>
    axios.post(`${backendUrl}/general/v1/storage`, file, {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress,
    }),
};
