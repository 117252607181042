import axios from 'axios';
import { CONFIG, IApiPromise } from '../..';
import { IRole, IUpdatePassword, IUser, IUserApi, IUserBE } from '../../types/user';

const baseUsersUrl = `${CONFIG.backOfficeApi}/v1/users`;

export const UserApi = {
  getUser: (): IApiPromise<IUser> => axios.get(`${CONFIG.backOfficeApi}/v1/me`),

  fetchUserById: (id: string): IApiPromise<IUser> => axios.get(`${baseUsersUrl}/${id}`),

  getAdminUsers: (): IApiPromise<IUser[]> => axios.get(`${baseUsersUrl}/admin_users`),

  getUsersByName: (name: string): IApiPromise<IUser[]> => axios.get(`${baseUsersUrl}?query=${name}`),

  fetchList: (params?: IUserApi): IApiPromise<IUser[]> => axios.get(baseUsersUrl, { params }),

  fetchAllRoles: (): IApiPromise<IRole[]> => axios.get(`${baseUsersUrl}/roles`),
  deactivateUser: (id: number): IApiPromise<IUser> => axios.delete(`${baseUsersUrl}/${id}`),
  activateUser: (id: number): IApiPromise<IUser> => axios.patch(`${baseUsersUrl}/${id}/undelete`),
  resetPassword: (id: number): IApiPromise<void> => axios.post(`${baseUsersUrl}/${id}/reset_password`),
  updatePassword: (payload: IUpdatePassword) => axios.post(`${baseUsersUrl}/updatePassword`, payload),
  save: ({ id, ...userData }: Partial<IUserBE>): IApiPromise<IUser> =>
    id ? axios.put(`${baseUsersUrl}/${id}`, userData) : axios.post(baseUsersUrl, userData),
};
