import classNames from 'classnames';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Autocomplete } from '../../../common/components/form-fields/autocomplete.component';
import { getAdmins } from '../../../common/store/user/user.selectors';
import { translate } from '../../../common/translations/translate';
import { IUser } from '../../../common/types/user';
import { useGeneralStyles } from '../../../style/generalStyles';
import { AssignButton } from '../../atoms';
import { useStyles } from './SgwRequestResponsiblePanel.styles';

interface IProps {
  onChange(value?: number): void;
  onAssignRequest(toMe: boolean): void;
  canAssign: boolean;
  canUnassign: boolean;
  value?: number;
  enabled?: boolean;
  assignedUser?: IUser;
}

export const AssignResponsibleControl: FC<IProps> = ({
  onChange,
  onAssignRequest,
  value,
  enabled,
  assignedUser,
  canAssign,
  canUnassign,
}) => {
  const C = useStyles();
  const G = useGeneralStyles();
  const admins = useSelector(getAdmins);

  return (
    <div className={classNames(G.flexRowCenter)}>
      <Autocomplete<number>
        placeholder={`${translate('Requests.Header.assign.to')} ${translate('Requests.Header.assign.nobody')}`}
        menuItems={admins}
        onChange={onChange}
        value={value}
        className={C.autoComplete}
        isClearable={false}
        disabled={!enabled}
      />
      <AssignButton
        assignedUser={assignedUser}
        onAssignRequest={onAssignRequest}
        canAssign={canAssign}
        canUnassign={canUnassign}
        showLabels={false}
        visible={enabled}
      />
    </div>
  );
};
