import * as React from 'react';
import { useCallback } from 'react';
import { FormattedDateTime } from '../../common';
import { PanelTitle } from '../../common/components/panel/panel-title.component';
import { INotification } from '../types/notification';
import { WorkOrderItems } from './notification-work-order-items.component';
import { NotificationState } from '../notification.constants';
import { translate, translateIgnoreTS } from '../../common/translations/translate';

interface IProps {
  notification: INotification;
}

export const NotificationTable: React.FunctionComponent<IProps> = ({ notification }) => {
  const getAddress = useCallback((notification: INotification) => {
    return (
      <>
        {notification.streetName} {notification.streetNumber}
        <br />
        {notification.postalCode || ''} {notification.city}
      </>
    );
  }, []);

  return (
    <>
      <dl>
        <dt>{translate('ID')}</dt>
        <dd>{notification.id}</dd>
        <dt>{translate('Notifications.info.createdAt')}</dt>
        <dd>
          <FormattedDateTime dateTime={notification.createdAt} />
        </dd>
        <dt>{translate('Notifications.info.userResponsible')}</dt>
        <dd>{notification.userResponsible && notification.userResponsible.fullName}</dd>
        <dt>{translate('Notifications.info.address')}</dt>
        <dd>{getAddress(notification)}</dd>
        <dt>{translate('Notifications.info.numberOfSigns')}</dt>
        <dd>{notification.numberOfSigns}</dd>
        <dt>{translate('Notifications.info.userComment')}</dt>
        <dd>{notification.userComment}</dd>
        <dt>{translate('Notifications.info.signNumber')}</dt>
        <dd>{notification.signs && notification.signs.map((x) => x.hash).join(', ')}</dd>
        <dt>{translate('Notifications.info.source')}</dt>
        <dd>{notification.sourceFormatted}</dd>

        {notification.statusReason && (
          <>
            <dt>
              {translateIgnoreTS(
                `Notifications.info.${
                  notification.state === NotificationState.invalid ? 'reasonInvalid' : 'reasonHandleInternally'
                }`,
              )}
            </dt>
            <dd>{notification.statusReason}</dd>
          </>
        )}
        {notification.gmpId && (
          <>
            <dt>{translate('Notifications.info.gmpInfo')}</dt>
            <dd>
              {notification.gmpMessageIdentifier} ({notification.gmpId})
            </dd>
          </>
        )}
      </dl>
      <PanelTitle>{translate('Notifications.info.personalInfo')}</PanelTitle>
      <dl>
        <dt>{translate('Notifications.info.name')}</dt>
        <dd>{notification.userName}</dd>
        <dt>{translate('Notifications.info.email')}</dt>
        <dd>
          <a href={`mailto:${notification.userEmail}`} target="_top">
            {notification.userEmail}
          </a>
        </dd>
      </dl>
      <WorkOrderItems notification={notification} />
    </>
  );
};
