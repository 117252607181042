import * as Leaflet from 'leaflet';
import React, { FunctionComponent } from 'react';
import { COLORS } from '../..';
import { IDraggableMarker } from '../../../types';
import { Marker } from './Marker.component';

export const DraggableMarker: FunctionComponent<IDraggableMarker> = ({
  draggable = true,
  onDragMarker,
  onDragMarkerEnd,
  onDragMarkerStart,
  position,
}) => {
  const ondrag = (event: Leaflet.LeafletEvent) => {
    // @ts-ignore - Typing is not correct by leaflet
    onDragMarker?.(event.latlng);
  };
  const ondragend = () => {
    onDragMarkerEnd?.();
  };

  const ondragstart = () => {
    onDragMarkerStart?.();
  };

  return (
    <Marker
      draggable={draggable}
      ondrag={ondrag}
      ondragstart={ondragstart}
      ondragend={ondragend}
      position={position}
      color={draggable ? COLORS.PRIMARY : COLORS.GREY_LIGHT}
    />
  );
};
