import { KeysEnum } from '../common/utils/keysEnum.util';
import { IRequestLocationForm } from './intake.types';

export enum UserType {
  civilian = 'civilian',
  company = 'company',
}

export interface IRequestFormData {
  attachments?: string;
  birthday?: string;
  bus?: string;
  city?: string;
  comment?: string;
  companyId?: string;
  companyName?: string;
  country?: string;
  cluster?: string;
  dateFrom?: string;
  dateUntil?: string;
  emailAddress?: string;
  emergencyProcedure?: boolean;
  entireDay?: boolean;
  eventName?: string;
  externalReference?: string;
  firstName?: string;
  ibanNumber?: string;
  lastName?: string;
  needsPayment?: boolean;
  needsPlacement?: boolean;
  onlyOnWeekdays?: boolean;
  phoneNumber?: string;
  publicDomainIntakes?: Partial<IRequestLocationForm>[];
  reason?: string;
  requestCity?: string;
  requestZipCode?: number;
  requestStreet?: string;
  requestStreetNumber?: string;
  ssn?: string;
  street?: string;
  streetNumber?: string;
  timeFrom?: string;
  timeUntil?: string;
  userRequested?: number;
  userType: UserType;
  zipCode?: string;
}

export const RequestCreateField: KeysEnum<IRequestFormData> = {
  attachments: 'attachments',
  birthday: 'birthday',
  bus: 'bus',
  city: 'city',
  comment: 'comment',
  companyId: 'companyId',
  companyName: 'companyName',
  country: 'country',
  cluster: 'cluster',
  dateFrom: 'dateFrom',
  dateUntil: 'dateUntil',
  emailAddress: 'emailAddress',
  emergencyProcedure: 'emergencyProcedure',
  eventName: 'eventName',
  entireDay: 'entireDay',
  externalReference: 'externalReference',
  firstName: 'firstName',
  ibanNumber: 'ibanNumber',
  lastName: 'lastName',
  needsPayment: 'needsPayment',
  needsPlacement: 'needsPlacement',
  onlyOnWeekdays: 'onlyOnWeekdays',
  phoneNumber: 'phoneNumber',
  publicDomainIntakes: 'publicDomainIntakes',
  reason: 'reason',
  ssn: 'ssn',
  street: 'street',
  streetNumber: 'streetNumber',
  requestCity: 'requestCity',
  requestZipCode: 'requestZipCode',
  requestStreet: 'requestStreet',
  requestStreetNumber: 'requestStreetNumber',
  timeFrom: 'timeFrom',
  timeUntil: 'timeUntil',
  userRequested: 'userRequested',
  userType: 'userType',
  zipCode: 'zipCode',
};

export const ALL_REQUEST_FIELDS = [
  'attachments',
  'birthday',
  'bus',
  'city',
  'cluster',
  'comment',
  'companyId',
  'companyName',
  'country',
  'dateFrom',
  'dateUntil',
  'emailAddress',
  'emergencyProcedure',
  'entireDay',
  'externalReference',
  'eventName',
  'firstName',
  'ibanNumber',
  'lastName',
  'needsPayment',
  'needsPlacement',
  'onlyOnWeekdays',
  'phoneNumber',
  'reason',
  'ssn',
  'street',
  'streetNumber',
  'timeFrom',
  'timeUntil',
  'userRequested',
  'userType',
  'zipCode',
];
