import React, { FC, useCallback } from 'react';
import { Card, Grid, IconButton, Typography } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import moment, { Moment } from 'moment';
import { COLORS } from '../../common';
import { AsignDatepicker } from '../../common/components/form-fields/datepicker/asign-datepicker.component';
import { Loader } from '../../common/components/layout/loader.component';
import { translate } from '../../common/translations/translate';
import { useAuthorization } from '../../hooks';
import { ITeam, IWorkOrder, IWorkOrderItem, IWorkOrderItemAclActions } from '../../types';
import { IWorkOrderItemsFilter } from '../../types';
import { isPlanningDisabled } from '../utils/planning-available';
import { PlanningStatus } from './planning-status.component';
import { TeamStatus } from './team-status.component';

interface IProps {
  filter: IWorkOrderItemsFilter;
  filterChange: (filter: IWorkOrderItemsFilter) => void;
  teams: ITeam[];
  workOrderItems: IWorkOrderItem[];
  workOrderItemsLoading?: boolean;
  workOrdersLoading?: boolean;
  workOrders: IWorkOrder[];
  teamWoiClick: (woi: IWorkOrderItem) => void;
  updateWorkOrder: (order: IWorkOrder) => void;
}

export const PlanningTours: FC<IProps> = (props) => {
  const { isAuthorized: canReorderWorkOrderItems } = useAuthorization(IWorkOrderItemAclActions.reorderWorkOrderItems);
  const planningDisabled = isPlanningDisabled(props.filter) || !canReorderWorkOrderItems;

  const getWorkOrderByTeam = useCallback(
    (team: ITeam): IWorkOrder | undefined => {
      return props.workOrders.find((workOrder) => workOrder.team.id === team.id);
    },
    [props.workOrders],
  );

  const onDayChange = useCallback(
    (selectedDate: Moment): void => {
      props.filterChange({
        ...props.filter,
        date: selectedDate.format('YYYY-MM-DD'),
        due_date_from: moment(props.filter.due_date_from).isSameOrBefore(selectedDate)
          ? selectedDate.format('YYYY-MM-DD')
          : props.filter.due_date_from,
        due_date: moment(props.filter.due_date).isSameOrBefore(selectedDate)
          ? selectedDate.format('YYYY-MM-DD')
          : props.filter.due_date,
      });
    },
    [props],
  );

  const onClickPreviousDate = useCallback((): void => {
    onDayChange(moment(props.filter.date).subtract(1, 'days'));
  }, [onDayChange, props.filter.date]);

  const onClickNextDate = useCallback((): void => {
    onDayChange(moment(props.filter.date).add(1, 'days'));
  }, [onDayChange, props.filter.date]);

  const renderHeader = useCallback(
    (planningDisabled: boolean, hideWarning: boolean = false): React.ReactNode => {
      return (
        <>
          <Grid container={true} alignItems="center" justify="space-between">
            <Grid item={true}>
              <Typography variant="h5">{translate('ToursOn')}</Typography>
            </Grid>
            <Grid item={true}>
              <Card style={{ padding: 5 }}>
                <Grid container={true} alignItems="center" spacing={1}>
                  <Grid item={true}>
                    <IconButton onClick={onClickPreviousDate} style={{ padding: 4 }}>
                      <ChevronLeft />
                    </IconButton>
                  </Grid>
                  <Grid item={true}>
                    <AsignDatepicker
                      style={{ textAlign: 'center' }}
                      value={moment(props.filter.date).utc()}
                      onChange={onDayChange}
                    />
                  </Grid>
                  <Grid item={true}>
                    <IconButton onClick={onClickNextDate} style={{ padding: 4 }}>
                      <ChevronRight />
                    </IconButton>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          {planningDisabled && !hideWarning && (
            <div
              id="planningDisabledWarning"
              style={{
                backgroundColor: COLORS.GREY_LIGHT,
                borderRadius: 20,
                marginTop: 15,
                padding: '7px 15px',
              }}
            >
              {translate('planningDisabled')}
            </div>
          )}
        </>
      );
    },
    [onClickPreviousDate, onClickNextDate, onDayChange, props.filter.date],
  );

  return (
    <>
      <div className="planning-tours-container" data-testid="PlanningToursHeader">
        {renderHeader(planningDisabled, !canReorderWorkOrderItems)}
        {props.workOrderItemsLoading ? (
          <div className={'planning-tours-loader-wrapper'}>
            <Loader />
          </div>
        ) : (
          <PlanningStatus workOrderItems={props.workOrderItems} />
        )}
      </div>
      <div className="planning-tours-container" data-testid="PlanningToursTeams">
        {props.teams.map((team: ITeam) => {
          return (
            <TeamStatus
              teamWoiClick={props.teamWoiClick}
              workOrdersLoading={props.workOrdersLoading}
              team={team}
              key={team.id}
              workOrder={getWorkOrderByTeam(team)}
              updateWorkOrder={props.updateWorkOrder}
              planningDisabled={planningDisabled}
            />
          );
        })}
      </div>
    </>
  );
};
