import AddIcon from '@material-ui/icons/Add';
import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Container, IPaging, ISorting } from '../../../../../../common';
import { Button } from '../../../../../../common/components/buttons/asign-button-extensions';
import { CheckboxWithLabel } from '../../../../../../common/components/form-fields/checkboxWithLabel.component';
import { GenericTablePanel } from '../../../../../../common/components/table/GenericTablePanel.component';
import { appUrls } from '../../../../../../common/config/url.constants';
import { translate } from '../../../../../../common/translations/translate';
import { SgwProjectsActions } from '../../../../../../store/actions';
import { useGeneralStyles } from '../../../../../../style/generalStyles';
import { ISgwProject, ISgwProjectAclActions, ISgwProjectsFilter } from '../../../../../../types';
import { Auth } from '../../../../../atoms';
import { useFormStyles } from '../../../../../../style/form.styles';
import { useProjects } from '../../../../../../hooks/useProjects.hook';

const useStyles = createUseStyles({
  id: {
    width: '15%',
    overflow: 'auto',
  },
  name: {
    width: '30%',
    overflow: 'auto',
  },
});

export const ProjectsPage: FC = () => {
  const C = useStyles();
  const M = useFormStyles();
  const G = useGeneralStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    projects,
    table: { filters, paging, sorting },
  } = useProjects();

  const onChangeSorting = (sorting: ISorting) => dispatch(SgwProjectsActions.list.fetch({ sorting }));
  const onChangePaging = (paging: IPaging) => dispatch(SgwProjectsActions.list.fetch({ paging }));
  const onChangeFiltering = (filters: ISgwProjectsFilter) => dispatch(SgwProjectsActions.list.fetch({ filters }));
  const onChange = () => onChangeFiltering({ ...filters, showDeleted: !filters.showDeleted });
  const onClick = (project: ISgwProject) => history.push(appUrls.sgw.manage.projects.detail(`${project.id}`));
  const onCreateProject = () => history.push(appUrls.sgw.manage.projects.create);

  return (
    <Container>
      <Auth acl={ISgwProjectAclActions.createSgwProject}>
        <Button.Green
          className={M.createButton}
          dataTestId="newProject"
          onClick={onCreateProject}
          startIcon={<AddIcon fontSize={'small'} />}
        >
          {translate('sgw.projects.newProject')}
        </Button.Green>
        <CheckboxWithLabel
          checked={!!filters.showDeleted}
          label={translate('sgw.projects.list.showDeleted')}
          onChange={onChange}
        />
      </Auth>
      <Auth acl={ISgwProjectAclActions.viewSgwProjectOverview}>
        <GenericTablePanel
          dataTestId="SgwProjectsListTable"
          labelUnavailable={translate('sgw.projects.list.unavailable')}
          onChangeSorting={onChangeSorting}
          onChangePaging={onChangePaging}
          onClick={onClick}
          paging={paging}
          sorting={sorting}
          records={projects}
          title={<div className={G.flexRowSpaceBetween}>{translate('sgw.projects.list.title')}</div>}
          columnKey="id"
          columns={[
            {
              label: translate('sgw.projects.columns.id'),
              name: 'id',
              sortable: true,
              className: C.id,
            },
            {
              label: translate('sgw.projects.columns.name'),
              name: 'name',
              sortable: true,
              className: C.name,
            },
            {
              label: translate('sgw.projects.columns.description'),
              name: 'description',
              sortable: true,
            },
          ]}
        />
      </Auth>
    </Container>
  );
};
