import { ServicesApi } from '../common/store/services/Services.api';
import { translate } from '../common/translations/translate';

export const uniqueFieldValidator =
  (list: string[], errorMessage: string, excludedValue?: string) =>
  (value: string): string | undefined => {
    const foundValue = list.find((title) => title === value && title !== excludedValue);
    return foundValue ? errorMessage : undefined;
  };

export const validateAptrUsageCode = async (code: string, initialCode?: string): Promise<string | null> => {
  if (code === initialCode || !code) return null;

  const response = await ServicesApi.validateAptrUsageCode(code);
  if (response?.data?.data?.in_use) {
    return translate('services.validation.usageCodeExists');
  }

  return null;
};
