import { FC } from 'react';
import { BaseMap, Panel } from '../../common';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { getCarFreeZone } from '../../common/store/car-free-zones/carFreeZones.selectors';
import { useParams } from 'react-router';
import { IRouterWithId } from '../../types';
import { MapLayer } from '../../common/components/map/MapLayer.component';
import * as React from 'react';
import { BASE_MAP_OVERLAY_LAYERS, getSelectedCarFreeZoneLayer } from '../../common/config/map.config';
import { SelectableCarFreeZoneLayer } from '../../common/components/map/SelectableCarFreeZoneLayer.component';
import { translate } from '../../common/translations/translate';
import { getBoundsOfGeometries } from '../../common/utils/geojson.util';

const useStyles = createUseStyles(() => ({
  map: {
    maxHeight: '600px',
  },
}));

export const CarFreeZoneMap: FC = () => {
  const C = useStyles();
  const { id } = useParams<IRouterWithId>();
  const carFreeZone = useSelector(getCarFreeZone(id));
  const carFreeZoneLayer = BASE_MAP_OVERLAY_LAYERS.find(({ id }) => id === 10);

  return carFreeZoneLayer ? (
    <Panel title={translate('Location')}>
      <BaseMap
        className={C.map}
        bounds={carFreeZone?.polygon ? getBoundsOfGeometries([carFreeZone?.polygon]) : undefined}
      >
        <MapLayer layer={getSelectedCarFreeZoneLayer(carFreeZone.gisId)} />
        <SelectableCarFreeZoneLayer selectedGisIds={carFreeZone.gisId} />
      </BaseMap>
    </Panel>
  ) : null;
};
