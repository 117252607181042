import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import * as React from 'react';
import { PropsWithChildren, useCallback } from 'react';
import { ISorting, ISortingDirection } from '../../index';
import { Unavailable } from '../formatters/unavailable';
import { Visible } from '../layout/Visible.component';
import { CloseSharp as Close } from '@material-ui/icons';
import { useGeneralStyles } from '../../../style/generalStyles';
import { LinkedTableRow } from './linked-table-row.component';
import { IColumn } from '../../../types/table.types';

interface IProps<T> {
  columnKey: keyof T;
  columns: IColumn<T>[];
  labelUnavailable: string;
  onChangeSorting?(sorting: ISorting): void;
  onClick?: (record: T) => void;
  records?: T[];
  sorting?: ISorting;
  onDelete?: (record: T) => void;
  recordToLink?: (record: T) => string;
}

type MultiSelectProps<T> =
  | { onSelectRecord(record: T): void; multiSelectable: boolean; selectedRecords: T[] }
  | { onSelectRecord?: undefined; multiSelectable?: undefined; selectedRecords?: undefined };

type Props<T> = MultiSelectProps<T> & IProps<T>;

const useStyles = makeStyles({
  clickableRow: { cursor: 'pointer' },
  checkbox: { padding: 0 },
});

export const GenericTable = <T,>({
  columnKey,
  columns,
  labelUnavailable,
  multiSelectable,
  onChangeSorting,
  onClick,
  onDelete,
  onSelectRecord,
  records,
  selectedRecords,
  sorting,
  recordToLink,
}: PropsWithChildren<Props<T>>) => {
  const C = useStyles();
  const G = useGeneralStyles();

  const _onChangeSorting = useCallback(
    (key: string) => () => {
      const direction =
        sorting?.key === key && sorting.direction === 'asc' ? ISortingDirection.desc : ISortingDirection.asc;
      onChangeSorting?.({ key, direction });
    },
    [onChangeSorting, sorting],
  );

  const _onClick = useCallback((record: T) => () => onClick?.(record), [onClick]);
  const _onDelete = useCallback((record: T) => () => onDelete?.(record), [onDelete]);

  const toggleAllSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    records?.forEach((record) => {
      if (event.target.checked) {
        !selectedRecords?.includes(record) && onSelectRecord?.(record);
      } else {
        selectedRecords?.includes(record) && onSelectRecord?.(record);
      }
    });
  };

  const renderRowContent = useCallback(
    (record: T) => (
      <>
        <Visible visible={!!multiSelectable}>
          <TableCell
            component="div"
            key="select"
            onClick={(e) => {
              e.stopPropagation();
              onSelectRecord?.(record);
            }}
          >
            <Checkbox className={C.checkbox} checked={selectedRecords?.includes(record)} />
          </TableCell>
        </Visible>
        {columns
          .filter(({ visible }) => visible === undefined || visible)
          .map((column) => (
            <TableCell component="div" key={`${column.sortKey || String(column.name)}`} className={column.className}>
              {/* @ts-ignore */}
              {column.renderer ? column.renderer(record[column.name], record) : record[column.name]}
            </TableCell>
          ))}
        <Visible key={`delete_${record}`} visible={!!onDelete}>
          <TableCell component="div" onClick={_onDelete(record)}>
            <Close fontSize={'small'} className={G.clickable} />
          </TableCell>
        </Visible>
      </>
    ),
    [multiSelectable, C.checkbox, selectedRecords, columns, onDelete, _onDelete, G.clickable, onSelectRecord],
  );

  return (
    <Table size={'small'} component="div">
      <TableHead component="div">
        <TableRow component="div">
          <Visible visible={!!multiSelectable}>
            <TableCell component="div" key="selectAll">
              <Checkbox className={C.checkbox} onChange={toggleAllSelected} data-testid="SelectAllFromTable" />
            </TableCell>
          </Visible>
          {columns
            .filter(({ visible }) => visible === undefined || visible)
            .map((column) => (
              <TableCell
                component="div"
                key={`${column.sortKey || String(column.name)}`}
                sortDirection={sorting?.direction}
                className={column.className}
              >
                {column.sortable ? (
                  <TableSortLabel
                    active={sorting?.key === (column.sortKey || column.name)}
                    direction={sorting?.direction}
                    onClick={_onChangeSorting(`${column.sortKey || String(column.name)}`)}
                  >
                    {column.label}
                  </TableSortLabel>
                ) : (
                  column.label
                )}
              </TableCell>
            ))}
          <Visible visible={!!onDelete}>
            <TableCell component="div" key={'delete'} />
          </Visible>
        </TableRow>
      </TableHead>
      <TableBody component="div">
        <Visible
          visible={!!records?.length}
          defaultComponent={
            <TableRow component="div">
              <TableCell component="div" colSpan={columns.length}>
                <Unavailable text={labelUnavailable} />
              </TableCell>
            </TableRow>
          }
        >
          {records?.map((record) => {
            return !!recordToLink ? (
              <LinkedTableRow
                hover={!!onClick}
                className={classNames(onClick && C.clickableRow)}
                key={`${record[columnKey]}`}
                href={recordToLink(record)}
              >
                {renderRowContent(record)}
              </LinkedTableRow>
            ) : (
              <TableRow
                component="div"
                hover={!!onClick}
                className={classNames(onClick && C.clickableRow)}
                key={`${record[columnKey]}`}
                onClick={_onClick(record)}
              >
                {renderRowContent(record)}
              </TableRow>
            );
          })}
        </Visible>
      </TableBody>
    </Table>
  );
};
