import { makeStyles } from '@material-ui/core/styles';
import { GpsFixedOutlined } from '@material-ui/icons';
import React, { FunctionComponent } from 'react';

interface IProps {
  resetPosition: () => void;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    overflow: 'hidden',
    position: 'absolute',
    left: 10,
    top: 80,
    zIndex: 800,
    border: '2px solid rgba(0,0,0,0.2)',
    borderRadius: 5,
    width: 34,
    height: 34,
  },
  button: {
    backgroundColor: '#FFF',
    padding: 5,
    cursor: 'pointer',
  },
});

export const MapLocationControles: FunctionComponent<IProps> = ({ resetPosition }) => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <div className={styles.button} onClick={resetPosition}>
        <GpsFixedOutlined fontSize={'small'} />
      </div>
    </div>
  );
};
