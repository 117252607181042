// TODO move to good place
import { LatLngTuple } from "leaflet";

export const DEFAULT_LOCATION = [51.2179465, 4.4044943] as LatLngTuple;
export const DEFAULT_ZOOM = 9;
export const MAX_ZOOM = 21;
export const OPEN_STREET_MAP_URL = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
export const DIGTAAL_VLAANDEREN_MAP_URL =
  'https://geo.api.vlaanderen.be/GRB/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.3.0&FORMAT=image/png&LAYER=grb_bsk&TILEMATRIXSET=GoogleMapsVL&&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}';
export const PARKING_BAN_ZOOM = 17;

export const COLORS = {
  PRIMARY: '#387FF5',
  WHITE: '#fff',
  RED: 'red',
};

// Map constants
export const MAP_BASELAYER_PARAMS = { request: 'getTile', layers: 'GRB_BSK' };
// tslint:disable-next-line:max-line-length
export const MAP_BASELAYER_URL =
  'https://geo.api.vlaanderen.be/GRB/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.3.0&FORMAT=image/png&LAYER=grb_bsk&TILEMATRIXSET=GoogleMapsVL&&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}';
export const MAP_SATELLITE_PARAMS = { request: 'getTile', layers: 'OMWRGBMRVL' };

// tslint:disable-next-line:max-line-length
export const MAP_SATELLITE_LAYER_URL =
  'https://geo.api.vlaanderen.be/OMWRGBMRVL/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.3.0&FORMAT=image/png&LAYER=omwrgbmrvl&TILEMATRIXSET=GoogleMapsVL&&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}';
export const MAP_CARFREEZONE_URL = '//geodata.antwerpen.be/arcgissql/rest/services/P_ASign/ASign/MapServer/15';
export const MAP_DEFAULT_POSITION: LatLngTuple = [51.212082, 4.408321];
export const MAP_MIN_DISTANCE = 1;
export const MAP_DEFAULT_ZOOM = 13;
export const MAP_MAX_ZOOM = 21;
export const MAP_FEATURE_COLOR = '#3388ff';
export const MAP_FEATURE_SELECTED_COLOR = 'green';
export const MAP_FEATURE_CONFLICT_COLOR = 'red';
export const MAP_DEFAULT_LAT_DISTANCE = 0.0001;
export const MAP_DEFAULT_LNG_DISTANCE = 0.0001;
export const MAP_DEFAULT_DRAWINGS_WEIGHT = 10;
export const MAP_BASE_CITY_LAYER_URL =
  'https://tiles.arcgis.com/tiles/1KSVSmnHT2Lw9ea6/arcgis/rest/services/basemap_stadsplan_v6/MapServer';
export const MAP_ARC_GIS_URL = 'https://geodata.antwerpen.be/arcgissql/rest/services/P_ASign/ASign/MapServer';
