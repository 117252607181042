import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { Visible } from '../../../common/components/layout/Visible.component';
import { translate, translateIgnoreTS } from '../../../common/translations/translate';
import { LabelValue } from '../../../common/components/layout/LabelValue.component';
import { Capacity, ISgwRequestDetailAclActions } from '../../../types';
import { selectCountries } from '../../../store/selectors';
import { EditButton } from '../../../request/components/details/edit-button.component';
import { useModalHandler, useRequestAuthorization } from '../../../hooks';
import { PartnerDetailForm } from '../../forms/PartnerDetailForm/PartnerDetailForm.component';
import { usePartnerContext } from '../../../hooks/usePartnerContext.hook';
import { PartnerActionButtons } from './PartnerActionButtons.component';

const useStyles = createUseStyles({
  gridContainer: {
    margin: '0',
  },
  action: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 2rem!important',
    fontWeight: 500,
  },
});

export const PartnerDetail: FC = () => {
  const C = useStyles();
  const { partner, partnerType, isSameAsRequester } = usePartnerContext();
  const countries = useSelector(selectCountries);
  const { open, closeModal, openModal } = useModalHandler();
  const { isAuthorized: canEdit } = useRequestAuthorization(ISgwRequestDetailAclActions.editSgwPartnerInformation);

  return (
    <Grid container className={C.gridContainer} spacing={2}>
      <Visible visible={canEdit}>
        <Grid item xs={12} className={C.action}>
          <EditButton title={translate('sgw.requests.detail.phases.generalInfo.edit')} onClick={openModal} />
        </Grid>
      </Visible>
      <Grid item xs={8}>
        <Grid container spacing={2}>
          <Visible
            visible={!isSameAsRequester}
            defaultComponent={
              <LabelValue label={translate('sgw.partners.columns.partner')}>
                {translate('sgw.requests.detail.partners.sameAsRequester')}
              </LabelValue>
            }
          >
            <LabelValue label={translate('sgw.partners.columns.partner')} visible={!!partnerType}>
              {translateIgnoreTS(`sgw.partners.types.${partnerType}`)}
            </LabelValue>
            <Visible
              visible={partner?.capacity === Capacity.Business}
              defaultComponent={
                <LabelValue
                  visible={partner?.capacity === Capacity.BelgianResident && !!partner?.ssn}
                  label={translate('sgw.partners.columns.ssn')}
                >
                  {partner?.ssn}
                </LabelValue>
              }
            >
              <LabelValue visible={!!partner?.vatNumber} label={translate('sgw.partners.columns.vatNumber')}>
                {partner?.vatNumber}
              </LabelValue>
              <LabelValue visible={!!partner?.companyName} label={translate('sgw.partners.columns.companyName')}>
                {partner?.companyName}
              </LabelValue>
            </Visible>
            <LabelValue
              visible={!!partner?.firstName || !!partner?.lastName}
              label={translate('sgw.partners.columns.name')}
            >
              {`${partner?.firstName || ''} ${partner?.lastName || ''}`}
            </LabelValue>
            <LabelValue visible={!!partner?.contactEmailAddress} label={translate('sgw.partners.columns.email')}>
              {partner?.contactEmailAddress}
            </LabelValue>
            <LabelValue visible={!!partner?.zipCode || !!partner?.city} label={translate('sgw.partners.columns.city')}>
              {`${partner?.zipCode || ''} ${partner?.city || ''} `}
            </LabelValue>
            <LabelValue visible={!!partner?.country} label={translate('sgw.partners.columns.country')}>
              {countries?.find(({ country }) => country === partner?.country)?.name}
            </LabelValue>
          </Visible>
        </Grid>
      </Grid>
      <Visible visible={canEdit}>
        <PartnerActionButtons openForm={openModal} />
      </Visible>
      <PartnerDetailForm visible={open} onCloseModal={closeModal} />
    </Grid>
  );
};
