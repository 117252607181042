import * as I18n from 'i18n-js';
import * as React from 'react';
import { FC, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { IPaging, ISorting } from '../../common';
import { GenericTablePanel } from '../../common/components/table/GenericTablePanel.component';
import { appUrls } from '../../common/config/url.constants';
import { IModule } from '../types/module';
import { IModulesFilter } from '../types/modules-filter';
import { ModulesListFilters } from './ModulesListFilters.component';
import { useDispatch, useSelector } from 'react-redux';
import { getModulesList, selectModulesTable } from '../store/modules.selectors';
import { ModulesActions } from '../store/modules.actions';

export const ModulesList: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { filters, paging, sorting } = useSelector(selectModulesTable);
  const modules = useSelector(getModulesList);

  useEffect(() => {
    dispatch(ModulesActions.list.fetch({}));
  }, [dispatch]);

  const onChangeSorting = useCallback(
    (sorting: ISorting) => {
      dispatch(ModulesActions.list.fetch({ sorting }));
    },
    [dispatch],
  );

  const onChangePaging = useCallback(
    (paging: IPaging) => {
      dispatch(ModulesActions.list.fetch({ paging }));
    },
    [dispatch],
  );

  const onFilterChange = useCallback(
    (filters: IModulesFilter): void => {
      dispatch(ModulesActions.list.fetch({ filters }));
    },
    [dispatch],
  );

  const onClick = useCallback(
    (module: IModule) => {
      history.push(appUrls.signs.modules.detail(module.id));
    },
    [history],
  );

  return (
    <GenericTablePanel<IModule>
      customHeader={<ModulesListFilters modulesFilter={filters} modulesFilterChange={onFilterChange} />}
      labelUnavailable={I18n.t('Modules.Unavailable')}
      onClick={onClick}
      onChangeSorting={onChangeSorting}
      onChangePaging={onChangePaging}
      paging={paging}
      sorting={sorting}
      records={modules}
      columnKey="id"
      columns={[
        {
          label: I18n.t('ModuleID'),
          name: 'id',
          sortable: true,
        },
        {
          label: I18n.t('Profile'),
          name: 'state',
          renderer: (state) => state.name,
          sortable: true,
        },
        {
          label: I18n.t('ApplicationVersion'),
          name: 'applicationVersion',
          sortable: true,
        },
        {
          label: I18n.t('Batch'),
          name: 'batch',
          renderer: (batch) => batch?.name,
          sortable: true,
        },
        {
          label: I18n.t('InMaintenance'),
          name: 'inMaintenance',
          renderer: (inMaintenance) => (inMaintenance ? I18n.t('yes') : I18n.t('no')),
          sortable: true,
        },
        {
          label: I18n.t('Location'),
          name: 'place',
          renderer: (place) => place.name,
          sortable: true,
        },
      ]}
    />
  );
};
