import moment from 'moment';
import { IPaging, ISortingDirection } from '../../common';
import { ISign } from '../types/sign';
import { ISignCondition } from '../types/sign-condition';
import { ISignCounters } from '../types/sign-counters';
import { ISignData } from '../types/sign-data';
import { ISignLocation } from '../types/sign-location';
import { ISignState } from '../types/sign-state';
import { ISignStateHistory } from '../types/sign-state-history';
import { ISignType } from '../types/sign-type';
import { ISignsFilter } from '../types/signs-filter';
import { ISignsForecast } from '../types/signs-forecast';
import { ISignMap, ISignMapPopup, ISignsMapFilter } from '../types/signs-map';
import { IPagedTableState } from '../../types';

export interface ISignsState {
  list: IPagedTableState<ISign, ISignsFilter>;
  sign: ISign | null;
  signActiveLocation: ISignLocation | null;
  signActiveLocationData: ISignData[];
  signConditions: ISignCondition[];
  signCounters: ISignCounters;
  signErrors: string[] | null;
  signGPSLogs: ISignData[];
  signStates: ISignState[];
  signStateHistory: ISignStateHistory[];
  signTypes: ISignType[];
  signLoading: boolean;
  signLocations: ISignLocation[];
  signLocationsPaging: IPaging;
  signsMap: ISignMap | null;
  signsMapPopup: ISignMapPopup | null;
  signsFilterDataLoaded: boolean;
  signsForecast: ISignsForecast[];
  signsForecastLoading: boolean;
  signsWoiForecast: ISignsForecast[];
  signsWoiForecastLoading: boolean;
}

export const initialSignsState: ISignsState = {
  list: {
    allIds: [],
    byId: {},
    table: {
      filters: {
        conditions: [],
        moduleId: '',
        noConnection: false,
        noGPS: false,
        notExtended: false,
        signId: '',
        states: [],
        types: [],
      },
      paging: {
        page: 1,
        pageSize: 20,
        totalPages: 0,
        totalRecords: 0,
      },
      sorting: {
        direction: ISortingDirection.asc,
        key: 'state',
      },
    },
    loading: true,
  },
  sign: null,
  signActiveLocation: null,
  signActiveLocationData: [],
  signConditions: [],
  signCounters: {
    damaged: 0,
    missing: 0,
    lowBattery: 0,
    moved: 0,
    noConnection: 0,
    noGPS: 0,
    notExtended: 0,
    placed: 0,
    requireMaintenance: 0,
    stored: 0,
  },
  signErrors: null,
  signGPSLogs: [],
  signLoading: true,
  signLocations: [],
  signLocationsPaging: {
    page: 1,
    pageSize: 10,
    totalPages: 0,
    totalRecords: 0,
  },
  signStateHistory: [],
  signStates: [],
  signTypes: [],
  signsFilterDataLoaded: false,
  signsForecast: [],
  signsForecastLoading: true,
  signsWoiForecast: [],
  signsWoiForecastLoading: true,
  signsMap: null,
  signsMapPopup: null,
};

export const initialSignsMapFilters: ISignsMapFilter = {
  lastUpdatedFrom: moment().subtract(6, 'months').format('YYYY-MM-DD'),
  lastUpdatedTo: undefined,
  state: ' ',
  type: ' ',
};
