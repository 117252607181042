import { Button } from '@material-ui/core';
import I18n from 'i18n-js';
import * as React from 'react';

export const SubmitButton: React.FC = () => {
  return (
    <Button variant="contained" color="primary" type="submit">
      {I18n.t('SaveChanges')}
    </Button>
  );
};
