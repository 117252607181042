import React, { ChangeEvent, FC, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { AddressFields, CompanyFields, ContactPersonFields, PersonalInfoFields, RadioButtonList } from '../../atoms';
import { translate, translateIgnoreTS } from '../../../common/translations/translate';
import { Capacity } from '../../../types';
import { Visible } from '../../../common/components/layout/Visible.component';
import { createUseStyles } from 'react-jss';
import { useSgwPartnerForm } from '../../../hooks';
import ModalDialog from '../../../common/components/modal-dialog/modal-dialog.component';
import { usePartnerContext } from '../../../hooks/usePartnerContext.hook';

const useStyles = createUseStyles({
  fieldsContainer: {
    marginTop: '1.5rem',
  },
});

interface IProps {
  visible: boolean;
  onCloseModal(): void;
  createNew?: boolean;
}

export const PartnerDetailForm: FC<IProps> = ({ visible, onCloseModal }) => {
  const { createMode, partnerId } = usePartnerContext();
  const { formMethods, submitPartner } = useSgwPartnerForm();
  const { register, watch, setValue, reset } = formMethods;
  const capacity = watch('capacity');

  useEffect(() => {
    createMode && reset({ capacity: Capacity.Business });
  }, [createMode, reset]);

  const onChangeCapacity = (e: ChangeEvent<HTMLInputElement>) => setValue('capacity', e.target.value as Capacity);

  return (
    <ModalDialog
      okButtonText={translate('sgw.partners.form.submit')}
      onClose={onCloseModal}
      onConfirm={submitPartner}
      title={translateIgnoreTS(`sgw.partners.form.${createMode ? 'createTitle' : 'updateTitle'}`)}
      visible={visible}
      maxWidth="lg"
    >
      <FormProvider {...formMethods}>
        <form id={'requestPartnerForm'}>
          <RadioButtonList
            {...register('capacity', { required: true })}
            id={`${partnerId}-partner`}
            initialValue={capacity}
            value={capacity}
            onChange={onChangeCapacity}
          >
            <RadioButtonList.Header>{translate('sgw.partners.form.infoIdentity')}</RadioButtonList.Header>
            <RadioButtonList.Item value={Capacity.Business}>
              {translate('sgw.partners.form.options.business')}
            </RadioButtonList.Item>
            <RadioButtonList.Item value={Capacity.BelgianResident}>
              {translate('sgw.partners.form.options.belgian_resident')}
            </RadioButtonList.Item>
            <RadioButtonList.Item value={Capacity.NonBelgianResident}>
              {translate('sgw.partners.form.options.non_belgian_resident')}
            </RadioButtonList.Item>
          </RadioButtonList>
          <div className={useStyles().fieldsContainer}>
            <Visible visible={[Capacity.BelgianResident, Capacity.NonBelgianResident].includes(capacity)}>
              <PersonalInfoFields showSsn={capacity === Capacity.BelgianResident} />
            </Visible>
            <Visible visible={capacity === Capacity.Business}>
              <CompanyFields />
            </Visible>
            <AddressFields />
          </div>
          <ContactPersonFields />
        </form>
      </FormProvider>
    </ModalDialog>
  );
};
