import { useFormContext, useWatch } from 'react-hook-form';
import { ValidateResult } from 'react-hook-form/dist/types/validator';
import { translate, translateIgnoreTS } from '../common/translations/translate';
import { VatApi } from '../store/api/vat.api';

export const useVatValidator: (
  shouldValidate: boolean,
  vatNumberField: string,
  companyNameField: string,
) => (vatValue?: string) => Promise<ValidateResult> = (shouldValidate, vatNumberField, companyNameField) => {
  const { setError, setValue } = useFormContext();
  const companyName = useWatch({ name: companyNameField });

  return async (vatValue?: string) => {
    if (!shouldValidate) return true;
    try {
      const response = await VatApi.check(vatValue);
      const vatInfo = response.data.data;
      if (!vatInfo.valid) {
        return translateIgnoreTS(`noValidVat.${vatInfo.issuedBy || 'general'}`);
      }

      if (vatInfo.vatNumber) {
        setValue(vatNumberField, vatInfo.vatNumber);
      }

      if (vatInfo.name) {
        setValue(companyNameField, vatInfo.name);
      } else if (!companyName) {
        setError(companyNameField, {
          type: 'manual',
          message: translate('required'),
        });
      }
    } catch (e) {
      return translate('noValidVat.general');
    }

    return true;
  };
};
