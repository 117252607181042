import { makeStyles } from '@material-ui/core/styles';
import * as nl from 'date-fns/locale/nl';
import moment, { Moment } from 'moment';
import React, { FunctionComponent } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { IComponentProps } from '../../../../types';
import { AsignDatepickerInput } from './asign-datepicker-input.component';

interface IProps extends IComponentProps {
  disabled?: boolean;
  value?: Moment;
  onChange: (value: any) => void;
  label?: string;
  minDate?: Moment;
  maxDate?: Moment;
  options?: object;
  id?: string;
  fullWidth?: boolean;
  required?: boolean;
}

const useStyles = makeStyles({
  popper: {
    zIndex: 3,
  },
});

export const AsignDatepicker: FunctionComponent<IProps> = ({
  disabled = false,
  value,
  onChange,
  label,
  minDate,
  maxDate,
  options,
  id,
  style,
  className,
  fullWidth = false,
  required,
}) => {
  registerLocale('nl', nl);
  const C = useStyles();

  const _onChange = (selectedDate: Date) => {
    onChange(moment(selectedDate));
  };

  return (
    <DatePicker
      disabled={disabled}
      fixedHeight
      disabledKeyboardNavigation
      id={id || 'datepicker'}
      customInput={
        <AsignDatepickerInput
          disabled={disabled}
          style={style}
          className={className}
          setDate={_onChange}
          label={label}
          minDate={minDate}
          maxDate={maxDate}
          fullWidth={fullWidth}
          required={required}
        />
      }
      openToDate={value && value.isValid() ? value.toDate() : moment().toDate()}
      selected={value && value.isValid() ? value.toDate() : undefined}
      dateFormat={'E dd/MM/yyyy'}
      onChange={_onChange}
      popperPlacement={'bottom'}
      locale={'nl'}
      minDate={minDate ? minDate.toDate() : null}
      maxDate={maxDate ? maxDate.toDate() : null}
      wrapperClassName={className}
      popperClassName={C.popper}
      required={required}
      {...options}
    />
  );
};
