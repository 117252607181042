import { useEffect } from 'react';

export const usePageActivation = (onActivate: () => void, onHide?: () => void) => {
  useEffect(() => {
    const listener = () => {
      if (document.hidden) {
        onHide?.();
      } else {
        onActivate();
      }
    };
    document.addEventListener('visibilitychange', listener);
    return () => document.removeEventListener('visibilitychange', listener);
  }, [onActivate, onHide]);
};
