import { IRootState } from '../../root.state';
import { createSelector } from '@reduxjs/toolkit';
import { getPhaseById } from './sgwRequest.selectors';

export const selectMetadata = (state: IRootState) => state.metadata;
export const selectTrafficMeasures = (state: IRootState) => state.metadata.general.sgw.trafficmeasures;
export const selectNatureOfWorks = (state: IRootState) => state.metadata.general.sgw.natureofworks;
export const selectCountries = (state: IRootState) => state.metadata.general.countries;
export const selectAppVersions = (state: IRootState) => state.metadata.appVersions;

export const getNatureOfWorks = createSelector(
  [selectNatureOfWorks],
  (natureOfWorks) => natureOfWorks?.filter(({ deleted }) => !deleted) || [],
);

export const getTrafficMeasures = createSelector(
  [selectTrafficMeasures],
  (trafficMeasures) => trafficMeasures?.filter(({ deleted }) => !deleted) || [],
);

export const getNatureOfWorksById = (ids: number[]) =>
  createSelector([getNatureOfWorks], (natureOfWorks) =>
    natureOfWorks.filter((item) => ids?.includes(item.id)).map((item) => item.name),
  );

export const getTrafficMeasuresById = (ids: number[]) =>
  createSelector([getTrafficMeasures], (trafficMeasures) =>
    trafficMeasures.filter((item) => ids?.includes(item.id)).map((item) => item.name),
  );

export const getNatureOfWorksOptions = createSelector([getNatureOfWorks], (natureOfWorks) =>
  natureOfWorks.map(({ id, name }) => ({ label: name, value: id })),
);
export const getTrafficMeasuresOptions = createSelector([getTrafficMeasures], (trafficMeasures) =>
  trafficMeasures.map(({ id, name }) => ({ label: name, value: id })),
);

export const getDistinctTrafficMeasuresForPhase = (id: number) =>
  createSelector([selectTrafficMeasures, getPhaseById(id)], (trafficMeasures, phase) =>
    phase?.sgwGeoDrawings
      .flatMap((geodrawing) => geodrawing.sgwTrafficMeasures)
      .filter((value, index, self) => self.indexOf(value) === index && trafficMeasures.some(({ id }) => id === value))
      .map((tmId) => trafficMeasures.find(({ id }) => id === tmId)),
  );
