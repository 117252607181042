import { makeStyles } from '@material-ui/core/styles';
import { WarningOutlined } from '@material-ui/icons';
import LinkIcon from '@material-ui/icons/Link';
import { Alert } from '@material-ui/lab';
import * as I18n from 'i18n-js';
import { LatLngBounds } from 'leaflet';
import * as React from 'react';
import { FunctionComponent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { COLORS } from '../../../common';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { PanelContent } from '../../../common/components/panel/panel-content.component';
import { getBoundsOfGeometries } from '../../../common/utils/geojson.util';
import { RequestActions } from '../../../store/actions';
import { selectCurrentConflictKey } from '../../../store/selectors';
import { IFeature } from '../../../types';
import { appUrls } from '../../../common/config/url.constants';
interface IProps {
  conflicts: IFeature[];
  requestId: string;
  setBounds: (bounds: LatLngBounds) => void;
}

const useStyles = makeStyles({
  title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  conflict: {
    backgroundColor: '#F5F5F5',
    marginBottom: 3,
  },
  conflictSelected: {
    backgroundColor: '#FFF1F0',
  },
  button: { marginLeft: 10, minWidth: 40 },
  viodIcon: { color: COLORS.PRIMARY, transform: 'rotate(-45deg)' },
});

const ConflictItem: FunctionComponent<{ conflict: IFeature; setBounds: (bounds: LatLngBounds) => void }> = ({
  conflict,
  setBounds,
}) => {
  const C = useStyles();
  const dispatch = useDispatch();
  const selected = useSelector(selectCurrentConflictKey) === conflict.properties.key;

  const mouseover = useCallback(() => {
    dispatch(RequestActions.setCurrentConflictKey(conflict.properties.key));
  }, [dispatch, conflict.properties.key]);

  const mouseout = useCallback(() => {
    dispatch(RequestActions.setCurrentConflictKey(null));
  }, [dispatch]);

  const flyToCenter = useCallback(() => {
    setBounds(getBoundsOfGeometries([conflict.geometry]));
  }, [setBounds, conflict.geometry]);

  const link = conflict.properties.type === 'asign' ? appUrls.requests.detail(conflict.properties.id, true) : null;

  return (
    <Alert
      onClick={flyToCenter}
      className={C.conflict + ' ' + (selected ? C.conflictSelected : '')}
      icon={<WarningOutlined color={'error'} />}
      onMouseOver={mouseover}
      onMouseOut={mouseout}
      action={
        link && (
          <Button.Link
            className={C.button}
            onClick={() => {
              window.open(link);
            }}
          >
            <LinkIcon className={C.viodIcon} />
          </Button.Link>
        )
      }
    >
      {conflict.properties.title}
      {conflict.properties.type !== 'asign' && ` - ${conflict.properties.type}`}
    </Alert>
  );
};

export const LocationConflicts: FunctionComponent<IProps> = ({ conflicts, setBounds }) => {
  const C = useStyles();

  return (
    <PanelContent title={<span className={C.title}>{I18n.t('Requests.Detail.Locations.Conflicts.Title')}</span>}>
      {conflicts.map((conf) => (
        <ConflictItem key={conf.properties.key} conflict={conf} setBounds={setBounds} />
      ))}
      {conflicts.length === 0 && I18n.t('Requests.Detail.Locations.Conflicts.NoConflicts')}
    </PanelContent>
  );
};
