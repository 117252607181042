import { translate } from '../../../common/translations/translate';
import { PermitConditionsFilter } from '../../organisms';
import { PermitConditionCategory } from '../PermitConditionCategory/PermitConditionCategory.component';
import ModalDialog from '../../../common/components/modal-dialog/modal-dialog.component';
import React, { FC, SetStateAction, useState } from 'react';
import { ISgwPermitConditionsFilter, SgwPermitConditionCategory } from '../../../types';

interface IProps {
  categories: SgwPermitConditionCategory[];
  onClose(): void;
  editMode?: boolean;
  onConfirm(): void;
  selectedIds: number[];
  setSelectedIds(value: SetStateAction<number[]>): void;
}

export const PermitConditionsDialog: FC<IProps> = ({
  categories,
  editMode,
  onClose,
  onConfirm,
  selectedIds,
  setSelectedIds,
}) => {
  const [filters, setFilters] = useState<ISgwPermitConditionsFilter>({});

  return (
    <ModalDialog
      maxWidth="lg"
      onClose={onClose}
      title={translate('sgw.requests.detail.permitConditions.title')}
      visible={editMode}
      okButtonText={translate('done')}
      onConfirm={onConfirm}
    >
      <PermitConditionsFilter
        changeFilters={setFilters}
        filters={filters}
        resetFilters={() => setFilters({})}
        showDeletedCheckbox={false}
      />
      {categories
        .filter((category) => !filters.category?.length || filters.category.includes(category))
        .map((category) => (
          <PermitConditionCategory
            filters={filters}
            selectedIds={selectedIds}
            key={category}
            category={category}
            addPermitCondition={(id: number) => setSelectedIds((previous) => [...previous, id])}
            removePermitCondition={(id: number) => setSelectedIds((previous) => previous.filter((prev) => id !== prev))}
          />
        ))}
    </ModalDialog>
  );
};
