import * as React from 'react';
import { FunctionComponent, useRef, useEffect, useState } from 'react';
import { MapContainer, MapContainerProps } from 'react-leaflet';
import { BaseLayer, CONFIG } from '../..';
import * as L from 'leaflet';
import { Control, LatLngBounds, LatLngExpression } from 'leaflet';
import { IMapDrawProps, IMapLayer } from '../../../types';
import { Visible } from '../layout/Visible.component';
import { MapLayersControl } from './MapLayersControl.component';
import { MapLoader } from './MapLoader.component';
import { Draw } from './Draw.component';
import { BaseMapProvider } from './BaseMapProvider.component';
import { useBaseMap } from './BaseMap.context';
interface IProps extends MapContainerProps {
  bounds?: LatLngBounds;
  flyToAnimation?: boolean;
  height?: number | string;
  loading?: boolean;
  maxZoom?: number;
  drawProps?: Partial<IMapDrawProps>;
  showDrawProps?: boolean;
  showLayersControl?: boolean;
  showOverviewControl?: boolean;
  disabled?: boolean;
  onpopupclose?(e: L.PopupEvent): void;
  overlayLayers?: IMapLayer[];
  selectedOverlayLayers?: number[];
}

export const BaseMap: FunctionComponent<IProps> = ({ overlayLayers, selectedOverlayLayers, ...props }) => (
  <BaseMapProvider defaultOverlayLayers={overlayLayers} defaultSelectedOverlayLayers={selectedOverlayLayers}>
    <BaseMapWithContext {...props} />
  </BaseMapProvider>
);

export const BaseMapWithContext: FunctionComponent<IProps> = ({
  bounds,
  center,
  drawProps,
  flyToAnimation = false,
  height,
  loading,
  maxZoom = 19,
  showDrawProps = false,
  showLayersControl = false,
  disabled = false,
  onpopupclose,
  ...props
}) => {
  const { mapRef, selectBaseLayer, addOverlay, removeOverlay, flyToBounds } = useBaseMap();

  const [originCenter, setOriginCenter] = useState<LatLngExpression>();
  const zoomRef = useRef<Control.Zoom>();

  mapRef?.current?.on('overlayadd', (e) => addOverlay(e as L.LayersControlEvent));
  mapRef?.current?.on('overlayremove', (e) => removeOverlay(e as L.LayersControlEvent));
  mapRef?.current?.on('baselayerchange', (e) => selectBaseLayer(e as L.LayersControlEvent));
  mapRef?.current?.on('popupclose', (e) => onpopupclose?.(e as L.PopupEvent));

  useEffect(() => {
    bounds?.isValid() && flyToBounds(bounds, maxZoom);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bounds]);

  useEffect(() => {
    if (!originCenter) {
      setOriginCenter(center || CONFIG.defaultMapLocation.center);
    } else if (mapRef?.current && center) {
      mapRef?.current.flyTo(center, props.zoom, { animate: flyToAnimation });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center]);

  useEffect(() => {
    const map = mapRef?.current;

    if (map?.zoomControl) {
      // save the zoom control if it is defined, so it can be set back when moving from disabled to enabled
      zoomRef.current = map?.zoomControl;
    }

    if (disabled) {
      map?.dragging.disable();
      map?.touchZoom.disable();
      map?.doubleClickZoom.disable();
      map?.scrollWheelZoom.disable();
      map?.boxZoom.disable();
      map?.keyboard.disable();
      map?.tap?.disable();
    } else {
      map?.dragging.enable();
      map?.touchZoom.enable();
      map?.doubleClickZoom.enable();
      map?.scrollWheelZoom.enable();
      map?.boxZoom.enable();
      map?.keyboard.enable();
      map?.tap?.disable();
    }

    if (zoomRef.current) {
      disabled ? map?.removeControl(zoomRef.current) : map?.addControl(zoomRef.current);
    }
  }, [mapRef, disabled]);

  return (
    <MapContainer
      {...CONFIG.defaultMapLocation}
      ref={mapRef}
      style={{ height: height || '100vh', width: '100%' }}
      {...props}
    >
      <Visible visible={!!loading}>
        <MapLoader />
      </Visible>
      <Visible visible={!showLayersControl || disabled}>
        <BaseLayer />
      </Visible>
      <Visible visible={showLayersControl && !disabled}>
        <MapLayersControl />
      </Visible>
      <Visible visible={showDrawProps}>
        <Draw {...drawProps} />
      </Visible>
      {props.children}
    </MapContainer>
  );
};
