import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { IFormikTextField, IWithClassName, IWithStyle } from '../../../types';

export interface IProps extends IFormikTextField, IWithStyle, IWithClassName {
  fullWidth?: boolean;
  multiline?: boolean;
  inputProps?: Object;
}

export const FormikTextField: FunctionComponent<IProps> = ({
  className,
  name,
  label,
  type,
  required = true,
  style,
  validate,
  value,
  disabled,
  readOnly = false,
  multiline = false,
  inputProps,
  fullWidth = true,
}) => (
  <Field
    component={TextField}
    className={className}
    disabled={disabled || readOnly}
    name={name}
    type={type || 'text'}
    label={label}
    required={required}
    style={fullWidth ? { width: '100%', ...style } : style}
    placeholder={''}
    validate={validate}
    InputProps={value !== undefined ? { value, inputProps } : { inputProps }}
    InputLabelProps={value !== undefined ? { shrink: !!value } : {}}
    multiline={multiline}
  />
);
