import { IReorderingWorkOrder } from '../types/reordering-work-order';
import { IReorderingWorkOrderItem } from '../types/reordering-work-order-item';

export interface IReorderingState {
  hoveredWorkOrderItem: IReorderingWorkOrderItem | null;
  loading: boolean;
  markerPopup: IReorderingWorkOrderItem | null;
  workOrder: IReorderingWorkOrder | null;
}

export const initialReorderingState: IReorderingState = {
  hoveredWorkOrderItem: null,
  loading: true,
  markerPopup: null,
  workOrder: null,
};
