import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as I18n from 'i18n-js';
import moment from 'moment';
import { default as React, FunctionComponent, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Unavailable } from '../../../../common/components/formatters/unavailable';
import { CollapsablePanel } from '../../../../components';
import { RequestLocationsActions } from '../../../../store/actions';
import { getIsAddingLicensePlatesAllowed, getIsBackOfficeUser, getRequestsDetails } from '../../../../store/selectors';
import { CarFreeZoneType, IPermittedPlate, IPublicDomainIntakeExtended } from '../../../../types';
import { CarFreeZoneLicensePlatesEditor } from './car-free-zone-license-plates.editor.component';

interface IProps {
  location: IPublicDomainIntakeExtended;
  requestId: string;
}

const useStyles = makeStyles({
  unavailable: {
    marginBottom: '15px',
  },
});

export const CarFreeZoneLicensePlatesContainer: FunctionComponent<IProps> = ({ location, requestId }) => {
  const C = useStyles();

  const request = useSelector(getRequestsDetails(requestId));
  const addingLicensePlatesAllowed = useSelector(getIsAddingLicensePlatesAllowed(requestId, location.id));
  const backofficeuser = useSelector(getIsBackOfficeUser);

  const dispatch = useDispatch();

  const [anprRegularisation, setAnprRegularisation] = useState<boolean>(location?.anprRegularisation || false);

  const toggleAnprRegularisation = useCallback(() => {
    setAnprRegularisation(!anprRegularisation);
  }, [anprRegularisation, setAnprRegularisation]);

  const onPermittedPlatesChange = useCallback(
    (permittedPlates: IPermittedPlate[]) => {
      addingLicensePlatesAllowed &&
        dispatch(
          RequestLocationsActions.patch({
            id: `${location.permitRequestId}`,
            locationId: `${location.id}`,
            anprRegularisation,
            permittedPlates,
          }),
        );
    },
    [anprRegularisation, dispatch, addingLicensePlatesAllowed, location],
  );

  return request ? (
    <CollapsablePanel title={I18n.t('Requests.Detail.CarFreeZoneLicensePlates.Title')}>
      <Grid container>
        {!addingLicensePlatesAllowed && (
          <Unavailable
            className={C.unavailable}
            text={I18n.t('Requests.Detail.CarFreeZoneLicensePlates.PeriodExpired')}
          />
        )}
        {location.carFreeZone!.type === CarFreeZoneType.anpr && addingLicensePlatesAllowed && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={anprRegularisation}
                  onChange={toggleAnprRegularisation}
                  disabled={!addingLicensePlatesAllowed}
                />
              }
              label={I18n.t('Requests.Detail.CarFreeZoneLicensePlates.ANPRRegularisation')}
            />
          </Grid>
        )}
        {location.carFreeZone!.type === CarFreeZoneType.anpr && !backofficeuser && addingLicensePlatesAllowed && (
          <Unavailable text={I18n.t('Requests.Detail.CarFreeZoneLicensePlates.YouHave48Hours')} />
        )}
        <Grid item xs={12}>
          <CarFreeZoneLicensePlatesEditor
            addingLicensePlatesAllowed={addingLicensePlatesAllowed}
            carFreeZoneType={location.carFreeZone?.type}
            isAnprRegularisation={anprRegularisation}
            permittedPlates={location.permittedPlates || []}
            requestId={requestId}
            locationId={location.id}
            dateFrom={moment().subtract(9, 'days')}
            dateUntil={moment(request.dateUntil, 'YYYY-MM-DD')}
            onPermittedPlatesChange={onPermittedPlatesChange}
          />
        </Grid>
      </Grid>
    </CollapsablePanel>
  ) : null;
};
