import * as I18n from 'i18n-js';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { FormattedDateTime } from '../../../common';
import { Unavailable } from '../../../common/components/formatters/unavailable';
import { IMessage, IMessageAttachment, IRequestMessagesList } from '../../../types';
import { Form } from './form.component';

export interface IMessagesProps {
  requestId: string;
  messagesList: IRequestMessagesList;
  updateSizeHandler?: () => void;
}

export const Messages: FunctionComponent<IMessagesProps> = (props: IMessagesProps) => {
  if (props.updateSizeHandler) {
    props.updateSizeHandler();
  }

  if (!props!.messagesList!.data || !props.messagesList!.acl!.messages_can_view) {
    return null;
  }

  const renderAttachments = (attachments: IMessageAttachment[]) => {
    if (attachments.length < 1) {
      return null;
    }

    return (
      <div className="messages-attachments">
        {attachments.map((file: IMessageAttachment) => {
          return (
            <a
              key={file.url}
              className="messages-attachments-file"
              href={file.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {file.name}
            </a>
          );
        })}
      </div>
    );
  };

  const renderMessage = (item: IMessage) => {
    const content = item.content.split('\n').map((val, key) => {
      return <p key={Math.random()}>{val}</p>;
    });

    return (
      <div className="messages-item" key={Math.random()}>
        <strong>{item.sender.fullName}</strong> <FormattedDateTime dateTime={item.createdAt} /> <br />
        <div className="messages-content">{content}</div>
        {renderAttachments(item.attachments)}
      </div>
    );
  };

  return (
    <div className="messages">
      {props.messagesList.data.map((item: IMessage) => renderMessage(item))}
      {props.messagesList.data.length === 0 && <Unavailable text={I18n.t('Messages.Form.NoMessages')} />}
      {props.messagesList.acl.messages_can_edit ? (
        <Form {...props} />
      ) : (
        <Unavailable text={I18n.t('Messages.Form.Deactivated')} />
      )}
    </div>
  );
};
