import { Checkbox, Grid, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import * as I18n from 'i18n-js';
import * as React from 'react';
import { FunctionComponent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { MultiSelect, SingleSelect } from '../../common';
import {
  selectModulesAppVersions,
  selectModulesBatchesUsed,
  selectModulesPlaces,
  selectModulesStates,
} from '../store/modules.selectors';
import { IModulesFilter } from '../types/modules-filter';

interface IProps {
  modulesFilter: IModulesFilter;
  modulesFilterChange: (filter: IModulesFilter) => void;
}

export const ModulesListFilters: FunctionComponent<IProps> = ({ modulesFilter, modulesFilterChange }) => {
  const states = useSelector(selectModulesStates);
  const places = useSelector(selectModulesPlaces);
  const appVersions = useSelector(selectModulesAppVersions);
  const batchesUsed = useSelector(selectModulesBatchesUsed);

  const moduleIdFilterChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      modulesFilterChange({ ...modulesFilter, id: event.target.value });
    },
    [modulesFilter, modulesFilterChange],
  );

  const statesFilterChange = useCallback(
    (states: string[]) => {
      modulesFilterChange({ ...modulesFilter, states });
    },
    [modulesFilter, modulesFilterChange],
  );

  const statesConnectedChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      modulesFilterChange({ ...modulesFilter, coupled: checked });
    },
    [modulesFilter, modulesFilterChange],
  );

  const batchesFilterChange = useCallback(
    (batches: number[]) => {
      modulesFilterChange({ ...modulesFilter, batches });
    },
    [modulesFilter, modulesFilterChange],
  );

  const inMaintenanceFilterChange = useCallback(
    (inMaintenance: boolean | string) => {
      modulesFilterChange({
        ...modulesFilter,
        inMaintenance: typeof inMaintenance === 'boolean' ? inMaintenance : null,
      });
    },
    [modulesFilter, modulesFilterChange],
  );

  const placesFilterChange = useCallback(
    (places: string[]) => {
      modulesFilterChange({ ...modulesFilter, places });
    },
    [modulesFilter, modulesFilterChange],
  );

  const appVersionsFilterChange = useCallback(
    (appVersions: string[]) => {
      modulesFilterChange({ ...modulesFilter, appVersions });
    },
    [modulesFilter, modulesFilterChange],
  );

  return (
    <>
      <div>
        <Checkbox
          style={{ paddingLeft: 0 }}
          onChange={statesConnectedChange}
          checked={modulesFilter.coupled}
          color={'primary'}
        />
        {I18n.t('FilterOnConnectedModules')}
      </div>
      <Grid container spacing={2} justify="space-around" style={{ marginBottom: 20 }}>
        <Grid item xs={2}>
          <TextField
            value={modulesFilter.id}
            onChange={moduleIdFilterChange}
            fullWidth
            id="moduleIDFilter"
            label={I18n.t('FilterOnModuleID')}
            InputProps={{
              endAdornment: <Search />,
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <MultiSelect
            data={states}
            value={modulesFilter.states}
            valueChange={statesFilterChange}
            valueProp="id"
            keyProp="id"
            displayProp="name"
            id="stateFilter"
            label={I18n.t('FilterOnProfile')}
          />
        </Grid>
        <Grid item xs={2}>
          <MultiSelect
            data={batchesUsed}
            value={modulesFilter.batches}
            valueChange={batchesFilterChange}
            valueProp="id"
            keyProp="id"
            displayProp="name"
            id="batchFilter"
            label={I18n.t('FilterOnBatch')}
          />
        </Grid>
        <Grid item xs={2}>
          <SingleSelect
            data={[
              { value: '', display: I18n.t('AllModules') },
              { value: true, display: I18n.t('InMaintenance') },
              { value: false, display: I18n.t('NotInMaintenance') },
            ]}
            value={modulesFilter.inMaintenance === null ? '' : modulesFilter.inMaintenance}
            valueChange={inMaintenanceFilterChange}
            valueProp="value"
            keyProp="value"
            displayProp="display"
            id="inMaintenanceFilter"
            label={I18n.t('FilterOnInMaintenance')}
          />
        </Grid>
        <Grid item xs={2}>
          <MultiSelect
            data={places}
            value={modulesFilter.places}
            valueChange={placesFilterChange}
            valueProp="id"
            keyProp="id"
            displayProp="name"
            id="placesFilter"
            label={I18n.t('FilterOnPlace')}
          />
        </Grid>
        <Grid item xs={2}>
          <MultiSelect
            data={appVersions}
            value={modulesFilter.appVersions}
            valueChange={appVersionsFilterChange}
            valueProp="id"
            keyProp="id"
            displayProp="name"
            id="appVersionsFilter"
            label={I18n.t('FilterOnAppVersion')}
          />
        </Grid>
      </Grid>
    </>
  );
};
