import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import * as React from 'react';
import { FunctionComponent, useCallback } from 'react';
import { useHistory } from 'react-router';
import { Container } from '../../../common';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { appUrls } from '../../../common/config/url.constants';
import { translate } from '../../../common/translations/translate';
import { Auth } from '../../../components';
import { HEADER_HEIGHT } from '../../../components/organisms/MainMenu/MainMenu.styles';
import { IUserManagementAclActions } from '../../../types';
import { UsersList } from './UsersList.component';

const useStyles = makeStyles({
  createButton: {
    marginTop: 18 + HEADER_HEIGHT,
    marginBottom: 18,
  },
});

export const UsersOverview: FunctionComponent = () => {
  const C = useStyles();
  const history = useHistory();

  const onCreateWhiteListEntry = useCallback(() => {
    history.push(appUrls.users.create);
  }, [history]);

  return (
    <Auth acl={[IUserManagementAclActions.viewUser]}>
      <Container>
        <div className={C.createButton}>
          <Auth acl={[IUserManagementAclActions.createUser]}>
            <Button.Green
              dataTestId="userCreateButton"
              onClick={onCreateWhiteListEntry}
              startIcon={<AddIcon fontSize={'small'} />}
            >
              {translate('users.list.createButton')}
            </Button.Green>
          </Auth>
        </div>
        <div data-testid="UsersOverview">
          <UsersList />
        </div>
      </Container>
    </Auth>
  );
};
