import { combineReducers } from 'redux';
import { generateArrayReducer, generatePrimitiveReducer } from '../../common';
import { IParkingBanFeature } from '../types/parking-ban-feature';
import { setParkingBans, setParkingBansLoading } from './cpa.actions';
import { initialCpaState } from './cpa.state';

export const cpaReducer = combineReducers({
  parkingBans: generateArrayReducer<IParkingBanFeature>(setParkingBans.type, initialCpaState.parkingBans),
  parkingBansLoading: generatePrimitiveReducer<boolean>(setParkingBansLoading.type, initialCpaState.parkingBansLoading),
});
