import React, { FC } from 'react';
import { Auth } from '../atoms';
import {
  ISgwAdvisingPartiesAclActions,
  ISgwNatureOfWorksAclActions,
  ISgwPermitConditionsAclActions,
  ISgwProjectAclActions,
  ISgwSettings,
  ISgwTrafficMeasureAclActions,
} from '../../types';
import { RouterMenu } from '../molecules';
import { Route, Switch } from 'react-router';
import { appUrls } from '../../common/config/url.constants';
import { translate } from '../../common/translations/translate';
import { useSelector } from 'react-redux';
import { getIsAclUser, selectUserAcl } from '../../common/store/user/user.selectors';
import { SettingsPage } from '../pages';
import { ProjectRoutes } from './Project.routes';
import { NatureOfWorksPageRoutes } from './NatureOfWorksPage.routes';
import { AdvisingPartiesRoutes } from './AdvisingParty.routes';
import { TrafficMeasuresRoutes } from './TrafficMeasure.routes';
import { PermitConditionRoutes } from './PermitCondition.routes';

export const ManageRoutes: FC = () => {
  const userAcl = useSelector(selectUserAcl);
  const isAcl = useSelector(getIsAclUser);

  return (
    <Auth
      acl={[
        ISgwPermitConditionsAclActions.viewSgwPermitConditionsOverview,
        ISgwSettings.viewSgwSettings,
        ISgwProjectAclActions.viewSgwProjectOverview,
        ISgwNatureOfWorksAclActions.viewSgwNatureOfWorksOverview,
        ISgwTrafficMeasureAclActions.viewSgwTrafficMeasuresOverview,
        ISgwAdvisingPartiesAclActions.viewAdvisingPartiesOverview,
      ]}
      showUnauthorizedPage
    >
      <RouterMenu
        tabs={[
          {
            label: translate('sgw.manage.permitConditions'),
            route: appUrls.sgw.manage.permitConditions.base,
            visible: !isAcl || !!userAcl?.[ISgwPermitConditionsAclActions.viewSgwPermitConditionsOverview],
          },
          {
            label: translate('sgw.manage.settings'),
            route: appUrls.sgw.manage.settings,
            visible: !isAcl || !!userAcl?.[ISgwSettings.viewSgwSettings],
          },
          {
            label: translate('sgw.manage.projects'),
            route: appUrls.sgw.manage.projects.base,
            visible: !isAcl || !!userAcl?.[ISgwProjectAclActions.viewSgwProjectOverview],
          },
          {
            label: translate('sgw.manage.natureOfWorks'),
            route: appUrls.sgw.manage.natureOfWorks.base,
            visible: !isAcl || !!userAcl?.[ISgwNatureOfWorksAclActions.viewSgwNatureOfWorksOverview],
          },
          {
            label: translate('sgw.manage.trafficMeasures'),
            route: appUrls.sgw.manage.trafficMeasures.base,
            visible: !isAcl || !!userAcl?.[ISgwTrafficMeasureAclActions.viewSgwTrafficMeasuresOverview],
          },
          {
            label: translate('sgw.manage.advisingParties'),
            route: appUrls.sgw.manage.advisingParties.base,
            visible: !isAcl || !!userAcl?.[ISgwAdvisingPartiesAclActions.viewAdvisingPartiesOverview],
          },
        ]}
      />
      <Switch>
        <Route path={appUrls.sgw.manage.permitConditions.base}>
          <PermitConditionRoutes />
        </Route>
        <Route path={appUrls.sgw.manage.settings}>
          <SettingsPage />
        </Route>
        <Route path={appUrls.sgw.manage.projects.base}>
          <ProjectRoutes />
        </Route>
        <Route path={appUrls.sgw.manage.natureOfWorks.base}>
          <NatureOfWorksPageRoutes />
        </Route>
        <Route path={appUrls.sgw.manage.trafficMeasures.base}>
          <TrafficMeasuresRoutes />
        </Route>
        <Route path={appUrls.sgw.manage.advisingParties.base}>
          <AdvisingPartiesRoutes />
        </Route>
      </Switch>
    </Auth>
  );
};
