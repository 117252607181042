import { FC } from 'react';
import { WMSTileLayer } from 'react-leaflet';
import { MAP_BASELAYER_PARAMS, MAP_BASELAYER_URL, MAP_MAX_ZOOM } from '../../config/map.constants';

export const BaseLayer: FC = () => (
  <WMSTileLayer
    format="image/png"
    maxZoom={MAP_MAX_ZOOM}
    url={MAP_BASELAYER_URL}
    params={MAP_BASELAYER_PARAMS}
    crossOrigin
  />
);
