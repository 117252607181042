export default class LocalStorage {
  public static get(key: string): string | null {
    try {
      return localStorage.getItem(key);
    } catch (e: any) {
      if (e.code === e.SECURITY_ERR) {
        return null;
      }
      throw e;
    }
  }

  public static set(key: string, value: string): void {
    try {
      localStorage.setItem(key, value);
    } catch (e: any) {
      if (e.code === e.SECURITY_ERR) {
        return;
      }
      throw e;
    }
  }

  public static remove(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (e: any) {
      if (e.code === e.SECURITY_ERR) {
        return;
      }
      throw e;
    }
  }
}
