import { createUseStyles } from 'react-jss';

export const usePhaseDetailStyles = createUseStyles({
  addLocationsForm: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  streetName: {
    width: '-webkit-fill-available',
  },
  leftPanel: {
    paddingRight: '100px',
    height: 'fit-content',
  },
  table: {
    width: '100%',
    padding: '8px',
  },
  clickableIcon: {
    marginLeft: '10px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  checkbox: {
    alignItems: 'center',
  },
  content: {
    marginTop: '20px',
  },
  longTextField: {
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  phaseName: {
    width: '100%',
  },
  selector: {
    marginRight: '10px',
  },
  streetNumberFormColumn: {
    width: '230px',
    marginLeft: '10px',
  },
  streetNumberColumn: {
    width: '40px',
  },

  titleValue: {
    marginBottom: '10px',
    padding: '8px',
  },
  titleValues: {
    marginBottom: '10px',
  },
  multiSelect: {
    width: '300px',
  },
});
