import React, { FC } from 'react';
import Container from '@material-ui/core/Container';
import { Paper, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CONFIG } from '../../../common';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { translate } from '../../../common/translations/translate';
import { appUrls } from '../../../common/config/url.constants';
import { Save } from '@material-ui/icons';
import { useUpdatePasswordForm } from '../../../hooks';

const useStyles = makeStyles({
  button: {
    margin: '10px 10px 20px',
  },
  inputFields: {
    margin: '10px',
    width: '100%',
  },
  image: {
    width: '30%',
    margin: '20px',
  },
  centered: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  error: {
    color: 'red',
    margin: '10px',
  },
});

export const ResetPasswordPage: FC = () => {
  const C = useStyles();

  const {
    onSubmit,
    formMethods: {
      register,
      formState: { errors },
    },
  } = useUpdatePasswordForm();

  return (
    <Container>
      <Paper square data-testid="ResetPasswordPage">
        <div className={C.centered}>
          <img alt={translate('login.image')} className={C.image} src={CONFIG.logoUrl} />
          <h1>{translate('resetPassword.title')}</h1>
          <a href={appUrls.login.selection}>{translate('resetPassword.login')}</a>
          <form onSubmit={onSubmit} className={C.form}>
            <TextField
              className={C.inputFields}
              {...register('newPassword', { required: true })}
              label={translate('resetPassword.password')}
              placeholder={translate('resetPassword.passwordPlaceholder')}
              type="password"
              required
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              className={C.inputFields}
              {...register('newPasswordConfirmation', { required: true })}
              label={translate('resetPassword.passwordConfirmation')}
              placeholder={translate('resetPassword.passwordConfirmationPlaceholder')}
              type="password"
              required
              InputLabelProps={{ shrink: true }}
            />
            {errors?.newPassword && <div className={C.error}>{errors.newPassword.message}</div>}
            <Button.Green type="submit" className={C.button} startIcon={<Save />}>
              {translate('resetPassword.save')}
            </Button.Green>
          </form>
        </div>
      </Paper>
    </Container>
  );
};
