import { appUrls } from '../../config/url.constants';

export interface IAppState {
  loading: boolean;
  accessToken: string | null;
  redirectUrl: string;
}

export const initialAppState: IAppState = {
  loading: true,
  accessToken: null,
  redirectUrl: appUrls.requests.base,
};
