import I18n from 'i18n-js';
import React, { CSSProperties, FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { COLORS } from '../../../../common';
import { ModalButton } from '../../../../common/components/buttons/modal-button';
import { getIsAuthorized } from '../../../../common/store/user/user.selectors';
import { getRequestAcl, getRequestReviewInfo, selectRequestConflictsById } from '../../../../store/selectors';
import { IRequestAclActions, IRequestApprovalComponent } from '../../../../types';

const styles = {
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '15px',
  },
  warning: {
    borderColor: COLORS.DANGER,
    borderRadius: '5px',
    borderStyle: 'solid',
    borderWidth: 1,
    color: COLORS.DANGER,
    padding: '5px',
  },
};

export const Review: FunctionComponent<IRequestApprovalComponent> = ({
  onApproveRequest,
  onRejectRequest,
  onReviewRequest,
  requestId,
}) => {
  const conflicts = useSelector(selectRequestConflictsById)[requestId];

  const { canApprove, showApprove, showReject, showReview, tooltip } = useSelector(getRequestReviewInfo(requestId));
  const acl = useSelector(getRequestAcl(requestId));
  const canRejectAssignedRequest = useSelector(getIsAuthorized([IRequestAclActions.rejectAssignedRequest]));
  const canTransitionRequestToReview = useSelector(getIsAuthorized([IRequestAclActions.transitionRequestToReview]));
  const canApproveAssignedRequest = useSelector(getIsAuthorized([IRequestAclActions.approveAssignedRequest]));

  const showRejectButton = showReject && canRejectAssignedRequest;
  const showReviewButton = showReview && canTransitionRequestToReview;
  const showApproveButton = showApprove && canApproveAssignedRequest;

  return (
    <div data-testid="requestReview" style={styles.root as CSSProperties}>
      <ModalButton.Review
        data-testid="requestReviewReviewButton"
        onConfirm={onReviewRequest}
        visible={showReviewButton}
        title={I18n.t('ModalButton.review')}
        buttonTitle={I18n.t('ModalButton.review')}
        shouldShowModal={false}
      />
      <ModalButton.Reject
        data-testid="requestReviewRejectButton"
        onConfirm={onRejectRequest}
        visible={showRejectButton}
        title={I18n.t('ModalButton.Request.reject')}
        buttonTitle={I18n.t('ModalButton.reject')}
        disabled={!acl?.can_reject}
      />
      <ModalButton.Approve
        data-testid="requestReviewApproveButton"
        disabled={!canApprove || !acl?.can_approve}
        onConfirm={onApproveRequest}
        tooltip={tooltip ? I18n.t(`Requests.Header.Approval.${tooltip}Reason`) : undefined}
        visible={showApproveButton}
        title={I18n.t('ModalButton.Request.approve')}
        buttonTitle={I18n.t('ModalButton.approve')}
        modalBody={
          conflicts &&
          conflicts.length > 0 && (
            <div style={styles.warning} data-testid="modalConflictsWarning">
              <span>{I18n.translate('ModalButton.Request.WarnForConflicts')}</span>
            </div>
          )
        }
      />
    </div>
  );
};
