import { Box, Collapse, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'formik';
import React, { ChangeEvent, FocusEvent, FunctionComponent, useCallback } from 'react';
import { Unavailable } from '../../../../common/components/formatters/unavailable';
import { FormikCheckboxField, FormikSelect, FormikTextField } from '../../../../common/components/formikFormFields';
import { Visible } from '../../../../common/components/layout/Visible.component';
import { Feedback } from '../../../../common/components/layout/Feedback.component';
import { IWithClassName, IWithFormikProps } from '../../../../types';
import { IService, IServiceEditFields, VehicleSource } from '../../../../types';
import { useDispatch, useSelector } from 'react-redux';
import { ServicesActions } from '../../../../common/store/services/Services.actions';
import { getExistingNames } from '../../../../common/store/services/Services.selectors';
import { vehicleSourceOptions } from '../../../Service.constants';
import { InfoText } from '../../../../common/components/layout/InfoText.component';
import classNames from 'classnames';
import { translate } from '../../../../common/translations/translate';
import { validateAptrUsageCode } from '../../../../utils';

const useStyles = makeStyles({
  form: { padding: 10 },
  containerPaddingTop: { paddingTop: 32 },
  syncWrapper: { marginTop: 20 },
  warning: { paddingLeft: 35 },
  warningText: { color: 'red' },
  remarkPaddingTop: { paddingTop: 8 },
});

interface IProps extends IWithClassName, IWithFormikProps<IService> {
  service: Partial<IService>;
}

export const ServiceEditForm: FunctionComponent<IProps> = ({ service, formikProps }) => {
  const C = useStyles();
  const dispatch = useDispatch();
  const existingNames = useSelector(getExistingNames);
  const isVehicleSourceAptr = formikProps.values.vehicleSource === VehicleSource.aptr;
  const remarkAlignment = !!formikProps.errors.vehicleSource ? 'center' : 'flex-end';

  const onChangeName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!existingNames.includes(event.target.value.toLowerCase()) && event.target.value.length > 0) {
        dispatch(ServicesActions.searchName(event.target.value));
      }
    },
    [dispatch, existingNames],
  );

  const onBlurAptrVpUsageCode = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      if (event.target.value) {
        formikProps.setFieldValue(IServiceEditFields.syncWithMoov, true);
      }
    },
    [formikProps],
  );

  const usageCodeRequired = useCallback(
    () =>
      isVehicleSourceAptr &&
      !formikProps.values.aptrC3UsageCode &&
      !formikProps.values.aptrF3UsageCode &&
      !formikProps.values.aptrVpUsageCode,
    [
      isVehicleSourceAptr,
      formikProps.values.aptrC3UsageCode,
      formikProps.values.aptrF3UsageCode,
      formikProps.values.aptrVpUsageCode,
    ],
  );

  return (
    <Form className={C.form} data-testid="ServiceEditForm" noValidate>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FormikTextField
            label={translate('Name')}
            name={IServiceEditFields.name}
            inputProps={{ onChange: onChangeName }}
          />
        </Grid>

        <Grid item xs={4}>
          <FormikTextField label={translate('street')} name={IServiceEditFields.streetName} required={false} />
        </Grid>
        <Grid item xs={4}>
          <FormikTextField label={translate('StreetNumber')} name={IServiceEditFields.streetNumber} required={false} />
        </Grid>
        <Grid item xs={2}>
          <FormikTextField label={translate('services.form.bus')} name={IServiceEditFields.bus} required={false} />
        </Grid>

        <Grid item xs={4}>
          <FormikTextField
            label={translate('services.form.zipCode')}
            name={IServiceEditFields.postalCode}
            required={false}
          />
        </Grid>
        <Grid item xs={6}>
          <FormikTextField label={translate('services.form.city')} name={IServiceEditFields.city} required={false} />
        </Grid>

        <Grid item xs={12}>
          <FormikTextField
            label={translate('services.form.description')}
            name={IServiceEditFields.description}
            required={false}
            multiline
          />
        </Grid>

        <Grid item xs={4}>
          <FormikSelect
            error={!!formikProps.errors.vehicleSource}
            errorLabel={formikProps.errors.vehicleSource}
            label={translate('services.form.vehicleSource.label')}
            name={IServiceEditFields.vehicleSource}
            options={vehicleSourceOptions()}
            required
          />
        </Grid>
        <Grid item xs={8}>
          <Box height={1} display="flex" alignItems={remarkAlignment} marginLeft={5}>
            <InfoText>{translate('services.form.vehicleSource.remark')}</InfoText>
          </Box>
        </Grid>
      </Grid>

      <Collapse in={!!formikProps.values.vehicleSource}>
        <Grid container spacing={4} className={C.remarkPaddingTop}>
          <Grid item xs={12}>
            <Visible visible={isVehicleSourceAptr}>
              <Feedback.Info>{translate('services.form.vehicleSource.remarkEditAptr')}</Feedback.Info>
            </Visible>
            <Visible visible={formikProps.values.vehicleSource === VehicleSource.whitelist}>
              <Feedback.Info>{translate('services.form.vehicleSource.remarkEditWhitelist')}</Feedback.Info>
            </Visible>
          </Grid>
        </Grid>
      </Collapse>

      <Collapse in={isVehicleSourceAptr}>
        <Grid container spacing={4} className={C.containerPaddingTop}>
          <Grid item xs={12}>
            <FormikTextField
              label={translate('services.form.aptrAreaCode')}
              name={IServiceEditFields.aptrAreaCode}
              required={isVehicleSourceAptr}
            />
          </Grid>

          <Grid item xs={4}>
            <FormikTextField
              inputProps={{ onBlur: onBlurAptrVpUsageCode }}
              label={translate('services.form.aptrVpUsageCode')}
              name={IServiceEditFields.aptrVpUsageCode}
              required={usageCodeRequired()}
              validate={(code) => validateAptrUsageCode(code, formikProps.initialValues.aptrVpUsageCode)}
            />
          </Grid>
          <Grid item xs={4}>
            <FormikTextField
              label={translate('services.form.aptrC3UsageCode')}
              name={IServiceEditFields.aptrC3UsageCode}
              required={usageCodeRequired()}
              validate={(code) => validateAptrUsageCode(code, formikProps.initialValues.aptrC3UsageCode)}
            />
          </Grid>
          <Grid item xs={4}>
            <FormikTextField
              label={translate('services.form.aptrF3UsageCode')}
              name={IServiceEditFields.aptrF3UsageCode}
              required={usageCodeRequired()}
              validate={(code) => validateAptrUsageCode(code, formikProps.initialValues.aptrF3UsageCode)}
            />
          </Grid>
        </Grid>
      </Collapse>
      <Collapse
        in={
          isVehicleSourceAptr &&
          !formikProps.values.aptrC3UsageCode &&
          !formikProps.values.aptrF3UsageCode &&
          !formikProps.values.aptrVpUsageCode
        }
      >
        <Box className={classNames(C.warningText)} paddingTop={1}>
          <InfoText>{translate('services.form.aptrUsageCodeRemark')}</InfoText>
        </Box>
      </Collapse>

      <Grid container spacing={1} className={C.containerPaddingTop}>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <FormikCheckboxField
                name={IServiceEditFields.syncWithMoov}
                label={translate('services.form.syncWithMoov')}
              />
            </Grid>
            <Grid item xs={12} className={C.warning}>
              <Visible visible={!!(service?.syncWithMoov && !formikProps.values.syncWithMoov)}>
                <Unavailable text={translate('services.form.uncheckSyncWithMoovWarning')} />
              </Visible>
              <Visible visible={!!(!service?.syncWithMoov && formikProps.values.syncWithMoov)}>
                <Unavailable text={translate('services.form.checkSyncWithMoovWarning')} />
              </Visible>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <FormikCheckboxField
                name={IServiceEditFields.syncWithZoneguard}
                label={translate('services.form.syncWithZoneguard')}
              />
            </Grid>
            <Grid item xs={12} className={C.warning}>
              <Visible visible={!!(service?.syncWithZoneguard && !formikProps.values.syncWithZoneguard)}>
                <Unavailable text={translate('services.form.uncheckSyncWithZoneguardWarning')} />
              </Visible>
              <Visible visible={!!(!service?.syncWithZoneguard && formikProps.values.syncWithZoneguard)}>
                <Unavailable text={translate('services.form.checkSyncWithZoneguardWarning')} />
              </Visible>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Feedback.Warning>{translate('services.form.saveWarning')}</Feedback.Warning>
          <Visible visible={isVehicleSourceAptr}>
            <Feedback.Warning>{translate('services.form.saveWarningAptrKrautli')}</Feedback.Warning>
            <Feedback.Warning>{translate('services.form.saveWarningAptrAnpr')}</Feedback.Warning>
          </Visible>
        </Grid>
      </Grid>
    </Form>
  );
};
