import {
  Actions,
  generateBaseActionCreator,
  generatePayloadActionCreator,
  TableActions,
  TBaseActionCreator,
  TPayloadActionCreator,
} from '../../common';
import { IModule, IModulesMetadata } from '../types/module';
import { IModuleBatch, IMoveModulesRequest } from '../types/module-batch';
import { IModuleCounters } from '../types/module-counters';
import { IModulePlace } from '../types/module-place';
import { IModuleState } from '../types/module-state';
import { IModuleUpdate } from '../types/module-update';
import { IModuleAppVersion, IModulesFilter } from '../types/modules-filter';
import { IBatchesForReorder } from './modules.state';

class ModulesActionsClass extends Actions {
  fetchMetadata = this.createAction('fetchMetadata');
  setMetadata = this.createPayloadAction<IModulesMetadata>('setMetadata');
  setAppVersions = this.createPayloadAction<IModuleAppVersion[]>('setAppVersions');
  list = new TableActions<IModule, IModulesFilter>(`${this.prefix}.list`);
}

export const ModulesActions = new ModulesActionsClass('modules');

export const setModulesLoading: TPayloadActionCreator<boolean> =
  generatePayloadActionCreator('modules.setModulesLoading');

export const fetchModule: TPayloadActionCreator<string> = generatePayloadActionCreator('modules.fetchModule');

export const deleteModule: TPayloadActionCreator<string> = generatePayloadActionCreator('modules.deleteModule');

export const setModule: TPayloadActionCreator<IModule> = generatePayloadActionCreator('modules.setModule');

export const setModuleLoading: TPayloadActionCreator<boolean> =
  generatePayloadActionCreator('modules.setModuleLoading');

export const updateModule: TPayloadActionCreator<IModuleUpdate> = generatePayloadActionCreator('modules.updateModule');

export const moveModules: TPayloadActionCreator<IMoveModulesRequest> =
  generatePayloadActionCreator('modules.moveModules');

export const fetchBatches: TBaseActionCreator = generateBaseActionCreator('modules.fetchBatches');

export const fetchBatchesUsed: TBaseActionCreator = generateBaseActionCreator('modules.fetchBatchesUsed');

export const fetchBatchForReorder: TBaseActionCreator = generateBaseActionCreator('modules.getBatchForReorder');

export const setBatches: TPayloadActionCreator<IModuleBatch[]> = generatePayloadActionCreator('modules.setBatches');

export const setBatchesUsed: TPayloadActionCreator<IModuleBatch[]> =
  generatePayloadActionCreator('modules.setBatchesUsed');

export const setBatchesForReorder: TPayloadActionCreator<IBatchesForReorder> =
  generatePayloadActionCreator('modules.setBatchesForReorder');

export const fetchStates: TBaseActionCreator = generateBaseActionCreator('modules.fetchStates');

export const setStates: TPayloadActionCreator<IModuleState[]> = generatePayloadActionCreator('modules.setStates');

export const initModuleOverview: TBaseActionCreator = generateBaseActionCreator('signs.initModuleOverview');

export const fetchModuleCounters: TBaseActionCreator = generateBaseActionCreator('signs.fetchModuleCounters');

export const setModuleCounters: TPayloadActionCreator<IModuleCounters> =
  generatePayloadActionCreator('signs.setModuleCounters');

export const fetchPlaces: TBaseActionCreator = generateBaseActionCreator('signs.fetchPlaces');

export const setPlaces: TPayloadActionCreator<IModulePlace[]> = generatePayloadActionCreator('signs.setPlaces');
