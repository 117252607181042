import { IFilter } from '../common/types/filter';
import { KeysEnum } from '../common/utils/keysEnum.util';
import { IPagedTableState, IWithId } from './generic.types';

export interface IServiceNew {
  aptrAreaCode?: string;
  aptrC3UsageCode?: string;
  aptrF3UsageCode?: string;
  aptrSyncedAt?: string;
  aptrVpUsageCode?: string;
  bus?: string;
  city?: string;
  description?: string;
  name: string;
  postalCode?: number;
  streetName?: string;
  streetNumber?: string;
  vehicleSource?: VehicleSource;
}

export interface IServiceSync {
  syncWithMoov?: boolean;
  syncWithZoneguard?: boolean;
}

export interface IServiceEdit extends IServiceNew, IServiceSync {}

export interface IService extends IServiceNew, IServiceSync, Partial<IPincodeManagement>, IWithId {
  createdAt: string;
  updatedAt: string;
  currentPincode?: IPincode;
  nextPincode?: IPincode;
  prevPincode?: IPincode;
  numberOfLicensePlates?: number;
  numberOfZappers?: number;
}

export enum VehicleSource {
  aptr = 'aptr',
  whitelist = 'whitelist',
}

export enum PincodeRenewPeriod {
  d = 'd',
  w = 'w',
  m = 'm',
  y = 'y',
}

export interface IPincodeManagement {
  pincodeFromDate?: string;
  pincodeUntilDate?: string;
  pincodeAutoRenewValue?: number;
  pincodeAutoRenewPeriod?: PincodeRenewPeriod;
}

export interface IServiceState {
  existingNames: string[];
  list: IPagedTableState<IService, IServiceFilter>;
  services: IService[];
  saving: boolean;
  savingPincode: boolean;
  whitelistEntryList: IPagedTableState<IWhitelistEntry, IWhitelistEntryListFilter>;
  whitelists: IWhiteListState;
  contactPersons: IServiceContactState;
  aptr: IAPTRState;
}

export interface IAPTRState {
  isSyncing: boolean;
  latestSync: string | null;
}

export interface IWhiteListState {
  list: IPagedTableState<IWhiteList>;
  result: IUploadResult | null;
}

export interface IUploadResult {
  mesage: string;
  data?: {
    error: string;
    value: string;
    line: string;
  }[];
  type: 'error' | 'info' | 'can_ignore_error';
}

export interface IPincode {
  dateFrom: string;
  dateUntil?: string;
  id: number;
  value: string;
  autoRenewValue?: number;
}

export interface IServiceContactDetails {
  fullName: string;
  email: string;
  phoneNumber?: string;
}

export interface IServiceContact extends IServiceContactDetails {}
export interface IServiceContactWithId extends IWithId, IServiceContactDetails {}

export interface IServiceContactState extends IPagedTableState<IServiceContact> {}

export interface IServiceFilter extends IFilter {
  query: string;
  pincodeStatus: string[];
  syncWith: string[];
  vehicleSource: VehicleSource[];
}

export const IServiceEditFields: KeysEnum<IServiceEdit> = {
  aptrAreaCode: 'aptrAreaCode',
  aptrC3UsageCode: 'aptrC3UsageCode',
  aptrF3UsageCode: 'aptrF3UsageCode',
  aptrSyncedAt: 'aptrSyncedAt',
  aptrVpUsageCode: 'aptrVpUsageCode',
  bus: 'bus',
  city: 'city',
  description: 'description',
  name: 'name',
  postalCode: 'postalCode',
  streetName: 'streetName',
  streetNumber: 'streetNumber',
  syncWithMoov: 'syncWithMoov',
  syncWithZoneguard: 'syncWithZoneguard',
  vehicleSource: 'vehicleSource',
};

export const IPincodeFormikFields: KeysEnum<IPincodeManagement> = {
  pincodeFromDate: 'pincodeFromDate',
  pincodeUntilDate: 'pincodeUntilDate',
  pincodeAutoRenewValue: 'pincodeAutoRenewValue',
  pincodeAutoRenewPeriod: 'pincodeAutoRenewPeriod',
};

export interface IWhiteList {
  createdAt: string;
  createdBy?: {
    id: number;
    fullName: string;
  };
  downloadUrl: string;
  fileName: string;
  id: number;
  name: string;
  validDateFrom: string;
}

export const newContactInitialValues: IServiceContact = {
  fullName: '',
  email: '',
  phoneNumber: '',
};

export enum WhitelistEntryTypes {
  licensePlate = 'license_plate',
  zapper = 'zapper',
}

export interface IAptrUsageCode {
  id: number;
  type: string;
  usageCode: string;
}

export interface IWhitelistEntry extends IWithId {
  aptrUsageCodes: IAptrUsageCode[];
  description: string;
  type: WhitelistEntryTypes;
  value: string;
  whiteListServiceId: number;
}

export interface IWhitelistEntryListFilter {
  aptrUsageCode?: string[];
  serviceId?: string;
  type?: string[];
  query?: string;
}

export interface IAptrUsageCodeValidationResult {
  in_use: boolean;
}
