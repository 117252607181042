import { combineReducers } from 'redux';
import { generatePayloadReducer } from '../../..';
import { whitelistFilesInitialFilter } from '../../../../service/Service.constants';
import { IUploadResult, IWhiteList } from '../../../../types';
import { ServicesActions } from '../Services.actions';
import { createPagedTableReducer } from '../../../../store/reducers/pagedTable.reducer';

export default combineReducers({
  list: createPagedTableReducer<IWhiteList>({
    fetchAction: ServicesActions.whitelistsList.fetch.type,
    addEntitiesActions: [ServicesActions.whitelistsList.set.type],
    setAllIdsAction: ServicesActions.whitelistsList.set.type,
    setParamsActions: [ServicesActions.whitelistsList.fetch.type, ServicesActions.whitelistsList.setParams.type],
    initialParamsState: whitelistFilesInitialFilter,
  }),
  result: generatePayloadReducer<IUploadResult | null>([ServicesActions.setUploadResult.type], null),
});
