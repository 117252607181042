import { Grid, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { MultiSelect } from '../../common';
import { AsignDatepicker } from '../../common/components/form-fields/datepicker/asign-datepicker.component';
import moment, { Moment } from 'moment';
import { ClearFiltersFab } from '../../common/components/buttons/ClearFiltersFab';
import * as React from 'react';
import { INotificationsFilter } from '../types/notification';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationActions } from '../../store/actions/notifications.actions';
import { getNotificationSources, getNotificationStates } from '../notification.constants';
import { initialNotificationsState } from '../store/notification.state';
import { translate } from '../../common/translations/translate';
import { selectTable } from '../../store/selectors/notifications.selectors';

export const NotificationFilters: FC = () => {
  const dispatch = useDispatch();
  const { filters } = useSelector(selectTable);

  const onChangeFilter = (newFilterValues: Partial<INotificationsFilter>) => {
    dispatch(
      NotificationActions.list.fetch({
        filters: {
          ...filters,
          ...newFilterValues,
        },
      }),
    );
  };

  const queryFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeFilter({ q: event.target.value });
  };

  const statesFilterChange = (states: string[]) => {
    onChangeFilter({ states });
  };

  const sourceFilterChange = (source: string[]) => {
    onChangeFilter({ source });
  };

  const dateFromFilterChange = (selectedDate: Moment) => {
    onChangeFilter({ from_date: selectedDate.format('YYYY-MM-DD') });
  };

  const dateToFilterChange = (selectedDate: Moment) => {
    onChangeFilter({ until_date: selectedDate.format('YYYY-MM-DD') });
  };

  const resetFilters = () => {
    onChangeFilter(initialNotificationsState.list.table.filters);
  };

  return (
    <Grid container={true} spacing={2} justify="space-between" style={{ marginBottom: 20 }}>
      <Grid item={true} xs={2}>
        <TextField
          value={filters.q}
          onChange={queryFilterChange}
          fullWidth={true}
          id="notificationQueryFilter"
          label={translate('Notifications.table.filters.query')}
          InputProps={{
            endAdornment: <Search />,
          }}
        />
      </Grid>
      <Grid item={true} xs={2}>
        <MultiSelect
          data={getNotificationStates()}
          value={filters.states}
          valueChange={statesFilterChange}
          valueProp="id"
          keyProp="id"
          displayProp="name"
          id="stateFilter"
          label={translate('Notifications.table.filters.status')}
        />
      </Grid>
      <Grid item={true} xs={2}>
        <MultiSelect
          data={getNotificationSources()}
          value={filters.source}
          valueChange={sourceFilterChange}
          valueProp="id"
          keyProp="id"
          displayProp="name"
          id="stateFilter"
          label={translate('Notifications.table.filters.source')}
        />
      </Grid>
      <Grid item={true} xs={2}>
        <AsignDatepicker
          label={translate('Notifications.table.filters.dateFrom')}
          value={moment(filters.from_date)}
          onChange={dateFromFilterChange}
          minDate={moment(new Date()).subtract(6, 'months')}
          maxDate={moment(new Date()).add(1, 'day')}
        />
      </Grid>
      <Grid item={true} xs={2}>
        <AsignDatepicker
          label={translate('Notifications.table.filters.dateUntil')}
          value={moment(filters.until_date)}
          onChange={dateToFilterChange}
          minDate={moment(new Date()).subtract(6, 'months')}
          maxDate={moment(new Date()).add(1, 'day')}
        />
      </Grid>
      <Grid item={true} xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <ClearFiltersFab onClick={resetFilters} />
      </Grid>
    </Grid>
  );
};
