import { useForm } from 'react-hook-form';
import { ISgwPartner } from '../types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SgwPartnerActions } from '../store/actions';
import { usePartnerContext } from './usePartnerContext.hook';

export const useSgwPartnerForm = () => {
  const dispatch = useDispatch();
  const { partner: initialValues, partnerType, requestId } = usePartnerContext();

  const formMethods = useForm<ISgwPartner>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: initialValues,
  });

  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    initialValues && reset(initialValues);
  }, [initialValues, reset]);

  const _onSubmit = (values: ISgwPartner) =>
    dispatch(SgwPartnerActions.save({ partner: values, requestId, partnerType }));

  const submitPartner = handleSubmit(_onSubmit);

  return { formMethods, submitPartner };
};
