import React, { FC, useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { COLORS, ISorting, ISortingDirection } from '../../../common';
import { translate, translateIgnoreTS } from '../../../common/translations/translate';
import { CollapsablePanel } from '../../molecules';
import { getTotalRequesCost, selectSgwRequestHistory } from '../../../store/selectors/sgwRequest.selectors';
import { GenericTablePanel } from '../../../common/components/table/GenericTablePanel.component';
import { ISgwHistory, SgwRequestHistoryValues } from '../../../types/sgwRequestHistory.types';
import { SgwRequestActions } from '../../../store/actions';
import { useParams } from 'react-router';
import { IRouterWithId } from '../../../types';
import { formatDateTimeToDisplayDateTime } from '../../../common/utils/date.util';
import { Visible } from '../../../common/components/layout/Visible.component';
import { Icon } from '../../../common/components/layout/icon.component';
import { useGeneralStyles } from '../../../style/generalStyles';
import classNames from 'classnames';
import { Button } from '@material-ui/core';
import { roundIfNumeric } from '../../../utils';

const useStyles = createUseStyles({
  table: {
    width: '100%',
    maxHeight: '500px',
    overflowY: 'auto',
    '& div': {
      boxShadow: 'none',
    },
  },
  showMore: {
    textTransform: 'none',
    color: COLORS.PRIMARY,
    marginBottom: '5px',
  },
});

export const SgwRequestHistory: FC = () => {
  const C = useStyles();
  const G = useGeneralStyles();
  const dispatch = useDispatch();
  const [sorting] = useState<ISorting>({ key: 'timestamp', direction: ISortingDirection.asc });
  const [drawer, setDrawer] = useState(false);
  const { history } = useSelector(selectSgwRequestHistory);
  const { id: requestId } = useParams<IRouterWithId>();
  const [showAll, setShowAll] = useState(false);
  const showMessage = showAll ? 'showLess' : 'showAll';
  const numberOfLogs = 5;
  const totalCost = useSelector(getTotalRequesCost());

  useEffect(() => {
    if (drawer) {
      dispatch(SgwRequestActions.fetchSgwRequestHistory({ requestId, sorting }));
    }
    // eslint-disable-next-line
  }, [dispatch, totalCost]);

  const fetchHistory = () => {
    if (!drawer) dispatch(SgwRequestActions.fetchSgwRequestHistory({ requestId, sorting }));
    setDrawer((prev) => !prev);
  };

  return (
    <CollapsablePanel title={translate('sgw.requests.detail.history.title')} onClick={() => fetchHistory()}>
      <div className={classNames(G.flexColumnEnd, G.fullWidth)}>
        <Visible visible={history.length >= numberOfLogs}>
          <Button
            variant="text"
            startIcon={<Icon.History />}
            onClick={() => setShowAll((prev) => !prev)}
            className={C.showMore}
          >
            {translateIgnoreTS(`sgw.requests.detail.history.${showMessage}`)}
          </Button>
        </Visible>
        <GenericTablePanel<ISgwHistory>
          className={C.table}
          labelUnavailable={translate('sgw.requests.detail.history.noHistory')}
          records={showAll ? history : history.slice(0, numberOfLogs)}
          columnKey="timestamp"
          showPagination={false}
          columns={[
            {
              label: translate('sgw.requests.detail.history.timeStamp'),
              name: 'timestamp',
              renderer: (timeStamp) => formatDateTimeToDisplayDateTime(timeStamp),
            },
            {
              label: translate('sgw.requests.detail.history.newValue'),
              name: 'newValue',
              renderer: (newValue) =>
                Object.values(SgwRequestHistoryValues).includes(newValue)
                  ? translateIgnoreTS(`sgw.requests.detail.history.values.${newValue}`)
                  : roundIfNumeric(newValue, 1),
            },
            {
              label: translate('sgw.requests.detail.history.oldValue'),
              name: 'oldValue',
              renderer: (oldValue) =>
                Object.values(SgwRequestHistoryValues).includes(oldValue)
                  ? translateIgnoreTS(`sgw.requests.detail.history.values.${oldValue}`)
                  : roundIfNumeric(oldValue, 1),
            },
            {
              label: translate('sgw.requests.detail.history.description'),
              name: 'description',
              renderer: (description) => translateIgnoreTS(`sgw.requests.detail.history.descriptions.${description}`),
            },
            {
              label: translate('sgw.requests.detail.history.user'),
              name: 'userName',
              renderer: (userName) => userName || translate('sgw.requests.detail.history.automaticStateTransition'),
            },
          ]}
        />
      </div>
    </CollapsablePanel>
  );
};
