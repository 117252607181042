import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router';
import { InfoText } from '../../../common/components/layout/InfoText.component';
import { Visible } from '../../../common/components/layout/Visible.component';
import { PanelTitle } from '../../../common/components/panel/panel-title.component';
import { appUrls } from '../../../common/config/url.constants';
import { RequestActions } from '../../../store/actions';
import { getDrafts, selectUser } from '../../../store/selectors';
import { Draft } from './Draft.container';
import { translate } from '../../../common/translations/translate';

const useStyles = makeStyles({
  actions: { marginTop: 30 },
  card: { marginTop: '2em' },
  cardHeader: {
    background: '#ddd',
    fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
    fontSize: '14px',
  },
  info: {
    color: '#9e9e9e',
  },
  drafts: {
    display: 'flex',
    flexWrap: 'wrap',
  },
});

export const DraftPanelContainer: React.FunctionComponent = () => {
  const C = useStyles();

  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const drafts = useSelector(getDrafts);
  const location = useLocation();

  useEffect(() => {
    user && dispatch(RequestActions.fetchDrafts(user.id));
  }, [user, dispatch]);

  return (
    <Visible visible={!!drafts.length && matchPath(location.pathname, [appUrls.requests.create]) !== null}>
      <Card className={C.card}>
        <CardHeader title={translate('Requests.Create.Drafts.Title')} className={C.cardHeader} />
        <CardContent>
          <PanelTitle>
            {drafts.length === 1
              ? translate('Requests.Create.Drafts.IncompleteRequest')
              : translate('Requests.Create.Drafts.IncompleteRequests', { amount: drafts.length })}
          </PanelTitle>
          <Visible visible={drafts.length >= 2}>
            <InfoText className={C.info}>{translate('Requests.Create.Drafts.ChooseARequest')}</InfoText>
          </Visible>
          <Box className={C.drafts}>
            {drafts.map((draft) => (
              <Draft draft={draft} key={draft.id} />
            ))}
          </Box>
        </CardContent>
      </Card>
    </Visible>
  );
};
