import { Grid, TextField } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Panel, PanelAction } from '../../../common';
import { CheckboxWithLabel } from '../../../common/components/form-fields/checkboxWithLabel.component';
import { Visible } from '../../../common/components/layout/Visible.component';
import { translate } from '../../../common/translations/translate';
import { useSgwNatureOfWorkForm } from '../../../hooks';
import { SgwNatureOfWorksActions } from '../../../store/actions';
import { useGeneralStyles } from '../../../style/generalStyles';
import { getNatureOfWorks } from '../../../store/selectors/sgwNatureOfWorks.selectors';
import { useHistory } from 'react-router';
import { appUrls } from '../../../common/config/url.constants';
import { useFormStyles } from '../../../style/form.styles';
import { SubmitButton } from '../../atoms';
import { Feedback } from '../../../common/components/layout/Feedback.component';

interface IProps {
  title: string;
}

export const NatureOfWorkForm: FC<IProps> = ({ title }) => {
  const C = useFormStyles();
  const G = useGeneralStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { formMethods, submitNatureOfWork, id } = useSgwNatureOfWorkForm();
  const {
    register,
    setValue,
    formState: { errors },
  } = formMethods;
  const natureOfWorks = useSelector(getNatureOfWorks(id));
  const [disable, setDisable] = useState(false);
  const isEditMode = !!id;

  useEffect(() => {
    dispatch(SgwNatureOfWorksActions.list.fetch({}));
  }, [dispatch]);

  useEffect(() => {
    if (natureOfWorks) {
      setDisable(natureOfWorks.deleted);
    }
  }, [natureOfWorks]);

  const onCheckChange = (checked: boolean) => {
    setDisable(checked);
    setValue('deleted', checked);
  };

  const goToDetail = () => history.push(appUrls.sgw.manage.natureOfWorks.detail(id));

  return (
    <FormProvider {...formMethods} data-testid="natureOfWorksForm">
      <form onSubmit={submitNatureOfWork} id="createNatureOfWork" noValidate>
        <Panel title={title}>
          <Visible visible={isEditMode}>
            <PanelAction icon={<Edit />} data-testid="saveButton" onClick={goToDetail} />
          </Visible>
          <Feedback.Info>{translate('sgw.natureOfWorks.clarification')}</Feedback.Info>
          <Grid container spacing={3} className={C.formGrid}>
            <Grid item xs={12}>
              <TextField
                {...register('name', { required: true })}
                label={translate('sgw.natureOfWorks.columns.name')}
                className={G.fullWidth}
                required
                data-testid="nameField"
                error={!!errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('description', { required: true })}
                label={translate('sgw.natureOfWorks.columns.description')}
                className={G.fullWidth}
                required
                data-testid="descriptionField"
                error={!!errors.description}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('gipodGuid', { required: true })}
                label={translate('sgw.natureOfWorks.columns.gipodGuid')}
                className={G.fullWidth}
                required
                data-testid="gipodGuidField"
                error={!!errors.gipodGuid}
              />
            </Grid>
            <Grid item xs={12}>
              <CheckboxWithLabel
                {...register('deleted')}
                checked={disable}
                label={translate('sgw.natureOfWorks.deactivate')}
                onChange={onCheckChange}
                className={C.checkbox}
              />
            </Grid>
          </Grid>
        </Panel>
        <SubmitButton formId="createNatureOfWork">{translate('sgw.natureOfWorks.saveNatureOfWork')}</SubmitButton>
      </form>
    </FormProvider>
  );
};
