import axios from 'axios';
import { CONFIG, IApiPromise, IPagedApiParams } from '../..';
import { IMoovImportResponse, IMoovTemplate } from '../../../types';

export const MoovApi = {
  fetchAll: (): IApiPromise<IMoovTemplate[]> =>
    axios.get(`${CONFIG.backOfficeApi}/v1/moov/templates`, { params: { page: 1, page_size: 1000 } }),
  fetchList: (params: IPagedApiParams): IApiPromise<IMoovTemplate[]> =>
    axios.get(`${CONFIG.backOfficeApi}/v1/moov/templates`, { params }),
  importTemplates: (): IApiPromise<IMoovImportResponse> =>
    axios.get(`${CONFIG.backOfficeApi}/v1/moov/import_templates`),
};
