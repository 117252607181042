import { Field, FieldProps } from 'formik';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { FunctionComponent, useCallback } from 'react';
import { DateFormat } from '../../../types';
import { AsignDatepicker } from '../form-fields/datepicker/asign-datepicker.component';
import { IFormikDateField } from '../../../types';
import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme, IFormikDateField>({
  root: { color: ({ disabled, readOnly }) => (disabled && !readOnly ? 'gray' : 'black') },
});

export const FormikDateField: FunctionComponent<IFormikDateField> = (props) => {
  const {
    disabled = false,
    readOnly = false,
    name,
    label,
    minDate = null,
    maxDate = null,
    dateFormat = DateFormat.date,
    fullWidth = true,
    required,
  } = props;
  const C = useStyles(props);

  const changeCallback = useCallback(
    (fieldProps: FieldProps) => {
      return (value: Moment) => {
        if (value) {
          fieldProps.form.setFieldValue(name, value.format(dateFormat));
        }
      };
    },
    [name, dateFormat],
  );

  return (
    <Field
      name={name}
      Label={{ label }}
      type="text"
      required={required}
      component={(fieldProps: FieldProps) => (
        <AsignDatepicker
          disabled={disabled || readOnly}
          value={moment(fieldProps.field.value, dateFormat)}
          label={label}
          onChange={changeCallback(fieldProps)}
          minDate={minDate || undefined}
          maxDate={maxDate || undefined}
          fullWidth={fullWidth}
          required={required}
          className={C.root}
        />
      )}
    />
  );
};
