import { createSelector } from '@reduxjs/toolkit';
import { IRootState } from '../../root.state';
import { createAllEntitiesSelector } from '../../common/utils/normalized.util';

export const selectStorageFilesAllIds = (store: IRootState) => store.storage.allIds;
export const selectStorageFilesById = (store: IRootState) => store.storage.byId;

export const getStorageFiles = createAllEntitiesSelector(selectStorageFilesAllIds, selectStorageFilesById);

export const getLocalStorageFiles = (localIds: string[]) =>
  createSelector([getStorageFiles], (files) => files.filter(({ id }) => localIds.includes(id)));

export const getLocalUploadFiles = (localIds: string[]) =>
  createSelector([getLocalStorageFiles(localIds)], (files) =>
    files.map(({ uploadId, name }) => ({ file: uploadId, name })),
  );
