import { Link } from '@material-ui/core';
import * as I18n from 'i18n-js';
import { FunctionComponent } from 'react';
import * as React from 'react';
import { COLORS } from '../../../common';
import { Icon } from '../../../common/components/layout/icon.component';
import { IPublicDomainIntakeExtended } from '../../../types';
import LinkIcon from '@material-ui/icons/Link';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { isAppFeatureEnabled } from '../../../store/selectors';
import { Visible } from '../../../common/components/layout/Visible.component';

interface IProps {
  location?: IPublicDomainIntakeExtended;
}

const useStyles = makeStyles({
  button: { marginLeft: 10 },
  buttonContent: { display: 'flex' },
  googleMapsIcon: { width: '13px', marginRight: '5px' },
  viodIcon: { color: COLORS.PRIMARY, marginRight: '2px', marginLeft: '-5px', transform: 'rotate(-45deg)' },
});

export const LocationButtons: FunctionComponent<IProps> = ({ location }) => {
  const styles = useStyles();
  const isArcGisEnabled = useSelector(isAppFeatureEnabled.integrateDigipolisArcGis);

  return location ? (
    <>
      <Button.Link
        onClick={() => {
          window.open(location.googleMapsAddress);
        }}
      >
        <div className={styles.buttonContent}>
          <Icon.GoogleMaps className={styles.googleMapsIcon} />
          <Link>{I18n.t('Requests.Detail.Locations.GoogleMaps')}</Link>
        </div>
      </Button.Link>
      <Visible visible={isArcGisEnabled}>
        <Button.Link
          className={styles.button}
          onClick={() => {
            window.open(location.viodAddress);
          }}
        >
          <LinkIcon className={styles.viodIcon} />
          <Link>{I18n.t('Requests.Detail.Locations.VIOD')}</Link>
        </Button.Link>
      </Visible>
    </>
  ) : null;
};
