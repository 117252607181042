import { Paper } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { Visible } from '../../../../common/components/layout/Visible.component';
import { Auth } from '../../../../components/atoms';
import { IRequestAclActions, IRequestDetailComponent } from '../../../../types';
import { RequestPermitHistory } from './permit-history.container';
import { RequestHistory } from './request-history.container';

export const HistoryContainer: FunctionComponent<IRequestDetailComponent> = ({ requestId, visible = true }) => (
  <Visible visible={visible}>
    <Auth acl={[IRequestAclActions.viewRequest]}>
      <Paper>
        <RequestHistory requestId={requestId} />
        <RequestPermitHistory requestId={requestId} />
      </Paper>
    </Auth>
  </Visible>
);
