import { COLORS, MaterialMarkerIcon } from '../../common';
import { Marker } from 'react-leaflet';
import * as L from 'leaflet';
import { WorkOrderItemPopup } from './work-order-item-popup.component';
import * as React from 'react';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { IPlanningAclActions, IWorkOrderItem, IWorkOrderItemAclActions } from '../../types';
import { getWorkOrderItemTypeIcon } from '../utils/work-order-item-type-icon';
import { Position } from 'geojson';
import { WORK_ORDER_ITEM_TYPES } from '../planning.constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectMarkerPopup, selectWorkOrderItemsFilter } from '../store/planning.selectors';
import { closeMarkerPopup, fetchMarkerPopup } from '../store/planning.actions';
import { useAuthorization } from '../../hooks';

interface IProps {
  woi: IWorkOrderItem;
  onDeleteSign(): void;
  onDoubleClick(): void;
}

export const WorkOrderItemMarker: FC<IProps> = ({ onDeleteSign, onDoubleClick, woi }) => {
  const dispatch = useDispatch();
  const markerRef = useRef<L.Marker>(null);
  const markerPopup = useSelector(selectMarkerPopup);
  const timer = useRef<any>();
  const workOrderItemsFilter = useSelector(selectWorkOrderItemsFilter);

  markerRef?.current?.on('popupclose', () => {
    dispatch(closeMarkerPopup());
  });

  const { isAuthorized: canViewWorkOrderItem } = useAuthorization([
    IPlanningAclActions.viewPlanning,
    IWorkOrderItemAclActions.viewWorkOrderItem,
  ]);

  useEffect(() => {
    if (markerPopup?.workOrderItemToLoad === woi.id) {
      markerRef?.current?.openPopup();
    }
  }, [markerPopup?.workOrderItemToLoad, woi.id]);

  const _getWorkOrderItemTypeIcon = useMemo((): string => {
    return woi.state === 'done' ? woi.state : getWorkOrderItemTypeIcon(woi.type);
  }, [woi.state, woi.type]);

  const getWorkOrderItemIconColor = useMemo(() => {
    return woi.hasSmartSigns ? COLORS.BLACK : COLORS.WHITE;
  }, [woi.hasSmartSigns]);

  const getWorkOrderItemColor = useMemo(() => {
    return woi.workOrder ? woi.workOrder.team.color : 'lightgray';
  }, [woi.workOrder]);

  const getWorkOrderItemFillColor = useMemo(() => {
    return woi.pickupOverdue ? COLORS.BLACK : COLORS.WHITE;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workOrderItemsFilter.date, woi.pickupOverdue]);

  const getWorkOrderItemPosition = useMemo(() => {
    let position: Position = [0, 0];
    if (woi.parkingBanIntake && woi.parkingBanIntake.geometry) {
      if (woi.parkingBanIntake.geometry.type === 'Polygon') {
        position = woi.parkingBanIntake.geometry.coordinates[0][0] as Position;
      } else {
        position = woi.parkingBanIntake.geometry.coordinates[0] as Position;
      }
    } else if (woi.type !== WORK_ORDER_ITEM_TYPES.RETRIEVE && woi.sign && woi.sign.location) {
      position = woi.sign.location.coordinates as Position;
    } else if (woi.location && woi.location.type === 'Point') {
      position = woi.location.coordinates as Position;
    }
    return { lat: position[1], lng: position[0] };
  }, [woi.location, woi.parkingBanIntake, woi.sign, woi.type]);

  const onOpenPopup = useCallback(() => {
    // Fix strange behavior of double click when work not consistently
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      // Single click
      canViewWorkOrderItem && dispatch(fetchMarkerPopup(woi.id));
    }, 250);
  }, [canViewWorkOrderItem, dispatch, woi.id]);

  return (
    <Marker
      data-testid={`Marker-${woi.id}`}
      ref={markerRef}
      icon={MaterialMarkerIcon(
        _getWorkOrderItemTypeIcon,
        getWorkOrderItemIconColor,
        getWorkOrderItemColor,
        getWorkOrderItemFillColor,
      )}
      position={getWorkOrderItemPosition}
      eventHandlers={{
        dblclick: onDoubleClick,
        click: onOpenPopup,
      }}
    >
      {markerPopup?.workOrderItemToLoad === woi.id && (
        <WorkOrderItemPopup deleteSign={onDeleteSign} markerPopup={markerPopup} />
      )}
    </Marker>
  );
};
