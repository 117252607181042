import { Link, TableRow } from '@material-ui/core';
import { LinkProps } from '@material-ui/core/Link';
import { TableRowProps } from '@material-ui/core/TableRow';
import * as React from 'react';
import { FunctionComponent } from 'react';

interface IProps {
  disabled?: boolean;
}

export const LinkedTableRow: FunctionComponent<TableRowProps & LinkProps & IProps> = ({
  disabled = false,
  ...props
}) => {
  return disabled ? (
    <TableRow component="div" data-testid="table-row-disabled" {...props} />
  ) : (
    <TableRow
      component={(props: LinkProps) => (
        <Link
          data-testid="table-row-link"
          target={props.target || '_top'}
          style={{ textDecoration: 'inherit' }}
          {...props}
        />
      )}
      {...props}
    />
  );
};
