import React, { FC, useCallback } from 'react';
import { FormattedDate, IPagedTable, IPaging, ISorting } from '../../../common';
import { GenericTablePanel } from '../../../common/components/table/GenericTablePanel.component';
import { multiLineCell } from '../../../common/components/table/MultiLineCell.component';
import { translate } from '../../../common/translations/translate';
import { IUser } from '../../../common/types/user';
import { StatusIcon } from '../../../request/components/icons/status-icon.component';
import { IPublicDomainIntake, IRequestOverview, IRequestStatus } from '../../../types';
import { UserRequestListFilters } from '../UserRequestListFilters/UserRequestListFilters.component';
import { useRequestMetadata } from '../../../hooks';
import { appUrls } from '../../../common/config/url.constants';
import { useHistory } from 'react-router';

interface IProps {
  labelUnavailable: string;
  loading: boolean;
  onFetch(table: Partial<IPagedTable>): void;
  records: IRequestOverview[];
  table: IPagedTable;
  title: string;
}

export const MasterDataRequestOverview: FC<IProps> = ({
  labelUnavailable,
  loading,
  onFetch,
  records,
  table,
  title,
}) => {
  const { sorting, paging } = table;
  const metadata = useRequestMetadata();
  const history = useHistory();

  const onChangeSorting = (sorting: ISorting) => {
    onFetch({ sorting });
  };

  const onChangePaging = (paging: IPaging) => {
    onFetch({ paging });
  };

  const onClick = useCallback(
    (request: IRequestOverview) => {
      history.push(appUrls.requests.detail(request.id));
    },
    [history],
  );

  return (
    <GenericTablePanel<IRequestOverview>
      columnKey="id"
      filterNode={<UserRequestListFilters onFetch={onFetch} />}
      labelUnavailable={labelUnavailable}
      loading={loading}
      onChangeSorting={onChangeSorting}
      onChangePaging={onChangePaging}
      onClick={onClick}
      paging={paging}
      records={records}
      sorting={sorting}
      title={title}
      columns={[
        {
          label: translate('Requests.table.reference'),
          name: 'referenceId',
          sortable: true,
        },
        {
          label: translate('Requests.table.createdAt'),
          name: 'createdAt',
          renderer: (createdAt: string) => <FormattedDate date={createdAt} />,
          sortable: true,
        },
        {
          label: translate('Requests.table.dateFrom'),
          name: 'dateFrom',
          renderer: (dateFrom: string) => <FormattedDate date={dateFrom} />,
          sortable: true,
        },
        {
          label: translate('Requests.table.dateUntil'),
          name: 'dateUntil',
          renderer: (dateUntil: string) => <FormattedDate date={dateUntil} />,
          sortable: true,
        },
        {
          label: translate('Requests.table.locations'),
          name: 'publicDomainIntakes',
          renderer: (intakes: IPublicDomainIntake[]) =>
            multiLineCell(
              intakes.map(
                ({ street, streetNumberFrom, streetNumberTo }) => `${street} ${streetNumberFrom}-${streetNumberTo}`,
              ),
            ),
        },
        {
          label: translate('Requests.table.type'),
          name: 'publicDomainIntakes',
          renderer: (intakes: IPublicDomainIntake[]) => multiLineCell(intakes.map(({ type }) => type.name)),
        },
        { label: translate('Requests.table.eventName'), name: 'eventName', sortable: true },
        {
          label: translate('Requests.table.userResponsible'),
          name: 'userResponsible',
          sortable: true,
          renderer: (userResponsible?: IUser) => userResponsible?.fullName,
        },
        {
          label: translate('Requests.table.state'),
          name: 'state',
          sortable: true,
          renderer: (status: IRequestStatus) => (
            <StatusIcon statusState={status.state} metadataStates={metadata?.states} />
          ),
        },
      ]}
    />
  );
};
