import * as React from 'react';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IPaging, ISorting } from '../../../common';
import { GenericTablePanel } from '../../../common/components/table/GenericTablePanel.component';
import { ServicesActions } from '../../../common/store/services/Services.actions';
import { getWhitelistsList, selectWhitelistTable } from '../../../common/store/services/Services.selectors';
import { translate } from '../../../common/translations/translate';
import { linkClick } from '../../../common/utils/link.util';
import { IWhiteList } from '../../../types';

export const ServicesWhitelistListComponent: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { paging, sorting } = useSelector(selectWhitelistTable);
  const whiteLists = useSelector(getWhitelistsList);

  useEffect(() => {
    dispatch(ServicesActions.whitelistsList.fetch({}));
  }, [dispatch]);

  const onChangeSorting = useCallback(
    (sorting: ISorting) => {
      dispatch(ServicesActions.whitelistsList.fetch({ sorting }));
    },
    [dispatch],
  );

  const onChangePaging = useCallback(
    (paging: IPaging) => {
      dispatch(ServicesActions.whitelistsList.fetch({ paging }));
    },
    [dispatch],
  );

  const onClick = useCallback((whiteList: IWhiteList) => {
    linkClick(whiteList.downloadUrl);
  }, []);

  return (
    <GenericTablePanel
      labelUnavailable={translate('services.list.unavailable')}
      onChangeSorting={onChangeSorting}
      onChangePaging={onChangePaging}
      onClick={onClick}
      paging={paging}
      sorting={sorting}
      records={whiteLists}
      title={translate('services.whitelists.title')}
      columnKey="id"
      columns={[
        {
          label: translate('ID'),
          name: 'id',
          sortable: true,
        },
        {
          label: translate('services.whitelists.name'),
          name: 'name',
          sortable: true,
        },
        {
          label: translate('services.whitelists.uploader'),
          name: 'createdBy',
          renderer: (id: number, record?: IWhiteList) => record?.createdBy?.fullName,
          sortable: true,
        },
        {
          label: translate('services.whitelists.validDateFrom'),
          name: 'validDateFrom',
          sortable: true,
        },
      ]}
    />
  );
};
