import { DateFormat, ISgwRequestsFilter, SgwRequestSearchField } from '../../types';
import { atomWithStorage } from 'jotai/utils';
import moment from 'moment';

export const ATOM_HASHES = {
  attachments: {
    open: 'attcm-open',
    edit: 'attcm-edit',
    star: 'star',
  },
  conflicts: {
    conflictId: 'conflict-id',
    visibleConflicts: 'visible-conflicts',
  },
  partners: {
    open: (id?: number, value?: boolean) => `partner-${id}-open${value ? `=${value}` : ''}`,
  },
  permitConditions: {
    edit: 'permit-edit',
  },
  phases: {
    open: (id: string) => `ph-${id}-open`,
    edit: (id: string) => `ph-${id}-edit`,
  },
  reschedulings: {
    open: (id: number) => `resch-${id}-open`,
    editMode: 'resch-edit',
  },
};

export const ATOMS = {
  sgwRequest: {
    overview: {
      searchFieldAtom: atomWithStorage<SgwRequestSearchField>(
        'sgwRequestOverviewSearchField',
        SgwRequestSearchField.contactName,
      ),
      searchValueAtom: atomWithStorage<string>('sgwRequestOverviewSearchValue', ''),
      filtersAtom: atomWithStorage<ISgwRequestsFilter>('sgwRequestOverviewFilter', {
        projectIds: [],
        typeOfWork: [],
        activeFrom: moment().subtract(1, 'month').format(DateFormat.reduxStoreDateString),
        activeUntil: moment().add(1, 'year').format(DateFormat.reduxStoreDateString),
        clusters: [],
        state: [],
      }),
    },
  },
  users: {
    sgwRequest: {
      searchFieldAtom: atomWithStorage<SgwRequestSearchField>(
        'userDetailSgwRequestOverviewSearchField',
        SgwRequestSearchField.contactName,
      ),
      searchValueAtom: atomWithStorage<string>('userDetailSgwRequestOverviewSearchValue', ''),
      filtersAtom: atomWithStorage<ISgwRequestsFilter>('userDetailSgwRequestOverviewFilter', {
        projectIds: [],
        typeOfWork: [],
        clusters: [],
        state: [],
      }),
    },
  },
};
