import { IRootState } from '../../root.state';
import { createAllEntitiesSelector } from '../../common/utils/normalized.util';
import { createPagedApiParamsSelector } from '../../common/store/utils/generic.selectors';
import { INotificationsFilter } from '../../notification/types/notification';

export const selectNotifications = (store: IRootState) => store.notifications;

export const selectAllIds = (store: IRootState) => store.notifications.list.allIds;
export const selectById = (store: IRootState) => store.notifications.list.byId;
export const selectTable = (store: IRootState) => store.notifications.list.table;
export const selectListLoading = (store: IRootState) => store.notifications.list.loading;
export const selectCount = (store: IRootState) => store.notifications.count;
export const selectNotificationsHistory = (store: IRootState) => store.notifications.notificationHistory;
export const selectLoading = (store: IRootState) => store.notifications.loading;

export const getNotifications = createAllEntitiesSelector(selectAllIds, selectById);
export const getPagedApiParams = createPagedApiParamsSelector<INotificationsFilter>(selectTable);
