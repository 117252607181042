import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Table, TableCell, TableHead, TableRow } from '@material-ui/core';
import { COLORS } from '../../../common';
import { IWithClassName } from '../../../types';
import classNames from 'classnames';

interface IProps extends IWithClassName {
  label: string;
  rows?: (string | JSX.Element)[][];
}

const useStyles = makeStyles({
  label: {
    fontWeight: 'bold',
    borderBottom: `1px solid ${COLORS.GREY_LIGHT}`,
  },
  root: {
    width: '100%',
  },
  tableCell: {
    border: 'none',
  },
  tableHead: {
    fontWeight: 'bold',
    borderBottom: `1px solid ${COLORS.GREY_LIGHT}`,
  },
});

export const LabelList: FC<IProps> = ({ label, rows, className }) => {
  const C = useStyles();
  return (
    <Grid item className={classNames(C.root, className)}>
      <Table size={'small'}>
        <TableHead className={C.tableHead}>{label}</TableHead>
        {rows?.map((row, index) => (
          <TableRow key={`row-${index}`}>
            {row.map((cell, index) => (
              <TableCell key={`${cell}-${index}`} className={C.tableCell}>
                {cell}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </Table>
    </Grid>
  );
};
