import { Collapse, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Formik, FormikProps } from 'formik';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  FormikAutocompleteFetchField,
  FormikCheckboxField,
  FormikSelect,
  FormikTextField,
} from '../../../common/components/formikFormFields';
import { InfoText } from '../../../common/components/layout/InfoText.component';
import { PanelTitle } from '../../../common/components/panel/panel-title.component';
import { createLinestring, createPolygon, getBounds, getGeoCodeAddress } from '../../../common/utils/geojson.util';
import { getCity, getZipcodesAsFormikOptions } from '../../../common/utils/zipCodes.util';
import { useAuthorization } from '../../../hooks';
import {
  getRequestSuggestions,
  selectGeoCodeCorrect,
  selectRequestGeoCodesById,
  selectReverseGeocodesById,
} from '../../../store/selectors';
import {
  GateSas,
  IFetchGeocode,
  IFetchSuggestions,
  IFormikOption,
  IntakeTypes,
  IRequestAclActions,
  IRequestLocationForm,
} from '../../../types';
import { locationFormManagement } from './LocationForm.management';
import { translate, translateIgnoreTS } from '../../../common/translations/translate';
import { ErrorLabel } from '../../../components/atoms/ErrorLabel/ErrorLabel.component';
import { getGatesByCarFreeZoneId } from '../../../common/store/car-free-zones/carFreeZones.selectors';

interface IProps {
  canEditLocations: boolean;
  expanded: boolean;
  location: IRequestLocationForm;
  onRefreshGeocodes: (address: IFetchGeocode) => void;
  onRefreshSuggestions: (address: IFetchSuggestions) => void;
  onSave: (location: Partial<IRequestLocationForm>) => void;
  onValidationResult: (location: Partial<IRequestLocationForm>, flag: boolean) => void;
  onLocationTypeChanged: (location: Partial<IRequestLocationForm>) => void;
}

const useStyles = makeStyles({
  accessInformation: { margin: '15px 0px 0px' },
  form: { marginTop: 20, width: '100%' },
});

export const LocationForm: FunctionComponent<IProps> = ({
  canEditLocations,
  expanded,
  location,
  onRefreshGeocodes,
  onRefreshSuggestions,
  onSave,
  onValidationResult,
  onLocationTypeChanged,
}) => {
  const C = useStyles();
  const suggestions = useSelector(getRequestSuggestions);
  const geoCodes = useSelector(selectRequestGeoCodesById);
  const isGeoCodeCorrect = useSelector(selectGeoCodeCorrect);
  const reverseGeocode: Partial<IRequestLocationForm> | undefined = useSelector(selectReverseGeocodesById)[location.id];
  const [initialValues, setInitialValues] = useState<IRequestLocationForm>(
    locationFormManagement.getInitialValues(location),
  );
  const [updateGeometry, setUpdateGeometry] = useState<boolean>(false);
  const { isAuthorized: addCfzToRequestLocation } = useAuthorization(IRequestAclActions.addCfzToRequestLocation);
  const gatesIn = useSelector(getGatesByCarFreeZoneId(`${location.carFreeZone}`, GateSas.in));
  const gatesOut = useSelector(getGatesByCarFreeZoneId(`${location.carFreeZone}`, GateSas.out));

  useEffect(() => {
    if (expanded) {
      const city = translateIgnoreTS(`Cities.${location.zipCode}`);
      const geoCodeFrom = geoCodes[getGeoCodeAddress({ ...location, city, streetNumber: location.streetNumberFrom })];
      const geoCodeTo = geoCodes[getGeoCodeAddress({ ...location, city, streetNumber: location.streetNumberTo })];

      if (geoCodeFrom?.coordinates && geoCodeTo?.coordinates) {
        if (location.type === IntakeTypes.parkingbanintake && updateGeometry) {
          onSave({ ...location, geometry: createLinestring([geoCodeFrom, geoCodeTo]) });
        } else if (location.type === IntakeTypes.minorconstructionsiteintake && updateGeometry) {
          onSave({ ...location, geometry: createPolygon(geoCodeFrom, geoCodeTo) });
        } else if (location.type === IntakeTypes.carfreezoneintake && !location.bounds && updateGeometry) {
          onSave({ ...location, bounds: getBounds([geoCodeFrom.coordinates, geoCodeTo.coordinates]) });
        }
        // This should be turned off after the geocodes have arrived
        setUpdateGeometry(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded, geoCodes, location, onSave]);

  useEffect(() => {
    reverseGeocode && onSave({ ...location, ...reverseGeocode });
    setInitialValues(locationFormManagement.getInitialValues({ ...location, ...reverseGeocode }));
    // only when reverse geocode changes do we want to change the initial values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reverseGeocode]);

  useEffect(() => {
    setInitialValues(locationFormManagement.getInitialValues(location));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.carFreeZone]);

  const typeOptions = Object.keys(IntakeTypes)
    .map((value) => ({
      label: translateIgnoreTS(`IntakeTypes.${value}`),
      value,
    }))
    .filter((item) => {
      // AS-7196 According to the matrix, a nutsbedrijf should not be able to add a CFZ
      return !item.value.match(IntakeTypes.carfreezoneintake) || addCfzToRequestLocation;
    });

  const getZipCodeOptions = useCallback(getZipcodesAsFormikOptions, []);

  const _onRefreshGeocodes = (address: IFetchGeocode) => {
    setUpdateGeometry(true);
    onRefreshGeocodes(address);
  };

  const _validate = (values: IRequestLocationForm) => {
    const { type, zipCode, street, streetNumberUnknown, streetNumberFrom, streetNumberTo } = values;
    const relevantValuesChanged =
      street !== initialValues.street ||
      streetNumberFrom !== initialValues.streetNumberFrom ||
      streetNumberTo !== initialValues.streetNumberTo ||
      streetNumberUnknown !== initialValues.streetNumberUnknown ||
      type !== initialValues.type ||
      zipCode !== initialValues.zipCode;

    if (zipCode && street && relevantValuesChanged) {
      const city = getCity(zipCode);
      if (streetNumberUnknown) {
        _onRefreshGeocodes({ city, street, zipCode });
      } else {
        if (streetNumberFrom) _onRefreshGeocodes({ city, street, zipCode, streetNumber: streetNumberFrom });
        if (streetNumberTo) _onRefreshGeocodes({ city, street, zipCode, streetNumber: streetNumberTo });
      }

      if (!isGeoCodeCorrect) {
        let errors = {};
        if (zipCode !== initialValues.zipCode) {
          // @ts-ignore
          errors['zipCode'] = translate('Requests.Create.AddressNotFound');
        }

        if (Object.keys(errors).length !== 0) {
          onValidationResult(location, false);
          return errors;
        }
      }
    }
    onValidationResult(location, true);

    const { geometry, geometryType, carFreeZone, gisId, ...valuesToSave } = values;
    return locationFormManagement.requestValidateValues(valuesToSave, onSave);
  };

  const onChange = useCallback(
    (formikProps: FormikProps<IRequestLocationForm>) => (option: IFormikOption) => {
      formikProps.setFieldValue('geometry', undefined, false);
      formikProps.setFieldValue('type', option.value as IntakeTypes, false);
      formikProps.setFieldValue('gisId', undefined, false);
      location.geometry = undefined;
      location.geometryType = 'line';
      location.type = option.value as IntakeTypes;
      location.gisId = undefined;
      onLocationTypeChanged(location);
    },
    [onLocationTypeChanged, location],
  );

  return (
    <Formik initialValues={initialValues} onSubmit={onSave} validate={_validate} enableReinitialize>
      {(formikProps: FormikProps<IRequestLocationForm>) => (
        <Form className={C.form} data-testid="LocationForm" noValidate>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <FormikSelect
                disabled={!canEditLocations}
                label={translate('Requests.Create.Form.locationType')}
                name="type"
                options={typeOptions}
                onChange={onChange(formikProps)}
              />
            </Grid>

            <Grid item xs={12}>
              <PanelTitle className={C.accessInformation}>
                {translate('Requests.Create.Form.addressInformation')}
              </PanelTitle>
            </Grid>
            <Grid item xs={12}>
              <FormikSelect
                disabled={!canEditLocations}
                label={`${translate('Requests.Create.Form.zipCode')} - ${translate('Requests.Create.Form.city')}`}
                name="zipCode"
                options={getZipCodeOptions()}
              />
              <ErrorLabel
                showError={!!(formikProps.errors.zipCode && formikProps.touched.zipCode)}
                message={formikProps.errors?.zipCode}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikAutocompleteFetchField
                disabled={!canEditLocations}
                setFieldValue={formikProps.setFieldValue}
                label={translate('Requests.Create.Form.street')}
                name="street"
                onChangeText={(street: string) =>
                  onRefreshSuggestions({ street, zipCode: formikProps.values.zipCode || undefined })
                }
                required={false}
                options={suggestions}
                value={formikProps.values.street || ''}
              />
            </Grid>

            <Grid item xs={12}>
              <Collapse in={!formikProps.values.streetNumberUnknown}>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <FormikTextField
                      label={translate('Requests.Create.Form.streetNumberFrom')}
                      name="streetNumberFrom"
                      readOnly={!canEditLocations}
                      required={false}
                      value={formikProps.values.streetNumberFrom}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikTextField
                      label={translate('Requests.Create.Form.streetNumberTo')}
                      name="streetNumberTo"
                      required={false}
                      readOnly={!canEditLocations}
                      value={formikProps.values.streetNumberTo}
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>

            <Grid item xs={12}>
              <FormikCheckboxField
                disabled={!canEditLocations}
                label={translate('Requests.Create.Form.streetNumberUnknown')}
                name="streetNumberUnknown"
              />
            </Grid>

            <Grid item xs={12}>
              <FormikTextField
                disabled={!canEditLocations}
                label={translate('Requests.Create.Form.description')}
                multiline
                inputProps={{ style: { overflowX: 'hidden', overflow: 'auto', maxHeight: '18em' } }}
                name="description"
                required={false}
              />
            </Grid>

            <Grid item xs={12}>
              <Collapse in={formikProps.values.type === IntakeTypes.carfreezoneintake}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <PanelTitle>{translate('Requests.Create.Form.accessInformation')}</PanelTitle>
                    <InfoText>{translate('Requests.Create.Form.accessInformationExplanation')}</InfoText>
                  </Grid>
                  {gatesIn.length ? (
                    <Grid item xs={12}>
                      <FormikSelect
                        disabled={!canEditLocations}
                        label={translate('Requests.Detail.Locations.Info.CarFreeZoneEntrance')}
                        options={gatesIn.map(({ id, name }) => ({ label: name, value: id }))}
                        name="carFreeZoneGateEntrance"
                      />
                    </Grid>
                  ) : null}
                  {gatesOut.length ? (
                    <Grid item xs={12}>
                      <FormikSelect
                        disabled={!canEditLocations}
                        label={translate('Requests.Detail.Locations.Info.CarFreeZoneExit')}
                        options={gatesOut.map(({ id, name }) => ({ label: name, value: id }))}
                        name="carFreeZoneGateExit"
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </Collapse>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
