import { Actions } from '../../common';
import { ISnackBarFeedback } from '../../types';

class SnackBarActionsClass extends Actions {
  setFailure = this.createPayloadAction<string>('setFailure');
  setFeedback = this.createPayloadAction<ISnackBarFeedback | null>('setFeedback');
  setSuccess = this.createPayloadAction<string>('setSuccess');
}

export const SnackBarActions = new SnackBarActionsClass('snackBar');
