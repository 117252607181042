import { CircularProgress, Grid } from '@material-ui/core';
import React, { FunctionComponent, PropsWithChildren } from 'react';

type IProps = {
  loading?: boolean;
  style?: object;
  small?: boolean;
};

export const Loader: FunctionComponent<PropsWithChildren<IProps>> = ({ children, loading, small = false, style }) =>
  loading || !children ? (
    small ? (
      <CircularProgress data-testid="small-loader" size={20} style={style} />
    ) : (
      <Grid container data-testid="loader" justify="center" style={style}>
        <Grid item>
          <CircularProgress data-testid="loading" />
        </Grid>
      </Grid>
    )
  ) : (
    <>{children}</>
  );
