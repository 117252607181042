import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import I18n from 'i18n-js';
import * as React from 'react';

export const RetrieveButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Button variant="contained" color="secondary" onClick={onClick}>
      <Add />
      {I18n.t('GenerateRetrieveWorkOrderItem')}
    </Button>
  );
};
