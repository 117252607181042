import { combineReducers } from 'redux';
import { generateArrayReducer, generateObjectReducer, generatePrimitiveReducer, IPaging } from '../../common';
import { ISign } from '../types/sign';
import { ISignCondition } from '../types/sign-condition';
import { ISignCounters } from '../types/sign-counters';
import { ISignData } from '../types/sign-data';
import { ISignLocation } from '../types/sign-location';
import { ISignState } from '../types/sign-state';
import { ISignStateHistory } from '../types/sign-state-history';
import { ISignType } from '../types/sign-type';
import { ISignsForecast } from '../types/signs-forecast';
import { ISignMap } from '../types/signs-map';
import {
  setSign,
  setSignActiveLocation,
  setSignActiveLocationData,
  setSignConditions,
  setSignCounters,
  setSignErrors,
  setSignGPSLogs,
  setSignLoading,
  setSignLocations,
  setSignLocationsPaging,
  setSignsFilterDataLoaded,
  setSignsForecast,
  setSignsForecastLoading,
  setSignsMap,
  setSignsMapPopup,
  setSignStateHistory,
  setSignStates,
  setSignTypes,
  SignsActions,
} from './signs.actions';
import { initialSignsState } from './signs.state';
import { createPagedTableReducer } from '../../store/reducers/pagedTable.reducer';

export const signsReducer = combineReducers({
  sign: generateObjectReducer<ISign | null>(setSign.type, initialSignsState.sign),
  signActiveLocation: generateObjectReducer<ISignLocation | null>(
    setSignActiveLocation.type,
    initialSignsState.signActiveLocation,
  ),
  signActiveLocationData: generateArrayReducer<ISignData>(
    setSignActiveLocationData.type,
    initialSignsState.signActiveLocationData,
  ),
  signConditions: generateArrayReducer<ISignCondition>(setSignConditions.type, initialSignsState.signConditions),
  signCounters: generateObjectReducer<ISignCounters>(setSignCounters.type, initialSignsState.signCounters),
  signErrors: generatePrimitiveReducer<string[] | null>(setSignErrors.type, null),
  signGPSLogs: generateArrayReducer<ISignData>(setSignGPSLogs.type, initialSignsState.signGPSLogs),
  signLoading: generatePrimitiveReducer<boolean>(setSignLoading.type, initialSignsState.signLoading),
  signLocations: generateArrayReducer<ISignLocation>(setSignLocations.type, initialSignsState.signLocations),
  signLocationsPaging: generateObjectReducer<IPaging>(
    setSignLocationsPaging.type,
    initialSignsState.signLocationsPaging,
  ),
  signStateHistory: generateArrayReducer<ISignStateHistory>(
    setSignStateHistory.type,
    initialSignsState.signStateHistory,
  ),
  signStates: generateArrayReducer<ISignState>(setSignStates.type, initialSignsState.signStates),
  signTypes: generateArrayReducer<ISignType>(setSignTypes.type, initialSignsState.signTypes),
  list: createPagedTableReducer<ISign>({
    fetchAction: SignsActions.list.fetch.type,
    addEntitiesActions: [SignsActions.list.set.type],
    setAllIdsAction: SignsActions.list.set.type,
    setParamsActions: [SignsActions.list.fetch.type, SignsActions.list.setParams.type],
    initialParamsState: initialSignsState.list.table,
    setLoadingAction: SignsActions.list.setLoading.type,
  }),
  signsFilterDataLoaded: generatePrimitiveReducer<boolean>(
    setSignsFilterDataLoaded.type,
    initialSignsState.signsFilterDataLoaded,
  ),
  signsForecast: generateArrayReducer<ISignsForecast | null>(setSignsForecast.type, initialSignsState.signsForecast),
  signsWoiForecast: generateArrayReducer<ISignsForecast | null>(
    SignsActions.setSignsWoiForecast.type,
    initialSignsState.signsWoiForecast,
  ),
  signsForecastLoading: generatePrimitiveReducer<boolean>(
    setSignsForecastLoading.type,
    initialSignsState.signsForecastLoading,
  ),
  signsWoiForecastLoading: generatePrimitiveReducer<boolean>(
    SignsActions.setSignsWoiForecastLoading.type,
    initialSignsState.signsWoiForecastLoading,
  ),
  signsMap: generateObjectReducer<ISignMap | null>(setSignsMap.type, initialSignsState.signsMap),
  signsMapPopup: generateObjectReducer<ISign | null>(setSignsMapPopup.type, null),
});
