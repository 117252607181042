import { useDispatch, useSelector } from 'react-redux';
import { getList } from '../common/store/user/user.selectors';
import { useEffect } from 'react';
import { UserActions } from '../common/store/user/user.actions';
import { USER_ROLE_ACL_UTILITY_COMPANY } from '../common/types/user';

export const useUtilityCompanyUsers = (query: string) => {
  const dispatch = useDispatch();
  const loadedUsers = useSelector(getList);

  useEffect(() => {
    dispatch(
      UserActions.list.fetch({
        filters: { roles: [USER_ROLE_ACL_UTILITY_COMPANY], query },
      }),
    );
  }, [dispatch, query]);

  return loadedUsers;
};
