import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { genericErrorHandler, TPayloadAction } from '../../common';
import { INotification } from '../types/notification';
import { NotificationApi } from './notification.api';
import { ICall } from '../../types';
import { NotificationActions } from '../../store/actions/notifications.actions';
import { translate } from '../../common/translations/translate';
import { getPagedApiParams } from '../../store/selectors/notifications.selectors';
import { getPagingFromHeaders } from '../../common/utils/api.util';

function* onFetchNotification(action: TPayloadAction<string>): SagaIterator {
  try {
    const response: ICall<typeof NotificationApi.getNotification> = yield call(
      NotificationApi.getNotification,
      action.payload,
    );
    yield put(NotificationActions.set(response!.data.data));
  } finally {
    yield put(NotificationActions.list.setLoading(false));
    yield put(NotificationActions.fetchHistory(action.payload));
  }
}

function* onPatchNotification(action: TPayloadAction<INotification>): SagaIterator {
  try {
    const response: ICall<typeof NotificationApi.patchNotification> = yield call(
      NotificationApi.patchNotification,
      action.payload,
    );
    yield put(NotificationActions.set(response!.data.data));
    yield put(NotificationActions.fetchCount());
  } catch (error) {
    yield put(NotificationActions.setError(translate('Notifications.Dialogs.ApiErrorMessage')));
  } finally {
    yield put(NotificationActions.fetchHistory(action.payload.id.toString()));
  }
}

function* onFetchNotifications(): SagaIterator {
  const params = yield select(getPagedApiParams);

  const response: ICall<typeof NotificationApi.fetchList> = yield call(NotificationApi.fetchList, params);

  yield all([
    put(NotificationActions.list.set(response!.data.data)),
    put(
      NotificationActions.list.setParams({
        paging: getPagingFromHeaders(response as any),
      }),
    ),
  ]);
}

function* onFetchNotificationHistory(action: TPayloadAction<string>): SagaIterator {
  try {
    put(NotificationActions.setHistory([]));
    const response: ICall<typeof NotificationApi.getNotificationHistory> = yield call(
      NotificationApi.getNotificationHistory,
      action.payload,
    );
    const notificationHistory = response!.data.data;
    yield put(NotificationActions.setHistory(notificationHistory));
  } finally {
    yield put(NotificationActions.list.setLoading(false));
  }
}

function* onFetchNotificationCount(): SagaIterator {
  const response: ICall<typeof NotificationApi.getNotificationCount> = yield call(NotificationApi.getNotificationCount);
  yield put(NotificationActions.setCount(response?.data.data.civilianNotificationCount || 0));
}

export function* notificationsSaga(): SagaIterator {
  yield takeLatest(NotificationActions.list.fetch.type, genericErrorHandler(onFetchNotifications));
  yield takeLatest(NotificationActions.fetch.type, genericErrorHandler(onFetchNotification));
  yield takeLatest(NotificationActions.fetchCount.type, genericErrorHandler(onFetchNotificationCount));
  yield takeLatest(NotificationActions.patch.type, genericErrorHandler(onPatchNotification));
  yield takeLatest(NotificationActions.fetchHistory.type, genericErrorHandler(onFetchNotificationHistory));
}
