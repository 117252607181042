import { WarningOutlined, InfoOutlined } from '@material-ui/icons';
import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { FC, PropsWithChildren } from 'react';
import { Visible } from './Visible.component';
import { IWithClassName } from '../../../types';
import classNames from 'classnames';

const useStyles = makeStyles({
  root: {
    fontSize: '0.875rem',
    fontWeight: 'lighter',
    fontStyle: 'italic',
  },
  iconOutlined: {
    lineHeight: 25,
    float: 'left',
    marginRight: 15,
    fontSize: 'large',
  },
});

interface IPropsBase extends IWithClassName {
  icon?: React.ReactNode;
}

const BaseFeedback: FC<PropsWithChildren<IPropsBase>> = ({ icon, children, className }) => {
  const C = useStyles();
  return (
    <p className={classNames(C.root, className)}>
      <Visible visible={!!icon}>{icon}</Visible>
      {children}
    </p>
  );
};

export const Feedback = {
  Info: (props: PropsWithChildren<IWithClassName>) => {
    const C = useStyles();
    return (
      <BaseFeedback
        {...props}
        icon={<InfoOutlined data-testid="infoIcon" color="primary" className={C.iconOutlined} />}
      />
    );
  },
  Text: (props: PropsWithChildren<IWithClassName>) => <BaseFeedback {...props} />,
  Warning: (props: PropsWithChildren<IWithClassName>) => {
    const C = useStyles();
    return (
      <BaseFeedback
        {...props}
        icon={<WarningOutlined data-testid="warningIcon" color="error" className={C.iconOutlined} />}
      />
    );
  },
};
