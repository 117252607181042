import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { genericErrorHandler } from '../../common';
import { StorageActions } from '../actions';
import { StorageApi } from '../api';
import { ICall } from '../../types';

export const takeUploadResult = (fileId: string) => (action: any) =>
  [StorageActions.uploadError.type, StorageActions.uploadSuccess.type].includes(action.type) &&
  action.payload &&
  action.payload.id === fileId;

function* onUpload({ payload: { file, metadata } }: ReturnType<typeof StorageActions.upload>): SagaIterator {
  const fileMetaData = { ...metadata, loading: false };

  try {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const response: ICall<typeof StorageApi.uploadFile> = yield call(StorageApi.uploadFile, formData);
    fileMetaData.uploadId = response!.data.data[0].id;
  } catch (e: any) {
    if (e.response!.data!.msgs!.length >= 0) {
      fileMetaData.error = e.response.data.msgs[0].message;
    } else {
      fileMetaData.error = 'Error occurred while uploading';
    }
  } finally {
    yield put(StorageActions.uploadSuccess(fileMetaData));
  }
}

function* onBulkDownload({ payload }: ReturnType<typeof StorageActions.bulkDownload>): SagaIterator {
  const { id, id2, fileName } = payload;
  const response: ICall<typeof StorageApi.download> = yield call(StorageApi.download, payload);
  const url = window.URL.createObjectURL(new Blob([response!.data], { type: 'application/octet-stream' }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName ? `${fileName}.zip` : `download_${id}${id2 ? `_${id2}` : ''}.zip`);
  document.body.appendChild(link);
  link.click();

  // Clean up and remove the link
  link.parentNode?.removeChild(link);
}

export function* storageSaga(): SagaIterator {
  yield takeEvery(StorageActions.bulkDownload.type, genericErrorHandler(onBulkDownload));
  yield takeEvery(StorageActions.upload.type, genericErrorHandler(onUpload));
}
