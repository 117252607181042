import { Actions, IPaging, SyncActionsClass, TableActions } from '../..';
import {
  IPincodeManagement,
  IService,
  IServiceContact,
  IServiceContactWithId,
  IServiceFilter,
  IServiceNew,
  IUploadResult,
  IWhiteList,
  IWhitelistEntry,
  IWhitelistEntryListFilter,
} from '../../../types/services.types';

class APTRActionsClass extends SyncActionsClass {
  fetchLatestSync = this.createAction('fetchLatestSync');
  setLatestSync = this.createPayloadAction<string>('setLatestSync');
}

class ServicesActionsClass extends Actions {
  aptr = new APTRActionsClass(`${this.prefix}.aptr`);
  addContact = this.createPayloadAction<{ contact: IServiceContact; service: IService }>('addContact');
  create = this.createPayloadAction<{ service: IServiceNew; contacts: IServiceContact[] }>('create');
  deleteContact = this.createPayloadAction<{ contact: IServiceContactWithId; service: IService }>('deleteContact');
  fetch = this.createPayloadAction<string>('fetch');
  contactsList = new TableActions<IServiceContact, { id: string }>(`${this.prefix}.contactsList`);
  list = new TableActions<IService, IServiceFilter>(`${this.prefix}.list`);
  setExistingNames = this.createPayloadAction<string[]>('setExistingNames');
  save = this.createPayloadAction<IService>('save');
  savePincode = this.createPayloadAction<{ serviceId: string; pinCodeManagement: IPincodeManagement }>('savePincode');
  saving = this.createPayloadAction<boolean>('saving');
  savingPincode = this.createPayloadAction<boolean>('savingPincode');
  resetPincodes = this.createPayloadAction<string>('resetPincodes');
  searchName = this.createPayloadAction<string>('searchName');
  set = this.createPayloadAction<IService>('set');
  setContactList = this.createPayloadAction<IServiceContactWithId[]>('setContactList');
  setContactListPaging = this.createPayloadAction<IPaging>('setContactListPaging');
  setUploadResult = this.createPayloadAction<IUploadResult | null>('setUploadResult');
  whitelistsList = new TableActions<IWhiteList>(`${this.prefix}.whitelistsList`);
  whitelistEntriesList = new TableActions<IWhitelistEntry, IWhitelistEntryListFilter>(
    `${this.prefix}.whitelistEntriesList`,
  );
  uploadWhitelistFile = this.createPayloadAction<{ file: File; ignoreFileLength?: boolean }>('uploadWhitelistFile');
}

export const ServicesActions = new ServicesActionsClass('services');
