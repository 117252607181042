import React, { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { IWithClassName } from '../../../types';
import { createUseStyles } from 'react-jss';

interface IProps extends IWithClassName {
  title: string;
}

const useStyles = createUseStyles({
  block: {
    display: 'block',
  },
  title: {
    marginRight: '.5rem',
    fontWeight: 'bold',
  },
});

export const ColonSeparatedTitle: FC<PropsWithChildren<IProps>> = ({ title, children, className }) => {
  const C = useStyles();

  return (
    <div className={classNames(C.block, className)}>
      <span className={C.title}>{`${title}:`}</span>
      <span>{children}</span>
    </div>
  );
};
