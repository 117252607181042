import { Checkbox, Chip, Collapse, Grid, TextField } from '@material-ui/core';

import { CheckboxProps } from '@material-ui/core/Checkbox';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import { Save } from '@material-ui/icons';
import * as I18n from 'i18n-js';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { ChangeEvent, FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'react-use';
import { FormattedDateTime } from '../../../../common';
import { Button } from '../../../../common/components/buttons/asign-button-extensions';
import { AsignDatepicker } from '../../../../common/components/form-fields/datepicker/asign-datepicker.component';
import { Feedback } from '../../../../common/components/layout/Feedback.component';
import { Visible } from '../../../../common/components/layout/Visible.component';
import { PanelTitle } from '../../../../common/components/panel/panel-title.component';
import { IUser } from '../../../../common/types/user';
import { useAuthorization, useCurrentUser } from '../../../../hooks';
import { RequestLocationsActions } from '../../../../store/actions';
import { selectRequestDetailById } from '../../../../store/selectors';
import { IPublicDomainIntakeExtended, IRequestAclActions, RequestStates } from '../../../../types';
import { LicensePlatesEditor } from './license-plates-editor.component';

const GreenCheckbox = withStyles({
  root: {
    marginLeft: -11,
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

interface IProps {
  location: IPublicDomainIntakeExtended;
}

const getUtcTime = (placedDate: string, placedTime: string): Moment => {
  return moment(placedDate + 'T' + placedTime + ':00' + moment(placedDate).format('Z'), moment.ISO_8601).utc();
};

/** For requests placed by Werkhaven */
export const LocationLicensePlatesRequesterComponent: FunctionComponent<IProps> = ({ location }) => {
  const dispatch = useDispatch();
  const currentUser: IUser | null = useCurrentUser();
  const request = useSelector(selectRequestDetailById)[location.permitRequestId];
  const [parkedPlates, setParkedPlates] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [placedDate, setPlacedDate] = useState<string>(moment().format('YYYY-MM-DD'));
  const [placedTime, setPlacedTime] = useState<string | undefined>();
  const [noParkedVehicles, toggleNoParkedVehicles] = useToggle(false);

  useEffect(() => {
    setParkedPlates(location.parkedPlates || []);
    setPlacedDate(location.signsPlacedAtDate || moment().format('YYYY-MM-DD'));
    setPlacedTime(location.signsPlacedAtTime);
    toggleNoParkedVehicles(!!location.noParkedVehicles);
  }, [location, toggleNoParkedVehicles]);

  const handleDateChange = (date: Moment) => {
    setPlacedDate(date.format('YYYY-MM-DD'));
  };

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlacedTime(event.target.value);
  };

  // If request is not from utility-company OR (user is from utility-company that owns the request AND request is not in_effect): SHOW THE LICENSE PLATE ADD FORM
  const isOkForUtilityCompany =
    !request.utilityCompanyRequested ||
    (request.utilityCompanyRequested &&
      request.state.state !== RequestStates.in_effect &&
      currentUser &&
      ((!!currentUser.utilityCompany && currentUser.utilityCompany?.id === request.utilityCompanyRequested?.id) ||
        (!currentUser.utilityCompany && currentUser?.id === request.userResponsible?.id)));

  const nonEditableStates = [RequestStates.canceled, RequestStates.rejected, RequestStates.closed];
  const parkedPlatesEditable = !nonEditableStates.includes(request.state.state) && isOkForUtilityCompany;

  const canSaveTime =
    placedDate &&
    placedTime &&
    (placedDate !== location.signsPlacedAtDate || placedTime !== location.signsPlacedAtTime);
  const canAddLicensePlates = !!(
    location.signsPlacedAtDate &&
    location.signsPlacedAtTime &&
    placedDate === location.signsPlacedAtDate &&
    placedTime === location.signsPlacedAtTime
  );

  const { isAuthorized: canAddLicensePlatesToRequest } = useAuthorization(IRequestAclActions.addLicensePlatesToRequest);
  const showAddMode = canAddLicensePlatesToRequest;

  const validate = useCallback(() => {
    setErrorMessage(null);
    if (!moment(placedTime, 'HH:mm', true).isValid()) {
      setErrorMessage(I18n.t('Requests.Detail.LicensePlates.InvalidTimeFormat'));
      return false;
    }
    if (!canSaveTime) {
      setErrorMessage(I18n.t('Requests.Detail.LicensePlates.FieldsSetIncorrectly'));
      return false;
    }
    return true;
  }, [setErrorMessage, placedTime, canSaveTime]);

  const saveHandler = useCallback(
    (props: { parkedPlates?: string[]; noParkedVehicles?: boolean; signsPlacedAt?: string }) => {
      if (canAddLicensePlatesToRequest) {
        dispatch(
          RequestLocationsActions.patch({
            id: `${location.permitRequestId}`,
            locationId: `${location.id}`,
            ...props,
          }),
        );
      }
    },
    [dispatch, canAddLicensePlatesToRequest, location.id, location.permitRequestId],
  );

  const saveTimeHandler = useCallback(() => {
    if (validate() && placedTime && location.permitRequestId) {
      const utcDate = getUtcTime(placedDate, placedTime);
      saveHandler({ signsPlacedAt: utcDate.format() });
    }
  }, [saveHandler, validate, location.permitRequestId, placedDate, placedTime]);

  const onParkedPlatesChange = useCallback(
    (newParkedPlates: string[]) => {
      saveHandler({ parkedPlates: newParkedPlates });
      setParkedPlates(newParkedPlates);
    },
    [saveHandler, setParkedPlates],
  );

  const _toggleNoParkedVehicles = (_: ChangeEvent<HTMLInputElement>, checked: boolean) =>
    saveHandler({ noParkedVehicles: checked });

  return (
    <Grid container alignItems={'flex-end'} data-testid="LocationLicensePlatesByRequester">
      <Visible visible={!parkedPlatesEditable}>
        <Grid item xs={12}>
          <Feedback.Warning>{I18n.t('Requests.Detail.LicensePlates.PeriodWasClosed')}</Feedback.Warning>
        </Grid>
      </Visible>

      <Visible visible={!location.signsPlacedAt}>
        <Grid item xs={12}>
          {I18n.t('Requests.Detail.LicensePlates.NoInfoWasSubmitted')}
        </Grid>
      </Visible>

      <Visible visible={!(parkedPlatesEditable && canAddLicensePlatesToRequest) && !!location.signsPlacedAt}>
        <div className="panel-body">
          <div className="row">
            <div className="col s12">
              <Visible visible={!!location.noParkedVehicles}>
                {I18n.t('Requests.Detail.LicensePlates.NoParkedVehiclesWhenTheSinsPlaced')}
              </Visible>
              {parkedPlates.map((pp) => {
                return <Chip key={pp} label={pp} style={{ marginRight: 15, marginBottom: 15 }} />;
              })}
            </div>
          </div>
          <div className="row">
            <div className="col s12">
              <p>
                {I18n.t('Requests.Detail.LicensePlates.PlacedAt')}{' '}
                <FormattedDateTime dateTime={location.signsPlacedAt} />
              </p>
            </div>
          </div>
        </div>
      </Visible>

      <Visible visible={!!parkedPlatesEditable && canAddLicensePlatesToRequest}>
        <Feedback.Warning>{I18n.t('Requests.Detail.LicensePlates.WarningNoAnyLicensePlates')} </Feedback.Warning>
        <Feedback.Warning>
          {I18n.t('Requests.Detail.LicensePlates.CanBeEditedUntilPermitGoesInEffect')}
        </Feedback.Warning>
        <hr />

        <Grid container>
          <Grid item xs>
            <PanelTitle>{I18n.t('Requests.Detail.LicensePlates.PlacementInformation')}</PanelTitle>
          </Grid>
        </Grid>

        <Grid container justify="flex-start" spacing={4}>
          <Grid item xs={4}>
            <AsignDatepicker label={I18n.t('Date')} value={moment(placedDate)} onChange={handleDateChange} fullWidth />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="message_input"
              name="message"
              value={placedTime}
              placeholder="HH:mm"
              label={I18n.t('Timestamp')}
              fullWidth
              margin="none"
              multiline
              onChange={handleTimeChange}
            />
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'right', alignSelf: 'flex-end' }}>
            <Button.Green
              color="secondary"
              onClick={saveTimeHandler}
              disabled={!canSaveTime}
              startIcon={<Save fontSize="small" />}
            >
              {I18n.t('Save')}
            </Button.Green>
          </Grid>
        </Grid>

        <Visible visible={!!errorMessage}>
          <Grid container justify="center" className="alert-warning">
            <Grid item xs={12}>
              {errorMessage}
            </Grid>
          </Grid>
        </Visible>

        <Grid container justify="flex-start" style={{ marginTop: '1em' }}>
          <Grid item xs>
            <PanelTitle>{I18n.t('Requests.Detail.LicensePlates.LicensePlates')}</PanelTitle>
            <Visible visible={!canAddLicensePlates}>
              <Feedback.Warning>
                {I18n.t('Requests.Detail.LicensePlates.WarningLicensePlatesDisabled')}
              </Feedback.Warning>
            </Visible>
            <GreenCheckbox
              checked={noParkedVehicles}
              onChange={_toggleNoParkedVehicles}
              name="noParkedVehicles"
              disabled={!canAddLicensePlates}
            />
            <label htmlFor="noParkedVehicles">{I18n.t('Requests.Detail.LicensePlates.NoParkedVehicles')}</label>
          </Grid>
        </Grid>

        <Visible visible={!location.noParkedVehicles}>
          <Grid container>
            <Grid item xs>
              <Collapse in={!noParkedVehicles}>
                <LicensePlatesEditor
                  parkedPlates={parkedPlates}
                  onParkedPlatesChange={onParkedPlatesChange}
                  disabled={!canAddLicensePlates}
                  showAddMode={showAddMode}
                />
              </Collapse>
            </Grid>
          </Grid>
        </Visible>
      </Visible>
    </Grid>
  );
};
