import { useForm } from 'react-hook-form';
import { IRouterWithId } from '../../types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getAdvisingParty, selectLoading } from '../../store/selectors/sgwAdvisingParties.selectors';
import { ISgwAdvisingParty } from '../../types';
import { SgwAdvisingPartiesActions } from '../../store/actions/sgwAdvisingParties.actions';

export const useSgwAdvisingPartiesForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams<IRouterWithId>();
  const initialValues = useSelector(getAdvisingParty(id));
  const loading = useSelector(selectLoading);

  const formMethods = useForm<ISgwAdvisingParty>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: { ...initialValues },
  });

  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    if (id) {
      dispatch(SgwAdvisingPartiesActions.fetch(id));
    } else {
      dispatch(SgwAdvisingPartiesActions.setLoading(false));
    }
  }, [dispatch, id]);

  useEffect(() => {
    !loading && reset(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, loading]);

  const _onSubmit = (values: ISgwAdvisingParty) => dispatch(SgwAdvisingPartiesActions.save(values));

  return { formMethods, submitAdvisingParty: handleSubmit(_onSubmit), id };
};
