import * as React from 'react';
import { Route, Switch } from 'react-router';
import { appUrls } from '../../common/config/url.constants';
import { Auth } from '..';
import { ISgwRequestAclActions } from '../../types';
import { RequestDetailPage, Dashboard } from '../pages';

export const RequestRoutes = () => (
  <Auth
    acl={[ISgwRequestAclActions.viewSgwRequestOverview, ISgwRequestAclActions.viewSgwRequestDetail]}
    showUnauthorizedPage
  >
    <Switch>
      <Route exact path={appUrls.sgw.requests.base}>
        <Dashboard />
      </Route>
      <Route exact path={appUrls.sgw.requests.detail()}>
        <RequestDetailPage />
      </Route>
    </Switch>
  </Auth>
);
