import { push } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { genericErrorHandler, TPayloadAction } from '../../common';
import { appUrls } from '../../common/config/url.constants';
import { translate } from '../../common/translations/translate';
import { getPagingFromHeaders } from '../../common/utils/api.util';
import { ICall, ISelect, ISgwPermitCondition, ISgwPermitConditionsPagedApiParams } from '../../types';
import { SgwPermitConditionsActions, SnackBarActions } from '../actions';
import { SgwPermitConditionsApi } from '../api';
import { getPagedApiParams, getSgwPermitCondition } from '../selectors/sgwPermitConditions.selectors';

function* onFetchList() {
  const params: ISelect<typeof getPagedApiParams> = yield select(getPagedApiParams);
  const response: ICall<typeof SgwPermitConditionsApi.fetchList> = yield call(
    SgwPermitConditionsApi.fetchList,
    params!,
  );
  yield all([
    put(
      SgwPermitConditionsActions.list.setParams({
        paging: getPagingFromHeaders(response as any),
      }),
    ),
    put(SgwPermitConditionsActions.list.set(response!.data.data)),
  ]);
}

function* onSave({ payload }: TPayloadAction<ISgwPermitCondition>): SagaIterator {
  const { id, ...permitCondition } = payload;
  const currentPermitCondition: ISgwPermitCondition = yield select(getSgwPermitCondition(`${id}`));

  try {
    const response: ICall<typeof SgwPermitConditionsApi.save> = yield call(SgwPermitConditionsApi.save, {
      ...currentPermitCondition,
      ...permitCondition,
      id,
    });

    yield put(SgwPermitConditionsActions.set(response!.data.data));
    yield put(SgwPermitConditionsActions.setLoading(false));
    yield put(SnackBarActions.setSuccess(translate('sgw.permitConditions.saved')));
    yield put(push(appUrls.sgw.manage.permitConditions.detail(`${response!.data.data.id}`)));
  } catch (e) {
    throw e;
  } finally {
    yield put(SgwPermitConditionsActions.setLoading(false));
  }
}

function* onFetch({ payload }: TPayloadAction<string>) {
  const response: ICall<typeof SgwPermitConditionsApi.fetch> = yield call(SgwPermitConditionsApi.fetch, payload);
  yield put(SgwPermitConditionsActions.set(response!.data.data));
  yield put(SgwPermitConditionsActions.setLoading(false));
}

function* onSearchReferenceCode({ payload }: TPayloadAction<string>) {
  const response: ICall<typeof SgwPermitConditionsApi.fetchList> = yield call(SgwPermitConditionsApi.fetchList, {
    referenceCode: payload,
  } as ISgwPermitConditionsPagedApiParams);
  yield put(
    SgwPermitConditionsActions.setExistingReferenceCodes(
      response!.data.data!.map(({ referenceCode }) => referenceCode),
    ),
  );
}

function* onSearchTitle({ payload }: TPayloadAction<string>) {
  const response: ICall<typeof SgwPermitConditionsApi.fetchList> = yield call(SgwPermitConditionsApi.fetchList, {
    title: payload,
  } as ISgwPermitConditionsPagedApiParams);
  yield put(SgwPermitConditionsActions.setExistingTitles(response!.data.data!.map(({ title }) => title)));
}

export function* sgwPermitConditionsSaga(): SagaIterator {
  yield takeLatest(SgwPermitConditionsActions.list.fetch.type, genericErrorHandler(onFetchList));
  yield takeLatest(SgwPermitConditionsActions.save.type, genericErrorHandler(onSave));
  yield takeLatest(SgwPermitConditionsActions.fetch.type, genericErrorHandler(onFetch));
  yield takeLatest(SgwPermitConditionsActions.searchReferenceCode.type, genericErrorHandler(onSearchReferenceCode));
  yield takeLatest(SgwPermitConditionsActions.searchTitle.type, genericErrorHandler(onSearchTitle));
}
