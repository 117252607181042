import { makeStyles } from '@material-ui/styles';
import { COLORS } from '../../../common';

export const HEADER_HEIGHT = 59;

export const useMainMenuStyles = makeStyles({
  content: {
    marginTop: HEADER_HEIGHT,
  },
  header: {
    height: HEADER_HEIGHT,
    position: 'fixed',
    top: '0px',
    alignItems: 'center',
    background: COLORS.GREY_DARK,
    display: 'flex',
    width: '-webkit-fill-available',
    zIndex: 999,
    '& .MuiTab-root': {
      textTransform: 'none',
      fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
      fontSize: '14px !important',
      fontWeight: '400',
      padding: '0px 15px',
      minHeight: 'inherit',
      height: '38px',
    },
    '& .Mui-selected': {
      background: COLORS.BLACK,
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiBadge-badge': {
      backgroundColor: COLORS.DANGER,
      fontSize: '10px',
      marginTop: '2px',
      padding: '0px',
    },
  },
  image: {
    marginLeft: '80px',
    maxHeight: HEADER_HEIGHT,
  },
  logo: {
    height: 59,
  },
  logout: {
    alignItems: 'center',
    color: 'white',
    cursor: 'pointer',
    display: 'flex',
    gap: '5px',
    padding: '10px',
  },
  mainMenu: {
    background: COLORS.GREY_DARK,
    color: COLORS.WHITE,
    width: '-webkit-max-content',
  },
  infoButton: {
    paddingTop: 6,
    paddingRight: 10,
    paddingLeft: 10,
  },
  topRightButtons: {
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    right: 0,
  },
});
