import { CircularProgress, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Edit, Save } from '@material-ui/icons';
import SyncIcon from '@material-ui/icons/Sync';
import { Formik } from 'formik';
import { FormikProps } from 'formik/dist/types';
import * as React from 'react';
import { FunctionComponent, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { PanelAction } from '../../../../common';
import { Button } from '../../../../common/components/buttons/asign-button-extensions';
import { LoaderBlur } from '../../../../common/components/layout/LoaderBlur.component';
import { Visible } from '../../../../common/components/layout/Visible.component';
import ModalDialog from '../../../../common/components/modal-dialog/modal-dialog.component';
import { PanelContent } from '../../../../common/components/panel/panel-content.component';
import { PanelFooter } from '../../../../common/components/panel/panel-footer.component';
import { getPincodeSaving } from '../../../../common/store/services/Services.selectors';
import { translate } from '../../../../common/translations/translate';
import { Auth } from '../../../../components';
import { IServiceAclActions, IWithClassName } from '../../../../types';
import { IPincodeManagement, IService } from '../../../../types';
import { PincodeForm } from './PincodeForm.component';
import { pincodeFormManagement } from './PincodeForm.management';

const useStyles = makeStyles({
  grid: { marginTop: 20, marginBottom: 20, justifyContent: 'flex-end' },
});

interface IProps extends IWithClassName {
  onSave(pinCodeManagement: IPincodeManagement): void;
  onReset(): void;
  service: IService;
}

export const PinCodeDetails: FunctionComponent<IProps> = ({ className, onSave, onReset, service }) => {
  const C = useStyles();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [confirmSave, setConfirmSave] = useState(false);
  const [confirmReset, setConfirmReset] = useState<boolean>(false);
  const toggleEditMode = useCallback(() => setEditMode(!editMode), [editMode]);
  const saving = useSelector(getPincodeSaving);

  const onConfirmSave = useCallback(
    (formikProps: FormikProps<IPincodeManagement>) => () => {
      formikProps.setSubmitting(false);
      setConfirmSave(false);
      setEditMode(false);
      onSave(formikProps.values);
    },
    [setConfirmSave, setEditMode, onSave],
  );

  const onCloseSave = useCallback(
    (formikProps: FormikProps<IPincodeManagement>) => () => {
      formikProps.setSubmitting(false);
      setEditMode(false);
      setConfirmSave(false);
    },
    [],
  );

  const onConfirmReset = useCallback(() => {
    setConfirmReset(false);
    onReset();
  }, [onReset]);

  const onCloseReset = useCallback(() => {
    setConfirmReset(false);
  }, []);

  return (
    <Paper square data-testid="PinCodeManagement" className={className}>
      <Formik initialValues={pincodeFormManagement.getInitialValues(service)} onSubmit={() => setConfirmSave(true)}>
        {(formikProps) => (
          <PanelContent title={translate('services.detail.pincodeManagement.title')}>
            <Auth acl={[IServiceAclActions.editServicePincode]}>
              <PanelAction icon={<Edit />} onClick={toggleEditMode} data-testid="PincodeEditModeButton" />
            </Auth>
            <LoaderBlur loading={saving}>
              <Grid container spacing={2} className={C.grid}>
                <PincodeForm service={service} formikProps={formikProps} editMode={editMode} />
                <Visible visible={confirmSave}>
                  <ModalDialog
                    title={translate('services.dialogs.confirmResetPopup.title')}
                    data-testid="PincodesSavePopup"
                    onClose={onCloseSave(formikProps)}
                    onConfirm={onConfirmSave(formikProps)}
                    okButtonText={translate('yes')}
                    refuseButtonText={translate('no')}
                  >
                    <Typography>{translate('services.dialogs.confirmResetPopup.message')}</Typography>
                  </ModalDialog>
                </Visible>
                <Visible visible={confirmReset}>
                  <ModalDialog
                    title={translate('services.dialogs.confirmResetPopup.title')}
                    data-testid="PincodesResetPopup"
                    onClose={onCloseReset}
                    onConfirm={onConfirmReset}
                    okButtonText={translate('yes')}
                    refuseButtonText={translate('no')}
                  >
                    <Typography>{translate('services.dialogs.confirmResetPopup.message')}</Typography>
                  </ModalDialog>
                </Visible>
              </Grid>
            </LoaderBlur>
            <PanelFooter>
              <Visible visible={editMode}>
                <Button.Green
                  startIcon={<Save fontSize="small" />}
                  data-testid="PincodeSaveButton"
                  disabled={formikProps?.isSubmitting}
                  onClick={formikProps?.submitForm}
                >
                  {translate('Save')}
                </Button.Green>
              </Visible>
              <Visible visible={!editMode}>
                <Auth acl={[IServiceAclActions.editServicePincode]}>
                  <Button.Green
                    startIcon={saving ? <CircularProgress color="inherit" size={18} /> : <SyncIcon fontSize="small" />}
                    data-testid="PincodeResetButton"
                    onClick={() => setConfirmReset(true)}
                  >
                    {translate('services.detail.pincodeManagement.reset')}
                  </Button.Green>
                </Auth>
              </Visible>
            </PanelFooter>
          </PanelContent>
        )}
      </Formik>
    </Paper>
  );
};
