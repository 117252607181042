import { createUseStyles } from 'react-jss';
import { COLORS } from '../../../common';

export const useDraggableWorkOrderItemStyles = createUseStyles({
  root: {
    background: COLORS.WHITE,
    flex: 1,
    padding: 12,
  },
  warning: {
    cursor: 'pointer',
  },
  woiDone: {
    color: COLORS.GREY,
  },
  woiNotDone: {
    color: COLORS.BLACK,
  },
  isShownOnMap: {
    cursor: 'pointer',
  },
  border: {
    paddingBottom: 1,
  },
  address: {
    flex: 3,
  },
  addressIcon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  addressContent: {
    paddingLeft: 5,
    width: 'max-content',
  },
  icons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  showWarning: {
    color: COLORS.DANGER,
  },
  woiIconWithZoneSigns: {
    color: COLORS.DANGER,
    display: 'flex',
    alignItems: 'center',
  },
  woiIconWithoutZoneSigns: {
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
  },
  numberOfSignsWithZoneSigns: {
    fontSize: 18,
    textAlign: 'center',
    color: COLORS.DANGER,
    display: 'flex',
    alignItems: 'center',
  },
  numberOfSignsWithoutZoneSigns: {
    fontSize: 18,
    textAlign: 'center',
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
  },
  removeButton: {
    marginLeft: 10,
    display: 'flex',
    alignItems: 'center',
  },
  removeButtonIcon: {
    color: COLORS.DANGER,
    cursor: 'pointer',
  },
  spacer: {
    width: 24,
  },
});
