import { makeStyles } from '@material-ui/styles';
import React, { FC } from 'react';
import { COLORS } from '../../../common';

interface IProps {
  count: number;
  variant: Variant;
}
type Variant = 'BLUE' | 'RED';

const useStyles = makeStyles<string, { variant: Variant }>({
  container: ({ variant }) => ({
    alignItems: 'center',
    backgroundColor: `${COLORS[variant]}33`,
    borderRadius: '5px',
    color: `${COLORS[variant]}`,
    display: 'flex',
    fontWeight: 'bold',
    height: '25px',
    justifyContent: 'center',
    width: '25px',
  }),
});

export const Counter: FC<IProps> = ({ count, variant }) => {
  const C = useStyles({ variant });
  return <div className={C.container}>{count}</div>;
};
