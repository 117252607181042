import { combineReducers } from 'redux';
import {
  IConflictGroup,
  IRequestMessagesList,
  ISgwRequest,
  ISgwRequestCounters,
  ISgwRequestsFilter,
} from '../../../types';
import { createPagedTableReducer, createByIdReducer } from '../pagedTable.reducer';
import { SgwRequestActions } from '../../actions';
import { generateObjectReducer, generatePayloadReducer } from '../../../common';
import { initialSgwRequestsState } from '../../state';
import { SgwPhasesActions } from '../../actions/phases.actions';
import { IRequestRescheduling } from '../../../types/SgwReschedulings.types';
import { ISgwRequestHistory } from '../../../types/sgwRequestHistory.types';
import { quarterCostReducer } from './quarterCost.reducer';

const conflictReducer = combineReducers({
  loading: generatePayloadReducer([SgwRequestActions.conflicts.setLoading.type], false),
  groups: generatePayloadReducer<IConflictGroup[]>([SgwRequestActions.conflicts.setList.type], []),
  messages: combineReducers({
    byId: createByIdReducer<IRequestMessagesList>([], [SgwRequestActions.conflicts.setMessage.type]),
  }),
});

export const requestsReducer = combineReducers({
  attachments: generatePayloadReducer([SgwRequestActions.attachments.set.type], []),
  attachmentsLoading: generatePayloadReducer([SgwRequestActions.attachments.setLoading.type], true),
  counters: generateObjectReducer<ISgwRequestCounters>(
    SgwRequestActions.setRequestCounters.type,
    initialSgwRequestsState.counters,
  ),
  list: createPagedTableReducer<ISgwRequest, ISgwRequestsFilter>({
    fetchAction: SgwRequestActions.list.fetch.type,
    addEntitiesActions: [SgwRequestActions.list.set.type],
    setAllIdsAction: SgwRequestActions.list.set.type,
    setParamsActions: [SgwRequestActions.list.fetch.type, SgwRequestActions.list.setParams.type],
    initialParamsState: initialSgwRequestsState.list.table,
    addEntityActions: [SgwRequestActions.set.type],
  }),
  loading: generatePayloadReducer([SgwRequestActions.setLoading.type], false),
  phases: generatePayloadReducer([SgwPhasesActions.setList.type], []),
  request: generateObjectReducer<ISgwRequest | null>(SgwRequestActions.set.type, initialSgwRequestsState.request),
  conflicts: conflictReducer,
  reschedulings: generatePayloadReducer<IRequestRescheduling[]>([SgwRequestActions.reschedulings.set.type], []),
  quarterCost: quarterCostReducer,
  history: generatePayloadReducer<ISgwRequestHistory>(
    [SgwRequestActions.setSgwRequestHistory.type],
    initialSgwRequestsState.history,
  ),
  permitHistory: generatePayloadReducer([SgwRequestActions.setSgwRequestPermitHistory.type], []),
});
