import React, { FC } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ISgwPhase } from '../../../types';
import { translate } from '../../../common/translations/translate';
import { LabelList } from '../../atoms/LabelList/LabelList.component';
import { TextField } from '@material-ui/core';
import { usePhaseDetailStyles } from '../../../style/phaseDetails.styles';
import { CloseSharp as Close } from '@material-ui/icons';
import { PhaseAddLocationForm } from '../../forms';

interface IProps {
  phaseId?: number;
}

export const PhaseEditLocationsTable: FC<IProps> = ({ phaseId }) => {
  const { register, control } = useFormContext<ISgwPhase>();
  const C = usePhaseDetailStyles();
  const { fields, remove, append } = useFieldArray({
    control,
    name: 'addresses',
  });

  return (
    <div className={C.table}>
      <LabelList
        label={translate('sgw.requests.detail.phases.generalInfo.streetNames')}
        rows={fields.map(({ id, streetName, streetNumberFrom, streetNumberTo }, index) => [
          <TextField fullWidth key={`${id}-streetName`} {...register(`addresses.${index}.streetName` as const)} />,
          <TextField
            className={C.streetNumberColumn}
            key={`${id}-streetNumberFrom`}
            {...register(`addresses.${index}.streetNumberFrom` as const)}
          />,
          <TextField
            className={C.streetNumberColumn}
            key={`${id}-streetNumberTo`}
            {...register(`addresses.${index}.streetNumberTo` as const)}
          />,
          <Close fontSize={'small'} className={C.clickableIcon} onClick={() => remove(index)} />,
        ])}
      />
      <PhaseAddLocationForm phaseId={phaseId} onAddAddress={append} />
    </div>
  );
};
