import { createReducer } from '@reduxjs/toolkit';
import { payloadReducer } from '../../../common';
import { IRequestMetadata } from '../../../types';
import { RequestActions } from '../../actions';

type TState = IRequestMetadata | null;

export default createReducer<TState>(null, {
  [RequestActions.setMetadata.type]: payloadReducer<TState>(),
});
