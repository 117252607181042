export enum SIGN_STATES {
  INACTIVE = 'inactive',
  STORED = 'stored',
  PLACED = 'placed',
  MOVED = 'moved',
  MISSING = 'missing',
  UPGRADE = 'upgrade',
}

export enum MODULE_STATES {
  PLACED = 'placed',
  STORAGE = 'storage',
  UPGRADE = 'upgrade',
}

export enum SIGN_CONDITIONS {
  OK = 'ok',
  DAMAGED = 'damaged',
  BROKEN = 'broken',
  LOW_BATTERY = 'low_battery',
}

export enum SIGN_TYPES {
  DIGITAL = 'sigfox',
  MANUAL = 'manual',
  MANUAL_ZONE = 'manual_zone',
}

export enum SIGN_FILTERS {
  NOT_EXTENDED = 'not_extended',
  NO_CONNECTION = 'no_connection',
  NO_GPS = 'no_gps',
  REQUIRE_MAINTENANCE = 'require_maintenance',
}

export enum SIGFOX_MESSAGE_TYPE {
  STATUS_BATTERY = 0,
  STATUS_LOCATION = 2,
}

export enum EVENT_TYPES {
  MOVED = 'manual',
  MISSING = 'missing',
  UNUSUAL_COMMUNICATION = 'unusual_communication',
  MISSING_INITAL_LOCATION = 'missing_inital_location',
  NO_ACTIVE_LOCATION = 'no_active_location',
}
