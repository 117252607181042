import React, { FC, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete } from '../../../common/components/form-fields/autocomplete.component';
import { translate } from '../../../common/translations/translate';
import { SgwRequestActions } from '../../../store/actions/sgwRequests.actions';
import { getAdvisingPartiesList } from '../../../store/selectors/sgwAdvisingParties.selectors';
import { useGeneralStyles } from '../../../style/generalStyles';

const useStyles = createUseStyles({
  autoComplete: {
    marginTop: '-10px',
    width: '180px',
  },
});

interface IProps {
  sgwAdvisingPartyId?: number;
  sgwRequestId: number;
  conflictGroupId: number;
}
export const SelectAdvisingParty: FC<IProps> = ({ sgwAdvisingPartyId, sgwRequestId, conflictGroupId }) => {
  const dispatch = useDispatch();
  const G = useGeneralStyles();
  const C = useStyles();
  const [value, setValue] = useState<number>(sgwAdvisingPartyId ? sgwAdvisingPartyId : 0);

  const advisingParties = useSelector(getAdvisingPartiesList);

  const onChange = (value?: number) => {
    if (value) {
      setValue(value);
      dispatch(
        SgwRequestActions.conflicts.save({
          requestId: sgwRequestId,
          conflictGroup: { id: conflictGroupId, sgwAdvisingPartyId: value },
        }),
      );
    }
  };

  return (
    <div onClick={(e) => e.stopPropagation()} className={G.marginRight}>
      <Autocomplete<number>
        placeholder={translate('sgw.requests.detail.advisingParties.placeholder')}
        menuItems={advisingParties.map((advisingParty) => ({
          value: advisingParty.id,
          label: advisingParty.name,
        }))}
        onChange={onChange}
        value={value}
        className={C.autoComplete}
        isClearable={false}
      />
    </div>
  );
};
