import { IGeometry } from '../types';
import { useEffect, useState } from 'react';
import { getArea, getDistance } from '../common/utils/geojson.util';

export const useAreaOrDistance = (initial?: IGeometry) => {
  const [geometryState, setGeometryState] = useState(initial);

  useEffect(() => {
    setGeometryState(initial);
  }, [initial]);

  return {
    areaOrDistance: getArea(geometryState) || getDistance(geometryState),
    geometryState,
    setGeometryState,
  };
};
