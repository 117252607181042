import { IPagedTable, ISortingDirection } from '../common';
import { IService, IServiceFilter, VehicleSource } from '../types';
import { baseInitialFilter } from '../common/config/paging.constants';
import { translate, translateIgnoreTS } from '../common/translations/translate';

export const SERVICE_SYNC_MOOV = 'syncWithMoov';
export const SERVICE_SYNC_ZONEGUARD = 'syncWithZoneguard';
export const SERVICE_SYNC_NONE = 'syncWithNone';

export const SERVICE_PINCODE_ACTIVE = 'active';
export const SERVICE_PINCODE_EXPIRED = 'expired';
export const SERVICE_PINCODE_NO_PINCODE = 'no_pincode';

export const WHITELIST_ENTRY_LIST_TYPES = ['license_plate', 'zapper'];

export const serviceInitialFilter: IServiceFilter = {
  pincodeStatus: [],
  syncWith: [],
  query: '',
  vehicleSource: [],
};

export const serviceInitialValues: Partial<IService> = {
  syncWithMoov: false,
  syncWithZoneguard: false,
};

export const whitelistFilesInitialFilter: IPagedTable = {
  ...baseInitialFilter,
};

export const whitelistEntryListInitialFilter: IPagedTable = {
  ...baseInitialFilter,
};

export const serviceListInitialFilter: IPagedTable<IServiceFilter> = {
  ...baseInitialFilter,
  filters: serviceInitialFilter,
  sorting: { key: 'name', direction: ISortingDirection.asc },
};

export const getServicePincodeFilter = () => [
  {
    value: SERVICE_PINCODE_ACTIVE,
    label: translate('services.pincodeFilter.active'),
  },
  {
    value: SERVICE_PINCODE_EXPIRED,
    label: translate('services.pincodeFilter.expired'),
  },
  {
    value: SERVICE_PINCODE_NO_PINCODE,
    label: translate('services.pincodeFilter.noPincode'),
  },
];

export const getServiceSyncFilter = () => [
  {
    id: SERVICE_SYNC_MOOV,
    name: translate('services.sync.moov'),
  },
  {
    id: SERVICE_SYNC_ZONEGUARD,
    name: translate('services.sync.zoneguard'),
  },
  {
    id: SERVICE_SYNC_NONE,
    name: translate('services.sync.none'),
  },
];

export const getWhitelistEntryListTypeFilter = () =>
  WHITELIST_ENTRY_LIST_TYPES.map((entry) => ({
    id: entry,
    name: translateIgnoreTS(`services.whitelists.entries.types.${entry}`),
  }));

export const vehicleSourceOptions = () =>
  Object.values(VehicleSource).map((vehicleSource) => ({
    label: translate(`services.form.vehicleSource.options.${vehicleSource}`),
    value: vehicleSource,
  }));
