import { Link } from '@material-ui/core';
import { FunctionComponent } from 'react';
import * as React from 'react';
import { COLORS } from '../../../common';
import AsignButton from '../../../common/components/buttons/asign-button';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';

interface IProps {
  title: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  visible?: boolean;
}

const styles = {
  root: {
    backgroundColor: COLORS.WHITE,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: COLORS.WHITE,
    },
  },
  icon: {
    width: '15px',
    color: COLORS.PRIMARY,
    marginRight: '5px',
  },
};

export const EditButton: FunctionComponent<IProps> = ({ title, onClick, disabled = false, visible }) => (
  <AsignButton style={styles.root} onClick={onClick} disabled={disabled} visible={visible}>
    <BorderColorOutlinedIcon style={styles.icon} />
    <Link>{title}</Link>
  </AsignButton>
);
