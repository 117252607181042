import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Counter } from '../..';
import { FormattedDate, IPaging, ISorting } from '../../../common';
import { Icon } from '../../../common/components/layout/icon.component';
import { Visible } from '../../../common/components/layout/Visible.component';
import { GenericTablePanel } from '../../../common/components/table/GenericTablePanel.component';
import { translate, translateIgnoreTS } from '../../../common/translations/translate';
import { IUser } from '../../../common/types/user';
import { FeedbackIcon } from '../../../request/components/icons/feedback-icon';
import { UrgentIcon } from '../../../request/components/icons/urgent-icon';
import { SgwRequestActions } from '../../../store/actions';
import {
  getSgwRequestsListWithAddedFields,
  selectListLoading,
  selectTable,
} from '../../../store/selectors/sgwRequest.selectors';
import { ConflictGroupState, IRequestMetadataState, ISgwRequestOverview } from '../../../types';
import { SgwRequestListFilters } from '..';
import { StatusIcon } from '../../../request/components/icons/status-icon.component';
import { appUrls } from '../../../common/config/url.constants';
import { useRequestMetadata } from '../../../hooks';
import { Tooltip } from '@material-ui/core';
import { createUseStyles } from 'react-jss';
import { getUserCanOnlySeeAdvisingPartyRequests } from '../../../common/store/user/user.selectors';
import { CONFLICT_COLORS } from '../../../style/conflict.styles';

const useStyles = createUseStyles({
  tablePanel: {
    width: '100%',
    marginTop: '20px',
  },
  conflictIcon: {
    width: '25px',
    height: '20px',
  },
});

interface IProps {
  userRequested?: number;
}

export const SgwRequestList: FC<IProps> = ({ userRequested }: IProps) => {
  const C = useStyles();
  const dispatch = useDispatch();
  const requests = useSelector(getSgwRequestsListWithAddedFields);
  const userCanOnlySeeAdvisingPartyRequests = useSelector(getUserCanOnlySeeAdvisingPartyRequests);
  const loading = useSelector(selectListLoading);
  const metadata = useRequestMetadata();
  const { paging, sorting } = useSelector(selectTable);

  const onChangePaging = (paging: IPaging): void => {
    dispatch(SgwRequestActions.list.fetch({ paging }));
  };

  const onChangeSorting = (sorting: ISorting): void => {
    dispatch(SgwRequestActions.list.fetch({ sorting }));
  };

  const recordToLink = (record: ISgwRequestOverview) => appUrls.sgw.requests.detail(record.id, true);

  const conflictStates: IRequestMetadataState[] = [
    {
      state: ConflictGroupState.notApplicable,
      name: translate('sgw.requests.detail.conflicts.conflictState.notApplicable'),
      displayName: translate('sgw.requests.detail.conflicts.conflictState.notApplicable'),
      color: CONFLICT_COLORS['notApplicable'],
    },
    {
      state: ConflictGroupState.resolved,
      name: translate('sgw.requests.detail.conflicts.conflictState.resolved'),
      displayName: translate('sgw.requests.detail.conflicts.conflictState.resolved'),
      color: CONFLICT_COLORS['resolved'],
    },
    {
      state: ConflictGroupState.feedbackGiven,
      name: translate('sgw.requests.detail.conflicts.conflictState.feedbackGiven'),
      displayName: translate('sgw.requests.detail.conflicts.conflictState.feedbackGiven'),
      color: CONFLICT_COLORS['feedbackGiven'],
    },
    {
      state: ConflictGroupState.awaitingFeedback,
      name: translate('sgw.requests.detail.conflicts.conflictState.awaitingFeedback'),
      displayName: translate('sgw.requests.detail.conflicts.conflictState.awaitingFeedback'),
      color: CONFLICT_COLORS['awaitingFeedback'],
    },
  ];

  return (
    <GenericTablePanel<ISgwRequestOverview>
      dataTestId="RequestListTable"
      className={C.tablePanel}
      columnKey="id"
      columns={[
        {
          label: <UrgentIcon.Header />,
          name: 'urgentRequest',
          renderer: (urgentRequest) => (urgentRequest ? <UrgentIcon /> : null),
        },
        {
          label: <FeedbackIcon.Header />,
          name: 'messageState',
          renderer: (messageState) => <FeedbackIcon messageState={messageState} />,
          visible: !userCanOnlySeeAdvisingPartyRequests,
        },
        { label: translate('sgw.requests.dashboard.table.id'), name: 'referenceId', sortable: true },
        {
          label: translate('sgw.requests.dashboard.table.submitted'),
          name: 'submittedDate',
          sortable: true,
          renderer: (submittedDate: string) => <FormattedDate date={submittedDate} />,
        },
        {
          label: translate('sgw.requests.dashboard.table.startDate'),
          name: 'dateFrom',
          sortable: true,
          renderer: (dateFrom: string) => <FormattedDate date={dateFrom} />,
        },
        {
          label: translate('sgw.requests.dashboard.table.type'),
          name: 'workType',
          sortable: true,
          renderer: (workType: string) => (
            <Visible visible={!!workType}>{translateIgnoreTS(`sgw.requests.workTypes.${workType}`)}</Visible>
          ),
        },
        {
          label: translate('sgw.requests.dashboard.table.mainLocation'),
          name: 'fullAddress',
          sortable: false,
        },
        {
          label: translate('sgw.requests.dashboard.table.cluster'),
          name: 'cluster',
          sortable: true,
          renderer: (c: string) => {
            const foundCluster = metadata?.clusters?.find(({ cluster }) => cluster === c);
            return foundCluster?.displayName;
          },
        },
        {
          label: translate('sgw.requests.dashboard.table.userResponsible'),
          name: 'userResponsible',
          sortable: true,
          renderer: (userResponsible: IUser | undefined) => userResponsible?.fullName,
        },
        {
          label: translate('sgw.requests.dashboard.table.status'),
          name: 'state',
          sortable: true,
          renderer: (state: string) => <StatusIcon statusState={state} metadataStates={metadata?.sgwStates} />,
        },
        {
          label: translate('sgw.requests.dashboard.table.conflictStatus'),
          name: 'conflictState',
          sortable: true,
          renderer: (state: string) => <StatusIcon statusState={state} metadataStates={conflictStates} />,
          visible: userCanOnlySeeAdvisingPartyRequests,
        },
        {
          label: <Icon.FeedbackGiven className={C.conflictIcon} />,
          name: 'countConflictsWithFeedback',
          renderer: (count: number) => (count ? <Counter count={count} variant="BLUE" /> : null),
          visible: !userCanOnlySeeAdvisingPartyRequests,
        },

        {
          label: <Icon.Conflicts className={C.conflictIcon} />,
          name: 'countOpenConflicts',
          renderer: (count: number) => (count ? <Counter count={count} variant="RED" /> : null),
          visible: !userCanOnlySeeAdvisingPartyRequests,
        },
        {
          label: (
            <Tooltip title={translate('sgw.requests.dashboard.table.conflictFlag')}>
              <span>
                <Icon.ConflictFlag className={C.conflictIcon} />
              </span>
            </Tooltip>
          ),
          name: 'hasSgwConflictGroupFlagged',
          renderer: (hasSgwConflictGroupFlagged: boolean) => (
            <Visible visible={hasSgwConflictGroupFlagged}>
              <Icon.ConflictFlag className={C.conflictIcon} />
            </Visible>
          ),
          visible: !userCanOnlySeeAdvisingPartyRequests,
        },
      ]}
      labelUnavailable={translate('sgw.requests.dashboard.table.unavailable')}
      onChangeSorting={onChangeSorting}
      onChangePaging={onChangePaging}
      paging={paging}
      sorting={sorting}
      records={requests}
      loading={loading}
      recordToLink={recordToLink}
      filterNode={<SgwRequestListFilters userRequested={userRequested} />}
    />
  );
};
