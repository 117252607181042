import AddIcon from '@material-ui/icons/Add';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGeneralStyles } from '../../../../../../style/generalStyles';
import { Container, IPaging, ISorting } from '../../../../../../common';
import { ISgwAdvisingPartiesAclActions, ISgwAdvisingPartiesFilter, ISgwAdvisingParty } from '../../../../../../types';
import { Auth } from '../../../../../atoms';
import { Button } from '../../../../../../common/components/buttons/asign-button-extensions';
import { translate } from '../../../../../../common/translations/translate';
import { GenericTablePanel } from '../../../../../../common/components/table/GenericTablePanel.component';
import { SgwAdvisingPartiesActions } from '../../../../../../store/actions';
import {
  getAdvisingPartiesList,
  selectListLoading,
  selectTable,
} from '../../../../../../store/selectors/sgwAdvisingParties.selectors';
import { CheckboxWithLabel } from '../../../../../../common/components/form-fields/checkboxWithLabel.component';
import { useHistory } from 'react-router';
import { appUrls } from '../../../../../../common/config/url.constants';
import { useFormStyles } from '../../../../../../style/form.styles';

export const AdvisingPartiesPage: FC = () => {
  const C = useFormStyles();
  const G = useGeneralStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { filters, paging, sorting } = useSelector(selectTable);
  const advisingParties = useSelector(getAdvisingPartiesList);
  const loading = useSelector(selectListLoading);

  useEffect(() => {
    dispatch(SgwAdvisingPartiesActions.list.fetch({}));
  }, [dispatch]);

  const onChangeSorting = (sorting: ISorting) => dispatch(SgwAdvisingPartiesActions.list.fetch({ sorting }));
  const onChangePaging = (paging: IPaging) => dispatch(SgwAdvisingPartiesActions.list.fetch({ paging }));
  const onChangeFiltering = (filters: ISgwAdvisingPartiesFilter) =>
    dispatch(SgwAdvisingPartiesActions.list.fetch({ filters }));
  const onClick = (advisingParty: ISgwAdvisingParty) =>
    history.push(appUrls.sgw.manage.advisingParties.detail(`${advisingParty.id}`));

  return (
    <Auth acl={ISgwAdvisingPartiesAclActions.viewAdvisingPartiesOverview} showUnauthorizedPage>
      <Container>
        <Auth acl={ISgwAdvisingPartiesAclActions.createAdvisingParties}>
          <Button.Green
            className={C.createButton}
            dataTestId="newAdvisingParty"
            onClick={() => history.push(appUrls.sgw.manage.advisingParties.create)}
            startIcon={<AddIcon fontSize={'small'} />}
          >
            {translate('sgw.advisingParties.newAdvisingParty')}
          </Button.Green>
          <CheckboxWithLabel
            checked={!filters.active}
            label={translate('sgw.advisingParties.list.showInactive')}
            onChange={() => onChangeFiltering({ ...filters, active: !filters.active || undefined })}
          />
        </Auth>
        <GenericTablePanel<ISgwAdvisingParty>
          dataTestId="SgwAdvisingPartiesListTable"
          labelUnavailable={translate('sgw.advisingParties.list.unavailable')}
          loading={loading}
          onChangeSorting={onChangeSorting}
          onChangePaging={onChangePaging}
          onClick={onClick}
          paging={paging}
          sorting={sorting}
          records={advisingParties}
          title={<div className={G.flexRowSpaceBetween}>{translate('sgw.advisingParties.list.title')}</div>}
          columnKey="id"
          columns={[
            {
              label: translate('sgw.advisingParties.columns.id'),
              name: 'id',
              sortable: true,
            },
            {
              label: translate('sgw.advisingParties.columns.advisingParty'),
              name: 'name',
              sortable: true,
            },
            {
              label: translate('sgw.advisingParties.columns.email'),
              name: 'email',
              sortable: true,
            },
            {
              label: translate('sgw.advisingParties.columns.description'),
              name: 'description',
              sortable: true,
            },
          ]}
        />
      </Container>
    </Auth>
  );
};
