import React, { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { DateFormat, GeoDrawingType, ISgwPhase } from '../../../types';
import { usePhaseDetailStyles } from '../../../style/phaseDetails.styles';
import { Grid, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { SgwPhasesActions } from '../../../store/actions/phases.actions';
import { translate, translateIgnoreTS } from '../../../common/translations/translate';
import { LabelValue } from '../../../common/components/layout/LabelValue.component';
import { AsignDatepicker } from '../../../common/components/form-fields/datepicker/asign-datepicker.component';
import moment from 'moment';
import { FROM_TIMES, toTimeOptions, UNTIL_TIMES } from '../../../common/utils/moment.util';
import { SingleSelectV2Component } from '../../../common/components/form-fields/single-select-v2.component';
import { convertDateFormat } from '../../../common/utils/date.util';
import { MultiSelectV2Component } from '../../../common/components/form-fields/multi-select-v2.component';
import { getNatureOfWorksOptions, getTrafficMeasuresOptions } from '../../../store/selectors';
import { useGeneralStyles } from '../../../style/generalStyles';
import { useAtom, WritableAtom } from 'jotai';
import { SubmitButton } from '../../atoms';
import classNames from 'classnames';
import { indexOfGeodrawingForType } from '../../../common/utils/phase.util';
import { Visible } from '../../../common/components/layout/Visible.component';
import { PhaseEditLocationsTable } from '../../molecules/PhaseEditLocationsTable/PhaseEditLocationsTable.component';
import { ErrorLabel } from '../../atoms/ErrorLabel/ErrorLabel.component';
import { useSpacingStyles } from '../../../style/spacing.styles';

interface IProps {
  editMode: WritableAtom<any, any>;
  phase?: ISgwPhase;
  recurringPhaseTemplate?: boolean;
  newPhase?: boolean;
}

export const PhaseDetailsForm: FC<IProps> = ({ editMode, phase, recurringPhaseTemplate, newPhase }) => {
  const C = usePhaseDetailStyles();
  const G = useGeneralStyles();
  const [, setEditMode] = useAtom(editMode);
  const { register, handleSubmit, setValue, watch } = useFormContext();
  const trafficMeasureOptions = useSelector(getTrafficMeasuresOptions);
  const dispatch = useDispatch();

  useEffect(() => {
    setValue('sgwGeoDrawings', phase?.sgwGeoDrawings || []);
    setValue('exemptedSurfaceArea', phase?.exemptedSurfaceArea || 0);
  }, [phase, setValue]);

  const onSubmit = (e: React.FormEvent) => {
    handleSubmit((newPhase) =>
      dispatch(
        SgwPhasesActions.save({
          phase: { ...phase, ...newPhase, isRecurringPhaseTemplate: recurringPhaseTemplate },
          syncRequest: !phase?.isRecurringPhaseTemplate && !phase?.parentPhaseId,
        }),
      ),
    )(e);
    setEditMode(false);
  };

  return (
    <form onSubmit={onSubmit} id={phase ? `phaseDetails-${phase?.id}` : 'newPhase'}>
      <Grid container className={C.content}>
        <Grid container item xs={6} spacing={2} className={C.leftPanel}>
          <LabelValue visible={!!phase?.id} label={translate('sgw.requests.detail.phases.generalInfo.id')}>
            {phase?.id}
          </LabelValue>
          <LabelValue label={translate('sgw.requests.detail.phases.generalInfo.phaseName')}>
            <TextField className={C.phaseName} {...register('phaseName')} />
          </LabelValue>
          <Visible visible={!recurringPhaseTemplate}>
            <LabelValue label={translate('sgw.requests.detail.phases.generalInfo.phaseStart')}>
              <AsignDatepicker
                {...register('dateFrom')}
                value={moment(watch('dateFrom'), DateFormat.reduxStoreDateString)}
                onChange={(value) =>
                  setValue(
                    'dateFrom',
                    convertDateFormat(value, DateFormat.reduxStoreDateTimeString, DateFormat.reduxStoreDateString),
                  )
                }
              />
              <SingleSelectV2Component
                fullWidth={false}
                className={C.selector}
                onChange={(e) => {
                  setValue('timeFrom', `${e?.target?.value}`);
                }}
                menuItems={toTimeOptions(FROM_TIMES)}
                value={watch('timeFrom')}
              />
            </LabelValue>
            <LabelValue label={translate('sgw.requests.detail.phases.generalInfo.phaseEnd')}>
              <AsignDatepicker
                {...register('dateUntil')}
                value={moment(watch('dateUntil'), DateFormat.reduxStoreDateString)}
                onChange={(value) =>
                  setValue(
                    'dateUntil',
                    convertDateFormat(value, DateFormat.reduxStoreDateTimeString, DateFormat.reduxStoreDateString),
                  )
                }
              />
              <SingleSelectV2Component
                fullWidth={false}
                className={C.selector}
                onChange={(e) => {
                  setValue('timeUntil', `${e?.target?.value}`);
                }}
                menuItems={toTimeOptions(UNTIL_TIMES)}
                value={watch('timeUntil')}
              />
            </LabelValue>
            <LabelValue label={translate('sgw.requests.detail.phases.generalInfo.workingHours')}>
              <SingleSelectV2Component
                fullWidth={false}
                className={C.selector}
                onChange={(e) => {
                  setValue('workingHoursFrom', `${e?.target?.value}`);
                }}
                menuItems={toTimeOptions(FROM_TIMES)}
                value={watch('workingHoursFrom')}
              />
              <SingleSelectV2Component
                fullWidth={false}
                className={C.selector}
                onChange={(e) => {
                  setValue('workingHoursUntil', `${e?.target?.value}`);
                }}
                menuItems={toTimeOptions(UNTIL_TIMES)}
                value={watch('workingHoursUntil')}
              />
            </LabelValue>
          </Visible>
          <PhaseEditLocationsTable phaseId={phase?.id} />
          <div className={C.longTextField}>
            {translate('sgw.requests.detail.phases.generalInfo.locationDescription')}
          </div>
          <TextField
            placeholder={translate('sgw.requests.detail.phases.generalInfo.locationDescriptionPlaceholder')}
            fullWidth
            multiline
            {...register('locationDescription')}
          />
        </Grid>
        <Grid container xs={6} spacing={2}>
          <LabelValue label={translate('sgw.requests.detail.phases.generalInfo.natureOfWorks')}>
            <MultiSelectV2Component
              fullWidth={false}
              values={watch('sgwNatureOfWorks') || []}
              onChange={(newValues) => setValue('sgwNatureOfWorks', newValues)}
              menuItems={useSelector(getNatureOfWorksOptions)}
              placeholder={translate('sgw.requests.detail.phases.generalInfo.natureofWorksPlaceholder')}
              className={C.multiSelect}
            />
          </LabelValue>
          <LabelValue label={translate('sgw.requests.detail.phases.generalInfo.trafficMeasures')}>
            {phase?.sgwGeoDrawings
              .filter(({ geoDrawingType }) => geoDrawingType === GeoDrawingType.constructionzone)
              .map((geoDrawing, index) => (
                <div className={classNames(G.flexRowSpaceBetween, G.flexRowCenterVertical, G.paddingY10)}>
                  <b>
                    {geoDrawing.customName ||
                      `${translateIgnoreTS(
                        `sgw.requests.detail.map.geoDrawingType.${geoDrawing.geoDrawingType}`,
                      )} ${indexOfGeodrawingForType(phase?.sgwGeoDrawings, geoDrawing)}`}
                  </b>
                  <MultiSelectV2Component
                    fullWidth={false}
                    values={watch(`sgwGeoDrawings.${index}.sgwTrafficMeasures` as const) || []}
                    //@ts-ignore
                    onChange={(newValues) => setValue(`sgwGeoDrawings.${index}.sgwTrafficMeasures`, newValues)}
                    menuItems={trafficMeasureOptions}
                    placeholder={translate('sgw.requests.detail.phases.generalInfo.trafficMeasuresPlaceholder')}
                    className={C.multiSelect}
                  />
                </div>
              ))}
          </LabelValue>
          <div className={G.fullWidth}>
            <div className={C.longTextField}>
              {translate('sgw.requests.detail.phases.generalInfo.trafficMeasureClarification')}
            </div>
            <TextField
              placeholder={translate('sgw.requests.detail.phases.generalInfo.trafficMeasureClarificationPlaceholder')}
              fullWidth
              multiline
              {...register('trafficMeasureClarification')}
            />
          </div>
        </Grid>
      </Grid>
      <div className={G.flexColumnEnd}>
        <ErrorLabel showError={!!newPhase} message={translate('sgw.requests.detail.phases.phaseReminder')} />
        <SubmitButton
          formId={phase ? `phaseDetails-${phase?.id}` : 'newPhase'}
          className={useSpacingStyles().marginTop.XXS}
        >
          {translate('sgw.requests.detail.phases.save')}
        </SubmitButton>
      </div>
    </form>
  );
};
