import React, { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid, TextField } from '@material-ui/core';
import { translate } from '../../../common/translations/translate';
import { Autocomplete } from '../../../common/components/form-fields/autocomplete.component';
import { getAllRequestCountries } from '../../../store/selectors';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { IAddress } from '../../../common';
import { grey } from '@material-ui/core/colors';
import { useGeneralStyles } from '../../../style/generalStyles';

const useStyles = createUseStyles({
  countryLabel: {
    color: `${grey[600]}!important`,
  },
});

export const AddressFields: FC = () => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<IAddress<string>>();
  const countries = useSelector(getAllRequestCountries);
  const currentCountry = watch('country');
  useEffect(() => {
    if (!currentCountry) {
      setValue('country', 'BE');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangCountry = (value?: string) => value && setValue('country', value);

  return (
    <Grid container spacing={2} className={useGeneralStyles().gridContainerPadding}>
      <Grid item xs={8}>
        <TextField
          {...register('street', { required: true })}
          label={translate('sgw.partners.columns.street')}
          required
          fullWidth
          error={!!errors?.street?.message}
          helperText={errors?.street?.message}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          {...register('streetNumber', { required: true })}
          label={translate('sgw.partners.columns.streetNumber')}
          required
          fullWidth
          error={!!errors?.streetNumber?.message}
          helperText={errors?.streetNumber?.message}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField {...register('bus')} label={translate('sgw.partners.columns.bus')} fullWidth />
      </Grid>
      <Grid item xs={2}>
        <TextField
          {...register('zipCode', { required: true })}
          label={translate('sgw.partners.columns.zipCode')}
          required
          fullWidth
          error={!!errors?.zipCode?.message}
          helperText={errors?.zipCode?.message}
        />
      </Grid>
      <Grid item xs={5}>
        <TextField
          {...register('city', { required: true })}
          label={translate('sgw.partners.columns.city')}
          required
          fullWidth
          error={!!errors?.city?.message}
          helperText={errors?.city?.message}
        />
      </Grid>
      <Grid item xs={5}>
        <Autocomplete<string>
          value={currentCountry}
          title={translate('sgw.partners.columns.country')}
          placeholder={translate('sgw.partners.columns.country')}
          menuItems={countries}
          onChange={onChangCountry}
          isClearable={false}
          labelClassName={useStyles().countryLabel}
          required
        />
      </Grid>
    </Grid>
  );
};
