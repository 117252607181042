import axios, { CancelToken } from 'axios';
import { CONFIG, IApiPromise, IPagedApiParams } from '../..';
import {
  CarFreeZonesFilterType,
  ICarFreeZone,
  ICarFreeZonesFilter,
  IExemption,
  IGate,
  IMoovTemplate,
} from '../../../types';

export const CarFreeZonesApi = {
  fetch: (id: string): IApiPromise<ICarFreeZone> => axios.get(`${CONFIG.backOfficeApi}/v1/carfreezones/${id}`),
  fetchAll: (): IApiPromise<ICarFreeZone[]> =>
    axios.get(`${CONFIG.backOfficeApi}/v1/carfreezones?page_size=1000&gis_sync_layer=17`),
  fetchGates: (carFreeZoneId: string, params: IPagedApiParams): IApiPromise<IGate[]> =>
    axios.get(`${CONFIG.backOfficeApi}/v1/carfreezones/${carFreeZoneId}/gates`, { params }),
  fetchExemptions: (params: IPagedApiParams, cancelToken: CancelToken): IApiPromise<IExemption[]> =>
    axios.get(`${CONFIG.backOfficeApi}/v1/exemptions`, { params, cancelToken }),
  fetchList: ({ type, ...params }: ICarFreeZonesFilter & IPagedApiParams): IApiPromise<ICarFreeZone[]> =>
    axios.get(`${CONFIG.backOfficeApi}/v1/carfreezones`, {
      params: {
        ...params,
        // if all CFZ types are selected, the filter is redundant and can be unset
        // this fix is here because filtering on all CFZ types gives a 500 error
        type: type?.length === Object.keys(CarFreeZonesFilterType).length ? undefined : type,
        gis_sync_layer: 17,
        page_size: 1000,
      },
    }),
  fetchTemplates: (id: string): IApiPromise<IMoovTemplate[]> =>
    axios.get(`${CONFIG.backOfficeApi}/v1/carfreezones/${id}/templates`),
  linkTemplates: (id: string, templateIds: string[]): IApiPromise<IMoovTemplate[]> =>
    axios.put(`${CONFIG.backOfficeApi}/v1/carfreezones/${id}/templates`, { templateIds }),
  syncGates: (): IApiPromise<number> => axios.get(`${CONFIG.backOfficeApi}/v1/carfreezones/sync_gates`),
  unlinkTemplates: (id: string, templateIds: string[]): IApiPromise<IMoovTemplate[]> =>
    axios.delete(`${CONFIG.backOfficeApi}/v1/carfreezones/${id}/templates`, { params: { templateIds } }),
};
