import * as React from 'react';
import { ILocationAddress } from '../..';

interface IProps {
  address: ILocationAddress;
  inline?: boolean;
}

export const FormattedAddress = ({ address, inline }: IProps) => {
  return (
    <div>
      {`${address.street} ${address.streetNumberFrom} - ${address.streetNumberTo}`}
      {inline ? ', ' : <br />}
      {`${address.zipCode} ${address.city}`}
    </div>
  );
};
