import { Grid } from '@material-ui/core';
import React, { FC } from 'react';
import { Visible } from '../../../common/components/layout/Visible.component';
import { translate } from '../../../common/translations/translate';
import { useStyles } from './RetributionTable.styles';

interface IProps {
  hasCorrections: boolean;
}

export const RetributionTableHeader: FC<IProps> = ({ hasCorrections }) => {
  const C = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item container xs={hasCorrections ? 10 : 11} justify="flex-end">
        <Grid item xs={2} className={C.header}>
          {translate('sgw.requests.detail.retributionTable.columns.phaseName')}
        </Grid>
        <Grid item xs={1} className={C.header}>
          {translate('sgw.requests.detail.retributionTable.columns.phaseDaysInQuarter')}
        </Grid>
        <Grid item xs={4} className={C.header} direction="column">
          {translate('sgw.requests.detail.retributionTable.columns.surface')}
          <Grid item container>
            <Grid xs>{translate('sgw.requests.detail.retributionTable.columns.total')}</Grid>
            <Grid xs>{translate('sgw.requests.detail.retributionTable.columns.small')}</Grid>
            <Grid xs>{translate('sgw.requests.detail.retributionTable.columns.medium')}</Grid>
            <Grid xs>{translate('sgw.requests.detail.retributionTable.columns.large')}</Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} className={C.header}>
          {translate('sgw.requests.detail.retributionTable.columns.tarifCategory')}
        </Grid>
        <Grid item xs={3} className={C.header} direction="column">
          <span>{translate('sgw.requests.detail.retributionTable.columns.tarif')}</span>
          <Grid item container>
            <Grid xs>{translate('sgw.requests.detail.retributionTable.columns.small')}</Grid>
            <Grid xs>{translate('sgw.requests.detail.retributionTable.columns.medium')}</Grid>
            <Grid xs>{translate('sgw.requests.detail.retributionTable.columns.large')}</Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={1}>
        <Grid item className={C.header} xs={12}>
          {translate('sgw.requests.detail.retributionTable.columns.totals')}
        </Grid>
      </Grid>
      <Visible visible={hasCorrections}>
        <Grid item container xs={1}>
          <Grid item className={C.header} xs={12}>
            {translate('sgw.requests.detail.retributionTable.columns.correction')}
          </Grid>
        </Grid>
      </Visible>
    </Grid>
  );
};
