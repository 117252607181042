import { IWorkOrderItem } from '../../../types';
import React, { FC } from 'react';
import { Visible } from '../../../common/components/layout/Visible.component';

interface IProps {
  woi: IWorkOrderItem;
}
export const WorkOrderItemAddress: FC<IProps> = ({ woi }) => (
  <Visible
    visible={!!woi?.parkingBanIntake?.street}
    defaultComponent={
      <>
        {woi.streetName} {woi.streetNumber}
        <br />
        {woi.postalCode} {woi.city}
      </>
    }
  >
    {woi?.parkingBanIntake?.street} {woi?.parkingBanIntake?.streetNumberFrom} - {woi?.parkingBanIntake?.streetNumberTo}
    <br />
    {woi?.parkingBanIntake?.zipCode} {woi?.parkingBanIntake?.city}
  </Visible>
);
