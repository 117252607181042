import { Grid, TextField } from '@material-ui/core';
import { Clear, Edit, Save } from '@material-ui/icons';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Panel, PanelAction } from '../../../common';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { Visible } from '../../../common/components/layout/Visible.component';
import { appUrls } from '../../../common/config/url.constants';
import { UserActions } from '../../../common/store/user/user.actions';
import { getListMenuItems } from '../../../common/store/user/user.selectors';
import { translate } from '../../../common/translations/translate';
import { USER_ROLE_ACL_UTILITY_COMPANY } from '../../../common/types/user';
import { useUtilityCompanyForm } from '../../../hooks/forms/useUtilityComapnyForm.hook';
import { UtilityCompanyActions } from '../../../store/actions';
import { useFormStyles } from '../../../style/form.styles';
import { useGeneralStyles } from '../../../style/generalStyles';
import { IRouterWithId } from '../../../types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TNullableLabelValue } from '../../../types/select.types';
import { useUtilityCompanyUsers } from '../../../hooks/useUtilityCompanyUsers.hook';

const useStyles = createUseStyles({
  user: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  info: {
    marginBottom: '1rem',
  },
});

interface IProps {
  title?: string;
}

export const UtilityCompanyForm: FC<IProps> = ({ title }) => {
  const C = useStyles();
  const F = useFormStyles();
  const G = useGeneralStyles();
  const dispatch = useDispatch();
  const { id } = useParams<IRouterWithId>();
  const history = useHistory();
  const { formMethods, submitUtilityCompany } = useUtilityCompanyForm();
  const {
    register,
    setValue,
    formState: { errors },
    clearErrors,
  } = formMethods;
  const [inputValue, setInputValue] = useState<TNullableLabelValue>();
  const [inputString, setInputString] = useState<string>('');
  const loadedUsers = useUtilityCompanyUsers(inputString);

  useEffect(() => {
    register('users', { required: true });
    dispatch(
      UserActions.list.fetch({
        filters: { roles: [USER_ROLE_ACL_UTILITY_COMPANY] },
        paging: { page: 0, pageSize: 100, totalPages: 0, totalRecords: 0 },
      }),
    );
  }, [register, dispatch]);

  useEffect(() => {
    id && dispatch(UtilityCompanyActions.fetch(id));
  }, [dispatch, id]);

  const companyUsers = formMethods.watch('users') || [];
  const userIds = companyUsers.map((user) => user.id || 0);

  const onChange = (e: ChangeEvent<{}>, labelValue: TNullableLabelValue) => {
    const user = loadedUsers.find(({ id }) => `${id}` === `${labelValue?.value}`);
    if (!user) {
      return;
    }
    setValue('users', [...companyUsers, user]);
    clearErrors('users');
    setInputValue(undefined);
  };

  const onInputChange = (e: ChangeEvent<{}>, value: string) => setInputString(value);

  const onRemoveSelectedUser = (userId: number) => () =>
    setValue(
      'users',
      companyUsers.filter(({ id }) => id !== userId),
    );

  const goToDetail = () => history.push(appUrls.utilityCompanies.detail(id));

  return (
    <FormProvider {...formMethods}>
      <form id={'createUtilityCompany'} onSubmit={submitUtilityCompany} data-testid="utilityCompanyForm" noValidate>
        <Panel title={title}>
          <Visible visible={!!id}>
            <PanelAction icon={<Edit />} onClick={goToDetail} data-testid="editButton" />
          </Visible>
          <Grid container spacing={3} className={F.formGrid}>
            <Grid item xs={12}>
              <TextField
                {...register('name', { required: true })}
                label={translate('utilityCompanies.table.name')}
                className={G.fullWidth}
                required
                data-testid="nameField"
                error={!!errors?.name}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={C.info}>{translate('utilityCompanies.create.usersInfo')}</div>
              <div>
                {companyUsers.map((user) => (
                  <div className={C.user} key={user.id} onClick={onRemoveSelectedUser(user.id || 0)}>
                    {user.fullName}
                    <Clear fontSize="small" />
                  </div>
                ))}
              </div>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={translate('utilityCompanies.create.selectUser')}
                    required
                    error={!!errors.users}
                  />
                )}
                placeholder={translate('utilityCompanies.create.selectUser')}
                options={useSelector(getListMenuItems(userIds))}
                getOptionLabel={(option) => option.label}
                value={inputValue}
                onChange={onChange}
                getOptionSelected={(option, value) => option.value === value.value}
                data-testid="selectUsers"
                onInputChange={onInputChange}
              />
            </Grid>
            <Grid item xs={12} className={F.bottomButtons}>
              <Button.Green type="submit" form="createUtilityCompany" data-testid="createButton" endIcon={<Save />}>
                {translate('utilityCompanies.create.saveUtilityCompany')}
              </Button.Green>
            </Grid>
          </Grid>
        </Panel>
      </form>
    </FormProvider>
  );
};
