import * as React from 'react';
import * as L from 'leaflet';
import { FunctionComponent, useEffect, useMemo, useRef } from 'react';
import { Marker } from 'react-leaflet';
import { useDispatch } from 'react-redux';
import { ReorderingMarkerIcon } from '../../common';
import { setReorderingMarkerPopup } from '../store/reordering.actions';
import { IReorderingWorkOrderItem } from '../types/reordering-work-order-item';
import { ReorderingMapPopup } from './reordering-map-popup';
import { closeMarkerPopup } from '../../planning/store/planning.actions';

interface IProps {
  workOrderItem: IReorderingWorkOrderItem;
  hovered?: boolean | null;
  markerPopupId?: number;
}

export const ReorderingMapMarker: FunctionComponent<IProps> = ({ workOrderItem, hovered, markerPopupId }) => {
  const markerRef = useRef<L.Marker>(null);
  const showPopup = useMemo(() => markerPopupId === workOrderItem?.id, [markerPopupId, workOrderItem?.id]);
  const dispatch = useDispatch();

  markerRef?.current?.on('popupclose', () => {
    dispatch(closeMarkerPopup());
  });

  useEffect(() => {
    markerRef?.current?.on('click', (event) => {
      dispatch(setReorderingMarkerPopup(workOrderItem));
    });
  }, [dispatch, workOrderItem]);

  useEffect(() => {
    showPopup && markerRef?.current?.openPopup();
  }, [showPopup, markerPopupId]);

  const getMarkerIcon = (item: IReorderingWorkOrderItem) => {
    const text = item.sequence.toString();
    if (item.checked) {
      if (hovered && !showPopup) {
        return ReorderingMarkerIcon(text, '#4A63F3', '#F1F3FF', '#4A63F3', 1);
      }
      return ReorderingMarkerIcon(text, '#FFF', '#4A63F3', '#FFF', 2);
    }

    if (hovered) {
      return ReorderingMarkerIcon(text, '#4A63F3', '#F1F3FF', '#4A63F3', 1);
    }
    return ReorderingMarkerIcon(text, '#B2B2B2', '#FFF', '#B2B2B2', 1);
  };

  return workOrderItem.location.lat && workOrderItem.location.lng ? (
    <Marker
      ref={markerRef}
      icon={getMarkerIcon(workOrderItem)}
      position={{ lat: workOrderItem.location.lat!, lng: workOrderItem.location.lng! }}
      zIndexOffset={showPopup || hovered ? 500 : 0}
    >
      {showPopup && <ReorderingMapPopup workOrderItem={workOrderItem} />}
    </Marker>
  ) : null;
};
