import { createReducer } from '@reduxjs/toolkit';
import { payloadReducer } from '../../../common';
import { IRequestExtension } from '../../../types';
import { RequestActions } from '../../actions';

type TState = IRequestExtension[] | null;

const initialState: TState = null;

export default createReducer<TState>(initialState, {
  [RequestActions.setExtensions.type]: payloadReducer<TState>(initialState),
  [RequestActions.setExtension.type]: (state: TState, { payload }: ReturnType<typeof RequestActions.setExtension>) =>
    state ? [...state.map((extension) => (extension.id === payload.id ? payload : extension))] : state,
});
