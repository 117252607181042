import { ISgwGeoDrawing } from '../types';
import { useForm } from 'react-hook-form';
import { useAreaOrDistance } from './useAreaOrDistance.hook';
import { useEffect } from 'react';

export const useGeoDrawingForm = (
  onSave: (values: ISgwGeoDrawing) => void,
  idToEdit?: number,
  initialValues?: Partial<ISgwGeoDrawing>,
) => {
  const formMethods = useForm<ISgwGeoDrawing>({});

  const { watch, setValue, handleSubmit, reset } = formMethods;
  const { areaOrDistance } = useAreaOrDistance(watch('geometry'));

  useEffect(() => {
    reset(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, idToEdit]);

  useEffect(() => {
    setValue('surfaceArea', areaOrDistance);
  }, [areaOrDistance, formMethods, setValue]);

  const _onSave = (values: any) => {
    onSave(values);
    formMethods.reset();
  };
  return {
    ...formMethods,
    submitGeometry: handleSubmit(_onSave),
  };
};
