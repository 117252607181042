import { makeStyles } from '@material-ui/core/styles';
import React, { FunctionComponent, useCallback } from 'react';
import { Panel } from '../../../common';
import I18n from 'i18n-js';
import { FileBox } from '../../../common/components/attachment';
import { useDispatch, useSelector } from 'react-redux';
import { BulkDownloadTypes, IFile, IRequestDetailComponent } from '../../../types';
import { getRequestAttachments, getStorageFiles, selectRequestDetailById } from '../../../store/selectors';
import { RequestActions, StorageActions } from '../../../store/actions';

const useStyles = makeStyles({
  panelTitle: {
    '& .MuiTypography-body1': {
      marginBottom: '30px',
    },
  },
});

export const AttachmentsPanel: FunctionComponent<IRequestDetailComponent> = ({ requestId }) => {
  const C = useStyles();
  const dispatch = useDispatch();
  const request = useSelector(selectRequestDetailById)[requestId];
  const isReadOnly = !request.acl.can_edit_attachments;

  const uploadFile = useCallback(
    (file: IFile) => {
      dispatch(RequestActions.uploadFile({ id: requestId, file }));
    },
    [dispatch, requestId],
  );

  const removeFile = useCallback(
    (fileName: string) => {
      dispatch(RequestActions.removeFile({ id: requestId, fileName }));
    },
    [dispatch, requestId],
  );

  const downloadZip = useCallback(
    (fileIds: string[]) => {
      dispatch(
        StorageActions.bulkDownload({
          type: BulkDownloadTypes.request,
          id: requestId,
          fileIds,
        }),
      );
    },
    [dispatch, requestId],
  );

  return (
    <Panel title={I18n.translate('Requests.Detail.Attachments.PanelTitle')} className={C.panelTitle}>
      <FileBox
        onDownloadZip={downloadZip}
        onFileSelect={isReadOnly ? undefined : uploadFile}
        onRemoveFile={isReadOnly ? undefined : removeFile}
        placeholder={I18n.translate('Requests.Detail.Attachments.Placeholder')}
        storedFiles={useSelector(getRequestAttachments(requestId))}
        uploadedFiles={useSelector(getStorageFiles)}
      />
    </Panel>
  );
};
