import { createReducer } from '@reduxjs/toolkit';
import { IById } from '../../../../common/utils/normalized.util';
import { IFeature } from '../../../../types';
import { RequestActions } from '../../../actions';

type TEntity = IFeature[];
type TState = IById<TEntity>;

export default createReducer<TState>(
  {},
  {
    [RequestActions.setConflicts.type]: (
      state: TState,
      { payload: { id, conflicts } }: ReturnType<typeof RequestActions.setConflicts>,
    ): TState => (id ? { ...state, [id]: conflicts } : state),
  },
);
