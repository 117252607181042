import { Grid } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initialPlanningFiltersForInstaller } from '..';
import { Visible } from '../../common/components/layout/Visible.component';
import { Auth } from '../../components';
import { useAuthorization } from '../../hooks';
import { fetchRequestReasonFilters } from '../../store/actions';
import { selectRequestReasonFilters, selectUser } from '../../store/selectors';
import {
  IPlanningAclActions,
  ITeam,
  IWorkOrder,
  IWorkOrderItem,
  IWorkOrderItemAclActions,
  IWorkOrderItemsFilter,
} from '../../types';
import { TeamAssignDialog } from '../components/dialog/team-assign-dialog.component';
import { UnassignItemDialog } from '../components/dialog/unassign-item-dialog.component';
import { PlanningFilter } from '../components/PlanningFilter.component';
import { PlanningMap } from '../components/planning-map.component';
import { PlanningTours } from '../components/planning-tours.component';
import {
  closeMarkerPopup,
  deleteWorkOrderItem,
  fetchMarkerPopup,
  fetchTeams,
  PlanningActions,
  searchStreet,
  updateWorkOrder,
} from '../store/planning.actions';
import {
  getPlanningWorkOrders,
  selectMapLocation,
  selectPlanningWorkOrderItems,
  selectPlanningWorkOrderItemsLoading,
  selectPlanningWorkOrdersLoading,
  selectTeams,
  selectWorkOrderItemsFilter,
} from '../store/planning.selectors';
import { HEADER_HEIGHT } from '../../components/organisms/MainMenu/MainMenu.styles';

export const PlanningPage: FC = () => {
  const dispatch = useDispatch();
  const [deleteSignDialog, setDeleteSignDialog] = useState(false);
  const [teamAssignDialog, setTeamAssignDialog] = useState(false);
  const [workOrderItemsToAssign, setWorkOrderItemsToAssign] = useState<IWorkOrderItem[]>([]);
  const workOrderItemsFilter = useSelector(selectWorkOrderItemsFilter);
  const user = useSelector(selectUser);
  const [orderForDelete, setOrderForDelete] = useState<IWorkOrderItem>();
  const workOrders = useSelector(getPlanningWorkOrders);
  const workOrderItems = useSelector(selectPlanningWorkOrderItems);
  const requestReasonFilters = useSelector(selectRequestReasonFilters);
  const mapLocation = useSelector(selectMapLocation);
  const workOrderItemsLoading = useSelector(selectPlanningWorkOrderItemsLoading);
  const workOrdersLoading = useSelector(selectPlanningWorkOrdersLoading);
  const teams = useSelector(selectTeams);

  const { isAuthorized: hasViewPlanningAccess } = useAuthorization(IPlanningAclActions.viewPlanning);
  const { isAuthorized: hasEditPlanningAccess } = useAuthorization(IPlanningAclActions.editPlanning);

  const getWorkOrdersAndItems = useCallback(
    (filter: IWorkOrderItemsFilter) => {
      dispatch(PlanningActions.fetchWorkOrderItems(filter));
      dispatch(PlanningActions.fetchWorkOrders({ filter, setLoading: true }));
    },
    [dispatch],
  );

  useEffect(() => {
    if (hasViewPlanningAccess) {
      dispatch(fetchRequestReasonFilters());
      getWorkOrdersAndItems(workOrderItemsFilter);
      dispatch(fetchTeams());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasViewPlanningAccess]);

  useEffect(() => {
    if (!hasEditPlanningAccess && user) {
      getWorkOrdersAndItems(initialPlanningFiltersForInstaller);
    }
  }, [getWorkOrdersAndItems, hasEditPlanningAccess, user]);

  const onWorkOrderItemsAssign = useCallback(
    (workOrderItemsToAssign: IWorkOrderItem[]): void => {
      if (!hasEditPlanningAccess) {
        // AS-4345 Only planner can assign workOrderItems
        return;
      }
      dispatch(closeMarkerPopup());
      if (workOrderItemsToAssign.length > 0) {
        setWorkOrderItemsToAssign(workOrderItemsToAssign);
        setTeamAssignDialog(true);
      }
    },
    [dispatch, hasEditPlanningAccess],
  );

  const handleClose = useCallback(() => {
    setDeleteSignDialog(false);
    setTeamAssignDialog(false);
    setWorkOrderItemsToAssign([]);
  }, []);

  const deleteSignConfirm = useCallback(
    (woi: IWorkOrderItem): void => {
      setOrderForDelete(woi);
      setDeleteSignDialog(!deleteSignDialog);
    },
    [deleteSignDialog],
  );

  const _deleteWorkOrderItem = useCallback(
    (woi: IWorkOrderItem): void => {
      dispatch(deleteWorkOrderItem(woi));
      handleClose();
    },
    [dispatch, handleClose],
  );

  const addItemToWorkOrder = useCallback((woi: IWorkOrderItem, order: IWorkOrder) => {
    const existenceItem = order.workOrderItems!.filter((item) => {
      return item.id === woi.id;
    });
    if (existenceItem.length === 0) {
      order.workOrderItems ? order.workOrderItems.push(woi) : (order.workOrderItems = [woi]);
    }
  }, []);

  const getWorkOrderByTeam = useCallback((team: ITeam, orders: IWorkOrder[] | [], date: string): IWorkOrder => {
    const order = orders.find((item) => item.team.id === team.id);
    return order || { acl: { canReorder: false }, date, team, workOrderItems: [] };
  }, []);

  const assignWorkOrderItems = useCallback(
    (team: ITeam, items: IWorkOrderItem[]) => {
      if (!items) {
        return;
      }
      const date = workOrderItemsFilter!.date || moment().format('YYYY-MM-DD');
      const order = getWorkOrderByTeam(team, workOrders, date);
      items.forEach((item) => {
        addItemToWorkOrder(item, order);
      });
      dispatch(PlanningActions.assignItemsToWorkOrder({ order, filter: workOrderItemsFilter }));
      setWorkOrderItemsToAssign([]);
      setTeamAssignDialog(false);
    },
    [addItemToWorkOrder, dispatch, getWorkOrderByTeam, workOrderItemsFilter, workOrders],
  );

  const unassignWorkOrderItems = useCallback(
    (items: IWorkOrderItem[]) => {
      const orders = workOrders;
      const ordersForUpdate: IWorkOrder[] = [];
      if (orders && items) {
        items.forEach((item: IWorkOrderItem) => {
          if (item.workOrder) {
            const workOrder = orders.find((order) => order.id === item.workOrder!.id);
            if (workOrder) {
              workOrder.workOrderItems = workOrder!.workOrderItems!.filter((orderItems) => orderItems.id !== item.id);
              if (!ordersForUpdate.find((x) => x.id === workOrder.id)) {
                ordersForUpdate.push(workOrder);
              }
            }
          }
        });
        ordersForUpdate.forEach((order) => {
          dispatch(PlanningActions.assignItemsToWorkOrder({ order, filter: workOrderItemsFilter }));
        });
      }
      setWorkOrderItemsToAssign([]);
      setTeamAssignDialog(false);
    },
    [dispatch, workOrderItemsFilter, workOrders],
  );

  const _updateWorkOrder = useCallback(
    (wo: IWorkOrder) => {
      dispatch(updateWorkOrder(wo));
    },
    [dispatch],
  );

  const onOpenPopup = useCallback(
    (workOrderItemId: number): void => {
      if (!teamAssignDialog) {
        dispatch(fetchMarkerPopup(workOrderItemId));
      }
    },
    [dispatch, teamAssignDialog],
  );

  const onTeamWoiClick = useCallback(
    (woi: IWorkOrderItem): void => {
      dispatch(closeMarkerPopup());
      onOpenPopup(woi.id);
    },
    [dispatch, onOpenPopup],
  );

  const onSearchStreet = useCallback(
    (street: string) => {
      dispatch(searchStreet(street));
    },
    [dispatch],
  );

  return (
    <Auth acl={IPlanningAclActions.viewPlanning} showUnauthorizedPage>
      <Grid
        alignContent="stretch"
        container
        direction="column"
        spacing={0}
        wrap="nowrap"
        style={{ height: 'calc(100vh - ' + HEADER_HEIGHT + 'px)', marginBottom: -20 }}
      >
        <Grid item>
          <Auth acl={IPlanningAclActions.editPlanning}>
            <PlanningFilter
              filter={workOrderItemsFilter!}
              filterChange={getWorkOrdersAndItems}
              requestReasonFilters={requestReasonFilters!}
            />
          </Auth>
        </Grid>
        <Grid item className={'planning-grid'}>
          <Grid container alignContent="stretch" style={{ height: '100%' }}>
            <Grid item sm={8} lg={9}>
              <PlanningMap
                deleteSign={deleteSignConfirm}
                mapLocation={mapLocation}
                searchStreet={onSearchStreet}
                workOrderItems={workOrderItems}
                workOrderItemsLoading={workOrderItemsLoading}
                onWorkOrderItemsAssign={onWorkOrderItemsAssign}
              />
            </Grid>
            <Auth acl={IWorkOrderItemAclActions.viewWorkOrderItem}>
              <Grid item sm={4} lg={3} className={'planning-tours-grid'}>
                <PlanningTours
                  filter={workOrderItemsFilter}
                  filterChange={getWorkOrdersAndItems}
                  teams={teams}
                  workOrderItems={workOrderItems}
                  workOrderItemsLoading={workOrderItemsLoading}
                  workOrders={workOrders}
                  workOrdersLoading={workOrdersLoading}
                  teamWoiClick={onTeamWoiClick}
                  updateWorkOrder={_updateWorkOrder}
                />
              </Grid>
            </Auth>
          </Grid>
        </Grid>
      </Grid>
      <Auth acl={IPlanningAclActions.editPlanning}>
        <Visible visible={!!deleteSignDialog && !!orderForDelete}>
          <UnassignItemDialog
            woi={orderForDelete!}
            handleDialogClose={handleClose}
            deleteSign={deleteSignConfirm}
            onConfirm={_deleteWorkOrderItem}
          />
        </Visible>
        <Visible visible={!!teamAssignDialog && !!workOrderItemsToAssign}>
          <TeamAssignDialog
            teams={teams}
            workOrderItems={workOrderItemsToAssign}
            handleDialogClose={handleClose}
            onConfirm={assignWorkOrderItems}
            onUnassign={unassignWorkOrderItems}
          />
        </Visible>
      </Auth>
    </Auth>
  );
};
