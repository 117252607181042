import { Tooltip } from '@material-ui/core';
import { Lens, TripOriginOutlined } from '@material-ui/icons';
import * as React from 'react';
import { FunctionComponent } from 'react';

type IProps = {
  checked: boolean | null;
  color: string;
  style?: any;
  tooltip: string;
};

const TourIcon: FunctionComponent<IProps> = ({ checked, color, style, tooltip }) => {
  if (checked) {
    return (
      <Tooltip title={tooltip}>
        <Lens fontSize="inherit" style={{ ...(style || {}), color: color }} />
      </Tooltip>
    );
  }
  return (
    <Tooltip title={tooltip}>
      <TripOriginOutlined strokeWidth={5} fontSize="inherit" style={{ ...(style || {}), color: color }} />
    </Tooltip>
  );
};

export default TourIcon;
