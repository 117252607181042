import { IRootState } from '../../../root.state';
import { createSelector } from '@reduxjs/toolkit';
import { IFilter } from '../../types/filter';
import { IPagedTable } from '../../types/paging';

export const createPagedApiParamsSelector = <Filter = IFilter>(
  tableSelector: (state: IRootState) => IPagedTable<Filter>,
) =>
  createSelector([tableSelector], ({ paging, sorting, filters }) => ({
    sort: sorting?.key,
    order: sorting?.direction,
    page: paging?.page,
    page_size: paging?.pageSize,
    ...filters,
  }));
