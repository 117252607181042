import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { COLORS } from '../../../common';
import { translateIgnoreTS } from '../../../common/translations/translate';
import { ConflictGroupState, IConflictGroup } from '../../../types';
import AsignButton from '../../../common/components/buttons/asign-button';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { SgwRequestActions } from '../../../store/actions';
import { useGeneralStyles } from '../../../style/generalStyles';
import { CONFLICT_COLORS } from '../../../style/conflict.styles';

const useStyles = createUseStyles<string, { buttonVariant: ConflictGroupState; selected: boolean }>({
  button: ({ buttonVariant, selected }) => ({
    border: `1px solid ${COLORS.GREY_LIGHTER}`,
    background: selected ? `${CONFLICT_COLORS[buttonVariant]}33` : 'none',
    color: CONFLICT_COLORS[buttonVariant],
    fontSize: 'smaller',
    boxShadow: 'none',
    '&:hover': {
      background: `${CONFLICT_COLORS[buttonVariant]}66`,
      boxShadow: 'none',
    },
  }),
});

interface IProps {
  buttonVariant: ConflictGroupState;
  conflictGroup: IConflictGroup;
}

export const SgwConflictStateButton: FC<IProps> = ({ buttonVariant, conflictGroup }) => {
  const selected = buttonVariant === conflictGroup.state;
  const dispatch = useDispatch();
  const C = useStyles({ buttonVariant, selected });
  const G = useGeneralStyles();

  const onChangeState = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!selected)
      dispatch(
        SgwRequestActions.conflicts.save({
          requestId: conflictGroup.sgwRequestId,
          conflictGroup: { id: conflictGroup.id, state: buttonVariant },
        }),
      );
  };

  return (
    <AsignButton className={classNames(C.button, G.marginRight)} onClick={onChangeState} type="button">
      {translateIgnoreTS(`sgw.requests.detail.conflicts.conflictState.${buttonVariant}`)}
    </AsignButton>
  );
};
