import { Grid } from '@material-ui/core';
import { translate } from '../../../common/translations/translate';
import { StatusIcon } from '../../../request/components/icons/status-icon.component';
import { Header } from '../../../common/components/layout/Header.component';
import React, { FC } from 'react';
import { useHeaderStyles } from '../RequestDetailHeader/RequestDetailHeader.component';
import { useManualPlacementQuery } from '../../../hooks';
import { IRequestStatus, IWithId, IWithName } from '../../../types';
import { InfoChip } from '../../../request/components/icons/info-chip.component';
import { Visible } from '../../../common/components/layout/Visible.component';

interface IProps {
  referenceId: string;
  state: IRequestStatus;
  utilityCompanyRequested?: IWithName & IWithId;
}

export const ManualPlacementHeader: FC<IProps> = ({ referenceId, state, utilityCompanyRequested }) => {
  const C = useHeaderStyles();
  const { request } = useManualPlacementQuery();

  return (
    <Header>
      <Grid container className={C.header}>
        <Grid item xs={12}>
          <span className={C.title}>{translate('sgw.requests.detail.title', { referenceId })}</span>
          <StatusIcon statusState={state.state} metadataStates={request?.metadata.states} />
          <Visible visible={!!utilityCompanyRequested}>
            <InfoChip text={translate('Requests.Detail.UtilityRequest')} />
          </Visible>
        </Grid>
      </Grid>
    </Header>
  );
};
