import { makeStyles } from '@material-ui/styles';

export const HEADER_HEIGHT = 59;

export const useLoginStyles = makeStyles({
  loginWrapper: {
    marginTop: '3em',
  },
  button: {
    marginBottom: '20px',
    fontSize: 16,
  },
  buttonsWrapper: {
    marginTop: 30,
    marginBottom: 30,
    width: 370,
  },
  digipolisLoginButtonWrapper: {
    textAlign: 'end',
  },
  form: {
    width: '50%',
  },
  inputFields: {
    margin: '10px',
    width: '100%',
  },
  image: {
    width: '30%',
    margin: 20,
    marginTop: 40,
  },
  centered: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});
