import React, { FC, PropsWithChildren } from 'react';
import { IAclActions } from '../../../types';
import { Visible } from '../../../common/components/layout/Visible.component';
import { UnauthorizedPage } from '../../pages';
import { useAuthorization } from '../../../hooks';

interface IProps {
  acl: IAclActions | IAclActions[];
  allAclNeeded?: boolean;
  showUnauthorizedPage?: boolean;
}

export const Auth: FC<PropsWithChildren<IProps>> = ({
  allAclNeeded = false,
  acl = [],
  children,
  showUnauthorizedPage = false,
}) => {
  const { isAuthorized } = useAuthorization(acl, allAclNeeded);
  return (
    <Visible defaultComponent={showUnauthorizedPage ? <UnauthorizedPage /> : undefined} visible={isAuthorized}>
      {children}
    </Visible>
  );
};
