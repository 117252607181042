import { Grid } from '@material-ui/core';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { Save } from '@material-ui/icons';
import React, { FC, PropsWithChildren } from 'react';
import { IWithClassName } from '../../../types';
import { translate } from '../../../common/translations/translate';
import { Visible } from '../../../common/components/layout/Visible.component';

interface IProps extends IWithClassName {
  formId: string;
  visible?: boolean;
}

export const SubmitButton: FC<PropsWithChildren<IProps>> = ({ formId, children, className, visible = true }) => {
  return (
    <Visible visible={visible}>
      <Grid container justify="flex-end" className={className}>
        <Grid item>
          <Button.Green data-testid="createButton" type="submit" form={formId} startIcon={<Save fontSize="small" />}>
            {children || translate('Save')}
          </Button.Green>
        </Grid>
      </Grid>
    </Visible>
  );
};
