import axios from 'axios';
import { CONFIG, IApiPromise } from '../../common';
import { ISgwSetting } from '../../types/sgwSetting.types';

const baseUrl = `${CONFIG.backOfficeApi}/v1/sgw/manage/settings`;

export const SgwSettingsApi = {
  fetchList: (): IApiPromise<ISgwSetting[]> => axios.get(baseUrl),
  saveList: (settings: ISgwSetting[]): IApiPromise<ISgwSetting[]> => axios.patch(baseUrl, { settings }),
};
