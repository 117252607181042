import { SgwPartnerType } from '../types';

const requestPartnerFieldByISgwPartnerType = {
  [SgwPartnerType.requester]: 'requestorId',
  [SgwPartnerType.client]: 'principalId',
  [SgwPartnerType.signalisation]: 'signallingResponsibleId',
  [SgwPartnerType.contractor]: 'mainContractorId',
  [SgwPartnerType.fee]: 'feeResponsibleId',
};

export const getRequestFieldByPartnerType = (partnerType: SgwPartnerType) =>
  requestPartnerFieldByISgwPartnerType[partnerType];
