import { combineReducers } from 'redux';
import {
  generateArrayReducer,
  generateObjectReducer,
  generatePayloadReducer,
  generatePrimitiveReducer,
} from '../../common';
import { INotification, INotificationHistoryItem } from '../types/notification';
import { initialNotificationsState } from './notification.state';
import { createPagedTableReducer } from '../../store/reducers/pagedTable.reducer';
import { NotificationActions } from '../../store/actions/notifications.actions';

export const notificationReducer = combineReducers({
  count: generatePayloadReducer([NotificationActions.setCount.type], 0),
  notification: generateObjectReducer<INotification | null>(
    NotificationActions.set.type,
    initialNotificationsState.notification,
  ),
  list: createPagedTableReducer<INotification>({
    fetchAction: NotificationActions.list.fetch.type,
    addEntitiesActions: [NotificationActions.list.set.type],
    setAllIdsAction: NotificationActions.list.set.type,
    setParamsActions: [NotificationActions.list.fetch.type, NotificationActions.list.setParams.type],
    setLoadingAction: NotificationActions.list.setLoading.type,
    initialParamsState: initialNotificationsState.list.table,
  }),
  loading: generatePayloadReducer([NotificationActions.setLoading.type], false),
  notificationHistory: generateArrayReducer<INotificationHistoryItem>(
    NotificationActions.setHistory.type,
    initialNotificationsState.notificationHistory,
  ),
  notificationsError: generatePrimitiveReducer<string | null>(NotificationActions.setError.type, null),
});
