import React, { FC } from 'react';
import { IMessage } from '../../../types';
import { FormattedDateTime } from '../../../common';
import { Visible } from '../../../common/components/layout/Visible.component';
import { SgwMessageAttachment } from './SgwMessageAttachment.component';

interface ISgwMessageProps {
  message: IMessage;
  requestId: string;
}

export const SgwMessage: FC<ISgwMessageProps> = ({
  message: { attachments, content, createdAt, sender },
  requestId,
}) => (
  <div className="messages-item">
    <strong>{sender.fullName}</strong> <FormattedDateTime dateTime={createdAt} /> <br />
    <div className="messages-content">
      {content.split('\n').map((val, index) => (
        <p key={val + index}>{val}</p>
      ))}
    </div>
    <Visible visible={!!attachments.length}>
      <div className="messages-attachments">
        {attachments.map((attachment) => (
          <SgwMessageAttachment requestId={requestId} attachment={attachment} />
        ))}
      </div>
    </Visible>
  </div>
);
