import React, { FC } from 'react';
import { categories, Category } from '../../../types';
import { SingleSelectV2Component } from '../../../common/components/form-fields/single-select-v2.component';
import { SgwRequestActions } from '../../../store/actions';
import { useDispatch } from 'react-redux';

interface IProps {
  editMode: boolean;
  category: Category;
  attachmentId: number;
  requestId: string;
}

export const RequestAttachmentCategorySelector: FC<IProps> = ({ editMode, category, attachmentId, requestId }) => {
  const dispatch = useDispatch();

  const onChange = (e: React.ChangeEvent<{ value: unknown }>) =>
    dispatch(
      SgwRequestActions.attachments.save({
        requestId,
        attachment: { category: e.target.value as Category, id: attachmentId },
      }),
    );
  return editMode ? (
    <SingleSelectV2Component onChange={onChange} menuItems={categories} value={category} />
  ) : (
    <div>{category}</div>
  );
};
