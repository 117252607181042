import { Grid } from '@material-ui/core';
import * as I18n from 'i18n-js';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Connect, Container, IPaging, WithRouter } from '../../common';
import { Header } from '../../common/components/layout/Header.component';
import { Loader } from '../../common/components/layout/loader.component';
import { appUrls } from '../../common/config/url.constants';
import { IUser, USER_ROLE_ACL } from '../../common/types/user';
import { Auth } from '../../components';
import { IRequest, ISignsAclActions } from '../../types';
import { ModuleGeneral } from '../components/module-general.component';
import { ModuleMaintenance } from '../components/module-maintenance.component';
import { SignGeneral } from '../components/sign-general.component';
import { SignLocationHistory } from '../components/sign-location-history.component';
import { SignMap } from '../components/sign-map.component';
import { SignRequest } from '../components/sign-request.component';
import { SignStateHistory } from '../components/sign-state-history.component';
import { fetchBatches, fetchPlaces, updateModule } from '../store/modules.actions';
import {
  deleteSign,
  fetchSign,
  fetchSignLocations,
  fetchSignsFilterData,
  generateRetrieveWorkOrderItem,
  setSignActiveLocation,
  updateSign,
} from '../store/signs.actions';
import { IModuleBatch } from '../types/module-batch';
import { IModulePlace } from '../types/module-place';
import { ISign } from '../types/sign';
import { ISignCondition } from '../types/sign-condition';
import { ISignData } from '../types/sign-data';
import { ISignLocation } from '../types/sign-location';
import { ISignState } from '../types/sign-state';
import { ISignStateHistory } from '../types/sign-state-history';
import { ISignType } from '../types/sign-type';
import { Visible } from '../../common/components/layout/Visible.component';

interface IProps
  extends Partial<
    RouteComponentProps<{
      hash: string;
    }>
  > {
  fetchBatches?: typeof fetchBatches;
  fetchModuleLocations?: typeof fetchPlaces;
  fetchSign?: typeof fetchSign;
  fetchSignLocations?: typeof fetchSignLocations;
  fetchSignsFilterData?: typeof fetchSignsFilterData;
  generateRetrieveWorkOrderItem?: typeof generateRetrieveWorkOrderItem;
  updateSign?: typeof updateSign;
  deleteSign?: typeof deleteSign;
  updateModule?: typeof updateModule;
  request?: IRequest;
  setSignActiveLocation?: typeof setSignActiveLocation;
  batches?: IModuleBatch[];
  moduleLocations?: IModulePlace[];
  sign?: ISign;
  signActiveLocation?: ISignLocation;
  signActiveLocationData?: ISignData[];
  signConditions?: ISignCondition[];
  signGPSLogs?: ISignData[];
  signLoading?: boolean;
  signLocations?: ISignLocation[];
  signLocationsPaging?: IPaging;
  signStateHistory?: ISignStateHistory[];
  signStates?: ISignState[];
  signTypes?: ISignType[];
  user: IUser | null;
}

@WithRouter
@Connect(
  ({ signs, requests, modules, user }) => ({
    batches: modules.batches.batches,
    moduleLocations: modules.places,
    request: requests.request,
    sign: signs.sign,
    signActiveLocation: signs.signActiveLocation,
    signActiveLocationData: signs.signActiveLocationData,
    signConditions: signs.signConditions,
    signGPSLogs: signs.signGPSLogs,
    signLoading: signs.signLoading,
    signLocations: signs.signLocations,
    signLocationsPaging: signs.signLocationsPaging,
    signStateHistory: signs.signStateHistory,
    signStates: signs.signStates,
    signTypes: signs.signTypes,
    user: user.user,
  }),
  {
    deleteSign,
    fetchBatches,
    fetchModuleLocations: fetchPlaces,
    fetchSign,
    fetchSignLocations,
    fetchSignsFilterData,
    generateRetrieveWorkOrderItem,
    setSignActiveLocation,
    updateModule,
    updateSign,
  },
)
export class SignDetailPage extends React.Component<IProps> {
  public componentDidMount(): void {
    const { match, user, fetchSign, fetchSignsFilterData, fetchBatches, fetchModuleLocations } = this.props;
    const isAcl = user?.roles.some(({ id }) => id === USER_ROLE_ACL);
    const canEditSign = !!user?.acl[ISignsAclActions.editSign];

    if (!isAcl || (isAcl && canEditSign)) {
      const { hash } = match!.params;

      fetchSign?.(hash);
      fetchSignsFilterData?.();
      fetchBatches?.();
      fetchModuleLocations?.();
    }
  }

  public render(): React.ReactNode {
    if (!this.props.sign) {
      return null;
    }

    return (
      <Auth acl={ISignsAclActions.editSign} showUnauthorizedPage>
        <Loader loading={this.props.signLoading}>
          <div style={{ marginTop: -20 }}>
            <Header backButtonUrl={appUrls.signs.base}>
              {I18n.t('DetailsSign')} {this.props.sign.hash}
            </Header>
          </div>
          <Container>
            <SignMap sign={this.props.sign} signGPSLogs={this.props.signGPSLogs!} />
            <Grid container spacing={4} style={{ marginTop: 10 }}>
              <Grid item xs={6}>
                <SignGeneral
                  generateRetrieveWorkOrderItem={this.onGenerateRetrieveWorkOrderItem}
                  updateSign={this.props.updateSign!}
                  sign={this.props.sign}
                  signConditions={this.props.signConditions!}
                  signStates={this.props.signStates!}
                  signTypes={this.props.signTypes!}
                  deleteSign={this.deleteSign}
                  user={this.props.user}
                />
                <Visible visible={!!this.props.sign.sigfoxDevice}>
                  <ModuleGeneral module={this.props.sign.sigfoxDevice!} readonly user={this.props.user} />
                </Visible>
                <SignRequest sign={this.props.sign} request={this.props.request} />
                <SignLocationHistory
                  signActiveLocation={this.props.signActiveLocation!}
                  signActiveLocationChange={this.onSignActiveLocationChange}
                  signActiveLocationData={this.props.signActiveLocationData!}
                  signLocations={this.props.signLocations!}
                  signLocationsPaging={this.props.signLocationsPaging!}
                  signLocationsPagingChange={this.onSignLocationsPagingChange}
                />
              </Grid>
              <Grid item xs={6}>
                <SignStateHistory signStateHistory={this.props.signStateHistory!} />
                <Visible visible={!!this.props.sign.sigfoxDevice}>
                  <ModuleMaintenance
                    module={this.props.sign.sigfoxDevice!}
                    batches={this.props.batches!}
                    places={this.props.moduleLocations!}
                    updateModule={this.props.updateModule!}
                  />
                </Visible>
              </Grid>
            </Grid>
          </Container>
        </Loader>
      </Auth>
    );
  }

  private onGenerateRetrieveWorkOrderItem = (): void => {
    this.props.generateRetrieveWorkOrderItem!(this.props.sign!);
  };

  private onSignLocationsPagingChange = (paging: IPaging) => {
    this.props.fetchSignLocations!({ hash: this.props.sign!.hash, paging });
  };

  private onSignActiveLocationChange = (location: ISignLocation) => {
    this.props.setSignActiveLocation!(
      this.props.signActiveLocation && location.id === this.props.signActiveLocation.id ? null : location,
    );
  };

  private deleteSign = (withModule: boolean) => {
    this.props.deleteSign!({ hash: this.props.sign!.hash, withModule });
  };
}
