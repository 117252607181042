import { Paper } from '@material-ui/core';
import React, { forwardRef, PropsWithChildren } from 'react';
import { PanelContent } from './panel-content.component';

interface IProps {
  title?: string | React.ReactNode;
  style?: object;
  className?: string;
  elevation?: number;
}

export const Panel = forwardRef<HTMLDivElement, PropsWithChildren<IProps>>(
  ({ children, style, title, ...props }, ref) => (
    <Paper square style={style || { marginBottom: 20 }} {...props} ref={ref}>
      <PanelContent title={title}>{children}</PanelContent>
    </Paper>
  ),
);
