import React, { FunctionComponent } from 'react';
import { Add, Save, Send } from '@material-ui/icons';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { Grid } from '@material-ui/core';
import I18n from 'i18n-js';
import { Visible } from '../../../common/components/layout/Visible.component';
import { Auth } from '../../../components';
import { IRequestAclActions } from '../../../types';

interface IProps {
  addAllowed: boolean;
  onAdd: () => void;
  onSaveLocations: () => void;
  onSubmit: () => void;
  saveAllowed: boolean;
  submitAllowed: boolean;
  submitVisible: boolean;
}

export const SidePaneHeader: FunctionComponent<IProps> = ({
  addAllowed,
  onAdd,
  onSaveLocations,
  onSubmit,
  saveAllowed,
  submitAllowed,
  submitVisible,
}) => {
  return (
    <Grid container data-testid="SidePaneHeader" spacing={2}>
      <Grid item>
        <Button.Green
          disabled={!addAllowed}
          onClick={onAdd}
          startIcon={<Add fontSize={'small'} />}
          tooltip={addAllowed ? '' : I18n.translate('Requests.Create.AddLocationDisabled')}
        >
          {I18n.translate('Requests.Create.AddLocation')}
        </Button.Green>
      </Grid>
      <Grid item>
        <Button.Green disabled={!saveAllowed} onClick={onSaveLocations} startIcon={<Save fontSize="small" />}>
          {I18n.translate('Save')}
        </Button.Green>
      </Grid>
      <Grid item>
        <Auth acl={IRequestAclActions.submitAssignedRequest}>
          <Visible visible={submitVisible}>
            <Button.Green
              data-testid="SubmitRequest"
              disabled={!submitAllowed}
              onClick={onSubmit}
              startIcon={<Send fontSize="small" />}
            >
              {I18n.translate('Requests.Create.Submit')}
            </Button.Green>
          </Visible>
        </Auth>
      </Grid>
    </Grid>
  );
};
