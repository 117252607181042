import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Container, Panel, PanelAction } from '../../../../../../common';
import { Header } from '../../../../../../common/components/layout/Header.component';
import { appUrls } from '../../../../../../common/config/url.constants';
import { Auth } from '../../../../../atoms';
import { Grid } from '@material-ui/core';
import { LabelValue } from '../../../../../../common/components/layout/LabelValue.component';
import { Edit } from '@material-ui/icons';
import { getAdvisingParty } from '../../../../../../store/selectors/sgwAdvisingParties.selectors';
import { ISgwAdvisingPartiesAclActions, IRouterWithId } from '../../../../../../types';
import { translate } from '../../../../../../common/translations/translate';
import { formatDateTimeToDisplayDate } from '../../../../../../common/utils/date.util';
import { Loader } from '../../../../../../common/components/layout/loader.component';
import { Visible } from '../../../../../../common/components/layout/Visible.component';
import { SgwAdvisingPartiesActions } from '../../../../../../store/actions/sgwAdvisingParties.actions';
import { useFormStyles } from '../../../../../../style/form.styles';

export const DetailAdvisingPartyPage: FC = () => {
  const dispatch = useDispatch();
  const C = useFormStyles();
  const { id } = useParams<IRouterWithId>();
  const advisingParty = useSelector(getAdvisingParty(id));
  const history = useHistory();

  useEffect(() => {
    id && dispatch(SgwAdvisingPartiesActions.fetch(id));
  }, [dispatch, id]);

  const onClick = () => history.push(appUrls.sgw.manage.advisingParties.edit(id));

  if (!advisingParty) {
    return <Loader />;
  }

  const {
    description,
    name,
    email,
    contactName,
    contactPhoneNumber,
    flagDays,
    active,
    mailNotification,
    createdAt,
    updatedAt,
    users,
  } = advisingParty;

  return (
    <Auth acl={ISgwAdvisingPartiesAclActions.viewAdvisingPartiesDetail} showUnauthorizedPage>
      <Header data-testid="AdvisingPartyDetailHeader" backButtonUrl={appUrls.sgw.manage.advisingParties.base}>
        {translate('sgw.advisingParties.detail.name', { advisingParty: name })}
      </Header>
      <Container className={C.container}>
        <Panel title={translate('sgw.advisingParties.detail.title')}>
          <Auth acl={ISgwAdvisingPartiesAclActions.editAdvisingParties}>
            <PanelAction icon={<Edit />} onClick={onClick} data-testid="ToggleButton" />
          </Auth>
          <Grid container spacing={2} className={C.grid}>
            <LabelValue label={translate('sgw.advisingParties.detail.id')}>{id}</LabelValue>
            <LabelValue label={translate('sgw.advisingParties.columns.name')}>{name}</LabelValue>
            <LabelValue label={translate('sgw.advisingParties.columns.email')}>{email}</LabelValue>
            <LabelValue label={translate('sgw.advisingParties.columns.contactName')}>{contactName}</LabelValue>
            <LabelValue label={translate('sgw.advisingParties.columns.contactPhoneNumber')}>
              {contactPhoneNumber}
            </LabelValue>
            <LabelValue label={translate('sgw.advisingParties.columns.description')}>{description}</LabelValue>
            <LabelValue label={translate('sgw.advisingParties.columns.flagDays')}>{flagDays}</LabelValue>
            <LabelValue label={translate('sgw.advisingParties.columns.users')} visible={!!users?.length}>
              {users?.map((user) => (user?.fullName ? <div key={user.id}>{user.fullName}</div> : null))}
            </LabelValue>
            <LabelValue label={translate('sgw.advisingParties.columns.mailNotification')}>
              {translate(mailNotification ? 'yes' : 'no')}
            </LabelValue>
            <LabelValue label={translate('sgw.advisingParties.detail.active')}>
              {translate(active ? 'yes' : 'no')}
            </LabelValue>
            <LabelValue label={translate('sgw.advisingParties.detail.createdAt')}>
              {createdAt && formatDateTimeToDisplayDate(createdAt)}
            </LabelValue>
            <Visible visible={!!updatedAt}>
              <LabelValue label={translate('sgw.advisingParties.detail.updatedAt')}>
                {updatedAt && formatDateTimeToDisplayDate(updatedAt)}
              </LabelValue>
            </Visible>
          </Grid>
        </Panel>
      </Container>
    </Auth>
  );
};
