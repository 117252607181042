import { useDispatch } from 'react-redux';
import { ILogin } from '../../types/login.types';
import { AppActions } from '../../common';
import { useForm } from 'react-hook-form';

export const useLoginForm = () => {
  const dispatch = useDispatch();
  const formMethods = useForm<ILogin>();

  const _onSubmit = (credentials: ILogin) => {
    dispatch(AppActions.fetchToken({ type: 'login', credentials }));
  };

  return { onSubmit: formMethods.handleSubmit(_onSubmit), formMethods };
};
