import axios from 'axios';
import { CONFIG, IApiPromise, IPagedApiParams } from '../..';
import {
  IPincodeManagement,
  IService,
  IAptrUsageCodeValidationResult,
  IServiceContact,
  IServiceContactWithId,
  IServiceNew,
  IWhiteList,
  IWhitelistEntry,
} from '../../../types';

export const ServicesApi = {
  fetch: (id: string): IApiPromise<IService> => axios.get(`${CONFIG.backOfficeApi}/v1/services/${id}`),
  fetchLatestAPTRSync: (): IApiPromise<{ aptrSyncedAt: string }> =>
    axios.get(`${CONFIG.backOfficeApi}/v1/services/latest_aptr_sync`),
  fetchList: (params: IPagedApiParams): IApiPromise<IService[]> =>
    axios.get(`${CONFIG.backOfficeApi}/v1/services`, { params }),
  fetchByName: (name: string): IApiPromise<IService[]> =>
    axios.get(`${CONFIG.backOfficeApi}/v1/services`, { params: { name } }),
  fetchContactList: (id: string, params: IPagedApiParams): IApiPromise<IServiceContactWithId[]> =>
    axios.get(`${CONFIG.backOfficeApi}/v1/services/${id}/contacts`, { params }),
  fetchWhitelistEntryList: (params: IPagedApiParams): IApiPromise<IWhitelistEntry[]> =>
    axios.get(`${CONFIG.backOfficeApi}/v1/white_list_entries`, { params }),
  fetchWhitelists: (params: IPagedApiParams): IApiPromise<IWhiteList[]> =>
    axios.get(`${CONFIG.backOfficeApi}/v1/whitelists`, { params }),
  create: (service: IServiceNew): IApiPromise<IService> => axios.post(`${CONFIG.backOfficeApi}/v1/services`, service),
  addContact: (service: IService, contact: IServiceContact): IApiPromise<IService> =>
    axios.post(`${CONFIG.backOfficeApi}/v1/services/${service.id}/contacts`, contact),
  deleteContact: (service: IService, contact: IServiceContactWithId): IApiPromise<IService> =>
    axios.delete(`${CONFIG.backOfficeApi}/v1/services/${service.id}/contacts/${contact.id}`),
  resetPincodes: (id: string): IApiPromise<IService> =>
    axios.get(`${CONFIG.backOfficeApi}/v1/services/${id}/reset_pincode`),
  save: ({ id, ...service }: IService): IApiPromise<IService> =>
    axios.patch(`${CONFIG.backOfficeApi}/v1/services/${id}`, service),
  savePincode: (id: string, pincodeManagement: IPincodeManagement): IApiPromise<IService> =>
    axios.patch(`${CONFIG.backOfficeApi}/v1/services/${id}/pincode`, pincodeManagement),
  syncWithAPTR: (): IApiPromise<IService> => axios.get(`${CONFIG.backOfficeApi}/v1/services/sync_aptr`),
  uploadWhitelistFile: (file: FormData, onUploadProgress?: (progressEvent: any) => void) =>
    axios.post(`${CONFIG.backOfficeApi}/v1/whitelists`, file, {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress,
    }),
  validateAptrUsageCode: (code: string): IApiPromise<IAptrUsageCodeValidationResult> =>
    axios.get(`${CONFIG.backOfficeApi}/v1/services/validate_aptr_usage_code/${code}`),
};
