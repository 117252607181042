import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import React, { FC, ChangeEvent } from 'react';
import { Visible } from '../../../common/components/layout/Visible.component';
import { translate } from '../../../common/translations/translate';

interface IProps {
  value?: string;
  onChange(e: ChangeEvent): void;
  onSearch?(): void;
}

export const SearchTextField: FC<IProps> = ({ value = '', onChange, onSearch }) => (
  <TextField
    value={value}
    onChange={onChange}
    fullWidth={true}
    id="queryFilter"
    label={translate('users.filters.query')}
    InputProps={{
      endAdornment: (
        <Visible visible={!!onSearch} defaultComponent={<Search />}>
          <InputAdornment position="end">
            <IconButton onClick={onSearch} aria-label="SearchButton">
              <Search />
            </IconButton>
          </InputAdornment>
        </Visible>
      ),
    }}
  />
);
