import { Actions, TableActions } from '../../common';
import { INotification, INotificationHistoryItem } from '../../notification/types/notification';

class NotificationsActionsClass extends Actions {
  fetch = this.createPayloadAction<string>('fetch');
  fetchCount = this.createAction('fetchCount');
  fetchHistory = this.createPayloadAction<string>('fetchHistory');
  patch = this.createPayloadAction<INotification>('patch');
  set = this.createPayloadAction<INotification>('set');
  setCount = this.createPayloadAction<number>('setCount');
  setError = this.createPayloadAction<string | null>('setError');
  setLoading = this.createPayloadAction<boolean>('setLoading');
  setHistory = this.createPayloadAction<INotificationHistoryItem[]>('setHistory');
  list = new TableActions<INotification>(`${this.prefix}.list`);
}

export const NotificationActions = new NotificationsActionsClass('notification');
