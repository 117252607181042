import { createSelector } from '@reduxjs/toolkit';
import { IRootState } from '../../../root.state';
import { IServiceFilter } from '../../../types/services.types';
import { createAllEntitiesSelector } from '../../utils/normalized.util';
import { createPagedApiParamsSelector } from '../utils/generic.selectors';
import moment from 'moment';
import { DateFormat } from '../../../types';

export const selectAllIds = (store: IRootState) => store.services.list.allIds;
export const selectById = (store: IRootState) => store.services.list.byId;
export const selectTable = (store: IRootState) => store.services.list.table;
export const getServicesSaving = (store: IRootState) => store.services.saving;
export const getPincodeSaving = (store: IRootState) => store.services.savingPincode;
export const selectLatestAPTRSync = (store: IRootState) =>
  store.services.aptr.latestSync ? moment(store.services.aptr.latestSync).format(DateFormat.dateTime) : '/';
export const selectSyncingAPTR = (store: IRootState) => store.services.aptr.isSyncing;

export const selectWhitelistsListAllIds = (store: IRootState) => store.services.whitelists.list.allIds;
export const selectWhitelistsListById = (store: IRootState) => store.services.whitelists.list.byId;
export const selectWhitelistTable = (store: IRootState) => store.services.whitelists.list.table;
export const selectUploadResult = (store: IRootState) => store.services.whitelists.result;

export const selectContactPersonsAllIds = (store: IRootState) => store.services.contactPersons.allIds;
export const selectContactPersonsById = (store: IRootState) => store.services.contactPersons.byId;
export const selectContactPersonsTable = (store: IRootState) => store.services.contactPersons.table;

export const selectWhitelistEntryListAllIds = (store: IRootState) => store.services.whitelistEntryList.allIds;
export const selectWhitelistEntryListById = (store: IRootState) => store.services.whitelistEntryList.byId;
export const selectWhitelistEntryListTable = (store: IRootState) => store.services.whitelistEntryList.table;

export const getExistingNames = (store: IRootState) => store.services.existingNames;

export const getList = createAllEntitiesSelector(selectAllIds, selectById);
export const getPagedApiParams = createPagedApiParamsSelector<IServiceFilter>(selectTable);
export const getWhitelistsList = createAllEntitiesSelector(selectWhitelistsListAllIds, selectWhitelistsListById);
export const getPagedWhitelistApiParams = createPagedApiParamsSelector<{}>(selectWhitelistTable);

export const getContactPersonsList = createAllEntitiesSelector(selectContactPersonsAllIds, selectContactPersonsById);
export const getContactPersonsPagedApiParams = createPagedApiParamsSelector(selectContactPersonsTable);

export const getWhitelistEntryList = createAllEntitiesSelector(
  selectWhitelistEntryListAllIds,
  selectWhitelistEntryListById,
);
export const getWhitelistEntryListExtended = createSelector(getWhitelistEntryList, (entries) =>
  entries.map((entry) => ({
    ...entry,
    aptrUsageCode: entry.aptrUsageCodes.map(({ usageCode }) => usageCode).join(', '),
  })),
);
export const getWhitelistEntryListPagedApiParams = createPagedApiParamsSelector(selectWhitelistEntryListTable);

export const getService = (id: string) => createSelector([selectById], (byId) => byId[id]);
export const getServiceAptrUsageCodes = (id: string) =>
  createSelector([getService(id)], ({ aptrC3UsageCode, aptrF3UsageCode, aptrVpUsageCode }) =>
    [aptrVpUsageCode, aptrC3UsageCode, aptrF3UsageCode]
      .filter((usageCode) => !!usageCode)
      .map((usageCode) => ({ id: usageCode, name: usageCode })),
  );
