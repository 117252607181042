import { IPagedTable, ISortingDirection } from '../common';
import { baseInitialFilter } from '../common/config/paging.constants';
import { translateIgnoreTS } from '../common/translations/translate';
import { IUser, IUserFilter, UserSourceType } from '../common/types/user';

export const userInitialFilter: IUserFilter = {
  query: '',
};

export const userInitialValues: Partial<IUser> = {};

export const userListInitialFilter: IPagedTable<IUserFilter> = {
  ...baseInitialFilter,
  filters: userInitialFilter,
  sorting: { key: 'id', direction: ISortingDirection.asc },
};

export const getUserSourceTypeOptions = () =>
  Object.values(UserSourceType).map((value) => ({
    label: translateIgnoreTS(`users.filters.options.source.${value}`),
    value,
  }));
