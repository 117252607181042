import { WORK_ORDER_ITEM_TYPES } from '../planning.constants';

export function getWorkOrderItemTypeIcon(type: WORK_ORDER_ITEM_TYPES): string {
  switch (type) {
    case WORK_ORDER_ITEM_TYPES.PICK_UP:
      return 'arrow_upward';
    case WORK_ORDER_ITEM_TYPES.DROP_OFF:
      return 'arrow_downward';
    case WORK_ORDER_ITEM_TYPES.UPDATE:
      return 'edit';
    case WORK_ORDER_ITEM_TYPES.REPLACE:
      return 'compare_arrows';
    case WORK_ORDER_ITEM_TYPES.NOTIFICATION:
      return 'notifications';
    case WORK_ORDER_ITEM_TYPES.PICKUP_OVERDUE:
      return 'arrow_upward';
    case WORK_ORDER_ITEM_TYPES.RETRIEVE:
    default:
      return 'location_searching';
  }
}
