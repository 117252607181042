import { push } from 'connected-react-router';
import { appUrls } from '../../common/config/url.constants';
import { translate } from '../../common/translations/translate';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { genericErrorHandler, TPayloadAction } from '../../common';
import { getPagingFromHeaders } from '../../common/utils/api.util';
import { ICall, ISelect, ISgwNatureOfWork } from '../../types';
import { SgwNatureOfWorksActions, SnackBarActions } from '../actions';
import { SgwNatureOfWorksApi } from '../api';
import { getNatureOfWorks, getPagedApiParams } from '../selectors/sgwNatureOfWorks.selectors';

function* onFetchNatureOfWorkList() {
  const params: ISelect<typeof getPagedApiParams> = yield select(getPagedApiParams);
  yield put(SgwNatureOfWorksActions.list.setLoading(true));
  const response: ICall<typeof SgwNatureOfWorksApi.fetchList> = yield call(SgwNatureOfWorksApi.fetchList, params!);
  yield all([
    put(
      SgwNatureOfWorksActions.list.setParams({
        paging: getPagingFromHeaders(response as any),
      }),
    ),
    put(SgwNatureOfWorksActions.list.set(response!.data.data)),
    put(SgwNatureOfWorksActions.list.setLoading(false)),
  ]);
}

function* onSaveNatureOfWork({ payload }: TPayloadAction<ISgwNatureOfWork>): SagaIterator {
  const { id, ...natureOfwork } = payload;
  const currentNatureOfWorks: ISgwNatureOfWork = yield select(getNatureOfWorks(`${id}`));

  const response: ICall<typeof SgwNatureOfWorksApi.save> = yield call(SgwNatureOfWorksApi.save, {
    ...currentNatureOfWorks,
    ...natureOfwork,
    id,
  });

  yield put(SgwNatureOfWorksActions.set(response!.data.data));
  yield put(SgwNatureOfWorksActions.setLoading(false));
  yield put(SnackBarActions.setSuccess(translate('sgw.natureOfWorks.saved')));
  yield put(push(appUrls.sgw.manage.natureOfWorks.detail(`${response!.data.data.id}`)));
}

function* onFetch({ payload }: TPayloadAction<string>) {
  const response: ICall<typeof SgwNatureOfWorksApi.fetch> = yield call(SgwNatureOfWorksApi.fetch, payload);
  yield put(SgwNatureOfWorksActions.set(response!.data.data));
  yield put(SgwNatureOfWorksActions.setLoading(false));
}

export function* sgwNatureOfWorksSaga(): SagaIterator {
  yield takeLatest(SgwNatureOfWorksActions.list.fetch.type, genericErrorHandler(onFetchNatureOfWorkList));
  yield takeLatest(SgwNatureOfWorksActions.save.type, genericErrorHandler(onSaveNatureOfWork));
  yield takeLatest(SgwNatureOfWorksActions.fetch.type, genericErrorHandler(onFetch));
}
