import { ISgwNatureOfWorksState } from '../../types';
import { ISortingDirection } from '../../common';
import { initialPaging } from '../../common/config/paging.constants';

export const initialSgwNatureOfWorksState: ISgwNatureOfWorksState = {
  list: {
    allIds: [],
    byId: {},
    table: {
      filters: {},
      paging: initialPaging,
      sorting: {
        direction: ISortingDirection.desc,
        key: 'id',
      },
    },
    loading: true,
  },
  natureOfWork: null,
  loading: true,
};
