import { LatLngExpression } from 'leaflet';
import * as React from 'react';
import { Marker } from 'react-leaflet';
import { BaseMap, Markers } from '../../common';
import { SIGN_STATES, SIGN_TYPES } from '../signs.constants';
import { ISign } from '../types/sign';
import { ISignData } from '../types/sign-data';

interface IProps {
  sign: ISign;
  signGPSLogs: ISignData[];
}

export class SignMap extends React.Component<IProps> {
  public render(): React.ReactNode {
    const signLocation = this.getSignLocation();
    const isSignMissingFoundOrMoved = this.isSignMissingFoundOrMoved();
    const showSignLocation = this.showSignLocation() || isSignMissingFoundOrMoved;

    return showSignLocation && signLocation ? (
      <BaseMap height={400} center={signLocation} zoom={19}>
        <Marker position={signLocation} icon={isSignMissingFoundOrMoved ? Markers.Red : Markers.Blue} />
      </BaseMap>
    ) : null;
  }

  private showSignLocation(): boolean {
    const { sign } = this.props;
    return sign.type.name === SIGN_TYPES.DIGITAL
      ? !!sign.parkingBanIntake && sign.state.state === SIGN_STATES.PLACED
      : !!sign.parkingBanIntake;
  }

  private isSignMissingFoundOrMoved(): boolean {
    const { sign } = this.props;
    return (
      sign.type.name === SIGN_TYPES.DIGITAL && [SIGN_STATES.MISSING, SIGN_STATES.MOVED].indexOf(sign.state.state) >= 0
    );
  }

  private getSignLocation(): LatLngExpression | null {
    const { sign, signGPSLogs } = this.props;
    let coordinates: number[] | null = null;
    if (this.isSignMissingFoundOrMoved()) {
      if (signGPSLogs[0]) {
        coordinates = [...signGPSLogs[0].locationData.location.coordinates];
      }
    } else {
      if (sign.location) {
        coordinates = [...sign.location.coordinates];
      }
    }
    return coordinates && (coordinates.reverse() as LatLngExpression);
  }
}
