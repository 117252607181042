import React, { useEffect } from 'react';
import { Container } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Button } from '../../../../common/components/buttons/asign-button-extensions';
import { GenericTablePanel } from '../../../../common/components/table/GenericTablePanel.component';
import { translate } from '../../../../common/translations/translate';
import { IUtilityCompany, IUtilityCompanyAclActions } from '../../../../types';
import { Auth } from '../../../atoms';
import { useGeneralStyles } from '../../../../style/generalStyles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { UtilityCompanyActions } from '../../../../store/actions';
import { IPaging, ISorting } from '../../../../common';
import { appUrls } from '../../../../common/config/url.constants';
import { IUser } from '../../../../common/types/user';
import { UtilityCompanyFilters } from '../../../molecules';
import { useFormStyles } from '../../../../style/form.styles';
import {
  getUtilityCompaniesList,
  selectListLoading,
  selectTable,
} from '../../../../store/selectors/utilityCompanies.selectors';

export const UtilityCompaniesPage = () => {
  const C = useFormStyles();
  const G = useGeneralStyles();

  const dispatch = useDispatch();
  const history = useHistory();
  const companies = useSelector(getUtilityCompaniesList);
  const loading = useSelector(selectListLoading);
  const { paging, sorting } = useSelector(selectTable);

  useEffect(() => {
    dispatch(UtilityCompanyActions.list.fetch({}));
  }, [dispatch]);

  const onChangeSorting = (sorting: ISorting) => dispatch(UtilityCompanyActions.list.fetch({ sorting }));

  const onChangePaging = (paging: IPaging) => dispatch(UtilityCompanyActions.list.fetch({ paging }));

  const onClick = (company: IUtilityCompany) => history.push(appUrls.utilityCompanies.detail(`${company.id}`));

  const onCreateUtilityCompany = () => history.push(appUrls.utilityCompanies.create);

  return (
    <Auth acl={IUtilityCompanyAclActions.viewUtilityCompany} showUnauthorizedPage>
      <Container>
        <Auth acl={IUtilityCompanyAclActions.createUtilityCompany}>
          <Button.Green
            className={C.createButton}
            dataTestId="newUtilityCompany"
            onClick={onCreateUtilityCompany}
            startIcon={<AddIcon fontSize={'small'} />}
            noTextTransform
          >
            {translate('utilityCompanies.newUtilityCompany')}
          </Button.Green>
        </Auth>
        <Auth acl={IUtilityCompanyAclActions.viewUtilityCompany}>
          <GenericTablePanel<IUtilityCompany>
            filterNode={<UtilityCompanyFilters />}
            dataTestId="UtilityCompanyListTable"
            labelUnavailable={translate('utilityCompanies.table.unavailable')}
            onChangeSorting={onChangeSorting}
            onChangePaging={onChangePaging}
            onClick={onClick}
            paging={paging}
            sorting={sorting}
            loading={loading}
            records={companies}
            title={<div className={G.flexRowSpaceBetween}>{translate('utilityCompanies.overview.title')}</div>}
            columnKey="id"
            columns={[
              {
                label: translate('utilityCompanies.table.id'),
                name: 'id',
              },
              {
                label: translate('utilityCompanies.table.name'),
                name: 'name',
              },
              {
                label: translate('utilityCompanies.table.amountOfUsers'),
                name: 'users',
                renderer: (users: IUser[]) => users.length,
              },
            ]}
          />
        </Auth>
      </Container>
    </Auth>
  );
};
