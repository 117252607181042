import React, { FunctionComponent, PropsWithChildren } from 'react';
import { IComponentProps } from '../../../types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles({
  info: { fontStyle: 'italic' },
});

export const InfoText: FunctionComponent<PropsWithChildren<IComponentProps>> = ({ className, children, ...props }) => {
  const C = useStyles();
  return (
    <div {...props} className={classNames(C.info, className)}>
      {children}
    </div>
  );
};
