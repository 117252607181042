import * as I18n from 'i18n-js';
import moment from 'moment';
import * as React from 'react';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Panel } from '../../common';
import { SgwRequestInternalNotes } from '../../components';
import { DateFormat, INotificationAclActions } from '../../types';
import { INotification } from '../types/notification';
import { NotificationTable } from './notification-table.component';
import { NotificationActions } from '../../store/actions/notifications.actions';
import { selectUser } from '../../store/selectors';
import { useAuthorization } from '../../hooks';

interface IProps {
  notification: INotification;
  patchNotification?: typeof NotificationActions.patch;
}

export const NotificationGeneral: React.FunctionComponent<IProps> = ({ notification, patchNotification }) => {
  const currentUser = useSelector(selectUser);
  const canAddNotification = useAuthorization(INotificationAclActions.editNotification);

  const patchInternalNotes = useCallback(
    (newNote: string, shouldAppend: boolean) => {
      const appendedNote = `${notification.adminNote ? notification.adminNote + '\n\n' : ''}${
        currentUser?.fullName
      } (${moment().format(DateFormat.dateTime)}):\n${newNote}`;
      patchNotification && patchNotification({ ...notification, adminNote: shouldAppend ? appendedNote : newNote });
    },
    [currentUser, notification, patchNotification],
  );

  return (
    <>
      <Panel title={I18n.t('GeneralInfo')}>
        <NotificationTable notification={notification} />
      </Panel>
      <SgwRequestInternalNotes
        notes={notification.adminNote}
        onPatchInternalNotes={patchInternalNotes}
        isReadOnly={!canAddNotification}
      />
    </>
  );
};
