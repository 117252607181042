import { Checkbox, FormControlLabel } from '@material-ui/core';
import { CheckboxProps } from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';
import * as React from 'react';

interface IProps extends CheckboxProps {
  label: string;
  disabled?: boolean;
}

const useStyles = makeStyles({
  root: {
    margin: -4,
    width: '100%',
    userSelect: 'none',
  },
});

export const QuickFilter: FunctionComponent<IProps> = ({ label, disabled = false, ...props }) => {
  const C = useStyles();
  return <FormControlLabel disabled={disabled} className={C.root} control={<Checkbox {...props} />} label={label} />;
};
