import { createReducer } from '@reduxjs/toolkit';
import { extractIdsFromArray } from '../../../common/utils/normalized.util';
import { RequestActions } from '../../actions';

type TState = string[];

export default createReducer<TState>([], {
  [RequestActions.setDrafts.type]: (state: TState, { payload }: ReturnType<typeof RequestActions.setDrafts>): TState =>
    extractIdsFromArray(payload),
  [RequestActions.remove.type]: (state: TState, { payload }: ReturnType<typeof RequestActions.remove>): TState =>
    state.filter((id) => id !== payload),
});
