import { Paper, Tab, Tabs } from '@material-ui/core';
import React, { FC, useEffect, useMemo } from 'react';
import { Container } from '../../../common';
import { useHistory, useLocation } from 'react-router';
import { ITab, IWithClassName } from '../../../types';
import { Link } from 'react-router-dom';
import { Visible } from '../../../common/components/layout/Visible.component';
import { useRouterMenuStyles } from '../../../style/routerMenu.styles';

interface IProps extends IWithClassName {
  tabs: ITab[];
}

export const RouterMenu: FC<IProps> = ({ tabs, className }) => {
  const C = useRouterMenuStyles();
  const history = useHistory();
  const location = useLocation();

  const sortedTabs = useMemo(() => [...tabs].sort(({ route: r1 }, { route: r2 }) => r2.length - r1.length), [tabs]);
  const visibleTabs = tabs.filter(({ visible }) => visible === undefined || visible);
  const selectedRoute = useMemo(
    () => sortedTabs.find(({ route }) => location.pathname?.includes(route))?.route,
    [location.pathname, sortedTabs],
  );

  useEffect(() => {
    !selectedRoute && visibleTabs.length && history.push(visibleTabs[0].route);
  }, [history, selectedRoute, visibleTabs]);

  return (
    <Visible visible={visibleTabs.length > 1}>
      <Paper square className={className}>
        <Container>
          <Tabs value={selectedRoute} indicatorColor="primary">
            {visibleTabs.map(({ label, route }) => (
              <Tab
                data-testid={`tab-${route}${route === selectedRoute ? '-selected' : ''}`}
                key={label?.toString()}
                label={
                  <Link className={C.link} to={route}>
                    {label}
                  </Link>
                }
                value={route}
              />
            ))}
          </Tabs>
        </Container>
      </Paper>
    </Visible>
  );
};
