import React, { FC, useCallback } from 'react';
import { useAuthorization } from '../../../hooks';
import {
  IDraggableProps,
  IDraggableWorkOrderItemInjectedProps,
  IPlanningAclActions,
  IWorkOrderItem,
  IWorkOrderItemAclActions,
} from '../../../types';
import { Grid, Tooltip } from '@material-ui/core';
import { Clear, Done, ReportProblemOutlined } from '@material-ui/icons';
import { ReorderIcon } from '../../../common/components/map/reorder-icon.component';
import { Auth } from '../../../components';
import { Visible } from '../../../common/components/layout/Visible.component';
import { useDispatch, useSelector } from 'react-redux';
import { selectWorkOrderItemsFilter } from '../../store/planning.selectors';
import { PlanningActions } from '../../store/planning.actions';
import { useDraggableWorkOrderItemStyles } from './DraggableWorkOrderItem.styles';
import { WorkOrderItemAddress } from '../WorkOrderItemAddress';
import { WorkOrderItemIcon } from '../WorkOrderItemIcon';
import classNames from 'classnames';
import { translate } from '../../../common/translations/translate';
const isWoiDone = (woi: IWorkOrderItem): boolean => woi.state === 'done';

export const DraggableWorkOrderItem: FC<IDraggableProps<IWorkOrderItem, IDraggableWorkOrderItemInjectedProps>> = ({
  item: woi,
  onClick,
  workOrder,
  listeners,
  canReorder,
}) => {
  const C = useDraggableWorkOrderItemStyles();
  const dispatch = useDispatch();
  const workOrderItemsFilter = useSelector(selectWorkOrderItemsFilter);
  const { isAuthorized: showDeleteWorkOrderItemButton } = useAuthorization(IPlanningAclActions.editPlanning);

  const reorderIcon = (showIcon: boolean) => (showIcon ? <ReorderIcon /> : <div className={C.spacer}></div>);

  const onClickWorkOrderItem = useCallback(() => {
    onClick(woi);
  }, [onClick, woi]);

  const onDeleteWorkOrderItem = useCallback(
    (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      event.stopPropagation();
      const order = {
        ...workOrder,
        workOrderItems: workOrder.workOrderItems?.filter(({ id }) => id !== woi.id) || [],
      };
      dispatch(PlanningActions.assignItemsToWorkOrder({ order, filter: workOrderItemsFilter }));
    },
    [dispatch, woi.id, workOrder, workOrderItemsFilter],
  );

  const onMarkWarningAsComplete = (woi: IWorkOrderItem) => {
    dispatch(PlanningActions.markWarningAsIncomplete(woi.id));
  };

  return (
    <div className={C.border}>
      <Grid
        className={classNames(
          C.root,
          isWoiDone(woi) && C.woiDone,
          !isWoiDone(woi) && C.woiNotDone,
          woi.isShownOnMap && C.isShownOnMap,
          woi.isShownOnMap && 'team-status-item-hover',
          !woi.isShownOnMap && 'team-status-item',
        )}
        container
        justify="space-between"
        alignItems="center"
        wrap="nowrap"
        onClick={onClickWorkOrderItem}
      >
        <Grid item className={C.address} container alignContent="center" wrap="nowrap">
          <Grid className={C.addressIcon} {...listeners}>
            <Visible visible={isWoiDone(woi)} defaultComponent={reorderIcon(canReorder)}>
              <Done color={'secondary'} />
            </Visible>
          </Grid>
          <Grid className={C.addressContent}>
            <WorkOrderItemAddress woi={woi} />
          </Grid>
        </Grid>
        <Grid item container wrap="nowrap" className={C.icons}>
          <Visible visible={!!woi.showWarning}>
            <Tooltip
              title={translate('planning.workOrderItem.removeIncompleteWarning')}
              onClick={(event) => {
                event.stopPropagation();
                onMarkWarningAsComplete(woi);
              }}
              className={C.warning}
            >
              <Grid item className={C.showWarning}>
                <ReportProblemOutlined />
              </Grid>
            </Tooltip>
          </Visible>
          <Grid item className={woi.hasZoneSigns ? C.woiIconWithZoneSigns : C.woiIconWithoutZoneSigns}>
            <WorkOrderItemIcon woi={woi} />
          </Grid>
          <Grid item className={woi.hasZoneSigns ? C.numberOfSignsWithZoneSigns : C.numberOfSignsWithoutZoneSigns}>
            {woi.numberOfSigns}
          </Grid>
          <Auth acl={IWorkOrderItemAclActions.editWorkOrderItem}>
            <Grid item className={C.removeButton}>
              <Visible visible={!isWoiDone(woi) && showDeleteWorkOrderItemButton}>
                <Clear fontSize="small" className={C.removeButtonIcon} onClick={onDeleteWorkOrderItem} />
              </Visible>
            </Grid>
          </Auth>
        </Grid>
      </Grid>
    </div>
  );
};
