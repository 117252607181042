import { useForm } from 'react-hook-form';
import { ISgwRequestInfo } from '../../types';
import { useDispatch } from 'react-redux';
import { SgwRequestActions } from '../../store/actions';
import { useAtom } from 'jotai';
import { requestEditAtom } from '../../components';

export const useRequestDetailInfoForm = (initialValues: Partial<ISgwRequestInfo>) => {
  const dispatch = useDispatch();
  const [, setEditMode] = useAtom(requestEditAtom);

  const formMethods = useForm<ISgwRequestInfo>({
    defaultValues: {
      id: initialValues.id,
      referenceId: initialValues.referenceId,
      bonuNumber: initialValues.bonuNumber,
      cluster: initialValues.cluster,
      dateFrom: initialValues.dateFrom,
      dateUntil: initialValues.dateUntil,
      gipodId: initialValues.gipodId,
      sgwDistrictIds: initialValues.sgwDistrictIds,
      mainLocation: initialValues.mainLocation,
      prio: initialValues.prio,
      requestorId: initialValues.requestorId,
      crane: initialValues.crane,
      sgwProject: initialValues.sgwProject,
      workDescription: initialValues.workDescription,
      workType: initialValues.workType,
      utilityCompanyName: initialValues.utilityCompanyName,
      utilityCompanyNameResponsible: initialValues.utilityCompanyNameResponsible,
      utilityCompanyEmailResponsible: initialValues.utilityCompanyEmailResponsible,
      utilityCompanyPhoneResponsible: initialValues.utilityCompanyPhoneResponsible,
    },
    shouldUnregister: true,
  });

  const onSubmit = formMethods.handleSubmit((values) => {
    // do not save prio on form submit, prio gets patched separately
    const { bonuNumber, prio, cluster, sgwProject, ...toSubmit } = values;
    dispatch(
      SgwRequestActions.patch({
        ...toSubmit,
        id: initialValues.id,
        bonuNumber: bonuNumber || null,
        cluster: cluster?.cluster,
        // @ts-ignore
        sgwProject: sgwProject?.id || null,
        userResponsible: undefined,
        userGisResponsible: undefined,
      }),
    );
    setEditMode(false);
  });

  return { formMethods, onSubmit };
};
