import { createReducer } from '@reduxjs/toolkit';
import { extractIdsFromArray } from '../../../../utils/normalized.util';
import { ServicesActions } from '../../Services.actions';

type TState = string[];

export default createReducer<TState>([], {
  [ServicesActions.setContactList.type]: (
    state: TState,
    { payload }: ReturnType<typeof ServicesActions.setContactList>,
  ): TState => extractIdsFromArray(payload),
});
