import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';
import * as React from 'react';
import { COLORS } from '../../../common';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles({
  root: {
    color: COLORS.DANGER,
    marginLeft: '10px',
    border: `1px solid ${COLORS.DANGER}`,
    fontWeight: 400,
    height: '28px',
  },
  icon: {
    color: COLORS.DANGER,
    width: '17px',
  },
});

interface IProps {
  text: string;
}

export const InfoChip: FunctionComponent<IProps> = ({ text }) => {
  const C = useStyles();

  return <Chip icon={<InfoIcon className={C.icon} />} label={text} variant="outlined" className={C.root} />;
};
