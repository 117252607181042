import { ISgwTrafficMeasuresState } from '../../types';
import { baseInitialFilter } from '../../common/config/paging.constants';

export const initialSgwTrafficMeasuresState: ISgwTrafficMeasuresState = {
  list: {
    allIds: [],
    byId: {},
    table: baseInitialFilter,
    loading: true,
  },
  loading: true,
};
