import { Grid, Paper } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Container, PanelAction } from '../../../../../../common';
import { Header } from '../../../../../../common/components/layout/Header.component';
import { LabelValue } from '../../../../../../common/components/layout/LabelValue.component';
import { PanelContent } from '../../../../../../common/components/panel/panel-content.component';
import { appUrls } from '../../../../../../common/config/url.constants';
import { translate } from '../../../../../../common/translations/translate';
import { SgwProjectsActions } from '../../../../../../store/actions';
import { getSgwProject } from '../../../../../../store/selectors/sgwProjects.selectors';
import { IRouterWithId, ISgwProjectAclActions } from '../../../../../../types';
import { Auth } from '../../../../../atoms';
import { useFormStyles } from '../../../../../../style/form.styles';

export const DetailProjectPage: FC = () => {
  const C = useFormStyles();
  const dispatch = useDispatch();
  const { id } = useParams<IRouterWithId>();
  const project = useSelector(getSgwProject(id));
  const history = useHistory();

  useEffect(() => {
    id && dispatch(SgwProjectsActions.fetch(id));
  }, [dispatch, id]);

  const onClick = () => history.push(appUrls.sgw.manage.projects.edit(id));

  return (
    <Auth acl={ISgwProjectAclActions.viewSgwProjectDetail} showUnauthorizedPage>
      <Header backButtonUrl={appUrls.sgw.manage.projects.base}>
        {translate('sgw.projects.projectName', { project: project?.name })}
      </Header>
      <Container className={C.container} data-testid="projectInfo">
        <Paper square>
          <PanelContent title={translate('sgw.projects.details')}>
            <Auth acl={ISgwProjectAclActions.editSgwProject}>
              <PanelAction icon={<Edit />} onClick={onClick} data-testid="editButton" />
            </Auth>
            <Grid container spacing={2} className={C.grid}>
              <LabelValue label={translate('sgw.projects.columns.name')}>{project?.name}</LabelValue>
              <LabelValue label={translate('sgw.projects.columns.description')}>{project?.description}</LabelValue>
              <LabelValue label={translate('sgw.projects.active')}>
                {translate(project?.deleted ? 'no' : 'yes')}
              </LabelValue>
            </Grid>
          </PanelContent>
        </Paper>
      </Container>
    </Auth>
  );
};
