import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  getSgwPermitConditionsList,
  selectLoading,
  selectSgwPermitConditionsTable,
} from '../store/selectors/sgwPermitConditions.selectors';
import { SgwPermitConditionsActions } from '../store/actions';
import { initialPaging } from '../common/config/paging.constants';

export const useSgwPermitConditions = () => {
  const permitConditions = useSelector(getSgwPermitConditionsList);
  const table = useSelector(selectSgwPermitConditionsTable);
  const loading = useSelector(selectLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(SgwPermitConditionsActions.list.fetch({ paging: initialPaging }));
  }, [dispatch]);

  return { permitConditions, table, loading };
};
