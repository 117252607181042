import React, { FC } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { translate, translateIgnoreTS } from '../../../common/translations/translate';
import { Visible } from '../../../common/components/layout/Visible.component';
import { GeoDrawingType, ISgwGeoDrawing, ISgwRequestDetailAclActions } from '../../../types';
import { Disableable } from '../../../common/components/layout/Disableable.component';
import { useGeoDrawingListStyles } from '../../../style/GeoDrawingList.styles';
import { useGeoDrawingContext } from '../../../hooks/useGeoDrawingContext.hook';
import { GeoDrawingListItemButtons } from '../GeoDrawingListItemButtons/GeoDrawingListItemButtons.component';
import { useRequestAuthorization } from '../../../hooks';

interface IProps extends ISgwGeoDrawing {
  onDelete(id?: number): void;
  onEdit(id?: number): void;
  disabled?: boolean;
  editMode?: boolean;
  size?: number;
}

export const GeoDrawingListItem: FC<IProps> = ({
  size,
  editMode = false,
  disabled = false,
  onDelete,
  onEdit,
  geoDrawingType,
  surfaceArea,
  customName,
  id,
}) => {
  const C = useGeoDrawingListStyles();
  const unit = geoDrawingType === GeoDrawingType.constructionzone ? 'm²' : 'm';
  const {
    formMethods: { register, watch },
  } = useGeoDrawingContext();
  const { isAuthorized: canEdit } = useRequestAuthorization(ISgwRequestDetailAclActions.editSgwMap);

  return (
    <Disableable disabled={disabled}>
      <Grid key={id} container direction="row" className={C.container}>
        <Grid xs={8} item container direction="row" className={C.info} justify="space-around">
          <Grid xs={12} item className={C.type}>
            <div className={C.bold}>
              {translateIgnoreTS(`sgw.requests.detail.map.geoDrawingType.${geoDrawingType}`)}
            </div>
            <div>{` - ${id}`}</div>
          </Grid>
          {editMode && canEdit ? (
            <Grid xs={12} item>
              <TextField
                fullWidth
                {...register('customName')}
                label={translate('sgw.requests.detail.map.addItem.customName')}
                InputLabelProps={{
                  shrink: !!watch('customName'),
                }}
              />
            </Grid>
          ) : (
            <Visible visible={!!customName}>
              <Grid xs={12} item>
                {customName}
              </Grid>
            </Visible>
          )}
          <Visible visible={!!surfaceArea}>
            <Grid xs={12} item className={C.bold}>{`${editMode ? size : surfaceArea} ${unit}`}</Grid>
          </Visible>
        </Grid>
        <Visible visible={canEdit}>
          <GeoDrawingListItemButtons
            editMode={editMode}
            onCancel={() => onEdit(undefined)}
            onDelete={() => onDelete(id)}
            onEdit={() => onEdit(id)}
          />
        </Visible>
      </Grid>
    </Disableable>
  );
};
