import axios from 'axios';
import { backendUrl, IApiPromise } from '../../common';
import { IManualPlacementIntake, IManualPlacementResponse } from '../../types';

export const ManualPlacementApi = {
  fetch: (id: string, key: string): IApiPromise<IManualPlacementResponse> =>
    axios.get(`${backendUrl}/manual_placement/requests/${id}/${key}`),
  patch: (id: string, key: string, values: Partial<IManualPlacementIntake>): IApiPromise<IManualPlacementResponse> =>
    axios.patch(`${backendUrl}/manual_placement/requests/${id}/${key}`, { parkingBanIntakes: [values] }),
};
