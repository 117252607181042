import { createSelector } from '@reduxjs/toolkit';
import { createAllEntitiesSelector } from '../../common/utils/normalized.util';
import { IRootState } from '../../root.state';
import { IRequestsApi } from '../../types';
import { selectRequestById } from './request.selectors';

// need a separate file to resolve circular dependencies

export const selectUserRequestTable = (store: IRootState) => store.user.requestList.table;
export const selectUserRequestLoading = (store: IRootState) => store.user.requestList.loading;
export const selectUserRequestAllIds = (store: IRootState) => store.user.requestList.allIds;
export const getUserRequests = createAllEntitiesSelector(selectUserRequestAllIds, selectRequestById);

export const getUserRequestApiParams = createSelector([selectUserRequestTable], ({ filters, paging, sorting }) => {
  const { fromDate, untilDate, userRequested, userResponsible, requestTypes, streetName, utilityCompany } = filters;

  let params: IRequestsApi = {
    page: paging.page,
    page_size: paging.pageSize,
    sort: sorting.key,
    order: sorting.direction,
  };

  if (fromDate) params.from_date = fromDate;
  if (untilDate) params.until_date = untilDate;
  if (userRequested) params.user_requested = userRequested;
  if (userResponsible) params.user_responsible = userResponsible;
  if (utilityCompany) params.utility_company_id = utilityCompany;
  if (requestTypes) params.publicdomainintake_types = requestTypes;
  if (streetName) params.street_name = streetName;

  return params;
});
