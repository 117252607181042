import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { COLORS } from '../..';
import { IWithClassName, IWithTestId } from '../../../types';
import { Visible } from '../layout/Visible.component';

const useStyles = createUseStyles({
  menuItem: { minHeight: 30 },
  error: {
    color: 'red',
    marginTop: '1rem',
  },
});

interface IProps extends IWithTestId, IWithClassName {
  fullWidth?: boolean;
  value?: any;
  onChange(event: React.ChangeEvent<{ value: unknown }>, child: React.ReactNode): void;
  menuItems: { value: any; label: string }[];
  placeholder?: string;
  title?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  shrink?: boolean;
  error?: boolean | string;
  inputClassname?: string;
}

export const SingleSelectV2Component: FC<IProps> = ({
  className,
  fullWidth = true,
  value = '',
  onChange,
  placeholder,
  shrink,
  menuItems,
  title,
  style,
  disabled,
  error = false,
  inputClassname,
  ...props
}) => {
  const C = useStyles();

  return (
    <>
      <FormControl className={className} disabled={disabled} fullWidth={fullWidth} style={style} error={!!error}>
        {title && <InputLabel shrink={placeholder ? true : shrink}>{title}</InputLabel>}
        <Select
          multiple={false}
          value={value}
          onChange={onChange}
          displayEmpty
          className="select-component"
          SelectDisplayProps={value === '' ? { style: { color: COLORS.GREY_LIGHT } } : undefined}
          inputProps={{ 'data-testid': props['data-testid'], className: inputClassname }}
        >
          {placeholder && (
            <MenuItem value="" disabled>
              {placeholder}{' '}
            </MenuItem>
          )}
          {menuItems.map(({ label, value }) => (
            <MenuItem className={C.menuItem} key={`${label}-${value}`} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Visible visible={!!error && typeof error === 'string'}>
        <div className={C.error}>{error}</div>
      </Visible>
    </>
  );
};
