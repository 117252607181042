import { IPaging, ISorting, ISortingDirection } from '../../common';
import { IModule } from '../types/module';
import { IModuleBatch, IMovementBatch } from '../types/module-batch';
import { IModuleCounters } from '../types/module-counters';
import { IModulePlace } from '../types/module-place';
import { IModuleState } from '../types/module-state';
import { IModuleAppVersion, IModulesFilter } from '../types/modules-filter';
import { IPagedTableState } from '../../types';

export interface IBatchesForReorder {
  batchFrom: IMovementBatch[];
  batchTo: IMovementBatch[];
}

export interface IBatchState {
  batches: IModuleBatch[];
  batchesUsed: IModuleBatch[];
  batchesForReorder: IBatchesForReorder;
}

export interface IModulesState {
  appVersions: IModuleAppVersion[];
  batches: IBatchState;
  list: IPagedTableState<IModule, IModulesFilter>;
  module: IModule | null;
  moduleCounters: IModuleCounters;
  moduleLoading: boolean;
  modules: IModule[];
  modulesFilter: IModulesFilter;
  modulesLoading: boolean;
  modulesPaging: IPaging;
  modulesSorting: ISorting;
  places: IModulePlace[];
  states: IModuleState[];
}

// @ts-ignore
export const initialModulesState: IModulesState = {
  appVersions: [],
  batches: {
    batches: [],
    batchesUsed: [],
    batchesForReorder: {
      batchFrom: [],
      batchTo: [],
    },
  },
  module: null,
  moduleCounters: {
    batches: {},
    critical: 0,
    inMaintenance: 0,
  },
  moduleLoading: true,
  modules: [],
  modulesFilter: {
    appVersions: [],
    batches: [],
    coupled: false,
    critical: null,
    id: '',
    inMaintenance: null,
    places: [],
    signId: '',
    states: [],
  },
  modulesLoading: true,
  modulesPaging: {
    page: 1,
    pageSize: 20,
    totalPages: 0,
    totalRecords: 0,
  },
  modulesSorting: {
    direction: ISortingDirection.asc,
    key: 'id',
  },
  places: [],
  states: [],
};
