import { Paper } from '@material-ui/core';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { RequestLocationsActions } from '../../../store/actions';
import {
  IntakeTypes,
  IPublicDomainIntakeExtended,
  IRequestAclActions,
  IRequestDetailComponent,
  IRequestLocationPatch,
} from '../../../types';
import { getRequestLocationsForId, selectRequestConflictsById } from '../../../store/selectors';
import { CarFreeZoneLicensePlatesContainer } from './license-plates/car-free-zone-license-plates-container';
import { LocationLicensePlatesContainer } from './license-plates/location-license-plates-container';
import { LocationConflicts } from './LocationConflicts.component';
import { LocationInfo } from './location-info.component';
import { PlacementContainer } from './placement/placement.container';
import { SignsContainer } from './signs/signs.container';
import { LocationAttachmentsContainer } from './location/LocationAttachments.container';
import { SelectLocation } from './location/SelectLocation.component';
import { Map } from './location/Map.component';
import { LatLngBounds } from 'leaflet';
import { equalBounds, getBoundsOfBounds } from '../../../common/utils/geojson.util';
import { Visible } from '../../../common/components/layout/Visible.component';
import { Disableable } from '../../../common/components/layout/Disableable.component';
import { getIsAuthorized } from '../../../common/store/user/user.selectors';
import { appUrls } from '../../../common/config/url.constants';

export const Locations: FunctionComponent<IRequestDetailComponent> = ({ requestId, visible = true }) => {
  const dispatch = useDispatch();
  const locations = useSelector(getRequestLocationsForId(requestId));
  const conflicts = useSelector(selectRequestConflictsById)[requestId];
  const { location } = useParams<{ location?: string }>();
  const history = useHistory();
  const canEditAssignedRequest = useSelector(getIsAuthorized([IRequestAclActions.editAssignedRequest]));

  const initialLocationId = locations.length === 1 ? locations[0].id : '';

  const [visibleLocationId, setVisibleLocationId] = useState<number | ''>(
    location ? parseInt(location) : initialLocationId,
  );
  const [bounds, setBounds] = useState<LatLngBounds>();
  const [boundsListenToLocationChange, setBoundsListenToLocationChange] = useState<boolean>(true);

  const allBounds = useCallback(
    () =>
      getBoundsOfBounds(
        // @ts-ignore - TS doesn't see I filter out undefined bounds...
        locations.filter(({ bounds }) => !!bounds).map(({ bounds }) => bounds),
      ),
    [locations],
  );

  const findLocationById = useCallback(
    (id: number | '') => locations.find((location) => id === location.id),
    [locations],
  );

  const visibleLocation = useCallback(
    () => (locations.length === 1 ? locations[0] : findLocationById(visibleLocationId)),
    [findLocationById, visibleLocationId, locations],
  );

  const isDisabled = useCallback(() => {
    if (!canEditAssignedRequest) {
      return true;
    }

    return (
      !!visibleLocation() &&
      visibleLocation()?.type.type === IntakeTypes.carfreezoneintake &&
      !!visibleLocation()!.carFreeZone &&
      !visibleLocation()!.carFreeZone!.active
    );
  }, [visibleLocation, canEditAssignedRequest]);

  const onPatchLocationInfo = useCallback(
    (info: IRequestLocationPatch) => {
      dispatch(RequestLocationsActions.patch(info));
    },
    [dispatch],
  );

  const updateBounds = useCallback(
    (location?: IPublicDomainIntakeExtended) => {
      const newBounds = location?.bounds || allBounds();
      if (
        boundsListenToLocationChange &&
        !equalBounds(newBounds, bounds) &&
        newBounds.getNorthEast() !== newBounds.getSouthWest()
      ) {
        setBounds(newBounds);
      }
    },
    [allBounds, bounds, boundsListenToLocationChange],
  );

  const changeVisibleLocation = useCallback(
    (locationId?: number) => {
      let url;
      if (locationId) {
        url = appUrls.requests.detailLocation(requestId, `${locationId}`);
      } else {
        url = appUrls.requests.detail(requestId);
      }
      history.push(url);
    },
    [history, requestId],
  );

  const onSelectLocation = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const newId = event.target.value as number | undefined;
      changeVisibleLocation(newId);
      setBoundsListenToLocationChange(true);
    },
    [changeVisibleLocation],
  );

  const onSetConflictBounds = useCallback((bounds: LatLngBounds) => {
    setBoundsListenToLocationChange(false);
    setBounds(bounds);
  }, []);

  useEffect(() => {
    const newId = location ? parseInt(location) : initialLocationId;
    if (newId !== visibleLocationId) {
      setVisibleLocationId(newId);
    }
    updateBounds(findLocationById(newId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, findLocationById, updateBounds, initialLocationId]);

  const resetPosition = () => {
    setBounds(bounds ? new LatLngBounds(bounds.getSouthWest(), bounds.getNorthEast()) : allBounds());
  };

  return visible ? (
    <Paper square style={{ marginBottom: 20 }}>
      <SelectLocation locations={locations} onChange={onSelectLocation} selectedLocation={visibleLocation()} />
      <Disableable disabled={isDisabled()}>
        <Map
          bounds={bounds}
          locations={locations}
          conflicts={conflicts}
          onClick={changeVisibleLocation}
          selectedLocation={visibleLocation()}
          resetPosition={resetPosition}
          disabled={isDisabled()}
        />
        <Visible visible={!!conflicts}>
          <LocationConflicts conflicts={conflicts} requestId={requestId} setBounds={onSetConflictBounds} />
        </Visible>
        <Visible visible={!!visibleLocation()}>
          <LocationInfo requestId={requestId} location={visibleLocation()} onPatchLocationInfo={onPatchLocationInfo} />
        </Visible>
      </Disableable>
      <LocationLicensePlatesContainer
        location={visibleLocation()}
        visible={visibleLocation()?.type?.type === IntakeTypes.parkingbanintake}
        data-testid="LocationLicensePlatesContainer"
      />
      <Visible
        visible={visibleLocation()?.type?.type === IntakeTypes.carfreezoneintake && !!visibleLocation()?.carFreeZone}
      >
        <CarFreeZoneLicensePlatesContainer
          requestId={requestId}
          location={visibleLocation()!}
          data-testid="CarFreeZoneLicensePlatesContainer"
        />
      </Visible>
      <Visible visible={visibleLocation()?.type?.type === IntakeTypes.parkingbanintake}>
        <SignsContainer location={visibleLocation()} />
      </Visible>
      <Visible visible={visibleLocation()?.type?.type === IntakeTypes.parkingbanintake}>
        <PlacementContainer location={visibleLocation()} requestId={requestId} />
      </Visible>
      <Visible visible={!!visibleLocation()}>
        <LocationAttachmentsContainer location={visibleLocation()} requestId={requestId} />
      </Visible>
    </Paper>
  ) : null;
};
