import React, { FC, useState } from 'react';
import { translate } from '../../../common/translations/translate';
import { useGeneralStyles } from '../../../style/generalStyles';
import classNames from 'classnames';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { Icon } from '../../../common/components/layout/icon.component';
import ModalDialog from '../../../common/components/modal-dialog/modal-dialog.component';
import { useDispatch, useSelector } from 'react-redux';
import { SgwRequestActions } from '../../../store/actions';
import { getSgwRequest, selectSgwRequestPermitHistory } from '../../../store/selectors/sgwRequest.selectors';
import { Panel } from '../../../common';
import { SgwRequestPermitHistory } from '../SgwRequestPermitHistory/SgwRequestPermitHistory.component';
import classnames from 'classnames';

interface IProps {
  id: string;
}

export const SgwPermitDocumentsPanel: FC<IProps> = ({ id }) => {
  const G = useGeneralStyles();
  const [emailModal, setEmailModal] = useState(false);
  const dispatch = useDispatch();
  const request = useSelector(getSgwRequest(id));
  const permits = useSelector(selectSgwRequestPermitHistory);

  const onDownload = () => window.open(`${request?.permitDownloadUrl}`, '_blank');

  const onSendEmail = () => {
    dispatch(SgwRequestActions.sendEmailPermit({ requestId: id }));
    setEmailModal(false);
  };

  return (
    <Panel
      data-testid="permitDocumentsPanel"
      title={
        <div className={classNames(G.flexRowSpaceBetween)}>
          {translate('sgw.requests.detail.permitDocumentsPanel.title')}
        </div>
      }
    >
      <SgwRequestPermitHistory id={id} />
      <div className={classnames(G.flexRowEnd, G.marginTop20)}>
        <Button.Blue
          disabled={!permits?.length}
          startIcon={<Icon.Email />}
          size="small"
          className={G.marginRight}
          onClick={() => setEmailModal((prevState) => !prevState)}
        >
          {translate('sgw.requests.detail.permitDocumentsPanel.email')}
        </Button.Blue>

        <Button.Blue disabled={!permits?.length} startIcon={<Icon.Download />} size="small" onClick={onDownload}>
          {translate('sgw.requests.detail.permitDocumentsPanel.download')}
        </Button.Blue>

        <ModalDialog
          maxWidth="sm"
          onClose={() => setEmailModal(false)}
          title={translate('sgw.requests.detail.permitDocumentsPanel.modal.title')}
          visible={emailModal}
          okButtonText={translate('sgw.requests.detail.permitDocumentsPanel.modal.sendEmail')}
          onConfirm={onSendEmail}
        >
          {translate('sgw.requests.detail.permitDocumentsPanel.modal.body')}
        </ModalDialog>
      </div>
    </Panel>
  );
};
