import { createReducer } from '@reduxjs/toolkit';
import { StorageActions } from '../../actions';

type TState = string[];

export default createReducer<TState>([], {
  [StorageActions.clear.type]: () => [],
  [StorageActions.upload.type]: (state: TState, { payload }: ReturnType<typeof StorageActions.upload>): TState =>
    state.includes(payload.metadata.id) ? state : state.concat(payload.metadata.id),
  [StorageActions.uploadError.type]: (
    state: TState,
    { payload: { id } }: ReturnType<typeof StorageActions.uploadError>,
  ): TState => (state.includes(id) ? state : state.concat(id)),
  [StorageActions.uploadSuccess.type]: (
    state: TState,
    { payload: { id } }: ReturnType<typeof StorageActions.uploadSuccess>,
  ): TState => (state.includes(id) ? state : state.concat(id)),
  [StorageActions.remove.type]: (
    state: TState,
    { payload: { id } }: ReturnType<typeof StorageActions.remove>,
  ): TState => state.filter((stateId) => stateId !== id),
});
