import {
  ICluster,
  ISgwPermitCondition,
  IConflictGroup,
  ISgwProject,
  IStoredFile,
  MessageState,
  IRequestMessagesList,
  ConflictGroupState,
} from '.';

import { IUser } from '../common/types/user';
import { INormalizedStateById } from '../common/utils/normalized.util';
import { IPagedTableState, IWithId } from './generic.types';
import { ISgwPhase } from './sgwPhase.types';
import { ISgwQuarterCost } from './SgwQuarterCost.types';
import { ISgwRequestHistory } from './sgwRequestHistory.types';
import { IRequestRescheduling } from './SgwReschedulings.types';

export interface ISgwCraneInfo {
  isUsed?: boolean;
  moreThan25Tons?: boolean;
  type?: string | null;
  maxHeight?: string | null;
}

export interface ISgwRequestAcl {
  canAssign?: boolean;
  canUnassign?: boolean;
  canCancel?: boolean;
  canReject?: boolean;
  canReview?: boolean;
  canGrant?: boolean;
  canAssignGisResponsible?: boolean;
  canAssignMobilityAdvisor?: boolean;
  canSendFinalInvoice?: boolean;
  canEditSgwCostCalculation?: boolean;
  sgwRetributionRequired: boolean;
  viewSgwHistoryLog?: boolean;
}

export interface ISgwRequestInfo extends IWithId {
  referenceId?: string;
  bonuNumber?: string | null;
  cluster?: ICluster;
  dateFrom: string;
  dateUntil: string;
  gipodId?: string;
  sgwDistrictIds?: number[];
  mainLocation: ISgwPhaseMainLocation;
  prio: boolean;
  requestorId?: number;
  userRequested?: IUser;
  crane: ISgwCraneInfo;
  sgwProject?: ISgwProject;
  workDescription: string;
  workType: WorkType;
  utilityCompanyName?: string;
  utilityCompanyNameResponsible?: string;
  utilityCompanyPhoneResponsible?: string;
  utilityCompanyEmailResponsible?: string;
}

// TODO extend ISgwRequestInfo ? but cluster is not compatible
export interface ISgwRequest extends IWithId {
  bonuNumber?: string | null;
  conflictState?: ConflictGroupState;
  phases?: string[];
  ssn: string;
  workType: WorkType;
  workDescription: string;
  urgentRequest: boolean;
  dateFrom: string;
  dateUntil: string;
  attachments?: IStoredFile[];
  referenceId?: string;
  mainLocation: ISgwPhaseMainLocation;
  country: string;
  sgwProject?: ISgwProject;
  crane: ISgwCraneInfo;
  messageState?: MessageState;
  permitConditions?: ISgwPermitCondition[];
  permitDownloadUrl: string;
  submittedDate: string;
  cluster: string;
  userRequested?: IUser;
  userResponsible: IUser;
  state: SgwRequestState;
  prio: boolean;
  sgwDistrictIds?: number[];
  requestorId: number;
  acl?: ISgwRequestAcl;
  backofficeNotes: string;
  userGisResponsible?: IUser;
  userMobilityAdvisor?: IUser;
  gisStatus?: string;
  motivationFieldPermit?: string;
  principalId?: number;
  mainContractorId?: number;
  feeResponsibleId?: number;
  signallingResponsibleId?: number;
  hasSgwConflictGroupFlagged: boolean;
  countConflictsWithFeedback?: number;
  countOpenConflicts?: number;
  isFinalInvoiceSent?: boolean;
  utilityCompanyName?: string;
  utilityCompanyNameResponsible?: string;
  utilityCompanyPhoneResponsible?: string;
  utilityCompanyEmailResponsible?: string;
  offsetDays?: number;
}

export interface ISgwRequestBE
  extends Omit<ISgwRequest, 'userResponsible' | 'userGisResponsible' | 'userMobilityAdvisor' | 'permitConditions'> {
  userResponsible: string | null;
  reasonForApproval?: string | null;
  reasonForRejection?: string | null;
  userGisResponsible?: string | null;
  userMobilityAdvisor?: string | null;
  permitConditionIds?: number[];
}

export enum SgwRequestUserResponsibleType {
  userResponsible = 'userResponsible',
  userGisResponsible = 'userGisResponsible',
  userMobilityAdvisor = 'userMobilityAdvisor',
}

export interface ISgwPhaseMainLocation {
  street: string;
  zipCode: string;
  city: string;
  bus?: string;
  streetNumberFrom?: string;
  streetNumberTo?: string;
  streetNumberUnknown?: boolean;
}

export enum WorkType {
  ConstructionPrivate = 'construction_private',
  ConstructionOnToPublicDomain = 'construction_on_to_public_domain',
  GroundWorksToPublicDomain = 'ground_works_to_public_domain',
  RelocationDelivery = 'relocation_delivery',
}

export enum SgwRequestSearchField {
  contactName = 'contactName',
  referenceId = 'referenceId',
  streetName = 'streetName',
  zipCode = 'zipCode',
}

export enum SgwRequestListOverviewTile {
  NEW = 'new',
  URGENT = 'urgent',
  SLEEPING = 'sleeping',
  RESCHEDULE = 'reschedule',
  CHECK_GIS = 'checkGIS',
  IN_REVIEW = 'inReview',
  IN_EFFECT = 'inEffect',
  FINAL_INVOICE = 'finalInvoice',
  FINAL_INVOICE_SENT = 'finalInvoiceSent',
  TODO = 'todo',
}

export enum SgwRequestState {
  draft = 'draft',
  submitted = 'submitted',
  reviewing = 'reviewing',
  canceled = 'canceled',
  permit_granted = 'permit_granted',
  rejected = 'rejected',
  in_effect = 'in_effect',
  permit_ended = 'permit_ended',
  closed = 'closed',
}

export enum SgwRequestTransition {
  submit = 'submit',
  review = 'review',
  haltReview = 'haltReview',
  reject = 'reject',
  approve = 'approve',
  effectuate = 'effectuate',
  close = 'close',
  cancel = 'cancel',
  finalClose = 'finalClose',
}

export enum SgwRequestGisState {
  ready_to_be_checked = 'Klaar voor controle',
  being_checked = 'In controle',
  gis_ok = 'Intekeningen goedgekeurd',
  gis_nok = 'Intekeningen afgekeurd',
}

export interface ISgwRequestOverview extends ISgwRequest {
  fullAddress: string;
}

export interface ISgwRequestCounters {
  checkGIS: number;
  inEffect: number;
  inReview: number;
  new: number;
  reschedule: number;
  sleeping: number;
  urgent: number;
  finalInvoice: number;
  finalInvoiceSent: number;
  todo: number;
}

export interface ISgwRequestsState {
  attachments: ISgwRequestAttachment[];
  attachmentsLoading: boolean;
  counters: ISgwRequestCounters;
  list: IPagedTableState<ISgwRequest, ISgwRequestsFilter>;
  loading: boolean;
  phases: ISgwPhase[];
  request: ISgwRequest | null;
  conflicts: {
    loading: boolean;
    groups: IConflictGroup[];
    messages: INormalizedStateById<IRequestMessagesList>;
  };
  reschedulings: IRequestRescheduling[];
  quarterCost: {
    costs: ISgwQuarterCost[];
    loading: boolean;
  };
  history: ISgwRequestHistory;
  permitHistory: ISgwRequestPermit[];
  offsetDays: number;
}

export interface ISgwRequestCountersFilter {
  myRequests?: string;
  clusters?: string[];
}

export interface ISgwRequestsFilter extends ISgwRequestCountersFilter {
  userResponsible?: number;
  userRequested?: number;
  tile?: string;
  contactName?: string;
  referenceId?: string;
  streetName?: string;
  zipCode?: string;
  activeFrom?: string | null;
  activeUntil?: string | null;
  projectIds?: string[];
  typeOfWork?: string[];
  state?: string[];
  filterOnlyOnReferenceId?: boolean;
}

export interface ISgwRequestPostTransition {
  id: number;
  transition: SgwRequestTransition;
}

export interface ISgwRequestPatchInternalNotes {
  id: string;
  backofficeNotes: string;
  shouldAppend?: boolean;
}

export enum Category {
  signalisationMap = 'Signalisatieplan',
  detourMap = 'Omleidingsplan',
  letterToResidents = 'Bewonersbrief',
  urgentRequest = 'Hoogdringendheidsbewijs',
  feeResponsibleApproval = 'Retributie Akkoord',
  invoice = 'Factuur',
  creditNote = 'Creditnota',
  other = 'Andere',
  knowledge = 'Kennisname',
  advice = 'Advies',
  report = 'Verslag',
  trafficLightMatrix = 'Verkeerslichtenmatrix',
  mail = 'Mail',
  agreement = 'Overeenkomst',
}

export const categories = [
  { label: Category.signalisationMap, value: Category.signalisationMap },
  { label: Category.detourMap, value: Category.detourMap },
  { label: Category.knowledge, value: Category.knowledge },
  { label: Category.letterToResidents, value: Category.letterToResidents },
  { label: Category.urgentRequest, value: Category.urgentRequest },
  { label: Category.feeResponsibleApproval, value: Category.feeResponsibleApproval },
  { label: Category.advice, value: Category.advice },
  { label: Category.mail, value: Category.mail },
  { label: Category.agreement, value: Category.agreement },
  { label: Category.report, value: Category.report },
  { label: Category.trafficLightMatrix, value: Category.trafficLightMatrix },
  { label: Category.invoice, value: Category.invoice },
  { label: Category.creditNote, value: Category.creditNote },
  { label: Category.other, value: Category.other },
];

export interface ISgwRequestAttachment extends IWithId {
  category: Category;
  createdAt: string;
  sgwPhases: ISgwPhase[];
  starred: boolean;
  quarterYear?: number;
  quarterIndex?: Quarter;
  fileName?: string;
  fileStorage: IStoredFile;
}

export interface ISgwRequestCopyAttachment {
  fileName: string;
  sgwPhases: number[];
  category: Category;
}

export enum Quarter {
  ONE = 1,
  TWO,
  THREE,
  FOUR,
}
export interface ISgwQuarter {
  year: number;
  index: Quarter;
}

export interface ISgwRequestPermit {
  filename: string;
  filePath: string;
  timeChanged: string;
  createdBy: string;
}
