import { Grid, TextField } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React, { FC, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Panel, PanelAction } from '../../../common';
import { CheckboxWithLabel } from '../../../common/components/form-fields/checkboxWithLabel.component';
import { translate } from '../../../common/translations/translate';
import { useSgwProjectForm } from '../../../hooks';
import { SgwProjectsActions } from '../../../store/actions';
import { useGeneralStyles } from '../../../style/generalStyles';
import { useHistory } from 'react-router';
import { appUrls } from '../../../common/config/url.constants';
import { useFormStyles } from '../../../style/form.styles';
import { Visible } from '../../../common/components/layout/Visible.component';
import { SubmitButton } from '../../atoms';

interface IProps {
  title: string;
}

export const ProjectForm: FC<IProps> = ({ title }) => {
  const C = useFormStyles();
  const G = useGeneralStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { formMethods, submitProject, id } = useSgwProjectForm();
  const {
    watch,
    register,
    setValue,
    formState: { errors },
  } = formMethods;

  useEffect(() => {
    id && dispatch(SgwProjectsActions.fetch(id));
  }, [dispatch, id]);

  const onCheckChange = (checked: boolean) => setValue('deleted', checked);

  const goToDetail = () => history.push(appUrls.sgw.manage.projects.detail(id));

  return (
    <FormProvider {...formMethods}>
      <form id={'createProject'} onSubmit={submitProject} data-testid="projectForm" noValidate>
        <Panel title={title}>
          <Visible visible={!!id}>
            <PanelAction icon={<Edit />} onClick={goToDetail} data-testid="editButton" />
          </Visible>
          <Grid container spacing={3} className={C.formGrid}>
            <Grid item xs={12}>
              <TextField
                {...register('name', { required: true })}
                label={translate('sgw.projects.columns.name')}
                className={G.fullWidth}
                required
                data-testid="nameField"
                error={!!errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('description', { required: true })}
                label={translate('sgw.projects.columns.description')}
                className={G.fullWidth}
                required
                data-testid="descriptionField"
                error={!!errors.description}
              />
            </Grid>
            <Grid item xs={12}>
              <CheckboxWithLabel
                {...register('deleted')}
                checked={watch('deleted')}
                label={translate('sgw.projects.deactivate')}
                onChange={onCheckChange}
                className={C.checkbox}
              />
            </Grid>
          </Grid>
        </Panel>
        <SubmitButton formId="createProject" data-testid="createButton">
          {translate('sgw.projects.saveProject')}
        </SubmitButton>
      </form>
    </FormProvider>
  );
};
