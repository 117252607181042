import { push } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import { genericErrorHandler, TPayloadAction } from '../../common';
import { appUrls } from '../../common/config/url.constants';
import { translateBatchName, translateMovementBatchName } from '../../common/utils/string.util';
import { IModule } from '../types/module';
import { IMoveModulesRequest } from '../types/module-batch';
import { IModuleUpdate } from '../types/module-update';
import {
  deleteModule,
  fetchBatches,
  fetchBatchesUsed,
  fetchBatchForReorder,
  fetchModule,
  fetchModuleCounters,
  fetchPlaces,
  fetchStates,
  initModuleOverview,
  ModulesActions,
  moveModules,
  setBatches,
  setBatchesForReorder,
  setBatchesUsed,
  setModule,
  setModuleCounters,
  setModuleLoading,
  setPlaces,
  setStates,
  updateModule,
} from './modules.actions';
import { ModulesApi } from './modules.api';
import { setSign } from './signs.actions';
import { ICall, ISelect } from '../../types';
import { selectSign } from './signs.selectors';
import { getModulesPagedApiParams } from './modules.selectors';
import { getPagingFromHeaders } from '../../common/utils/api.util';

function* onFetchModule(action: TPayloadAction<string>): SagaIterator {
  yield put(setModuleLoading(true));
  const response: ICall<typeof ModulesApi.getModule> = yield call(ModulesApi.getModule, action.payload);

  const sigfoxDevice: IModule = response!.data.data;

  if (sigfoxDevice.batch) {
    sigfoxDevice.batch.name = translateBatchName(sigfoxDevice.batch);
  }

  yield all([put(setModule(response!.data.data)), put(setModuleLoading(false))]);
}

function* onUpdateModule(action: TPayloadAction<IModuleUpdate>): SagaIterator {
  const response: ICall<typeof ModulesApi.updateModule> = yield call(ModulesApi.updateModule, action.payload);
  const sigfoxDevice: IModule = response!.data.data;

  if (sigfoxDevice.batch) {
    sigfoxDevice.batch.name = translateBatchName(sigfoxDevice.batch);
  }

  yield put(setModule(sigfoxDevice));
  // if a sign is set on the state, update the module of the sign as well
  const sign: ISelect<typeof selectSign> = yield select(selectSign);
  if (sign) {
    yield put(setSign({ ...sign, sigfoxDevice }));
  }
}

function* onDeleteModule(action: TPayloadAction<string>): SagaIterator {
  yield call(ModulesApi.deleteModule, action.payload);
  yield put(push(appUrls.signs.modules.base));
}

function* onFetchList(): SagaIterator {
  const params: ISelect<typeof getModulesPagedApiParams> = yield select(getModulesPagedApiParams);

  const response: ICall<typeof ModulesApi.fetchList> = yield call(ModulesApi.fetchList, params!);

  yield all([
    put(ModulesActions.list.set(response!.data.data)),
    put(ModulesActions.list.setParams({ paging: getPagingFromHeaders(response as any) })),
  ]);
}

function* onFetchBatches(): SagaIterator {
  const response: ICall<typeof ModulesApi.getBatches> = yield call(ModulesApi.getBatches);

  const batchData = response!.data.data.map((batch) => ({
    ...batch,
    name: translateBatchName(batch),
  }));

  yield put(setBatches(batchData));
}

function* onFetchBatchesUsed(): SagaIterator {
  const response: ICall<typeof ModulesApi.getBatchesUsed> = yield call(ModulesApi.getBatchesUsed);

  const batchData = response!.data.data.map((batch) => ({
    ...batch,
    name: translateBatchName(batch),
  }));

  yield put(setBatchesUsed(batchData));
}

function* onFetchBatchesforReorder(): SagaIterator {
  const response: ICall<typeof ModulesApi.getBatchesForReorder> = yield call(ModulesApi.getBatchesForReorder);

  const batchData = response!.data.data;
  batchData.batchFrom = batchData.batchFrom.map((batch) => ({
    ...batch,
    name: translateMovementBatchName(batch),
  }));

  batchData.batchTo = batchData.batchTo.map((batch) => ({
    ...batch,
    name: batch.dateFrom ? translateMovementBatchName(batch) : batch.name,
  }));

  yield put(setBatchesForReorder(batchData));
}

function* onFetchStates(): SagaIterator {
  const response: ICall<typeof ModulesApi.getStates> = yield call(ModulesApi.getStates);
  yield put(setStates(response!.data.data));
}

function* onFetchPlaces(): SagaIterator {
  const response: ICall<typeof ModulesApi.getPlaces> = yield call(ModulesApi.getPlaces);
  yield put(setPlaces(response!.data.data));
}

function* onFetchModuleCounters(): SagaIterator {
  const response: ICall<typeof ModulesApi.getModuleCounter> = yield call(ModulesApi.getModuleCounter);
  // @ts-ignore The typing used by the api (number) seems incorrect, should be investigated.
  yield put(setModuleCounters(response!.data.data));
}

function* onInitModuleOverview(): SagaIterator {
  yield put(ModulesActions.fetchMetadata());
  // We need this to be sure that all metadata loaded
  yield take(ModulesActions.setMetadata.type);
  yield put(fetchModuleCounters());
}

function* onMoveModules(action: TPayloadAction<IMoveModulesRequest>): SagaIterator {
  yield call(ModulesApi.moveModulesBetweenBatches, action.payload);
  yield put(initModuleOverview());
}

function* onFetchModulesMetadata(): SagaIterator {
  const response: ICall<typeof ModulesApi.getMetadata> = yield call(ModulesApi.getMetadata);
  yield put(ModulesActions.setMetadata(response!.data.data));
}

function* onSetModulesMetadata({ payload }: ReturnType<typeof ModulesActions.setMetadata>): SagaIterator {
  yield put(
    setBatches(
      payload.batches.map((batch) => ({
        ...batch,
        name: translateBatchName(batch),
      })),
    ),
  );
  yield put(
    setBatchesUsed(
      payload.batchesInUse.map((batch) => ({
        ...batch,
        name: translateBatchName(batch),
      })),
    ),
  );
  yield put(setStates(payload.states));
  yield put(setPlaces(payload.places));
  yield put(ModulesActions.setAppVersions(payload.appVersions));
}

export function* modulesSaga(): SagaIterator {
  yield takeLatest(fetchModule.type, genericErrorHandler(onFetchModule));
  yield takeLatest(updateModule.type, genericErrorHandler(onUpdateModule));
  yield takeLatest(deleteModule.type, genericErrorHandler(onDeleteModule));
  yield takeLatest(ModulesActions.list.fetch.type, genericErrorHandler(onFetchList));
  yield takeLatest(moveModules.type, genericErrorHandler(onMoveModules));
  yield takeLatest(fetchBatches.type, genericErrorHandler(onFetchBatches));
  yield takeLatest(fetchBatchesUsed.type, genericErrorHandler(onFetchBatchesUsed));
  yield takeLatest(fetchBatchForReorder.type, genericErrorHandler(onFetchBatchesforReorder));
  yield takeLatest(fetchStates.type, genericErrorHandler(onFetchStates));
  yield takeLatest(fetchPlaces.type, genericErrorHandler(onFetchPlaces));
  yield takeLatest(fetchModuleCounters.type, genericErrorHandler(onFetchModuleCounters));
  yield takeLatest(initModuleOverview.type, genericErrorHandler(onInitModuleOverview));
  yield takeLatest(ModulesActions.fetchMetadata.type, genericErrorHandler(onFetchModulesMetadata));
  yield takeLatest(ModulesActions.setMetadata.type, genericErrorHandler(onSetModulesMetadata));
}
