import { combineReducers } from 'redux';
import { createPagedTableReducer } from '../../../../store/reducers/pagedTable.reducer';
import { CarFreeZonesActions } from '../carFreeZones.actions';
import { IRequestOverview } from '../../../../types';
import { carFreeZoneRequestsInitialFilter } from '../../../../carFreeZone/CarFreeZone.constants';

export default combineReducers({
  list: createPagedTableReducer<IRequestOverview>(
    {
      fetchAction: CarFreeZonesActions.requestList.fetch.type,
      setAllIdsAction: CarFreeZonesActions.requestList.set.type,
      setParamsActions: [CarFreeZonesActions.requestList.fetch.type, CarFreeZonesActions.requestList.setParams.type],
      initialParamsState: carFreeZoneRequestsInitialFilter,
    },
    false,
  ),
});
