import axios from 'axios';
import { CONFIG, IApiPromise, IPagedApiParams } from '../../common';
import { ISgwAdvisingParty, ISgwAdvisingPartyBE } from '../../types/sgwAdvisingParties.types';

export const SgwAdvisingPartiesApi = {
  fetchList: (params: IPagedApiParams): IApiPromise<ISgwAdvisingParty[]> =>
    axios.get(`${CONFIG.backOfficeApi}/v1/sgw/advising_parties`, { params }),
  save: (advisingParty: Partial<ISgwAdvisingPartyBE>): IApiPromise<ISgwAdvisingParty> => {
    const { id, ...data } = advisingParty;
    return id
      ? axios.patch(`${CONFIG.backOfficeApi}/v1/sgw/advising_parties/${id}`, data)
      : axios.post(`${CONFIG.backOfficeApi}/v1/sgw/advising_parties`, advisingParty);
  },
  fetch: (id: string): IApiPromise<ISgwAdvisingParty> =>
    axios.get(`${CONFIG.backOfficeApi}/v1/sgw/advising_parties/${id}`),
};
