export enum CostState {
  informative = 'informative',
  payable = 'payable',
}

export interface ICostBase {
  fixedAdministrationCost: number;
  fixedEmergencyCost: number;
  numberOfDays: number;
  totalCost: number;
  variableCost: number;
}

export interface ICost extends ICostBase {
  id: number;
  costComponents: ICostComponent[];
  state: {
    state: CostState;
  };
}

export interface ICostOverview {
  numberOfDays: number;
  numberOfParkingSpots: number;
  fixedAdministrationCost: string;
  fixedEmergencyCost: string;
  totalCost: string;
  variableCost: string;
}

export interface ICostComponent {
  amount: number;
  numberOfParkingSpots: number;
}
