import { Checkbox, FormControl, Input, InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core';
import * as React from 'react';
import { translate } from '../../translations/translate';

interface IProps {
  id: string;
  label: string;
  data: any[];
  value: any[];
  valueChange: (value: any[]) => void;
  valueProp?: string;
  displayProp?: string;
  keyProp?: string;
  placeholder?: string;
}

export class MultiSelect extends React.Component<IProps> {
  public render(): React.ReactNode {
    return (
      <FormControl fullWidth={true}>
        <InputLabel htmlFor={this.props.id} shrink={this.props.placeholder ? true : undefined}>
          {this.props.label}
        </InputLabel>
        <Select
          multiple={true}
          value={this.getSelectValue()}
          onChange={this.handleChange}
          input={<Input id={this.props.id} />}
          renderValue={this.renderValue}
          className="select-component"
        >
          <MenuItem style={{ padding: 10 }} key="select-all" value="select-all">
            <ListItemText style={{ padding: 0 }} primary={translate('SelectAll')} />
          </MenuItem>
          <MenuItem style={{ padding: 10 }} key="deselect-all" value="deselect-all">
            <ListItemText style={{ padding: 0 }} primary={translate('DeselectAll')} />
          </MenuItem>
          {this.props.data.map((data: any) => (
            <MenuItem style={{ padding: 10 }} key={this.getKeyValue(data)} value={this.getValue(data)}>
              <Checkbox color="primary" checked={this.props.value.indexOf(this.getValue(data)) > -1} />
              <ListItemText style={{ padding: 0 }} primary={this.getDisplayValue(data)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  private getSelectValue = () => {
    return this.props.value.length ? this.props.value : this.props.placeholder ? [this.props.placeholder] : [];
  };

  private renderValue = () => {
    return this.props.value.length
      ? this.props.value
          .map((value: any) => {
            return this.getDisplayValue(this.getData(value));
          })
          .join(', ')
      : this.props.placeholder;
  };

  private handleChange = (e: React.ChangeEvent<any>) => {
    if (e.target.value.indexOf('select-all') >= 0) {
      this.props.valueChange(this.props.data.map(this.getValue));
    } else if (e.target.value.indexOf('deselect-all') >= 0) {
      this.props.valueChange([]);
    } else {
      const value = e.target.value.filter((item: string) => item !== this.props.placeholder || !this.props.placeholder);
      this.props.valueChange(value);
    }
  };

  private getDisplayValue = (value: any) => {
    return this.props.displayProp ? value?.[this.props.displayProp] : value;
  };
  private getKeyValue = (value: any) => (this.props.keyProp ? value?.[this.props.keyProp] : value);
  private getValue = (value: any) => (this.props.valueProp ? value?.[this.props.valueProp] : value);
  private getData = (value: any) =>
    this.props.valueProp ? this.props.data.find((data: any) => data[this.props.valueProp!] === value) : value;
}
