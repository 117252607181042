import { push } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { genericErrorHandler, TPayloadAction } from '../../common';
import { ATOM_HASHES } from '../../common/config/atom.constants';
import { appUrls } from '../../common/config/url.constants';
import { translate } from '../../common/translations/translate';
import { ICall, ISgwPartner, ISgwRequest, SgwPartnerType } from '../../types';
import { urlWithHash } from '../../utils';
import { getRequestFieldByPartnerType } from '../../utils/sgwRequest.util';
import { SgwPartnerActions, SgwRequestActions, SnackBarActions } from '../actions';
import { SgwPartnersApi } from '../api';
import { getSgwPartner } from '../selectors/sgwPartners.selectors';
import { getSgwRequest } from '../selectors/sgwRequest.selectors';

function* onFetchPartner(action: TPayloadAction<string>): SagaIterator {
  try {
    const response: ICall<typeof SgwPartnersApi.fetch> = yield call(SgwPartnersApi.fetch, action.payload);
    yield put(SgwPartnerActions.set(response!.data.data));
  } finally {
    yield put(SgwPartnerActions.setLoading(false));
  }
}

function* onSavePartner({
  payload,
}: TPayloadAction<{ partner: ISgwPartner; requestId?: string; partnerType?: SgwPartnerType }>): SagaIterator {
  const { id, ...partner } = payload.partner;
  const currentPartner: ISgwPartner = yield select(getSgwPartner(id));
  const request: ISgwRequest | undefined = yield select(getSgwRequest(payload?.requestId));

  try {
    const response: ICall<typeof SgwPartnersApi.save> = yield call(SgwPartnersApi.save, {
      ...currentPartner,
      ...partner,
      id,
    });

    yield put(SgwPartnerActions.set(response!.data.data));
    yield put(SgwPartnerActions.setLoading(false));
    yield put(SnackBarActions.setSuccess(translate('sgw.partners.form.saveSuccess')));

    const partnerId = response?.data?.data?.id;
    if (request && payload.partnerType) {
      yield put(
        SgwRequestActions.patch({
          id: request.id,
          [getRequestFieldByPartnerType(payload.partnerType)]: partnerId,
        }),
      );

      // Forcing to refresh
      yield put(push(appUrls.sgw.requests.base));
      yield put(
        push(urlWithHash(appUrls.sgw.requests.detail(`${request.id}`), ATOM_HASHES.partners.open(partnerId, true))),
      );
    }
  } finally {
    yield put(SgwPartnerActions.setLoading(false));
  }
}

export function* sgwPartnersSaga(): SagaIterator {
  yield takeEvery(SgwPartnerActions.fetch.type, genericErrorHandler(onFetchPartner));
  yield takeEvery(SgwPartnerActions.save.type, genericErrorHandler(onSavePartner));
}
