import { push } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { genericErrorHandler, TPayloadAction } from '../../common';
import { appUrls } from '../../common/config/url.constants';
import { translate } from '../../common/translations/translate';
import { getPagingFromHeaders } from '../../common/utils/api.util';
import { ICall, ISelect, ISgwProject } from '../../types';
import { SgwProjectsActions, SnackBarActions } from '../actions';
import { SgwProjectsApi } from '../api';
import { getPagedApiParams, getSgwProject } from '../selectors/sgwProjects.selectors';

function* onFetchProjectList() {
  const params: ISelect<typeof getPagedApiParams> = yield select(getPagedApiParams);
  const response: ICall<typeof SgwProjectsApi.fetchList> = yield call(SgwProjectsApi.fetchList, params!);
  yield all([
    put(
      SgwProjectsActions.list.setParams({
        paging: getPagingFromHeaders(response as any),
      }),
    ),
    put(SgwProjectsActions.list.set(response!.data.data)),
  ]);
}

function* onSaveProject({ payload }: TPayloadAction<ISgwProject>): SagaIterator {
  const { id, ...project } = payload;
  const currentProject: ISgwProject = yield select(getSgwProject(`${id}`));

  try {
    const response: ICall<typeof SgwProjectsApi.save> = yield call(SgwProjectsApi.save, {
      ...currentProject,
      ...project,
      id,
    });

    yield put(SgwProjectsActions.set(response!.data.data));
    yield put(SgwProjectsActions.setLoading(false));
    yield put(SnackBarActions.setSuccess(translate('sgw.projects.saved')));
    yield put(push(appUrls.sgw.manage.projects.detail(`${response!.data.data.id}`)));
  } catch (e: any) {
    const { msgs = [] } = e?.response?.data || {};
    if (msgs.some((msg: any) => msg.type === 'E' && msg.message.includes('Unique constraint violation'))) {
      yield put(SnackBarActions.setFailure(translate('sgw.projects.saveErrorDuplicateName')));
    } else {
      throw e;
    }
  } finally {
    yield put(SgwProjectsActions.setLoading(false));
  }
}

function* onFetch({ payload }: TPayloadAction<string>) {
  const response: ICall<typeof SgwProjectsApi.fetch> = yield call(SgwProjectsApi.fetch, payload);
  yield put(SgwProjectsActions.set(response!.data.data));
  yield put(SgwProjectsActions.setLoading(false));
}

export function* sgwProjectsSaga(): SagaIterator {
  yield takeLatest(SgwProjectsActions.list.fetch.type, genericErrorHandler(onFetchProjectList));
  yield takeLatest(SgwProjectsActions.save.type, genericErrorHandler(onSaveProject));
  yield takeLatest(SgwProjectsActions.fetch.type, genericErrorHandler(onFetch));
}
