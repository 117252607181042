import { default as Leaflet, LatLngBounds } from 'leaflet';
import React, { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Map } from 'leaflet';
import { useSelector } from 'react-redux';
import { isAppFeatureEnabled } from '../../../store/selectors';
import { IMapLayer, MapLayerTypes } from '../../../types';
import { CONFIG } from '../../config/config';
import { BaseMapDefaultLayers, BASE_MAP_BASE_LAYERS, BASE_MAP_OVERLAY_LAYERS } from '../../config/map.config';
import { BaseMapContext } from './BaseMap.context';

interface IProps {
  defaultOverlayLayers?: IMapLayer[];
  defaultSelectedOverlayLayers?: number[];
}

export const BaseMapProvider: FC<PropsWithChildren<IProps>> = ({
  children,
  defaultOverlayLayers = BASE_MAP_OVERLAY_LAYERS,
  defaultSelectedOverlayLayers = BaseMapDefaultLayers.overlayLayers,
}) => {
  const isArcGisEnabled = useSelector(isAppFeatureEnabled.integrateDigipolisArcGis);

  const [selectedBaseLayer, setSelectedBaseLayer] = useState<number>(BaseMapDefaultLayers.baseLayer);
  const [baseLayers, setBaseLayers] = useState<IMapLayer[]>(BASE_MAP_BASE_LAYERS);
  const [overlayLayers, setOverlayLayers] = useState<IMapLayer[]>(defaultOverlayLayers);
  const [selectedOverlayLayers, setSelectedOverlayLayers] = useState<number[]>(defaultSelectedOverlayLayers);
  const mapRef = useRef<Map>(null);

  useEffect(() => {
    const filterOutArcGis = (layer: IMapLayer) =>
      !(
        [CONFIG.arcgisUrl, CONFIG.baseMapUrl, CONFIG.baseViodUrl, `${CONFIG.arcgisUrl}/17`].includes(
          layer?.url || '',
        ) || layer?.type === MapLayerTypes.BASE_MAP
      );

    if (isArcGisEnabled) {
      setBaseLayers(BASE_MAP_BASE_LAYERS);
      setOverlayLayers(defaultOverlayLayers);
    } else {
      setBaseLayers(BASE_MAP_BASE_LAYERS.filter(filterOutArcGis));
      setOverlayLayers(defaultOverlayLayers.filter(filterOutArcGis));
    }
  }, [defaultOverlayLayers, isArcGisEnabled]);

  const selectBaseLayer = (event: Leaflet.LayersControlEvent) => {
    // @ts-ignore - Leaflet type not totally correct
    setSelectedBaseLayer(event.layer.options.id);
  };

  const addOverlay = (event: Leaflet.LayersControlEvent) => {
    // @ts-ignore - Leaflet type not totally correct
    setSelectedOverlayLayers(selectedOverlayLayers.concat(event.layer.options.id));
  };

  const removeOverlay = (event: Leaflet.LayersControlEvent) => {
    // @ts-ignore - Leaflet type not totally correct
    const layerId: number = event.layer.options.id;
    setSelectedOverlayLayers(selectedOverlayLayers.filter((id) => id !== layerId));
  };

  const flyToBounds = (flyToBounds?: LatLngBounds, maxZoom: number = 19) => {
    if (mapRef?.current && flyToBounds) {
      mapRef.current.flyToBounds(flyToBounds, { duration: 0.2, padding: [30, 30], maxZoom });
    }
  };

  return (
    <BaseMapContext.Provider
      value={{
        addOverlay,
        baseLayers,
        flyToBounds,
        mapRef,
        overlayLayers,
        removeOverlay,
        selectedBaseLayer,
        selectedOverlayLayers,
        selectBaseLayer,
        setBaseLayers,
        setOverlayLayers,
      }}
    >
      {children}
    </BaseMapContext.Provider>
  );
};
