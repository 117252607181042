import { ExpansionPanelDetails } from '@material-ui/core';
import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ISgwPhase, ISgwRequestAclActions, ISgwRequestDetailAclActions } from '../../../types';
import { EditButton } from '../../../request/components/details/edit-button.component';
import { COLORS } from '../../../common';
import { translate } from '../../../common/translations/translate';
import { PhaseDetailsContent } from './PhaseDetailsContent.component';
import { PhaseDetailsForm } from './PhaseDetailsForm.component';
import { useAtom, WritableAtom } from 'jotai';
import { useAuthorization, useRequestAuthorization } from '../../../hooks';
import { Visible } from '../../../common/components/layout/Visible.component';
import { PhaseAttachments } from '../../molecules/PhaseAttachments/PhaseAttachments.component';
import classNames from 'classnames';
import { useGeneralStyles } from '../../../style/generalStyles';

interface IProps {
  editModeAtom: WritableAtom<any, any>;
  phase?: ISgwPhase;
  recurringPhaseTemplate?: boolean;
  newPhase?: boolean;
}

const useStyles = makeStyles({
  details: {
    marginLeft: '20px',
    marginRight: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    borderBottom: `2px solid ${COLORS.GREY_LIGHT}`,
    padding: '0px 7px',
  },
});

export const PhaseDetails: FC<IProps> = ({ recurringPhaseTemplate, editModeAtom, phase, newPhase }) => {
  const C = useStyles();
  const G = useGeneralStyles();
  const [editMode, setEditMode] = useAtom(editModeAtom);
  const { isAuthorized: userCanEdit } = useAuthorization(
    [ISgwRequestAclActions.editSgwRequest, ISgwRequestDetailAclActions.editSgwPhaseDetails],
    true,
  );
  const { isAuthorized: userCanViewSgwPhaseDetails } = useAuthorization(
    [ISgwRequestDetailAclActions.viewSgwPhaseDetails],
    true,
  );
  const { isAuthorized: requestIsEditable } = useRequestAuthorization(ISgwRequestDetailAclActions.editSgwPhaseDetails);

  return (
    <ExpansionPanelDetails className={C.details}>
      <div className={C.header}>
        <h3>{translate('sgw.requests.detail.phases.generalInfo.title')}</h3>
        <Visible visible={requestIsEditable && !newPhase}>
          <EditButton
            title={translate('sgw.requests.detail.phases.generalInfo.edit')}
            onClick={() => setEditMode(!editMode)}
          />
        </Visible>
      </div>
      <Visible
        visible={editMode && userCanEdit && requestIsEditable}
        defaultComponent={phase && <PhaseDetailsContent {...phase} />}
      >
        <PhaseDetailsForm
          phase={phase}
          editMode={editModeAtom}
          recurringPhaseTemplate={recurringPhaseTemplate}
          newPhase={newPhase}
        />
      </Visible>
      <div className={classNames(C.header, G.marginTop20)}>
        <h3>{translate('sgw.requests.detail.phases.attachments.title')}</h3>
      </div>
      <Visible visible={userCanEdit || userCanViewSgwPhaseDetails}>
        {phase && <PhaseAttachments id={phase.id} editMode={editMode} />}
      </Visible>
    </ExpansionPanelDetails>
  );
};
