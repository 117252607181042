import { Actions, SyncActionsClass, TableActions } from '../..';
import {
  ICarFreeZone,
  ICarFreeZoneRequestsFilter,
  ICarFreeZonesFilter,
  IExemption,
  IExemptionsFilter,
  IGate,
  IGatesFilter,
  ILinkCarFreeZoneWithTemplateApi,
  IMoovTemplate,
  IRequestOverview,
} from '../../../types';
import { IById } from '../../utils/normalized.util';

class GatesActionsClass extends SyncActionsClass {
  list = new TableActions<IGate, IGatesFilter>('list');
}

class ExemptionsActionsClass extends SyncActionsClass {
  fetch = this.createPayloadAction<string>('fetch');
  list = new TableActions<IExemption, IExemptionsFilter>(`${this.prefix}.list`);
}

class CarFreeZonesActionsClass extends Actions {
  exemptions = new ExemptionsActionsClass(`${this.prefix}.exemptions`);
  fetch = this.createPayloadAction<string>('fetch');
  fetchAll = this.createAction('fetchAll');
  gates = new GatesActionsClass(`${this.prefix}.gates`);
  linkTemplate = this.createPayloadAction<ILinkCarFreeZoneWithTemplateApi>('linkTemplate');
  list = new TableActions<ICarFreeZone, ICarFreeZonesFilter>(`${this.prefix}.list`);
  setAll = this.createPayloadAction<ICarFreeZone[]>('setAll');
  set = this.createPayloadAction<ICarFreeZone>('set');
  setTemplates = this.createPayloadAction<IById<IMoovTemplate[]>>('setTemplates');
  unlinkTemplate = this.createPayloadAction<ILinkCarFreeZoneWithTemplateApi>('unlinkTemplate');
  requestList = new TableActions<IRequestOverview, ICarFreeZoneRequestsFilter>(`${this.prefix}.requestList`);
}

export const CarFreeZonesActions = new CarFreeZonesActionsClass('carFreeZones');
