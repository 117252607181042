import { combineReducers } from 'redux';
import { ISgwNatureOfWork } from '../../../types';
import { createPagedTableReducer } from '../pagedTable.reducer';
import { SgwNatureOfWorksActions } from '../../actions';
import { initialSgwNatureOfWorksState } from '../../state';
import { generateObjectReducer } from '../../../common';

export const natureOfWorksReducer = combineReducers({
  list: createPagedTableReducer<ISgwNatureOfWork>({
    fetchAction: SgwNatureOfWorksActions.list.fetch.type,
    addEntitiesActions: [SgwNatureOfWorksActions.list.set.type],
    addEntityActions: [SgwNatureOfWorksActions.set.type],
    setAllIdsAction: SgwNatureOfWorksActions.list.set.type,
    setParamsActions: [SgwNatureOfWorksActions.list.fetch.type, SgwNatureOfWorksActions.list.setParams.type],
    initialParamsState: initialSgwNatureOfWorksState.list.table,
  }),
  request: generateObjectReducer<ISgwNatureOfWork | null>(
    SgwNatureOfWorksActions.set.type,
    initialSgwNatureOfWorksState.natureOfWork,
  ),
});
