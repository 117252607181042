import axios from 'axios';
import { Point } from 'geojson';
import { LatLng } from 'leaflet';
import { CONFIG, IApiPromise } from '../../common';
import { IGeocode, IGeometry, ISuggestion } from '../../types';

export const GeolocationApi = {
  getArea: (geometry: IGeometry): IApiPromise<{ area: number }> =>
    axios.post(`${CONFIG.generalApi}/v1/geometry_area`, geometry),
  getSuggestions: (query: string): IApiPromise<ISuggestion[]> => {
    return axios.get(`${CONFIG.generalApi}/v1/geolocation/suggestions`, {
      params: { query },
    });
  },
  getGeocode: (address: string): IApiPromise<IGeocode> => {
    return axios.get(`${CONFIG.generalApi}/v1/geolocation/geocode?address=${address}`);
  },
  reverseGeocode: (location: LatLng): IApiPromise<Point> => {
    return axios.get(`${CONFIG.generalApi}/v1/geolocation/reverse`, {
      params: {
        lat: location.lat,
        lon: location.lng,
      },
    });
  },
};
