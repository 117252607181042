import {
  generateBaseActionCreator,
  generatePayloadActionCreator,
  TBaseActionCreator,
  TPayloadActionCreator,
} from '../../common';
import { IReorderingWorkOrder, IReorderingWorkOrderItemChangeOrder } from '../types/reordering-work-order';
import { IReorderingWorkOrderItem } from '../types/reordering-work-order-item';

export const setReorderingLoading: TPayloadActionCreator<boolean> = generatePayloadActionCreator(
  'reordering.setReorderingLoading',
);

export const setReorderingMarkerPopup: TPayloadActionCreator<IReorderingWorkOrderItem | null> =
  generatePayloadActionCreator('reordering.setReorderingMarkerPopup');

export const fetchReorderingWorkOrder: TPayloadActionCreator<number> = generatePayloadActionCreator(
  'reordering.fetchReorderingWorkOrder',
);

export const setReorderingWorkOrder: TPayloadActionCreator<IReorderingWorkOrder | null> = generatePayloadActionCreator(
  'reordering.setReorderingWorkOrder',
);

export const changeOrderReorderingWorkOrder: TPayloadActionCreator<IReorderingWorkOrderItemChangeOrder> =
  generatePayloadActionCreator('reordering.changeOrderReorderingWorkOrder');

export const toggleCheckReorderingWorkOrder: TPayloadActionCreator<IReorderingWorkOrderItem> =
  generatePayloadActionCreator('reordering.toggleCheckReorderingWorkOrder');

export const saveReorderingWorkOrder: TBaseActionCreator = generateBaseActionCreator(
  'reordering.saveReorderingWorkOrder',
);

export const setReorderingHoveredWorkOrderItem: TPayloadActionCreator<IReorderingWorkOrderItem | null> =
  generatePayloadActionCreator('reordering.setReorderingHoveredWorkOrderItem');
