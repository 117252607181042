import { IWorkOrderItem } from '../../../types';
import { WORK_ORDER_ITEM_TYPES } from '../../planning.constants';
import {
  ArrowDownward,
  ArrowUpward,
  CompareArrows,
  Create,
  LocationSearching,
  Notifications,
} from '@material-ui/icons';
import React, { FC } from 'react';

interface IProps {
  woi: IWorkOrderItem;
}
export const WorkOrderItemIcon: FC<IProps> = ({ woi }) => {
  switch (woi.type) {
    case WORK_ORDER_ITEM_TYPES.PICK_UP:
      return <ArrowUpward fontSize="small" />;
    case WORK_ORDER_ITEM_TYPES.DROP_OFF:
      return <ArrowDownward fontSize="small" />;
    case WORK_ORDER_ITEM_TYPES.RETRIEVE:
      return <LocationSearching fontSize="small" />;
    case WORK_ORDER_ITEM_TYPES.REPLACE:
      return <CompareArrows fontSize="small" />;
    case WORK_ORDER_ITEM_TYPES.UPDATE:
      return <Create fontSize="small" />;
    case WORK_ORDER_ITEM_TYPES.NOTIFICATION:
      return <Notifications fontSize="small" />;
    default:
      return null;
  }
};
