import { Actions, TableActions } from '../../common';
import { IUtilityCompany, IUtilityCompanyFilter } from '../../types/utilityCompany.types';

class UtilityCompaniesActionsClass extends Actions {
  list = new TableActions<IUtilityCompany, IUtilityCompanyFilter>(`${this.prefix}.list`);
  setLoading = this.createPayloadAction<boolean>('setLoading');
  set = this.createPayloadAction<IUtilityCompany>('set');
  fetch = this.createPayloadAction<string>('fetch');
  save = this.createPayloadAction<IUtilityCompany>('save');
}

export const UtilityCompanyActions = new UtilityCompaniesActionsClass('utilityCompanies');
