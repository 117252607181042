import React, { FC, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import sanitizeHtml from 'sanitize-html';
import { useGeneralStyles } from '../../../style/generalStyles';

interface IProps {
  onChange?: React.Dispatch<React.SetStateAction<string>>;
  value?: string;
  placeholder?: string;
  editorClassName?: string;
  readOnly?: boolean;
  toolbarHidden?: boolean;
}

const sanitizeConfig = {
  allowedTags: sanitizeHtml.defaults.allowedTags.concat(['ins', 'del']),
};

const toolbarConfig = {
  options: ['inline', 'blockType', 'fontSize', 'list'],
  inline: {
    options: ['bold', 'italic', 'underline', 'strikethrough'],
  },
  blockType: {
    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
  },
};

export const RichEditor: FC<IProps> = ({ onChange, editorClassName, placeholder, value, readOnly, toolbarHidden }) => {
  const G = useGeneralStyles();
  const { contentBlocks, entityMap } = htmlToDraft(value ? value : '');
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks, entityMap)),
  );
  const currentContent = editorState.getCurrentContent();
  const showPlaceholder = currentContent?.hasText() || currentContent?.getBlockMap().first().getType() === 'unstyled';

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
    onChange?.(sanitizeHtml(draftToHtml(convertToRaw(editorState.getCurrentContent())), sanitizeConfig));
  };

  return (
    <div className={G.fullWidth}>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        toolbar={toolbarConfig}
        editorClassName={editorClassName}
        placeholder={showPlaceholder ? placeholder : undefined}
        readOnly={readOnly}
        toolbarHidden={toolbarHidden}
      />
    </div>
  );
};
