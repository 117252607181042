import { Grid, TextField } from '@material-ui/core';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import { COLORS } from '../../../common';
import { translate } from '../../../common/translations/translate';
import { IContactPerson } from '../../../types';

const useStyles = createUseStyles({
  label: {
    paddingTop: '2rem',
    marginTop: '2rem',
    marginBottom: '1rem',
    borderTop: `1px solid ${COLORS.GREY_LIGHT}`,
  },
});

export const ContactPersonFields: FC = () => {
  const C = useStyles();
  const {
    register,
    formState: { errors },
  } = useFormContext<IContactPerson>();
  return (
    <>
      <div className={C.label}>{translate('sgw.partners.form.infoContact')}</div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            {...register('contactFirstName', { required: true })}
            label={translate('sgw.partners.columns.firstName')}
            required
            fullWidth
            error={!!errors?.contactFirstName?.message}
            helperText={errors?.contactFirstName?.message}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            {...register('contactLastName', { required: true })}
            label={translate('sgw.partners.columns.lastName')}
            required
            fullWidth
            error={!!errors?.contactLastName?.message}
            helperText={errors?.contactLastName?.message}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...register('contactPhoneNumber', { required: true })}
            label={translate('sgw.partners.columns.phoneNumber')}
            required
            fullWidth
            error={!!errors?.contactPhoneNumber?.message}
            helperText={errors?.contactPhoneNumber?.message}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...register('contactEmailAddress', { required: true })}
            label={translate('sgw.partners.columns.email')}
            required
            fullWidth
            error={!!errors?.contactEmailAddress?.message}
            helperText={errors?.contactEmailAddress?.message}
          />
        </Grid>
      </Grid>
    </>
  );
};
