import React, { FC, PropsWithChildren, createContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSgwPartner } from '../store/selectors/sgwPartners.selectors';
import { getSgwRequest } from '../store/selectors/sgwRequest.selectors';
import { ISgwPartner, ISgwRequest, SgwPartnerType } from '../types';

interface IPartnerContext {
  requestId: string;
  partnerId?: number;
  partnerType: SgwPartnerType;
  partner?: ISgwPartner;
  request?: ISgwRequest;
  isSameAsRequester: boolean;
  setCreateMode(createMode: boolean): void;
  createMode: boolean;
}

interface IProps {
  requestId: string;
  partnerId: number;
  partnerType: SgwPartnerType;
}

const initialFunction = () => {
  throw new Error('Function not implemented.');
};

export const initialPartnerContextValues: IPartnerContext = {
  requestId: '',
  partnerId: 0,
  partnerType: SgwPartnerType.client,
  isSameAsRequester: false,
  setCreateMode: initialFunction,
  createMode: false,
};

export const PartnerContext = createContext<IPartnerContext>(initialPartnerContextValues);

export const PartnerContextProvider: FC<PropsWithChildren<IProps>> = ({
  children,
  requestId,
  partnerId,
  partnerType,
}) => {
  const [createMode, setCreateMode] = useState<boolean>(false);
  const currentPartner = useSelector(getSgwPartner(partnerId));
  const request = useSelector(getSgwRequest(requestId));
  const isSameAsRequester = partnerType !== SgwPartnerType.requester && partnerId === request?.requestorId;

  const value = {
    requestId,
    partnerId,
    partnerType,
    partner: currentPartner,
    request,
    isSameAsRequester,
    createMode,
    setCreateMode,
  };

  return <PartnerContext.Provider value={value}>{children}</PartnerContext.Provider>;
};
