import { LineString, MultiLineString, MultiPoint, MultiPolygon, Point, Polygon } from 'geojson';

export type IGeometry = LineString | Point | Polygon | MultiLineString | MultiPoint | MultiPolygon;

export enum IGeometryType {
  Point = 'Point',
  LineString = 'LineString',
  Polygon = 'Polygon',
  MultiPoint = 'MultiPoint',
  MultiLineString = 'MultiLineString',
  MultiPolygon = 'MultiPoint',
}
