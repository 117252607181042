import {
  Button,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  FormHelperText,
  IconButton,
  Input,
  Typography,
} from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { ArrowForward, Close, Done } from '@material-ui/icons';
import * as React from 'react';
import { CommonSelect } from '../../../common/components/form-fields/common-select.component';

import { IBatchesForReorder } from '../..';
import { Loader } from '../../../common/components/layout/loader.component';
import { IMovementBatch } from '../../types/module-batch';
import { translate } from '../../../common/translations/translate';
import { Feedback } from '../../../common/components/layout/Feedback.component';

const styles = (theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: 10,
      top: 13,
    },
    title: {
      borderBottom: 1,
      borderBottomColor: '#cccccc',
      borderBottomStyle: 'solid',
      margin: 0,
      padding: 20,
      paddingRight: 80,
    },
  });

export interface IDialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: IDialogTitleProps) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography={true} className={classes.title}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: 50,
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    border: 0,
    borderStyle: 'solid',
    borderTop: 1,
    borderTopColor: '#cccccc',
    margin: 0,
    padding: 20,
  },
}))(MuiDialogActions);

interface IProps {
  fetchBatchForReorder: () => void;
  onClose: () => void;
  onConfirm: (amount: number, batchFrom: IMovementBatch, batchTo: IMovementBatch) => void;
  batches: IBatchesForReorder;
}

interface IState {
  amount: number;
  batchFrom?: IMovementBatch;
  batchTo?: IMovementBatch;
  isConfirm: boolean;
}

export default class MoveModulesDialog extends React.Component<IProps, IState> {
  public state = {
    amount: 1,
    batchFrom: {} as IMovementBatch,
    batchTo: {} as IMovementBatch,
    isConfirm: false,
  };

  public componentDidMount(): void {
    this.props.fetchBatchForReorder!();
  }

  public render(): React.ReactNode {
    return (
      <div style={{ position: 'absolute', backgroundColor: 'white' }}>
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onClose={this.props.onClose}
        >
          {this.props.batches.batchFrom && this.props.batches.batchFrom ? (
            <>
              <DialogTitle id="alert-dialog-title" onClose={this.props.onClose}>
                {translate('MoveModules.Title')}
              </DialogTitle>
              {this.state.isConfirm ? this.renderConfirmScreen() : this.renderMoveScreen()}
            </>
          ) : (
            <Loader />
          )}
        </Dialog>
      </div>
    );
  }

  private renderMoveScreen = (): React.ReactNode => {
    return (
      <>
        <DialogContent>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: 150, padding: 20 }}>
              <Typography>#</Typography>
              <Input
                value={this.state.amount.toString()}
                type={'number'}
                onChange={this.changeAmount}
                style={this.getValidateAmountError() ? { color: 'red' } : undefined}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: 250, padding: 20 }}>
              <Typography>{translate('MoveModules.From')}</Typography>
              <CommonSelect
                valueChange={this.onSelectFrom}
                data={this.props.batches.batchFrom}
                value={this.state.batchFrom}
                keyProp={'id'}
                displayProp={'name'}
              />
              <FormHelperText>
                {this.state.batchFrom.id
                  ? translate('MoveModules.ModulesCount') + ': ' + this.state.batchFrom.modulesCount
                  : ''}
              </FormHelperText>
            </div>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <ArrowForward />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: 250, padding: 20 }}>
              <Typography>{translate('MoveModules.To')}</Typography>
              <CommonSelect
                valueChange={this.onSelectTo}
                data={this.props.batches.batchTo}
                value={this.state.batchTo}
                keyProp={'id'}
                displayProp={'name'}
              />
              <FormHelperText>
                {this.state.batchTo.id
                  ? translate('MoveModules.ModulesCount') + ': ' + this.state.batchTo.modulesCount
                  : ''}
              </FormHelperText>
            </div>
          </div>
          <Feedback.Warning>{translate('MoveModules.maintenanceWarning')}</Feedback.Warning>
        </DialogContent>
        <DialogActions>
          <div style={{ color: 'red', position: 'absolute', left: 20 }}>{this.getValidateAmountError()}</div>
          <Button
            variant="contained"
            color="secondary"
            onClick={this.onAccept}
            disabled={this.getValidateAmountError() || !this.state.batchFrom.id || !this.state.batchTo.id}
          >
            <Done /> {translate('MoveModules.Accept')}
          </Button>
        </DialogActions>
      </>
    );
  };

  private renderConfirmScreen = (): React.ReactNode => {
    return (
      <>
        <DialogContent>
          <Typography>
            U gaat <b>{this.state.amount}</b> modules overzetten van <b>{this.state.batchFrom.name} </b>
            naar <b>{this.state.batchTo.name} </b>.
          </Typography>
          <Typography>{translate('MoveModules.Confirmation')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={this.onConfirm}>
            <Done /> {translate('MoveModules.Confirm')}
          </Button>
          <Button variant="contained" color="primary" style={{ backgroundColor: 'red' }} onClick={this.props.onClose}>
            <Close /> {translate('MoveModules.Decline')}
          </Button>
        </DialogActions>
      </>
    );
  };

  private onSelectFrom = (value: IMovementBatch): void => {
    this.setState({
      batchFrom: value,
    });
  };

  private onSelectTo = (value: IMovementBatch): void => {
    this.setState({
      batchTo: value,
    });
  };

  private onConfirm = (): void => {
    this.props.onConfirm(this.state.amount, this.state.batchFrom, this.state.batchTo);
  };

  private onAccept = (): void => {
    this.setState({
      isConfirm: true,
    });
  };

  private changeAmount = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const value: number = parseInt(event.target.value, 0);
    this.setState({
      amount: value >= 0 ? value : 0,
    });
  };

  private getValidateAmountError = (): any => {
    if (this.state.amount <= 0) {
      return translate('MoveModules.MinAmountError');
    }
    if (this.state.batchFrom && this.state.amount > this.state.batchFrom.modulesCount) {
      return translate('MoveModules.MaxAmountError');
    }
  };
}
