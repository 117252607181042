import { push } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest, throttle } from 'redux-saga/effects';
import { AppActions, genericErrorHandler, TPayloadAction } from '../..';
import { SnackBarActions } from '../../../store/actions';
import { RequestsApi } from '../../../store/api';
import { getRedirectUrlAfterLogin } from '../../../store/selectors';
import { getUserRequestApiParams } from '../../../store/selectors/user-request.selectors';
import { ICall, ISelect } from '../../../types';
import { mapToIUserBE } from '../../../utils';
import { appUrls } from '../../config/url.constants';
import { HOUR_IN_MS } from '../../time-constants';
import { translate } from '../../translations/translate';
import { IUpdatePassword, IUser } from '../../types/user';
import { getPagingFromHeaders } from '../../utils/api.util';
import LocalStorage from '../../utils/local-storage';
import { UserActions } from './user.actions';
import { UserApi } from './user.api';
import { getUsersApiParams } from './user.selectors';

function* onGetUser({ payload: shouldRedirect }: ReturnType<typeof UserActions.fetch>): SagaIterator {
  const response: ICall<typeof UserApi.getUser> = yield call(UserApi.getUser);
  if (!!response?.data.data) {
    LocalStorage.set('userId', `${response.data.data.id}`);
    yield put(UserActions.set(response.data.data));
    if (shouldRedirect) {
      const redirectUrl = yield select(getRedirectUrlAfterLogin);
      yield put(push(redirectUrl));
    }
  }
  yield put(AppActions.setLoading(false));
}

function* onFetchUserById(action: TPayloadAction<string>): SagaIterator {
  const response: ICall<typeof UserApi.fetchUserById> = yield call(UserApi.fetchUserById, action.payload);
  if (!!response?.data) {
    yield put(UserActions.setUser(response.data.data));
  }
}

function* onGetAdminList(): SagaIterator {
  const response: ICall<typeof UserApi.getAdminUsers> = yield call(UserApi.getAdminUsers);
  yield put(UserActions.setAdminList(response!.data.data));
}

function* onGetListByName(action: TPayloadAction<string>): SagaIterator {
  const response: ICall<typeof UserApi.getUsersByName> = yield call(UserApi.getUsersByName, action.payload);
  if (response!.data.data?.length) {
    yield put(UserActions.list.set(response!.data.data));
  }
}

function* onFetchAllRoles(): SagaIterator {
  const response: ICall<typeof UserApi.fetchAllRoles> = yield call(UserApi.fetchAllRoles);
  if (response!.data.data?.length) {
    yield put(UserActions.setAllRoles(response!.data.data));
  }
}

function* onFetchUsersList() {
  try {
    yield call(UserActions.list.setLoading, true);
    const params: ISelect<typeof getUsersApiParams> = yield select(getUsersApiParams);
    const response: ICall<typeof UserApi.fetchList> = yield call(UserApi.fetchList, params);
    yield all([
      put(
        UserActions.list.setParams({
          paging: getPagingFromHeaders(response as any),
        }),
      ),
      put(UserActions.list.set(response!.data.data)),
    ]);
  } finally {
    yield call(UserActions.list.setLoading, false);
  }
}

function* onFetchUserRequestList(): SagaIterator {
  const params: ISelect<typeof getUserRequestApiParams> = yield select(getUserRequestApiParams);
  const response = yield call(RequestsApi.getRequestList, params!);

  yield all([
    put(
      UserActions.requestList.setParams({
        paging: getPagingFromHeaders(response as any),
      }),
    ),
    put(UserActions.requestList.set(response!.data.data)),
  ]);
}

function* onDeactivateUser({ payload }: TPayloadAction<{ deactivate: boolean; id: number }>): SagaIterator {
  const { deactivate, id } = payload;
  const response: ICall<typeof UserApi.deactivateUser> = yield call(
    deactivate ? UserApi.deactivateUser : UserApi.activateUser,
    id,
  );
  yield put(UserActions.setUser(response!.data.data));
}

function* onResetPassword({ payload }: TPayloadAction<number>): SagaIterator {
  yield call(UserApi.resetPassword, payload);
}

function* onSaveUser({ payload }: TPayloadAction<IUser>): SagaIterator {
  const { id, ...user } = payload;

  const response: ICall<typeof UserApi.save> = yield call(
    UserApi.save,
    mapToIUserBE({
      ...user,
      id,
    }),
  );

  yield put(UserActions.setUser(response!.data.data));
  yield put(SnackBarActions.setSuccess(translate('users.saved')));
  yield put(push(appUrls.users.detail(`${response!.data.data.id}`)));
}

function* onUpdatePassword({ payload }: TPayloadAction<IUpdatePassword>): SagaIterator {
  const response: ICall<typeof UserApi.updatePassword> = yield call(UserApi.updatePassword, payload);
  if (response?.status === 200) {
    yield put(SnackBarActions.setSuccess(translate('resetPassword.passwordSaved')));
    yield put(push(appUrls.login.selection));
  } else {
    throw new Error(translate('SomethingWentWrong'));
  }
}

export function* userSaga(): SagaIterator {
  yield takeLatest(UserActions.fetch.type, genericErrorHandler(onGetUser));
  yield throttle(5 * HOUR_IN_MS, UserActions.fetchAdminList.type, genericErrorHandler(onGetAdminList));
  yield takeLatest(UserActions.fetchListByName.type, genericErrorHandler(onGetListByName));
  yield takeLatest(UserActions.fetchAllRoles.type, genericErrorHandler(onFetchAllRoles));
  yield takeLatest(UserActions.list.fetch.type, genericErrorHandler(onFetchUsersList));
  yield takeLatest(UserActions.requestList.fetch.type, genericErrorHandler(onFetchUserRequestList));
  yield takeLatest(UserActions.fetchUserById, genericErrorHandler(onFetchUserById));
  yield takeLatest(UserActions.deactivateUser, genericErrorHandler(onDeactivateUser));
  yield takeLatest(UserActions.resetPassword, genericErrorHandler(onResetPassword));
  yield takeLatest(UserActions.save, genericErrorHandler(onSaveUser));
  yield takeLatest(UserActions.updatePassword, genericErrorHandler(onUpdatePassword));
}
