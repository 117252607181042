import { Checkbox, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import * as I18n from 'i18n-js';
import { FunctionComponent } from 'react';
import * as React from 'react';
import { IWithClassName } from '../../../types';

interface IProps extends IWithClassName {
  fullWidth?: boolean;
  values: any[];
  onChange: (newValues: any) => void;
  menuItems: { value: any; label: string }[];
  placeholder?: string;
  title?: string;
  error?: string;
  style?: React.CSSProperties;
  hideSelectAll?: boolean;
}

export const MultiSelectV2Component: FunctionComponent<IProps> = ({
  fullWidth = true,
  values,
  onChange,
  placeholder,
  menuItems,
  title = '',
  style,
  className,
  error,
}) => {
  const changeMultiple = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string[];
    if (value.indexOf('select-all') >= 0) {
      onChange(menuItems.map(({ value }) => value));
    } else if (value.indexOf('deselect-all') >= 0) {
      onChange([]);
    } else {
      onChange(value);
    }
  };

  const itemValueToLabel = (value: any): string | undefined => {
    const menuItem = menuItems.find((menuItem) => menuItem.value === value);
    return menuItem ? menuItem.label : undefined;
  };

  return (
    <FormControl fullWidth={fullWidth} style={style} className={className}>
      {title && <InputLabel shrink>{title}</InputLabel>}
      <Select
        displayEmpty
        multiple
        value={values}
        onChange={changeMultiple}
        renderValue={() => values.map((value) => itemValueToLabel(value)).join(', ') || placeholder}
        className="select-component"
        SelectDisplayProps={values.length === 0 ? { style: { color: 'grey' } } : undefined}
        MenuProps={{ variant: 'menu' }}
        error={!!error}
      >
        {placeholder && (
          <MenuItem value={[]} disabled>
            {placeholder}
          </MenuItem>
        )}

        <MenuItem key="select-all" value="select-all">
          {I18n.t('SelectAll')}
        </MenuItem>
        <MenuItem key="deselect-all" value="deselect-all">
          {I18n.t('DeselectAll')}
        </MenuItem>

        {menuItems.map(({ label, value }) => (
          <MenuItem key={label} value={value}>
            <Checkbox checked={values.indexOf(value) > -1} />
            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 1000 }}>{label}</div>
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
