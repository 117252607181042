import { Grid, Typography } from '@material-ui/core';
import * as React from 'react';

interface IProps {
  id: string;
  values: Array<{
    subtitle: string;
    title: string;
    value: any;
    disable?: boolean;
  }>;
  value?: any;
  valueChange: (value: any) => void;
  itemsPerRow?: number;
}

export class TileSelect extends React.Component<IProps> {
  public render(): React.ReactNode {
    const columns = 12;
    const itemsPerRow = this.props.itemsPerRow || 5;
    return (
      <div className="tile-select">
        <Grid container={true} spacing={4} justify="center">
          {this.props.values.map((value, index) => {
            const id = `${this.props.id}-${index}`;
            return (
              <Grid item={true} sm={'auto'} style={{ width: `${(100 / columns) * (columns / itemsPerRow)}%` }} key={id}>
                <input
                  className="tile-select__input"
                  id={id}
                  name={this.props.id}
                  type="radio"
                  checked={this.props.value === value.value}
                  readOnly={true}
                />
                <label
                  htmlFor={id}
                  className="tile-select__label"
                  onClick={this.createOnChange(value.value)}
                  style={value.disable ? { background: '#EEE' } : undefined}
                >
                  <Typography variant="h5" align="center" style={{ marginBottom: 10 }}>
                    {value.title}
                  </Typography>
                  <Typography variant="subtitle1" align="center">
                    {value.subtitle}
                  </Typography>
                </label>
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  }

  private createOnChange = (value: any) => () => {
    this.props.valueChange(value);
  };
}
