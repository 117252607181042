import React, { FC } from 'react';
import { Route, Switch } from 'react-router';
import { appUrls } from '../../common/config/url.constants';
import {
  PermitConditionsPage,
  CreatePermitConditionPage,
  DetailPermitConditionPage,
  EditPermitConditionPage,
} from '../pages/sgw/manage';

export const PermitConditionRoutes: FC = () => (
  <Switch>
    <Route exact path={appUrls.sgw.manage.permitConditions.base}>
      <PermitConditionsPage />
    </Route>
    <Route exact path={appUrls.sgw.manage.permitConditions.create}>
      <CreatePermitConditionPage />
    </Route>
    <Route exact path={appUrls.sgw.manage.permitConditions.detail()}>
      <DetailPermitConditionPage />
    </Route>
    <Route exact path={appUrls.sgw.manage.permitConditions.edit()}>
      <EditPermitConditionPage />
    </Route>
  </Switch>
);
