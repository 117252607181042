import { makeStyles } from '@material-ui/core/styles';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { DateFormat, IFormikOption, IWithClassName, IWithFormikProps } from '../../../../types';
import { IPincodeFormikFields, IPincodeManagement, IService, PincodeRenewPeriod } from '../../../../types';
import { LabelValue } from '../../../../common/components/layout/LabelValue.component';
import { Form } from 'formik';
import { Grid } from '@material-ui/core';
import { FormikDateField, FormikSelect, FormikTextFieldNumber } from '../../../../common/components/formikFormFields';
import moment from 'moment';
import { useGeneralStyles } from '../../../../style/generalStyles';
import { CheckboxWithLabel } from '../../../../common/components/form-fields/checkboxWithLabel.component';
import { translate, translateIgnoreTS } from '../../../../common/translations/translate';

const useStyles = makeStyles({
  automaticPincodeRenewalCheckbox: {},
  automaticPincodeRenewalNumber: { width: 30 },
  automaticPincodeRenewalPeriod: {
    width: 165,
    marginLeft: 20,
    marginTop: -16,
  },
  divider: { marginTop: 20 },
  saveButton: {
    marginRight: 10,
  },
  syncWrapper: {
    marginTop: 20,
  },
  form: { display: 'inherit', margin: 10 },
  noUntilDate: {
    marginTop: -5,
    width: 'auto',
    height: 10,
  },
  select: {
    width: 120,
    marginLeft: 20,
    marginTop: -16,
  },
});

interface IProps extends IWithClassName, IWithFormikProps<IPincodeManagement> {
  editMode: boolean;
  service: Partial<IService>;
}

export const PincodeForm: FunctionComponent<IProps> = ({ editMode, formikProps, service }) => {
  const C = useStyles();
  const G = useGeneralStyles();

  const { initialValues: initial, values: current, setFieldValue } = formikProps;

  const [hasUntilDate, setHasUntilDate] = useState<boolean>(!!initial.pincodeUntilDate);
  const [autoRenewal, setAutoRenewal] = useState<boolean>(!!initial.pincodeAutoRenewPeriod);

  const pincodeRenewOptions: IFormikOption[] = [
    { value: PincodeRenewPeriod.d, label: translate('DayDays') },
    { value: PincodeRenewPeriod.w, label: translate('WeekWeeks') },
    { value: PincodeRenewPeriod.m, label: translate('MonthMonths') },
    { value: PincodeRenewPeriod.y, label: translate('YearYears') },
  ];

  const setOrClearFieldValue = useCallback(
    (shouldBeSet: boolean, field: string, value: any) => {
      setFieldValue(field, shouldBeSet ? value : null);
    },
    [setFieldValue],
  );

  const onToggleAutomaticRenewal = useCallback(
    (checked: boolean) => {
      setOrClearFieldValue(checked, IPincodeFormikFields.pincodeAutoRenewValue, initial.pincodeAutoRenewValue);
      setOrClearFieldValue(checked, IPincodeFormikFields.pincodeAutoRenewPeriod, initial.pincodeAutoRenewPeriod);
      setAutoRenewal(checked);

      if (checked) {
        setHasUntilDate(false);
        setFieldValue(IPincodeFormikFields.pincodeUntilDate, null);
        setFieldValue(IPincodeFormikFields.pincodeAutoRenewPeriod, PincodeRenewPeriod.w);
        setFieldValue(IPincodeFormikFields.pincodeAutoRenewValue, 1);
      }
    },
    [initial, setOrClearFieldValue, setFieldValue],
  );

  const onToggleHasUntilDate = useCallback(
    (checked: boolean) => {
      setOrClearFieldValue(
        !checked,
        IPincodeFormikFields.pincodeUntilDate,
        moment(current.pincodeFromDate, DateFormat.reduxStoreDateString)
          .add(3, 'week')
          .format(DateFormat.reduxStoreDateString),
      );

      if (!checked) {
        setAutoRenewal(false);
        setFieldValue(IPincodeFormikFields.pincodeAutoRenewPeriod, null);
        setFieldValue(IPincodeFormikFields.pincodeAutoRenewValue, null);
      }

      setHasUntilDate(!checked);
    },
    [current, setOrClearFieldValue, setFieldValue],
  );

  const pincodeString = (translation: string, value?: string, date?: string) =>
    (value || '/') +
    (date
      ? translateIgnoreTS(`services.detail.pincodeManagement.${translation}`, {
          date: moment(date).format(DateFormat.dateTime),
        })
      : '');

  return (
    <Form className={C.form} noValidate>
      <Grid container spacing={2}>
        <LabelValue label={translate('services.detail.pincodeManagement.currentValue')}>
          {pincodeString('validUntil', service.currentPincode?.value, service.currentPincode?.dateUntil)}
        </LabelValue>
        <LabelValue
          visible={!!service.nextPincode && autoRenewal}
          label={translate('services.detail.pincodeManagement.nextValue')}
        >
          {pincodeString('validFrom', service.nextPincode?.value, service.nextPincode?.dateFrom)}
        </LabelValue>
        <LabelValue label={translate('services.detail.pincodeManagement.fromDate')}>
          <div className={G.flexRowSpaceBetween}>
            <FormikDateField
              name={IPincodeFormikFields.pincodeFromDate}
              readOnly={!editMode}
              dateFormat={DateFormat.reduxStoreDateString}
              maxDate={current.pincodeUntilDate ? moment(current.pincodeUntilDate) : null}
            />
            <CheckboxWithLabel
              onChange={onToggleHasUntilDate}
              checked={!hasUntilDate}
              className={C.noUntilDate}
              readOnly={!editMode}
              label={translate('services.detail.pincodeManagement.noUntilDate')}
            />
          </div>
        </LabelValue>
        <LabelValue visible={hasUntilDate} label={translate('services.detail.pincodeManagement.untilDate')}>
          <FormikDateField
            name={IPincodeFormikFields.pincodeUntilDate}
            readOnly={!editMode}
            dateFormat={DateFormat.reduxStoreDateString}
            minDate={moment(current.pincodeFromDate)}
          />
        </LabelValue>
        <div className={G.fullWidth}>
          <CheckboxWithLabel
            onChange={onToggleAutomaticRenewal}
            checked={autoRenewal}
            className={C.automaticPincodeRenewalCheckbox}
            readOnly={!editMode}
            disabled={hasUntilDate}
            label={translate('services.detail.pincodeManagement.automaticPincodeRenewal')}
          />
        </div>
        <LabelValue
          className={G.flexRow}
          visible={autoRenewal}
          label={translate('services.detail.pincodeManagement.renewEvery')}
        >
          <FormikTextFieldNumber
            className={C.automaticPincodeRenewalNumber}
            name={IPincodeFormikFields.pincodeAutoRenewValue}
            readOnly={!editMode}
            min={1}
            required={autoRenewal}
          />
          <div className={C.automaticPincodeRenewalPeriod}>
            <FormikSelect
              name={IPincodeFormikFields.pincodeAutoRenewPeriod}
              readOnly={!editMode}
              options={pincodeRenewOptions}
              required={autoRenewal}
            />
          </div>
        </LabelValue>
      </Grid>
    </Form>
  );
};
