import AddIcon from '@material-ui/icons/Add';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGeneralStyles } from '../../../../../../style/generalStyles';
import { Container, IPaging, ISorting } from '../../../../../../common';
import { ISgwTrafficMeasure, ISgwTrafficMeasureAclActions, ISgwTrafficMeasuresFilter } from '../../../../../../types';
import { Auth } from '../../../../../atoms';
import { Button } from '../../../../../../common/components/buttons/asign-button-extensions';
import { translate } from '../../../../../../common/translations/translate';
import { GenericTablePanel } from '../../../../../../common/components/table/GenericTablePanel.component';
import { SgwTrafficMeasuresActions } from '../../../../../../store/actions/sgwTrafficMeasures.actions';
import { CheckboxWithLabel } from '../../../../../../common/components/form-fields/checkboxWithLabel.component';
import { useHistory } from 'react-router';
import { appUrls } from '../../../../../../common/config/url.constants';
import {
  getSgwTrafficMeasureList,
  selectListLoading,
  selectTable,
} from '../../../../../../store/selectors/sgwTrafficMeasures.selectors';
import { useFormStyles } from '../../../../../../style/form.styles';

export const TrafficMeasuresPage: FC = () => {
  const C = useFormStyles();
  const G = useGeneralStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { filters, paging, sorting } = useSelector(selectTable);
  const trafficMeasures = useSelector(getSgwTrafficMeasureList);
  const loading = useSelector(selectListLoading);

  useEffect(() => {
    dispatch(SgwTrafficMeasuresActions.list.fetch({}));
  }, [dispatch]);

  const onChangeSorting = (sorting: ISorting) => dispatch(SgwTrafficMeasuresActions.list.fetch({ sorting }));
  const onChangePaging = (paging: IPaging) => dispatch(SgwTrafficMeasuresActions.list.fetch({ paging }));
  const onChangeFiltering = (filters: ISgwTrafficMeasuresFilter) =>
    dispatch(SgwTrafficMeasuresActions.list.fetch({ filters }));
  const onClick = (trafficMeasure: ISgwTrafficMeasure) =>
    history.push(appUrls.sgw.manage.trafficMeasures.detail(`${trafficMeasure.id}`));
  const onCheckboxChange = () => onChangeFiltering({ ...filters, showDeleted: !filters.showDeleted });

  return (
    <Auth acl={ISgwTrafficMeasureAclActions.viewSgwTrafficMeasuresOverview} showUnauthorizedPage>
      <Container>
        <Auth acl={ISgwTrafficMeasureAclActions.createSgwTrafficMeasure}>
          <Button.Green
            className={C.createButton}
            dataTestId="newTrafficMeasure"
            onClick={() => history.push(appUrls.sgw.manage.trafficMeasures.create)}
            startIcon={<AddIcon fontSize={'small'} />}
          >
            {translate('sgw.trafficMeasures.newTrafficMeasure')}
          </Button.Green>
          <CheckboxWithLabel
            checked={!!filters.showDeleted}
            label={translate('sgw.trafficMeasures.list.showDeleted')}
            onChange={onCheckboxChange}
          />
        </Auth>
        <GenericTablePanel<ISgwTrafficMeasure>
          dataTestId="SgwTrafficMeasuresListTable"
          labelUnavailable={translate('sgw.trafficMeasures.list.unavailable')}
          loading={loading}
          onChangeSorting={onChangeSorting}
          onChangePaging={onChangePaging}
          onClick={onClick}
          paging={paging}
          sorting={sorting}
          records={trafficMeasures}
          title={<div className={G.flexRowSpaceBetween}>{translate('sgw.trafficMeasures.list.title')}</div>}
          columnKey="id"
          columns={[
            {
              label: translate('sgw.trafficMeasures.columns.id'),
              name: 'id',
              sortable: true,
            },
            {
              label: translate('sgw.trafficMeasures.columns.name'),
              name: 'name',
              sortable: true,
            },
            {
              label: translate('sgw.trafficMeasures.columns.description'),
              name: 'description',
              sortable: true,
            },
            {
              label: translate('sgw.trafficMeasures.columns.gipodGuid'),
              name: 'gipodGuid',
              sortable: true,
            },
            {
              label: translate('sgw.trafficMeasures.columns.existInGipod'),
              name: 'existsInGipod',
              sortable: true,
              renderer: (existsInGipod: boolean) => translate(existsInGipod ? 'yes' : 'no'),
            },
          ]}
        />
      </Container>
    </Auth>
  );
};
