import { INormalizedState } from '../common/utils/normalized.util';

export interface IUploadedFile {
  name: string;
  key: number;
  id?: string;
  loading?: boolean;
  uploadError?: string;
}

export interface IFileMetadata {
  error?: string;
  id: string;
  loading: boolean;
  name: string;
  uploadId?: string;
}

export interface IFile {
  metadata: IFileMetadata;
  file: File;
}

export interface IFileSelect {
  accept?: string;
  disabled?: boolean;
  onFileSelect?: (file: IFile) => void;
}

interface IFileView {
  onRemove?: (fileName: string) => void;
  onSelect?: (fileName: string) => void;
  selected?: boolean;
}

export interface IFileLink extends IFileView {
  downloadUrl?: string;
  id?: number | string;
  fileName?: string;
  loading?: boolean;
  name: string;
  url?: string;
}

export interface IFileThumbnail extends IFileView {
  file: IStoredFile;
  onClick?: () => void;
}

export interface IFileManage {
  onRemoveFile?: (fileName: string) => void;
  onDownloadZip?: (fileIds: string[]) => void;
  placeholder?: string;
  storedFiles?: IStoredFile[];
  uploadedFiles?: IFileMetadata[];
}

export interface IStoredFile {
  base64url: string;
  createdAt: string;
  downloadUrl: string;
  file: string;
  fileName: string;
  id: number;
  mimeType: string;
  name: string;
  url: string;
}

export enum BulkDownloadTypes {
  notification,
  request,
  requestLocation,
  sgwRequest,
}

export interface IBulkDownload {
  fileIds: string[];
  id: number | string;
  id2?: number | string;
  type: BulkDownloadTypes;
  fileName?: string;
}

export const bulkDownloadUrlPrefix = {
  [BulkDownloadTypes.notification]: '/v1/civilian_notifications/',
  [BulkDownloadTypes.request]: '/v2/requests/',
  [BulkDownloadTypes.requestLocation]: '/v2/requests/',
  [BulkDownloadTypes.sgwRequest]: '/v1/sgw/requests/',
};

export const bulkDownloadUrlPrefix2 = {
  [BulkDownloadTypes.notification]: '',
  [BulkDownloadTypes.request]: '',
  [BulkDownloadTypes.requestLocation]: '/locations/',
  [BulkDownloadTypes.sgwRequest]: '/attachments/',
};

export interface IWithAttachments {
  attachments: IStoredFile[];
}

export interface IStorageState extends INormalizedState<IFileMetadata> {}
