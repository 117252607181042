import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { genericErrorHandler, TPayloadAction } from '../../common';
import { GeolocationApi, RequestsApi } from '../../store/api';
import { IRootState } from '../../root.state';
import { ICall, IMarkerPopup, IWorkOrder, IWorkOrderItem, IWorkOrderItemsFilter, ISelect } from '../../types';
import {
  closeMarkerPopup,
  deleteWorkOrderItem,
  fetchMarkerPopup,
  fetchTeams,
  PlanningActions,
  searchStreet,
  setMapLocation,
  setMarkerPopup,
  setTeams,
  setWorkOrderItems,
  setWorkOrderItemsFilter,
  setWorkOrderItemsLoading,
  setWorkOrders,
  setWorkOrdersLoading,
  updateWorkOrder,
  updateWorkOrderState,
} from './planning.actions';
import { PlanningApi } from './planning.api';
import { WorkOrderItemsFilterStorage } from '../../common/utils/filter-storage';
import { selectWorkOrderItemsFilter } from './planning.selectors';

const getPlanningState = (state: IRootState) => state.planning;

function* onFetchTeams(): SagaIterator {
  const response: ICall<typeof PlanningApi.getTeams> = yield call(PlanningApi.getTeams);
  yield put(setTeams(response!.data.data));
}

function* onFetchWorkOrders(
  action: TPayloadAction<{ filter: IWorkOrderItemsFilter; setLoading: boolean }>,
): SagaIterator {
  if (action.payload.setLoading) {
    yield put(setWorkOrdersLoading(true));
  }

  yield call(onFetchWorkOrdersBase, action.payload.filter);

  if (action.payload.setLoading) {
    yield put(setWorkOrdersLoading(false));
  }
}

export function* onFetchWorkOrdersBase(filter: IWorkOrderItemsFilter): SagaIterator {
  const totalRecords: number = 10000;
  const response: ICall<typeof PlanningApi.getWorkOrders> = yield call(PlanningApi.getWorkOrders, filter, totalRecords);
  WorkOrderItemsFilterStorage.set(filter);
  const items: IWorkOrder[] = response!.data.data;

  yield put(setWorkOrders(items));
}

function* onFetchWorkOrderItems(action: TPayloadAction<IWorkOrderItemsFilter>): SagaIterator {
  yield put(setWorkOrderItemsLoading(true));
  yield call(onFetchWorkOrderItemsBase, action.payload);
  yield put(setWorkOrderItemsLoading(false));
}

export function* onFetchWorkOrderItemsBase(filter: IWorkOrderItemsFilter): SagaIterator {
  yield all([put(setMarkerPopup(null)), put(setWorkOrderItemsFilter(filter))]);

  let woiList: IWorkOrderItem[] = [];
  // if no type selected - the work order list should be empty
  if (filter.types.length > 0) {
    const response: ICall<typeof PlanningApi.getWorkOrderItems> = yield call(PlanningApi.getWorkOrderItems, filter);
    woiList = response!.data.data;
  }

  WorkOrderItemsFilterStorage.set(filter);
  yield put(setWorkOrderItems(woiList));
}

function* onFetchMarkersPopup(action: TPayloadAction<number>): SagaIterator {
  yield put(setMarkerPopup({ workOrderItemToLoad: action.payload }));

  const response: ICall<typeof PlanningApi.getWorkOrderItem> = yield call(PlanningApi.getWorkOrderItem, action.payload);
  const workOrderItem = response!.data.data;

  let responseRequest: ICall<typeof RequestsApi.getRequest> | null = null;
  if (workOrderItem.parkingBanIntake?.permitRequestId) {
    responseRequest = yield call(RequestsApi.getRequest, workOrderItem.parkingBanIntake.permitRequestId);
  }
  const request = responseRequest ? responseRequest!.data.data : null;

  const payload: IMarkerPopup = {
    request,
    workOrderItem,
    workOrderItemToLoad: action.payload,
  } as IMarkerPopup;
  yield put(setMarkerPopup(payload));
}

function* onCloseMarkerPopup() {
  yield put(setMarkerPopup(null));
}

function* onDeleteWorkOrderItem(action: TPayloadAction<IWorkOrderItem>): SagaIterator {
  yield all([put(setWorkOrderItemsLoading(true))]);
  yield call(PlanningApi.deleteWorkOrderItem, action.payload.id);
  const planningState: ISelect<typeof getPlanningState> = yield select(getPlanningState);
  const workOrders = planningState!.workOrders.map((order) => {
    order.workOrderItems = order.workOrderItems!.filter((item) => item.id !== action.payload.id);
    return order;
  });
  const workOrderItems = planningState!.workOrderItems.filter((item) => item.id !== action.payload.id);

  yield put(setWorkOrders(workOrders));
  yield put(setWorkOrderItems(workOrderItems));
  yield all([put(setWorkOrderItemsLoading(false))]);
}

export function* onAssignItemsToWorkOrder({
  payload,
}: ReturnType<typeof PlanningActions.assignItemsToWorkOrder>): SagaIterator {
  yield all([put(setWorkOrderItemsLoading(true)), put(setWorkOrdersLoading(true))]);
  yield call(PlanningApi.assignItemsToWorkOrder, payload.order);

  yield all([call(onFetchWorkOrdersBase, payload.filter), call(onFetchWorkOrderItemsBase, payload.filter)]);
  yield all([put(setWorkOrderItemsLoading(false)), put(setWorkOrdersLoading(false))]);
}

function* onUpdateWorkOrder(action: TPayloadAction<IWorkOrder>): SagaIterator {
  yield put({ type: updateWorkOrderState.type, payload: action.payload });
  yield call(PlanningApi.assignItemsToWorkOrder, action.payload);
}

function* onSearchStreet(action: TPayloadAction<string>): SagaIterator {
  let searchText = action.payload;
  if (searchText) {
    searchText += ', Antwerpen';
    const suggestionsResponse: ICall<typeof GeolocationApi.getSuggestions> = yield call(
      GeolocationApi.getSuggestions,
      searchText,
    );
    const suggestions = suggestionsResponse!.data.data;
    if (suggestions && suggestions.length) {
      const geocodeResponse: ICall<typeof GeolocationApi.getGeocode> = yield call(
        GeolocationApi.getGeocode,
        suggestions[0].label + ', Antwerpen',
      );
      yield put(
        setMapLocation({
          center: [geocodeResponse!.data.data.point.coordinates[1], geocodeResponse!.data.data.point.coordinates[0]],
          zoom: 18,
        }),
      );
    }
  }
}

function* onUpdateWorkOrderState(action: TPayloadAction<IWorkOrder>): SagaIterator {
  const planningState: ISelect<typeof getPlanningState> = yield select(getPlanningState);
  const items = planningState!.workOrders;
  const workOrders = items.map((item: IWorkOrder) => (item.id === action.payload.id ? action.payload : item));
  yield put(setWorkOrders(workOrders));
}

function* onMarkWarningAsIncomplete(action: TPayloadAction<number>): SagaIterator {
  const planningState: ISelect<typeof getPlanningState> = yield select(getPlanningState);
  const workOrders = planningState!.workOrders;

  // find the workOrder which has the workOrderItem with id === action.payload
  const workOrderToUpdate = workOrders.find(({ workOrderItems }) =>
    workOrderItems?.map((item) => item.id).includes(action.payload),
  );

  // map the work order items so the woi to update has isWarningRemoved = true
  if (workOrderToUpdate) {
    const updatedWorkOrder: IWorkOrder = {
      ...workOrderToUpdate,
      workOrderItems:
        workOrderToUpdate.workOrderItems?.map((woi) =>
          woi.id === action.payload ? { ...woi, isWarningRemoved: true, showWarning: false } : woi,
        ) || null,
    };

    // replace the workOrderToUpdate with the updatedWorkOrder
    yield put(
      setWorkOrders(
        workOrders.map((workOrder) => (workOrder.id === workOrderToUpdate.id ? updatedWorkOrder : workOrder)),
      ),
    );
  }

  yield call(PlanningApi.markWarningAsIncomplete, action.payload);

  const filter = yield select(selectWorkOrderItemsFilter);
  yield put(PlanningActions.fetchWorkOrders({ filter, setLoading: false }));
}

export function* planningSaga(): SagaIterator {
  yield takeLatest(fetchTeams.type, genericErrorHandler(onFetchTeams));
  yield takeLatest(PlanningActions.fetchWorkOrders.type, genericErrorHandler(onFetchWorkOrders));
  yield takeLatest(PlanningActions.fetchWorkOrderItems.type, genericErrorHandler(onFetchWorkOrderItems));
  yield takeLatest(fetchMarkerPopup.type, genericErrorHandler(onFetchMarkersPopup));
  yield takeLatest(closeMarkerPopup.type, genericErrorHandler(onCloseMarkerPopup));
  yield takeLatest(deleteWorkOrderItem.type, genericErrorHandler(onDeleteWorkOrderItem));
  yield takeLatest(PlanningActions.assignItemsToWorkOrder.type, genericErrorHandler(onAssignItemsToWorkOrder));
  yield takeLatest(PlanningActions.markWarningAsIncomplete.type, genericErrorHandler(onMarkWarningAsIncomplete));
  yield takeLatest(updateWorkOrder.type, genericErrorHandler(onUpdateWorkOrder));
  yield takeLatest(searchStreet.type, genericErrorHandler(onSearchStreet));
  yield takeLatest(updateWorkOrderState.type, genericErrorHandler(onUpdateWorkOrderState));
}
