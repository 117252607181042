import { Grid, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { Header } from '../../../common/components/layout/Header.component';
import { appUrls } from '../../../common/config/url.constants';
import { translate } from '../../../common/translations/translate';

interface IProps {
  userFullName: string;
}

const useStyles = makeStyles({
  header: {
    marginLeft: '0px',
    marginRight: '20px',
  },
  title: {
    marginLeft: '0px',
    marginRight: '20px',
  },
  container: {
    maxWidth: 'unset',
  },
});

export const UserDetailHeader: FC<IProps> = (props) => {
  const C = useStyles();

  return (
    <Header data-testid="UserDetailHeader" backButtonUrl={appUrls.users.base} containerClassName={C.container}>
      <Grid container className={C.header}>
        <Grid item xs={12}>
          <span className={C.title}>
            {translate('users.details.userLabel')} {props.userFullName}
          </span>
        </Grid>
      </Grid>
    </Header>
  );
};
