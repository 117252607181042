import { FC } from 'react';
import { WMSTileLayer } from 'react-leaflet';
import { MAP_MAX_ZOOM, MAP_SATELLITE_LAYER_URL, MAP_SATELLITE_PARAMS } from '../../config/map.constants';

interface IProps {
  id?: string;
}

export const SatelliteLayer: FC<IProps> = () => (
  <WMSTileLayer format="image/png" maxZoom={MAP_MAX_ZOOM} params={MAP_SATELLITE_PARAMS} url={MAP_SATELLITE_LAYER_URL} />
);
