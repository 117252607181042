import { Grid, makeStyles } from '@material-ui/core';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClearFiltersFab } from '../../../common/components/buttons/ClearFiltersFab';
import { UtilityCompanyActions } from '../../../store/actions';
import { selectTable } from '../../../store/selectors/utilityCompanies.selectors';
import { SearchTextField } from '../../atoms';

const useStyles = makeStyles({
  container: {
    marginBottom: '20px',
    alignItems: 'center',
    paddingRight: '20px',
  },
  clear: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

export const UtilityCompanyFilters: FC = () => {
  const C = useStyles();
  const dispatch = useDispatch();
  const { filters } = useSelector(selectTable);
  const [name, setName] = useState<string | undefined>();

  useEffect(() => {
    if (name !== filters.name) {
      dispatch(UtilityCompanyActions.list.fetch({ filters: { ...filters, name } }));
    }
  }, [dispatch, filters, name]);

  const onChangeName = (event: ChangeEvent<any>) => {
    setName(event.currentTarget.value);
  };

  const resetFilters = () => {
    setName(undefined);
  };

  return (
    <Grid container spacing={2} className={C.container}>
      <Grid item xs={3}>
        <SearchTextField value={name} onChange={onChangeName} />
      </Grid>
      <Grid item xs={2} className={C.clear}>
        <ClearFiltersFab onClick={resetFilters} />
      </Grid>
    </Grid>
  );
};
