import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Search } from '@material-ui/icons';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { MultiSelect } from '../../../common';
import { ClearFiltersFab } from '../../../common/components/buttons/ClearFiltersFab';
import { CheckboxWithLabel } from '../../../common/components/form-fields/checkboxWithLabel.component';
import { selectAllRoles } from '../../../common/store/user/user.selectors';
import { translate } from '../../../common/translations/translate';
import { IUserFilter, UserSourceType } from '../../../common/types/user';
import { IUtilityCompany } from '../../../types/utilityCompany.types';
import { IWithStyle } from '../../../types';
import { getUserSourceTypeOptions } from '../../User.constants';
import { getUtilityCompaniesList } from '../../../store/selectors/utilityCompanies.selectors';

interface IProps extends IWithStyle {
  resetFilters: () => void;
  filters: IUserFilter;
  changeFilters: (filter: IUserFilter) => void;
}

const useStyles = makeStyles({
  root: {
    marginBottom: 20,
  },
  resetButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 22,
  },
  showDeleted: {
    paddingTop: '0 !important',
    paddingLeft: '4px !important',
    marginTop: '-5px !important',
  },
});

export const UsersFilterComponent: FunctionComponent<IProps> = ({ filters, changeFilters, resetFilters }) => {
  const C = useStyles();

  const allUserRoles = useSelector(selectAllRoles);
  const allUtilityCompanies = useSelector(getUtilityCompaniesList);

  const queryFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeFilters({
      ...filters,
      query: event.target.value,
    });
  };

  const sourceFilterChange = (sourceTypes: UserSourceType[]) => {
    changeFilters({
      ...filters,
      sources: sourceTypes,
    });
  };

  const rolesFilterChange = (roles: string[]) => {
    changeFilters({
      ...filters,
      roles,
    });
  };

  const utilityCompaniesFilterChange = (utilityCompanies: IUtilityCompany[]) => {
    changeFilters({
      ...filters,
      utilityCompanyIds: utilityCompanies,
    });
  };

  const showDeletedChange = () => {
    changeFilters({
      ...filters,
      showDeleted: filters.showDeleted ? undefined : true,
    });
  };

  return (
    <Grid container spacing={4} justify="space-between" className={C.root}>
      <Grid item xs={3}>
        <TextField
          value={filters.query}
          onChange={queryFilterChange}
          fullWidth={true}
          id="queryFilter"
          label={translate('users.filters.query')}
          InputProps={{
            endAdornment: <Search />,
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <MultiSelect
          data={getUserSourceTypeOptions()}
          displayProp="label"
          id="sourceFilter"
          label={translate('users.filters.sources')}
          value={filters.sources || []}
          valueChange={sourceFilterChange}
          valueProp="value"
        />
      </Grid>
      <Grid item xs={3}>
        <MultiSelect
          data={allUserRoles || []}
          displayProp="label"
          id="rolesFilter"
          label={translate('users.filters.roles')}
          value={filters.roles || []}
          valueChange={rolesFilterChange}
          valueProp="id"
        />
      </Grid>
      <Grid item xs={2}>
        <MultiSelect
          data={allUtilityCompanies || []}
          displayProp="name"
          id="utilityCompaniesFilter"
          label={translate('users.filters.utilityCompanies')}
          value={filters.utilityCompanyIds || []}
          valueChange={utilityCompaniesFilterChange}
          valueProp="id"
        />
      </Grid>
      <Grid item xs={2} className={C.resetButton}>
        <ClearFiltersFab onClick={resetFilters} />
      </Grid>
      <Grid item xs={12} className={C.showDeleted}>
        <CheckboxWithLabel
          label={translate('users.filters.includeInactive')}
          checked={!!filters.showDeleted}
          onChange={showDeletedChange}
        />
      </Grid>
    </Grid>
  );
};
