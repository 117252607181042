import React, { FunctionComponent } from 'react';
import { Field } from 'formik';
import { Select } from 'formik-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { IFormikOption, IFormikSelect } from '../../../types';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Visible } from '../layout/Visible.component';

const useStyles = makeStyles<Theme, IFormikSelect>({
  disabledOrReadOnly: { color: ({ disabled, readOnly }) => (disabled && !readOnly ? 'gray' : 'black') },
  error: { color: 'red' },
  formControl: { width: '100%' },
});

export const FormikSelect: FunctionComponent<IFormikSelect> = (props) => {
  const {
    error = false,
    errorLabel,
    label,
    name,
    onChange,
    options,
    disabled = false,
    readOnly = false,
    required = false,
  } = props;
  const C = useStyles(props);

  return (
    <FormControl className={C.formControl}>
      <InputLabel className={error ? C.error : undefined} htmlFor={name}>
        {label}
      </InputLabel>
      <Field
        disabled={disabled || readOnly}
        component={Select}
        name={name}
        inputProps={{
          id: name,
          onChange: (event: React.ChangeEvent<IFormikOption>) => onChange?.(event.target),
          className: C.disabledOrReadOnly,
        }}
        required={required}
        error={error}
      >
        {options.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Field>
      <Visible visible={error}>
        <p className={`MuiFormHelperText-root Mui-error Mui-required ${C.error}`}>{errorLabel}</p>
      </Visible>
    </FormControl>
  );
};
