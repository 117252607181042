import { combineReducers } from 'redux';
import carFreeZones from './allCarFreeZones.reducer';
import templates from './templates.reducer';
import requests from './requests.reducer';
import { createPagedTableReducer } from '../../../../store/reducers/pagedTable.reducer';
import { CarFreeZonesActions } from '../carFreeZones.actions';
import { ICarFreeZone, ICarFreeZonesFilter } from '../../../../types';
import gates from './gates.reducer';
import exemptions from './exemptions.reducer';
import { carFreeZonesInitialParams } from '../../../../carFreeZone/CarFreeZone.constants';

export default combineReducers({
  carFreeZones,
  gates,
  exemptions,
  list: createPagedTableReducer<ICarFreeZone, ICarFreeZonesFilter>({
    fetchAction: CarFreeZonesActions.list.fetch.type,
    addEntitiesActions: [CarFreeZonesActions.list.set.type, CarFreeZonesActions.setAll.type],
    setAllIdsAction: CarFreeZonesActions.list.set.type,
    addEntityActions: [CarFreeZonesActions.set.type],
    setParamsActions: [CarFreeZonesActions.list.fetch.type, CarFreeZonesActions.list.setParams.type],
    initialParamsState: carFreeZonesInitialParams,
  }),
  templates,
  requests,
});
