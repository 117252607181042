import {
  Actions,
  generateBaseActionCreator,
  generatePayloadActionCreator,
  IPaging,
  TableActions,
  TBaseActionCreator,
  TPayloadActionCreator,
} from '../../common';
import { ISign } from '../types/sign';
import { ISignCondition } from '../types/sign-condition';
import { ISignCounters } from '../types/sign-counters';
import { ISignData } from '../types/sign-data';
import { ISignDelete } from '../types/sign-delete';
import { ISignLocation } from '../types/sign-location';
import { ISignState } from '../types/sign-state';
import { ISignStateHistory } from '../types/sign-state-history';
import { ISignType } from '../types/sign-type';
import { ISignUpdate } from '../types/sign-update';
import { ISignsFilter } from '../types/signs-filter';
import { ISignsForecast, ISignsWoiForecast } from '../types/signs-forecast';
import { ISignMap, ISignMapMarker, ISignMapPopup, ISignsMapFilter } from '../types/signs-map';

class SignsActionsClass extends Actions {
  fetchSignsByFilter = this.createPayloadAction<ISignsFilter>('fetchSignsByFilter');
  fetchSignsWoiForecast = this.createAction('fetchSignsWoiForecast');
  setSignsWoiForecast = this.createPayloadAction<ISignsWoiForecast[]>('setSignsWoiForecast');
  setSignsWoiForecastLoading = this.createPayloadAction<boolean>('setSignsWoiForecastLoading');
  hideSignOnMapView = this.createPayloadAction<{ hash: string }>('hideSignOnMapView');
  list = new TableActions<ISign, ISignsFilter>(`${this.prefix}.list`);
  setLoading = this.createPayloadAction<boolean>('setLoading');
}

export const SignsActions = new SignsActionsClass('signs');

export const fetchSign: TPayloadActionCreator<string> = generatePayloadActionCreator('signs.fetchSign');

export const updateSign: TPayloadActionCreator<ISignUpdate> = generatePayloadActionCreator('signs.updateSign');

export const generateRetrieveWorkOrderItem: TPayloadActionCreator<ISign> = generatePayloadActionCreator(
  'signs.generateRetrieveWorkOrderItem',
);

export const setSign: TPayloadActionCreator<ISign> = generatePayloadActionCreator('signs.setSign');

export const deleteSign: TPayloadActionCreator<ISignDelete> = generatePayloadActionCreator('signs.deleteSign');

export const setSignGPSLogs: TPayloadActionCreator<ISignData[]> = generatePayloadActionCreator('signs.setSignGPSLogs');

export const fetchSignLocations: TPayloadActionCreator<{ hash: string; paging: IPaging }> =
  generatePayloadActionCreator('signs.fetchSignLocations');

export const setSignLocations: TPayloadActionCreator<ISignLocation[]> =
  generatePayloadActionCreator('signs.setSignLocations');

export const setSignLocationsPaging: TPayloadActionCreator<IPaging> =
  generatePayloadActionCreator('signs.setSignLocationsPaging');

export const setSignActiveLocation: TPayloadActionCreator<ISignLocation | null> =
  generatePayloadActionCreator('signs.setSignActiveLocation');

export const setSignActiveLocationData: TPayloadActionCreator<ISignData[]> = generatePayloadActionCreator(
  'signs.setSignActiveLocationData',
);

export const setSignStateHistory: TPayloadActionCreator<ISignStateHistory[]> =
  generatePayloadActionCreator('signs.setSignStateHistory');

export const setSignLoading: TPayloadActionCreator<boolean> = generatePayloadActionCreator('signs.setSignLoading');

export const fetchSignsFilterData: TBaseActionCreator = generateBaseActionCreator('signs.fetchSignsFilterData');

export const setSignsFilterDataLoaded: TPayloadActionCreator<boolean> = generatePayloadActionCreator(
  'signs.setSignsFilterDataLoaded',
);

export const setSignConditions: TPayloadActionCreator<ISignCondition[]> =
  generatePayloadActionCreator('signs.setSignConditions');

export const setSignStates: TPayloadActionCreator<ISignState[]> = generatePayloadActionCreator('signs.setSignStates');

export const setSignTypes: TPayloadActionCreator<ISignType[]> = generatePayloadActionCreator('signs.setSignTypes');

export const fetchSignCounters: TBaseActionCreator = generateBaseActionCreator('signs.fetchSignCounters');

export const setSignCounters: TPayloadActionCreator<ISignCounters> =
  generatePayloadActionCreator('signs.setSignCounters');

export const fetchSignsForecast: TBaseActionCreator = generateBaseActionCreator('signs.fetchSignsForecast');

export const setSignsForecast: TPayloadActionCreator<ISignsForecast[]> =
  generatePayloadActionCreator('signs.setSignsForecast');

export const setSignsForecastLoading: TPayloadActionCreator<boolean> = generatePayloadActionCreator(
  'signs.setSignsForecastLoading',
);

export const bulkDeleteSign: TPayloadActionCreator<string[]> = generatePayloadActionCreator('signs.bulkDeleteSign');

export const setSignErrors: TPayloadActionCreator<string[] | null> = generatePayloadActionCreator('signs.setSignError');

export const fetchSignsMap: TPayloadActionCreator<ISignsMapFilter> =
  generatePayloadActionCreator('signs.fetchSignsMap');

export const setSignsMap: TPayloadActionCreator<ISignMap | null> = generatePayloadActionCreator('signs.setSignsMap');

export const fetchSignsMapPopup: TPayloadActionCreator<string> =
  generatePayloadActionCreator('signs.fetchSignMarkerPopup');

export const setSignsMapPopup: TPayloadActionCreator<ISignMapPopup | null> =
  generatePayloadActionCreator('signs.setSignsMapPopup');

export const generateRetrieveWorkOrderItemFromSignMap: TPayloadActionCreator<ISignMapMarker> =
  generatePayloadActionCreator('signs.generateRetrieveWorkOrderItemFromSignMap');
