import React, { FC } from 'react';
import { Container } from '../../../../common';
import { Header } from '../../../../common/components/layout/Header.component';
import { appUrls } from '../../../../common/config/url.constants';
import { translate } from '../../../../common/translations/translate';
import { useFormStyles } from '../../../../style/form.styles';
import { IUtilityCompanyAclActions } from '../../../../types';
import { Auth } from '../../../atoms';
import { UtilityCompanyForm } from '../../../forms';

export const CreateUtilityCompanyPage: FC = () => (
  <Auth acl={IUtilityCompanyAclActions.createUtilityCompany} showUnauthorizedPage>
    <Header backButtonUrl={appUrls.utilityCompanies.base}>{translate('utilityCompanies.newUtilityCompany')}</Header>
    <Container className={useFormStyles().container}>
      <UtilityCompanyForm title={translate('utilityCompanies.newUtilityCompanyDetails')} />
    </Container>
  </Auth>
);
