import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import { useAtom } from 'jotai';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Panel } from '../../../common';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { Visible } from '../../../common/components/layout/Visible.component';
import ModalDialog from '../../../common/components/modal-dialog/modal-dialog.component';
import { translate } from '../../../common/translations/translate';
import { SgwRequestActions } from '../../../store/actions';
import {
  getSgwRequest,
  getTotalRequesCorrection,
  getTotalRequesCost,
  selectSgwQuarterCosts,
  selectSgwQuarterCostsLoading,
} from '../../../store/selectors/sgwRequest.selectors';
import { useGeneralStyles } from '../../../style/generalStyles';
import { useSpacingStyles } from '../../../style/spacing.styles';
import { IRouterWithId } from '../../../types';
import { IntakeForm, intakeModalAtom } from '../../forms/IntakeForm/IntakeForm.component';
import { useStyles } from './RetributionTable.styles';
import { RetributionTableHeader } from './RetributionTableHeader.component';
import { RetributionTableQuarter } from './RetributionTableQuarter.component';
import { LoaderBlur } from '../../../common/components/layout/LoaderBlur.component';

export const RetributionTable: FC = () => {
  const G = { ...useGeneralStyles(), ...useSpacingStyles() };
  const C = useStyles();
  const dispatch = useDispatch();
  const { id: sgwRequestId } = useParams<IRouterWithId>();
  const quarterCosts = useSelector(selectSgwQuarterCosts);
  const quarterCostsLoading = useSelector(selectSgwQuarterCostsLoading);
  const totalCost = useSelector(getTotalRequesCost());
  const totalRequesCorrection = useSelector(getTotalRequesCorrection());
  const [finalInvoiceModal, setFinalInvoiceModal] = useState(false);
  const [intakeModal, setIntakeModal] = useAtom(intakeModalAtom);
  const request = useSelector(getSgwRequest(sgwRequestId));
  const form = 'intakeForm';

  useEffect(() => {
    sgwRequestId && dispatch(SgwRequestActions.quarterCost.fetch(sgwRequestId));
  }, [dispatch, sgwRequestId]);

  const sendFinalInvoice = () => {
    dispatch(SgwRequestActions.sendFinalInvoice(`${sgwRequestId}`));
    setFinalInvoiceModal(false);
  };

  const allInvoiced = !quarterCosts.some((x) => !x.invoiced);
  const hasCorrections = quarterCosts.some((x) => !!x.quarterCorectionCost);

  return (
    <Panel className={G.marginTop.MD} title={<span>{translate('sgw.requests.detail.retributionTable.title')}</span>}>
      <LoaderBlur loading={quarterCostsLoading}>
        <RetributionTableHeader hasCorrections={hasCorrections} />
        {quarterCosts.map((quarterCost) => (
          <RetributionTableQuarter
            hasCorrections={hasCorrections}
            {...quarterCost}
            key={`${quarterCost.quarterYear}-${quarterCost.quarterIndex}`}
          />
        ))}
        <Grid spacing={2} container justify="flex-end">
          <Grid item xs={hasCorrections ? 2 : 1}>
            <div className={allInvoiced ? C.invoicedQuarterTotal : C.quarterTotal}>
              {' '}
              {`${translate('sgw.requests.detail.retributionTable.columns.total')}:`}
            </div>
          </Grid>
          <Grid item xs={1}>
            <div className={allInvoiced ? C.invoicedQuarterTotal : C.quarterTotal}>
              {`${translate('sgw.requests.detail.retributionTable.currency')} ${totalCost.toFixed(2)}`}
            </div>
          </Grid>
          <Visible visible={hasCorrections}>
            <Grid item xs={1}>
              <div className={allInvoiced ? C.invoicedQuarterTotal : C.quarterTotal}>
                {`${translate('sgw.requests.detail.retributionTable.currency')} ${totalRequesCorrection.toFixed(2)}`}
              </div>
            </Grid>
          </Visible>
        </Grid>
        <Visible visible={hasCorrections}>
          <Grid spacing={2} container justify="flex-end">
            <Grid item xs={2}>
              <div className={allInvoiced ? C.invoicedQuarterTotal : C.quarterTotal}>
                {' '}
                {`${translate('sgw.requests.detail.retributionTable.columns.totalWithCorrection')}:`}
              </div>
            </Grid>
            <Grid item xs={1}>
              <div className={allInvoiced ? C.invoicedQuarterTotal : C.quarterTotal}>
                {`${translate('sgw.requests.detail.retributionTable.currency')} ${(
                  totalCost + totalRequesCorrection
                ).toFixed(2)}`}
              </div>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </Visible>
        <div className={classNames(G.flexRowEnd, G.marginTop.SM)}>
          <Visible visible={!!request?.acl?.canEditSgwCostCalculation}>
            <Button.Blue className={G.marginRight.XS} onClick={() => setIntakeModal(true)}>
              {translate('sgw.requests.detail.retributionTable.editIntake')}
            </Button.Blue>
          </Visible>

          <Visible visible={!!request?.acl?.canSendFinalInvoice}>
            <Button.Gray
              onClick={() => setFinalInvoiceModal(true)}
              disabled={request?.isFinalInvoiceSent}
              className={G.marginLeft.XS}
            >
              {translate('sgw.requests.detail.retributionTable.sendFinalInvoiceButton')}
            </Button.Gray>
          </Visible>
        </div>
      </LoaderBlur>
      <ModalDialog
        onClose={() => setIntakeModal(false)}
        title={translate('sgw.requests.detail.retributionTable.intakeModalTitle')}
        visible={intakeModal}
        okButtonText={translate('sgw.requests.detail.retributionTable.confirmIntake')}
        form={form}
      >
        <IntakeForm sgwRequestId={sgwRequestId} />
      </ModalDialog>

      <ModalDialog
        onClose={() => setFinalInvoiceModal(false)}
        title={translate('sgw.requests.detail.retributionTable.finalInvoiceModalTitle')}
        visible={finalInvoiceModal}
        okButtonText={translate('sgw.requests.detail.retributionTable.sendFinalInvoiceButton')}
        onConfirm={sendFinalInvoice}
      >
        {translate('sgw.requests.detail.retributionTable.sendFinalInvoiceInfo')}
      </ModalDialog>
    </Panel>
  );
};
