import { Actions, TableActions } from '../../common';
import { ISgwPartner, SgwPartnerType } from '../../types';

class SgwPartnersActionsClass extends Actions {
  fetch = this.createPayloadAction<string>('fetch');
  set = this.createPayloadAction<ISgwPartner>('set');
  setLoading = this.createPayloadAction<boolean>('setLoading');
  list = new TableActions<ISgwPartner>(`${this.prefix}.list`);
  save = this.createPayloadAction<{ partner: ISgwPartner; requestId?: string; partnerType?: SgwPartnerType }>('save');
}

export const SgwPartnerActions = new SgwPartnersActionsClass('sgwPartners');
