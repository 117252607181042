import { makeStyles } from '@material-ui/core/styles';
import I18n from 'i18n-js';
import * as React from 'react';
import { FunctionComponent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { CONFIG } from '../../../common';
import { FileBox } from '../../../common/components/attachment';
import { InfoText } from '../../../common/components/layout/InfoText.component';
import { PanelTitle } from '../../../common/components/panel/panel-title.component';
import { RequestActions, StorageActions } from '../../../store/actions';
import { getEditableFieldsIncludes, getLocalStorageFiles, getRequestAttachments } from '../../../store/selectors';
import { IFile, RequestCreateField } from '../../../types';

const useStyles = makeStyles({
  root: { marginBottom: '2em' },
  info: {
    color: '#9e9e9e',
  },
});

interface IProps {
  onAddFile: (id: string) => void;
  localIds: string[];
}

export const AttachmentsComponent: FunctionComponent<IProps> = ({ onAddFile, localIds }) => {
  const C = useStyles();
  const dispatch = useDispatch();
  const localStorageFiles = useSelector(getLocalStorageFiles(localIds));
  const { id: requestId } = useParams<{ id: string }>();
  const requestAttachments = useSelector(getRequestAttachments(requestId));

  const _onAddFile = useCallback(
    (file: IFile) => {
      dispatch(StorageActions.upload(file));
      onAddFile(file.metadata.id);
    },
    [dispatch, onAddFile],
  );

  const _onRemoveFile = useCallback(
    (fileToRemove: string) => {
      const existingRequestAttachment = requestAttachments.find(({ fileName }) => fileName === fileToRemove);
      const existingStorageAttachment = localStorageFiles.find(({ id }) => id === fileToRemove);

      existingRequestAttachment &&
        dispatch(RequestActions.removeFile({ id: requestId, fileName: existingRequestAttachment.fileName }));
      existingStorageAttachment && dispatch(StorageActions.remove({ id: existingStorageAttachment.id }));
    },
    [dispatch, requestId, requestAttachments, localStorageFiles],
  );

  return (
    <div className={C.root}>
      <PanelTitle>{I18n.t('Requests.Create.Attachments.Title')}</PanelTitle>
      <InfoText className={C.info}>
        {I18n.t(`Requests.Create.Attachments.Info`)}
        <a href={`mailto:${CONFIG.deLijnMailAddress}`}>{CONFIG.deLijnMailAddress} </a>
        {I18n.t(`Requests.Create.Attachments.PleaseAddAttachmentHere`)}
      </InfoText>
      <FileBox
        disabled={!useSelector(getEditableFieldsIncludes(RequestCreateField.attachments))}
        storedFiles={requestAttachments}
        uploadedFiles={localStorageFiles}
        onFileSelect={_onAddFile}
        onRemoveFile={_onRemoveFile}
      />
    </div>
  );
};
