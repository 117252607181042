import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSgwRequestAssignStatus, selectSgwRequest } from '../../../store/selectors/sgwRequest.selectors';
import { SgwRequestState, SgwRequestTransition } from '../../../types';
import { translate } from '../../../common/translations/translate';
import { ModalButton } from '../../../common/components/buttons/modal-button';
import { makeStyles } from '@material-ui/core';
import { SgwRequestActions } from '../../../store/actions';

const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '15px',
  },
});

export const ReviewButton: FC = () => {
  const C = useStyles();
  const dispatch = useDispatch();
  const request = useSelector(selectSgwRequest);
  const { allowEditRequest } = useSelector(getSgwRequestAssignStatus);
  const showApproveAndRejectButton = allowEditRequest && !!request && request.state === SgwRequestState.reviewing;
  const showReviewButton: boolean = !!request && !!request.acl?.canReview;

  const onReviewRequest = () => postTransition(SgwRequestTransition.review);
  const onRejectRequest = () => postTransition(SgwRequestTransition.reject);
  const onApproveRequest = () => postTransition(SgwRequestTransition.approve);

  const postTransition = (state: SgwRequestTransition) => {
    request?.id && dispatch(SgwRequestActions.postTransition({ id: request.id, transition: state }));
  };

  return (
    <div data-testid="requestReview" className={C.root}>
      <ModalButton.Review
        data-testid="requestReviewReviewButton"
        onConfirm={onReviewRequest}
        visible={showReviewButton}
        title={translate('ModalButton.review')}
        buttonTitle={translate('ModalButton.review')}
        shouldShowModal={false}
      />
      <ModalButton.Reject
        data-testid="requestReviewRejectButton"
        onConfirm={onRejectRequest}
        visible={showApproveAndRejectButton}
        title={translate('ModalButton.Request.reject')}
        buttonTitle={translate('ModalButton.reject')}
        disabled={!request?.acl?.canReject}
        needsReason={false}
        modalBody={translate('sgw.requests.detail.rejectConfirmation')}
      />
      <ModalButton.Approve
        data-testid="requestReviewApproveButton"
        disabled={!request?.acl?.canGrant}
        onConfirm={onApproveRequest}
        visible={showApproveAndRejectButton}
        title={translate('ModalButton.Request.approve')}
        buttonTitle={translate('ModalButton.approve')}
        needsReason={false}
        modalBody={translate('sgw.requests.detail.approveConfirmation')}
      />
    </div>
  );
};
