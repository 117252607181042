import { Grid, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { useCallback } from 'react';
import * as React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { SingleSelectV2Component } from '../../../common/components/form-fields/single-select-v2.component';
import { translate } from '../../../common/translations/translate';

interface IProps<T> {
  title: string;
  searchField: T;
  allSearchFields: { value: T; label: string }[];
  updateSearchField: (e: React.ChangeEvent<{ value: unknown }>) => void;
  searchValue: string;
  updateSearchValue: (e: React.ChangeEvent<{ value: unknown }>) => void;
  saveSearchValue: () => void;
  error?: boolean;
}

export const SearchTextComponent = <T,>({
  error = false,
  title,
  searchField,
  allSearchFields,
  updateSearchField,
  searchValue,
  updateSearchValue,
  saveSearchValue,
}: IProps<T>) => {
  const onSearch = useCallback(() => {
    !error && saveSearchValue();
  }, [error, saveSearchValue]);

  const onKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      e.key === 'Enter' && onSearch();
    },
    [onSearch],
  );

  return (
    <Grid container direction={'row'} spacing={1} alignItems="flex-end">
      <Grid item xs={5}>
        <SingleSelectV2Component
          title={title}
          value={searchField}
          onChange={updateSearchField}
          menuItems={allSearchFields}
          placeholder={translate('searching')}
        />
      </Grid>
      <Grid item xs={7}>
        <TextField
          data-testid="SearchInput"
          error={error}
          onKeyUp={onKeyUp}
          value={searchValue}
          fullWidth
          onChange={updateSearchValue}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={onSearch}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};
