import { useForm } from 'react-hook-form';
import { IRouterWithId, IUtilityCompany } from '../../types';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UtilityCompanyActions } from '../../store/actions';
import { useParams } from 'react-router';
import { getUtilityCompany } from '../../store/selectors/utilityCompanies.selectors';

export const useUtilityCompanyForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams<IRouterWithId>();
  const initialValues = useSelector(getUtilityCompany(id));

  const formMethods = useForm<IUtilityCompany>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: { ...initialValues },
  });

  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    initialValues && reset(initialValues);
  }, [initialValues, reset]);

  const _onSubmit = useCallback(
    (values: IUtilityCompany) => {
      dispatch(UtilityCompanyActions.save(values));
    },
    [dispatch],
  );

  const submitUtilityCompany = handleSubmit(_onSubmit);

  return { formMethods, submitUtilityCompany };
};
