import { Action } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { TPayloadAction } from '../..';
import { EXCLUDED_ERROR_STATUSES } from '../../error-constants';
import { handleError, handleNetworkError, handleOtherError, handleServerError } from './errors.actions';
import { getIsLoggedIn } from '../../../store/selectors';
import { captureException } from '@sentry/react';

export function* errorHandler(e: any): SagaIterator {
  console.log(e);
  captureException(new Error(e.message));

  const isLoggedIn = yield select(getIsLoggedIn);

  // AS-4992: exclude 401 and 403 from the general error handler
  if (!(e.response && EXCLUDED_ERROR_STATUSES.includes(e.response.status))) {
    yield put(handleError(e));
  }

  if (e.response) {
    yield put(handleServerError(e));
  } else if (e.message === 'Network Error' || e.code === 'ECONNABORTED') {
    yield put(handleNetworkError(e));
  } else if (isLoggedIn) {
    yield put(handleOtherError(e));
  }
}

export const genericErrorHandler = (saga: (...args: any[]) => SagaIterator, ...args: any[]) =>
  function* handleApp(action: Action | TPayloadAction<any>): SagaIterator {
    try {
      yield call(saga, action, args);
    } catch (e: any) {
      //@ts-ignore TODO: fix this
      yield call(errorHandler, e, args);
    }
  };
