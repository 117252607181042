import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Container, PanelAction } from '../../../../../../common';
import { Header } from '../../../../../../common/components/layout/Header.component';
import { appUrls } from '../../../../../../common/config/url.constants';
import { translate } from '../../../../../../common/translations/translate';
import { getNatureOfWorks } from '../../../../../../store/selectors/sgwNatureOfWorks.selectors';
import { IRouterWithId, ISgwNatureOfWorksAclActions } from '../../../../../../types';
import { Auth } from '../../../../../atoms';
import { Grid, Paper } from '@material-ui/core';
import { LabelValue } from '../../../../../../common/components/layout/LabelValue.component';
import { Edit } from '@material-ui/icons';
import { PanelContent } from '../../../../../../common/components/panel/panel-content.component';
import { SgwNatureOfWorksActions } from '../../../../../../store/actions';
import { useFormStyles } from '../../../../../../style/form.styles';

export const DetailNatureOfWorksPage: FC = () => {
  const C = useFormStyles();
  const dispatch = useDispatch();
  const { id } = useParams<IRouterWithId>();
  const natureOfWorks = useSelector(getNatureOfWorks(id));
  const history = useHistory();

  useEffect(() => {
    dispatch(SgwNatureOfWorksActions.list.fetch({}));
  }, [dispatch]);

  const onClick = () => history.push(appUrls.sgw.manage.natureOfWorks.edit(id));

  return (
    <Auth acl={ISgwNatureOfWorksAclActions.viewSgwNatureOfWorksDetail} showUnauthorizedPage>
      <Header backButtonUrl={appUrls.sgw.manage.natureOfWorks.base}>
        {translate('sgw.natureOfWorks.natureOfWorkName', { natureOfWork: natureOfWorks?.name })}
      </Header>
      <Container className={C.container}>
        <Paper square data-testid="natureOfWorkInfo">
          <PanelContent title={translate('sgw.natureOfWorks.detail')}>
            <Auth acl={ISgwNatureOfWorksAclActions.viewSgwNatureOfWorksDetail}>
              <PanelAction icon={<Edit />} onClick={onClick} data-testid="ToggleButton" />
            </Auth>
            <Grid container spacing={2} className={C.grid}>
              <LabelValue label={translate('sgw.natureOfWorks.columns.name')}>{natureOfWorks?.name}</LabelValue>
              <LabelValue label={translate('sgw.natureOfWorks.columns.description')}>
                {natureOfWorks?.description}
              </LabelValue>
              <LabelValue label={translate('sgw.natureOfWorks.columns.gipodGuid')}>
                {natureOfWorks?.gipodGuid}
              </LabelValue>
              <LabelValue label={translate('sgw.natureOfWorks.detailActive')}>
                {translate(natureOfWorks?.deleted ? 'no' : 'yes')}
              </LabelValue>
            </Grid>
          </PanelContent>
        </Paper>
      </Container>
    </Auth>
  );
};
