import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../common';

export const useGeneralStyles = makeStyles({
  bold: {
    fontWeight: 'bold',
  },
  clickable: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  fullWidth: {
    width: '100%',
  },
  halfWidth: {
    width: '50%',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexColumnEnd: {
    alignItems: 'end',
    display: 'flex',
    flexDirection: 'column',
  },
  flexRow: {
    display: 'flex',
  },
  flexRowCenterVertical: {
    display: 'flex',
    alignItems: 'center',
  },
  flexRowSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  flexRowEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  gridContainer: {
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  margin20: { margin: 20 },
  gridContainerPadding: {
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  marginBottom20: { marginBottom: 20 },
  flexRowCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  marginTop20: { marginTop: 20 },
  marginTop40: { marginTop: 40 },
  marginRight: { marginRight: 10 },
  marginLeft40: { marginTop: 40 },
  warningPrimary: {
    padding: '15px',
    border: '1px solid',
    borderColor: COLORS.PRIMARY,
    color: COLORS.PRIMARY,
    fontSize: 'small',
    fontWeight: 500,
  },
  warningRed: {
    padding: '15px',
    border: '1px solid',
    borderColor: COLORS.DANGER,
    color: COLORS.DANGER,
    fontSize: 'small',
    fontWeight: 500,
  },
  infoText: { color: COLORS.GREY_LIGHT, fontSize: 'small', marginTop: '0.5rem' },
  textEnd: {
    textAlign: 'end',
    whiteSpace: 'pre',
  },
  whiteSpaceBreak: {
    whiteSpace: 'break-spaces',
  },
  padding10: {
    padding: '10px',
  },
  paddingY10: {
    padding: '10px 0',
  },
  textLength20: {
    width: '20ch',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  errorMessage: {
    color: COLORS.RED,
  },
});
