import { useMutation, useQueryClient } from 'react-query';
import { IManualPlacementIntake } from '../types';
import { ManualPlacementApi } from '../store/api/manualPlacement.api';
import { useLocation, useParams } from 'react-router';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { SnackBarActions } from '../store/actions';
import { translate } from '../common/translations/translate';

export const useManualPlacementMutation = (publicDomainIntakeId: number) => {
  const { id: requestId } = useParams<{ id: string }>();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const key = useMemo(() => new URLSearchParams(search).get('key')!, [search]);

  return useMutation(
    (values: Partial<IManualPlacementIntake>) => {
      return ManualPlacementApi.patch(requestId, key, {
        id: publicDomainIntakeId,
        ...values,
      });
    },
    {
      onSuccess: () => {
        dispatch(SnackBarActions.setSuccess(translate('manualPlacement.saveSucceeded')));
        queryClient.invalidateQueries('manualPlacement');
      },
      onError: (error: Error) => {
        dispatch(SnackBarActions.setFailure(error.message));
      },
    },
  );
};
