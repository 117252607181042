import { createUseStyles } from 'react-jss';
import { COLORS } from '../../../common';

export const useRadioButtonListStyles = createUseStyles({
  list: {
    gap: '0.6rem',
  },
  radio: {
    marginLeft: '0.6rem',
    marginTop: '0.2rem',
  },
  input: {
    backgroundColor: COLORS.WHITE,
    borderRadius: '50%',
    content: '""',
    display: 'inline-block',
    height: '1.5rem',
    transitionDuration: '250ms',
    transitionProperty: 'background, box-shadow',
    transitionTimingFunction: 'ease-in-out',
    width: '1.5rem',
  },
  marginBottom: {
    marginBottom: '0.5rem',
  },
});
