import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { ConflictGroupState, IConflictGroup, ISgwRequestDetailAclActions } from '../../../types';
import { SgwConflictStateButton } from '../../atoms/ConflictStateButton/ConflictStateButton.component';
import { useRequestAuthorization } from '../../../hooks';
import { Visible } from '../../../common/components/layout/Visible.component';

const useStyles = createUseStyles({
  FlexAlignVertical: {
    display: 'flex',
    alignItems: 'center',
  },
});

export const SgwConflictState: FC<IConflictGroup> = (conflictGroup) => {
  const C = useStyles();
  const { isAuthorized: canSetSgwConflictStateToAwaitingAdvic } = useRequestAuthorization(
    ISgwRequestDetailAclActions.setSgwConflictStateToAwaitingAdvice,
  );
  const { isAuthorized: canSetSgwConflictStateToFeedbackGiven } = useRequestAuthorization(
    ISgwRequestDetailAclActions.setSgwConflictStateToFeedbackGiven,
  );
  const { isAuthorized: canSetSgwConflictStateToNotApplicable } = useRequestAuthorization(
    ISgwRequestDetailAclActions.setSgwConflictStateToNotApplicable,
  );
  const { isAuthorized: canSetSgwConflictStateToConflictResolved } = useRequestAuthorization(
    ISgwRequestDetailAclActions.setSgwConflictStateToConflictResolved,
  );

  return (
    <div className={C.FlexAlignVertical}>
      <Visible visible={canSetSgwConflictStateToAwaitingAdvic}>
        <SgwConflictStateButton
          buttonVariant={ConflictGroupState.awaitingFeedback}
          conflictGroup={conflictGroup}
          key={`${conflictGroup.id} ${ConflictGroupState.awaitingFeedback}`}
        />
      </Visible>
      <Visible visible={canSetSgwConflictStateToFeedbackGiven}>
        <SgwConflictStateButton
          buttonVariant={ConflictGroupState.feedbackGiven}
          conflictGroup={conflictGroup}
          key={`${conflictGroup.id} ${ConflictGroupState.feedbackGiven}`}
        />
      </Visible>
      <Visible visible={canSetSgwConflictStateToNotApplicable}>
        <SgwConflictStateButton
          buttonVariant={ConflictGroupState.notApplicable}
          conflictGroup={conflictGroup}
          key={`${conflictGroup.id} ${ConflictGroupState.notApplicable}`}
        />
      </Visible>
      <Visible visible={canSetSgwConflictStateToConflictResolved}>
        <SgwConflictStateButton
          buttonVariant={ConflictGroupState.resolved}
          conflictGroup={conflictGroup}
          key={`${conflictGroup.id} ${ConflictGroupState.resolved}`}
        />
      </Visible>
    </div>
  );
};
