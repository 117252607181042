import * as React from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { COLORS } from '../../../common';
import { translate } from '../../../common/translations/translate';
import { getSgwRequestAssignedUsername, selectSgwRequest } from '../../../store/selectors/sgwRequest.selectors';

const useStyles = createUseStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  assignedLabel: {
    color: COLORS.GREY,
    marginRight: '5px',
  },
  assignedToNobody: {
    color: COLORS.MAGENTA,
  },
});

export const UserAssignedLabel = () => {
  const C = useStyles();
  const username = useSelector(getSgwRequestAssignedUsername);
  const request = useSelector(selectSgwRequest);

  return (
    <div data-testid="userAssignedLabel" className={C.root}>
      <span className={C.assignedLabel}>{translate('Requests.Header.assign.to')}</span>
      <span className={classNames(!request?.userResponsible && C.assignedToNobody)}>{username}</span>
    </div>
  );
};
