import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@material-ui/core';
import React, { FC, ChangeEvent, useState, MouseEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedDateTime, Panel } from '../../common';
import { Loader } from '../../common/components/layout/loader.component';
import { translate, translateIgnoreTS } from '../../common/translations/translate';
import { NotificationActions } from '../../store/actions/notifications.actions';
import { selectNotificationsHistory, selectLoading } from '../../store/selectors/notifications.selectors';
import { INotificationHistoryItem } from '../types/notification';

interface IProps {
  id?: number;
}

export const NotificationHistory: FC<IProps> = ({ id }) => {
  const dispatch = useDispatch();
  const notificationHistory = useSelector(selectNotificationsHistory);
  const loading = useSelector(selectLoading);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  useEffect(() => {
    id && dispatch(NotificationActions.fetchHistory(`${id}`));
  }, [dispatch, id]);

  const handleChangePage = (_: MouseEvent<any> | null, newPage: number) => setPage(newPage);

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const showData = notificationHistory
    ? notificationHistory.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : [];

  return (
    <Panel title={translate('history.title')}>
      <Loader loading={loading}>
        <Table size={'small'}>
          <TableHead>
            <TableRow>
              <TableCell>{translate('history.date')}</TableCell>
              <TableCell>{translate('history.field')}</TableCell>
              <TableCell>{translate('history.value')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {showData.map((item) => (
              <HistoryItem key={`${item.timestamp}_${item.value}`} item={item} />
            ))}
          </TableBody>
        </Table>
        <TablePagination
          labelRowsPerPage={translate('history.rowsPerPage')}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={notificationHistory?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Loader>
    </Panel>
  );
};

interface HistoryItemProps {
  item: INotificationHistoryItem;
}

const HistoryItem: FC<HistoryItemProps> = ({ item: { timestamp, value, field } }) => {
  const translateField = (field: string, val: string) =>
    field === 'state' ? translateIgnoreTS('Notifications.status.' + val) : val;

  return (
    <TableRow>
      <TableCell>
        <FormattedDateTime dateTime={timestamp} />
      </TableCell>
      <TableCell>{translateIgnoreTS(`Notifications.info.${field}`)}</TableCell>
      <TableCell>{translateField(field, value)}</TableCell>
    </TableRow>
  );
};
