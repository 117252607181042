import { Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import * as React from 'react';
import { FunctionComponent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AsignButton from '../../common/components/buttons/asign-button';
import { Button } from '../../common/components/buttons/asign-button-extensions';
import { ModalButton } from '../../common/components/buttons/modal-button';
import ModalDialog from '../../common/components/modal-dialog/modal-dialog.component';
import { useAuthorization } from '../../hooks';
import { IRootState } from '../../root.state';
import { INotification } from '../types/notification';
import { INotificationAclActions } from '../../types';
import { Auth } from '../../components';
import { NotificationState } from '../notification.constants';
import { Visible } from '../../common/components/layout/Visible.component';
import { NotificationActions } from '../../store/actions/notifications.actions';
import { translate, translateIgnoreTS } from '../../common/translations/translate';

interface IProps {
  notification: INotification;
}

const NotificationActionsComponent: FunctionComponent<IProps> = ({ notification }: IProps) => {
  const { state, userResponsible } = notification;
  const { user, isAuthorized: hasEditNotificationAclAction } = useAuthorization(
    INotificationAclActions.editNotification,
  );
  const notificationsError: string | null = useSelector((store: IRootState) => store.notifications.notificationsError);
  const dispatch = useDispatch();

  const patchNotificationAction = useCallback(
    (patchData: INotification) => {
      if (notification && user) {
        dispatch(NotificationActions.patch(patchData));
      }
    },
    [dispatch, notification, user],
  );

  const onClosePopups = useCallback((): void => {
    dispatch(NotificationActions.setError(null));
  }, [dispatch]);

  const isAssignedToMe = useCallback(
    (): boolean => !!(notification && user && userResponsible?.id === user.id),
    [notification, user, userResponsible?.id],
  );

  const onAssign = useCallback((): void => {
    patchNotificationAction({ ...notification, userResponsible: { id: user!.id } });
  }, [notification, patchNotificationAction, user]);

  const onUnassign = useCallback((): void => {
    patchNotificationAction({ ...notification, userResponsible: { id: null } });
  }, [notification, patchNotificationAction]);

  const setNotificationState = useCallback(
    (state: NotificationState, clearReason: boolean = false) =>
      () => {
        patchNotificationAction({ ...notification, state, statusReason: clearReason ? '' : notification.statusReason });
      },
    [notification, patchNotificationAction],
  );

  const setNotificationStateWithReason = useCallback(
    (state: NotificationState, closePopups = false) =>
      (statusReason: string | null) => {
        closePopups && onClosePopups();
        patchNotificationAction({ ...notification, state, statusReason: statusReason || '' });
      },
    [notification, patchNotificationAction, onClosePopups],
  );

  if (!notification || !user || !user.acl) {
    return null;
  }

  return (
    <>
      <Visible visible={!!notificationsError}>
        <ModalDialog
          title={translate('Notifications.Dialogs.ErrorTitle')}
          onClose={onClosePopups}
          buttons={
            <AsignButton color="default" className={'button-margin-left'} onClick={onClosePopups}>
              {translate('refuse')} <Close />
            </AsignButton>
          }
        >
          <Typography>{notificationsError}</Typography>
        </ModalDialog>
      </Visible>
      <Auth acl={INotificationAclActions.editNotification}>
        <ModalButton.Review
          onConfirm={onAssign}
          visible={!isAssignedToMe()}
          title={translate('Notifications.Buttons.Evaluate')}
          buttonTitle={translate('Notifications.Buttons.Evaluate')}
          tooltip={translate('Notifications.Buttons.Evaluate')}
          shouldShowModal={!!userResponsible}
          needsReason={false}
          modalBody={translate('Notifications.Dialogs.ReassignMessage')}
        />
        <ModalButton.Unassign
          onConfirm={onUnassign}
          visible={isAssignedToMe()}
          title={translate('Notifications.Dialogs.UnassignTitle')}
          buttonTitle={translate('Notifications.Buttons.Unassign')}
          tooltip={translate('Requests.Header.assign.confirm.unassignTitle')}
          needsReason={false}
          modalBody={translate('Notifications.Dialogs.UnassignMessage')}
        />
        <ModalButton.Approve
          onConfirm={setNotificationState(NotificationState.pickup)}
          visible={state === NotificationState.in_progress && isAssignedToMe()}
          title={translate('Notifications.Dialogs.PickupTitle')}
          buttonTitle={translate('Notifications.Buttons.Pickup')}
          needsReason={false}
          modalBody={translate('Notifications.Dialogs.PickupMessage')}
        />
        <ModalButton.ToGMP
          onConfirm={setNotificationState(NotificationState.in_gmp)}
          visible={hasEditNotificationAclAction && state === NotificationState.in_progress && isAssignedToMe()}
          title={translate('Notifications.Buttons.ToGMP')}
          buttonTitle={translate('Notifications.Buttons.ToGMP')}
          needsReason={false}
          modalBody={translate('Notifications.Dialogs.GMPMessage')}
        />
        <Button.Gray
          visible={state === NotificationState.pickup && isAssignedToMe()}
          className={'button-margin-left'}
          onClick={setNotificationState(NotificationState.in_progress)}
        >
          {translate('Notifications.Buttons.CancelPickup')} <Close />
        </Button.Gray>
        <ModalButton.Reject
          onConfirm={setNotificationStateWithReason(NotificationState.invalid, true)}
          visible={state === NotificationState.in_progress && isAssignedToMe()}
          title={translate('Notifications.Dialogs.MarkAsInvalid')}
          buttonTitle={translate('Notifications.Buttons.Invalid')}
          needsReason
          reasonText={translate('Notifications.Dialogs.MarkAsInvalidComment')}
        />
        <ModalButton.HandleInternally
          onConfirm={setNotificationStateWithReason(NotificationState.handle_internally, true)}
          visible={state === NotificationState.in_progress && isAssignedToMe()}
          title={translate('Notifications.Dialogs.HandleInternally')}
          buttonTitle={translate('Notifications.Buttons.HandleInternally')}
          needsReason
          reasonText={translate('Notifications.Dialogs.HandleInternallyComment')}
        />
        <Button.Gray
          data-testid="set-back-to-in-progress"
          visible={[NotificationState.invalid, NotificationState.handle_internally].includes(state) && isAssignedToMe()}
          className={'button-margin-left'}
          onClick={setNotificationState(NotificationState.in_progress, true)}
        >
          {translateIgnoreTS(
            `Notifications.Buttons.${state === NotificationState.invalid ? 'UnmarkInvalid' : 'CancelHandleInternally'}`,
          )}{' '}
          <Close />
        </Button.Gray>
      </Auth>
    </>
  );
};

export default NotificationActionsComponent;
