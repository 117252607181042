import React, { FunctionComponent, MouseEvent } from 'react';
import { IFetchGeocode, IFetchSuggestions, IRequestLocationForm } from '../../../types';
import { CollapsablePanel } from '../../../components';
import { Grid, IconButton } from '@material-ui/core';
import I18n from 'i18n-js';
import { Delete } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../common';
import { LocationForm } from './LocationForm.component';
import { getAddressFromTo } from '../../../common/utils/address.util';
import classNames from 'classnames';
import { Visible } from '../../../common/components/layout/Visible.component';

interface IProps {
  canEditLocations: boolean;
  deleteAllowed: boolean;
  expanded: boolean;
  location: IRequestLocationForm;
  onExpand: (event: any, isExpanded: boolean) => void;
  onRefreshGeocodes: (address: IFetchGeocode) => void;
  onRefreshSuggestions: (address: IFetchSuggestions) => void;
  onRemove: (id: number) => void;
  onSave: (location: Partial<IRequestLocationForm>) => void;
  onValidationResult: (location: Partial<IRequestLocationForm>, flag: boolean) => void;
  onLocationTypeChanged: (location: Partial<IRequestLocationForm>) => void;
}

const useStyles = makeStyles({
  panelExpanded: {
    '& .MuiExpansionPanelSummary-root': {
      backgroundColor: COLORS.PRIMARY,
      color: COLORS.WHITE,
    },
  },
  panelNotExpanded: {
    '& .MuiExpansionPanelSummary-root': {
      backgroundColor: COLORS.GREY_LIGHT,
    },
  },
  paper: {
    marginTop: 16,
    width: '100%',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  titleExpanded: {
    color: COLORS.WHITE,
  },
  titleNotExpanded: {
    color: 'inherit',
  },
});

export const LocationPanel: FunctionComponent<IProps> = ({
  canEditLocations,
  deleteAllowed,
  expanded,
  location,
  onExpand,
  onRefreshGeocodes,
  onRefreshSuggestions,
  onRemove,
  onSave,
  onValidationResult,
  onLocationTypeChanged,
}) => {
  const C = useStyles();

  const title = getAddressFromTo(location) || I18n.translate('Requests.Create.Form.newLocation');

  const _onRemove = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onRemove(location.id);
  };

  return (
    <Grid item xs={12}>
      <CollapsablePanel
        className={expanded ? C.panelExpanded : C.panelNotExpanded}
        data-testid="LocationPanel"
        expanded={expanded}
        noTitleWrap
        onChange={onExpand}
        title={
          <div className={C.title}>
            <p className={classNames('collapsable-expansion-title', expanded ? C.titleExpanded : C.titleNotExpanded)}>
              {title}
            </p>
            <Visible visible={deleteAllowed}>
              <IconButton aria-label="delete" onClick={_onRemove}>
                <Delete className={expanded ? C.titleExpanded : C.titleNotExpanded} />
              </IconButton>
            </Visible>
          </div>
        }
      >
        <LocationForm
          canEditLocations={canEditLocations}
          expanded={expanded}
          location={location}
          onRefreshGeocodes={onRefreshGeocodes}
          onRefreshSuggestions={onRefreshSuggestions}
          onSave={onSave}
          onValidationResult={onValidationResult}
          onLocationTypeChanged={onLocationTypeChanged}
        />
      </CollapsablePanel>
    </Grid>
  );
};
