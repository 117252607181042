import * as I18n from 'i18n-js';
import * as React from 'react';
import { FunctionComponent, useEffect, useState } from 'react';
import { LayersControl } from 'react-leaflet';
import { MapLayer } from './MapLayer.component';
import { useBaseMap } from './BaseMap.context';
import { useGeoDrawingContext } from '../../../hooks/useGeoDrawingContext.hook';
import { useSelector } from 'react-redux';
import { selectSgwRequest } from '../../../store/selectors/sgwRequest.selectors';

export const MapLayersControl: FunctionComponent = () => {
  const { baseLayers, overlayLayers, selectedBaseLayer, selectedOverlayLayers } = useBaseMap();
  const [overlayPrefix, setOverlayPrefix] = useState<number>(Math.random);
  const {
    phaseGeoDrawings: { selectedPhase },
  } = useGeoDrawingContext();
  const request = useSelector(selectSgwRequest);

  useEffect(() => {
    setOverlayPrefix(Math.random());
  }, [overlayLayers, selectedPhase, request]);

  return (
    <LayersControl data-testid="LayersControl">
      {baseLayers.map((layer) => (
        <LayersControl.BaseLayer name={I18n.t(layer.name)} key={layer.id} checked={selectedBaseLayer === layer.id}>
          <MapLayer layer={layer} />
        </LayersControl.BaseLayer>
      ))}
      {overlayLayers.map((layer) => (
        <LayersControl.Overlay
          name={I18n.t(layer.name)}
          key={`${overlayPrefix}${layer.id}`}
          checked={selectedOverlayLayers.includes(layer.id)}
        >
          <MapLayer layer={layer} phase={selectedPhase} request={request} />
        </LayersControl.Overlay>
      ))}
    </LayersControl>
  );
};
