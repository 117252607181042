import { combineReducers } from 'redux';
import { ISgwPartner } from '../../../types';
import { generatePayloadReducer } from '../../../common';
import { SgwPartnerActions } from '../../actions/sgwPartners.actions';
import { initialSgwPartnersState } from '../../state/partners.state';
import { createPagedTableReducer } from '../pagedTable.reducer';

export const partnersReducer = combineReducers({
  list: createPagedTableReducer<ISgwPartner>({
    fetchAction: SgwPartnerActions.list.fetch.type,
    addEntitiesActions: [SgwPartnerActions.list.set.type],
    addEntityActions: [SgwPartnerActions.set.type],
    setAllIdsAction: SgwPartnerActions.list.set.type,
    setParamsActions: [SgwPartnerActions.list.fetch.type, SgwPartnerActions.list.setParams.type],
    initialParamsState: initialSgwPartnersState.list.table,
  }),
  loading: generatePayloadReducer([SgwPartnerActions.setLoading.type], false),
});
