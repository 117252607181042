import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as React from 'react';
import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { IFormikTextFieldAutoComplete, IWithClassName } from '../../../types';

interface IProps extends Omit<IFormikTextFieldAutoComplete, 'options'>, IWithClassName {
  onChangeText?: (text: string) => void;
  options: string[];
  setFieldValue: (field: string, value: any) => void;
  validate?: (value: any) => string | null;
}

export const FormikAutocompleteFetchField: FunctionComponent<IProps> = ({
  className,
  label,
  name,
  onChangeText,
  options,
  value,
  setFieldValue,
  required,
  validate,
  disabled = false,
}) => {
  const [optionsState, setOptionsState] = useState<string[]>([]);

  useEffect(() => {
    setOptionsState(options);
  }, [options]);

  const _onInputChange = (e: any, v: string, r: string) => {
    if (r === 'input') {
      if (v.length > 0) {
        onChangeText && onChangeText(v);
      } else {
        setOptionsState([]);
      }
    }
  };

  const _onChange = (event: ChangeEvent<{}>, value: string | null) => {
    setFieldValue(name, value);
  };

  const errorMessage = validate && validate(value);

  return (
    <Autocomplete
      className={className}
      disabled={disabled}
      getOptionLabel={(label) => label}
      onChange={_onChange}
      onInputChange={_onInputChange}
      options={optionsState}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="standard"
          required={required}
          error={!!errorMessage}
          helperText={errorMessage}
        />
      )}
    />
  );
};
