import { Divider, FormHelperText, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormikErrors } from 'formik';
import I18n from 'i18n-js';
import moment from 'moment';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import {
  FormikAutocompleteField,
  FormikCheckboxField,
  FormikDateField,
} from '../../../common/components/formikFormFields';
import { PanelTitle } from '../../../common/components/panel/panel-title.component';
import { FROM_TIMES, toTimeOptions, UNTIL_TIMES } from '../../../common/utils/moment.util';
import { getEditableFieldsIncludes } from '../../../store/selectors';
import { RequestCreateField, IRequestFormData } from '../../../types';
import { Visible } from '../../../common/components/layout/Visible.component';

interface IProps {
  errors: FormikErrors<IRequestFormData>;
  values: IRequestFormData;
  setFieldValue: (field: string, value: any) => void;
}

const useStyles = makeStyles({
  container: { width: 550 },
  countryField: { width: 300 },
  infoText: {
    fontSize: 13,
    color: '#9e9e9e',
    fontStyle: 'italic',
  },
  validation: {
    paddingLeft: '16px',
  },
});

const dateFormat = 'YYYY-MM-DD';

export const RequestFormDatetimeComponent: FunctionComponent<IProps> = ({ errors, values, setFieldValue }) => {
  const C = useStyles();

  const timeFromDisabled = !useSelector(getEditableFieldsIncludes(RequestCreateField.timeFrom));
  const timeUntilDisabled = !useSelector(getEditableFieldsIncludes(RequestCreateField.timeUntil));

  if (!values.dateFrom) {
    setFieldValue(RequestCreateField.dateFrom, moment());
  }

  if (!values.dateUntil) {
    setFieldValue(RequestCreateField.dateUntil, moment());
  }

  if (moment(values.dateFrom, dateFormat) > moment(values.dateUntil, dateFormat)) {
    setFieldValue(RequestCreateField.dateUntil, values.dateFrom);
  }

  return (
    <>
      <PanelTitle>{I18n.t('Requests.Create.DateTitle')}</PanelTitle>

      <Grid container className={C.container} spacing={4} justify={'flex-start'}>
        <Grid item xs={12} className={C.infoText}>
          {I18n.t(`Requests.Create.SelectedPeriodStartsBeforeTheNormalProcessingTime`)}
        </Grid>
        <Grid item xs={6}>
          <FormikDateField
            disabled={!useSelector(getEditableFieldsIncludes(RequestCreateField.dateFrom))}
            name={RequestCreateField.dateFrom}
            label={I18n.t(`Requests.Create.Form.dateFrom`)}
            minDate={moment()}
            dateFormat={dateFormat}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <FormikDateField
            disabled={!useSelector(getEditableFieldsIncludes(RequestCreateField.dateUntil))}
            name={RequestCreateField.dateUntil}
            label={I18n.t(`Requests.Create.Form.dateUntil`)}
            minDate={values.dateFrom ? moment(values.dateFrom) : moment()}
            dateFormat={dateFormat}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <FormikCheckboxField
            disabled={!useSelector(getEditableFieldsIncludes(RequestCreateField.onlyOnWeekdays))}
            name={RequestCreateField.onlyOnWeekdays}
            label={I18n.t('Requests.Create.Form.onlyOnWeekdays')}
          />
        </Grid>
      </Grid>

      <br />
      <Divider />
      <br />

      <Visible visible={values.reason !== 'container'}>
        <Grid item xs={12} className={C.infoText}>
          {I18n.t('Requests.Create.TimeInfo')}
        </Grid>

        <PanelTitle>{I18n.t('Requests.Create.TimeTitle')}</PanelTitle>
        <Grid container className={C.container} spacing={4} justify={'flex-start'}>
          {!values.entireDay && (
            <>
              <Grid item xs={6}>
                <FormikAutocompleteField
                  disabled={timeFromDisabled}
                  name={RequestCreateField.timeFrom}
                  label={I18n.t(`Requests.Create.Form.timeFrom`)}
                  options={toTimeOptions(FROM_TIMES)}
                  value={`${values.timeFrom}`}
                  error={!!errors.timeFrom}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikAutocompleteField
                  disabled={timeUntilDisabled}
                  name={RequestCreateField.timeUntil}
                  label={I18n.t(`Requests.Create.Form.timeUntil`)}
                  options={toTimeOptions(UNTIL_TIMES)}
                  value={`${values.timeUntil}`}
                  error={!!errors.timeUntil}
                />
              </Grid>
              {(errors.timeUntil || errors.timeFrom) && (
                <FormHelperText error className={C.validation}>
                  {I18n.t('Requests.Create.Validation.TimeOrder')}
                </FormHelperText>
              )}
            </>
          )}
          <Grid item xs={12}>
            <FormikCheckboxField
              disabled={!useSelector(getEditableFieldsIncludes(RequestCreateField.entireDay))}
              name={RequestCreateField.entireDay}
              label={I18n.t('Requests.Create.Form.entireDay')}
            />
          </Grid>
        </Grid>
      </Visible>
    </>
  );
};
