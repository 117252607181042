import { Grid, TextField } from '@material-ui/core';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { Visible } from '../../../common/components/layout/Visible.component';
import { translate } from '../../../common/translations/translate';
import { useGeneralStyles } from '../../../style/generalStyles';
import { IPersonalInfo } from '../../../types';
import { checkSSN } from '../../../utils';

interface IProps {
  showSsn: boolean;
}

export const PersonalInfoFields: FC<IProps> = ({ showSsn }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<IPersonalInfo>();
  return (
    <Grid container spacing={2} className={useGeneralStyles().gridContainerPadding}>
      <Visible visible={showSsn}>
        <Grid item xs={6}>
          <TextField
            {...register('ssn', { required: showSsn, validate: checkSSN(showSsn) })}
            label={translate('sgw.partners.columns.ssn')}
            placeholder={translate('withoutspaces')}
            required={showSsn}
            fullWidth
            error={!!errors?.ssn?.message}
            helperText={errors?.ssn?.message}
          />
        </Grid>
      </Visible>
      <Grid item xs={showSsn ? 3 : 6}>
        <TextField
          {...register('firstName', { required: true })}
          label={translate('sgw.partners.columns.firstName')}
          required
          fullWidth
          error={!!errors?.firstName?.message}
          helperText={errors?.firstName?.message}
        />
      </Grid>
      <Grid item xs={showSsn ? 3 : 6}>
        <TextField
          {...register('lastName', { required: true })}
          label={translate('sgw.partners.columns.lastName')}
          required
          fullWidth
          error={!!errors?.lastName?.message}
          helperText={errors?.lastName?.message}
        />
      </Grid>
    </Grid>
  );
};
