import * as I18n from 'i18n-js';
import React, { CSSProperties, FunctionComponent } from 'react';
import { ModalButton } from '../../../common/components/buttons/modal-button';
import { IRequestExtensionAcl } from '../../../types';

interface IProps {
  acl: IRequestExtensionAcl;
  onReject: (reason: string | null) => void;
  onApprove: (reason: string | null) => void;
}

const styles = {
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '15px',
  },
};

export const ExtensionActions: FunctionComponent<IProps> = ({ onApprove, onReject, acl }) => {
  const showApproveButton = acl.can_be_approved;
  const showRejectButton = acl.can_be_rejected;

  return (
    <div data-testid="extensionActions" style={styles.root as CSSProperties}>
      <ModalButton.Reject
        data-testid="extensionActionsReject"
        onConfirm={onReject}
        visible={showRejectButton}
        title={I18n.t('ModalButton.RequestExtension.Reject')}
        buttonTitle={I18n.t('ModalButton.RequestExtension.Reject')}
      />
      <ModalButton.Approve
        data-testid="extensionActionsApprove"
        onConfirm={onApprove}
        visible={showApproveButton}
        title={I18n.t('ModalButton.RequestExtension.Approve')}
        buttonTitle={I18n.t('ModalButton.RequestExtension.Approve')}
        shouldShowModal={acl.show_approve_warning}
        modalBody={I18n.t('ModalButton.RequestExtension.ConfirmApprove')}
      />
    </div>
  );
};
