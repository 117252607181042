import { combineReducers } from 'redux';
import { IPaging, ISortingDirection } from '../../../..';
import { createPagedTableReducer } from '../../../../../store/reducers/pagedTable.reducer';
import { initialPaging } from '../../../../config/paging.constants';
import { ServicesActions } from '../../Services.actions';
import allIds from './allIds.reducer';
import byId from './byId.reducer';

export default combineReducers({
  allIds,
  byId,
  table: createPagedTableReducer<{ id: string; paging: IPaging }>({
    fetchAction: ServicesActions.contactsList.fetch.type,
    addEntitiesActions: [ServicesActions.contactsList.set.type],
    setAllIdsAction: ServicesActions.contactsList.set.type,
    setParamsActions: [ServicesActions.contactsList.fetch.type, ServicesActions.contactsList.setParams.type],
    initialParamsState: {
      paging: initialPaging,
      filters: {},
      sorting: { key: 'name', direction: ISortingDirection.asc },
    },
  }),
});
