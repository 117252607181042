import React, { ChangeEvent, FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { translate } from '../../../common/translations/translate';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { LabelValue } from '../../../common/components/layout/LabelValue.component';
import { useStreetSuggestions } from '../../../hooks/useStreetSuggestions.hook';
import { ISgwRequestInfo } from '../../../types';
import { CheckboxWithLabel } from '../../../common/components/form-fields/checkboxWithLabel.component';
import { Visible } from '../../../common/components/layout/Visible.component';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  addressRow: {
    display: 'flex',
    marginBottom: '10px',
  },
  checkbox: {},
});

export const RequestDetailAddressFields: FC = () => {
  const {
    register,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext<ISgwRequestInfo>();
  const [input, setInput] = useState('');
  const suggestions = useStreetSuggestions(input, undefined, 'Antwerpen');
  const C = useStyles();

  const onSelectStreet = (event: ChangeEvent<{}>, value: string | null) => {
    value && setValue('mainLocation.street', value);
  };

  return (
    <LabelValue label={translate('sgw.requests.detail.info.address')}>
      <Autocomplete
        {...register('mainLocation.street', { required: true })}
        value={watch('mainLocation.street')}
        placeholder={'Straatnaam'}
        options={suggestions.map(({ label }) => label)}
        onChange={onSelectStreet}
        onInputChange={(e: any) => setInput(e?.target?.value)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={translate('sgw.requests.detail.info.mainAddress.street')}
            required
            error={!!errors.mainLocation?.street}
          />
        )}
      />
      <CheckboxWithLabel
        {...register('mainLocation.streetNumberUnknown')}
        label={translate('sgw.requests.detail.info.mainAddress.streetNumberUnknown')}
        checked={!!watch('mainLocation.streetNumberUnknown')}
        onChange={(checked) => setValue('mainLocation.streetNumberUnknown', checked)}
      />
      <Visible visible={!watch('mainLocation.streetNumberUnknown')}>
        <div className={C.addressRow}>
          <TextField
            {...register('mainLocation.streetNumberFrom')}
            label={translate('sgw.requests.detail.info.mainAddress.streetNumberFrom')}
            error={!!errors.mainLocation?.streetNumberFrom}
          />
          <TextField
            {...register('mainLocation.streetNumberTo')}
            label={translate('sgw.requests.detail.info.mainAddress.streetNumberTo')}
            error={!!errors.mainLocation?.streetNumberTo}
          />
        </div>
      </Visible>
      <div className={C.addressRow}>
        <TextField
          {...register('mainLocation.city')}
          label={translate('sgw.requests.detail.info.mainAddress.city')}
          error={!!errors.mainLocation?.city}
        />
        <TextField
          {...register('mainLocation.zipCode')}
          label={translate('sgw.requests.detail.info.mainAddress.zipCode')}
          error={!!errors.mainLocation?.zipCode}
        />
      </div>
    </LabelValue>
  );
};
