import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { genericErrorHandler } from '../../common';
import { initCpa, setParkingBans, setParkingBansLoading } from './cpa.actions';
import { CpaApi } from './cpa.api';
import { ICall } from '../../types';

function* onInitCpa(): SagaIterator {
  // Fetch the parking bans
  yield put(setParkingBansLoading(true));
  const response: ICall<typeof CpaApi.getParkingBans> = yield call(CpaApi.getParkingBans);
  yield put(setParkingBans(response!.data.data.parkingBanIntakes));
  yield put(setParkingBansLoading(false));
}

export function* cpaSaga(): SagaIterator {
  yield takeLatest(initCpa.type, genericErrorHandler(onInitCpa));
}
