import { Grid, TextField } from '@material-ui/core';
import * as I18n from 'i18n-js';
import { Search as SearchIcon, Search } from '@material-ui/icons';
import * as React from 'react';
import { MultiSelect, SingleSelect } from '../../../common';
import { IEventsFilter } from '../../types/events-filter';
import { IEventType } from '../../types/event-type';

interface IProps {
  eventsFilter: IEventsFilter;
  eventsFilterChange: (filter: IEventsFilter) => void;
  types: IEventType[];
}

export const Filters: React.FunctionComponent<IProps> = ({ eventsFilter, eventsFilterChange, types }: IProps) => {
  const moduleIdFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeFilter({ moduleId: event.target.value });
  };

  const signIdFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeFilter({ signId: event.target.value });
  };

  const typeFilterChange = (types: string[]) => {
    changeFilter({ types });
  };

  const processedFilterChange = (processed: boolean | string) => {
    changeFilter({
      processed: typeof processed === 'boolean' ? processed : null,
    });
  };

  const changeFilter = (newFilter: Partial<IEventsFilter>) => {
    eventsFilterChange({
      ...eventsFilter,
      ...newFilter,
    });
  };

  return (
    <Grid container={true} spacing={2} justify="space-around" style={{ marginBottom: 20 }}>
      <Grid item={true} xs={2}>
        <TextField
          value={eventsFilter.moduleId}
          onChange={moduleIdFilterChange}
          fullWidth={true}
          id="moduleIdFilter"
          label={I18n.t('FilterOnModuleID')}
          InputProps={{
            endAdornment: <Search />,
          }}
        />
      </Grid>
      <Grid item={true} xs={2}>
        <TextField
          value={eventsFilter.signId}
          onChange={signIdFilterChange}
          fullWidth={true}
          id="signIdFilter"
          label={I18n.t('FilterOnSignID')}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
        />
      </Grid>
      <Grid item={true} xs={3}>
        <MultiSelect
          data={types}
          value={eventsFilter.types}
          valueChange={typeFilterChange}
          valueProp="type"
          keyProp="type"
          displayProp="name"
          id="eventTypeFilter"
          label={I18n.t('FilterOnSignType')}
        />
      </Grid>
      <Grid item={true} xs={2}>
        <SingleSelect
          data={[
            { value: '', display: I18n.t('AllEvents') },
            { value: true, display: I18n.t('Processed') },
            { value: false, display: I18n.t('Unprocessed') },
          ]}
          value={eventsFilter.processed === null ? '' : eventsFilter.processed}
          valueChange={processedFilterChange}
          valueProp="value"
          keyProp="value"
          displayProp="display"
          id="processedFilter"
          label={I18n.t('FilterOnProcessed')}
        />
      </Grid>
    </Grid>
  );
};
