import * as React from 'react';
import { FunctionComponent, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../../common/components/layout/loader.component';
import { MetadataActions } from '../../../store/actions/metadata.actions';
import { selectAppVersions } from '../../../store/selectors';
import { Grid } from '@material-ui/core';

const useStyles = createUseStyles({
  container: {
    position: 'absolute',
    top: 59,
    right: 0,
    backgroundColor: '#adadad',
    opacity: 0.95,
    minWidth: 350,
  },
  appVersionName: {
    width: '30px',
  },
  appVersions: {
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
    color: '#DDD',
  },
});

export const AppInfo: FunctionComponent = () => {
  const C = useStyles();
  const dispatch = useDispatch();
  const appVersions = useSelector(selectAppVersions);

  useEffect(() => {
    dispatch(MetadataActions.fetchAppVersions());
  }, [dispatch]);

  return (
    <div className={C.container}>
      <Loader loading={!appVersions.asign_backend}>
        <div className={C.appVersions}>
          <Grid container direction={'row'}>
            <div className={C.appVersionName}>BE:</div>
            <div>{appVersions.asign_backend?.app_version}</div>
          </Grid>
          <Grid container direction={'row'}>
            <div className={C.appVersionName}>BO:</div>
            <div>{appVersions.asign_frontend_backoffice?.app_version}</div>
          </Grid>
          <Grid container direction={'row'}>
            <div className={C.appVersionName}>FO:</div>
            <div>{appVersions.asign_frontend?.app_version}</div>
          </Grid>
          <Grid container direction={'row'}>
            <div className={C.appVersionName}>PV:</div>
            <div>{appVersions.asign_frontend_parkeerverbod?.app_version}</div>
          </Grid>
          <Grid container direction={'row'}>
            <div className={C.appVersionName}>IR:</div>
            <div>{appVersions.asign_imageresizer?.app_version}</div>
          </Grid>
          <Grid container direction={'row'}>
            <div className={C.appVersionName}>SS:</div>
            <div>{appVersions.asign_socket_server?.app_version}</div>
          </Grid>
          <Grid container direction={'row'}>
            <div className={C.appVersionName}>DBA:</div>
            <div>{appVersions.asign_database_automation?.app_version}</div>
          </Grid>
        </div>
      </Loader>
    </div>
  );
};
