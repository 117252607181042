import { combineReducers } from 'redux';
import { generateArrayReducer, generateObjectReducer, generatePayloadReducer } from '../../..';
import { createPagedTableReducer } from '../../../../store/reducers/pagedTable.reducer';
import { userListInitialFilter } from '../../../../users/User.constants';
import { IUser, IUserFilter, IUserRequestFilter } from '../../../types/user';
import { UserActions } from '../user.actions';
import { initialUserState } from '../user.state';
import admins from './admins.reducer';
import { ISgwRequest } from '../../../../types';

export const userReducer = combineReducers({
  admins,
  allRoles: generateArrayReducer(UserActions.setAllRoles.type, []),
  user: generateObjectReducer<IUser | null>(UserActions.set.type, initialUserState.user),
  list: createPagedTableReducer<IUser, IUserFilter>({
    setLoadingAction: UserActions.list.setLoading.type,
    fetchAction: UserActions.list.fetch.type,
    setAllIdsAction: UserActions.list.set.type,
    setParamsActions: [UserActions.list.fetch.type, UserActions.list.setParams.type],
    initialParamsState: userListInitialFilter,
    addEntitiesActions: [UserActions.list.set.type],
    addEntityActions: [UserActions.setUser.type],
  }),
  requestList: createPagedTableReducer<ISgwRequest, IUserRequestFilter>({
    fetchAction: UserActions.requestList.fetch.type,
    setAllIdsAction: UserActions.requestList.set.type,
    setParamsActions: [UserActions.requestList.fetch.type, UserActions.requestList.setParams.type],
    initialParamsState: initialUserState.requestList.table,
  }),
  selectedId: generatePayloadReducer<string | null>([UserActions.setSelected.type], null),
});
