import { Grid, TextField } from '@material-ui/core';
import { Clear, Edit } from '@material-ui/icons';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { ISortingDirection, Panel, PanelAction } from '../../../common';
import { CheckboxWithLabel } from '../../../common/components/form-fields/checkboxWithLabel.component';
import { Visible } from '../../../common/components/layout/Visible.component';
import { translate } from '../../../common/translations/translate';
import { useGeneralStyles } from '../../../style/generalStyles';
import { useSgwAdvisingPartiesForm } from '../../../hooks/forms/useSgwAdvisingPartiesForm.hook';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoading } from '../../../store/selectors/sgwAdvisingParties.selectors';
import { Loader } from '../../../common/components/layout/loader.component';
import { useHistory } from 'react-router';
import { appUrls } from '../../../common/config/url.constants';
import { useFormStyles } from '../../../style/form.styles';
import { Auth, SubmitButton } from '../../atoms';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getList, getListMenuItems } from '../../../common/store/user/user.selectors';
import { ISgwAdvisingPartiesAclActions, TNullableLabelValue } from '../../../types';
import { UserActions } from '../../../common/store/user/user.actions';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  user: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  info: {
    marginBottom: '1rem',
  },
});

interface IProps {
  title: string;
}

export const AdvisingPartiesForm: FC<IProps> = ({ title }) => {
  const F = useStyles();
  const C = useFormStyles();
  const G = useGeneralStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { formMethods, submitAdvisingParty, id } = useSgwAdvisingPartiesForm();
  const {
    register,
    setValue,
    watch,
    formState: { errors },
    clearErrors,
  } = formMethods;
  const isEditMode = !!id;
  const active = watch('active');
  const loading = useSelector(selectLoading);
  const loadedUsers = useSelector(getList);

  const [inputValue, setInputValue] = useState<TNullableLabelValue>();
  const [inputString, setInputString] = useState<string>('');

  const toBeLinkedAPUsers = watch('users') || [];
  const userIds = toBeLinkedAPUsers.map((user) => user.id || 0);

  useEffect(() => {
    dispatch(
      UserActions.list.fetch({
        filters: { showAvailableForSgwAdvisingParty: true, query: inputString },
        sorting: {
          key: 'fullName',
          direction: ISortingDirection.asc,
        },
      }),
    );
  }, [dispatch, inputString]);

  const onChange = (e: ChangeEvent<{}>, labelValue: TNullableLabelValue) => {
    const user = loadedUsers.find(({ id }) => `${id}` === `${labelValue?.value}`);
    if (!user) {
      return;
    }
    setValue('users', [...toBeLinkedAPUsers, user]);
    clearErrors('users');
    setInputValue(undefined);
  };

  const onInputChange = (e: ChangeEvent<{}>, value: string) => setInputString(value);

  const goToDetail = () => history.push(appUrls.sgw.manage.advisingParties.detail(id));

  const onRemoveSelectedUser = (userId: number) => () =>
    setValue(
      'users',
      toBeLinkedAPUsers.filter(({ id }) => id !== userId),
    );

  return (
    <Loader loading={loading}>
      <FormProvider {...formMethods} data-testid="advisingPartiesForm">
        <form id="createAdvisingParty" onSubmit={submitAdvisingParty} noValidate>
          <Panel title={title}>
            <Visible visible={isEditMode}>
              <PanelAction icon={<Edit />} data-testid="editButton" onClick={goToDetail} />
            </Visible>
            <Grid container spacing={3} className={C.formGrid}>
              <Grid item xs={12}>
                <TextField
                  {...register('name', { required: true })}
                  label={translate('sgw.advisingParties.columns.name')}
                  className={G.fullWidth}
                  required
                  data-testid="nameField"
                  error={!!errors.name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...register('email', { required: true })}
                  label={translate('sgw.advisingParties.columns.email')}
                  className={G.fullWidth}
                  required
                  data-testid="emailField"
                  error={!!errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...register('contactName')}
                  label={translate('sgw.advisingParties.columns.contactName')}
                  className={G.fullWidth}
                  data-testid="contactNameField"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...register('contactPhoneNumber')}
                  label={translate('sgw.advisingParties.columns.contactPhoneNumber')}
                  className={G.fullWidth}
                  data-testid="contactPhoneNumberField"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...register('description')}
                  label={translate('sgw.advisingParties.columns.description')}
                  className={G.fullWidth}
                  data-testid="descriptionField"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  {...register('flagDays')}
                  label={translate('sgw.advisingParties.columns.flagDays')}
                  className={G.fullWidth}
                  data-testid="flagDaysField"
                />
              </Grid>
              <Auth acl={[ISgwAdvisingPartiesAclActions.addUserToAdvisingParty]}>
                <Grid item xs={12}>
                  <div className={F.info}>{translate('sgw.advisingParties.usersInfo')}</div>
                  <div>
                    {toBeLinkedAPUsers.map((user) => (
                      <div className={F.user} key={user.id} onClick={onRemoveSelectedUser(user.id || 0)}>
                        {user.fullName}
                        <Clear fontSize="small" />
                      </div>
                    ))}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label={translate('sgw.advisingParties.selectUser')}
                        required
                        error={!!errors.users}
                      />
                    )}
                    placeholder={translate('sgw.advisingParties.selectUser')}
                    options={useSelector(getListMenuItems(userIds))}
                    getOptionLabel={(option) => option.label}
                    value={inputValue}
                    onChange={onChange}
                    getOptionSelected={(option, value) => option.value === value.value}
                    data-testid="selectUsers"
                    onInputChange={onInputChange}
                  />
                </Grid>
              </Auth>
              <Grid item xs={12}>
                <CheckboxWithLabel
                  {...register('active')}
                  checked={!active}
                  label={translate('sgw.advisingParties.columns.deactivate')}
                  onChange={(checked) => setValue('active', !checked)}
                  className={C.checkbox}
                />
              </Grid>
              <Grid item xs={12}>
                <CheckboxWithLabel
                  {...register('mailNotification')}
                  checked={watch('mailNotification')}
                  label={translate('sgw.advisingParties.columns.mailNotification')}
                  onChange={(checked) => setValue('mailNotification', checked)}
                  className={C.checkbox}
                />
              </Grid>
            </Grid>
          </Panel>
          <SubmitButton formId="createAdvisingParty">{translate('sgw.natureOfWorks.saveNatureOfWork')}</SubmitButton>
        </form>
      </FormProvider>
    </Loader>
  );
};
