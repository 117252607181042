import React, { FC } from 'react';
import { CONFIG, Panel } from '../../../common';
import { Grid, Link } from '@material-ui/core';
import { translate } from '../../../common/translations/translate';
import {
  GeoDrawingAddListItem,
  GeoDrawingCopyListItem,
  GeoDrawingList,
  RequestDetailMap,
  useRequestDetailMapStyles,
} from '../../molecules';
import { ISgwRequestDetailAclActions } from '../../../types';
import { useGeneralStyles } from '../../../style/generalStyles';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { Icon } from '../../../common/components/layout/icon.component';
import LinkIcon from '@material-ui/icons/Link';
import { useGeoDrawingContext } from '../../../hooks/useGeoDrawingContext.hook';
import { useRequestAuthorization } from '../../../hooks';
import { Visible } from '../../../common/components/layout/Visible.component';

export const RequestDetailMapPanel: FC = () => {
  const C = useRequestDetailMapStyles();
  const G = useGeneralStyles();
  const { isAuthorized: requestAllowsEditSgwMap } = useRequestAuthorization(ISgwRequestDetailAclActions.editSgwMap);

  const {
    phaseGeoDrawings: { center },
  } = useGeoDrawingContext();

  return (
    <Panel
      title={
        <div className={G.flexRowSpaceBetween}>
          <div>{translate('sgw.requests.detail.map.title')}</div>
          <div className={C.locationLinks}>
            <Button.Link
              visible={!!center}
              onClick={center && (() => window.open(`${CONFIG.baseGoogleMapsUrl}?q=${center.lat},${center.lng}`))}
            >
              <Icon.GoogleMaps className={C.googleMapsIcon} />
              <Link>{translate('Requests.Detail.Locations.GoogleMaps')}</Link>
            </Button.Link>
            <Button.Link
              visible={!!center}
              onClick={
                center &&
                (() =>
                  window.open(
                    `${CONFIG.baseViodUrl}&runWorkflow=OpstartSGW&type=WGS84&argX=${center.lat}&argY=${center.lng}`,
                  ))
              }
            >
              <LinkIcon className={C.viodIcon} />
              <Link>{translate('Requests.Detail.Locations.VIOD')}</Link>
            </Button.Link>
          </div>
        </div>
      }
    >
      <Grid container>
        <Grid item xs={3} className={C.phaseDetails}>
          <GeoDrawingList />
          <Visible visible={requestAllowsEditSgwMap}>
            <GeoDrawingAddListItem />
            <GeoDrawingCopyListItem />
          </Visible>
        </Grid>
        <Grid item xs={9}>
          <RequestDetailMap />
        </Grid>
      </Grid>
    </Panel>
  );
};
