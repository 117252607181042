import { Grid, makeStyles, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import moment, { Moment } from 'moment';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IPagedTable } from '../../../common';
import { ClearFiltersFab } from '../../../common/components/buttons/ClearFiltersFab';
import { Autocomplete } from '../../../common/components/form-fields/autocomplete.component';
import { AsignDatepicker } from '../../../common/components/form-fields/datepicker/asign-datepicker.component';
import { MultiSelectV2Component } from '../../../common/components/form-fields/multi-select-v2.component';
import { UserActions } from '../../../common/store/user/user.actions';
import { getAdmins } from '../../../common/store/user/user.selectors';
import { translate } from '../../../common/translations/translate';
import { getAllRequestTypes } from '../../../store/selectors';
import { selectUserRequestTable } from '../../../store/selectors/user-request.selectors';
import { DateFormat, IRequestsFilter } from '../../../types';
import { useRequestMetadata } from '../../../hooks';

const useStyles = makeStyles({
  container: {
    justifyContent: 'space-between',
    marginBottom: '20px',
    alignItems: 'center',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  clear: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  datesContainer: {
    width: '80%',
  },
});

export const UserRequestListFilters: FC<{ onFetch(table: Partial<IPagedTable>): void }> = ({ onFetch }) => {
  const C = useStyles();
  const dispatch = useDispatch();
  const table = useSelector(selectUserRequestTable);
  useRequestMetadata();
  const filters = table.filters;
  const allRequestTypes = useSelector(getAllRequestTypes);

  const onChangeFilter = (newFilterValues: Partial<IRequestsFilter>) => {
    onFetch({ filters: { ...filters, ...newFilterValues } });
  };

  const dateFromFilterChange = (selectedDate: Moment) => {
    onChangeFilter({ fromDate: selectedDate.format(DateFormat.reduxStoreDateString) });
  };

  const dateUntilFilterChange = (selectedDate: Moment) => {
    onChangeFilter({ untilDate: selectedDate.format(DateFormat.reduxStoreDateString) });
  };

  const streetNameFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeFilter({ streetName: event.target.value });
  };

  const requestTypesFilterChange = (requestTypes: string[]) => {
    onChangeFilter({ requestTypes });
  };

  const userResponsibleFilterChange = (userResponsible?: number) => {
    onChangeFilter({ userResponsible });
  };

  useEffect(() => {
    onFetch({ filters });
  }, [filters, onFetch]);

  useEffect(() => {
    dispatch(UserActions.fetchAdminList());
  }, [dispatch]);

  const resetFilters = () => {
    onFetch({
      ...table,
      // Default filters
      filters: {
        userRequested: filters.userRequested,
        utilityCompany: filters.utilityCompany,
      },
    });
  };

  return (
    <Grid container spacing={2} className={C.container}>
      <Grid container spacing={2} className={C.container}>
        <Grid container spacing={2} className={C.datesContainer}>
          <Grid item xs={2}>
            <AsignDatepicker
              label={translate('users.requestsList.filters.fromDate')}
              value={filters.fromDate ? moment(filters.fromDate) : undefined}
              onChange={dateFromFilterChange}
              options={{ popperPlacement: 'bottom-start', 'data-testid': 'fromDateField' }}
            />
          </Grid>
          <Grid item xs={2}>
            <AsignDatepicker
              label={translate('users.requestsList.filters.untilDate')}
              value={filters.untilDate ? moment(filters.untilDate) : undefined}
              onChange={dateUntilFilterChange}
              options={{ popperPlacement: 'bottom-start', 'data-testid': 'untilDateField' }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              type="text"
              value={filters.streetName || ''}
              onChange={streetNameFilterChange}
              fullWidth={true}
              label={translate('users.requestsList.filters.street')}
              InputProps={{
                endAdornment: <Search />,
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <MultiSelectV2Component
              title={(filters.requestTypes && translate('users.requestsList.filters.requestType')) || ' '}
              values={filters.requestTypes || []}
              onChange={requestTypesFilterChange}
              menuItems={allRequestTypes}
              placeholder={translate('users.requestsList.filters.requestType')}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete<number>
              title={(filters.userResponsible && translate('UserResponsible')) || undefined}
              placeholder={translate('UserResponsible')}
              menuItems={useSelector(getAdmins)}
              value={filters.userResponsible}
              onChange={userResponsibleFilterChange}
              elipsisChars={25}
            />
          </Grid>
        </Grid>
        <Grid item xs={2} className={C.clear}>
          <ClearFiltersFab onClick={resetFilters} />
        </Grid>
      </Grid>
    </Grid>
  );
};
