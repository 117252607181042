import {
  Actions,
  generateBaseActionCreator,
  generatePayloadActionCreator,
  TableActions,
  TBaseActionCreator,
  TPayloadActionCreator,
} from '../../common';
import { IEvent } from '../types/event';
import { IEventType } from '../types/event-type';
import { IEventsFilter } from '../types/events-filter';

class EventsActionsClass extends Actions {
  list = new TableActions<IEvent, IEventsFilter>(`${this.prefix}.list`);
}
export const EventsActions = new EventsActionsClass('events');

export const setEventsLoading: TPayloadActionCreator<boolean> = generatePayloadActionCreator('events.setEventsLoading');

export const setEvent: TPayloadActionCreator<IEvent> = generatePayloadActionCreator('events.setEvent');

export const initEventOverview: TBaseActionCreator = generateBaseActionCreator('events.initEventOverview');

export const bulkProcessEvent: TPayloadActionCreator<number[]> =
  generatePayloadActionCreator('events.bulkProcessEvent');

export const setEventErrors: TPayloadActionCreator<string[] | null> =
  generatePayloadActionCreator('events.setEventError');

export const setEventTypes: TPayloadActionCreator<IEventType[]> = generatePayloadActionCreator('events.setEventTypes');

export const fetchEventsFilterData: TBaseActionCreator = generateBaseActionCreator('events.fetchEventsFilterData');

export const setEventsFilterDataLoaded: TPayloadActionCreator<boolean> = generatePayloadActionCreator(
  'events.setEventsFilterDataLoaded',
);
