import { Chip } from '@material-ui/core';
import React, { FC } from 'react';
import { IWithClassName } from '../../../types';
import { COLORS } from '../../config/theme';

interface IProps extends IWithClassName {
  color?: string;
  label?: string;
}

export const LabelChip: FC<IProps> = ({ className, color = COLORS.GREEN, label }: IProps) => (
  <Chip
    className={className}
    key={label}
    label={label}
    style={{
      height: '28px',
      fontWeight: 'bold',
      background: color + '33', // set transparency by adding alpha to hex color code
      color,
    }}
  />
);
