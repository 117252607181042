import { baseUrl } from './config';

export const appUrls = {
  base: '/admin',
  error: '/error',
  acl: {
    unauthorized: '/admin/unauthorized',
  },
  carFreeZones: {
    base: '/admin/carfreezones',
    krautliZones: {
      base: '/admin/carfreezones/krautli_zones',
      detail: (id: string = ':id', fullUrl = false) => {
        const url = `/admin/carfreezones/krautli_zones/${id}`;
        return fullUrl ? `${baseUrl}${url}` : url;
      },
    },
    moovTemplates: '/admin/carfreezones/moov_templates',
    exemptions: '/admin/carfreezones/exemptions',
  },
  cpa: {
    base: '/admin',
  },
  login: {
    base: '/admin/login',
    selection: '/admin/login_selection',
    oauthMprofile: '/admin/oauth_mprofile',
  },
  reset: {
    password: (code: string = ':code') => `/admin/reset/password/${code}`,
  },
  manualPlacement: {
    base: (id: string = ':id') => `/admin/manual_placement/${id}`,
  },
  notifications: {
    base: '/admin/notifications',
    detail: (id: string = ':id', fullUrl = false) => {
      const url = `/admin/notifications/detail/${id}`;
      return fullUrl ? `${baseUrl}${url}` : url;
    },
  },
  planning: {
    base: '/admin/planning',
    reordering: {
      base: '/admin/planning/reordering',
      detail: (id: string = ':id') => `/admin/planning/reordering/${id}`,
    },
  },
  requests: {
    base: '/admin/requests',
    list: '/admin/requests/list',
    create: '/admin/requests/create',
    detail: (id: string | number = ':id', fullUrl = false) =>
      fullUrl ? `${baseUrl}/admin/requests/${id}` : `/admin/requests/${id}`,
    detailLocation: (id: string = ':id', location: string = ':location', fullUrl = false) => {
      const url = `/admin/requests/${id}/locations/${location}`;
      return fullUrl ? `${baseUrl}${url}` : url;
    },
    edit: (id: string = ':id', fullUrl = false) => {
      const url = `/admin/requests/${id}/edit`;
      return fullUrl ? `${baseUrl}${url}` : url;
    },
    editLocations: (id: string = ':id', fullUrl = false) => {
      const url = `/admin/requests/${id}/edit/locations`;
      return fullUrl ? `${baseUrl}${url}` : url;
    },
    messages: (id: string = ':id', fullUrl = false) => {
      const url = `/admin/requests/${id}/messages`;
      return fullUrl ? `${baseUrl}${url}` : url;
    },
  },
  signs: {
    base: '/admin/signs',
    detail: (hash: string = ':hash', fullUrl = false) =>
      fullUrl ? `${baseUrl}/admin/signs/detail/${hash}` : `/admin/signs/detail/${hash}`,
    modules: {
      base: '/admin/signs/modules',
      detail: (id: string = ':id', fullUrl = false) =>
        fullUrl ? `${baseUrl}/admin/signs/modules/detail/${id}` : `/admin/signs/modules/detail/${id}`,
    },
    forecast: '/admin/signs/forecast',
    map: '/admin/signs/map',
    events: '/admin/signs/events',
  },
  services: {
    base: '/admin/services',
    create: '/admin/services/create',
    detail: (id: string = ':id', fullUrl = false) => {
      const url = `/admin/services/${id}`;
      return fullUrl ? `${baseUrl}${url}` : url;
    },
    whitelist: {
      base: '/admin/services/whitelist',
    },
  },
  sgw: {
    requests: {
      base: '/admin/sgw/requests',
      detail: (id: string | number = ':id', fullUrl = false) =>
        fullUrl ? `${baseUrl}/admin/sgw/requests/${id}` : `/admin/sgw/requests/${id}`,
    },
    manage: {
      base: '/admin/sgw/manage',
      settings: '/admin/sgw/manage/settings',
      permitConditions: {
        base: '/admin/sgw/manage/permit_conditions',
        create: '/admin/sgw/manage/permit_conditions/create',
        detail: (id: string = ':id', fullUrl = false) => {
          const url = `/admin/sgw/manage/permit_conditions/${id}`;
          return fullUrl ? `${baseUrl}${url}` : url;
        },
        edit: (id: string = ':id', fullUrl = false) => {
          const url = `/admin/sgw/manage/permit_conditions/${id}/edit`;
          return fullUrl ? `${baseUrl}${url}` : url;
        },
      },
      projects: {
        base: '/admin/sgw/manage/projects',
        create: '/admin/sgw/manage/projects/create',
        detail: (id: string = ':id', fullUrl = false) => {
          const url = `/admin/sgw/manage/projects/${id}`;
          return fullUrl ? `${baseUrl}${url}` : url;
        },
        edit: (id: string = ':id', fullUrl = false) => {
          const url = `/admin/sgw/manage/projects/${id}/edit`;
          return fullUrl ? `${baseUrl}${url}` : url;
        },
      },
      natureOfWorks: {
        base: '/admin/sgw/manage/nature_of_works',
        create: '/admin/sgw/manage/nature_of_works/create',
        detail: (id: string = ':id', fullUrl = false) => {
          const url = `/admin/sgw/manage/nature_of_works/${id}`;
          return fullUrl ? `${baseUrl}${url}` : url;
        },
        edit: (id: string = ':id', fullUrl = false) => {
          const url = `/admin/sgw/manage/nature_of_works/${id}/edit`;
          return fullUrl ? `${baseUrl}${url}` : url;
        },
      },
      advisingParties: {
        base: '/admin/sgw/manage/advising_parties',
        create: '/admin/sgw/manage/advising_parties/create',
        detail: (id: string = ':id') => `/admin/sgw/manage/advising_parties/${id}`,
        edit: (id: string = ':id') => `/admin/sgw/manage/advising_parties/${id}/edit`,
      },
      trafficMeasures: {
        base: '/admin/sgw/manage/traffic_measures',
        create: '/admin/sgw/manage/traffic_measures/create',
        detail: (id: string = ':id') => `/admin/sgw/manage/traffic_measures/${id}`,
        edit: (id: string = ':id') => `/admin/sgw/manage/traffic_measures/${id}/edit`,
      },
    },
  },
  users: {
    base: '/admin/users',
    create: '/admin/users/create',
    detail: (id: string = ':id', fullUrl = false) => {
      const url = `/admin/users/${id}`;
      return fullUrl ? `${baseUrl}${url}` : url;
    },
    edit: (id: string = ':id', fullUrl = false) => {
      const url = `/admin/users/${id}/edit`;
      return fullUrl ? `${baseUrl}${url}` : url;
    },
  },
  utilityCompanies: {
    base: '/admin/utility_companies',
    create: '/admin/utility_companies/create',
    detail: (id: string = ':id') => `/admin/utility_companies/${id}`,
    edit: (id: string = ':id') => `/admin/utility_companies/${id}/edit`,
  },
  attachments: {
    base: (fullUrl = false, attachmentUrl?: string) =>
      `${fullUrl ? baseUrl : ''}/admin/attachments${attachmentUrl ? `?url=${attachmentUrl}` : ''}`,
  },
};
