import * as React from 'react';
import { FunctionComponent, PropsWithChildren } from 'react';
import { IWithClassName } from '../../../types';
import { createUseStyles } from 'react-jss';
import { Visible } from '../../../common/components/layout/Visible.component';

interface IProps extends IWithClassName {
  title: string;
  visible?: boolean;
}

const useStyles = createUseStyles({
  column: {
    flexDirection: 'column',
    display: 'flex',
    marginBottom: '5px',
  },
  title: {
    fontWeight: 600,
    marginBottom: ' 5px',
  },
});

export const TitleValue: FunctionComponent<PropsWithChildren<IProps>> = ({
  className,
  children,
  title,
  visible = true,
}) => {
  const C = useStyles();

  return (
    <Visible visible={visible}>
      <div className={className}>
        <div className={C.column}>
          <div className={C.title}>{title}</div>
          <div>{children}</div>
        </div>
      </div>
    </Visible>
  );
};
