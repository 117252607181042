import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Search } from '@material-ui/icons';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { CarFreeZonesFilterState, CarFreeZonesFilterType, ICarFreeZonesFilter, IWithStyle } from '../../types';
import { getCarFreeZonesFilterStateOptions, getCarFreeZonesFilterTypeOptions } from '../CarFreeZone.constants';
import { MultiSelect } from '../../common';
import { ClearFiltersFab } from '../../common/components/buttons/ClearFiltersFab';
import { translate } from '../../common/translations/translate';

interface IProps extends IWithStyle {
  changeFilters(filter: ICarFreeZonesFilter): void;
  filters: ICarFreeZonesFilter;
  resetFilters(): void;
}

const useStyles = makeStyles({
  root: {
    marginBottom: 20,
  },
  resetButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 22,
  },
});

export const CarFreeZonesFilter: FunctionComponent<IProps> = ({ filters, changeFilters, resetFilters }) => {
  const C = useStyles();

  const queryFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeFilters({
      ...filters,
      query: event.target.value,
    });
  };

  const stateFilterChange = (state: CarFreeZonesFilterState[]) => {
    changeFilters({
      ...filters,
      state,
    });
  };

  const typeFilterChange = (type: CarFreeZonesFilterType[]) => {
    changeFilters({
      ...filters,
      type,
    });
  };

  return (
    <Grid container spacing={4} justify="space-between" className={C.root}>
      <Grid item xs={4}>
        <TextField
          value={filters.query}
          onChange={queryFilterChange}
          fullWidth
          id="notificationQueryFilter"
          label={translate('services.filters.query')}
          InputProps={{
            endAdornment: <Search />,
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <MultiSelect
          data={getCarFreeZonesFilterTypeOptions()}
          displayProp={'label'}
          id="typeFilter"
          label={translate('carFreeZones.krautli.list.filters.type.label')}
          value={filters.type || []}
          valueChange={typeFilterChange}
          valueProp={'value'}
        />
      </Grid>
      <Grid item xs={2}>
        <MultiSelect
          data={getCarFreeZonesFilterStateOptions()}
          displayProp={'label'}
          id="stateFilter"
          label={translate('carFreeZones.krautli.list.filters.state.label')}
          value={filters.state || []}
          valueChange={stateFilterChange}
          valueProp={'value'}
        />
      </Grid>

      <Grid item xs={2} className={C.resetButton}>
        <ClearFiltersFab onClick={resetFilters} />
      </Grid>
    </Grid>
  );
};
