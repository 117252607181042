import arrayMove from 'array-move';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { genericErrorHandler, TPayloadAction } from '../../common';
import { IRootState } from '../../root.state';
import { IReorderingWorkOrderItemChangeOrder } from '../types/reordering-work-order';
import { IReorderingWorkOrderItem } from '../types/reordering-work-order-item';
import {
  changeOrderReorderingWorkOrder,
  fetchReorderingWorkOrder,
  saveReorderingWorkOrder,
  setReorderingLoading,
  setReorderingWorkOrder,
  toggleCheckReorderingWorkOrder,
} from './reordering.actions';
import { ReorderingApi } from './reordering.api';
import { ICall, ISelect } from '../../types';

const getWorkOrder = (state: IRootState) => state.reordering.workOrder;

function* onFetchReorderingWorkOrder(action: TPayloadAction<number>): SagaIterator {
  yield all([put(setReorderingLoading(true)), put(setReorderingWorkOrder(null))]);

  const response: ICall<typeof ReorderingApi.getReorderingWorkOrder> = yield call(
    ReorderingApi.getReorderingWorkOrder,
    action.payload,
  );

  yield put(setReorderingWorkOrder(response!.data.data));
  yield put(setReorderingLoading(false));
}

function* onChangeOrderReorderingWorkOrder(action: TPayloadAction<IReorderingWorkOrderItemChangeOrder>): SagaIterator {
  const workOrder: ISelect<typeof getWorkOrder> = yield select(getWorkOrder);
  const oldIndex = workOrder!.workOrderItems.findIndex((woi) => woi.id === action.payload.workOrderItem.id);
  workOrder!.workOrderItems = arrayMove(workOrder!.workOrderItems, oldIndex, action.payload.order);
  // Update sequence numbers to be in order
  workOrder!.workOrderItems.forEach((woi, index) => {
    woi.sequence = index + 1;
  });
  yield put(setReorderingWorkOrder(workOrder!));
  yield put(saveReorderingWorkOrder());
}

function* onToggleCheckReorderingWorkOrder(action: TPayloadAction<IReorderingWorkOrderItem>): SagaIterator {
  const workOrder: ISelect<typeof getWorkOrder> = yield select(getWorkOrder);
  let moveToEndWoi = null;
  workOrder!.workOrderItems.forEach((woi) => {
    if (woi.id === action.payload.id) {
      woi.checked = !woi.checked;
      if (!woi.checked) {
        moveToEndWoi = woi;
      }
    }
  });
  yield put(setReorderingWorkOrder(workOrder!));
  if (moveToEndWoi) {
    yield put(changeOrderReorderingWorkOrder({ order: workOrder!.workOrderItems.length, workOrderItem: moveToEndWoi }));
  }
  yield put(saveReorderingWorkOrder());
}

function* onSaveReorderingWorkOrder(): SagaIterator {
  try {
    yield put(setReorderingLoading(true));

    const workOrder: ISelect<typeof getWorkOrder> = yield select(getWorkOrder);
    const response: ICall<typeof ReorderingApi.saveReorderingWorkOrder> = yield call(
      ReorderingApi.saveReorderingWorkOrder,
      workOrder!,
    );

    yield put(setReorderingWorkOrder(response!.data.data));
  } finally {
    yield put(setReorderingLoading(false));
  }
}

export function* reorderingSaga(): SagaIterator {
  yield takeLatest(fetchReorderingWorkOrder.type, genericErrorHandler(onFetchReorderingWorkOrder));
  yield takeEvery(changeOrderReorderingWorkOrder.type, genericErrorHandler(onChangeOrderReorderingWorkOrder));
  yield takeEvery(toggleCheckReorderingWorkOrder.type, genericErrorHandler(onToggleCheckReorderingWorkOrder));
  yield takeLatest(saveReorderingWorkOrder.type, genericErrorHandler(onSaveReorderingWorkOrder));
}
