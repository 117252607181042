import moment from 'moment';
import * as React from 'react';
import { DateFormat } from '../../../types';

interface IProps {
  date: string | undefined;
}

export const FormattedDate = ({ date }: IProps) => {
  return <>{date && moment(date).format(DateFormat.date)}</>;
};
