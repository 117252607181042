import { latLng, latLngBounds } from 'leaflet';
import * as React from 'react';
import { FunctionComponent, useEffect } from 'react';
import { IReorderingWorkOrderItem } from '../types/reordering-work-order-item';
import { ReorderingMapMarker } from './reordering-map-marker';
import { useBaseMap } from '../../common/components/map/BaseMap.context';

interface IProps {
  workOrderItems: IReorderingWorkOrderItem[];
  hoveredWorkOrderItem: IReorderingWorkOrderItem | null;
  markerPopup: IReorderingWorkOrderItem | null;
}

export const ReorderingMapMarkers: FunctionComponent<IProps> = ({
  hoveredWorkOrderItem,
  markerPopup,
  workOrderItems,
}) => {
  const { flyToBounds } = useBaseMap();

  useEffect(() => {
    if (workOrderItems.length) {
      flyToBounds(
        latLngBounds(
          workOrderItems
            .filter((item) => item.location.lat && item.location.lng)
            .map((item) => latLng(item.location.lat!, item.location.lng!)),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workOrderItems.length]);

  return workOrderItems.length ? (
    <>
      {workOrderItems.map((item) => (
        <ReorderingMapMarker
          key={item.id}
          workOrderItem={item}
          hovered={hoveredWorkOrderItem?.id === item.id}
          markerPopupId={markerPopup?.id}
        />
      ))}
    </>
  ) : null;
};
