import { Grid, TextareaAutosize } from '@material-ui/core';
import classNames from 'classnames';
import { useAtom } from 'jotai';
import moment from 'moment';
import React, { FC } from 'react';
import { FormProvider, useForm, useFieldArray } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { COLORS } from '../../../common';
import { AsignDatepicker } from '../../../common/components/form-fields/datepicker/asign-datepicker.component';
import { translate } from '../../../common/translations/translate';
import { convertDateFormat, formatDateTimeToDisplayDate } from '../../../common/utils/date.util';
import { SgwRequestActions } from '../../../store/actions';
import { getRecurringPhaseIds, selectPhases } from '../../../store/selectors/sgwRequest.selectors';
import { useGeneralStyles } from '../../../style/generalStyles';
import { DateFormat, IRouterWithId } from '../../../types';
import { IRequestRescheduling } from '../../../types/SgwReschedulings.types';
import { SubmitButton } from '../../atoms';
import { reschedulingEditModeAtom } from '../../organisms/RequestReschedulings/RequestReschedulings.component';

export interface IProps {
  rescheduling: IRequestRescheduling;
}

const useStyles = createUseStyles({
  gridPadding: {
    padding: '4px 16px !important',
  },
  textArea: {
    width: '75%',
    border: `1px solid ${COLORS.GREY_LIGHTER}`,
    borderRadius: '5px',
    padding: '5px',
  },
});

export const SgwReschedulingForm: FC<IProps> = ({ rescheduling }) => {
  const G = useGeneralStyles();
  const C = useStyles();
  const phases = useSelector(selectPhases);
  const recurringPhases = useSelector(getRecurringPhaseIds);
  const form = 'reschedulingForm';
  const formMethods = useForm<IRequestRescheduling>({
    defaultValues: rescheduling,
  });
  const { handleSubmit, register, watch, setValue, control } = formMethods;
  const { fields } = useFieldArray({
    control,
    name: 'phaseReschedulings',
  });
  const dispatch = useDispatch();
  const { id: requestId } = useParams<IRouterWithId>();
  const [, setEditMode] = useAtom(reschedulingEditModeAtom);

  const onSubmit = (rescheduling: IRequestRescheduling) => {
    dispatch(
      SgwRequestActions.reschedulings.update({
        requestId,
        rescheduling,
        reschedulingId: `${rescheduling.id}`,
      }),
    );
    setEditMode(false);
    formMethods.reset();
  };

  return (
    <FormProvider {...formMethods}>
      <Grid container>
        <form id={form} onSubmit={handleSubmit(onSubmit)} className={G.fullWidth}>
          {fields.map((rescheduling, index) => {
            return (
              <Grid container item spacing={4} alignItems="center" key={rescheduling.sgwPhaseId}>
                <Grid item xs={4}>
                  <div
                    className={classNames(
                      G.flexColumn,
                      recurringPhases.includes(rescheduling.sgwPhaseId) && G.marginLeft40,
                    )}
                  >
                    <div>{phases.find((phase) => phase.id === rescheduling.sgwPhaseId)?.phaseName}</div>
                    <small>{`${formatDateTimeToDisplayDate(
                      rescheduling.originalDateFrom,
                    )} - ${formatDateTimeToDisplayDate(rescheduling.originalDateUntil)}`}</small>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <AsignDatepicker
                    {...register(`phaseReschedulings.${index}.newDateFrom` as const)}
                    value={moment(
                      watch(`phaseReschedulings.${index}.newDateFrom` as const),
                      DateFormat.reduxStoreDateString,
                    )}
                    onChange={(value) =>
                      setValue(
                        `phaseReschedulings.${index}.newDateFrom` as const,
                        //@ts-ignore
                        convertDateFormat(value, DateFormat.reduxStoreDateTimeString, DateFormat.reduxStoreDateString),
                      )
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <AsignDatepicker
                    value={moment(
                      watch(`phaseReschedulings.${index}.newDateUntil` as const),
                      DateFormat.reduxStoreDateString,
                    )}
                    onChange={(value) =>
                      setValue(
                        `phaseReschedulings.${index}.newDateUntil` as const,
                        //@ts-ignore
                        convertDateFormat(value, DateFormat.reduxStoreDateTimeString, DateFormat.reduxStoreDateString),
                      )
                    }
                  />
                </Grid>
              </Grid>
            );
          })}
          <div className={classNames(G.flexColumn, G.marginTop20)}>
            <b>{translate('sgw.requests.detail.requestRescheduling.accordion.additionalClarification')}</b>
            <div className={G.gridContainerPadding}>
              <TextareaAutosize rowsMin={4} {...register('description')} className={C.textArea} />
            </div>
          </div>
        </form>
      </Grid>
      <SubmitButton formId={form}>{translate('sgw.requests.detail.requestRescheduling.save')}</SubmitButton>
    </FormProvider>
  );
};
