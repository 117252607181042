import { Checkbox, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { IWithClassName } from '../../../types';

const useStyles = makeStyles<Theme, IProps>({
  checkboxLabel: { paddingTop: '11px !important;', color: (props) => (props.disabled ? 'grey' : 'inherit') },
});

interface IProps extends IWithClassName {
  label: string;
  checked: boolean;
  disabled?: boolean;
  onChange?(checked: boolean): void;
  readOnly?: boolean;
}

export const CheckboxWithLabel: FunctionComponent<IProps> = (props) => {
  const { label, checked, className, disabled = false, onChange, readOnly = false } = props;
  const C = useStyles(props);
  return (
    <Grid container className={className}>
      <Grid item>
        <Checkbox
          onChange={(event, checked) => onChange?.(checked)}
          checked={checked}
          disabled={disabled || readOnly}
        />
      </Grid>
      <Grid item className={C.checkboxLabel}>
        {label}
      </Grid>
    </Grid>
  );
};
