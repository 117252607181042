import { SwitchWithErrorHandling } from '../../common/components/error/generic-error-handler';
import { Route } from 'react-router';
import { appUrls } from '../../common/config/url.constants';
import { CpaPage } from '../../cpa/pages/cpa.page';
import { SignsRoutes } from '../../signs';
import { NotificationsRoutes } from '../../notification/notification.routes';
import { PlanningRoutes } from '../../planning';
import { TourReorderingRoutes } from '../../tour-reordering/tour-reordering.routes';
import { RequestRoutes } from '../../request/request.routes';
import { ServiceRoutes } from '../../service/Service.routes';
import { UserRoutes } from '../../users/User.routes';
import { CarFreeZoneRoutes } from '../../carFreeZone/CarFreeZone.routes';
import { RequestRoutes as SgwRequestRoutes } from './Request.routes';
import { ManageRoutes } from './Manage.routes';
import * as React from 'react';
import { UnauthorizedPage } from '../pages/UnauthorizedPage/Unauthorized.page';
import { UtilityCompaniesRoutes } from './UtilityCompany.routes';

export const MainMenuRoutes = () => {
  return (
    <SwitchWithErrorHandling>
      <Route exact path={appUrls.acl.unauthorized}>
        <UnauthorizedPage />
      </Route>
      {/*The cpa base is actually '/' as this one sent from the angular part, although you will notice a '/requests/cpa' url*/}
      <Route exact path={appUrls.cpa.base}>
        <CpaPage />
      </Route>
      <Route path={appUrls.signs.base}>
        <SignsRoutes />
      </Route>
      <Route path={appUrls.notifications.base}>
        <NotificationsRoutes />
      </Route>
      <Route path={appUrls.planning.reordering.base}>
        <TourReorderingRoutes />
      </Route>
      <Route path={appUrls.planning.base}>
        <PlanningRoutes />
      </Route>
      <Route path={appUrls.requests.base}>
        <RequestRoutes />
      </Route>
      <Route path={appUrls.services.base}>
        <ServiceRoutes />
      </Route>
      <Route path={appUrls.users.base}>
        <UserRoutes />
      </Route>
      <Route path={appUrls.carFreeZones.base}>
        <CarFreeZoneRoutes />
      </Route>
      <Route path={appUrls.sgw.requests.base}>
        <SgwRequestRoutes />
      </Route>
      <Route path={appUrls.sgw.manage.base}>
        <ManageRoutes />
      </Route>
      <Route path={appUrls.utilityCompanies.base}>
        <UtilityCompaniesRoutes />
      </Route>
    </SwitchWithErrorHandling>
  );
};
