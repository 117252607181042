import { combineReducers } from 'redux';
import { ISgwProject } from '../../../types';
import { createPagedTableReducer } from '../pagedTable.reducer';
import { initialSgwProjectsState } from '../../state/projects.state';
import { SgwProjectsActions } from '../../actions';
import { generateObjectReducer } from '../../../common';

export const projectsReducer = combineReducers({
  list: createPagedTableReducer<ISgwProject>({
    fetchAction: SgwProjectsActions.list.fetch.type,
    addEntitiesActions: [SgwProjectsActions.list.set.type],
    addEntityActions: [SgwProjectsActions.set.type],
    setAllIdsAction: SgwProjectsActions.list.set.type,
    setParamsActions: [SgwProjectsActions.list.fetch.type, SgwProjectsActions.list.setParams.type],
    initialParamsState: initialSgwProjectsState.list.table,
  }),
  project: generateObjectReducer<ISgwProject | null>(SgwProjectsActions.set.type, initialSgwProjectsState.project),
});
