import { Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { Route, Switch } from 'react-router';
import { FormattedDateTime } from '../../common';
import { LabelValue } from '../../common/components/layout/LabelValue.component';
import { PanelContent } from '../../common/components/panel/panel-content.component';
import { appUrls } from '../../common/config/url.constants';
import { ICarFreeZone } from '../../types';
import { translate } from '../../common/translations/translate';

const useStyles = makeStyles({
  grid: { marginTop: 20, marginBottom: 20 },
  divider: { marginTop: 20 },
  form: { padding: 15 },
});

interface IProps extends ICarFreeZone {}

export const Details: FunctionComponent<IProps> = ({
  active,
  city,
  createdAt,
  description,
  externalReference,
  gisId,
  id,
  latitude,
  longitude,
  name,
  street,
  streetNumber,
  type,
  updatedAt,
  zipCode,
}) => {
  const C = useStyles();

  return (
    <Paper square data-testid="requestInfo">
      <PanelContent title={translate('carFreeZones.krautli.details.subTitle')}>
        <Grid container spacing={2} className={C.grid}>
          <Switch>
            <Route exact path={appUrls.carFreeZones.krautliZones.detail()}>
              <LabelValue label={translate('ID')}>{id}</LabelValue>
              <LabelValue label={translate('carFreeZones.krautli.props.name')}>{name}</LabelValue>
              <LabelValue label={translate('carFreeZones.krautli.props.description')}>{description}</LabelValue>
              <LabelValue label={translate('carFreeZones.krautli.props.gisId')}>{gisId}</LabelValue>
              <LabelValue label={translate('carFreeZones.krautli.props.externalReference')}>
                {externalReference}
              </LabelValue>
              <LabelValue label={translate('carFreeZones.krautli.props.type')}>{type}</LabelValue>

              <LabelValue label={translate('carFreeZones.krautli.props.active')} className={C.divider}>
                {translate(active ? 'yes' : 'no')}
              </LabelValue>
              <LabelValue label={translate('carFreeZones.krautli.props.createdAt')}>
                <FormattedDateTime dateTime={createdAt} />
              </LabelValue>
              <LabelValue label={translate('carFreeZones.krautli.props.updatedAt')}>
                <FormattedDateTime dateTime={updatedAt} />
              </LabelValue>
            </Route>
          </Switch>
        </Grid>
      </PanelContent>
    </Paper>
  );
};
