import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useGeoDrawingListStyles } from '../../../style/GeoDrawingList.styles';
import { translate } from '../../../common/translations/translate';
import { Visible } from '../../../common/components/layout/Visible.component';
import { SingleSelectV2Component } from '../../../common/components/form-fields/single-select-v2.component';
import { useGeoDrawingContext } from '../../../hooks/useGeoDrawingContext.hook';
import { useGeneralStyles } from '../../../style/generalStyles';
import { Disableable } from '../../../common/components/layout/Disableable.component';
import { Close, FileCopy } from '@material-ui/icons';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { useDispatch, useSelector } from 'react-redux';
import { useStickyValue } from '../../../hooks';
import classNames from 'classnames';
import { SgwPhasesActions } from '../../../store/actions/phases.actions';
import { getCopyablePhaseMenuItems } from '../../../store/selectors/sgwRequest.selectors';

export const GeoDrawingCopyListItem: FC = () => {
  const C = useGeoDrawingListStyles();
  const dispatch = useDispatch();
  const G = useGeneralStyles();
  const {
    phaseGeoDrawings: { idToEdit, selectedPhase, addMode },
  } = useGeoDrawingContext();
  const copyablePhases = useSelector(getCopyablePhaseMenuItems(selectedPhase?.id));

  const [phaseIdToCopy, setPhaseIdToCopy] = useStickyValue(copyablePhases[0]?.value);
  const [copyMode, setCopyMode] = useState(false);

  useEffect(
    () => {
      setCopyMode(false);
      setPhaseIdToCopy(copyablePhases[0]?.value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedPhase?.id, selectedPhase?.sgwGeoDrawings, setPhaseIdToCopy],
  );

  const onCopy = () => dispatch(SgwPhasesActions.copyFromPhase({ phaseIdToCopy, phaseIdToSave: selectedPhase!.id }));

  return (
    <Visible visible={!!copyablePhases.length && !addMode}>
      <div className={C.addContainer}>
        <Disableable disabled={!!idToEdit}>
          {copyMode ? (
            <>
              <SingleSelectV2Component
                fullWidth
                placeholder={translate('sgw.requests.detail.map.copy.select')}
                value={phaseIdToCopy}
                onChange={(e: ChangeEvent<{ value: number }>) => setPhaseIdToCopy(e.target.value)}
                menuItems={copyablePhases}
              />
              <div className={classNames(G.flexRowSpaceBetween, G.marginTop20)}>
                <Button.Gray type="button" onClick={() => setCopyMode(false)}>
                  <Close /> {translate('refuse')}
                </Button.Gray>
                <Button.Green type="button" onClick={onCopy}>
                  <FileCopy fontSize="small" />
                  {translate('copy')}
                </Button.Green>
              </div>
            </>
          ) : (
            <div className={C.copyButton}>
              <Button.Blue onClick={() => setCopyMode(true)} type="button">
                <FileCopy fontSize="small" />
                {translate('sgw.requests.detail.map.copy.title')}
              </Button.Blue>
            </div>
          )}
        </Disableable>
      </div>
    </Visible>
  );
};
