import { push } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest, throttle } from 'redux-saga/effects';
import { genericErrorHandler, TPayloadAction } from '../../common';
import { appUrls } from '../../common/config/url.constants';
import { translate } from '../../common/translations/translate';
import { getPagingFromHeaders } from '../../common/utils/api.util';
import { ICall, ISelect, IUtilityCompany } from '../../types';
import { mapToUtilityCompanyBE } from '../../utils';
import { SnackBarActions, UtilityCompanyActions } from '../actions';
import { UtilityCompaniesApi } from '../api';
import { getPagedApiParams, getUtilityCompany } from '../selectors/utilityCompanies.selectors';
import { THROTTLE } from '../../common/time-constants';

function* onFetchUtilityCompanyList(): SagaIterator {
  const params: ISelect<typeof getPagedApiParams> = yield select(getPagedApiParams);
  const response: ICall<typeof UtilityCompaniesApi.fetchList> = yield call(UtilityCompaniesApi.fetchList, params);
  yield all([
    put(UtilityCompanyActions.list.set(response!.data.data)),
    put(
      UtilityCompanyActions.list.setParams({
        paging: getPagingFromHeaders(response as any),
      }),
    ),
  ]);
}

function* onSaveUtilityCompany({ payload }: TPayloadAction<IUtilityCompany>): SagaIterator {
  const { id, ...company } = payload;
  const currentCompany: IUtilityCompany = yield select(getUtilityCompany(`${id}`));

  try {
    const response: ICall<typeof UtilityCompaniesApi.save> = yield call(
      UtilityCompaniesApi.save,
      mapToUtilityCompanyBE({
        ...currentCompany,
        ...company,
        id,
      }),
    );

    yield put(UtilityCompanyActions.set(response!.data.data));
    yield put(UtilityCompanyActions.setLoading(false));
    yield put(SnackBarActions.setSuccess(translate('utilityCompanies.saved')));
    yield put(push(appUrls.utilityCompanies.detail(`${response!.data.data.id}`)));
  } catch (e: any) {
    const { msgs = [] } = e?.response?.data || {};
    if (msgs.some((msg: any) => msg.type === 'E' && msg.message.includes('Unique constraint violation'))) {
      yield put(SnackBarActions.setFailure(translate('utilityCompanies.duplicateName')));
    } else {
      throw e;
    }
  } finally {
    yield put(UtilityCompanyActions.setLoading(false));
  }
}

function* onFetch({ payload }: TPayloadAction<string>) {
  const response: ICall<typeof UtilityCompaniesApi.fetch> = yield call(UtilityCompaniesApi.fetch, payload);
  yield put(UtilityCompanyActions.set(response!.data.data));
  yield put(UtilityCompanyActions.setLoading(false));
}

export function* utilityCompaniesSaga(): SagaIterator {
  yield throttle(
    THROTTLE.keyboard,
    UtilityCompanyActions.list.fetch.type,
    genericErrorHandler(onFetchUtilityCompanyList),
  );

  yield takeLatest(UtilityCompanyActions.save.type, genericErrorHandler(onSaveUtilityCompany));
  yield takeLatest(UtilityCompanyActions.fetch.type, genericErrorHandler(onFetch));
}
