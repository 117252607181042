import { FunctionComponent, useCallback, useEffect } from 'react';
import * as React from 'react';
import { FormattedDate, IPaging, ISorting } from '../../../common';
import { useDispatch, useSelector } from 'react-redux';
import { getRequests, selectRequestsLoading, selectRequestsTable, selectUser } from '../../../store/selectors';
import { FeedbackIcon } from '../icons/feedback-icon';
import { StatusIcon } from '../icons/status-icon.component';
import { UrgentIcon } from '../icons/urgent-icon';
import {
  IPlanningAclActions,
  IRequestAclActions,
  IRequestOverview,
  IPublicDomainIntake,
  IRequestStatus,
  MessageState,
} from '../../../types';
import { multiLineCell } from '../../../common/components/table/MultiLineCell.component';
import { useAuthorization } from '../../../hooks';
import { RequestActions } from '../../../store/actions';
import { CarFreeZonesActions } from '../../../common/store/car-free-zones/carFreeZones.actions';
import { GenericTablePanel } from '../../../common/components/table/GenericTablePanel.component';
import { IUser } from '../../../common/types/user';
import { makeStyles } from '@material-ui/styles';
import { translate } from '../../../common/translations/translate';
import { appUrls } from '../../../common/config/url.constants';
import { useRequestMetadata } from '../../../hooks';
import { UserActions } from '../../../common/store/user/user.actions';

const useStyles = makeStyles({
  dates: {
    minWidth: '100px',
  },
});

export const OverviewComponent: FunctionComponent = () => {
  const C = useStyles();
  const metadata = useRequestMetadata();
  const { isAuthorized: canEditPlanning } = useAuthorization(IPlanningAclActions.editPlanning);
  const { isAuthorized: canViewRequest } = useAuthorization(IRequestAclActions.viewRequest);
  const currentUser = useSelector(selectUser);
  const isLoading = useSelector(selectRequestsLoading);
  const { sorting, paging } = useSelector(selectRequestsTable);
  const list = useSelector(getRequests);

  const dispatch = useDispatch();

  const fetch = useCallback(() => {
    if (currentUser) {
      dispatch(RequestActions.list.fetch({}));
      dispatch(UserActions.fetchAdminList());
      dispatch(CarFreeZonesActions.fetchAll());
    }
  }, [dispatch, currentUser]);

  useEffect(() => {
    canViewRequest && fetch();
  }, [fetch, canViewRequest]);

  const onPagingChange = (paging: IPaging) => {
    dispatch(RequestActions.list.fetch({ paging }));
  };

  const onSortingChange = (sorting: ISorting) => {
    dispatch(RequestActions.list.fetch({ sorting }));
  };

  return (
    <GenericTablePanel<IRequestOverview>
      columnKey="referenceId"
      columns={[
        {
          label: <UrgentIcon.Header />,
          name: 'emergencyProcedure',
          renderer: (emergencyProcedure?: boolean) => emergencyProcedure && <UrgentIcon />,
        },
        {
          label: <FeedbackIcon.Header />,
          name: 'messageState',
          renderer: (messageState: MessageState) => <FeedbackIcon messageState={messageState} />,
        },
        { label: translate('Requests.table.reference'), name: 'referenceId', sortable: true },
        {
          label: translate('Requests.table.createdAt'),
          name: 'createdAt',
          sortable: true,
          renderer: (createdAt: string) => <FormattedDate date={createdAt} />,
        },
        {
          label: translate('Requests.table.dateFrom'),
          name: 'dateFrom',
          sortable: true,
          className: C.dates,
          renderer: (dateFrom: string) => <FormattedDate date={dateFrom} />,
        },
        {
          label: translate('Requests.table.dateUntil'),
          name: 'dateUntil',
          sortable: true,
          className: C.dates,
          renderer: (dateUntil: string) => <FormattedDate date={dateUntil} />,
        },
        {
          label: translate('Requests.table.type'),
          name: 'publicDomainIntakes',
          sortable: false,
          sortKey: 'publicDomainIntakeTypes',
          renderer: (intakes: IPublicDomainIntake[]) => multiLineCell(intakes.map(({ type }) => type.name)),
        },
        {
          label: translate('Requests.table.locations'),
          name: 'publicDomainIntakes',
          sortable: false,
          renderer: (intakes: IPublicDomainIntake[]) =>
            multiLineCell(
              intakes.map(
                ({ street, streetNumberFrom, streetNumberTo }) => `${street} ${streetNumberFrom}-${streetNumberTo}`,
              ),
            ),
        },
        { label: translate('Requests.table.eventName'), name: 'eventName', sortable: true, visible: canEditPlanning },
        {
          label: translate('Requests.table.userResponsible'),
          name: 'userResponsible',
          sortable: true,
          renderer: (userResponsible?: IUser) => userResponsible?.fullName,
        },
        {
          label: translate('Requests.table.state'),
          name: 'state',
          sortable: true,
          renderer: (status: IRequestStatus) => (
            <StatusIcon statusState={status.state} metadataStates={metadata?.states} />
          ),
        },
      ]}
      labelUnavailable={translate('Requests.table.unavailable')}
      onChangeSorting={onSortingChange}
      onChangePaging={onPagingChange}
      paging={paging}
      sorting={sorting}
      loading={isLoading}
      records={list}
      recordToLink={({ id }) => appUrls.requests.detail(id, true)}
    />
  );
};
