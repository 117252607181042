import { IconButton } from '@material-ui/core';
import { AddCircleOutlineOutlined } from '@material-ui/icons';
import { translate } from '../../../common/translations/translate';
import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  addItems: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

interface IProps {
  onAdd(): void;
}

export const AddGeoDrawingListItem: FC<IProps> = ({ onAdd }) => {
  const C = useStyles();

  return (
    <div className={C.addItems}>
      <IconButton onClick={onAdd}>
        <AddCircleOutlineOutlined />
      </IconButton>
      <div>{translate('sgw.requests.detail.map.addItem.title')}</div>
    </div>
  );
};
