import React, { FC } from 'react';
import { PanelContent } from '../../../common/components/panel/panel-content.component';
import { translate } from '../../../common/translations/translate';
import { Grid, Paper } from '@material-ui/core';
import { LabelValue } from '../../../common/components/layout/LabelValue.component';
import { formatDateTimeToDisplayDate } from '../../../common/utils/date.util';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { CloudDownload } from '@material-ui/icons';
import { IPermit, IPermittedPlate, IRequestReason } from '../../../types';
import { useGeneralStyles } from '../../../style/generalStyles';
import classNames from 'classnames';

interface IProps {
  dateFrom: string;
  dateUntil: string;
  timeFrom: string;
  timeUntil: string;
  entireDay: boolean;
  permit?: IPermit;
  reason: IRequestReason;
  permittedPlates: IPermittedPlate[];
}

export const ManualPlacementRequestInfo: FC<IProps> = ({
  dateFrom,
  dateUntil,
  timeFrom,
  timeUntil,
  entireDay,
  reason,
  permit,
  permittedPlates,
}) => {
  const G = useGeneralStyles();

  const downloadPermit = () => {
    window.open(permit?.downloadUrl, '_blank');
  };

  return (
    <Paper square className={G.marginBottom20}>
      <PanelContent title={translate('manualPlacement.request')}>
        <Grid container spacing={2}>
          <LabelValue label={translate('manualPlacement.period')}>{`${formatDateTimeToDisplayDate(
            dateFrom,
          )} - ${formatDateTimeToDisplayDate(dateUntil)}`}</LabelValue>
          <LabelValue
            visible={!entireDay}
            label={translate('manualPlacement.between')}
          >{`${timeFrom} - ${timeUntil}`}</LabelValue>
          <LabelValue label={translate('manualPlacement.reason')}>{reason.name}</LabelValue>
          <LabelValue visible={!!permittedPlates?.length} label={translate('manualPlacement.permittedPlates')}>
            {permittedPlates.map(({ licensePlate }) => licensePlate).join(', ')}
          </LabelValue>
          <div className={classNames(G.fullWidth, G.flexRowEnd)}>
            <Button.Blue visible={!!permit?.downloadUrl} onClick={downloadPermit}>
              <CloudDownload className={G.marginRight} />
              <span>{translate('manualPlacement.downloadPermit')}</span>
            </Button.Blue>
          </div>
        </Grid>
      </PanelContent>
    </Paper>
  );
};
