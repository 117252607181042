import * as React from 'react';
import { Route } from 'react-router';
import { EventsPage } from './pages/events.page';
import { ForecastPage } from './pages/forecast.page';
import { ModuleDetailPage } from './pages/module-detail.page';
import { ModulesPage } from './pages/modules.page';
import { SignDetailPage } from './pages/sign-detail.page';
import { SignsMapPage } from './pages/signs-map.page';
import { SignsPage } from './pages/signs.page';
import { RouterMenu } from '../components';
import { translate } from '../common/translations/translate';
import { appUrls } from '../common/config/url.constants';
import { useSelector } from 'react-redux';
import { getIsAclUser, selectUserAcl } from '../common/store/user/user.selectors';
import { IModulesAclActions, ISignsAclActions } from '../types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  menu: {
    marginBottom: '20px',
  },
});

export const SignsRoutes = () => {
  const C = useStyles();
  const userAcl = useSelector(selectUserAcl);
  const isAcl = useSelector(getIsAclUser);

  return (
    <>
      <RouterMenu
        className={C.menu}
        tabs={[
          {
            label: translate('Menu.Sign'),
            route: appUrls.signs.base,
            visible: !isAcl || !!userAcl?.[ISignsAclActions.viewSign],
          },
          {
            label: translate('Menu.Forecast'),
            route: appUrls.signs.forecast,
            visible: !isAcl || !!userAcl?.[ISignsAclActions.viewSign],
          },
          {
            label: translate('Menu.Modules'),
            route: appUrls.signs.modules.base,
            visible: !isAcl || !!userAcl?.[IModulesAclActions.viewModule],
          },
          {
            label: translate('Menu.SignMap'),
            route: appUrls.signs.map,
            visible: !isAcl || !!userAcl?.[ISignsAclActions.viewLocation],
          },
          {
            label: translate('Menu.Events'),
            route: appUrls.signs.events,
            visible: !isAcl || !!userAcl?.[ISignsAclActions.viewSignEvents],
          },
        ]}
      />
      <Route exact={true} path={appUrls.signs.base} component={SignsPage} />
      <Route exact={true} path={appUrls.signs.map} component={SignsMapPage} />
      <Route exact={true} path={appUrls.signs.detail()} component={SignDetailPage} />
      <Route exact={true} path={appUrls.signs.forecast} component={ForecastPage} />
      <Route exact={true} path={appUrls.signs.modules.base} component={ModulesPage} />
      <Route exact={true} path={appUrls.signs.modules.detail()} component={ModuleDetailPage} />
      <Route exact={true} path={appUrls.signs.events} component={EventsPage} />
    </>
  );
};
