import { Actions, TableActions } from '../../common';
import { ISgwPermitCondition, ISgwPermitConditionsFilter } from '../../types';

class SgwPermitConditionsActionsClass extends Actions {
  list = new TableActions<ISgwPermitCondition, ISgwPermitConditionsFilter>(`${this.prefix}.list`);
  setLoading = this.createPayloadAction<boolean>('setLoading');
  set = this.createPayloadAction<ISgwPermitCondition>('set');
  fetch = this.createPayloadAction<string>('fetch');
  save = this.createPayloadAction<ISgwPermitCondition>('save');
  setExistingReferenceCodes = this.createPayloadAction<string[]>('setExistingReferenceCodes');
  searchReferenceCode = this.createPayloadAction<string>('searchReferenceCode');
  setExistingTitles = this.createPayloadAction<string[]>('setExistingTitles');
  searchTitle = this.createPayloadAction<string>('searchTitle');
}

export const SgwPermitConditionsActions = new SgwPermitConditionsActionsClass('sgwPermitConditions');
