import { Actions, TableActions } from '../../common';
import { ISgwNatureOfWork, ISgwNatureOfWorksFilter } from '../../types';

class SgwNatureOfWorksActionsClass extends Actions {
  list = new TableActions<ISgwNatureOfWork, ISgwNatureOfWorksFilter>(`${this.prefix}.list`);
  setLoading = this.createPayloadAction<boolean>('setLoading');
  set = this.createPayloadAction<ISgwNatureOfWork>('set');
  fetch = this.createPayloadAction<string>('fetch');
  save = this.createPayloadAction<ISgwNatureOfWork>('save');
}

export const SgwNatureOfWorksActions = new SgwNatureOfWorksActionsClass('sgwNatureOfWorks');
