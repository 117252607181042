import { ISgwPermitCondition } from '../../../types';
import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { ColonSeparatedTitle } from '../ColonSeparatedTitle/ColonSeparatedTitle.component';
import { createUseStyles } from 'react-jss';

interface IProps extends ISgwPermitCondition {}

const useStyles = createUseStyles({
  row: {
    margin: '20px',
  },
  referenceCode: {
    paddingRight: '5px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export const SelectedPermitConditionRow: FC<IProps> = ({ referenceCode, title, description }) => {
  const C = useStyles();
  return (
    <div className={C.row}>
      <Grid container alignItems="center">
        <Grid item xs={2} className={C.referenceCode}>
          {referenceCode}
        </Grid>
        <Grid item xs={10}>
          <ColonSeparatedTitle title={title}>{description}</ColonSeparatedTitle>
        </Grid>
      </Grid>
    </div>
  );
};
