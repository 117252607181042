import { combineReducers } from 'redux';
import {
  generateArrayReducer,
  generateBooleanReducer,
  generateObjectReducer,
  generatePayloadReducer,
  generatePayloadReducerById,
  generatePrimitiveReducer,
} from '../../../common';
import {
  IRequest,
  IRequestMessageAttachments,
  IRequestMessagesList,
  IRequestOverview,
  IRequestReason,
  IRequestReasonFilter,
  IRequestsFilter,
  ISuggestion,
} from '../../../types';
import {
  RequestActions,
  RequestLocationsActions,
  setRequestMessageAttachments,
  setRequestMessagesList,
  setRequestReasonFilters,
  setRequestReasons,
} from '../../actions';
import draftIds from './draftIds.reducer';
import conflicts from './conflicts';
import detail from './detail';
import metadata from './metadata.reducer';
import requestExtensions from './requestExtensions.reducer';
import requestDetail from './requestDetail.reducer';
import locationDetail from './locationDetail.reducer';
import history from './history';
import { initialRequestsState } from '../../state';
import { createPagedTableReducer } from '../pagedTable.reducer';
import { CarFreeZonesActions } from '../../../common/store/car-free-zones/carFreeZones.actions';
import { UserActions } from '../../../common/store/user/user.actions';

export const requestsReducer = combineReducers({
  list: createPagedTableReducer<IRequestOverview, IRequestsFilter>({
    fetchAction: RequestActions.list.fetch.type,
    addEntitiesActions: [
      CarFreeZonesActions.requestList.set.type,
      RequestActions.list.set.type,
      RequestActions.setDrafts.type,
      UserActions.requestList.set.type,
    ],
    setAllIdsAction: RequestActions.list.set.type,
    setParamsActions: [RequestActions.list.fetch.type, RequestActions.list.setParams.type],
    setLoadingAction: RequestActions.list.setLoading.type,
    initialParamsState: initialRequestsState.list.table,
  }),
  alreadyAssigned: generateBooleanReducer([RequestActions.setAlreadyAssigned.type]),
  draftIds,
  conflicts,
  copyRequest: generatePrimitiveReducer<IRequest | null>(
    RequestActions.setCopyRequest.type,
    initialRequestsState.copyRequest,
  ),
  currentConflictKey: generatePrimitiveReducer<number | null>(RequestActions.setCurrentConflictKey.type, null),
  detail,
  exporting: generateBooleanReducer([RequestActions.setExporting.type]),
  geocodesById: generatePayloadReducerById<ReturnType<typeof RequestLocationsActions.setGeocode>>([
    RequestLocationsActions.setGeocode.type,
  ]),
  history,
  loading: generateBooleanReducer([RequestActions.setLoading.type]), // TODO only use this for loading a single request!!
  saving: generateBooleanReducer([RequestActions.setSaving.type]),
  locationDetail,
  metadata,
  request: requestDetail,
  requestExtensions,
  requestMessageAttachments: generateObjectReducer<IRequestMessageAttachments>(
    setRequestMessageAttachments.type,
    initialRequestsState.requestMessageAttachments,
  ),
  requestMessagesList: generateObjectReducer<IRequestMessagesList>(
    setRequestMessagesList.type,
    initialRequestsState.requestMessagesList,
  ),
  requestReasonFilters: generateArrayReducer<IRequestReasonFilter>(
    setRequestReasonFilters.type,
    initialRequestsState.requestReasonFilters,
  ),
  requestReasons: generateArrayReducer<IRequestReason>(setRequestReasons.type, initialRequestsState.requestReasons),
  reverseGeocodesById: generatePayloadReducerById<ReturnType<typeof RequestLocationsActions.setReverseGeocode>>([
    RequestLocationsActions.setReverseGeocode.type,
  ]),
  suggestions: generatePayloadReducer<ISuggestion[]>([RequestLocationsActions.setSuggestions.type], []),
  isGeoCodeCorrect: generateBooleanReducer([RequestActions.setGeoCodeCorrect.type], undefined),
});
