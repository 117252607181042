import { formatDateTimeToDisplayDate } from '../../../common/utils/date.util';
import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

interface IProps {
  title: string;
  date: string;
  time?: string;
}

const useStyles = makeStyles({
  bold: {
    fontWeight: 'bold',
  },
});

export const DateField: FC<IProps> = ({ title, date, time }) => {
  const C = useStyles();

  return (
    <div>
      <div className={C.bold}>{title}</div>
      <div>{formatDateTimeToDisplayDate(date, time)}</div>
    </div>
  );
};
