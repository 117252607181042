import React, { FC } from 'react';
import { CancelOutlined } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { ISgwRequestAclActions, SgwRequestTransition } from '../../../types';
import { useModalHandler } from '../../../hooks';
import { getSgwRequestAssignStatus, selectSgwRequest } from '../../../store/selectors/sgwRequest.selectors';
import { Auth } from '../..';
import { Visible } from '../../../common/components/layout/Visible.component';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { translate } from '../../../common/translations/translate';
import ModalDialog from '../../../common/components/modal-dialog/modal-dialog.component';
import { makeStyles } from '@material-ui/styles';
import { SgwRequestActions } from '../../../store/actions';

const useStyles = makeStyles({
  button: {
    marginLeft: '10px',
  },
  outline: {
    fontSize: '17px',
  },
});

export const CancelButton: FC = () => {
  const C = useStyles();
  const dispatch = useDispatch();
  const { open, openModal, closeModal } = useModalHandler();
  const { allowEditRequest } = useSelector(getSgwRequestAssignStatus);
  const request = useSelector(selectSgwRequest);
  const canCancel = request?.acl?.canCancel;

  const onConfirm = () => {
    closeModal();
    request && dispatch(SgwRequestActions.postTransition({ id: request.id, transition: SgwRequestTransition.cancel }));
  };

  return (
    <Auth acl={ISgwRequestAclActions.sgwRequestSetStateToCancelled}>
      <Visible visible={allowEditRequest}>
        <div>
          <Button.White
            data-testid="cancellationButton"
            iconButton
            onClick={openModal}
            className={C.button}
            visible={canCancel}
            tooltip={translate('Requests.Header.cancellation.confirmTitle')}
          >
            <CancelOutlined className={C.outline} color="primary" />
          </Button.White>
        </div>
        <ModalDialog
          okButtonText={translate('ok')}
          onClose={closeModal}
          onConfirm={onConfirm}
          title={translate('Requests.Header.cancellation.confirmTitle')}
          visible={open}
        >
          <span>{translate('Requests.Header.cancellation.confirmDescription')}</span>
        </ModalDialog>
      </Visible>
    </Auth>
  );
};
