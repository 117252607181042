import { translate } from '../common/translations/translate';

export const checkSSN =
  (required: boolean = true) =>
  (value?: string) => {
    let errorMessage;

    if (required) {
      errorMessage = validateRequired(value);
    }

    if (value && !validateSSN(value)) {
      errorMessage = translate('noValidSsn.general');
    }

    return errorMessage || true;
  };

export const validateSSN = (ssn: string) => {
  const personalBase = ssn.substr(0, 9);
  const pre2000 = parseInt(personalBase); // dates before 2000
  const post2000 = parseInt(`2${personalBase}`); // dates after 2000
  const check = parseInt(ssn.substr(9, 2));

  return ssn.match(/^[\d]{11}$/) && (97 - (pre2000 % 97) === check || 97 - (post2000 % 97) === check);
};

const validateRequired = (value?: string | {}): string | null => {
  const message = translate('required');
  if (!value || (typeof value === 'object' && Object.keys(value).length === 0)) {
    return message;
  } else {
    if (typeof value === 'string') {
      if (value === '') {
        return message;
      }
    }
  }
  return null;
};
