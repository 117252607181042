import { combineReducers } from 'redux';
import { createPagedTableReducer } from '../pagedTable.reducer';
import { generateBooleanReducer } from '../../../common';
import { ISgwAdvisingParty } from '../../../types';
import { SgwAdvisingPartiesActions } from '../../actions';
import { initialSgwAdvisingPartiesState } from '../../state';

export const advisingPartiesReducer = combineReducers({
  list: createPagedTableReducer<ISgwAdvisingParty>({
    fetchAction: SgwAdvisingPartiesActions.list.fetch.type,
    addEntitiesActions: [SgwAdvisingPartiesActions.list.set.type],
    addEntityActions: [SgwAdvisingPartiesActions.set.type],
    setAllIdsAction: SgwAdvisingPartiesActions.list.set.type,
    setParamsActions: [SgwAdvisingPartiesActions.list.fetch.type, SgwAdvisingPartiesActions.list.setParams.type],
    initialParamsState: initialSgwAdvisingPartiesState.list.table,
  }),
  loading: generateBooleanReducer([SgwAdvisingPartiesActions.setLoading.type], true),
});
