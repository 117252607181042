import { Button, Tooltip } from '@material-ui/core';
import * as React from 'react';
import { CSSProperties, FunctionComponent, MouseEvent, useState } from 'react';
import { IAsignButtonProps } from '../../../types';

const styles = {
  iconButton: {
    minWidth: '30px',
    height: '30px',
    width: '30px',
  },
  noTextTransform: {
    textTransform: 'none',
  },
};

const AsignButton: FunctionComponent<IAsignButtonProps> = ({
  className,
  dataTestId,
  debounceTime = 0,
  iconButton = false,
  noTextTransform = true,
  onClick,
  style,
  tooltip = '',
  visible = true,
  type = 'submit',
  ...props
}) => {
  const [debounced, setDebounced] = useState<boolean>(false);
  const [hideClickEffect, setHideClickEffect] = useState<boolean>(false);

  const handleOnClick = (event: MouseEvent<HTMLElement>) => {
    if (!debounced && onClick) {
      if (debounceTime) {
        setDebounced(true);
        if (debounceTime > 200) {
          setTimeout(() => {
            setHideClickEffect(true);
          }, 200);
        }
      }
      onClick(event as any);
      setTimeout(() => {
        setDebounced(false);
        setHideClickEffect(false);
      }, debounceTime);
    }
  };

  const _style = {
    ...(noTextTransform ? styles.noTextTransform : undefined),
    ...(iconButton ? styles.iconButton : undefined),
    whiteSpace: 'nowrap',
    ...style,
  };

  return visible ? (
    <Tooltip title={tooltip}>
      <span>
        <Button
          className={className}
          data-testid={dataTestId}
          disableRipple={hideClickEffect}
          disableTouchRipple={hideClickEffect}
          disableFocusRipple={hideClickEffect}
          focusRipple={hideClickEffect}
          size="small"
          style={_style as CSSProperties}
          type={type}
          variant="contained"
          {...props}
          onClick={handleOnClick}
        />
      </span>
    </Tooltip>
  ) : null;
};

export default AsignButton;
