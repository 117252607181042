import { ExpansionPanelSummary } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React, { FC } from 'react';
import { ICollapsablePanelProps } from '../../../types';

export const CollapsablePanelSummary: FC<ICollapsablePanelProps> = ({ noTitleWrap, title }) => (
  <ExpansionPanelSummary expandIcon={<ExpandMore />} IconButtonProps={{ color: 'inherit' }}>
    {noTitleWrap ? title : <div className="collapsable-expansion-title">{title}</div>}
  </ExpansionPanelSummary>
);
