import { Grid } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import React, { FC, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { COLORS } from '../../../common';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { Visible } from '../../../common/components/layout/Visible.component';
import { translate, translateIgnoreTS } from '../../../common/translations/translate';
import { formatDateTimeToDisplayDateTime } from '../../../common/utils/date.util';
import { useRequestAuthorization } from '../../../hooks';
import { SgwRequestActions } from '../../../store/actions';
import { useGeneralStyles } from '../../../style/generalStyles';
import { ConflictCategory, IConflictGroupWithPhaseIds, ISgwRequestDetailAclActions } from '../../../types';
import { Auth } from '../../atoms';
import { PhaseNameDatesComponent } from '../../atoms/PhaseNameDates/PhaseNameDates.component';
import { RichEditor } from '../../atoms/RichEditor/RichEditor.component';

interface IProps {
  conflictGroup: IConflictGroupWithPhaseIds;
}

const useStyles = createUseStyles({
  input: {
    width: '100%',
    borderColor: COLORS.GREY_LIGHTER,
    padding: '5px',
  },
  icon: {
    width: '20px',
    marginRight: '10px',
  },
  editor: {
    padding: '15px',
  },
});

export const SgwConflictInfo: FC<IProps> = ({
  conflictGroup: {
    remarks: advice,
    conflictCategory,
    phaseIds,
    isCreatedManually,
    sgwRequestId,
    id,
    conflicts,
    requestedAdviceAt,
    resolvedAt,
    lastConflictSyncAt,
  },
}) => {
  const C = useStyles();
  const G = useGeneralStyles();
  const dispatch = useDispatch();

  const [toBeSavedAdvice, setToBeSavedAdvice] = useState<string>(advice || '');
  const [isAdviceChanged, setAdviceChanged] = useState<boolean>(false);
  const { isAuthorized: useSgwConflictRemarksAdvisingParty } = useRequestAuthorization(
    ISgwRequestDetailAclActions.useSgwConflictRemarksAdvisingParty,
  );

  useEffect(() => {
    setToBeSavedAdvice(advice || '');
  }, [advice]);

  useEffect(() => {
    let prevState = (advice || '').trim();
    let newState = (toBeSavedAdvice || '').trim();
    if (newState === '<p></p>') {
      newState = '';
    }

    setAdviceChanged(prevState !== newState);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toBeSavedAdvice]);

  const isConstructionParkingEvent = () =>
    (conflictCategory === ConflictCategory.constructionZone ||
      conflictCategory === ConflictCategory.parkingBan ||
      conflictCategory === ConflictCategory.event) &&
    !isCreatedManually;

  const saveAdvice = () => {
    dispatch(
      SgwRequestActions.conflicts.save({
        requestId: sgwRequestId,
        conflictGroup: {
          id: id,
          remarks: toBeSavedAdvice,
        },
      }),
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        {translate('sgw.requests.detail.conflicts.conflictInformation.category')}
      </Grid>
      <Grid item xs={8}>
        {translateIgnoreTS(`sgw.requests.detail.conflicts.conflictCategory.${conflictCategory}`)}
      </Grid>
      <Visible visible={isConstructionParkingEvent()}>
        <Grid item xs={4}>
          {translate('sgw.requests.detail.conflicts.conflictInformation.dossierNumber')}
        </Grid>
        <Grid item xs={8}>
          {conflicts.map((conflict) => (
            <span key={conflict.id} className={G.marginRight}>
              {conflict.dossierNumber}
            </span>
          ))}
        </Grid>
      </Visible>
      <Visible visible={!!phaseIds.length}>
        <Grid item xs={4}>
          {translate('sgw.requests.detail.conflicts.conflictInformation.detectedInPhase')}
        </Grid>
        <Grid item xs={8}>
          {phaseIds.map((phaseId: number, index) => (
            <PhaseNameDatesComponent phaseId={phaseId} key={phaseId} index={index + 1} />
          ))}
        </Grid>
      </Visible>
      <Visible visible={isConstructionParkingEvent()}>
        <Grid item xs={4}>
          {translate('sgw.requests.detail.conflicts.conflictInformation.amountDetected')}
        </Grid>
        <Grid item xs={8}>
          {conflicts.length}
        </Grid>
      </Visible>
      <Visible visible={!!requestedAdviceAt}>
        <Grid item xs={4}>
          {translate('sgw.requests.detail.conflicts.conflictInformation.requestedAdviceAt')}
        </Grid>
        <Grid item xs={8}>
          {formatDateTimeToDisplayDateTime(requestedAdviceAt!)}
        </Grid>
      </Visible>
      <Visible visible={!!resolvedAt}>
        <Grid item xs={4}>
          {translate('sgw.requests.detail.conflicts.conflictInformation.resolvedAt')}
        </Grid>
        <Grid item xs={8}>
          {formatDateTimeToDisplayDateTime(resolvedAt!)}
        </Grid>
      </Visible>
      <Visible visible={!!lastConflictSyncAt}>
        <Grid item xs={4}>
          {translate('sgw.requests.detail.conflicts.conflictInformation.lastConflictSyncAt')}
        </Grid>
        <Grid item xs={8}>
          {formatDateTimeToDisplayDateTime(lastConflictSyncAt!)}
        </Grid>
      </Visible>
      <Auth acl={[ISgwRequestDetailAclActions.viewSgwConflictRemarksAdvisingParty]}>
        <Grid item xs={4}>
          {translate('sgw.requests.detail.conflicts.conflictInformation.advice')}
        </Grid>
        <Grid item xs={12}>
          <RichEditor
            onChange={setToBeSavedAdvice}
            editorClassName={C.editor}
            value={toBeSavedAdvice}
            placeholder={translate('sgw.requests.detail.conflicts.conflictInformation.noAdvice')}
            readOnly={!useSgwConflictRemarksAdvisingParty}
          />
          <Visible visible={useSgwConflictRemarksAdvisingParty}>
            <div className={G.flexRowEnd}>
              <Button.Green
                disabled={!isAdviceChanged}
                onClick={saveAdvice}
                size="small"
                startIcon={<Save fontSize="small" />}
                type="button"
              >
                {translate('sgw.requests.detail.conflicts.saveAdvice')}
              </Button.Green>
            </div>
          </Visible>
        </Grid>
      </Auth>
    </Grid>
  );
};
