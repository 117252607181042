import { createSelector } from '@reduxjs/toolkit';
import { IRootState } from '../../../root.state';
import {
  IAclActions,
  IAclMatrix,
  ICarFreeZoneAclActions,
  ICpaAppAclActions,
  INotificationAclActions,
  IPlanningAclActions,
  IRequestAclActions,
  IServiceAclActions,
  ISgwAdvisingPartiesAclActions,
  ISgwNatureOfWorksAclActions,
  ISgwPermitConditionsAclActions,
  ISgwProjectAclActions,
  ISgwRequestAclActions,
  ISgwSettings,
  ISgwTrafficMeasureAclActions,
  ISignsAclActions,
  IUserManagementAclActions,
  IUtilityCompanyAclActions,
} from '../../../types';
import {
  isMProfile,
  IUserApi,
  USER_ROLE_ACL,
  userHasAtLeastOneOfAcls,
  userHasAtLeastOneOfRoles,
} from '../../types/user';

import { createAllEntitiesSelector } from '../../utils/normalized.util';
import { selectUser } from '../../../store/selectors';
import { selectCount } from '../../../store/selectors/notifications.selectors';
import { translate } from '../../translations/translate';
import { appUrls } from '../../config/url.constants';
import { Badge } from '@material-ui/core';
import * as React from 'react';
import { selectSgwRequestAcl } from '../../../store/selectors/sgwRequest.selectors';

export const selectUserAcl = (store: IRootState) => store.user.user?.acl;
export const selectUserRoles = (store: IRootState) => store.user.user?.roles;
export const selectAllRoles = (store: IRootState) => store.user.allRoles;
export const selectAdmins = (store: IRootState) => store.user.admins;
export const selectListById = (store: IRootState) => store.user.list.byId;
export const selectListAllIds = (store: IRootState) => store.user.list.allIds;
export const selectListLoading = (store: IRootState) => store.user.list.loading;
export const selectSelectedId = (store: IRootState) => store.user.selectedId;
export const selectUserTable = (store: IRootState) => store.user.list.table;

export const getIsAclUser = createSelector([selectUserRoles], (roles = []) =>
  // https://rombitbe.atlassian.net/browse/AS-7382 => remove this selector and update all its depending code.
  roles.some(({ id }) => id === USER_ROLE_ACL),
);

export const getUser = (id?: string) =>
  createSelector([selectListById], (selectById) => {
    if (!id || !selectById?.[id]) {
      return undefined;
    }

    const { roles, ...userData } = selectById[id];

    return {
      ...userData,
      roles: roles?.filter((role) => !role.derived) || [],
    };
  });

export const currentUserHasRoleOrAclAction = (roles: string[], acls: string[]) =>
  createSelector([selectUser], (user) => userHasAtLeastOneOfRoles(user, roles) || userHasAtLeastOneOfAcls(user, acls));

export const getIsAuthorized = (requiredAcl: IAclActions[], allAclNeeded: boolean = false) =>
  createSelector([getIsAclUser, selectUserAcl], (isAclUser, acl): boolean => {
    if (!isAclUser) return false;
    return allAclNeeded
      ? // @ts-ignore - Complaining about index might be any
        requiredAcl.every((aclAction) => acl && !!acl[aclAction])
      : // @ts-ignore - Complaining about index might be any
        requiredAcl.some((aclAction) => acl && !!acl[aclAction]);
  });

export const getIsRequestAuthorized = (requiredAcl: IAclActions[], allAclNeeded: boolean = false) =>
  createSelector([getIsAclUser, selectSgwRequestAcl], (isAclUser, acl): boolean => {
    if (!isAclUser) return false;
    return allAclNeeded
      ? // @ts-ignore - Complaining about index might be any
        requiredAcl.every((aclAction) => acl && !!acl[aclAction])
      : // @ts-ignore - Complaining about index might be any
        requiredAcl.some((aclAction) => acl && !!acl[aclAction]);
  });

export const getCanOpenRequestDetails = createSelector(
  [selectUserAcl],
  (acl: IAclMatrix | undefined) => !!acl?.[IRequestAclActions.viewRequest],
);
export const getAdmins = createSelector([selectAdmins], (admins) =>
  admins.map(({ id, fullName }) => ({ value: id, label: fullName })),
);
export const getList = createAllEntitiesSelector(selectListAllIds, selectListById);

export const getListMenuItems = (excludedList: number[] = []) =>
  createSelector([getList], (users) =>
    users
      .filter(({ id }) => !excludedList.includes(id))
      .map(({ id, fullName, email }) => ({ value: id, label: `${fullName} (${email})` })),
  );

export const getUsername = () => createSelector([selectUser], (user) => (user ? user.fullName : ''));

export const getSelectedUser = createSelector([selectSelectedId, selectListById], (selectedId, list) =>
  selectedId ? list[selectedId] : undefined,
);

export const getIsMProfile = () => createSelector([selectUser], isMProfile);

export const getUsersApiParams = createSelector([selectUserTable], ({ filters, paging, sorting }) => {
  let params: IUserApi = {
    page: paging.page,
    page_size: paging.pageSize,
    sort: sorting.key,
    order: sorting.direction,
    ...filters,
  };

  return params;
});

export const selectUserById = (id?: string) => createSelector([selectListById], (byId) => (id ? byId[id] : undefined));

export const getUsersByIds = (ids: number[]) => createSelector([selectListById], (byId) => ids.map((id) => byId[id]));

export const getUserCanOnlySeeAdvisingPartyRequests = createSelector(
  [
    getIsAuthorized([ISgwAdvisingPartiesAclActions.viewSgwAdvisingPartyRequestOverview]),
    getIsAuthorized([ISgwRequestAclActions.viewSgwRequestOverview]),
  ],
  (canViewAdvisingPartyRequests, canViewAllRequests) => canViewAdvisingPartyRequests && !canViewAllRequests,
);

export const getAsignTabs = createSelector([selectUserAcl, selectCount], (userAcl, notificationCount) => [
  {
    label: translate('mainMenu.requests'),
    route: appUrls.requests.base,
    visible: !!userAcl?.[IRequestAclActions.viewRequest],
  },
  {
    label: translate('mainMenu.planning'),
    route: appUrls.planning.base,
    visible: !!userAcl?.[IPlanningAclActions.viewPlanning],
  },
  {
    label: translate('mainMenu.signs'),
    route: appUrls.signs.base,
    visible: !!userAcl?.[ISignsAclActions.viewSign],
  },
  {
    label: (
      <Badge color="error" badgeContent={notificationCount}>
        {translate('mainMenu.notifications')}
      </Badge>
    ),
    route: appUrls.notifications.base,
    visible: !!userAcl?.[INotificationAclActions.viewNotification],
  },
  {
    label: translate('mainMenu.cpa'),
    route: appUrls.cpa.base,
    visible: !!userAcl?.[ICpaAppAclActions.cpaAppUser],
  },
]);

export const getSgwTabs = createSelector([selectUserAcl], (userAcl) => [
  {
    label: translate('mainMenu.sgwRequests'),
    route: appUrls.sgw.requests.base,
    visible:
      !!userAcl?.[ISgwRequestAclActions.viewSgwRequestOverview] ||
      !!userAcl?.[ISgwAdvisingPartiesAclActions.viewSgwAdvisingPartyRequestOverview],
  },
  {
    label: translate('mainMenu.sgwManage'),
    route: appUrls.sgw.manage.base,
    visible:
      !!userAcl?.[ISgwPermitConditionsAclActions.viewSgwPermitConditionsOverview] ||
      !!userAcl?.[ISgwSettings.viewSgwSettings] ||
      !!userAcl?.[ISgwProjectAclActions.viewSgwProjectOverview] ||
      !!userAcl?.[ISgwNatureOfWorksAclActions.viewSgwNatureOfWorksOverview] ||
      !!userAcl?.[ISgwTrafficMeasureAclActions.viewSgwTrafficMeasuresOverview] ||
      !!userAcl?.[ISgwAdvisingPartiesAclActions.viewAdvisingPartiesOverview],
  },
]);

export const getMasterTabs = createSelector([selectUserAcl], (userAcl) => [
  {
    label: translate('mainMenu.services'),
    route: appUrls.services.base,
    visible: !!userAcl?.[IServiceAclActions.viewServiceList],
  },
  {
    label: translate('mainMenu.carFreeZones'),
    route: appUrls.carFreeZones.base,
    visible:
      !!userAcl?.[ICarFreeZoneAclActions.viewCarFreeZoneList] || !!userAcl?.[ICarFreeZoneAclActions.viewExemptions],
  },
  {
    label: translate('mainMenu.utilityCompanies'),
    route: appUrls.utilityCompanies.base,
    visible: !!userAcl?.[IUtilityCompanyAclActions.viewUtilityCompany],
  },
  {
    label: translate('mainMenu.users'),
    route: appUrls.users.base,
    visible: !!userAcl?.[IUserManagementAclActions.viewUser],
  },
]);

export const getAllTabs = createSelector(
  [getAsignTabs, getSgwTabs, getMasterTabs],
  (asignTabs, sgwTabs, masterTabs) => [...asignTabs, ...sgwTabs, ...masterTabs],
);

export const getVisibleTabs = createSelector([getAllTabs], (allTabs) => allTabs.filter((tab) => tab.visible));

export const getFirstVisibleTab = createSelector([getAllTabs], (allTabs) => allTabs.find((tab) => tab.visible));

export const getTabsAreVisible = createSelector([getVisibleTabs], (visibleTabs) => visibleTabs.length > 1);
