import { Grid } from '@material-ui/core';
import I18n from 'i18n-js';
import * as React from 'react';
import { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Panel } from '../../../common';
import { Loader } from '../../../common/components/layout/loader.component';
import { IRequestDetailComponent } from '../../../types';
import { Messages } from '../messages/messages.component';
import { selectRequestLoading, selectRequestMessages } from '../../../store/selectors';
import { fetchRequestMessages } from '../../../store/actions';

export const MessagesPanel: FunctionComponent<IRequestDetailComponent> = ({ requestId, visible = true }) => {
  const messages = useSelector(selectRequestMessages);
  const loading = useSelector(selectRequestLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    if (requestId) {
      // Dispatch action to load request messages
      dispatch(fetchRequestMessages(requestId));
    }
  }, [dispatch, requestId]);

  return visible ? (
    <Panel data-testid="messages_panel" title={I18n.t('Requests.Detail.Messages.Title')}>
      <Grid container spacing={2} style={{ marginTop: 20, marginBottom: 0, padding: 8 }}>
        <Loader loading={loading}>
          <Messages messagesList={messages} requestId={requestId} />
        </Loader>
      </Grid>
    </Panel>
  ) : null;
};
