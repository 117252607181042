import * as React from 'react';
import { Route } from 'react-router';
import { ReorderingPage } from './pages/reordering.page';
import { appUrls } from '../common/config/url.constants';

export const TourReorderingRoutes = () => (
  <>
    <Route exact path={appUrls.planning.reordering.detail()} component={ReorderingPage} />
  </>
);
