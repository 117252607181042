import { Button } from '@material-ui/core';
import I18n from 'i18n-js';
import * as React from 'react';

export const DeleteButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Button variant="contained" color="primary" onClick={onClick}>
      {I18n.t('DeleteSign')}
    </Button>
  );
};
