import React, { ChangeEvent, FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { IWithClassName, IWithComponentName } from '../../../types';
import { useRadioButtonListStyles } from './RadioButtonList.styles';
import { Visible } from '../../../common/components/layout/Visible.component';
import { Children } from '../Children/Children.component';
import { useGeneralStyles } from '../../../style/generalStyles';
import { ErrorLabel } from '../ErrorLabel/ErrorLabel.component';

interface IListProps extends IWithClassName {
  id: string;
  value?: string;
  name?: string;
  initialValue?: string;
  onChange?(e: ChangeEvent<HTMLInputElement>): void;
}

interface IHeaderProps extends IWithComponentName, IWithClassName {
  id?: string;
}

interface IItemProps extends Partial<IListProps>, IWithClassName, IWithComponentName {
  value: string;
}

interface IRequiredErrorProps extends IWithComponentName {
  show?: boolean;
}

interface RadioButtonListFC extends FC<PropsWithChildren<IListProps>> {
  Header: FC<PropsWithChildren<IHeaderProps>>;
  Item: FC<PropsWithChildren<IItemProps>>;
  RequiredError: FC<IRequiredErrorProps>;
}

const RadioButtonList: RadioButtonListFC = ({ children, className, id, value, ...props }) => {
  const C = useRadioButtonListStyles();

  return (
    <div className={classNames(useGeneralStyles().flexColumn, C.list, className)} id={id}>
      <Children<IHeaderProps> componentNames={['RadioButtonListHeader']} id={id}>
        {children}
      </Children>
      <Children<IListProps> {...props} componentNames={['RadioButtonListItem']} id={id}>
        {children}
      </Children>
      <Children componentNames={['RadioButtonListRequiredError']}>{children}</Children>
    </div>
  );
};

const RadioButtonListHeader: FC<PropsWithChildren<IHeaderProps>> = ({ children, className, id }) => (
  <Visible visible={!!children}>
    <label htmlFor={id} className={classNames(useRadioButtonListStyles().marginBottom, className)}>
      {children}
    </label>
  </Visible>
);

const RadioButtonListItem: FC<PropsWithChildren<IItemProps>> = ({
  children,
  className,
  componentName,
  initialValue,
  id,
  ...props
}) => {
  const C = useRadioButtonListStyles();
  const fullId = `${id}-${props.value}`;

  return (
    <div className={classNames(useGeneralStyles().flexRowCenter, className)}>
      <input {...props} type="radio" id={fullId} className={C.input} checked={initialValue === props.value} />
      <label htmlFor={fullId} className={C.radio}>
        {children}
      </label>
    </div>
  );
};

const RadioButtonListRequiredError: FC<PropsWithChildren<IRequiredErrorProps>> = ({ children, show = false }) => (
  <ErrorLabel showError={show} message={typeof children === 'string' ? children : undefined} />
);

RadioButtonListHeader.defaultProps = { componentName: 'RadioButtonListHeader' };
RadioButtonListItem.defaultProps = { componentName: 'RadioButtonListItem' };
RadioButtonListRequiredError.defaultProps = { componentName: 'RadioButtonListRequiredError' };

RadioButtonList.Header = RadioButtonListHeader;
RadioButtonList.Item = RadioButtonListItem;
RadioButtonList.RequiredError = RadioButtonListRequiredError;
export { RadioButtonList };
