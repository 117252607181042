import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../../common';

export const useRequestDetailStyles = makeStyles({
  gridContainer: {
    width: '100%',
  },
  selectedPrio: {
    color: 'white',
    backgroundColor: 'orange',
    '&:hover': {
      background: '#efefef',
    },
  },
  basePrio: {
    marginRight: '10px',
    textTransform: 'none',
    fontWeight: 500,
  },
  fitContent: {
    height: 'fit-content',
  },
  resetSpacing: {
    width: '100%',
    margin: '0 0 8px -8px',
  },
  bottomSpacing: {
    marginBottom: '1.1rem',
  },
  paperSpacing: {
    marginBottom: '20px',
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  leftPanel: {
    paddingRight: '20px',
  },
  infoText: { color: COLORS.GREY_LIGHT, fontSize: 'small', marginTop: '0.5rem', marginLeft: '8px' },
});
