import React, { FC } from 'react';
import { Container } from '../../../../../../common';
import { Header } from '../../../../../../common/components/layout/Header.component';
import { appUrls } from '../../../../../../common/config/url.constants';
import { translate } from '../../../../../../common/translations/translate';
import { ISgwNatureOfWorksAclActions } from '../../../../../../types';
import { Auth } from '../../../../../atoms';
import { NatureOfWorkForm } from '../../../../../forms';
import { useFormStyles } from '../../../../../../style/form.styles';

export const EditNatureOfWorksPage: FC = () => (
  <Auth acl={ISgwNatureOfWorksAclActions.createSgwNatureOfWorks} showUnauthorizedPage>
    <Header backButtonUrl={appUrls.sgw.manage.natureOfWorks.base}>
      {translate('sgw.natureOfWorks.updateNatureOfWork')}
    </Header>
    <Container className={useFormStyles().container}>
      <NatureOfWorkForm title={translate('sgw.natureOfWorks.detail')} />
    </Container>
  </Auth>
);
