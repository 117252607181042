import axios from 'axios';
import { CANCEL } from 'redux-saga';
import { CONFIG, IApiPromise, IInteractiveList, IPagedApiParams } from '../../common';
import { IModule, IModulesMetadata } from '../types/module';
import { IModuleBatch, IMoveModulesRequest } from '../types/module-batch';
import { IModulePlace } from '../types/module-place';
import { IModuleState } from '../types/module-state';
import { IModuleUpdate } from '../types/module-update';
import { IModulesFilter } from '../types/modules-filter';
import { IBatchesForReorder } from './modules.state';

export const ModulesApi = {
  getModule: (id: string): IApiPromise<IModule> => axios.get(`${CONFIG.backOfficeApi}/v1/modules/${id}`),

  updateModule: (module: IModuleUpdate): IApiPromise<IModule> =>
    axios.patch(`${CONFIG.backOfficeApi}/v1/modules/${module.id}`, {
      batch: module.batch,
      place: module.place,
      state: module.state,
    }),

  deleteModule: (id: string): IApiPromise<void> => axios.delete(`${CONFIG.backOfficeApi}/v1/modules/${id}`),

  fetchList: (params: IPagedApiParams): IApiPromise<IModule[]> =>
    axios.get(`${CONFIG.backOfficeApi}/v1/modules`, { params }),

  getModules: ({ filter, paging, sorting }: IInteractiveList<IModulesFilter>): IApiPromise<IModule[]> => {
    const source = axios.CancelToken.source();
    const request: any = axios.get(`${CONFIG.backOfficeApi}/v1/modules`, {
      cancelToken: source.token,
      params: {
        ...filter,
        order: sorting.direction,
        page: paging.page,
        page_size: paging.pageSize,
        sort: sorting.key,
      },
    });
    // Cancel the request when the saga is cancelled
    request[CANCEL] = () => source.cancel();
    return request;
  },

  getBatches: (): IApiPromise<IModuleBatch[]> => axios.get(`${CONFIG.backOfficeApi}/v1/modules/batches`),
  getBatchesUsed: (): IApiPromise<IModuleBatch[]> => axios.get(`${CONFIG.backOfficeApi}/v1/modules/batches/inuse`),
  getBatchesForReorder: (): IApiPromise<IBatchesForReorder> =>
    axios.get(`${CONFIG.backOfficeApi}/v1/modules/batch_reorder`),

  moveModulesBetweenBatches: ({ count, batchFrom, batchTo }: IMoveModulesRequest): IApiPromise<IBatchesForReorder> =>
    axios.post(`${CONFIG.backOfficeApi}/v1/modules/batch_reorder`, {
      batchFrom,
      batchTo,
      count,
    }),

  getStates: (): IApiPromise<IModuleState[]> => axios.get(`${CONFIG.backOfficeApi}/v1/modules/states`),
  getPlaces: (): IApiPromise<IModulePlace[]> => axios.get(`${CONFIG.backOfficeApi}/v1/modules/places`),
  getModuleCounter: (): IApiPromise<number> => axios.get(`${CONFIG.backOfficeApi}/v1/modules/count`),
  getMetadata: (): IApiPromise<IModulesMetadata> => axios.get(`${CONFIG.backOfficeApi}/v1/metadata/module`),
};
