import { Grid, InputAdornment, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import moment, { max, Moment } from 'moment';
import * as React from 'react';
import { MultiSelect, Panel } from '../../common';
import { AsignDatepicker } from '../../common/components/form-fields/datepicker/asign-datepicker.component';
import { SIGN_TYPES } from '../../signs/signs.constants';
import { WORK_ORDER_ITEM_TYPES } from '../planning.constants';
import { WorkOrderItemTypeFilter } from './work-order-item-type-filter.component';
import { IRequestReasonFilter, IWorkOrderItemsFilter } from '../../types';
import { FC, useEffect, useState } from 'react';
import { translate } from '../../common/translations/translate';
import { getTeamsSelectItems } from '../store/planning.selectors';
import { useSelector } from 'react-redux';
import { SingleSelectV2Component } from '../../common/components/form-fields/single-select-v2.component';

interface IProps {
  filter: IWorkOrderItemsFilter;
  filterChange: (filter: IWorkOrderItemsFilter) => void;
  requestReasonFilters: IRequestReasonFilter[];
}

export const PlanningFilter: FC<IProps> = ({ filter, filterChange, requestReasonFilters }) => {
  const [referenceNumber, setReferenceNumber] = useState('');
  const teamsSelectItems = useSelector(getTeamsSelectItems);

  useEffect(() => {
    // After page reload reset referenceNumber filter if value is invalid
    // otherwise populate field with value stored in filters
    if (referenceNumber.length === 0 && filter.reference_number === 'invalid') {
      filterChange({
        ...filter,
        reference_number: '',
      });
    } else if (filter.reference_number !== 'invalid') {
      setReferenceNumber(filter.reference_number);
    }
  }, [filter, filter.reference_number, filterChange, referenceNumber.length]);

  const onPlannedFilterChange = (planned: boolean | string): void => {
    filterChange({
      ...filter,
      planned: getBooleanFilterValue(planned),
    });
  };

  const onSignTypesFilterChange = (signType: SIGN_TYPES): void => {
    filterChange({
      ...filter,
      sign_types: (signType as string) === ' ' ? [] : [signType],
    });
  };

  const onWorkOrderItemTypeFilterChange = (types: WORK_ORDER_ITEM_TYPES[]): void => {
    filterChange({
      ...filter,
      types,
    });
  };

  const onDaysBeforeDueDateFilterChange = (date: Moment): void => {
    filterChange({
      ...filter,
      due_date: date.format('YYYY-MM-DD'),
    });
  };

  const onDueDateFromFilterChange = (date: Moment): void => {
    filterChange({
      ...filter,
      due_date_from: date.format('YYYY-MM-DD'),
      due_date: date.isSameOrAfter(filter.due_date) ? date.format('YYYY-MM-DD') : filter.due_date,
    });
  };

  const onReasonFilterChange = (reasons: string[]): void => {
    filterChange({
      ...filter,
      reasons,
    });
  };

  const onTeamFilterChange = (team_id: number | ' '): void => {
    filterChange({
      ...filter,
      team_id: team_id === ' ' ? null : team_id,
    });
  };

  const getBooleanFilterValue = (value: boolean | string): boolean | null => {
    return typeof value === 'boolean' ? value : null;
  };

  const getBooleanSelectValue = (value: boolean | null): boolean | string => {
    return typeof value === 'boolean' ? value : ' ';
  };

  const validateReferenceNumber = (val: string) => {
    return val.length === 11 || val.length === 0;
  };

  const onReferenceNumberChange = (e: React.ChangeEvent<any>) => {
    const val = e.target.value;
    setReferenceNumber(e.target.value);
    if (validateReferenceNumber(val)) {
      // Load data only if field have valid value
      filterChange({
        ...filter,
        reference_number: val,
      });
    } else {
      // Prevent browser to reload data on each field change
      if (filter.reference_number !== 'invalid') {
        filterChange({
          ...filter,
          reference_number: 'invalid',
        });
      }
    }
  };

  return (
    <div data-testid="PlanningFilter" style={{ marginBottom: -20, position: 'relative' }}>
      <Panel>
        <Grid container spacing={2} style={{ padding: '10px 15px' }}>
          <Grid item style={{ minWidth: 150, flex: 1 }}>
            <SingleSelectV2Component
              menuItems={[
                { value: ' ', label: translate('All') },
                { value: true, label: translate('planning.filter.planned') },
                { value: false, label: translate('planning.filter.unplanned') },
              ]}
              value={getBooleanSelectValue(filter.planned)}
              onChange={(e) => onPlannedFilterChange(e.target.value as boolean)}
              title={translate('planning.filter.planningStatus')}
            />
          </Grid>
          <Grid item style={{ minWidth: 150, flex: 1 }}>
            <SingleSelectV2Component
              menuItems={[
                { value: ' ', label: translate('All') },
                { value: SIGN_TYPES.DIGITAL, label: translate('planning.filter.digitalSign') },
                { value: SIGN_TYPES.MANUAL, label: translate('planning.filter.manualSign') },
              ]}
              value={filter.sign_types.length > 0 ? filter.sign_types[0] : ' '}
              onChange={(e) => onSignTypesFilterChange(e.target.value as SIGN_TYPES)}
              title={translate('planning.filter.signTypes')}
            />
          </Grid>
          <Grid item style={{ minWidth: 150, flex: 1 }}>
            <SingleSelectV2Component
              menuItems={teamsSelectItems}
              value={filter.team_id || ' '}
              onChange={(e) => onTeamFilterChange(e.target.value as number)}
              title={translate('planning.filter.tour')}
            />
          </Grid>
          <Grid item style={{ minWidth: 220, maxWidth: 250, flex: 1, padding: 0 }}>
            <Grid container justify="space-around" style={{ gridTemplateRows: '25px' }}>
              <WorkOrderItemTypeFilter types={filter.types} typesChange={onWorkOrderItemTypeFilterChange} />
            </Grid>
          </Grid>
          <Grid item style={{ minWidth: 150, maxWidth: 150, flex: 1 }}>
            <TextField
              id="text_search"
              label={translate('planning.filter.referenceNumber')}
              error={!validateReferenceNumber(referenceNumber)}
              value={referenceNumber}
              placeholder={translate('Search')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search style={{ cursor: 'pointer' }} color="action" />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={onReferenceNumberChange}
            />
          </Grid>
          <Grid item style={{ minWidth: 150, maxWidth: 150, flex: 1, zIndex: 1000 }}>
            <AsignDatepicker
              label={translate('planning.filter.ordersFrom')}
              value={moment(filter.due_date_from)}
              onChange={onDueDateFromFilterChange}
              minDate={moment(filter.date)}
            />
          </Grid>
          <Grid item style={{ minWidth: 150, maxWidth: 150, flex: 1, zIndex: 1000 }}>
            <AsignDatepicker
              label={translate('planning.filter.ordersUntil')}
              value={moment(filter.due_date)}
              onChange={onDaysBeforeDueDateFilterChange}
              minDate={max(moment(filter.date), moment(filter.due_date_from))}
            />
          </Grid>
          <Grid item style={{ minWidth: 150, flex: 1 }}>
            <MultiSelect
              data={requestReasonFilters}
              value={requestReasonFilters.length ? filter.reasons : []}
              valueChange={onReasonFilterChange}
              valueProp="id"
              keyProp="id"
              displayProp="name"
              id="reasonFilter"
              label={translate('planning.filter.reason')}
              placeholder={translate('All')}
            />
          </Grid>
        </Grid>
      </Panel>
    </div>
  );
};
