import React, { FC, useCallback } from 'react';
import { useFormStyles } from '../../../../../../style/form.styles';
import { useGeneralStyles } from '../../../../../../style/generalStyles';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Container, IPaging, ISorting } from '../../../../../../common';
import { SgwPermitConditionsActions } from '../../../../../../store/actions';
import {
  ISgwPermitCondition,
  ISgwPermitConditionsAclActions,
  ISgwPermitConditionsFilter,
} from '../../../../../../types';
import { appUrls } from '../../../../../../common/config/url.constants';
import { Auth } from '../../../../../atoms';
import { Button } from '../../../../../../common/components/buttons/asign-button-extensions';
import AddIcon from '@material-ui/icons/Add';
import { translate, translateIgnoreTS } from '../../../../../../common/translations/translate';
import { GenericTablePanel } from '../../../../../../common/components/table/GenericTablePanel.component';
import { useSgwPermitConditions } from '../../../../../../hooks';
import { createUseStyles } from 'react-jss';
import { PermitConditionsFilter } from '../../../../../organisms';
import { initialSgwPermitConditionsState } from '../../../../../../store/state';

const useStyles = createUseStyles({
  referenceCode: {
    width: '150px',
    overflow: 'auto',
  },
});

export const PermitConditionsPage: FC = () => {
  const C = useStyles();
  const M = useFormStyles();
  const G = useGeneralStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    permitConditions,
    table: { filters, paging, sorting },
  } = useSgwPermitConditions();

  const onChangeSorting = (sorting: ISorting) => dispatch(SgwPermitConditionsActions.list.fetch({ sorting }));
  const onChangePaging = (paging: IPaging) => dispatch(SgwPermitConditionsActions.list.fetch({ paging }));
  const onChangeFiltering = (filters: ISgwPermitConditionsFilter) =>
    dispatch(SgwPermitConditionsActions.list.fetch({ filters }));
  const resetFilters = useCallback((): void => {
    dispatch(SgwPermitConditionsActions.list.fetch({ filters: initialSgwPermitConditionsState.list.table.filters }));
  }, [dispatch]);
  const onClick = (permitCondition: ISgwPermitCondition) =>
    history.push(appUrls.sgw.manage.permitConditions.detail(`${permitCondition.id}`));
  const onCreatePermitCondition = () => history.push(appUrls.sgw.manage.permitConditions.create);

  return (
    <Container>
      <Auth acl={ISgwPermitConditionsAclActions.createSgwPermitCondition}>
        <Button.Green
          className={M.createButton}
          dataTestId="newPermitCondition"
          onClick={onCreatePermitCondition}
          startIcon={<AddIcon fontSize={'small'} />}
        >
          {translate('sgw.permitConditions.newPermitCondition')}
        </Button.Green>
      </Auth>
      <Auth acl={ISgwPermitConditionsAclActions.viewSgwPermitConditionsOverview}>
        <GenericTablePanel<ISgwPermitCondition>
          customHeader={
            <PermitConditionsFilter
              changeFilters={onChangeFiltering}
              filters={filters}
              resetFilters={resetFilters}
              showDeletedCheckbox
            />
          }
          dataTestId="SgwPermitConditionsListTable"
          labelUnavailable={translate('sgw.permitConditions.list.unavailable')}
          onChangeSorting={onChangeSorting}
          onChangePaging={onChangePaging}
          onClick={onClick}
          paging={paging}
          sorting={sorting}
          records={permitConditions}
          title={<div className={G.flexRowSpaceBetween}>{translate('sgw.permitConditions.list.title')}</div>}
          columnKey="id"
          columns={[
            {
              label: translate('sgw.permitConditions.columns.referenceCode'),
              name: 'referenceCode',
              sortable: true,
              className: C.referenceCode,
            },
            {
              label: translate('sgw.permitConditions.columns.category'),
              name: 'category',
              sortable: true,
              renderer: (category: string) => translateIgnoreTS(`sgw.permitConditions.categories.${category}`),
            },
            {
              label: translate('sgw.permitConditions.columns.title'),
              name: 'title',
              sortable: true,
            },
            {
              label: translate('sgw.permitConditions.columns.description'),
              name: 'description',
              sortable: true,
            },
          ]}
        />
      </Auth>
    </Container>
  );
};
