import { Grid } from '@material-ui/core';
import React, { FC } from 'react';
import { Visible } from '../../../common/components/layout/Visible.component';
import { translate, translateIgnoreTS } from '../../../common/translations/translate';
import { IPhaseQuarterCost } from '../../../types/SgwQuarterCost.types';
import { useStyles } from './RetributionTable.styles';

interface IProps extends IPhaseQuarterCost {
  index: number;
  recurringPhase?: boolean;
  recurringphases?: IPhaseQuarterCost[];
  hasCorrections: boolean;
}

export const RetributionTablePhase: FC<IProps> = ({
  cost,
  duration,
  phaseName,
  surfaceArea50m2,
  surfaceArea300m2,
  surfaceArea301m2,
  tariffs,
  tariffCategory,
  index,
  recurringPhase,
  correctionCost,
  hasCorrections,
}) => {
  const C = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item container xs={hasCorrections ? 10 : 11} justify="flex-end">
        <Grid item xs={2} className={recurringPhase ? C.recurringPhaseName : C.phaseName}>
          {phaseName ||
            `${
              recurringPhase
                ? translate('sgw.requests.detail.retributionTable.recurringPhase')
                : translate('sgw.requests.detail.retributionTable.phase')
            } ${index + 1}`}
        </Grid>
        <Grid item xs={1} className={C.phase}>
          {duration}
        </Grid>
        <Grid item xs={1} className={C.phase}>
          {(surfaceArea50m2 || 0) + (surfaceArea300m2 || 0) + (surfaceArea301m2 || 0)}
        </Grid>
        <Grid item xs={1} className={C.phase}>
          {surfaceArea50m2 || 0}
        </Grid>
        <Grid item xs={1} className={C.phase}>
          {surfaceArea300m2 || 0}
        </Grid>
        <Grid item xs={1} className={C.phase}>
          {surfaceArea301m2 || 0}
        </Grid>
        <Grid item xs={1} className={C.phase}>
          {translateIgnoreTS(`sgw.requests.detail.retributionTable.${tariffCategory}`)}
        </Grid>
        <Grid item xs={1} className={C.phase}>
          {tariffs.surfaceArea50m2}
        </Grid>
        <Grid item xs={1} className={C.phase}>
          {tariffs.surfaceArea300m2}
        </Grid>
        <Grid item xs={1} className={C.phase}>
          {tariffs.surfaceArea301m2}
        </Grid>
      </Grid>
      <Grid item container xs={1}>
        <Grid item xs={12} className={C.phaseTotal}>
          <span>{`${translate('sgw.requests.detail.retributionTable.currency')} ${cost.toFixed(2)}`}</span>
        </Grid>
      </Grid>
      <Visible visible={!!correctionCost}>
        <Grid item container xs={1}>
          <Grid item xs={12} className={C.phaseTotal}>
            <span>{`${translate('sgw.requests.detail.retributionTable.currency')} ${correctionCost?.toFixed(2)}`}</span>
          </Grid>
        </Grid>
      </Visible>
    </Grid>
  );
};
