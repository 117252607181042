import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IPagedTable } from '../../../common';
import { UserActions } from '../../../common/store/user/user.actions';
import { translate } from '../../../common/translations/translate';
import {
  getUserRequests,
  selectUserRequestLoading,
  selectUserRequestTable,
} from '../../../store/selectors/user-request.selectors';
import { MasterDataRequestOverview } from '../../molecules';

interface IParam {
  userRequested?: number;
  utilityCompany?: number;
}

export const UserRequestOverview: FC<IParam> = ({ userRequested, utilityCompany }) => {
  const dispatch = useDispatch();
  const loading = useSelector(selectUserRequestLoading);
  const table = useSelector(selectUserRequestTable);
  const requests = useSelector(getUserRequests);

  const onFetch = useCallback(
    (table: Partial<IPagedTable>) => dispatch(UserActions.requestList.fetch(table)),
    [dispatch],
  );

  useEffect(() => {
    (userRequested || utilityCompany) &&
      onFetch({
        filters: {
          userRequested,
          utilityCompany,
        },
      });
  }, [onFetch, userRequested, utilityCompany]);

  if (!userRequested && !utilityCompany) {
    return null;
  }

  return (
    <MasterDataRequestOverview
      loading={loading}
      records={requests}
      table={table}
      title={translate('users.table.title')}
      labelUnavailable={translate('users.table.unavailable')}
      onFetch={onFetch}
    />
  );
};
