import * as React from 'react';
import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import I18n from 'i18n-js';
import { getRequestContactInfo } from '../../../store/selectors';
import { IRequestDetailComponent } from '../../../types';
import { Panel } from '../../../common';
import { Grid } from '@material-ui/core';
import { LabelValue } from '../../../common/components/layout/LabelValue.component';

export const ContactInfo: FunctionComponent<IRequestDetailComponent> = ({ requestId, visible = true }) => {
  const info = useSelector(getRequestContactInfo(requestId));

  return info && visible ? (
    <Panel data-testid="contactInfo" title={I18n.t('Requests.Detail.ContactInfo.Title')}>
      <Grid container spacing={2} style={{ marginTop: '20px', marginBottom: '20px' }}>
        <LabelValue label={I18n.t('Requests.Detail.ContactInfo.Name')}>
          {info.url ? (
            <a href={info.url} target="_blank" rel="noreferrer">
              {info.name}
            </a>
          ) : (
            info.name
          )}
        </LabelValue>
        <LabelValue label={I18n.t('Requests.Detail.ContactInfo.Phone')}>{info.phoneNumber}</LabelValue>
        <LabelValue label={I18n.t('Requests.Detail.ContactInfo.Email')}>
          <a href={`mailto:${info.emailAddress}`} target="_top">
            {info.emailAddress}
          </a>
        </LabelValue>
        <LabelValue
          label={I18n.t('Requests.Detail.ContactInfo.Ssn')}
          visible={info.isBelgian && info.ssn !== undefined}
        >
          {info.ssn}
        </LabelValue>
        <LabelValue
          label={I18n.t('Requests.Detail.ContactInfo.Birthday')}
          visible={!info.isBelgian && info.birthday !== undefined}
        >
          {info.birthday}
        </LabelValue>
        <LabelValue label={I18n.t('Requests.Detail.ContactInfo.Address')}>{info.address}</LabelValue>
        <LabelValue label={I18n.t('Requests.Detail.ContactInfo.City')}>{info.city}</LabelValue>
        <LabelValue label={I18n.t('Requests.Detail.ContactInfo.Country')} visible={!info.isBelgian}>
          {info.country}
        </LabelValue>
        <LabelValue
          label={I18n.t('Requests.Detail.ContactInfo.UtilityCompany')}
          visible={info.utilityCompany.hasUtilityRole}
        >
          {info.utilityCompany.name}
        </LabelValue>
        <LabelValue label={I18n.t('Requests.Detail.ContactInfo.CompanyId')} visible={info.companyId !== undefined}>
          {info.companyId}
        </LabelValue>
        <LabelValue label={I18n.t('Requests.Detail.ContactInfo.CompanyName')} visible={info.companyName !== undefined}>
          {info.companyName}
        </LabelValue>
        <LabelValue label={I18n.t('Requests.Detail.ContactInfo.Iban')} visible={info.ibanNumber !== undefined}>
          {info.ibanNumber}
        </LabelValue>
      </Grid>
    </Panel>
  ) : null;
};
