import { createSelector } from '@reduxjs/toolkit';
import { createPagedApiParamsSelector } from '../../common/store/utils/generic.selectors';
import { createAllEntitiesSelector } from '../../common/utils/normalized.util';
import { IRootState } from '../../root.state';

export const selectSgwTrafficMeasures = (store: IRootState) => store.sgw.trafficMeasures;
export const selectAllIds = (store: IRootState) => store.sgw.trafficMeasures.list.allIds;
export const selectById = (store: IRootState) => store.sgw.trafficMeasures.list.byId;
export const selectTable = (store: IRootState) => store.sgw.trafficMeasures.list.table;
export const selectListLoading = (store: IRootState) => store.sgw.trafficMeasures.list.loading;
export const selectLoading = (store: IRootState) => store.sgw.trafficMeasures.loading;

export const getSgwTrafficMeasureList = createAllEntitiesSelector(selectAllIds, selectById);
export const getPagedApiParams = createPagedApiParamsSelector(selectTable);
export const getSgwTrafficMeasure = (id?: string) =>
  createSelector([selectById], (selectById) => (id ? selectById?.[id] : undefined));
