import * as React from 'react';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, IPaging, ISorting } from '../../common';
import { translate } from '../../common/translations/translate';
import { Auth } from '../../components';
import { ICarFreeZoneAclActions, IExemption } from '../../types';
import { GenericTablePanel } from '../../common/components/table/GenericTablePanel.component';
import { useDispatch, useSelector } from 'react-redux';
import { CarFreeZonesActions } from '../../common/store/car-free-zones/carFreeZones.actions';
import {
  getExemptionList,
  selectExemptionsLoading,
  selectExemptionsTable,
} from '../../common/store/car-free-zones/carFreeZones.selectors';
import { useGeneralStyles } from '../../style/generalStyles';
import { ExemptionsFilter } from './ExemptionsFilter.component';

const useStyles = makeStyles({
  container: { paddingTop: 20 },
  header: { display: 'flex', justifyContent: 'space-between' },
});

export const Exemptions: FunctionComponent = () => {
  const C = useStyles();
  const G = useGeneralStyles();
  const dispatch = useDispatch();
  const exemptionList = useSelector(getExemptionList);
  const exemptionsLoading = useSelector(selectExemptionsLoading);
  const { sorting, paging } = useSelector(selectExemptionsTable);

  useEffect(() => {
    dispatch(CarFreeZonesActions.exemptions.list.fetch({}));
  }, [dispatch]);

  const onChangeSorting = useCallback(
    (sorting: ISorting) => {
      dispatch(CarFreeZonesActions.exemptions.list.fetch({ sorting }));
    },
    [dispatch],
  );

  const onChangePaging = useCallback(
    (paging: IPaging) => {
      dispatch(CarFreeZonesActions.exemptions.list.fetch({ paging }));
    },
    [dispatch],
  );

  return (
    <Container className={C.container}>
      <Auth acl={ICarFreeZoneAclActions.viewExemptions}>
        <GenericTablePanel<IExemption>
          blurPaginationWhenLoading
          filterNode={<ExemptionsFilter />}
          className={G.marginTop20}
          columnKey="id"
          title={<div className={G.flexRowSpaceBetween}>{translate('carFreeZones.exemptions.title')}</div>}
          columns={[
            { label: translate('carFreeZones.exemptions.table.exemptionId'), name: 'exemptionId', sortable: false },
            { label: translate('carFreeZones.exemptions.table.contactPerson'), name: 'contactPerson', sortable: false },
            { label: translate('carFreeZones.exemptions.table.licensePlate'), name: 'licensePlate', sortable: false },
            { label: translate('carFreeZones.exemptions.table.pincode'), name: 'pincode', sortable: false },
            { label: translate('carFreeZones.exemptions.table.zapper'), name: 'zapper', sortable: false },
            { label: translate('carFreeZones.exemptions.table.zoneType'), name: 'zoneType', sortable: false },
            { label: translate('carFreeZones.exemptions.table.zone'), name: 'zone', sortable: false },
            { label: translate('carFreeZones.exemptions.table.dateFrom'), name: 'dateFrom', sortable: false },
            { label: translate('carFreeZones.exemptions.table.dateUntil'), name: 'dateUntil', sortable: false },
            {
              label: translate('carFreeZones.exemptions.table.active'),
              name: 'active',
              sortable: false,
              renderer: (active) => {
                if (active) return translate('carFreeZones.exemptions.filters.state.options.active');
                if (active === false) return translate('carFreeZones.exemptions.filters.state.options.inactive');
                return '';
              },
            },
          ]}
          labelUnavailable={translate('carFreeZones.exemptions.unavailable')}
          loading={exemptionsLoading}
          onChangeSorting={onChangeSorting}
          onChangePaging={onChangePaging}
          paging={paging}
          sorting={sorting}
          records={exemptionList}
        />
      </Auth>
    </Container>
  );
};
