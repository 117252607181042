import { combineReducers } from 'redux';
import { generateBooleanReducer, generatePayloadReducer } from '../../..';
import { serviceListInitialFilter, whitelistEntryListInitialFilter } from '../../../../service/Service.constants';
import { createPagedTableReducer } from '../../../../store/reducers/pagedTable.reducer';
import { ServicesActions } from '../Services.actions';
import whitelists from './whitelist.reducer';
import contactPersons from './contact-persons/contactPersons.reducer';
import aptr from './aptr.reducer';
import { IService, IServiceFilter, IWhitelistEntry, IWhitelistEntryListFilter } from '../../../../types/services.types';

export default combineReducers({
  list: createPagedTableReducer<IService, IServiceFilter>({
    fetchAction: ServicesActions.list.fetch.type,
    addEntitiesActions: [ServicesActions.list.set.type],
    setAllIdsAction: ServicesActions.list.set.type,
    addEntityActions: [ServicesActions.set.type],
    setParamsActions: [ServicesActions.list.fetch.type, ServicesActions.list.setParams.type],
    initialParamsState: serviceListInitialFilter,
  }),
  aptr,
  contactPersons,
  existingNames: generatePayloadReducer<string[]>([ServicesActions.setExistingNames.type], []),
  saving: generateBooleanReducer([ServicesActions.saving.type]),
  savingPincode: generateBooleanReducer([ServicesActions.savingPincode.type]),
  whitelists,
  whitelistEntryList: createPagedTableReducer<IWhitelistEntry, IWhitelistEntryListFilter>({
    fetchAction: ServicesActions.whitelistEntriesList.fetch.type,
    addEntitiesActions: [ServicesActions.whitelistEntriesList.set.type],
    setAllIdsAction: ServicesActions.whitelistEntriesList.set.type,
    setParamsActions: [
      ServicesActions.whitelistEntriesList.fetch.type,
      ServicesActions.whitelistEntriesList.setParams.type,
    ],
    initialParamsState: whitelistEntryListInitialFilter,
  }),
});
