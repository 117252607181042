import { Chip, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import I18n from 'i18n-js';
import * as React from 'react';
import { FunctionComponent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Autocomplete } from '../../common/components/form-fields/autocomplete.component';
import { Feedback } from '../../common/components/layout/Feedback.component';
import { Visible } from '../../common/components/layout/Visible.component';
import { PanelContent } from '../../common/components/panel/panel-content.component';
import { getIsAuthorized } from '../../common/store/user/user.selectors';
import { Auth } from '../../components/atoms';
import { ICarFreeZoneAclActions, ILinkTemplateComponentProps } from '../../types';

const useStyles = makeStyles({
  chip: {
    marginRight: '15px',
    marginBottom: '15px',
  },
});

export const LinkTemplate: FunctionComponent<ILinkTemplateComponentProps> = ({
  linkedTemplates = [],
  unlinkedTemplates = [],
  onLinkTemplate,
  onUnlinkTemplate,
}) => {
  const C = useStyles();

  const canEditCarFreeZoneMoovTemplate = useSelector(
    getIsAuthorized([ICarFreeZoneAclActions.editCarFreeZoneMoovTemplate]),
  );

  const onDelete = useCallback(
    (templateId: number) => () => {
      onUnlinkTemplate(`${templateId}`);
    },
    [onUnlinkTemplate],
  );

  return (
    <Paper square data-testid="LinkTemplate">
      <PanelContent title={I18n.t('carFreeZones.krautli.templates.title')}>
        <Auth acl={ICarFreeZoneAclActions.editCarFreeZoneMoovTemplate}>
          <Feedback.Warning>
            {I18n.t('carFreeZones.krautli.templates.updatesWillBeProcessedOvernight')}
          </Feedback.Warning>
        </Auth>
        {linkedTemplates.map((template) => (
          <Chip
            data-testid="LinkedTemplateChip"
            key={template.id}
            label={template.name}
            onDelete={canEditCarFreeZoneMoovTemplate ? onDelete(template.id) : undefined}
            className={C.chip}
          />
        ))}
        <Auth acl={ICarFreeZoneAclActions.editCarFreeZoneMoovTemplate}>
          <Visible visible={!linkedTemplates.length}>
            <span>{I18n.translate('carFreeZones.krautli.templates.noActiveTemplatesLinked')}</span>
          </Visible>
          <Autocomplete<string>
            placeholder={I18n.t(`carFreeZones.krautli.templates.linkInputLabel`)}
            menuItems={unlinkedTemplates}
            onChange={onLinkTemplate}
          />
        </Auth>
      </PanelContent>
    </Paper>
  );
};
