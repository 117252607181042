import { Typography } from '@material-ui/core';
import * as React from 'react';
import { IComponentProps } from '../../../types';
import { PropsWithChildren } from 'react';

interface IProps extends IComponentProps {}

export const PanelTitle: React.FunctionComponent<PropsWithChildren<IProps>> = ({ children, className, style }) =>
  children ? (
    <div
      style={className ? {} : { margin: '30px 0px 20px 0', ...style }}
      data-testid="PanelTitle"
      className={className}
    >
      <Typography component={'span'} style={{ fontWeight: 400, fontSize: '1.2em' }}>
        {children}
      </Typography>
    </div>
  ) : null;
