import { ModalButton } from '../../../common/components/buttons/modal-button';
import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { categories, Category, IMessageAttachment, ISgwRequestCopyAttachment } from '../../../types';
import { SgwRequestActions } from '../../../store/actions';
import { MultiSelectV2Component } from '../../../common/components/form-fields/multi-select-v2.component';
import { getAllPhases } from '../../../store/selectors/sgwRequest.selectors';
import { SingleSelectV2Component } from '../../../common/components/form-fields/single-select-v2.component';
import { createUseStyles } from 'react-jss';
import { translate } from '../../../common/translations/translate';

interface IProps {
  requestId: string;
  attachment: IMessageAttachment;
}

const useStyles = createUseStyles({
  modalBody: {
    display: 'flex',
    gap: 10,
  },
});

export const SgwMessageAttachment: FC<IProps> = ({ requestId, attachment }) => {
  const C = useStyles();
  const dispatch = useDispatch();
  const [sgwPhaseIds, setSgwPhaseIds] = useState<number[]>([]);
  const [category, setCategory] = useState<Category>();
  const allPhases = useSelector(getAllPhases);

  const copyFile = (fileName: string) => () => {
    if (category) {
      const attachment: ISgwRequestCopyAttachment = { fileName, sgwPhases: sgwPhaseIds, category };
      dispatch(SgwRequestActions.attachments.copy({ requestId, attachment }));
    }
  };

  return (
    <>
      <a
        key={attachment.url}
        className="messages-attachments-file"
        href={attachment.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {attachment.name}
      </a>
      <ModalButton.CopyAttachment
        buttonTitle={translate('add')}
        onConfirm={copyFile(attachment.fileName)}
        modalBody={
          <div className={C.modalBody}>
            <MultiSelectV2Component
              title={translate('sgw.requests.detail.attachments.table.phase')}
              values={sgwPhaseIds}
              menuItems={allPhases.map(({ phaseName, id }) => ({ label: phaseName, value: id }))}
              onChange={(newValues) => setSgwPhaseIds(newValues)}
            />
            <SingleSelectV2Component
              shrink
              title={translate('sgw.requests.detail.attachments.table.category')}
              value={category}
              onChange={(e) => setCategory(e.target.value as Category)}
              menuItems={categories}
            />
          </div>
        }
        needsReason={false}
        title={translate('sgw.requests.detail.attachments.add')}
        visible
      />
    </>
  );
};
