import { push } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { genericErrorHandler, IApiResponseMessage, IPaging, TPayloadAction } from '../../common';
import { appUrls } from '../../common/config/url.constants';
import { translateBatchName } from '../../common/utils/string.util';
import { IRootState } from '../../root.state';
import { SIGN_CONDITIONS, SIGN_STATES } from '../signs.constants';
import { ISign } from '../types/sign';
import { ISignDelete } from '../types/sign-delete';
import { ISignLocation } from '../types/sign-location';
import { ISignStateHistory } from '../types/sign-state-history';
import { ISignUpdate } from '../types/sign-update';
import { ISignsFilter } from '../types/signs-filter';
import { ISignMap, ISignMapMarker, ISignsMapFilter } from '../types/signs-map';
import {
  bulkDeleteSign,
  deleteSign,
  fetchSign,
  fetchSignCounters,
  fetchSignLocations,
  fetchSignsFilterData,
  fetchSignsForecast,
  fetchSignsMap,
  fetchSignsMapPopup,
  generateRetrieveWorkOrderItem,
  generateRetrieveWorkOrderItemFromSignMap,
  setSign,
  setSignActiveLocation,
  setSignActiveLocationData,
  setSignConditions,
  setSignCounters,
  setSignErrors,
  setSignGPSLogs,
  setSignLoading,
  setSignLocations,
  setSignLocationsPaging,
  setSignsFilterDataLoaded,
  setSignsForecast,
  setSignsForecastLoading,
  setSignsMap,
  setSignsMapPopup,
  setSignStateHistory,
  setSignStates,
  setSignTypes,
  SignsActions,
  updateSign,
} from './signs.actions';
import { SignsApi } from './signs.api';
import { initialSignsState } from './signs.state';
import { RequestActions } from '../../store/actions';
import { ICall, ISelect } from '../../types';
import { getPagedApiParams, selectSignsState } from './signs.selectors';
import { getPagingFromHeaders } from '../../common/utils/api.util';

function* onFetchSign(action: TPayloadAction<string>): SagaIterator {
  yield put(setSignLoading(true));

  // @ts-ignore - fix undefined somehow
  const [signResponse, gpsLogResponse]: [ICall<typeof SignsApi.getSign>, ICall<typeof SignsApi.getSignGPSLogs>] =
    yield all([call(SignsApi.getSign, action.payload), call(SignsApi.getSignGPSLogs, action.payload)]);

  const sign: ISign = signResponse!.data.data;
  const stateHistory: ISignStateHistory[] = signResponse!.data.msgs.find(
    (msg: IApiResponseMessage) => msg.code === 'stateHistory',
  )!.message;

  if (sign.sigfoxDevice && sign.sigfoxDevice.batch) {
    sign.sigfoxDevice.batch.name = translateBatchName(sign.sigfoxDevice.batch);
  }

  yield all([
    // Clear older sign data
    put(RequestActions.set(null)),
    put(setSignLocations([])),
    put(setSignActiveLocation(null)),
    put(setSignActiveLocationData([])),
    // Set new data
    put(fetchSignLocations({ hash: sign.hash, paging: initialSignsState.signLocationsPaging })),
    put(setSign(sign)),
    put(setSignStateHistory(stateHistory)),
    put(setSignGPSLogs(gpsLogResponse!.data.data)),
    put(setSignLoading(false)),
  ]);

  if (sign.parkingBanIntake?.permitRequestId) {
    yield put(RequestActions.fetch(sign.parkingBanIntake.permitRequestId));
  }
}

function* onFetchSignLocations(action: TPayloadAction<{ hash: string; paging: IPaging }>): SagaIterator {
  const { hash, paging } = action.payload;
  const response: ICall<typeof SignsApi.getSignLocations> = yield call(SignsApi.getSignLocations, hash, paging);
  yield all([
    put(setSignLocations(response!.data.data)),
    put(
      setSignLocationsPaging({
        page: action.payload.paging.page,
        pageSize: action.payload.paging.pageSize,
        totalPages: parseInt(response!.headers['x-rombit-pagination-totalpages'], 10),
        totalRecords: parseInt(response!.headers['x-rombit-pagination-totalrecords'], 10),
      }),
    ),
  ]);
}

function* onChangeSignActiveLocation(action: TPayloadAction<ISignLocation | null>): SagaIterator {
  if (action.payload) {
    const response: ICall<typeof SignsApi.getLocationData> = yield call(SignsApi.getLocationData, action.payload.id);
    yield put(setSignActiveLocationData(response!.data.data));
  } else {
    yield put(setSignActiveLocationData([]));
  }
}

function* onUpdateSign(action: TPayloadAction<ISignUpdate>): SagaIterator {
  const response: ICall<typeof SignsApi.updateSign> = yield call(SignsApi.updateSign, action.payload);
  yield put(setSign(response!.data.data));
}

function* onDeleteSign(action: TPayloadAction<ISignDelete>): SagaIterator {
  yield call(SignsApi.deleteSign, action.payload);
  yield put(push(appUrls.signs.base));
}

function* onBulkDeleteSign(action: TPayloadAction<string[]>): SagaIterator {
  yield put(SignsActions.list.setLoading(true));
  const deleteResponse: ICall<typeof SignsApi.bulkDeleteSign> = yield call(SignsApi.bulkDeleteSign, action.payload);

  // @ts-ignore - no errors for type void
  if (deleteResponse!.data.data.errors!.length > 0) {
    // @ts-ignore - no errors for type void
    yield put(setSignErrors(deleteResponse!.data.data.errors));
  } else {
    yield call(refreshSigns);
  }
  yield put(SignsActions.list.setLoading(false));
}

function* onGenerateRetrieveWorkOrderItem(action: TPayloadAction<ISign>): SagaIterator {
  yield call(SignsApi.generateRetrieveWorkOrderItem, action.payload);
}

function* onGenerateRetrieveWorkOrderItemFromSignMap(action: TPayloadAction<ISignMapMarker>): SagaIterator {
  yield put(setSignsMapPopup(null)); // Show loading on popup
  const response: ICall<typeof SignsApi.getSign> = yield call(SignsApi.getSign, action.payload.hash);
  yield call(SignsApi.generateRetrieveWorkOrderItem, response!.data.data);
  yield put(fetchSignsMapPopup(action.payload.hash));
}

function* onFetchSigns(): SagaIterator {
  const params = yield select(getPagedApiParams);
  const response: ICall<typeof SignsApi.fetchList> = yield call(SignsApi.fetchList, params);

  if (response) {
    yield all([
      put(
        SignsActions.list.setParams({
          paging: getPagingFromHeaders(response as any),
        }),
      ),
      put(SignsActions.list.set(response.data.data)),
    ]);
  }
}

function* onFetchSignsByFilter(action: TPayloadAction<ISignsFilter>): SagaIterator {
  yield put(
    SignsActions.list.fetch({
      filters: action.payload,
      paging: initialSignsState.list.table.paging,
      sorting: initialSignsState.list.table.sorting,
    }),
  );
}

function* onHideSignOnMapView(action: TPayloadAction<{ hash: string }>): SagaIterator {
  yield call(SignsApi.updateSign, { hash: action.payload.hash, hideOnMapView: true });
  const signsMap: ISignMap = yield select((state: IRootState) => state.signs.signsMap);
  yield put(
    setSignsMap({
      ...signsMap,
      signs: signsMap.signs.map((item) => {
        return item.hash === action.payload.hash ? { ...item, hideOnMapView: true } : item;
      }),
    }),
  );
  yield put(fetchSignsMapPopup(action.payload.hash));
}

function* refreshSigns() {
  yield put(SignsActions.list.fetch({}));
}

function* onFetchSignsFilterData(): SagaIterator {
  const signsState: ISelect<typeof selectSignsState> = yield select(selectSignsState);
  if (!signsState!.signsFilterDataLoaded) {
    // @ts-ignore - Check how to solve yield all
    const [conditionsResponse, statesResponse, typesResponse]: [
      ICall<typeof SignsApi.getSignConditions>,
      ICall<typeof SignsApi.getSignStates>,
      ICall<typeof SignsApi.getSignTypes>,
    ] = yield all([call(SignsApi.getSignConditions), call(SignsApi.getSignStates), call(SignsApi.getSignTypes)]);
    yield all([
      put(setSignConditions(conditionsResponse?.data.data || [])),
      put(setSignStates(statesResponse?.data.data || [])),
      put(setSignTypes(typesResponse?.data.data || [])),
      put(setSignsFilterDataLoaded(true)),
    ]);
  }
}

function* onFetchSignCounters(): SagaIterator {
  // @ts-ignore - Too much to type
  const [placed, moved, stored, missing, damaged, lowBattery, notExtended, noGPS, noConnection, requireMaintenance] =
    yield all([
      call(SignsApi.getSignCounter, { states: [SIGN_STATES.PLACED, SIGN_STATES.MOVED] }),
      call(SignsApi.getSignCounter, { states: [SIGN_STATES.MOVED] }),
      call(SignsApi.getSignCounter, { states: [SIGN_STATES.STORED] }),
      call(SignsApi.getSignCounter, { states: [SIGN_STATES.MISSING] }),
      call(SignsApi.getSignCounter, { conditions: [SIGN_CONDITIONS.DAMAGED] }),
      call(SignsApi.getSignCounter, { conditions: [SIGN_CONDITIONS.LOW_BATTERY] }),
      call(SignsApi.getSignCounter, { notExtended: true }),
      call(SignsApi.getSignCounter, { noGPS: true }),
      call(SignsApi.getSignCounter, { noConnection: true }),
      call(SignsApi.getSignCounter, { requireMaintenance: true }),
    ]);

  yield put(
    setSignCounters({
      damaged: damaged.data.data,
      missing: missing.data.data,
      lowBattery: lowBattery.data.data,
      moved: moved.data.data,
      noConnection: noConnection.data.data,
      noGPS: noGPS.data.data,
      notExtended: notExtended.data.data,
      placed: placed.data.data,
      requireMaintenance: requireMaintenance.data.data,
      stored: stored.data.data,
    }),
  );
}

function* onFetchSignsForecast(): SagaIterator {
  yield put(setSignsForecastLoading(true));
  const response: ICall<typeof SignsApi.getSignsForecast> = yield call(SignsApi.getSignsForecast);
  // @ts-ignore - graph not present
  const graphData = response!.data.data.graph;
  yield all([
    put(
      setSignsForecast(
        Object.keys(graphData).map((date: string) => ({
          averageDropOff: graphData[date].averageDropOff,
          averagePickUp: graphData[date].averagePickUp,
          averagePlaced: graphData[date].averagePlaced,
          date: date.split('-').reverse().splice(0, 2).join('/'),
          dropOff: graphData[date].dropOff,
          pickUp: graphData[date].pickUp,
          placed: graphData[date].placed,
          stored: graphData[date].stored,
          total: graphData[date].total,
        })),
      ),
    ),
    put(setSignsForecastLoading(false)),
  ]);
}

function* onFetchSignsWoiForecast(): SagaIterator {
  yield put(SignsActions.setSignsWoiForecastLoading(true));
  const response: ICall<typeof SignsApi.getSignsWoiForecast> = yield call(SignsApi.getSignsWoiForecast);
  // @ts-ignore - graph not present
  const graphData = response!.data.data.graph;
  yield put(
    SignsActions.setSignsWoiForecast(
      Object.keys(graphData).map((date: string) => ({
        date: date.split('-').reverse().splice(0, 2).join('/'),
        woiNum: graphData[date].woiNum,
      })),
    ),
  );
  yield put(SignsActions.setSignsWoiForecastLoading(false));
}

function* onFetchSignsMap(action: TPayloadAction<ISignsMapFilter>): SagaIterator {
  yield put(setSignLoading(true));
  yield put(setSignsMap(null));
  const response: ICall<typeof SignsApi.getSignsMap> = yield call(SignsApi.getSignsMap, action.payload);
  yield put(setSignsMap(response!.data.data));
  yield put(setSignLoading(false));
}

function* onFetchSignsMapPopup(action: TPayloadAction<string>): SagaIterator {
  yield put(setSignsMapPopup(null));
  const signResponse: ICall<typeof SignsApi.getSignsMapPopup> = yield call(SignsApi.getSignsMapPopup, action.payload);
  yield put(setSignsMapPopup(signResponse!.data.data));
}

export function* signsSaga(): SagaIterator {
  yield takeLatest(fetchSign.type, genericErrorHandler(onFetchSign));
  yield takeLatest(fetchSignLocations.type, genericErrorHandler(onFetchSignLocations));
  yield takeLatest(updateSign.type, genericErrorHandler(onUpdateSign));
  yield takeLatest(generateRetrieveWorkOrderItem.type, genericErrorHandler(onGenerateRetrieveWorkOrderItem));
  yield takeLatest(
    generateRetrieveWorkOrderItemFromSignMap.type,
    genericErrorHandler(onGenerateRetrieveWorkOrderItemFromSignMap),
  );
  yield takeLatest(SignsActions.list.fetch.type, genericErrorHandler(onFetchSigns));
  yield takeLatest(SignsActions.fetchSignsByFilter.type, genericErrorHandler(onFetchSignsByFilter));
  yield takeLatest(SignsActions.hideSignOnMapView.type, genericErrorHandler(onHideSignOnMapView));
  yield takeLatest(fetchSignsFilterData.type, genericErrorHandler(onFetchSignsFilterData));
  yield takeLatest(fetchSignCounters.type, genericErrorHandler(onFetchSignCounters));
  yield takeLatest(fetchSignsForecast.type, genericErrorHandler(onFetchSignsForecast));
  yield takeLatest(SignsActions.fetchSignsWoiForecast.type, genericErrorHandler(onFetchSignsWoiForecast));
  yield takeLatest(setSignActiveLocation.type, genericErrorHandler(onChangeSignActiveLocation));
  yield takeLatest(deleteSign.type, genericErrorHandler(onDeleteSign));
  yield takeLatest(bulkDeleteSign.type, genericErrorHandler(onBulkDeleteSign));
  yield takeLatest(fetchSignsMap.type, genericErrorHandler(onFetchSignsMap));
  yield takeLatest(fetchSignsMapPopup.type, genericErrorHandler(onFetchSignsMapPopup));
}
