import { Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Save } from '@material-ui/icons';
import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useState } from 'react';
import { IPaging, ISorting, ISortingDirection, Paging } from '../../../common';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { Visible } from '../../../common/components/layout/Visible.component';
import ModalDialog from '../../../common/components/modal-dialog/modal-dialog.component';
import { PanelFooter } from '../../../common/components/panel/panel-footer.component';
import { PanelTitle } from '../../../common/components/panel/panel-title.component';
import { GenericTable } from '../../../common/components/table/GenericTable.component';
import { translate } from '../../../common/translations/translate';
import { validateEmail } from '../../../common/utils/email.util';
import { Auth } from '../../../components';
import { IServiceAclActions } from '../../../types';
import { IServiceContact, newContactInitialValues } from '../../../types';

const useStyles = makeStyles({
  removeContactButton: {
    cursor: 'pointer',
  },
  contactField: {
    minHeight: 100,
  },
  addContactForm: {
    marginTop: 10,
  },
});

interface IProps {
  contacts: IServiceContact[];
  setContacts?(contacts: IServiceContact[]): void;
  setNewContact(contact: IServiceContact): void;
  newContact: IServiceContact;
  onAdd(): void;
  onDelete?(contact: IServiceContact): void;
  onFetchContactList?(sorting: ISorting): void;
}

export const ServiceContacts: FunctionComponent<IProps> = ({
  contacts,
  setContacts,
  setNewContact,
  newContact,
  onAdd,
  onDelete,
  onFetchContactList,
}) => {
  const C = useStyles();
  const [isValidContact, setIsValidContact] = useState<boolean>(false);
  const [newContactError, setNewContactError] = useState<Partial<IServiceContact>>({});
  const [contactToRemove, setContactToRemove] = useState<IServiceContact | null>(null);
  const [page, setPage] = useState<number>(1);
  const [sorting, setSorting] = useState<ISorting>({ direction: ISortingDirection.asc, key: 'name' });

  const onRowDelete = useCallback((contact: IServiceContact) => {
    setContactToRemove(contact);
  }, []);

  const paging = {
    page: page,
    pageSize: 10,
    totalPages: Math.ceil(contacts.length / 10),
    totalRecords: contacts.length,
  };

  const onChangeSorting = (sorting: ISorting) => {
    setSorting(sorting);
    onFetchContactList?.(sorting);
  };

  const onChangePaging = (padding: IPaging) => {
    setPage(padding.page);
  };

  const removeContact = useCallback(
    (contactToRemove: IServiceContact) => {
      setContacts?.(contacts.filter((contact) => contact.email !== contactToRemove.email));
      onDelete?.(contactToRemove);
    },
    [onDelete, setContacts, contacts],
  );

  const editContactName = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNewContact({ ...newContact, fullName: event.target.value });
  };

  const editContactEmail = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNewContact({ ...newContact, email: event.target.value });
  };

  const editContactPhone = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNewContact({ ...newContact, phoneNumber: event.target.value });
  };

  const getRecords = (): IServiceContact[] => {
    if (sorting) {
      contacts.sort((a, b) => {
        let direction = 0;
        // @ts-ignore
        if (a[sorting.key] > b[sorting.key]) {
          direction = -1;
        }
        // @ts-ignore
        if (a[sorting.key] < b[sorting.key]) {
          direction = 1;
        }
        return sorting.direction === 'asc' ? direction : -direction;
      });
    }

    return contacts.slice((paging.page - 1) * paging.pageSize, paging.page * paging.pageSize);
  };

  const _onAdd = () => {
    onAdd();
    setNewContact(newContactInitialValues);
  };

  useEffect(() => {
    let valid = true;
    let error: Partial<IServiceContact> = { fullName: '', email: '' };
    if (!newContact.email || !newContact.fullName) {
      valid = false;
    } else {
      if (!newContact.email) {
        error.email = translate('services.validation.required');
        valid = false;
      } else if (contacts.find((contact) => contact.email === newContact.email)) {
        error.email = translate('services.validation.duplicateEmail');
        valid = false;
      } else if (!validateEmail(newContact.email)) {
        error.email = translate('services.validation.invalidEmail');
        valid = false;
      }
      if (!newContact.fullName) {
        error.fullName = translate('services.validation.required');
        valid = false;
      }
    }
    setNewContactError(error);
    setIsValidContact(valid);
  }, [newContact, contacts]);

  return (
    <>
      <PanelTitle>{translate('services.contacts.title')}</PanelTitle>
      <Grid container spacing={4} alignItems={'flex-end'}>
        <Grid item xs={12}>
          <GenericTable
            labelUnavailable={translate('services.contacts.unavailable')}
            onChangeSorting={onChangeSorting}
            onDelete={contacts.length > 1 ? onRowDelete : undefined}
            sorting={sorting}
            records={getRecords()}
            columnKey="email"
            columns={[
              {
                label: translate('services.contacts.name'),
                name: 'fullName',
              },
              {
                label: translate('services.contacts.email'),
                name: 'email',
              },
              {
                label: translate('services.contacts.phone'),
                name: 'phoneNumber',
              },
            ]}
          />
          <Visible visible={paging.totalPages > 1}>
            <Paging pageChange={onChangePaging} {...paging} />
          </Visible>
          <Auth acl={[IServiceAclActions.editService]}>
            <Grid container justify="space-between" spacing={4} className={C.addContactForm}>
              <Grid item xs={4} className={C.contactField}>
                <TextField
                  value={newContact.fullName}
                  label={translate('services.contacts.name')}
                  onChange={editContactName}
                  error={!!newContactError.fullName}
                  helperText={newContactError.fullName}
                  fullWidth={true}
                  required={true}
                />
              </Grid>
              <Grid item xs={4} className={C.contactField}>
                <TextField
                  value={newContact.email}
                  label={translate('services.contacts.email')}
                  type="text" // AS-8627 Do not use type="email" because it magically transforms test@gmäil.com to test@xn--gmil-moa.com
                  onChange={editContactEmail}
                  error={!!newContactError.email}
                  helperText={newContactError.email}
                  fullWidth={true}
                  required={true}
                />
              </Grid>
              <Grid item xs={4} className={C.contactField}>
                <TextField
                  value={newContact.phoneNumber}
                  label={translate('services.contacts.phone')}
                  onChange={editContactPhone}
                  fullWidth={true}
                />
              </Grid>
            </Grid>
            <Visible visible={!!contactToRemove}>
              <ModalDialog
                title={translate('services.dialogs.confirmSavePopup.title')}
                data-testid="ServicesSavePopup"
                onClose={() => {
                  setContactToRemove(null);
                }}
                onConfirm={() => {
                  setContactToRemove(null);
                  contactToRemove && removeContact(contactToRemove);
                }}
                okButtonText={translate('yes')}
                refuseButtonText={translate('no')}
              >
                <Typography>
                  {translate('services.dialogs.confirmSavePopup.removeContact', { name: contactToRemove?.email })}
                </Typography>
              </ModalDialog>
            </Visible>
          </Auth>
        </Grid>
      </Grid>
      <PanelFooter>
        <Auth acl={[IServiceAclActions.editService]}>
          <Button.Green disabled={!isValidContact} onClick={_onAdd} startIcon={<Save fontSize="small" />}>
            {translate('add')}
          </Button.Green>
        </Auth>
      </PanelFooter>
    </>
  );
};
