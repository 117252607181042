import { Grid, Paper } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { Save } from '@material-ui/icons';
import { Formik } from 'formik';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../../common/components/buttons/asign-button-extensions';
import { Header } from '../../../../common/components/layout/Header.component';
import { LoaderBlur } from '../../../../common/components/layout/LoaderBlur.component';
import { PanelContent } from '../../../../common/components/panel/panel-content.component';
import { appUrls } from '../../../../common/config/url.constants';
import { ServicesActions } from '../../../../common/store/services/Services.actions';
import { getExistingNames, getServicesSaving } from '../../../../common/store/services/Services.selectors';
import { IService, IServiceContact, newContactInitialValues } from '../../../../types';
import { serviceInitialValues } from '../../../Service.constants';
import { ServiceContacts } from '../ServiceContacts.component';
import { ServiceEditForm } from './ServiceEditForm.component';
import { serviceFormManagement } from './ServiceEditForm.management';
import { translate } from '../../../../common/translations/translate';

const useStyles = makeStyles({
  container: { marginTop: 20 },
  form: { marginLeft: 8, marginRight: 8 },
  grid: { marginTop: 20, marginBottom: 20 },
  saveButton: {
    marginRight: 10,
  },
  serviceContacts: {
    width: '100%',
    padding: 10,
  },
});

export const CreateService: FunctionComponent = () => {
  const C = useStyles();
  const dispatch = useDispatch();
  const busy = useSelector(getServicesSaving);
  const [contacts, setContacts] = useState<IServiceContact[]>([]);
  const [newContact, setNewContact] = useState<IServiceContact>(newContactInitialValues);
  const existingNames = useSelector(getExistingNames);

  const onSubmit = useCallback(
    (service: IService) => {
      dispatch(ServicesActions.create({ service, contacts }));
    },
    [dispatch, contacts],
  );

  const addContact = () => {
    setContacts([...contacts, newContact]);
    setNewContact(newContactInitialValues);
  };

  return (
    <>
      <Header backButtonUrl={appUrls.services.base}>{translate('services.create.title')}</Header>
      <LoaderBlur loading={busy}>
        <Container className={C.container}>
          <Grid container justify="center">
            <Grid item xs={6}>
              <Paper square data-testid="requestInfo">
                <PanelContent title={translate('services.create.subTitle')}>
                  <Grid container spacing={2} className={C.grid}>
                    <Formik
                      initialValues={serviceFormManagement.getInitialValues(serviceInitialValues)}
                      onSubmit={onSubmit}
                      validate={(service) => serviceFormManagement.requestValidateValues(service, existingNames)}
                    >
                      {(formikProps) => (
                        <>
                          <ServiceEditForm service={serviceInitialValues} formikProps={formikProps} />
                          <div className={C.serviceContacts}>
                            <ServiceContacts
                              contacts={contacts}
                              setContacts={setContacts}
                              onAdd={addContact}
                              setNewContact={setNewContact}
                              newContact={newContact}
                            />
                          </div>
                          <Grid container justify="flex-end" spacing={4}>
                            <Grid item>
                              <Button.Green
                                className={C.saveButton}
                                disabled={formikProps?.isSubmitting || contacts.length < 1}
                                onClick={() => {
                                  formikProps.submitForm();
                                }}
                                startIcon={<Save fontSize="small" />}
                                data-testid="SaveButton"
                              >
                                {translate('Save')}
                              </Button.Green>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Formik>
                  </Grid>
                </PanelContent>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </LoaderBlur>
    </>
  );
};
