import { makeStyles } from '@material-ui/core/styles';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { IComponentProps } from '../../../types';

interface IProps extends IComponentProps {
  disabled: boolean;
}

const useStyles = makeStyles({
  disabled: {
    filter: 'opacity(0.5) blur(0.4px)',
    pointerEvents: 'none',
  },
});

export const Disableable: FunctionComponent<PropsWithChildren<IProps>> = ({
  children,
  disabled = false,
  className,
  style,
}) => {
  const C = useStyles();
  return (
    <div className={disabled ? C.disabled : className} style={style}>
      {children}
    </div>
  );
};
