import { Checkbox, FormControl, Input, InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core';
import * as React from 'react';

interface IProps {
  id: string;
  label: string;
  data: any[];
  value: any;
  valueChange: (value: any) => void;
  valueProp?: string;
  displayProp?: string;
  keyProp?: string;
}

export class SingleSelect extends React.Component<IProps> {
  public render(): React.ReactNode {
    return (
      <FormControl fullWidth={true}>
        <InputLabel htmlFor={this.props.id}>{this.props.label}</InputLabel>
        <Select
          multiple={false}
          value={this.props.value}
          onChange={this.handleChange}
          input={<Input id={this.props.id} />}
          renderValue={this.renderValue}
          className="select-component"
        >
          {this.props.data.map((data: any) => (
            <MenuItem style={{ padding: 10 }} key={this.getKeyValue(data)} value={this.getValue(data)}>
              <Checkbox color="primary" checked={this.props.value === this.getValue(data)} />
              <ListItemText style={{ padding: 0 }} primary={this.getDisplayValue(data)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  private renderValue = () => {
    return this.getDisplayValue(this.getData(this.props.value));
  };

  private handleChange = (e: React.ChangeEvent<any>) => {
    this.props.valueChange(e.target.value);
  };

  private getDisplayValue = (value: any) => (this.props.displayProp ? value?.[this.props.displayProp] : value);
  private getKeyValue = (value: any) => (this.props.keyProp ? value?.[this.props.keyProp] : value);
  private getValue = (value: any) => (this.props.valueProp ? value?.[this.props.valueProp] : value);
  private getData = (value: any) =>
    this.props.valueProp ? this.props.data.find((data: any) => data?.[this.props.valueProp!] === value) : value;
}
