import { createMuiTheme } from '@material-ui/core';
import { green, grey } from '@material-ui/core/colors';

export const COLORS = {
  APPLE: 'rgb(74, 163, 44)',
  BEER: '#F18C21',
  BACKGROUND: '#EEEEEE',
  BLACK: '#000000',
  BLUE: '#2797FF',
  RED: '#CF3D46',
  DANGER: '#E00B0B',
  GREEN: '#036819',
  GREEN_LIGHT: '#287D00',
  GREEN_LIGHTER: '#287d0033',
  GREY: grey.A700,
  GREY_DARK: '#333333',
  GREY_LIGHT: '#a2a2a2',
  GREY_LIGHTER: '#e0e0e0',
  BLUE_LIGHT: '#93d9f3',
  BLUE_LIGHTER: '#F0F5FF',
  ORANGE_LIGHTER: '#fe8d0b33',
  ORANGE: '#ffa500',
  ORANGE_DARK: '#ffcb6f',
  MAGENTA: '#EB2F96',
  PRIMARY: '#2196f3',
  SECONDARY: green.A700,
  TEAM_CARD_BG: '#E6E6E6',
  WHITE: '#FFFFFF',
  GAINSBORO: '#E3D5D6',
  DARK_TERRA_COTTA: '#D15058',
  DISABLED_GREY: '#00000061',
};

export const theme = createMuiTheme({
  overrides: {
    MuiFormControlLabel: {
      root: {
        marginLeft: -6,
      },
      label: {
        fontSize: '0.9rem',
      },
    },
    MuiButton: {
      containedSecondary: {
        color: COLORS.WHITE,
      },
    },
    MuiInput: {
      root: {
        fontSize: 14,
      },
      underline: {
        '&:after': {
          borderBottomColor: COLORS.PRIMARY,
        },
      },
    },
    MuiInputLabel: {
      animated: {
        '&$focused': {
          color: COLORS.PRIMARY,
        },
        fontSize: 14,
      },
      shrink: {
        fontSize: '12px !important',
        transform: 'translate(0, 0) scale(1)',
      },
    },
    MuiMenuItem: {
      root: {
        '&:not(:last-child)': {
          borderBottom: `solid 1px ${COLORS.BACKGROUND}`,
        },
      },
    },
    MuiSelect: {
      select: {
        fontSize: 14,
      },
    },
    MuiTab: {
      root: {
        fontSize: '12px !important',
        minWidth: '0px !important',
      },
    },
    MuiTableCell: {
      head: {
        color: 'inherit',
        fontWeight: 'bold',
        padding: '5px 5px 5px 5px !important',
      },
      sizeSmall: {
        paddingBottom: 10,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 10,
      },
      root: {
        fontSize: '14px !important',
        '&:last-child': {
          paddingRight: 0,
        },
      },
    },
    MuiTableRow: {
      head: {
        height: 0,
        background: '#FFF !important',
      },
      hover: {
        cursor: 'pointer',
      },
      root: {
        height: 0,
      },
    },
  },
  palette: {
    background: {
      default: COLORS.BACKGROUND,
      paper: COLORS.WHITE,
    },
    primary: {
      main: COLORS.PRIMARY,
    },
    secondary: {
      main: COLORS.SECONDARY,
    },
  },
  typography: {
    fontSize: 14,
  },
});
