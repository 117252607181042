import React, { ChangeEvent, FC } from 'react';
import { useGeoDrawingListStyles } from '../../../style/GeoDrawingList.styles';
import { translate, translateIgnoreTS } from '../../../common/translations/translate';
import { Grid, TextField } from '@material-ui/core';
import { GeoDrawingType } from '../../../types';
import { Visible } from '../../../common/components/layout/Visible.component';
import { SingleSelectV2Component } from '../../../common/components/form-fields/single-select-v2.component';
import { useGeoDrawingContext } from '../../../hooks/useGeoDrawingContext.hook';
import { AddGeoDrawingListItem, GeoDrawingListItemButtons } from '../../atoms';
import { useGeneralStyles } from '../../../style/generalStyles';
import classNames from 'classnames';
import { Disableable } from '../../../common/components/layout/Disableable.component';
import { getValuesOfEnum } from '../../../utils';

export const GeoDrawingAddListItem: FC = () => {
  const C = useGeoDrawingListStyles();
  const G = useGeneralStyles();
  const {
    formMethods: {
      watch,
      register,
      reset,
      formState: { errors },
    },
    phaseGeoDrawings: { addMode, setAddMode, idToEdit },
  } = useGeoDrawingContext();

  const geoDrawingMenuItems = getValuesOfEnum<GeoDrawingType>(GeoDrawingType).map((type) => ({
    label: translateIgnoreTS(`sgw.requests.detail.map.geoDrawingType.${type}`),
    value: type,
  }));

  const surfaceArea = watch('surfaceArea');
  const geoDrawingType = watch('geoDrawingType');

  const onChangeGeoDrawingType = (event: ChangeEvent<{ value: GeoDrawingType }>) =>
    reset({
      geoDrawingType: event.target.value,
    });

  const onAddGeoDrawing = () => {
    reset({});
    setAddMode(true);
  };

  return (
    <div className={C.addContainer}>
      <Disableable disabled={!!idToEdit}>
        <Visible visible={addMode} defaultComponent={<AddGeoDrawingListItem onAdd={onAddGeoDrawing} />}>
          <Grid container>
            <Grid item xs={8} container direction="column" className={C.info} justify="space-around">
              <SingleSelectV2Component
                className={G.marginBottom20}
                {...register('geoDrawingType', { required: true })}
                title={translate('sgw.requests.detail.map.addItem.geoDrawingType')}
                onChange={onChangeGeoDrawingType}
                value={geoDrawingType}
                menuItems={geoDrawingMenuItems}
                error={!!errors.geoDrawingType}
              />
              <TextField
                fullWidth
                className={G.marginBottom20}
                {...register('customName')}
                label={translate('sgw.requests.detail.map.addItem.customName')}
              />
              <Visible visible={!!surfaceArea}>
                <div className={classNames(C.bold, G.marginBottom20)}>
                  {`${surfaceArea} ${geoDrawingType === GeoDrawingType.constructionzone ? 'm²' : 'm'}`}
                </div>
              </Visible>
            </Grid>
            <GeoDrawingListItemButtons editMode={true} onCancel={() => setAddMode(false)} />
          </Grid>
        </Visible>
      </Disableable>
    </div>
  );
};
