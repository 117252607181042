import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { getRequestPartnersId } from '../../../store/selectors/sgwRequest.selectors';
import { Panel } from '../../../common';
import { PartnerContactInfo } from '../../molecules';
import { SgwPartnerType } from '../../../types';
import { translate } from '../../../common/translations/translate';
import { PartnerContextProvider } from '../../../context';

const useStyles = createUseStyles({
  header: {
    fontWeight: 'bold',
    padding: '20px 15px',
    alignItems: 'center',
  },
});

interface IProps {
  requestId: string;
}

export const PartnersContactInfoPanel: FC<IProps> = ({ requestId }) => {
  const C = useStyles();
  const partnerIds = useSelector(getRequestPartnersId);
  return (
    <Panel title={translate('sgw.requests.detail.partners.title')}>
      <Grid container className={C.header}>
        <Grid item xs={2}>
          {translate('sgw.partners.columns.partner')}
        </Grid>
        <Grid item xs={4}>
          {translate('sgw.partners.columns.name')}
        </Grid>
        <Grid item xs={4}>
          {translate('sgw.partners.columns.phoneNumber')}
        </Grid>
        <Grid item xs={2}>
          {translate('sgw.partners.columns.email')}
        </Grid>
      </Grid>
      {Object.entries(partnerIds).map(
        ([key, value]) =>
          !!value && (
            <PartnerContextProvider
              key={key}
              requestId={requestId}
              partnerId={value}
              partnerType={key as SgwPartnerType}
            >
              <PartnerContactInfo />
            </PartnerContextProvider>
          ),
      )}
    </Panel>
  );
};
