import { ISortingDirection } from '../../common';
import { IUtilityCompaniesState } from '../../types';

export const initialUtilityCompaniesState: IUtilityCompaniesState = {
  list: {
    allIds: [],
    byId: {},
    table: {
      filters: {},
      paging: {
        page: 1,
        pageSize: 100,
        totalPages: 1,
        totalRecords: 0,
      },
      sorting: {
        direction: ISortingDirection.desc,
        key: 'createdAt',
      },
    },
    loading: true,
  },
  loading: true,
};
