import React, { FunctionComponent, useState } from 'react';
import { ICostOverview } from '../../../types';
import classNames from 'classnames';
import { CollapsablePanel } from '../../../components';
import { makeStyles } from '@material-ui/core/styles';
import { useGeneralStyles } from '../../../style/generalStyles';
import I18n from 'i18n-js';
import { COLORS } from '../../../common';

interface IProps {
  costOverview: ICostOverview;
}

const useStyles = makeStyles({
  container: { fontWeight: 500 },
  costRow: {
    marginBottom: 5,
    marginTop: 5,
  },
  costBorder: {
    borderTop: `solid 1px ${COLORS.GREY}`,
  },
});

export const CostOverview: FunctionComponent<IProps> = ({ costOverview }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const C = useStyles();
  const G = useGeneralStyles();

  const costs: (keyof ICostOverview)[] = [
    'numberOfParkingSpots',
    'numberOfDays',
    'fixedAdministrationCost',
    'fixedEmergencyCost',
    'variableCost',
  ];

  const onExpand = (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  return (
    <CollapsablePanel
      className={C.container}
      classNameDetails={C.costBorder}
      data-testid="CostOverview"
      expanded={expanded}
      noTitleWrap
      onChange={onExpand}
      title={
        <div className={classNames(G.flexRowSpaceBetween, G.fullWidth)}>
          <div>{I18n.translate('Requests.Cost.payment')}</div>
          <div>{costOverview.totalCost || 0}</div>
        </div>
      }
    >
      <div className={classNames(G.flexColumn, G.fullWidth)}>
        {costs.map((cost, ind) => (
          <div key={Math.random()} className={classNames(G.flexRowSpaceBetween, G.fullWidth, C.costRow)}>
            <div>{I18n.translate(`Requests.Cost.${cost}`)}</div>
            <div>{costOverview[cost]}</div>
          </div>
        ))}
      </div>
    </CollapsablePanel>
  );
};
