import { Grid } from '@material-ui/core';
import React, { FC, PropsWithChildren } from 'react';
import { createUseStyles } from 'react-jss';

interface IProps {
  fullWidth?: boolean;
}

const useStyles = createUseStyles({
  default: {
    maxWidth: 1400,
    margin: '0 auto',
    padding: '0 15px',
  },
  fullWidth: {
    paddingLeft: '20px',
    paddingRight: '20px',
  },
});

export const PageGrid: FC<PropsWithChildren<IProps>> = ({ children, fullWidth = false }) => {
  const C = useStyles();
  return (
    <Grid container justify="center" spacing={fullWidth ? 2 : 0} className={fullWidth ? C.fullWidth : C.default}>
      {children}
    </Grid>
  );
};
