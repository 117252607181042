import * as React from 'react';
import { FormattedDateTime, Panel } from '../../common';
import { Unavailable } from '../../common/components/formatters/unavailable';
import { translate } from '../../common/translations/translate';
import { ISignStateHistory } from '../types/sign-state-history';

interface IProps {
  signStateHistory: ISignStateHistory[];
}

export class SignStateHistory extends React.Component<IProps> {
  public render(): React.ReactNode {
    return (
      <Panel title={translate('history.title')}>
        <dl>
          {this.props.signStateHistory.map((history: ISignStateHistory, index: number) => (
            <React.Fragment key={index}>
              <dt>{history.state.nameNormalized}</dt>
              <dd>
                <FormattedDateTime dateTime={history.timestamp} />
              </dd>
            </React.Fragment>
          ))}
        </dl>
        {this.props.signStateHistory.length === 0 && <Unavailable text={translate('noHistoryAvailable')} />}
      </Panel>
    );
  }
}
