export enum RequestStates {
  approved = 'approved',
  canceled = 'canceled',
  closed = 'closed',
  draft = 'draft',
  in_effect = 'in_effect',
  rejected = 'rejected',
  reviewing = 'reviewing',
  submitted = 'submitted',
  waiting_for_payment = 'waiting_for_payment',
}
