import { ISortingDirection } from '../../common';
import { IEvent } from '../types/event';
import { IEventType } from '../types/event-type';
import { IPagedTableState } from '../../types';
import { IEventsFilter } from '../types/events-filter';

export interface IEventsState {
  eventTypes: IEventType[];
  eventsFilterDataLoaded: boolean;
  eventErrors: string[] | null;
  list: IPagedTableState<IEvent, IEventsFilter>;
}

export const initialEventsState: IEventsState = {
  eventErrors: [],
  eventTypes: [],
  eventsFilterDataLoaded: false,
  list: {
    byId: {},
    allIds: [],
    table: {
      filters: {
        moduleId: '',
        processed: null,
        signId: '',
        types: [],
      },
      sorting: {
        direction: ISortingDirection.desc,
        key: 'detected',
      },
      paging: {
        page: 1,
        pageSize: 20,
        totalPages: 0,
        totalRecords: 0,
      },
    },
    loading: true,
  },
};
