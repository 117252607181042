import { Actions, ISorting, TableActions } from '../../common';
import {
  ISgwRequest,
  ISgwRequestBE,
  IRequestMessage,
  ISgwRequestCounters,
  ISgwRequestPatchInternalNotes,
  ISgwRequestPostTransition,
  ISgwRequestsFilter,
  ISgwRequestAttachment,
  IConflictGroup,
  IRequestMessagesList,
  ISgwPhase,
  ISgwRequestPermit,
  ISgwRequestCopyAttachment,
} from '../../types';
import { ISgwQuarterCost } from '../../types/SgwQuarterCost.types';
import { ISgwRequestHistory } from '../../types/sgwRequestHistory.types';

import { IRequestRescheduling } from '../../types/SgwReschedulings.types';

class AttachmentsActions extends Actions {
  copy = this.createPayloadAction<{ requestId: string; attachment: ISgwRequestCopyAttachment }>('copy');
  delete = this.createPayloadAction<{ requestId: string; attachmentId: string }>('delete');
  fetch = this.createPayloadAction<string>('fetch');
  save = this.createPayloadAction<{ requestId: string; attachment: Partial<ISgwRequestAttachment> }>('save');
  set = this.createPayloadAction<ISgwRequestAttachment[]>('set');
  setLoading = this.createPayloadAction<boolean>('setLoading');
}

class ConflictsActions extends Actions {
  setLoading = this.createPayloadAction<boolean>('setLoading');
  sync = this.createPayloadAction<number>('sync');
  fetchList = this.createPayloadAction<string>('fetchList');
  setList = this.createPayloadAction<IConflictGroup[]>('setList');
  sendEmailAP = this.createPayloadAction<{ requestId: string; conflictId: number }>('sendEmailAP');
  save = this.createPayloadAction<{ requestId: number; conflictGroup: Partial<IConflictGroup> }>('patchConflictGroup');
  fetchMessages = this.createPayloadAction<{ requestId: number; conflictGroupId: number }>('fetchMessages');
  setMessage = this.createPayloadAction<IRequestMessagesList>('setMessage');
  saveMessage = this.createPayloadAction<{ conflictGroupId: number; message: IRequestMessage }>('saveMessage');
}
class ReschedulingsActions extends Actions {
  apply = this.createPayloadAction<{ requestId: string; reschedulingId: string }>('apply');
  consider = this.createPayloadAction<{ requestId: string; reschedulingId: string }>('consider');
  fetch = this.createPayloadAction<string>('fetch');
  reject = this.createPayloadAction<{ requestId: string; reschedulingId: string }>('reject');
  set = this.createPayloadAction<IRequestRescheduling[]>('set');
  update = this.createPayloadAction<{
    requestId: string;
    reschedulingId: string;
    rescheduling: Partial<IRequestRescheduling>;
  }>('update');
}
class QuarterCostActions extends Actions {
  fetch = this.createPayloadAction<string>('fetch');
  set = this.createPayloadAction<ISgwQuarterCost[]>('set');
  setLoading = this.createPayloadAction<boolean>('setLoading');
}

class SgwRequestsActionsClass extends Actions {
  attachments = new AttachmentsActions(`${this.prefix}.attachments`);
  list = new TableActions<ISgwRequest, ISgwRequestsFilter>(`${this.prefix}.list`);
  fetchRequestCounters = this.createAction('fetchRequestCounters');
  setRequestCounters = this.createPayloadAction<ISgwRequestCounters>('setRequestCounters');
  fetch = this.createPayloadAction<string>('fetch');
  set = this.createPayloadAction<ISgwRequest>('set');
  syncDates = this.createPayloadAction<{ requestId: number; phase?: Partial<ISgwPhase> }>('syncDates');
  setLoading = this.createPayloadAction<boolean>('setLoading');
  fetchSgwRequestMessages = this.createPayloadAction<string>('fetchSgwRequestMessages');
  saveSgwRequestMessage = this.createPayloadAction<IRequestMessage>('saveSgwRequestMessage');
  resolveSgwRequestMessages = this.createPayloadAction<string>('resolveSgwRequestMessages');
  assign = this.createPayloadAction<{ id: number; assign: boolean }>('assign');
  patch = this.createPayloadAction<Partial<ISgwRequestBE>>('patch');
  postTransition = this.createPayloadAction<ISgwRequestPostTransition>('postTransition');
  patchInternalNotes = this.createPayloadAction<ISgwRequestPatchInternalNotes>('patchInternalNotes');
  conflicts = new ConflictsActions(`${this.prefix}.conflicts`);
  sendEmailPermit = this.createPayloadAction<{ requestId: string }>('sendEmailPermit');
  sendFinalInvoice = this.createPayloadAction<string>('sendFinalInvoice');
  reschedulings = new ReschedulingsActions(`${this.prefix}.reschedulings`);
  quarterCost = new QuarterCostActions(`${this.prefix}.quarterCost`);
  fetchSgwRequestHistory = this.createPayloadAction<{ requestId: string; sorting: ISorting }>('fetchRequestHistory');
  setSgwRequestHistory = this.createPayloadAction<ISgwRequestHistory>('setSgwRequestHistory');
  fetchSgwRequestPermitHistory = this.createPayloadAction<{ id: string }>('fetchSgwRequestPermitHistory');
  setSgwRequestPermitHistory = this.createPayloadAction<ISgwRequestPermit[]>('setSgwRequestPermitHistory');
}

export const SgwRequestActions = new SgwRequestsActionsClass('sgwRequests');
