import { makeStyles } from '@material-ui/styles';
import React, { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { COLORS } from '../../../common';
import AsignButton from '../../../common/components/buttons/asign-button';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { Icon } from '../../../common/components/layout/icon.component';
import { Visible } from '../../../common/components/layout/Visible.component';
import ModalDialog from '../../../common/components/modal-dialog/modal-dialog.component';
import { translate, translateIgnoreTS } from '../../../common/translations/translate';
import { IUser } from '../../../common/types/user';
import { useModalHandler } from '../../../hooks';
import { selectAlreadyAssigned, selectUser } from '../../../store/selectors';
import classNames from 'classnames';

const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    marginLeft: '20px',
  },
  assignToMeButton: {
    marginLeft: '20px',
    width: '150px',
    '& span': {
      marginLeft: '5px',
    },
  },
  assignedLabel: {
    color: COLORS.GREY,
    marginRight: '5px',
  },
  assignedToNobody: {
    color: COLORS.MAGENTA,
  },
});

interface IProps {
  assignedUser: Partial<IUser> | undefined;
  onAssignRequest(toMe?: boolean, checkFirst?: boolean): void;
  onClose?(): void;
  canAssign: boolean;
  canUnassign: boolean;
  showLabels?: boolean;
  visible?: boolean;
}

export const AssignButton: FC<IProps> = ({
  assignedUser,
  onAssignRequest,
  onClose,
  canAssign,
  canUnassign,
  showLabels = true,
  visible = true,
}) => {
  const C = useStyles();
  const { open, closeModal, openModal } = useModalHandler();
  const user = useSelector(selectUser);
  const alreadyAssigned = useSelector(selectAlreadyAssigned);
  const assignedToMe = assignedUser && assignedUser.id === user?.id;

  const getUserName = (): string => {
    if (assignedToMe) return translate('Requests.Header.assign.me');
    if (assignedUser?.fullName) return assignedUser.fullName;
    return translate('Requests.Header.assign.nobody');
  };

  const _onClose = useCallback(() => {
    onClose?.();
    closeModal();
  }, [closeModal, onClose]);

  const onConfirm = useCallback(() => {
    _onClose();
    onAssignRequest(assignedUser ? !assignedToMe : true);
  }, [_onClose, onAssignRequest, assignedUser, assignedToMe]);

  const onAssignToMe = () => {
    if (assignedUser) {
      openModal();
    } else {
      onAssignRequest(true, true);
    }
  };

  useEffect(() => {
    alreadyAssigned && openModal();
  }, [alreadyAssigned, openModal]);

  return (
    <Visible visible={visible}>
      <div data-testid="sgwRequestAssignButton" className={C.root}>
        <Visible visible={showLabels}>
          <span className={C.assignedLabel}>{translate('Requests.Header.assign.to')}</span>
          <span data-testid="assignedUser" className={classNames(!assignedUser && C.assignedToNobody)}>
            {getUserName()}
          </span>
        </Visible>
        <Visible visible={canUnassign}>
          <Button.White
            data-testid="unassignButton"
            iconButton
            onClick={openModal}
            className={C.button}
            tooltip={translate('Requests.Header.assign.confirm.unassignTitle')}
          >
            <Icon.UnassignUser />
          </Button.White>
        </Visible>
        <Visible visible={canAssign}>
          <Visible
            visible={showLabels}
            defaultComponent={
              <Button.White
                data-testid="assignToMeButton"
                iconButton
                onClick={onAssignToMe}
                className={C.button}
                tooltip={translate('Requests.Header.assign.toMe')}
              >
                <Icon.AssignUserInverted />
              </Button.White>
            }
          >
            <AsignButton
              color="primary"
              data-testid="assignToMeButton"
              onClick={onAssignToMe}
              className={C.assignToMeButton}
            >
              <Icon.AssignUser />
              <span>{translate('Requests.Header.assign.toMe')}</span>
            </AsignButton>
          </Visible>
        </Visible>
      </div>
      <ModalDialog
        okButtonText={translate('yes')}
        onClose={_onClose}
        onConfirm={onConfirm}
        title={translateIgnoreTS(
          `Requests.Header.assign.confirm.${assignedToMe && !alreadyAssigned ? 'una' : 'a'}ssignTitle`,
        )}
        visible={open}
      >
        {translateIgnoreTS(
          `Requests.Header.assign.confirm.${assignedToMe && !alreadyAssigned ? 'una' : 'a'}ssignDescription`,
        )}
      </ModalDialog>
    </Visible>
  );
};
