import { IUser, IUserBE } from '../common/types/user';

export const mapToIUserBE = (user: IUser): IUserBE => {
  const { utilityCompany, roles, role, ...userData } = user;

  return {
    ...userData,
    ...(role ? { roles: [role] } : {}),
    ...(utilityCompany ? { utilityCompany: utilityCompany.id } : {}),
  };
};
