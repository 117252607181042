import { Actions, TableActions } from '../..';
import { IMoovTemplate } from '../../../types';

class MoovActionsClass extends Actions {
  fetchAll = this.createAction('fetchAll');
  setAll = this.createPayloadAction<IMoovTemplate[]>('setAll');
  setSyncTemplatesLoading = this.createPayloadAction<boolean>('setSyncTemplatesLoading');
  syncTemplates = this.createAction('syncTemplates');
  list = new TableActions<IMoovTemplate>(`${this.prefix}.list`);
}

export const MoovActions = new MoovActionsClass('moov');
