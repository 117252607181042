import type { ISgwRequestsState } from '../../types';
import { ISortingDirection } from '../../common';

export const initialSgwRequestsState: ISgwRequestsState = {
  attachments: [],
  attachmentsLoading: true,
  list: {
    allIds: [],
    byId: {},
    table: {
      filters: {},
      paging: {
        page: 1,
        pageSize: 20,
        totalPages: 1,
        totalRecords: 20,
      },
      sorting: {
        direction: ISortingDirection.desc,
        key: 'createdAt',
      },
    },
    loading: true,
  },
  counters: {
    checkGIS: 0,
    inEffect: 0,
    inReview: 0,
    new: 0,
    reschedule: 0,
    sleeping: 0,
    urgent: 0,
    finalInvoice: 0,
    finalInvoiceSent: 0,
    todo: 0,
  },
  request: null,
  loading: true,
  phases: [],
  conflicts: {
    loading: true,
    groups: [],
    messages: {
      byId: {},
    },
  },
  reschedulings: [],
  quarterCost: {
    costs: [],
    loading: false,
  },
  history: {
    history: [],
    total: 0,
  },
  permitHistory: [],
  offsetDays: 0,
};
