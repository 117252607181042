export interface ISgwRequestHistory {
  history: ISgwHistory[];
  total: number;
}

export interface ISgwHistory {
  timestamp: string;
  newValue: SgwRequestHistoryDescription | string | number;
  oldValue: string;
  description: SgwRequestHistoryDescription;
  userName: string;
}

export enum SgwRequestHistoryDescription {
  StateGeneral = 'state; general',
  CostValue = 'cost; value',
  Invoice = 'invoice',
  StateConflict = 'state; conflict',
  SyncConflict = 'sync; conflict',
  AdviceConflict = 'advice; conflict',
  ManualAdviceConlfict = 'manualadvice; conflict',
}

export enum SgwRequestHistoryValues {
  ConflictResolved = 'resolved',
  ConflictNotApplicatble = 'notApplicable',
  ConflictFeedbackGiven = 'feedbackGiven',
  ConflictAwaitingFeedback = 'awaitingFeedback',
  StateDraft = 'draft',
  StateSubmitted = 'submitted',
  StateReviewing = 'reviewing',
  StateCanceled = 'canceled',
  StatePermitGranted = 'permit_granted',
  StateRejected = 'rejected',
  StateInEffect = 'in_effect',
  StatePermitEnded = 'permit_ended',
  StateClosed = 'closed',
}
