import * as React from 'react';
import { Route, Switch } from 'react-router';
import { appUrls } from '../common/config/url.constants';
import { Auth } from '../components';
import { ICarFreeZoneAclActions } from '../types';
import { KrautliZones } from './components/KrautliZones.container';
import { MoovTemplates } from './components/MoovTemplates.page';
import { Exemptions } from './components/Exemptions.page';
import { RouterMenu } from '../components';
import { translate } from '../common/translations/translate';
import { useSelector } from 'react-redux';
import { getIsAuthorized } from '../common/store/user/user.selectors';

export const CarFreeZoneRoutes = () => {
  const canViewCarFreeZoneList = useSelector(getIsAuthorized([ICarFreeZoneAclActions.viewCarFreeZoneList]));
  const canViewMoovTemplates = useSelector(getIsAuthorized([ICarFreeZoneAclActions.viewMoovTemplates]));
  const canViewExemptions = useSelector(getIsAuthorized([ICarFreeZoneAclActions.viewExemptions]));

  return (
    <>
      <Auth
        acl={[
          ICarFreeZoneAclActions.viewCarFreeZone,
          ICarFreeZoneAclActions.viewCarFreeZoneList,
          ICarFreeZoneAclActions.viewExemptions,
        ]}
      >
        <RouterMenu
          tabs={[
            {
              label: translate('carFreeZones.krautli.title'),
              route: appUrls.carFreeZones.krautliZones.base,
              visible: canViewCarFreeZoneList,
            },
            {
              label: translate('carFreeZones.moovTemplates.title'),
              route: appUrls.carFreeZones.moovTemplates,
              visible: canViewMoovTemplates,
            },
            {
              label: translate('carFreeZones.exemptions.title'),
              route: appUrls.carFreeZones.exemptions,
              visible: canViewExemptions,
            },
          ]}
        />
        <Switch>
          <Route exact path={appUrls.carFreeZones.moovTemplates}>
            <MoovTemplates />
          </Route>
          <Route exact path={appUrls.carFreeZones.exemptions}>
            <Exemptions />
          </Route>
          <Route path={appUrls.carFreeZones.base}>
            <KrautliZones />
          </Route>
        </Switch>
      </Auth>
    </>
  );
};
