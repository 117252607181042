import { Actions, TableActions } from '../../common';
import { ISgwAdvisingParty, ISgwAdvisingPartiesFilter } from '../../types/sgwAdvisingParties.types';

class SgwAdvisingPartiesActionsClass extends Actions {
  list = new TableActions<ISgwAdvisingParty, ISgwAdvisingPartiesFilter>(`${this.prefix}.list`);
  setLoading = this.createPayloadAction<boolean>('setLoading');
  set = this.createPayloadAction<ISgwAdvisingParty>('set');
  fetch = this.createPayloadAction<string>('fetch');
  save = this.createPayloadAction<ISgwAdvisingParty>('save');
}

export const SgwAdvisingPartiesActions = new SgwAdvisingPartiesActionsClass('sgwAdvisingParties');
