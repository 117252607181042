import React, { useCallback, useMemo, useState } from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import { SortableItem } from './SortableItem.component';
import { createPortal } from 'react-dom';
import { ISortableListProps } from '../../../types';
import { createUseStyles } from 'react-jss';

const defaultGetItemId = ({ id }: any) => id;

const useStyles = createUseStyles({
  sortableList: { overflow: 'auto' },
});

export const SortableList = <TListItem, TInjectProps = {}>({
  disabled = false,
  injectedProps,
  items,
  getItemId = defaultGetItemId,
  renderItem,
  onSort,
}: ISortableListProps<TListItem, TInjectProps>) => {
  const itemIds = useMemo(() => items.map(getItemId), [items, getItemId]);
  const [activeId, setActiveId] = useState<UniqueIdentifier>();
  const C = useStyles();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragStart = useCallback(({ active: { id } }: DragStartEvent) => {
    setActiveId(id);
  }, []);

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (over && active.id !== over.id) {
        const oldIndex = itemIds.indexOf(active.id);
        const newIndex = itemIds.indexOf(over.id);
        if (onSort) onSort(oldIndex, newIndex);
      }
      setActiveId(undefined);
    },
    [itemIds, onSort],
  );

  const handleDragCancel = () => {
    setActiveId(undefined);
  };

  return (
    <div className={C.sortableList}>
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
        collisionDetection={closestCenter}
        modifiers={[restrictToFirstScrollableAncestor]}
      >
        <SortableContext items={itemIds} strategy={verticalListSortingStrategy} disabled={disabled}>
          {itemIds.map((id, i) => (
            <SortableItem<TListItem, TInjectProps>
              key={i}
              id={id}
              injectedProps={injectedProps}
              item={items[i]}
              renderItem={renderItem}
              isDragged={false}
            />
          ))}
        </SortableContext>
        {createPortal(
          <DragOverlay adjustScale={false} dropAnimation={null}>
            {activeId ? (
              <SortableItem<TListItem, TInjectProps>
                id={activeId}
                item={items[itemIds.indexOf(activeId)]}
                renderItem={renderItem}
                isDragged
              />
            ) : (
              <></>
            )}
          </DragOverlay>,
          document.body,
        )}
      </DndContext>
    </div>
  );
};
