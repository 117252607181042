import React, { FC } from 'react';
import { useVatValidator } from '../../../hooks';
import { useFormContext } from 'react-hook-form';
import { Grid, TextField } from '@material-ui/core';
import { translate } from '../../../common/translations/translate';
import { ICompany } from '../../../types';
import { useGeneralStyles } from '../../../style/generalStyles';

export const CompanyFields: FC = () => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<ICompany>();
  const validateVat = useVatValidator(true, 'vatNumber', 'companyName');

  return (
    <Grid container spacing={2} className={useGeneralStyles().gridContainerPadding}>
      <Grid item xs={6}>
        <TextField
          {...register('vatNumber', { required: true, validate: validateVat })}
          label={translate('sgw.partners.columns.vatNumber')}
          required
          fullWidth
          error={!!errors?.vatNumber?.message}
          helperText={errors?.vatNumber?.message}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          {...register('companyName', { required: true })}
          label={translate('sgw.partners.columns.companyName')}
          required
          fullWidth
          InputLabelProps={{ shrink: !!watch('companyName') }}
          error={!!errors?.companyName?.message}
          helperText={errors?.companyName?.message}
        />
      </Grid>
    </Grid>
  );
};
