import arrayMove from 'array-move';
import * as React from 'react';
import { Loader } from '../../common/components/layout/loader.component';
import { IDraggableWorkOrderItemInjectedProps, IWorkOrder, IWorkOrderItem } from '../../types';
import { SortableList } from '../../components/molecules/SortableList/SortableList.component';
import { DraggableWorkOrderItem } from './DraggableWorkOrderItem';

interface IProps {
  workOrder: IWorkOrder;
  teamWoiClick: (woi: IWorkOrderItem) => void;
  updateWorkOrder: (order: IWorkOrder) => void;
  workOrdersLoading?: boolean;
  planningDisabled?: boolean;
}

export default class TeamStatusItems extends React.Component<IProps> {
  public render(): React.ReactNode {
    return this.props.workOrdersLoading ? (
      <div className={'items-loading-wrapper'}>
        <Loader />
      </div>
    ) : (
      <SortableList<IWorkOrderItem, IDraggableWorkOrderItemInjectedProps>
        disabled={this.props.planningDisabled}
        injectedProps={{
          onClick: this.props.teamWoiClick,
          workOrder: this.props.workOrder,
          canReorder: !this.props.planningDisabled,
        }}
        items={this.props.workOrder.workOrderItems || []}
        // @ts-ignore
        renderItem={DraggableWorkOrderItem}
        onSort={this.onSortEnd}
      />
    );
  }

  private onSortEnd = (oldIndex: number, newIndex: number) => {
    if (this.props.workOrder.workOrderItems) {
      const workOrder = this.props.workOrder;
      workOrder.workOrderItems = arrayMove(this.props.workOrder.workOrderItems, oldIndex, newIndex);
      this.props.updateWorkOrder(workOrder);
    }
  };
}
