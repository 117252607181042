import * as React from 'react';
import { RequestListOverview } from '../../../../organisms';
import { SgwRequestList } from '../../../../molecules';
import { ISgwAdvisingPartiesAclActions, ISgwRequestAclActions } from '../../../../../types';
import { Auth, PageGrid } from '../../../../atoms';

export const Dashboard = () => (
  <Auth
    acl={[
      ISgwRequestAclActions.viewSgwRequestOverview,
      ISgwAdvisingPartiesAclActions.viewSgwAdvisingPartyRequestOverview,
    ]}
    showUnauthorizedPage
  >
    <PageGrid fullWidth>
      <RequestListOverview />
      <SgwRequestList />
    </PageGrid>
  </Auth>
);
