import { IMoovTemplate } from '../../../../types';
import { IById } from '../../../utils/normalized.util';
import { createReducer } from '@reduxjs/toolkit';
import { CarFreeZonesActions } from '../carFreeZones.actions';

type TState = IById<IMoovTemplate[]>;

export default createReducer<TState>(
  {},
  {
    [CarFreeZonesActions.setTemplates.type]: (
      state: TState,
      { payload }: ReturnType<typeof CarFreeZonesActions.setTemplates>,
    ): TState => ({ ...state, ...payload }),
  },
);
