import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { genericErrorHandler } from '../../common';
import { MetadataApi } from '../api/metadata.api';
import { ICall } from '../../types';
import { MetadataActions } from '../actions/metadata.actions';

function* onFetchMetadata(): SagaIterator {
  const response: ICall<typeof MetadataApi.fetch> = yield call(MetadataApi.fetch);

  yield put(
    MetadataActions.set({
      ...response!.data.data,
      loaded: true,
    }),
  );
}

function* onFetchAppVersions(): SagaIterator {
  const response: ICall<typeof MetadataApi.fetchAppVersions> = yield call(MetadataApi.fetchAppVersions);

  yield put(MetadataActions.setAppVersions(response!.data.data));
}

export function* metadataSaga(): SagaIterator {
  yield takeLatest(MetadataActions.fetch.type, genericErrorHandler(onFetchMetadata));
  yield takeLatest(MetadataActions.fetchAppVersions.type, genericErrorHandler(onFetchAppVersions));
}
