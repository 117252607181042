import React, { FC, useCallback, useMemo } from 'react';
import { Visible } from '../../../common/components/layout/Visible.component';
import { useRequestDetailStyles } from './requestDetailInfo.styles';
import { Grid, TextField } from '@material-ui/core';
import { LabelValue } from '../../../common/components/layout/LabelValue.component';
import { translate, translateIgnoreTS } from '../../../common/translations/translate';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getSgwPartner } from '../../../store/selectors/sgwPartners.selectors';
import { Capacity, ISgwRequestInfo, WorkType } from '../../../types';
import { useRequestDetailInfoForm } from '../../../hooks/forms/useRequestDetailInfoForm.hook';
import { FormProvider } from 'react-hook-form';
import { useProjects } from '../../../hooks/useProjects.hook';
import { SingleSelectV2Component } from '../../../common/components/form-fields/single-select-v2.component';
import { useMetadata } from '../../../hooks';
import { MultiSelectV2Component } from '../../../common/components/form-fields/multi-select-v2.component';
import { RequestDetailAddressFields } from '../../molecules/RequestDetailInfoAddressFields/RequestDetailAddressFields.component';
import { RequestDetailInfoCraneFields } from '../../molecules/RequestDetailInfoCraneFields/RequestDetailInfoCraneFields.component';
import { SgwPriorityPicker } from '../../molecules/SgwPriorityPicker/SgwPriorityPicker.component';
import { SubmitButton } from '../../atoms';

export const RequestDetailInfoForm: FC<ISgwRequestInfo> = (props) => {
  const { id, referenceId, prio, requestorId, cluster } = props;
  const C = useRequestDetailStyles();
  const { projects } = useProjects(true);
  const {
    sgw: { districts },
  } = useMetadata();

  const partner = useSelector(getSgwPartner(requestorId));
  const { formMethods, onSubmit } = useRequestDetailInfoForm(props);
  const { register, watch, setValue, getFieldState } = formMethods;

  const sgwDistrictIdsState = getFieldState('sgwDistrictIds');
  const sgwProject = watch('sgwProject');
  const selectedSgwProjectOption = sgwProject?.name;

  const workTypeOptions = Object.values(WorkType).map((workType) => ({
    label: translateIgnoreTS(`sgw.requests.workTypes.${workType}`),
    value: workType,
  }));

  const onSelectSgwProject = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setValue(
        'sgwProject',
        projects.find((project) => project.name === e.target.value),
      );
    },
    [projects, setValue],
  );

  const selectableProjects = useMemo(() => {
    const projectMenuItems = [{ label: '', value: '' }, ...projects.map(({ name }) => ({ label: name, value: name }))];

    return sgwProject ? [...projectMenuItems, { label: sgwProject.name, value: sgwProject.name }] : projectMenuItems;
  }, [projects, sgwProject]);

  return (
    <FormProvider {...formMethods}>
      <form id="requestDetailInfoForm" onSubmit={onSubmit}>
        <Grid container className={C.gridContainer}>
          <Grid container xs={6} spacing={2} className={classNames(C.resetSpacing, C.leftPanel)}>
            <LabelValue label={translate('sgw.requests.detail.info.identificationNumber')}>{id}</LabelValue>
            <LabelValue visible={!!cluster?.displayName} label={translate('sgw.requests.detail.info.cluster')}>
              {cluster?.displayName}
            </LabelValue>
            <LabelValue label={translate('sgw.requests.detail.info.district')}>
              <MultiSelectV2Component
                {...register('sgwDistrictIds', {
                  required: { value: true, message: translate('services.form.required') },
                })}
                error={sgwDistrictIdsState.error?.message}
                values={watch('sgwDistrictIds') || []}
                onChange={(newValues) => setValue('sgwDistrictIds', newValues)}
                menuItems={districts.map(({ name, id }) => ({ label: name, value: id }))}
              />
            </LabelValue>
            <LabelValue visible={!!referenceId} label={translate('sgw.requests.detail.info.referenceNumber')}>
              {referenceId}
            </LabelValue>
            <SgwPriorityPicker prio={prio} />
            <LabelValue label={translate('sgw.requests.detail.info.projectName')}>
              <SingleSelectV2Component
                {...register('sgwProject')}
                value={selectedSgwProjectOption}
                onChange={onSelectSgwProject}
                menuItems={selectableProjects}
              />
            </LabelValue>
            <RequestDetailInfoCraneFields />
          </Grid>
          <Grid container xs={6} spacing={2} className={classNames(C.resetSpacing, C.fitContent)}>
            <LabelValue label={translate('sgw.requests.detail.info.requestedBy')} visible={!!partner}>
              <Visible
                visible={partner?.capacity === Capacity.Business}
                defaultComponent={
                  <>
                    {partner?.firstName} {partner?.lastName}
                  </>
                }
              >
                {partner?.companyName}
              </Visible>
            </LabelValue>
            <RequestDetailAddressFields />
            <LabelValue label={translate('sgw.requests.detail.info.typeOfWorkZone')}>
              <SingleSelectV2Component
                {...register('workType')}
                value={watch('workType')}
                onChange={(e) => setValue('workType', e.target.value as WorkType)}
                menuItems={workTypeOptions}
                disabled
              />
            </LabelValue>
            <LabelValue label={translate('sgw.requests.detail.info.gipodId')}>
              <TextField {...register('gipodId')} disabled />
            </LabelValue>
            {props.workType === WorkType.GroundWorksToPublicDomain && (
              <>
                <LabelValue label={translate('sgw.requests.detail.info.bonuNumber')}>
                  <TextField {...register('bonuNumber')} />
                </LabelValue>
                <LabelValue label={translate('sgw.requests.detail.info.utilityCompanyName')}>
                  <TextField {...register('utilityCompanyName', { required: true })} required />
                </LabelValue>
                <LabelValue label={translate('sgw.requests.detail.info.utilityCompanyNameResponsible')}>
                  <TextField {...register('utilityCompanyNameResponsible', { required: true })} required />
                </LabelValue>
                <LabelValue label={translate('sgw.requests.detail.info.utilityCompanyPhoneResponsible')}>
                  <TextField {...register('utilityCompanyPhoneResponsible', { required: true })} required />
                </LabelValue>
                <LabelValue label={translate('sgw.requests.detail.info.utilityCompanyEmailResponsible')}>
                  <TextField {...register('utilityCompanyEmailResponsible', { required: true })} required />
                </LabelValue>
              </>
            )}
          </Grid>
        </Grid>
      </form>
      <SubmitButton formId="requestDetailInfoForm">{translate('sgw.requests.detail.info.save')}</SubmitButton>
    </FormProvider>
  );
};
