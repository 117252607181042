import {
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { Close, Done } from '@material-ui/icons';
import * as React from 'react';
import { IWithTestId } from '../../../types';
import AsignButton from '../buttons/asign-button';
import { translate } from '../../translations/translate';

const styles = (theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: 10,
      top: 13,
    },
    title: {
      borderBottom: 1,
      borderBottomColor: '#cccccc',
      borderBottomStyle: 'solid',
      margin: 0,
      padding: 20,
      paddingRight: 80,
    },
  });

interface IDialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: IDialogTitleProps) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.title}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: 50,
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    border: 0,
    borderStyle: 'solid',
    borderTop: 1,
    borderTopColor: '#cccccc',
    margin: 0,
    padding: 20,
  },
}))(MuiDialogActions);

interface IProps extends IWithTestId {
  title: string | JSX.Element;
  children: any;
  onClose: () => void;
  onConfirm?: (props?: any) => void;
  buttons?: any;
  okButtonText?: string;
  refuseButtonText?: string;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  visible?: boolean;
  form?: string;
}

export default class ModalDialog extends React.Component<IProps> {
  public static defaultProps = {
    maxWidth: 'sm',
  };

  public render(): React.ReactNode {
    const { visible = true } = this.props;
    return visible ? (
      <Dialog
        maxWidth={this.props.maxWidth}
        fullWidth
        open
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={this.onClose}
        data-testid={this.props['data-testid']}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle id="alert-dialog-title" onClose={this.onClose}>
          {this.props.title}
        </DialogTitle>

        <DialogContent>{this.props.children}</DialogContent>

        <DialogActions>
          {this.props.buttons || (
            <>
              <AsignButton color="default" className={'button-margin-left'} onClick={this.onClose}>
                <Close /> {this.props.refuseButtonText || translate('refuse')}
              </AsignButton>
              <AsignButton
                color="primary"
                className={'button-margin-left'}
                onClick={this.onConfirm}
                form={this.props.form}
              >
                <Done /> {this.props.okButtonText || translate('ok')}
              </AsignButton>
            </>
          )}
        </DialogActions>
      </Dialog>
    ) : null;
  }

  private onClose = () => {
    this.props.onClose();
  };

  private onConfirm = () => {
    if (this.props.onConfirm) {
      this.props.onConfirm();
    }
  };
}
