import { Grid, TextField } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React, { ChangeEvent, FC, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Panel, PanelAction } from '../../../common';
import { CheckboxWithLabel } from '../../../common/components/form-fields/checkboxWithLabel.component';
import { translate } from '../../../common/translations/translate';
import { useSgwPermitConditionForm } from '../../../hooks';
import { SgwPermitConditionsActions } from '../../../store/actions';
import { useGeneralStyles } from '../../../style/generalStyles';
import { useHistory } from 'react-router';
import { appUrls } from '../../../common/config/url.constants';
import { useFormStyles } from '../../../style/form.styles';
import { Visible } from '../../../common/components/layout/Visible.component';
import { SubmitButton } from '../../atoms';
import { SingleSelectV2Component } from '../../../common/components/form-fields/single-select-v2.component';
import { getFilterOptions, uniqueFieldValidator } from '../../../utils';
import { SgwPermitConditionCategory } from '../../../types';
import {
  selectExistingReferenceCodes,
  selectExistingTitles,
} from '../../../store/selectors/sgwPermitConditions.selectors';
import { ErrorLabel } from '../../atoms/ErrorLabel/ErrorLabel.component';

interface IProps {
  title: string;
}

export const PermitConditionForm: FC<IProps> = ({ title }) => {
  const C = useFormStyles();
  const G = useGeneralStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { formMethods, submitPermitCondition, id, initialValues } = useSgwPermitConditionForm();
  const {
    watch,
    register,
    setValue,
    formState: { errors },
  } = formMethods;
  const existingReferenceCodes = useSelector(selectExistingReferenceCodes);
  const existingTitles = useSelector(selectExistingTitles);

  useEffect(() => {
    id && dispatch(SgwPermitConditionsActions.fetch(id));
  }, [dispatch, id]);

  const onCheckChange = (checked: boolean) => setValue('active', !checked);

  const goToDetail = () => history.push(appUrls.sgw.manage.permitConditions.detail(id));

  const onRoleChange = (e: ChangeEvent<{ value: SgwPermitConditionCategory }>) => setValue('category', e.target.value);

  const onChangeCode = (e: ChangeEvent<{ value: string }>) => {
    setValue('referenceCode', e.target.value);
    dispatch(SgwPermitConditionsActions.searchReferenceCode(e.target.value));
  };

  const onChangeTitle = (e: ChangeEvent<{ value: string }>) => {
    setValue('title', e.target.value);
    dispatch(SgwPermitConditionsActions.searchTitle(e.target.value));
  };

  return (
    <FormProvider {...formMethods}>
      <form id={'createPermitCondition'} onSubmit={submitPermitCondition} data-testid="permitConditionForm" noValidate>
        <Panel title={title}>
          <Visible visible={!!id}>
            <PanelAction icon={<Edit />} onClick={goToDetail} data-testid="editButton" />
          </Visible>
          <Grid container spacing={3} className={C.formGrid}>
            <Grid item xs={12}>
              <SingleSelectV2Component
                menuItems={getFilterOptions(SgwPermitConditionCategory, 'sgw.permitConditions.categories')}
                data-testid="categoriesField"
                placeholder={translate('sgw.permitConditions.categoryPlaceholder')}
                title={watch('category') ? translate('sgw.permitConditions.columns.category') : ''}
                style={{ maxWidth: 'none' }}
                value={watch('category')}
                onChange={onRoleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={useGeneralStyles().flexColumn}>
                <TextField
                  {...register('referenceCode', {
                    required: true,
                    validate: uniqueFieldValidator(
                      existingReferenceCodes,
                      translate('sgw.permitConditions.referenceCodeExists'),
                      initialValues?.referenceCode,
                    ),
                  })}
                  label={translate('sgw.permitConditions.columns.referenceCode')}
                  className={G.fullWidth}
                  required
                  data-testid="referenceCodeField"
                  error={!!errors.referenceCode}
                  onChange={onChangeCode}
                />
                <ErrorLabel
                  showError={errors.referenceCode?.type === 'validate'}
                  message={errors.referenceCode?.message}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={useGeneralStyles().flexColumn}>
                <TextField
                  {...register('title', {
                    required: true,
                    validate: uniqueFieldValidator(
                      existingTitles,
                      translate('sgw.permitConditions.titleExists'),
                      initialValues?.title,
                    ),
                  })}
                  label={translate('sgw.permitConditions.columns.title')}
                  className={G.fullWidth}
                  required
                  data-testid="titleField"
                  error={!!errors.title}
                  onChange={onChangeTitle}
                />
                <ErrorLabel showError={errors.title?.type === 'validate'} message={errors.title?.message} />
              </div>
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('description', { required: true })}
                label={translate('sgw.permitConditions.columns.description')}
                className={G.fullWidth}
                required
                data-testid="descriptionField"
                error={!!errors.description}
              />
            </Grid>
            <Grid item xs={12}>
              <CheckboxWithLabel
                {...register('active')}
                checked={!watch('active')}
                label={translate('sgw.permitConditions.columns.deactivate')}
                onChange={onCheckChange}
                className={C.checkbox}
              />
            </Grid>
          </Grid>
        </Panel>
        <SubmitButton formId="createPermitCondition" data-testid="createButton">
          {translate('sgw.permitConditions.savePermitCondition')}
        </SubmitButton>
      </form>
    </FormProvider>
  );
};
