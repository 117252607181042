import { useCallback } from 'react';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { RequestActions } from '../../../store/actions';
import { IRequestOverview } from '../../../types';
import { DraftComponent } from './Draft.component';
import { appUrls } from '../../../common/config/url.constants';

interface IProps {
  draft: IRequestOverview;
}

export const Draft: React.FunctionComponent<IProps> = ({ draft }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onEdit = useCallback(() => {
    history.push(appUrls.requests.edit(`${draft.id}`));
  }, [history, draft.id]);

  const onDelete = useCallback(() => {
    dispatch(RequestActions.remove(`${draft.id}`));
  }, [dispatch, draft.id]);

  return <DraftComponent onEdit={onEdit} onDelete={onDelete} draft={draft} />;
};
