import React, { FC } from 'react';
import { Route, Switch } from 'react-router';
import { appUrls } from '../../common/config/url.constants';
import {
  CreateTrafficMeasuresPage,
  DetailTrafficMeasurePage,
  EditTrafficMeasurePage,
  TrafficMeasuresPage,
} from '../pages';

export const TrafficMeasuresRoutes: FC = () => (
  <Switch>
    <Route exact path={appUrls.sgw.manage.trafficMeasures.base}>
      <TrafficMeasuresPage />
    </Route>
    <Route exact path={appUrls.sgw.manage.trafficMeasures.create}>
      <CreateTrafficMeasuresPage />
    </Route>
    <Route exact path={appUrls.sgw.manage.trafficMeasures.edit()}>
      <EditTrafficMeasurePage />
    </Route>
    <Route exact path={appUrls.sgw.manage.trafficMeasures.detail()}>
      <DetailTrafficMeasurePage />
    </Route>
  </Switch>
);
