import { Actions } from '../../common';
import {
  IFetchGeocode,
  IFetchSuggestions,
  IFile,
  IGeometry,
  IPublicDomainIntakeExtended,
  IRequestLocation,
  IRequestLocationDetail,
  IRequestLocationForm,
  IRequestLocationPatch,
  ISuggestion,
} from '../../types';
import { Point } from 'geojson';
import { IWithId } from '../../common/utils/normalized.util';

class RequestLocationsActionsClass extends Actions {
  addSign = this.createPayloadAction<{ location: IPublicDomainIntakeExtended; signId: string }>('addSign');
  fetch = this.createPayloadAction<IRequestLocation>('fetch');
  fetchGeocode = this.createPayloadAction<IFetchGeocode>('fetchGeocode');
  fetchSuggestions = this.createPayloadAction<IFetchSuggestions>('fetchSuggestions');
  markSignsAsMissing = this.createPayloadAction<IPublicDomainIntakeExtended>('markSignsAsMissing');
  patch = this.createPayloadAction<IRequestLocationPatch>('patch');
  removeFile = this.createPayloadAction<string>('removeFile');
  removeSign = this.createPayloadAction<{ location: IPublicDomainIntakeExtended; signId: string }>('removeSign');
  reverseGeocode = this.createPayloadAction<{ geometry: IGeometry; locationId: number }>('reverseGeocode');
  set = this.createPayloadAction<IRequestLocationDetail>('set');
  setGeocode = this.createPayloadAction<IWithId<Point>>('setGeocode');
  setReverseGeocode = this.createPayloadAction<Partial<IRequestLocationForm>>('setReverseGeocode');
  setSuggestions = this.createPayloadAction<ISuggestion[]>('setSuggestions');
  uploadFile = this.createPayloadAction<IFile>('uploadFile');
}

export const RequestLocationsActions = new RequestLocationsActionsClass('requestLocation');
