import * as React from 'react';
import { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BaseMap, IMapLocation } from '../../common';
import { MapSearchControl } from '../../common/components/map/map-search-control.component';
import { searchStreet } from '../../planning/store/planning.actions';
import { IRootState } from '../../root.state';
import { IReorderingWorkOrder } from '../types/reordering-work-order';
import { IReorderingWorkOrderItem } from '../types/reordering-work-order-item';
import { ReorderingMapMarkers } from './ReorderingMapMarkers.component';

export const ReorderingMap: FunctionComponent = () => {
  const dispatch = useDispatch();
  const hoveredWorkOrderItem: IReorderingWorkOrderItem | null = useSelector(
    (store: IRootState) => store.reordering.hoveredWorkOrderItem,
  );
  const markerPopup = useSelector((store: IRootState) => store.reordering.markerPopup);
  const workOrder: IReorderingWorkOrder | null = useSelector((store: IRootState) => store.reordering.workOrder);
  const mapLocation: IMapLocation = useSelector((store: IRootState) => store.planning.mapLocation);

  const onSearchStreet = (street: string) => {
    dispatch(searchStreet(street));
  };

  return (
    <BaseMap loading={!workOrder} center={mapLocation.center} zoom={mapLocation.zoom} showLayersControl>
      <ReorderingMapMarkers
        hoveredWorkOrderItem={hoveredWorkOrderItem}
        markerPopup={markerPopup}
        workOrderItems={workOrder?.workOrderItems || []}
      />
      <MapSearchControl searchStreet={onSearchStreet} />
    </BaseMap>
  );
};
