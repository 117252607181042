import { matchPath, useLocation } from 'react-router';
import { appUrls } from '../common/config/url.constants';
import { isInIframe } from '../common/utils/window.util';
import { useEffect } from 'react';
import { AppActions } from '../common';
import { useDispatch } from 'react-redux';

export const useLogin = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const shouldInitApp = !matchPath(location.pathname, {
    path: [appUrls.reset.password(), appUrls.manualPlacement.base()],
    exact: true,
    strict: true,
  });

  const showMainMenu =
    !isInIframe() &&
    location.pathname !== appUrls.login.base &&
    location.pathname !== appUrls.login.selection &&
    location.pathname !== appUrls.login.oauthMprofile &&
    !location.pathname.includes(appUrls.reset.password('')) &&
    !location.pathname.includes(appUrls.manualPlacement.base('')) &&
    !location.pathname.includes(appUrls.attachments.base());

  useEffect(() => {
    if (shouldInitApp) {
      dispatch(AppActions.init());
    } else {
      dispatch(AppActions.setLoading(false));
    }
  }, [dispatch, shouldInitApp]);

  return { showMainMenu };
};
