import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ISortableListItemProps } from '../../../types';

export const SortableItem = <TListItem, TInjectProps>({
  id,
  injectedProps,
  item,
  renderItem,
  isDragged = false,
}: ISortableListItemProps<TListItem, TInjectProps>) => {
  const { setNodeRef, attributes, listeners, transform, transition, activeIndex, index } = useSortable({ id });

  return (
    <div
      ref={setNodeRef}
      key={id}
      {...attributes}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
      }}
    >
      {
        // @ts-ignore
        renderItem({
          ...injectedProps,
          item,
          isActive: index >= 0 && activeIndex === index,
          isDragged,
          listeners,
        })
      }
    </div>
  );
};
