import { combineReducers } from 'redux';
import { generateArrayReducer, generatePrimitiveReducer } from '../../common';
import { IEvent } from '../types/event';
import { IEventType } from '../types/event-type';
import { EventsActions, setEventErrors, setEventsFilterDataLoaded, setEventTypes } from './events.actions';
import { initialEventsState } from './events.state';
import { createPagedTableReducer } from '../../store/reducers/pagedTable.reducer';

export const eventsReducer = combineReducers({
  eventErrors: generatePrimitiveReducer<string[] | null>(setEventErrors.type, null),
  eventTypes: generateArrayReducer<IEventType>(setEventTypes.type, initialEventsState.eventTypes),
  eventsFilterDataLoaded: generatePrimitiveReducer<boolean>(
    setEventsFilterDataLoaded.type,
    initialEventsState.eventsFilterDataLoaded,
  ),
  list: createPagedTableReducer<IEvent>({
    fetchAction: EventsActions.list.fetch.type,
    addEntitiesActions: [EventsActions.list.set.type],
    setAllIdsAction: EventsActions.list.set.type,
    setParamsActions: [EventsActions.list.setParams.type, EventsActions.list.fetch.type],
    initialParamsState: initialEventsState.list.table,
  }),
});
