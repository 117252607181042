import L, { MarkerCluster } from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import { default as React, FunctionComponent } from 'react';
import { Polyline } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { ISignMapLine, ISignMapMarker } from '../types/signs-map';
import { SignMarker } from './SignMarker.component';

interface IProps {
  signsMap: ISignMapMarker[];
  signsMapLines: ISignMapLine[];
  options?: any;
}

export const SignsMapClusterComponent: FunctionComponent<IProps> = (props: IProps) => {
  const iconCreateFunction = (cluster: MarkerCluster) => {
    const count = cluster.getChildCount();
    const className = `marker-cluster marker-cluster-${count < 10 ? 'small' : count < 100 ? 'medium' : 'large'}`;
    return new L.DivIcon({ html: `<div>${count}<span/></div>`, className, iconSize: new L.Point(40, 40) });
  };

  const renderLines = (): JSX.Element[] => {
    return props.signsMapLines.map((request) => {
      const positions = request.points.map((x) => x.location);
      return (
        <>
          <Polyline positions={positions} />
        </>
      );
    });
  };

  return (
    <MarkerClusterGroup
      chunkedLoading={false}
      disableClusteringAtZoom={17}
      iconCreateFunction={iconCreateFunction}
      spiderLegPolylineOptions={{
        opacity: 0,
        weight: 0,
      }}
      spiderfyOnMaxZoom={false}
    >
      {props.signsMap.map((sign) => (
        <SignMarker sign={sign} key={sign.hash} />
      ))}
      {renderLines()}
    </MarkerClusterGroup>
  );
};
