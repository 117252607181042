import React, { FC } from 'react';
import { PanelContent } from '../../../common/components/panel/panel-content.component';
import { translate } from '../../../common/translations/translate';
import { Grid, Paper } from '@material-ui/core';
import { LabelValue } from '../../../common/components/layout/LabelValue.component';
import { useGeneralStyles } from '../../../style/generalStyles';

interface IProps {
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  emailAddress?: string;
}

export const ManualPlacementContactInfo: FC<IProps> = ({ firstName, lastName, phoneNumber, emailAddress }) => {
  const G = useGeneralStyles();
  return (
    <Paper square className={G.marginBottom20}>
      <PanelContent title={translate('manualPlacement.contactInfo')}>
        <Grid container spacing={2}>
          <LabelValue label={translate('manualPlacement.name')}>{`${firstName} ${lastName}`}</LabelValue>
          <LabelValue visible={!!phoneNumber} label={translate('manualPlacement.phoneNumber')}>
            {phoneNumber}
          </LabelValue>
          <LabelValue visible={!!emailAddress} label={translate('manualPlacement.emailAddress')}>
            {emailAddress}
          </LabelValue>
        </Grid>
      </PanelContent>
    </Paper>
  );
};
