import axios from 'axios';
import { CANCEL } from 'redux-saga';
import { CONFIG, IApiPromise, IPagedApiParams, IPaging } from '../../common';
import { SIGN_STATES } from '../signs.constants';
import { ISign } from '../types/sign';
import { ISignCondition } from '../types/sign-condition';
import { ISignData } from '../types/sign-data';
import { ISignDelete } from '../types/sign-delete';
import { ISignLocation } from '../types/sign-location';
import { ISignState } from '../types/sign-state';
import { ISignType } from '../types/sign-type';
import { ISignUpdate } from '../types/sign-update';
import { ISignsForecast } from '../types/signs-forecast';
import { ISignMap, ISignMapPopup, ISignsMapFilter } from '../types/signs-map';

export class SignsApi {
  public static getSign(hash: string): IApiPromise<ISign> {
    return axios.get(`${CONFIG.backOfficeApi}/v1/signs/${hash}`);
  }

  public static fetchList(params: IPagedApiParams): IApiPromise<ISign[]> {
    return axios.get(`${CONFIG.backOfficeApi}/v1/signs`, { params });
  }

  public static updateSign(sign: ISignUpdate): IApiPromise<ISign> {
    const { hash, ...data } = sign;
    return axios.patch(`${CONFIG.backOfficeApi}/v1/signs/${hash}`, data);
  }

  public static deleteSign(deleteSign: ISignDelete): IApiPromise<void> {
    const { hash, withModule } = deleteSign;
    return axios.delete(`${CONFIG.backOfficeApi}/v1/signs/${hash}?withModule=${withModule}`);
  }

  public static bulkDeleteSign(deleteSign: string[]): IApiPromise<void> {
    return axios.post(`${CONFIG.backOfficeApi}/v1/signs/bulkDelete`, {
      signs: deleteSign,
    });
  }

  public static generateRetrieveWorkOrderItem(sign: ISign): IApiPromise<void> {
    const { id, location } = sign;
    return axios.post(`${CONFIG.backOfficeApi}/v1/work_order_items`, {
      location,
      sign: id,
      state: 'open',
      type: 'retrieve',
    });
  }

  public static getSignGPSLogs(hash: string): IApiPromise<ISignData[]> {
    return axios.get(`${CONFIG.backOfficeApi}/v1/signs/${hash}/gpslog`, {
      params: {
        order: 'desc',
        sort: 'placedAt',
      },
    });
  }

  public static getSignLocations(hash: string, paging: IPaging): IApiPromise<ISignLocation[]> {
    return axios.get(`${CONFIG.backOfficeApi}/v1/signs/${hash}/locations`, {
      params: {
        order: 'desc',
        page: paging.page,
        page_size: paging.pageSize,
        sort: 'placedAt',
      },
    });
  }

  public static getLocationData(locationID: number): IApiPromise<ISignData[]> {
    return axios.get(`${CONFIG.backOfficeApi}/v1/sign_locations/${locationID}/data`);
  }

  public static getSignTypes(): IApiPromise<ISignType[]> {
    return axios.get(`${CONFIG.backOfficeApi}/v1/signs/types`);
  }

  public static getSignStates(): IApiPromise<ISignState[]> {
    return axios.get(`${CONFIG.backOfficeApi}/v1/signs/states`);
  }

  public static getSignConditions(): IApiPromise<ISignCondition[]> {
    return axios.get(`${CONFIG.backOfficeApi}/v1/signs/conditions`);
  }

  public static getSignCounter(params: any): IApiPromise<number> {
    return axios.get(`${CONFIG.backOfficeApi}/v1/signs/count`, {
      params,
    });
  }

  public static getSignsForecast(): IApiPromise<ISignsForecast> {
    return axios.get(`${CONFIG.backOfficeApi}/v1/signs/forecast`);
  }

  public static getSignsWoiForecast(): IApiPromise<ISignsForecast> {
    return axios.get(`${CONFIG.backOfficeApi}/v1/signs/woi_forecast`);
  }

  public static getSignsMap(filter: ISignsMapFilter): IApiPromise<ISignMap> {
    const source = axios.CancelToken.source();
    const request: any = axios.get(`${CONFIG.backOfficeApi}/v1/signs/map`, {
      cancelToken: source.token,
      params: {
        lastUpdatedFrom: filter.lastUpdatedFrom,
        lastUpdatedTo: filter.lastUpdatedTo,
        states: filter.state !== ' ' ? [filter.state] : [],
        types: filter.type !== ' ' ? [filter.type] : [],
        showHiddenSigns: filter.showHiddenSigns ? 1 : 0,
      },
    });
    // Cancel the request when the saga is cancelled
    request[CANCEL] = () => source.cancel();
    return request;
  }

  public static getSignsMapPopup(hash: string): IApiPromise<ISignMapPopup> {
    return axios.get(`${CONFIG.backOfficeApi}/v1/signs/map/${hash}`);
  }

  public static markSignAsMissing(hash: string): IApiPromise<ISign> {
    return axios.patch(`${CONFIG.backOfficeApi}/v1/signs/${hash}`, { state: SIGN_STATES.MISSING });
  }
}
