import * as React from 'react';
import { CircularProgress } from '@material-ui/core';
import { FunctionComponent } from 'react';

export const MapLoader: FunctionComponent = () => (
  <div
    data-testid="MapLoader"
    style={{
      backgroundColor: 'rgba(255, 255, 255, .5)',
      bottom: 0,
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: 1000,
    }}
  >
    <CircularProgress
      data-testid="CircularProgress"
      style={{
        position: 'absolute',
        right: '50%',
        top: '50%',
      }}
    />
  </div>
);
