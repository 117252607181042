import { Tooltip } from '@material-ui/core';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { ConflictCategory, IWithClassName, IWithStyle, SgwPartnerType } from '../../../types';
import {
  ASSIGN_USER,
  ASSIGN_USER_INVERTED,
  CONFLICTS,
  COPY_ICON,
  GOOGLE_MAPS,
  HANDLE_INTERNALLY,
  HISTORY,
  INFO,
  LOGOUT,
  PARTNER_CLIENT,
  PARTNER_CONTRACTOR,
  PARTNER_FEE,
  PARTNER_REQUESTOR,
  PARTNER_SIGNALISATION,
  RESET_PINCODE,
  UNASSIGN_USER,
  CONFLICT_BENCH,
  CONFLICT_BUS,
  CONFLICT_CALENDER,
  CONFLICT_CHANGE,
  CONFLICT_CRANE,
  CONFLICT_PARKING,
  CONFLICT_PORT,
  CONFLICT_ROAD_CLOSURE,
  CONFLICT_TRAIN,
  CONFLICT_TREE,
  CONFLICT_PROHIBITED,
  CONFLICT_WASTE,
  CONFLICT_EMAIL,
  CONFLICT_FLAG,
  FEEDBACK_GIVEN,
  EMAIL,
  DOWNLOAD,
  MAP_MARKER,
  CONFLICT_DETOUR,
  CONFLICT_GENERIC,
} from '../../icons';
import { translate, translateIgnoreTS } from '../../translations/translate';

interface IProps extends IWithClassName, IWithStyle, FunctionComponent<React.ImgHTMLAttributes<HTMLElement>> {
  alt?: string;
  variant?: 'normal' | 'large';
}

interface IPartnerProps extends Partial<IProps> {
  partnerType: SgwPartnerType;
}
interface IConflictProps extends IWithClassName {
  variant: ConflictCategory;
  alt: string;
}

const partnerIcons = {
  [SgwPartnerType.client]: PARTNER_CLIENT,
  [SgwPartnerType.contractor]: PARTNER_CONTRACTOR,
  [SgwPartnerType.fee]: PARTNER_FEE,
  [SgwPartnerType.requester]: PARTNER_REQUESTOR,
  [SgwPartnerType.signalisation]: PARTNER_SIGNALISATION,
};
const conflictIcons = {
  [ConflictCategory.crane]: CONFLICT_BENCH,
  [ConflictCategory.crane]: CONFLICT_CRANE,
  [ConflictCategory.constructionZone]: CONFLICT_ROAD_CLOSURE,
  [ConflictCategory.parkingBan]: CONFLICT_PROHIBITED,
  [ConflictCategory.event]: CONFLICT_CALENDER,
  [ConflictCategory.publicTransport]: CONFLICT_BUS,
  [ConflictCategory.cityCleaning]: CONFLICT_WASTE,
  [ConflictCategory.dedicatedParkingCambio]: CONFLICT_PARKING,
  [ConflictCategory.dedicatedParkingCarSharing]: CONFLICT_PARKING,
  [ConflictCategory.dedicatedParkingChargingStation]: CONFLICT_PARKING,
  [ConflictCategory.dedicatedParkingDisabledPerson]: CONFLICT_PARKING,
  [ConflictCategory.dedicatedParkingTaxi]: CONFLICT_PARKING,
  [ConflictCategory.greenZone]: CONFLICT_TREE,
  [ConflictCategory.mobility]: CONFLICT_CHANGE,
  [ConflictCategory.portZone]: CONFLICT_PORT,
  [ConflictCategory.railway]: CONFLICT_TRAIN,
  [ConflictCategory.streetInfrastructure]: CONFLICT_BENCH,
  [ConflictCategory.detour]: CONFLICT_DETOUR,
  [ConflictCategory.fireDepartment]: CONFLICT_GENERIC,
  [ConflictCategory.utilityCompanies]: CONFLICT_GENERIC,
  [ConflictCategory.shippingPolice]: CONFLICT_GENERIC,
  [ConflictCategory.cityHarbour]: CONFLICT_GENERIC,
  [ConflictCategory.trafficPolice]: CONFLICT_GENERIC,
  [ConflictCategory.projectSites]: CONFLICT_GENERIC,
  [ConflictCategory.tunnel]: CONFLICT_GENERIC,
  [ConflictCategory.markets]: CONFLICT_GENERIC,
  [ConflictCategory.trafficSignaling]: CONFLICT_GENERIC,
  [ConflictCategory.brabantsTunnel]: CONFLICT_GENERIC,
  [ConflictCategory.neighborhoodParking]: CONFLICT_GENERIC,
  [ConflictCategory.zoo]: CONFLICT_GENERIC,
  [ConflictCategory.retailCatering]: CONFLICT_GENERIC,
  [ConflictCategory.prison]: CONFLICT_GENERIC,
  [ConflictCategory.railwayInfrastructure]: CONFLICT_GENERIC,
  [ConflictCategory.artWork]: CONFLICT_GENERIC,
  [ConflictCategory.loadingZone]: CONFLICT_GENERIC,
  [ConflictCategory.airport]: CONFLICT_GENERIC,
  [ConflictCategory.oosterweelZone]: CONFLICT_GENERIC,
  [ConflictCategory.parkingMachine]: CONFLICT_GENERIC,
  [ConflictCategory.highway]: CONFLICT_GENERIC,
  [ConflictCategory.diamantwijk]: CONFLICT_GENERIC,
  [ConflictCategory.publicOrder]: CONFLICT_GENERIC,
  [ConflictCategory.football]: CONFLICT_GENERIC,
  [ConflictCategory.railroadPolice]: CONFLICT_GENERIC,
  [ConflictCategory.velo]: CONFLICT_GENERIC,
  [ConflictCategory.streetLightning]: CONFLICT_GENERIC,
  [ConflictCategory.terrace]: CONFLICT_GENERIC,
  [ConflictCategory.temporaryTrafficLights]: CONFLICT_GENERIC,
  [ConflictCategory.garbageCans]: CONFLICT_GENERIC,
};

const IconComp: IProps = (props, variant = 'normal') => {
  const height = variant === 'normal' ? '15px' : '20px';

  return <img alt={props.alt || ''} style={{ height }} {...props} />;
};

export const Icon = {
  AssignUser: (props: Partial<IProps>) => (
    <IconComp src={ASSIGN_USER} alt={props.alt || translate('icons.alt.assignUser')} {...props} />
  ),
  AssignUserInverted: (props: Partial<IProps>) => (
    <IconComp src={ASSIGN_USER_INVERTED} alt={props.alt || translate('icons.alt.assignUser')} {...props} />
  ),
  CopyRequest: (props: Partial<IProps>) => (
    <IconComp src={COPY_ICON} alt={props.alt || translate('icons.alt.copyRequest')} {...props} />
  ),
  GoogleMaps: (props: Partial<IProps>) => (
    <IconComp src={GOOGLE_MAPS} alt={props.alt || translate('icons.alt.googleMaps')} {...props} />
  ),
  HandleInternally: (props: Partial<IProps>) => (
    <IconComp
      src={HANDLE_INTERNALLY}
      alt={props.alt || translate('icons.alt.handleInternally')}
      {...props}
      style={{ width: '23px' }}
    />
  ),
  History: (props: Partial<IProps>) => (
    <IconComp src={HISTORY} alt={props.alt || translate('icons.alt.history')} {...props} />
  ),
  Logout: (props: Partial<IProps>) => (
    <IconComp src={LOGOUT} alt={props.alt || translate('icons.alt.logout')} {...props} />
  ),
  ResetPincode: (props: Partial<IProps>) => (
    <IconComp src={RESET_PINCODE} alt={props.alt || translate('icons.alt.resetPincode')} {...props} />
  ),
  UnassignUser: (props: Partial<IProps>) => (
    <IconComp src={UNASSIGN_USER} alt={props.alt || translate('icons.alt.unassignUser')} {...props} />
  ),
  Conflicts: (props: Partial<IProps>) => (
    <Tooltip title={translate('sgw.requests.dashboard.table.conflicts')}>
      <span>
        <IconComp src={CONFLICTS} alt={props.alt || translate('icons.alt.conflicts')} {...props} />
      </span>
    </Tooltip>
  ),
  FeedbackGiven: (props: Partial<IProps>) => (
    <Tooltip title={translate('sgw.requests.dashboard.table.feedbackGiven')}>
      <span>
        <IconComp src={FEEDBACK_GIVEN} alt={props.alt || translate('icons.alt.feedbackGiven')} {...props} />
      </span>
    </Tooltip>
  ),
  Info: (props: Partial<IProps>) => <IconComp src={INFO} alt={props.alt || translate('icons.alt.info')} {...props} />,
  Partner: ({ alt, partnerType, ...props }: IPartnerProps) => (
    <Tooltip title={translateIgnoreTS(`sgw.partners.types.${partnerType}`)}>
      <span>
        <IconComp
          src={partnerIcons[partnerType]}
          alt={alt || translateIgnoreTS(`sgw.partners.types.${partnerType}`)}
          {...props}
        />
      </span>
    </Tooltip>
  ),
  ConflictIcons: ({ variant, alt, ...props }: IConflictProps) => (
    <img src={conflictIcons[variant]} alt={alt} {...props} />
  ),
  ConflictFlag: ({ variant, ...props }: Partial<IConflictProps>) => (
    <img src={CONFLICT_FLAG} alt={props.alt || translate('assets.images.conflictFlag')} {...props} />
  ),
  MapMarker: ({ alt, ...props }: Partial<IProps>) => <img src={MAP_MARKER} alt={alt} {...props} />,

  SendEmail: (props: Partial<IProps>) => (
    <img src={CONFLICT_EMAIL} alt={props.alt || translate('assets.images.conflicts.sendEmail')} {...props} />
  ),
  Email: (props: Partial<IProps>) => (
    <IconComp src={EMAIL} alt={props.alt || translate('assets.images.email')} {...props} />
  ),
  Download: (props: Partial<IProps>) => (
    <IconComp src={DOWNLOAD} alt={props.alt || translate('assets.images.download')} {...props} />
  ),
};
