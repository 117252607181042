import { createAction } from '@reduxjs/toolkit';
import { IPartialPagedTable } from '../../types/paging';

export type TBaseAction = {
  type: string;
};

export type TPayloadAction<TPayload> = TBaseAction & {
  payload: TPayload;
};

export type TBaseActionCreator = {
  (): TBaseAction;
  type: string;
};

export type TPayloadActionCreator<TPayload> = {
  (payload: TPayload): TPayloadAction<TPayload>;
  type: string;
};

export function generateBaseActionCreator(type: string): TBaseActionCreator {
  const actionCreator: any = () => ({ type });
  actionCreator.type = type;
  return actionCreator;
}

export function generatePayloadActionCreator<TPayload>(type: string): TPayloadActionCreator<TPayload> {
  const actionCreator: any = (payload: TPayload) => ({ payload, type });
  actionCreator.type = type;
  return actionCreator;
}

export class Actions {
  prefix: string;

  constructor(prefix: string) {
    this.prefix = prefix;
  }

  createAction(actionType: string) {
    return createAction<undefined>(`${this.prefix}.${actionType}`);
  }

  createPayloadAction<TPayload>(actionType: string) {
    return createAction<TPayload>(`${this.prefix}.${actionType}`);
  }
}

export class TableActions<IRecord, IFilter = {}> extends Actions {
  fetch = this.createPayloadAction<IPartialPagedTable<IFilter>>('fetch');
  set = this.createPayloadAction<IRecord[]>('setList');
  setParams = this.createPayloadAction<IPartialPagedTable<IFilter>>('setListParams');
  setLoading = this.createPayloadAction<boolean>('setLoading');
}

export class SyncActionsClass extends Actions {
  sync = this.createAction('sync');
  isSyncing = this.createPayloadAction<boolean>('isSyncing');
}
