import { combineReducers } from 'redux';
import { natureOfWorksReducer } from './natureOfWorks.reducer';
import { partnersReducer } from './partners.reducer';
import { projectsReducer } from './projects.reducer';
import { requestsReducer } from './requests.reducer';
import { settingsReducer } from './settings.reducer';
import { advisingPartiesReducer } from './advisingParties.reducer';
import { trafficMeasuresReducer } from './trafficMeasures.reducer';
import { permitConditionsReducer } from './permitConditions.reducer';

export const sgwReducer = combineReducers({
  advisingParties: advisingPartiesReducer,
  natureOfWorks: natureOfWorksReducer,
  partners: partnersReducer,
  permitConditions: permitConditionsReducer,
  projects: projectsReducer,
  requests: requestsReducer,
  settings: settingsReducer,
  trafficMeasures: trafficMeasuresReducer,
});
