import React, { FC } from 'react';
import { ExpansionPanel } from '@material-ui/core';
import { ExpansionPanelProps } from '@material-ui/core/ExpansionPanel/ExpansionPanel';
import { useAtom, WritableAtom } from 'jotai';

interface IProps extends ExpansionPanelProps {
  isExpanded: WritableAtom<any, any>;
}

export const JotaiExpansionPanel: FC<IProps> = ({ isExpanded, children, ...expansionPanelProps }) => {
  const [isOpen, setIsOpen] = useAtom(isExpanded);

  const _onChange = (event: React.ChangeEvent<{}>, expanded: boolean) => {
    setIsOpen(expanded);
    expansionPanelProps.onChange?.(event, expanded);
  };

  return (
    <ExpansionPanel onChange={_onChange} {...expansionPanelProps} defaultExpanded={isOpen}>
      {children}
    </ExpansionPanel>
  );
};
