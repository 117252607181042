import { ISgwRequestAttachment } from '../../../types';
import React, { FC } from 'react';
import { SgwRequestActions } from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getQuartersInRequestOptions } from '../../../store/selectors/sgwRequest.selectors';
import { translate } from '../../../common/translations/translate';
import { useGeneralStyles } from '../../../style/generalStyles';
import { SingleSelectV2Component } from '../../../common/components/form-fields/single-select-v2.component';
import { Visible } from '../../../common/components/layout/Visible.component';
import { parseQuarter, stringBeQuarter } from '../../../common/utils/quarter.util';

interface IProps {
  attachment: ISgwRequestAttachment;
  editMode: boolean;
  requestId: string;
}

export const RequestAttachmentPhaseQuarterSelector: FC<IProps> = ({ attachment, editMode, requestId }) => {
  const dispatch = useDispatch();
  const { textLength20 } = useGeneralStyles();

  const quarterOptions = useSelector(getQuartersInRequestOptions());

  const onChange = ({ target }: React.ChangeEvent<any>) => {
    dispatch(
      SgwRequestActions.attachments.save({
        requestId,
        attachment: {
          ...parseQuarter(target.value),
          id: attachment.id,
        },
      }),
    );
  };

  return editMode ? (
    <SingleSelectV2Component value={stringBeQuarter(attachment)} onChange={onChange} menuItems={quarterOptions} />
  ) : (
    <Visible
      visible={!!attachment.quarterYear && !!attachment.quarterIndex}
      defaultComponent={<div>{translate('sgw.requests.detail.attachments.table.noQuarter')}</div>}
    >
      <div className={textLength20}>{`${attachment.quarterYear} ${translate(
        'sgw.requests.detail.attachments.table.quarter',
      )} ${attachment.quarterIndex}`}</div>
    </Visible>
  );
};
