import React, { FC } from 'react';
import { Container } from '../../../../../../common';
import { Header } from '../../../../../../common/components/layout/Header.component';
import { appUrls } from '../../../../../../common/config/url.constants';
import { translate } from '../../../../../../common/translations/translate';
import { ISgwProjectAclActions } from '../../../../../../types';
import { Auth } from '../../../../../atoms';
import { ProjectForm } from '../../../../../forms';
import { useFormStyles } from '../../../../../../style/form.styles';

export const CreateProjectPage: FC = () => (
  <Auth acl={ISgwProjectAclActions.createSgwProject} showUnauthorizedPage>
    <Header backButtonUrl={appUrls.sgw.manage.projects.base}>{translate('sgw.projects.newProject')}</Header>
    <Container className={useFormStyles().container}>
      <ProjectForm title={translate('sgw.projects.newProjectDetails')} />
    </Container>
  </Auth>
);
