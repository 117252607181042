import { createSelector } from '@reduxjs/toolkit';
import { IRootState } from '../../root.state';

export const selectPlanningWorkOrders = (store: IRootState) => store.planning.workOrders;
export const selectPlanningWorkOrderItems = (store: IRootState) => store.planning.workOrderItems;
export const selectWorkOrderItemsFilter = (store: IRootState) => store.planning.workOrderItemsFilter;
export const selectMapLocation = (store: IRootState) => store.planning.mapLocation;
export const selectMarkerPopup = (store: IRootState) => store.planning.markerPopup;
export const selectPlanningWorkOrderItemsLoading = (store: IRootState) => store.planning.workOrderItemsLoading;
export const selectPlanningWorkOrdersLoading = (store: IRootState) => store.planning.workOrdersLoading;
export const selectTeams = (store: IRootState) => store.planning.teams;

export const getPlanningWorkOrderItemsIds = createSelector([selectPlanningWorkOrderItems], (items) =>
  items.map((item) => item.id),
);

export const getPlanningWorkOrders = createSelector(
  [selectPlanningWorkOrders, getPlanningWorkOrderItemsIds],
  (orders, itemsIds) =>
    orders.map((order) => ({
      ...order,
      workOrderItems: order.workOrderItems
        ? order.workOrderItems.map((item) => ({
            ...item,
            isShownOnMap: itemsIds.includes(item.id),
          }))
        : [],
    })),
);

export const getTeamsSelectItems = createSelector([selectTeams], (teams) => {
  const teamValues = teams.map((team) => ({ value: team.id as number | ' ', label: team.name }));
  teamValues.unshift({ value: ' ', label: 'Alle' });
  return teamValues;
});
