import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Container, Panel, PanelAction } from '../../../../../../common';
import { Header } from '../../../../../../common/components/layout/Header.component';
import { appUrls } from '../../../../../../common/config/url.constants';
import { IRouterWithId, ISgwTrafficMeasureAclActions } from '../../../../../../types';
import { Auth } from '../../../../../atoms';
import { Grid } from '@material-ui/core';
import { LabelValue } from '../../../../../../common/components/layout/LabelValue.component';
import { Edit } from '@material-ui/icons';
import { translate } from '../../../../../../common/translations/translate';
import { Loader } from '../../../../../../common/components/layout/loader.component';
import { getSgwTrafficMeasure } from '../../../../../../store/selectors/sgwTrafficMeasures.selectors';
import { SgwTrafficMeasuresActions } from '../../../../../../store/actions';
import { useFormStyles } from '../../../../../../style/form.styles';

export const DetailTrafficMeasurePage: FC = () => {
  const dispatch = useDispatch();
  const C = useFormStyles();
  const { id } = useParams<IRouterWithId>();
  const trafficMeasure = useSelector(getSgwTrafficMeasure(id));
  const history = useHistory();

  useEffect(() => {
    dispatch(SgwTrafficMeasuresActions.list.fetch({}));
  }, [dispatch]);

  const onClick = () => history.push(appUrls.sgw.manage.trafficMeasures.edit(id));

  if (!trafficMeasure) {
    return <Loader />;
  }

  const { name, description, deleted, gipodGuid, existsInGipod } = trafficMeasure;

  return (
    <Auth acl={ISgwTrafficMeasureAclActions.viewSgwTrafficMeasureDetail} showUnauthorizedPage>
      <Header backButtonUrl={appUrls.sgw.manage.trafficMeasures.base}>
        {translate('sgw.trafficMeasures.trafficMeasureName', { trafficMeasure: name })}
      </Header>
      <Container className={C.container}>
        <Panel title={translate('sgw.trafficMeasures.detail')}>
          <Auth acl={ISgwTrafficMeasureAclActions.editSgwTrafficMeasure}>
            <PanelAction icon={<Edit />} onClick={onClick} data-testid="ToggleButton" />
          </Auth>
          <Grid container spacing={2} className={C.grid}>
            <LabelValue label={translate('sgw.trafficMeasures.columns.name')}>{name}</LabelValue>
            <LabelValue label={translate('sgw.trafficMeasures.columns.description')}>{description}</LabelValue>
            <LabelValue label={translate('sgw.trafficMeasures.columns.gipodGuid')}>{gipodGuid}</LabelValue>
            <LabelValue label={translate('sgw.trafficMeasures.columns.existInGipod')}>
              {translate(existsInGipod ? 'yes' : 'no')}
            </LabelValue>
            <LabelValue label={translate('sgw.trafficMeasures.detailActive')}>
              {translate(deleted ? 'no' : 'yes')}
            </LabelValue>
          </Grid>
        </Panel>
      </Container>
    </Auth>
  );
};
