import { makeStyles } from '@material-ui/core/styles';
import React, { ChangeEvent, FunctionComponent, PropsWithChildren, useCallback, useState } from 'react';
import {
  Autorenew,
  Close,
  Delete,
  DeleteOutlined,
  LowPriority,
  RateReviewOutlined,
  Refresh,
  ThumbDown,
  ThumbUp,
} from '@material-ui/icons';
import { IconButton, TextField, Tooltip } from '@material-ui/core';
import { COLORS } from '../..';
import { useGeneralStyles } from '../../../style/generalStyles';
import { Icon } from '../layout/icon.component';
import AsignButton from './asign-button';
import ModalDialog from '../modal-dialog/modal-dialog.component';
import { Button } from './asign-button-extensions';
import { IAsignButtonProps } from '../../../types';
import SendIcon from '@material-ui/icons/Send';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import { Visible } from '../layout/Visible.component';
import { translate } from '../../translations/translate';
import { SyncButton } from './SyncButton.component';

const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '15px',
  },
  textarea: {
    width: '100%',
  },
  warning: {
    borderColor: COLORS.DANGER,
    borderRadius: '5px',
    borderStyle: 'solid',
    borderWidth: 1,
    color: COLORS.DANGER,
    padding: '5px',
  },
  cancel: {
    marginRight: '10px',
  },
});

const styles = {
  icon: {
    marginLeft: '5px',
    width: '15px',
  },
};

interface IModalButtonBaseProps {
  disabled?: boolean;
  shouldShowModal?: boolean;
  onConfirm: (reason: string | null) => void;
  tooltip?: string;
  visible?: boolean;
  title: string;
  buttonTitle: string;
  modalBody?: React.ReactNode;
  needsReason?: boolean;
  reasonText?: string;
  confirmButton: React.ReactNode;
  loading?: boolean;
}

const ModalButtonBase: FunctionComponent<PropsWithChildren<IModalButtonBaseProps>> = ({
  children,
  shouldShowModal = true,
  onConfirm,
  confirmButton,
  visible,
  title,
  modalBody,
  reasonText,
  needsReason = true,
  disabled = false,
}) => {
  const C = useStyles();

  const [modalVisible, setModalVisible] = useState(false);
  const [reason, setReason] = useState<string | null>(null);

  const hideModal = () => setModalVisible(false);
  const showModal = useCallback(() => !disabled && setModalVisible(true), [disabled]);

  const _onClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      shouldShowModal
        ? showModal()
        : // @ts-ignore - Typescript doesn't notice that this should be an empty function
          onConfirm();
    },
    [showModal, shouldShowModal, onConfirm],
  );

  const _onConfirm = useCallback(() => {
    hideModal();
    onConfirm(reason);
  }, [onConfirm, reason]);

  const onChangeReason = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setReason(event.target.value || null);
  };

  return (
    <Visible visible={!!visible}>
      <span onClick={_onClick}>{children}</span>
      <ModalDialog
        onClose={hideModal}
        onConfirm={onConfirm}
        title={title}
        visible={modalVisible}
        buttons={
          <>
            <Button.Gray className={C.cancel} color="default" data-testid="modalCancelButton" onClick={hideModal}>
              {translate('refuse')} <Close />
            </Button.Gray>
            <span onClick={_onConfirm}>{confirmButton}</span>
          </>
        }
      >
        {modalBody}
        {needsReason && (
          <TextField
            id="textarea"
            label={reasonText}
            margin="normal"
            multiline
            onChange={onChangeReason}
            className={C.textarea}
          />
        )}
      </ModalDialog>
    </Visible>
  );
};

export const ModalButton = {
  Approve: (props: Omit<IModalButtonBaseProps, 'confirmButton'> & IAsignButtonProps) => (
    <ModalButtonBase
      {...props}
      reasonText={props.reasonText || translate('ModalButton.approvalReason')}
      confirmButton={
        <AsignButton data-testid="modalConfirmButton" color="secondary" style={{ margin: '5px' }}>
          <span>{props.title}</span>
          <ThumbUp style={styles.icon} />
        </AsignButton>
      }
    >
      <AsignButton color="secondary" style={{ margin: '5px', ...props.style }} {...props}>
        <span>{props.buttonTitle}</span>
        <ThumbUp style={styles.icon} />
      </AsignButton>
    </ModalButtonBase>
  ),
  Reject: (props: Omit<IModalButtonBaseProps, 'confirmButton'> & IAsignButtonProps) => (
    <ModalButtonBase
      {...props}
      reasonText={props.reasonText || translate('ModalButton.rejectionReason')}
      confirmButton={
        <Button.Red data-testid="modalConfirmButton" style={{ margin: '5x' }}>
          <span>{props.buttonTitle}</span>
          <ThumbDown style={styles.icon} />
        </Button.Red>
      }
    >
      <Button.Red style={{ margin: '5px', ...props.style }} {...props}>
        <span>{props.buttonTitle}</span>
        <ThumbDown style={styles.icon} />
      </Button.Red>
    </ModalButtonBase>
  ),
  Review: (props: Omit<IModalButtonBaseProps, 'confirmButton'> & IAsignButtonProps) => (
    <ModalButtonBase
      {...props}
      confirmButton={
        <AsignButton data-testid="modalConfirmButton" style={{ margin: '5px' }}>
          <span>{props.title}</span>
          <RateReviewOutlined style={styles.icon} />
        </AsignButton>
      }
    >
      <AsignButton color="default" style={{ margin: '5px', ...props.style }} {...props}>
        <span>{props.buttonTitle}</span>
        <RateReviewOutlined style={styles.icon} />
      </AsignButton>
    </ModalButtonBase>
  ),
  Unassign: (props: Omit<IModalButtonBaseProps, 'confirmButton'> & IAsignButtonProps) => (
    <ModalButtonBase
      {...props}
      confirmButton={
        <Button.White data-testid="modalConfirmButton" style={{ margin: '5px' }}>
          <span>{props.title}</span>
          <Icon.UnassignUser style={{ width: '10px', marginLeft: '5px' }} />
        </Button.White>
      }
    >
      <Button.White data-testid="unassignButton" iconButton style={{ margin: '5px', ...props.style }} {...props}>
        <Icon.UnassignUser style={{ width: '10px' }} />
      </Button.White>
    </ModalButtonBase>
  ),
  HandleInternally: (props: Omit<IModalButtonBaseProps, 'confirmButton'> & IAsignButtonProps) => (
    <ModalButtonBase
      {...props}
      confirmButton={
        <Button.White data-testid="modalConfirmButton" style={{ margin: '5px' }}>
          <span>{props.buttonTitle}</span>
          <Icon.HandleInternally />
        </Button.White>
      }
    >
      <Button.White style={{ margin: '5px', ...props.style }} {...props}>
        <span>{props.buttonTitle}</span>
        <Icon.HandleInternally />
      </Button.White>
    </ModalButtonBase>
  ),
  ToGMP: (props: Omit<IModalButtonBaseProps, 'confirmButton'> & IAsignButtonProps) => (
    <ModalButtonBase
      {...props}
      confirmButton={
        <Button.White data-testid="modalConfirmButton" style={{ margin: '5px' }}>
          <span>{props.buttonTitle}</span>
          <SendIcon style={styles.icon} />
        </Button.White>
      }
    >
      <Button.White style={{ margin: '5px', ...props.style }} {...props}>
        <span>{props.buttonTitle}</span>
        <SendIcon style={styles.icon} />
      </Button.White>
    </ModalButtonBase>
  ),
  MarkAsMissing: (props: Omit<IModalButtonBaseProps, 'confirmButton'> & IAsignButtonProps) => (
    <ModalButtonBase
      {...props}
      confirmButton={
        <Button.Red data-testid="modalConfirmButton" style={{ margin: '5px' }}>
          <NotListedLocationIcon style={{ width: '20px', marginRight: '5px' }} />
          <span>{props.buttonTitle}</span>
        </Button.Red>
      }
    >
      <Button.Red style={{ margin: '5px', ...props.style }} {...props}>
        <NotListedLocationIcon style={{ width: '20px', marginRight: '5px' }} />
        <span>{props.buttonTitle}</span>
      </Button.Red>
    </ModalButtonBase>
  ),
  Close: (props: Omit<IModalButtonBaseProps, 'confirmButton'> & IAsignButtonProps) => (
    <ModalButtonBase
      {...props}
      confirmButton={
        <Button.Red data-testid="modalConfirmButton" style={{ margin: '5px' }}>
          <span>{props.buttonTitle}</span>
          <Delete style={styles.icon} />
        </Button.Red>
      }
    >
      <Close fontSize={'small'} className={useGeneralStyles().clickable} />
    </ModalButtonBase>
  ),
  Deactivate: (props: Omit<IModalButtonBaseProps, 'confirmButton'> & IAsignButtonProps) => (
    <ModalButtonBase
      {...props}
      confirmButton={
        <Button.Red data-testid="modalConfirmButton" style={{ margin: '5px' }} endIcon={<Delete />}>
          <span>{props.buttonTitle}</span>
        </Button.Red>
      }
    >
      <Button.Red endIcon={<Delete />}>
        <span>{props.buttonTitle}</span>
      </Button.Red>
    </ModalButtonBase>
  ),
  Activate: (props: Omit<IModalButtonBaseProps, 'confirmButton'> & IAsignButtonProps) => (
    <ModalButtonBase
      {...props}
      confirmButton={
        <Button.Blue data-testid="modalConfirmButton" style={{ margin: '5px' }} endIcon={<Refresh />}>
          <span>{props.buttonTitle}</span>
        </Button.Blue>
      }
    >
      <Button.Blue endIcon={<Refresh />}>
        <span>{props.buttonTitle}</span>
      </Button.Blue>
    </ModalButtonBase>
  ),
  ResetPassword: (props: Omit<IModalButtonBaseProps, 'confirmButton'> & IAsignButtonProps) => (
    <ModalButtonBase
      {...props}
      confirmButton={
        <Button.Green
          data-testid="modalConfirmButton"
          style={{ margin: '5px', ...props.style }}
          endIcon={<Autorenew />}
        >
          <span>{props.buttonTitle}</span>
        </Button.Green>
      }
    >
      <Button.Green endIcon={<Autorenew />}>
        <span>{props.buttonTitle}</span>
      </Button.Green>
    </ModalButtonBase>
  ),
  SyncConflicts: (props: Omit<IModalButtonBaseProps, 'confirmButton'> & IAsignButtonProps) => (
    <ModalButtonBase
      {...props}
      confirmButton={
        <Button.Green
          data-testid="modalConfirmButton"
          style={{ margin: '5px', ...props.style }}
          endIcon={<Autorenew />}
        >
          <span>{props.buttonTitle}</span>
        </Button.Green>
      }
    >
      <SyncButton isSyncing={!!props.loading} disabled={props.disabled} data-testid="SyncConflictsButton">
        <span>{props.buttonTitle}</span>
      </SyncButton>
    </ModalButtonBase>
  ),
  DeleteGeoDrawing: (props: Omit<IModalButtonBaseProps, 'confirmButton'> & IAsignButtonProps) => (
    <ModalButtonBase
      {...props}
      confirmButton={
        <Button.Red
          data-testid="modalConfirmButton"
          style={{ margin: '5px', ...props.style }}
          endIcon={<DeleteOutlined />}
        >
          <span>{props.buttonTitle}</span>
        </Button.Red>
      }
    >
      <Tooltip title={translate('sgw.requests.detail.map.buttons.delete')}>
        <IconButton data-testid={'GeoDrawingDelete'}>
          <DeleteOutlined />
        </IconButton>
      </Tooltip>
    </ModalButtonBase>
  ),
  DeletePhase: (props: Omit<IModalButtonBaseProps, 'confirmButton'> & IAsignButtonProps) => (
    <ModalButtonBase
      {...props}
      confirmButton={
        <Button.Red style={{ margin: '5px', ...props.style }} endIcon={<DeleteOutlined />}>
          <span>{props.buttonTitle}</span>
        </Button.Red>
      }
    >
      <IconButton data-testid={'GeoDrawingDelete'}>
        <DeleteOutlined />
      </IconButton>
    </ModalButtonBase>
  ),
  CopyAttachment: (props: Omit<IModalButtonBaseProps, 'confirmButton'> & IAsignButtonProps) => (
    <ModalButtonBase
      {...props}
      confirmButton={
        <Button.Blue style={{ margin: '5px' }} endIcon={<LowPriority />}>
          <span>{props.buttonTitle}</span>
        </Button.Blue>
      }
    >
      <Tooltip title={translate('sgw.requests.detail.attachments.add')}>
        <IconButton>
          <LowPriority />
        </IconButton>
      </Tooltip>
    </ModalButtonBase>
  ),
};
