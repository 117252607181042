import { Fab } from '@material-ui/core';
import { FabProps } from '@material-ui/core/Fab';
import * as React from 'react';
import { FC, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

interface IProps extends Omit<FabProps, 'children'> {
  icon: React.ReactNode;
  hasTitle?: boolean;
}

const useStyles = createUseStyles({
  fab: {
    position: 'absolute',
    right: '0',
    top: '0',
  },
  hasTitle: {
    top: -46,
  },
  root: {
    position: 'relative',
  },
});

export const PanelAction: FC<IProps> = ({ icon, hasTitle = true, ...props }) => {
  const C = useStyles();

  return (
    <div className={C.root}>
      <Fab
        data-testid="PanelAction"
        className={classNames(C.fab, hasTitle ? C.hasTitle : undefined)}
        size="small"
        color="primary"
        {...props}
      >
        {icon as NonNullable<ReactNode> & ReactNode}
      </Fab>
    </div>
  );
};
