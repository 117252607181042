import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Save } from '@material-ui/icons';
import moment from 'moment';
import * as React from 'react';
import { ChangeEvent, FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { SingleSelectV2Component } from '../../../common/components/form-fields/single-select-v2.component';
import { LabelValue } from '../../../common/components/layout/LabelValue.component';
import { Visible } from '../../../common/components/layout/Visible.component';
import { PanelContent } from '../../../common/components/panel/panel-content.component';
import {
  getCarFreeZonesMenuItems,
  getGatesByCarFreeZoneId,
} from '../../../common/store/car-free-zones/carFreeZones.selectors';
import { getIsAuthorized } from '../../../common/store/user/user.selectors';
import { translate } from '../../../common/translations/translate';
import { getIsRequestEditable } from '../../../store/selectors';
import {
  DateFormat,
  GateSas,
  IntakeTypes,
  IPublicDomainIntakeExtended,
  IRequestAclActions,
  IRequestLocationPatch,
} from '../../../types';
import { EditButton } from './edit-button.component';

interface IProps {
  location?: IPublicDomainIntakeExtended;
  onPatchLocationInfo: (info: IRequestLocationPatch) => void;
  requestId: string;
}

const useStyles = makeStyles({
  grid: { marginTop: '20px', marginBottom: '20px' },
  title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  editStreetNumbers: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  streetNumber: {
    width: '40px',
    margin: '5px',
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

export const LocationInfo: FunctionComponent<IProps> = ({ location, onPatchLocationInfo, requestId }) => {
  const C = useStyles();

  const [editMode, setEditMode] = useState<boolean>(false);

  const [newNumberOfSigns, setNewNumberOfSigns] = useState<string>('');
  const [newNumberOfZoneSigns, setNewNumberOfZoneSigns] = useState<string>('');
  const [newStreetNumberFrom, setNewStreetNumberFrom] = useState<string>('');
  const [newStreetNumberTo, setNewStreetNumberTo] = useState<string>('');
  const [carFreeZoneId, setCarFreeZoneId] = useState<number | undefined>();
  const canEditAssignedRequest = useSelector(getIsAuthorized([IRequestAclActions.editAssignedRequest]));
  const [carFreeZoneGateEntrance, setCarFreeZoneGateEntrance] = useState<number | null>(
    location?.carFreeZoneGateEntrance?.id || null,
  );
  const [carFreeZoneGateExit, setCarFreeZoneGateExit] = useState<number | null>(
    location?.carFreeZoneGateExit?.id || null,
  );

  const isEditable = useSelector(getIsRequestEditable(requestId));
  const carFreeZones = useSelector(getCarFreeZonesMenuItems(location?.carFreeZone?.id));
  const entrances = useSelector(getGatesByCarFreeZoneId(`${carFreeZoneId}`, GateSas.in));
  const exits = useSelector(getGatesByCarFreeZoneId(`${carFreeZoneId}`, GateSas.out));

  const initialiseEditableFields = useCallback(() => {
    setNewNumberOfSigns(`${location?.numberOfSigns || 0}`);
    setNewNumberOfZoneSigns(`${location?.numberOfZoneSigns || 0}`);
    setNewStreetNumberFrom(location?.streetNumberFrom || '');
    setNewStreetNumberTo(location?.streetNumberTo || '');
    setCarFreeZoneId(location?.carFreeZone?.id);
    setCarFreeZoneGateEntrance(location?.carFreeZoneGateEntrance?.id || null);
    setCarFreeZoneGateExit(location?.carFreeZoneGateExit?.id || null);
  }, [location]);

  useEffect(() => {
    initialiseEditableFields();
    setEditMode(false);
  }, [location, initialiseEditableFields]);

  const onEdit = useCallback(() => {
    setEditMode(!editMode);
    initialiseEditableFields();
  }, [editMode, initialiseEditableFields]);

  const onChangeValue = useCallback(
    (setValue: (value: string) => void) => (event: ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    },
    [],
  );

  const onSetCarFreeZone = useCallback((event: ChangeEvent<any>) => {
    setCarFreeZoneId(parseInt(event.target.value));
  }, []);

  useEffect(() => {
    if (location?.carFreeZone?.id === carFreeZoneId) {
      setCarFreeZoneGateEntrance(location?.carFreeZoneGateEntrance?.id || null);
      setCarFreeZoneGateExit(location?.carFreeZoneGateExit?.id || null);
    } else {
      setCarFreeZoneGateEntrance(null);
      setCarFreeZoneGateExit(null);
    }
  }, [
    carFreeZoneId,
    location?.carFreeZone?.id,
    location?.carFreeZoneGateEntrance?.id,
    location?.carFreeZoneGateExit?.id,
  ]);

  const onSave = useCallback(() => {
    if (
      location &&
      ((location.numberOfSigns || 0) !== parseInt(newNumberOfSigns) ||
        (location.numberOfZoneSigns || 0) !== parseInt(newNumberOfZoneSigns) ||
        location.streetNumberFrom !== newStreetNumberFrom ||
        location.streetNumberTo !== newStreetNumberTo ||
        location.carFreeZone?.id !== carFreeZoneId ||
        location?.carFreeZoneGateEntrance?.id !== carFreeZoneGateEntrance ||
        location?.carFreeZoneGateExit?.id !== carFreeZoneGateExit)
    ) {
      onPatchLocationInfo({
        id: requestId,
        locationId: `${location.id}`,
        carFreeZone: carFreeZoneId,
        carFreeZoneGateEntrance,
        carFreeZoneGateExit,
        numberOfSigns: parseInt(newNumberOfSigns),
        numberOfZoneSigns: parseInt(newNumberOfZoneSigns),
        streetNumberFrom: newStreetNumberFrom,
        streetNumberTo: newStreetNumberTo,
      });
    }
    setEditMode(false);
  }, [
    carFreeZoneId,
    carFreeZoneGateEntrance,
    carFreeZoneGateExit,
    newNumberOfSigns,
    newNumberOfZoneSigns,
    newStreetNumberFrom,
    newStreetNumberTo,
    onPatchLocationInfo,
    requestId,
    location,
  ]);

  return (
    <PanelContent
      title={
        <span className={C.title}>
          {translate('Requests.Detail.Locations.Info.Title')}
          <EditButton title={translate('Requests.Detail.Locations.Info.Edit')} onClick={onEdit} visible={isEditable} />
        </span>
      }
    >
      {location ? (
        <Grid container spacing={2} className={C.grid}>
          <LabelValue label={translate('Requests.Detail.Locations.Info.Id')}>{location.id}</LabelValue>
          <LabelValue
            label={translate('Requests.Detail.Locations.Info.Type')}
            visible={!!location.type && !!location.type.name}
          >
            {location.type.name}
          </LabelValue>
          <LabelValue label={translate('Requests.Detail.Locations.Info.State')} visible={!!location.state}>
            {location.state && location.state.name}
          </LabelValue>
          <LabelValue
            labelCentered
            label={translate('Requests.Detail.Locations.Info.NumberOfSigns')}
            visible={location.numberOfSigns !== undefined || location.canHaveZoneSigns}
          >
            {!editMode ? (
              location.numberOfSigns || 0
            ) : (
              <TextField
                data-testid="numberOfSignsField"
                type="number"
                value={newNumberOfSigns}
                onChange={onChangeValue(setNewNumberOfSigns)}
              />
            )}
          </LabelValue>
          <LabelValue
            labelCentered
            label={translate('Requests.Detail.Locations.Info.NumberOfZoneSigns')}
            visible={location.canHaveZoneSigns}
          >
            {!editMode ? (
              location.numberOfZoneSigns || 0
            ) : (
              <TextField
                data-testid="numberOfZoneSignsField"
                type="number"
                value={newNumberOfZoneSigns}
                onChange={onChangeValue(setNewNumberOfZoneSigns)}
              />
            )}
          </LabelValue>
          <LabelValue
            label={translate('Requests.Detail.Locations.Info.NumberOfSignsPlaced')}
            visible={!!location.numberOfSignsPlaced !== undefined}
          >
            {location.numberOfSignsPlaced}
          </LabelValue>
          <LabelValue
            labelCentered
            label={translate('Requests.Detail.Locations.Info.Address')}
            visible={!!location.streetAndNumbers}
          >
            {!editMode ? (
              location.streetAndNumbers
            ) : (
              <Grid container className={C.editStreetNumbers}>
                <Grid item xs={4}>
                  {location.street}
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    data-testid="streetNumberFromField"
                    className={C.streetNumber}
                    value={newStreetNumberFrom}
                    onChange={onChangeValue(setNewStreetNumberFrom)}
                  />
                </Grid>
                <Grid item xs={1}>
                  -
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    data-testid="streetNumberToField"
                    className={C.streetNumber}
                    value={newStreetNumberTo}
                    onChange={onChangeValue(setNewStreetNumberTo)}
                  />
                </Grid>
              </Grid>
            )}
          </LabelValue>
          <LabelValue label={translate('Requests.Detail.Locations.Info.City')} visible={!!location.cityAndZipCode}>
            {location.cityAndZipCode}
          </LabelValue>
          <LabelValue label={translate('Requests.Detail.Locations.Info.CheckedAt')} visible={!!location.checkedAt}>
            {moment(location.checkedAt).format(DateFormat.dateTime)}
          </LabelValue>
          <LabelValue
            label={translate('Requests.Detail.Locations.Info.CarFreeZone')}
            labelCentered
            visible={location?.type?.type === IntakeTypes.carfreezoneintake}
          >
            {!editMode ? (
              location.carFreeZone?.name
            ) : (
              <SingleSelectV2Component
                title={translate('Requests.Detail.Locations.Info.CarFreeZone')}
                onChange={onSetCarFreeZone}
                menuItems={carFreeZones}
                value={carFreeZoneId}
                disabled={!canEditAssignedRequest || !editMode}
              />
            )}
          </LabelValue>
          <LabelValue
            label={translate('Requests.Detail.Locations.Info.CarFreeZoneEntrance')}
            labelCentered
            visible={!!entrances?.length}
          >
            {!editMode ? (
              location?.carFreeZoneGateEntrance?.name
            ) : (
              <SingleSelectV2Component
                title={translate('Requests.Detail.Locations.Info.CarFreeZoneEntrance')}
                menuItems={entrances.map(({ id, name }) => ({ label: name, value: id }))}
                value={carFreeZoneGateEntrance}
                onChange={(event) => setCarFreeZoneGateEntrance(event.target.value as number)}
              />
            )}
          </LabelValue>
          <LabelValue
            label={translate('Requests.Detail.Locations.Info.CarFreeZoneExit')}
            labelCentered
            visible={!!exits?.length}
          >
            {!editMode ? (
              location?.carFreeZoneGateExit?.name
            ) : (
              <SingleSelectV2Component
                title={translate('Requests.Detail.Locations.Info.CarFreeZoneExit')}
                menuItems={exits.map(({ id, name }) => ({ label: name, value: id }))}
                value={carFreeZoneGateExit}
                onChange={(event) => setCarFreeZoneGateExit(event.target.value as number)}
              />
            )}
          </LabelValue>
          <LabelValue label={translate('Requests.Detail.Locations.Info.Description')} visible={!!location.description}>
            {location.description}
          </LabelValue>
        </Grid>
      ) : null}
      <Visible visible={canEditAssignedRequest}>
        <div className={C.button}>
          <Button.Green
            onClick={onSave}
            disabled={!editMode && carFreeZoneId === location?.carFreeZone?.id}
            startIcon={<Save fontSize="small" />}
          >
            {translate('Requests.Detail.Locations.Info.SaveEdit')}
          </Button.Green>
        </div>
      </Visible>
    </PanelContent>
  );
};
