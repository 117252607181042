import { createUseStyles } from 'react-jss';
import { COLORS } from '../../../common';

export const useStyles = createUseStyles({
  autoComplete: {
    marginTop: '-10px',
    maxWidth: '70%',
  },
  paddingTop: {
    paddingTop: '10px',
  },
  paddingBottom: {
    paddingBottom: '12px',
  },
  resetMargin: {
    margin: '0!important',
  },
  title: {
    flex: '1',
  },
  titleContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    borderBottom: `1px solid ${COLORS.GREY_LIGHT}`,
    paddingBottom: '8px',
  },
});
