import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AddCircleOutline as AddIcon } from '@material-ui/icons';
import * as I18n from 'i18n-js';
import * as React from 'react';
import { FunctionComponent, useEffect, useState } from 'react';
import { Button } from '../../../../common/components/buttons/asign-button-extensions';
import { Unavailable } from '../../../../common/components/formatters/unavailable';
import { Visible } from '../../../../common/components/layout/Visible.component';
import { filterLicensePlate, isLicensePlateValid } from '../../../../utils';
import { LicensePlatesList } from './license-plates-list.component';

interface IProps {
  parkedPlates: string[];
  onParkedPlatesChange: (parkedPlates: string[]) => void;
  disabled?: boolean;
  showAddMode: boolean;
}

const useStyles = makeStyles({
  button: {
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  datepicker: {
    marginBottom: '10px',
  },
  parkedPlates: {
    marginRight: '15px',
    marginBottom: '15px',
  },
  unavailable: {
    marginBottom: '10px',
  },
});

/** For requests placed by Werkhaven */
export const LicensePlatesEditor: FunctionComponent<IProps> = ({
  parkedPlates,
  onParkedPlatesChange,
  disabled = false,
  showAddMode,
}) => {
  const C = useStyles();

  const [newParkedPlateText, setNewParkedPlateText] = useState<string>('');
  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewParkedPlateText(filterLicensePlate(event.target.value));
  };

  const sendMessageHandler = () => {
    const newParkedPlatesList = [...parkedPlates, newParkedPlateText.toUpperCase()];
    onParkedPlatesChange(newParkedPlatesList);
    setNewParkedPlateText('');
  };

  const deleteLicensePlateHandler = (parkedPlateToDel: string) => {
    return () => {
      const newParkedPlatesList = parkedPlates.filter((pp) => pp !== parkedPlateToDel);
      onParkedPlatesChange(newParkedPlatesList);
    };
  };

  const canSend = () => {
    return (
      !disabled &&
      newParkedPlateText &&
      !parkedPlates.includes(newParkedPlateText) &&
      isLicensePlateValid(newParkedPlateText)
    );
  };

  useEffect(() => {
    disabled && setNewParkedPlateText('');
  }, [disabled]);

  return (
    <>
      {parkedPlates.length === 0 && (
        <Unavailable className={C.unavailable} text={I18n.t('Requests.Detail.LicensePlates.NoneAvailable')} />
      )}
      <LicensePlatesList
        deleteLicensePlateHandler={deleteLicensePlateHandler}
        licensePlates={parkedPlates}
        showOnDelete={showAddMode}
      />
      <Visible visible={showAddMode}>
        <Grid container justify="space-between" spacing={4} data-testid="LocationLicensePlatesEditor">
          <Grid item sm={12} lg>
            <TextField
              id="message_input"
              name="message"
              value={newParkedPlateText}
              label={I18n.t('Requests.Detail.LicensePlates.Placeholder')}
              fullWidth
              margin="none"
              onChange={handleMessageChange}
              disabled={disabled}
            />
          </Grid>
          <Grid sm={12} lg={'auto'} item className={C.button}>
            <Button.Green onClick={sendMessageHandler} disabled={!canSend()} startIcon={<AddIcon fontSize={'small'} />}>
              {I18n.t(`Requests.Detail.LicensePlates.AddLicensePlate`)}
            </Button.Green>
          </Grid>
        </Grid>
      </Visible>
    </>
  );
};
