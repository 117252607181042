import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MetadataActions } from '../store/actions/metadata.actions';
import { selectMetadata } from '../store/selectors';

export const useMetadata = () => {
  const dispatch = useDispatch();
  const metadata = useSelector(selectMetadata);

  useEffect(() => {
    !metadata.general.loaded && dispatch(MetadataActions.fetch());
  }, [dispatch, metadata]);

  return metadata.general;
};
