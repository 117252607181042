import { useDispatch, useSelector } from 'react-redux';
import { getPhasesBounds, getPhasesCenter, selectPhases } from '../store/selectors/sgwRequest.selectors';
import { ISgwGeoDrawing, ISgwPhase } from '../types';
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { SgwPhasesActions } from '../store/actions/phases.actions';
import { LatLng, LatLngBounds } from 'leaflet';
import { atomWithHash } from 'jotai/utils';
import { useAtom } from 'jotai';

export interface IPhaseGeoDrawings {
  addMode: boolean;
  bounds: LatLngBounds;
  center?: LatLng;
  changeSelectedPhase(event: ChangeEvent<{ value: number }>): void;
  deleteGeoDrawing(id?: number): void;
  idToEdit?: number;
  phases: ISgwPhase[];
  saveGeoDrawing(values: ISgwGeoDrawing): void;
  selectedPhase?: ISgwPhase;
  setAddMode: Dispatch<SetStateAction<boolean>>;
  setIdToEdit: Dispatch<SetStateAction<number | undefined>>;
  geoDrawingToEdit?: ISgwGeoDrawing;
}

const selectedPhaseAtom = atomWithHash<number | undefined>('selected-phase', undefined);

export const usePhaseGeoDrawings = (): IPhaseGeoDrawings => {
  const dispatch = useDispatch();
  const phases = useSelector(selectPhases);
  const [addMode, setAddMode] = useState<boolean>(false);
  const bounds = useSelector(getPhasesBounds);
  const center = useSelector(getPhasesCenter);
  const [selectedPhaseId, setSelectedPhaseId] = useAtom(selectedPhaseAtom);
  const selectedPhase = phases.find(({ id }) => id === selectedPhaseId) || phases[0];
  const [idToEdit, setIdToEdit] = useState<number>();

  const geoDrawingToEdit = useMemo(
    () => selectedPhase?.sgwGeoDrawings.find(({ id }) => id === idToEdit),
    [idToEdit, selectedPhase],
  );

  const saveGeoDrawing = useCallback(
    (values: ISgwGeoDrawing) => {
      const remainingGeoDrawings = selectedPhase?.sgwGeoDrawings.filter(({ id }) => id !== idToEdit) || [];

      dispatch(
        SgwPhasesActions.save({
          phase: {
            id: selectedPhase?.id,
            sgwGeoDrawings: [...remainingGeoDrawings, { ...values }],
          },
        }),
      );
    },
    [selectedPhase?.sgwGeoDrawings, selectedPhase?.id, dispatch, idToEdit],
  );

  const deleteGeoDrawing = useCallback(
    (idToDelete?: number) => {
      dispatch(
        SgwPhasesActions.save({
          phase: {
            id: selectedPhase?.id,
            sgwGeoDrawings: selectedPhase?.sgwGeoDrawings.filter(({ id }) => id !== idToDelete) || [],
          },
        }),
      );
    },
    [dispatch, selectedPhase?.id, selectedPhase?.sgwGeoDrawings],
  );

  const changeSelectedPhase = useCallback(
    (event: ChangeEvent<{ value: number }>) => {
      setSelectedPhaseId(event.target.value);
    },
    [setSelectedPhaseId],
  );

  useEffect(() => {
    setAddMode(false);
    setIdToEdit(undefined);
  }, [phases]);

  return {
    addMode,
    bounds,
    center,
    changeSelectedPhase,
    deleteGeoDrawing,
    idToEdit,
    phases,
    saveGeoDrawing,
    selectedPhase,
    setAddMode,
    setIdToEdit,
    geoDrawingToEdit,
  };
};
