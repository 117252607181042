import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { Snackbar as MaterialSnackBar, SnackbarContent } from '@material-ui/core';
import { SnackBarVariant } from '../../../types';
import { createUseStyles } from 'react-jss';

interface IProps {
  duration?: number;
  onClose?: () => void;
  show: boolean;
  variant?: SnackBarVariant;
}

const useStyles = createUseStyles({
  snackbar: {
    zIndex: 2001,
  },
  error: {
    backgroundColor: '#f66162',
  },
  success: {
    backgroundColor: 'rgb(76, 175, 80)',
  },
});

export const Snackbar: FC<PropsWithChildren<IProps>> = ({
  children,
  duration,
  onClose,
  show = false,
  variant = SnackBarVariant.success,
}) => {
  const C = useStyles();
  const [open, setOpen] = useState<boolean>(show);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const _onClose = () => {
    setOpen(false);
    onClose?.();
  };

  return (
    <MaterialSnackBar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={duration}
      onClose={_onClose}
      open={open}
      className={C.snackbar}
    >
      <SnackbarContent
        message={<span>{children}</span>}
        className={variant === SnackBarVariant.success ? C.success : C.error}
      />
    </MaterialSnackBar>
  );
};
