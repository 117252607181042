import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Search } from '@material-ui/icons';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { MultiSelect } from '../../../common';
import { ClearFiltersFab } from '../../../common/components/buttons/ClearFiltersFab';
import { translate } from '../../../common/translations/translate';
import { IWithStyle } from '../../../types';
import { IServiceFilter, VehicleSource } from '../../../types';
import { getServicePincodeFilter, getServiceSyncFilter, vehicleSourceOptions } from '../../Service.constants';

interface IProps extends IWithStyle {
  resetFilters: () => void;
  filters: IServiceFilter;
  changeFilters: (filter: IServiceFilter) => void;
}

const useStyles = makeStyles({
  root: {
    marginBottom: 20,
  },
  resetButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 22,
  },
});

export const ServicesFilterComponent: FunctionComponent<IProps> = ({ filters, changeFilters, resetFilters }) => {
  const C = useStyles();

  const queryFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeFilters({
      ...filters,
      query: event.target.value,
    });
  };

  const pincodeFilterChange = (pincodeStatus: string[]) => {
    changeFilters({
      ...filters,
      pincodeStatus,
    });
  };

  const syncWithFilterChange = (syncWith: string[]) => {
    changeFilters({
      ...filters,
      syncWith,
    });
  };

  const vehicleSourceFilterChange = (vehicleSource: VehicleSource[]) => {
    changeFilters({
      ...filters,
      vehicleSource,
    });
  };

  return (
    <Grid container spacing={4} justify="space-between" className={C.root}>
      <Grid item xs={4}>
        <TextField
          value={filters.query}
          onChange={queryFilterChange}
          fullWidth={true}
          id="notificationQueryFilter"
          label={translate('services.filters.query')}
          InputProps={{
            endAdornment: <Search />,
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <MultiSelect
          id="stateFilter"
          data={getServicePincodeFilter()}
          value={filters.pincodeStatus}
          valueChange={pincodeFilterChange}
          label={translate('services.filters.pincode')}
          valueProp={'value'}
          displayProp={'label'}
        />
      </Grid>
      <Grid item xs={2}>
        <MultiSelect
          data={getServiceSyncFilter()}
          value={filters.syncWith}
          valueChange={syncWithFilterChange}
          valueProp="id"
          keyProp="id"
          displayProp="name"
          id="syncFilter"
          label={translate('services.filters.syncs')}
        />
      </Grid>
      <Grid item xs={2}>
        <MultiSelect
          data={vehicleSourceOptions()}
          value={filters.vehicleSource}
          valueChange={vehicleSourceFilterChange}
          valueProp="value"
          keyProp="value"
          displayProp="label"
          id="vehicleSourceFilter"
          label={translate('services.filters.vehicleSource')}
        />
      </Grid>
      <Grid item xs={2} className={C.resetButton}>
        <ClearFiltersFab onClick={resetFilters} />
      </Grid>
    </Grid>
  );
};
