import { createUseStyles } from 'react-jss';
import { CONFIG } from '../../../common';
import { getFirstVisibleTab } from '../../../common/store/user/user.selectors';
import { translate } from '../../../common/translations/translate';
import * as React from 'react';
import { useSelector } from 'react-redux';

const useStyles = createUseStyles(() => ({
  page: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  image: {
    width: '100px',
    height: '100px',
    filter: 'grayscale(100%) opacity(30%)',
  },
  title: {
    margin: 0,
    fontSize: '22px',
    marginTop: '20px',
  },
  text: {
    marginTop: '10px',
    fontSize: '16px',
  },
  link: {
    color: '#266ca3',
  },
}));

export const Error = () => {
  const C = useStyles();
  const firstVisibleTab = useSelector(getFirstVisibleTab);
  const homeUrl = firstVisibleTab?.route ?? '/';
  return (
    <div className={C.page}>
      <div className={C.container}>
        <a href={homeUrl} rel="noreferrer">
          <img alt={translate('login.image')} className={C.image} src={CONFIG.logoUrl} />
        </a>
        <h2 className={C.title}>{translate('errorPage.title')}</h2>

        <h4 className={C.text}>
          {translate('errorPage.description')}

          <a href={homeUrl} className={C.link}>
            {translate('errorPage.again')}
          </a>
        </h4>
      </div>
    </div>
  );
};
