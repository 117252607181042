import { Button } from '@material-ui/core';
import * as React from 'react';
import { FC, useState } from 'react';
import { Panel, TileSelect } from '../../common';
import { Loader } from '../../common/components/layout/loader.component';
import { Auth } from '../../components';
import { IModulesAclActions } from '../../types';
import { IModulesFilter, ModulePredefinedFilter } from '../types/modules-filter';
import { useDispatch, useSelector } from 'react-redux';
import { getModuleFilterTiles, selectModulesCounter } from '../store/modules.selectors';
import { ModulesActions } from '../store/modules.actions';
import { translate } from '../../common/translations/translate';
import { moduleListInitialFilter } from '../store/modules.reducer';

interface IProps {
  onMoveModulesClick: () => void;
}

export const ModulesOverview: FC<IProps> = ({ onMoveModulesClick }) => {
  const dispatch = useDispatch();
  const tiles = useSelector(getModuleFilterTiles);
  const counters = useSelector(selectModulesCounter);
  const [tile, setTile] = useState<ModulePredefinedFilter | number | undefined>();

  const onPredefinedFilterChange = (predefinedFilter: ModulePredefinedFilter | number): void => {
    const extraFilter: Partial<IModulesFilter> = {};
    if (tile === predefinedFilter) {
      setTile(undefined);
    } else {
      setTile(predefinedFilter);

      if (predefinedFilter === ModulePredefinedFilter.IN_MAINTENANCE) {
        extraFilter.inMaintenance = true;
      } else if (predefinedFilter === ModulePredefinedFilter.CRITICAL) {
        extraFilter.critical = true;
      } else {
        extraFilter.batches = [predefinedFilter];
      }
    }

    dispatch(
      ModulesActions.list.fetch({
        filters: {
          ...moduleListInitialFilter.filters,
          ...extraFilter,
        },
      }),
    );
  };

  const isLoading = () => {
    return Object.entries(counters.batches).length === 0;
  };

  return (
    <Panel title={translate('OverviewModules')}>
      <Loader loading={isLoading()}>
        <Auth acl={IModulesAclActions.editModule}>
          <Button
            id="moveModules"
            variant="contained"
            color="default"
            size="small"
            onClick={onMoveModulesClick}
            style={{ position: 'absolute', top: 15, right: 15 }}
            data-testid="moveModules"
          >
            {translate('MoveModules.Button')}
          </Button>
        </Auth>
        <TileSelect
          id="overviewFilters"
          itemsPerRow={4}
          value={tile}
          valueChange={onPredefinedFilterChange}
          values={tiles}
        />
      </Loader>
    </Panel>
  );
};
