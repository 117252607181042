import { Box, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import I18n from 'i18n-js';
import { FunctionComponent, useCallback, useEffect } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { InfoText } from '../../../common/components/layout/InfoText.component';
import { PanelTitle } from '../../../common/components/panel/panel-title.component';
import ClearIcon from '@material-ui/icons/Clear';
import { UserActions } from '../../../common/store/user/user.actions';
import { getListMenuItems } from '../../../common/store/user/user.selectors';
import Autocomplete, { AutocompleteChangeReason } from '@material-ui/lab/Autocomplete';
import { getEditableFieldsIncludes } from '../../../store/selectors';
import { RequestCreateField } from '../../../types';
import { TNullableLabelValue } from '../../../types/select.types';

const useStyles = makeStyles({
  root: { marginBottom: '2em' },
  container: { width: 1000 },
  buttonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  info: {
    color: '#9e9e9e',
  },
});

export const SelectUserComponent: FunctionComponent = () => {
  const C = useStyles();
  const dispatch = useDispatch();

  const [value, setValue] = React.useState<TNullableLabelValue>(null);
  const [inputValue, setInputValue] = React.useState('');
  const disabled = !useSelector(getEditableFieldsIncludes(RequestCreateField.userRequested));

  useEffect(() => {
    dispatch(UserActions.fetchListByName(inputValue));
  }, [dispatch, inputValue]);

  useEffect(() => {
    value && dispatch(UserActions.setSelected(`${value.value}`));
  }, [dispatch, value]);

  const onClear = useCallback(() => {
    dispatch(UserActions.setSelected(null));
    setInputValue('');
    setValue(null);
  }, [dispatch]);

  const onChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: TNullableLabelValue, reason: AutocompleteChangeReason) => {
      if (reason === 'clear') {
        onClear();
      }
      setValue(newValue);
    },
    [onClear],
  );

  return (
    <Box className={C.root} data-testid="SelectUserComponent">
      <PanelTitle>{I18n.t('Requests.Create.SelectUser')}</PanelTitle>
      <InfoText className={C.info}>{I18n.t(`Requests.Create.SelectUserDescription`)}</InfoText>
      <Grid container className={C.container} spacing={6}>
        <Grid item xs={6}>
          <Autocomplete
            renderInput={(params) => (
              <TextField {...params} variant="standard" label={I18n.t('Requests.Create.SelectUser')} />
            )}
            options={useSelector(getListMenuItems())}
            getOptionLabel={(option) => option.label}
            value={value}
            onChange={onChange}
            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
            getOptionSelected={(option, value) => option.value === value.value}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={3} className={C.buttonContainer}>
          <Button.White type="button" disabled={disabled} onClick={onClear} startIcon={<ClearIcon />}>
            {I18n.t('Requests.Create.ClearUserData')}
          </Button.White>
        </Grid>
      </Grid>
    </Box>
  );
};
