import * as Leaflet from 'leaflet';
import { DivIcon, LatLng, PathOptions } from 'leaflet';
import React, { FunctionComponent, useCallback } from 'react';
import { Marker, Polygon } from 'react-leaflet';
import { GeoDrawingType, IGeometry, IGeometryType } from '../../../types';
import { geometryToPositions, isLatLngArray } from '../../utils/geojson.util';
import { GeometryWithMarkers } from './GeometryWithMarkers.component';

interface IProps {
  editable?: boolean;
  geoDrawingType?: GeoDrawingType;
  geometry?: IGeometry;
  saveGeometry?: (geometry: IGeometry) => void;
  showMarkers?: boolean;
  positions?: LatLng | LatLng[] | LatLng[][] | LatLng[][][] | null;
  onClick?: (event?: Leaflet.LeafletMouseEvent) => void;
  pathOptions?: PathOptions;
  icon?: DivIcon;
}

const defaultPathOptions: PathOptions = {
  weight: 5,
};

export const GeometryComponent: FunctionComponent<IProps> = ({
  geoDrawingType,
  geometry,
  pathOptions,
  positions,
  showMarkers = true,
  ...restProps
}) => {
  const getPositions = useCallback(
    () => positions || (geometry && geometryToPositions(geometry)),
    [geometry, positions],
  );

  if (!geometry || !(positions || getPositions())) return null;

  const props = {
    ...defaultPathOptions,
    pathOptions,
    ...restProps,
  };

  switch (geometry.type) {
    case 'Point':
      return showMarkers ? (
        <Marker
          // @ts-ignore
          position={isLatLngArray(getPositions()) ? (getPositions()[0] as LatLng) : (getPositions() as LatLng)}
          // AS-6911 Changing type of request from CFZ to Parking Ban gives a blank screen
          {...{ ...props, draggable: props.editable, editable: undefined }}
        />
      ) : null;

    case 'LineString':
      return (
        <GeometryWithMarkers
          showArrow={geoDrawingType === GeoDrawingType.detour}
          hideMarkers={!showMarkers}
          positions={getPositions() as LatLng[]}
          {...props}
        />
      );

    case 'Polygon':
      return (
        <GeometryWithMarkers
          geometryType={IGeometryType.Polygon}
          hideMarkers={!showMarkers}
          positions={getPositions() as LatLng[]}
          {...props}
        />
      );

    case 'MultiPoint':
      return (
        <>
          {(getPositions() as LatLng[]).map((position) => (
            // AS-6911 Changing type of request from CFZ to Parking Ban gives a blank screen
            <Marker position={position} {...{ ...props, draggable: props.editable, editable: undefined }} />
          ))}
        </>
      );

    case 'MultiLineString':
      return (
        <>
          {(getPositions() as LatLng[][]).map((positions) => (
            <GeometryWithMarkers
              showArrow={geoDrawingType === GeoDrawingType.detour}
              hideMarkers={!showMarkers}
              positions={positions}
              {...props}
            />
          ))}
        </>
      );
    case 'MultiPolygon':
      return (
        <>
          {(getPositions() as LatLng[][][]).map((positions) => (
            <Polygon positions={positions} {...props} />
          ))}
        </>
      );
    default:
      return null;
  }
};
