import axios from 'axios';
import { CONFIG, IApiPromise, IPagedApiParams } from '../../common';
import { ISgwNatureOfWork } from '../../types';

export const SgwNatureOfWorksApi = {
  fetchList: (params: IPagedApiParams): IApiPromise<ISgwNatureOfWork[]> =>
    axios.get(`${CONFIG.backOfficeApi}/v1/sgw/natureofworks`, { params }),
  save: (natureOfWork: Partial<ISgwNatureOfWork>): IApiPromise<ISgwNatureOfWork> => {
    const { id, ...natureOfWorkData } = natureOfWork;
    return id
      ? axios.patch(`${CONFIG.backOfficeApi}/v1/sgw/natureofworks/${id}`, natureOfWorkData)
      : axios.post(`${CONFIG.backOfficeApi}/v1/sgw/natureofworks`, natureOfWork);
  },
  fetch: (id: string): IApiPromise<ISgwNatureOfWork> => axios.get(`${CONFIG.backOfficeApi}/v1/sgw/natureofworks/${id}`),
};
