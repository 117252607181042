import * as L from 'leaflet';
import { Marker } from 'react-leaflet';
import { SignsMapMarkerPopupComponent } from './signs-map-marker-popup.component';
import { default as React, FC, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../root.state';
import { ISignMapMarker } from '../types/signs-map';
import { COLORS, MaterialMarkerIcon } from '../../common';
import { SIGN_STATES, SIGN_TYPES } from '../signs.constants';
import { fetchSignsMapPopup, generateRetrieveWorkOrderItemFromSignMap, SignsActions } from '../store/signs.actions';

interface IProps {
  sign: ISignMapMarker;
}

export const SignMarker: FC<IProps> = ({ sign }) => {
  const dispatch = useDispatch();
  const markerRef = useRef<L.Marker>(null);
  const signPopup = useSelector((store: IRootState) => store.signs.signsMapPopup);

  const getMarkerIcon = useCallback((sign: ISignMapMarker) => {
    let icon = '';
    const color = COLORS.WHITE;
    let bgColor = COLORS.PRIMARY;

    switch (sign.type) {
      case SIGN_TYPES.DIGITAL:
        icon = 'wifi';
        break;
    }

    switch (sign.state) {
      case SIGN_STATES.PLACED:
        bgColor = COLORS.PRIMARY;
        break;
      case SIGN_STATES.MISSING:
        bgColor = COLORS.DANGER;
        break;
      case SIGN_STATES.MOVED:
        bgColor = COLORS.BLACK;
        break;
    }

    if (sign.hideOnMapView) {
      bgColor = COLORS.GREY_LIGHT;
    }

    return MaterialMarkerIcon(icon, color, bgColor);
  }, []);

  return (
    <Marker
      ref={markerRef}
      icon={getMarkerIcon(sign)}
      position={sign.location}
      eventHandlers={{
        click: () => dispatch(fetchSignsMapPopup(sign.hash)),
      }}
    >
      <SignsMapMarkerPopupComponent
        sign={sign}
        signPopup={signPopup}
        createWorOrderCallback={(sign) => dispatch(generateRetrieveWorkOrderItemFromSignMap(sign))}
        hideSignOnMapViewCallback={(sign) => dispatch(SignsActions.hideSignOnMapView({ hash: sign.hash }))}
      />
    </Marker>
  );
};
