import { Actions, TableActions } from '../../common';
import { ISgwTrafficMeasure, ISgwTrafficMeasuresFilter } from '../../types';

class SgwTrafficMeasuresActionsClass extends Actions {
  list = new TableActions<ISgwTrafficMeasure, ISgwTrafficMeasuresFilter>(`${this.prefix}.list`);
  setLoading = this.createPayloadAction<boolean>('setLoading');
  set = this.createPayloadAction<ISgwTrafficMeasure>('set');
  fetch = this.createPayloadAction<string>('fetch');
  save = this.createPayloadAction<ISgwTrafficMeasure>('save');
}

export const SgwTrafficMeasuresActions = new SgwTrafficMeasuresActionsClass('sgwTrafficMeasures');
